import React from 'react';
import { Tabs, Tab } from "react-bootstrap";
import '../../../assets/css/partials/common/userProfile.scss';
import MyProfile from "./myProfile";
import MyBookings from "./myBookings";
import {
    BrowserRouter as Router,
    Route,
    Link,
    Switch,
    NavLink,
} from "react-router-dom";

function ProfileTabHolder() {
  const currentTab = localStorage.getItem("profileTab");
    return (
          <div className=" profile-tabs">
            {/* <nav>
              <ul className="d-flex justify-content-center">
                <li>
                  <NavLink to="/user/profile" exact={true} activeClassName="active">
                    My profile
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/user/bookings"  activeClassName="active">
                    Bookings
                  </NavLink>
                </li>
              </ul>
            </nav> */}
          {
                 <Tabs className="justify-content-center " defaultActiveKey={(currentTab === 'profile' || currentTab === null) ? 'profile' : 'bookings'} id="uncontrolled-tab-example" onSelect={(selectedTab)=>localStorage.setItem("profileTab", selectedTab)}>
                  <Tab eventKey="profile" title="My profile">
                  <MyProfile />
                  </Tab>
                  <Tab eventKey="bookings" title="Bookings">
                  <MyBookings/>
                  </Tab>
              </Tabs>
          }
          </div>
    )
}

export default ProfileTabHolder
