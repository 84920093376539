import React, {useState, useEffect} from 'react'
import '../corporates/calenderModal.css';
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import * as EventsAction from "../../redux/actions/eventsAction";

function SelectDateAndTime({ showMyModal, scheduleDates }) {
    const dispatch = useDispatch();
    const [dateState, setDateState] = useState(null);
    const [timeState, setTimeState] = useState(null);
    const [endtimeState, setEndTimeState] = useState(null);

    const [showModal, setShowModal] = useState(showMyModal);
    
    const selectedDate = useSelector(state => state?.events?.selected_date);
    const selectedTime = useSelector(state => state?.events?.selected_time);
    const endTime = useSelector(state => state?.events?.end_time);

    useEffect(() => {
        setDateState(selectedDate);
        setTimeState(selectedTime);
        setEndTimeState(endTime);
    }, [selectedDate, selectedTime, endTime]);

    const getDateHandler = (date, time, endTime) => {
        setDateState(date);
        setTimeState(time);
        setEndTimeState(endTime);
    } 

    const selectedDateHandler = () => {
        dispatch(EventsAction.handleSelectedDate(dateState , timeState, endtimeState));
        setShowModal(false)
    }

    return (
        <div className={`modal-wrapper ${showModal ? 'show' : 'hide'}`}>
            <div className="card-box calender-modal">
                <div className="row">
                    <div className="col-12 col-md-8">
                        {
                            scheduleDates && scheduleDates.length > 0 && scheduleDates.map((item) => (
                                <div  className={dateState == item?.date ? 'active-date dates-list' : 'dates-list' }  onClick={() => getDateHandler(item?.date, item?.from, item?.to)}>
                                    <div>
                                        <p>{moment(item?.date, 'DD-MM-YYYY').format('Do MMM YYYY, ddd')}</p>
                                    </div>
                                    <div> - </div>
                                    <div>
                                        <p>{moment(item?.from, 'hh:mm').format('LT')}</p>
                                    </div>
                                </div>
                            ))
                        }

                    </div>
                    <div className="col-12 col-md-4">
                        <div className="start-date">
                            <p>Start date</p>
                            <div className="selected-date">
                            {
                                dateState !== null ?  <p>{moment(dateState, 'DD-MM-YYYY').format('Do MMM YYYY, dddd')}</p> : ''
                            }
                               
                            </div>
                        </div>

                        <div className="submit">
                            <button type="submit" onClick={() => {selectedDateHandler()}} className="btn">Done</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SelectDateAndTime
