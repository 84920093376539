// visas & more data
import category1 from '../assets/images/holidays/categories/category1.png';
import category2 from '../assets/images/holidays/categories/category2.png';
import category3 from '../assets/images/holidays/categories/category3.png';
import category4 from '../assets/images/holidays/categories/category4.png';
import category5 from '../assets/images/holidays/categories/category5.png';
import category6 from '../assets/images/holidays/categories/category6.png';
import category7 from '../assets/images/holidays/categories/category7.png';

import logo1 from '../assets/images/holidays/certifications/south-africa.svg';
import logo2 from '../assets/images/holidays/certifications/taiwan.svg';
import logo3 from '../assets/images/holidays/certifications/ireland.svg';
import logo4 from '../assets/images/holidays/certifications/england.svg';
import logo5 from '../assets/images/holidays/certifications/yas-island.svg';
import logo6 from '../assets/images/holidays/certifications/turkey.svg';
import logo7 from '../assets/images/holidays/certifications/oman.svg';
import logo8 from '../assets/images/holidays/certifications/kenya.svg';
import logo9 from '../assets/images/holidays/certifications/uzbekistan.svg';
import logo10 from '../assets/images/holidays/certifications/maldives.svg';
import logo11 from '../assets/images/holidays/certifications/ras-al-khaimah.svg';
import logo12 from '../assets/images/holidays/certifications/qatar.svg';
import logo13 from '../assets/images/holidays/certifications/russia.svg';
import logo14 from '../assets/images/holidays/certifications/abudhabi.svg';


import logo15 from '../assets/images/holidays/certifications/switzerland.svg';
import logo16 from '../assets/images/holidays/certifications/germany.svg';
import logo17 from '../assets/images/holidays/certifications/europe.svg';
import logo18 from '../assets/images/holidays/certifications/new-zealand.svg';
import logo19 from '../assets/images/holidays/certifications/hawaii.svg';
import logo20 from '../assets/images/holidays/certifications/seychelles.svg';
import logo21 from '../assets/images/holidays/certifications/srilanka.svg';
import logo22 from '../assets/images/holidays/certifications/dubai.svg';
import logo23 from '../assets/images/holidays/certifications/egypt.svg';
import logo24 from '../assets/images/holidays/certifications/japan.svg';
import logo25 from '../assets/images/holidays/certifications/usa.svg';
import logo26 from '../assets/images/holidays/certifications/australia.svg';
import logo27 from '../assets/images/holidays/certifications/spain.svg';
import logo28 from '../assets/images/holidays/certifications/south-korea.svg';
import logo29 from '../assets/images/holidays/certifications/greece.svg';
import logo31 from '../assets/images/holidays/certifications/bahamas.svg'
import logo32 from '../assets/images/holidays/certifications/norway.svg'
import logo33 from '../assets/images/holidays/certifications/thailand.jpg'


import visa from '../assets/images/holidays/Converted/visa.webp';
import passport from '../assets/images/holidays/Converted/passport.webp';
import travel from '../assets/images/holidays/applications/travel.png';

import voucher1 from '../assets/images/holidays/sendGift/1.svg'
import voucher2 from '../assets/images/holidays/sendGift/2.svg'
import voucher3 from '../assets/images/holidays/sendGift/3.svg'
import voucher4 from '../assets/images/holidays/sendGift/4.svg'
import voucher5 from '../assets/images/holidays/sendGift/5.svg'
import voucher6 from '../assets/images/holidays/sendGift/6.svg'

import customize1 from "../assets/images/holidays/Converted/customize1.webp";
import customize2 from "../assets/images/holidays/Converted/customize2.webp";
import customize3 from "../assets/images/holidays/Converted/customize3.webp";

import domestic1 from "../assets/images/holidays/domestic-coverage/image1.svg";
import domestic2 from "../assets/images/holidays/domestic-coverage/image2.svg";
import domestic3 from "../assets/images/holidays/domestic-coverage/image3.svg";
import domestic4 from "../assets/images/holidays/domestic-coverage/image4.svg";
import domestic5 from "../assets/images/holidays/domestic-coverage/image5.svg";
import domestic6 from "../assets/images/holidays/domestic-coverage/image6.svg";

import international1 from "../assets/images/holidays/international-coverage/image1.svg";
import international2 from "../assets/images/holidays/international-coverage/image2.svg";
import international3 from "../assets/images/holidays/international-coverage/image3.svg";
import international4 from "../assets/images/holidays/international-coverage/image4.svg";
import international5 from "../assets/images/holidays/international-coverage/image5.svg";
import international6 from "../assets/images/holidays/international-coverage/image6.svg";
import international7 from "../assets/images/holidays/international-coverage/image7.svg";
import international8 from "../assets/images/holidays/international-coverage/image8.svg";
import international9 from "../assets/images/holidays/international-coverage/image9.svg";
import international10 from "../assets/images/holidays/international-coverage/image10.svg";
import international11 from "../assets/images/holidays/international-coverage/image11.svg";
import international12 from "../assets/images/holidays/international-coverage/image12.svg";

import Package1 from "../assets/images/holidays/Converted/info1.webp";
import Package2 from "../assets/images/holidays/Converted/info2.webp";

import Package1Mobi from "../assets/images/holidays/Converted/mobi-info1.webp";
import Package2Mobi from "../assets/images/holidays/Converted/mobi-info2.webp";

// Holidays categories
export const HOLIDAYS_CATEGORIES_LIST = [
    // {
    //     id: '1',
    //     image: `${category1}`,
    //     name: `Deals`
    // },
    // {
    //     id: '2',
    //     image: `${category2}`,
    //     name: `Bharat dekho`,
    //     active: true
    // },
    // {
    //     id: '3',
    //     image: `${category3}`,
    //     name: `Workations`
    // },
    // {
    //     id: '4',
    //     image: `${category4}`,
    //     name: `International`
    // },
    {
        id: '5',
        image: `${category5}`,
        name: `Visas & More`,
        active: true
    },
    // {
    //     id: '6',
    //     image: `${category6}`,
    //     name: `Customize`
    // },
    // {
    //     id: '7',
    //     image: `${category7}`,
    //     name: `Send a Gift`
    // },
];


// Certifications list1
export const CERTIFICATION_LOGO_LIST1 = [
    {
        image: `${logo26}`,
        name: 'Australia',
    },
    {
        image: `${logo18}`,
        name: 'New Zealand',
    },
    {
        image: `${logo29}`,
        name: 'Greece',
    },
    {
        image: `${logo1}`,
        name: 'South Africa',
    },
     
    {
        image: `${logo27}`,
        name: 'Spain',
    },
    {
        image: `${logo15}`,
        name: 'Switzerland',
    },
    {
        image: `${logo16}`,
        name: 'Germany',
    },
    {
        image: `${logo17}`,
        name: 'Europe',
    },
    {
        image: `${logo31}`,
        name: 'Bahamas',
    },
    {
        image: `${logo32}`,
        name: 'Norway',
    },
    {
        image:`${logo33}`,
        name: 'Thailand',
    }
   
   

];


// Certifications list2
export const CERTIFICATION_LOGO_LIST2 = [
    {
        image: `${logo6}`,
        name: 'Turkey',
    },
    {
        image: `${logo24}`,
        name: 'Japan',
    },
    {
        image: `${logo28}`,
        name: 'South Korea',
    },
    {
        image: `${logo2}`,
        name: 'Taiwan',
    },
    {
        image: `${logo3}`,
        name: 'Ireland',
    },
    {
        image: `${logo4}`,
        name: 'England',
    },
    {
        image: `${logo10}`,
        name: 'Maldives',
    },
    {
        image: `${logo13}`,
        name: 'Russia',
    },
    {
        image: `${logo25}`,
        name: 'U.S.A',
    },
    {
        image: `${logo19}`,
        name: 'Hawaii',
    },
    {
        image: `${logo21}`,
        name: 'Sri Lanka',
    },

]

export const CERTIFICATION_LOGO_LIST3 = [
    {
        image: `${logo8}`,
        name: 'Kenya',
    },
    {
        image: `${logo20}`,
        name: 'Seychelles',
    },
    {
        image: `${logo23}`,
        name: 'Egypt',
    },
    {
        image: `${logo22}`,
        name: 'Dubai',
    },
    {
        image: `${logo14}`,
        name: 'Abu Dhabi',
    },
    {
        image: `${logo5}`,
        name: 'Yas Island',
    },
    {
        image: `${logo7}`,
        name: 'Oman',
    },
    {
        image: `${logo11}`,
        name: 'Ras al Khaimah',
    },
    {
        image: `${logo12}`,
        name: 'Qatar',
    },
    {
        image: `${logo9}`,
        name: 'Uzbekistan',
    },
   
  

]

// Applications data

export const APPLICATIONS_DATA = [
    {
        title: 'Visa application',
        description: 'Our professional services hep you in completing all your Visa formalities with clear documentation, as per individual country specific regulations.  Our Visa facilitation services cover regular & E-Visas; for Leisure travel - Corporate travel - Group travel - Students and so on..',
        image: `${visa}`,
    },
    {
        title: 'Passport renewal / application',
        description: 'Documentation & Assistance in obtaining passports, Authentication of support documents, Forex, Travel insurance are all part of our service portfolio.',
        image: `${passport}`,
    },
  
]


// Send a gift data





export const VOUCHERS_DATA = [
    {
        id: 0,
        image: `https://hammock-holiday.s3-ap-south-1.amazonaws.com/holidays/user/abe2274b5d284e878da2ed7f84bec7c8.png`,
        title: 'Valentines',
        description: 'Put your family first. The ideal and flexible gifting options for any occasion.  A wide range of options to suit any age group.',

    },
    {
        id: 1,
        image: `https://hammock-holiday.s3-ap-south-1.amazonaws.com/holidays/user/b34a62454bed4869a30d628868fcbbad.png`,
        title: 'Birthday',
        description: 'Put your family first. The ideal and flexible gifting options for any occasion.  A wide range of options to suit any age group.',

    },
    {
        id: 2,
        image: `https://hammock-holiday.s3-ap-south-1.amazonaws.com/holidays/user/4f79e36d678f4468b791ed90b88a0d4a.png`,
        title: 'Best Wishes',
        description: 'Put your family first. The ideal and flexible gifting options for any occasion.  A wide range of options to suit any age group.',

    },
    {
        id: 4,
        image: `https://hammock-holiday.s3-ap-south-1.amazonaws.com/holidays/user/5a589df84686465492187846ea3ad820.png`,
        title: 'Anniversary',
        description: 'Put your family first. The ideal and flexible gifting options for any occasion.  A wide range of options to suit any age group.',

    },
    {
        id: 5,
        image: `https://hammock-holiday.s3-ap-south-1.amazonaws.com/holidays/user/bfb2953b4d9a4286bfff2acefaa00887.png`,
        title: 'Baby Shower',
        description: 'Put your family first. The ideal and flexible gifting options for any occasion.  A wide range of options to suit any age group.',

    },
    {
        id: 6,
        image: `${voucher6}`,
        title: 'Custom Title', // Don't change custom title used for validation in sendGift.js
        description: 'Custom message',

    },

]

export const CUSTOMIZE_DATA = [
        {
            image: `${customize1}`,
            title: 'Curated travel experiences, to satisfy the traveller in you'
        },
        {
            image: `${customize2}`,
            title: 'Adventure, History, Culture, Wildlife, Nature, Photography or Architecture'
        },
        {
            image: `${customize3}`,
            title: 'We have a myriad selection of experiences awaiting you.'
        },
    
]

export const DOMESTIC_COVERAGE1 = [
    {
        name: "Rajasthan",
        image: `${domestic1}`
    },
    {
        name: "Sikkim",
        image: `${domestic2}`
    },
    {
        name: "Arunachal",
        image: `${domestic3}`
    },
    

]

export const DOMESTIC_COVERAGE2 = [
    {
        name: "Leh ladakh",
        image: `${domestic4}`
    },
    {
        name: "Goa",
        image: `${domestic5}`
    },
    {
        name: "+13 more states",
        image: `${domestic6}`
    }
]

export const INTERNATIONAL_COVERAGE1 = [
    {
        name: "Oman",
        image: `${international1}`
    },
    {
        name: "Kenya",
        image: `${international2}`
    },
    {
        name: "Uzbekistan",
        image: `${international3}`
    },
    {
        name: "Russia",
        image: `${international4}`
    },
    {
        name: "Abu Dhabi",
        image: `${international5}`
    },
    {
        name: "Maldives",
        image: `${international6}`
    },

]

export const INTERNATIONAL_COVERAGE2 = [
    {
        name: "Seychelles",
        image: `${international7}`
    },
    {
        name: "Sri Lanka",
        image: `${international8}`
    },
    {
        name: "Dubai",
        image: `${international9}`
    },
    {
        name: "Egypt",
        image: `${international10}`
    },
    {
        name: "U.S.A",
        image: `${international11}`
    },
    {
        name: "+12 more counteries",
        image: `${international12}`
    },
]


export const HOLIDAY_PACKAGES = [
    {
        id: 1,
        title: "Reach out to us for seamless Visa Application process",
        image: [`${Package1}`],
        imageMobi: [`${Package1Mobi}`],
        desc: "Enjoy, Premium slot VFS appointments, Single point contact for information & assistance to complete all supporting forms. Choice of regular & refundable Airfares, Flexi - Baggage policies, Hotel vouchers, Travel Insurance, Forex requirements and more.",
    },
    {
        id: 2,
        title: "Nearly a 98% success rate",
        image: [`${Package2}`],
        imageMobi: [`${Package2Mobi}`],
        desc: "Visa applications that we assist you to process are twice as likely to be approved, as compared to visa’s processed through other regular channels."
    },
];