
// import Package1 from "../assets/images/myFamilyFirst/creative3.png";
// import Package2 from "../assets/images/myFamilyFirst/creative4.png";
// import Package3 from "../assets/images/myFamilyFirst/creative5.png";

import Package1 from "../assets/images/myFamilyFirst/Converted/3.webp";
import Package2 from "../assets/images/myFamilyFirst/Converted/4.webp";
import Package3 from "../assets/images/myFamilyFirst/Converted/5.webp";

import Package1Mobi from "../assets/images/myFamilyFirst/Converted/3.webp";
import Package2Mobi from "../assets/images/myFamilyFirst/Converted/4.webp";
import Package3Mobi from "../assets/images/myFamilyFirst/Converted/5.webp";

import GiftImg from "../assets/images/myFamilyFirst/gift.png";

import Slide1 from "../assets/images/myFamilyFirst/slide1.png";
import Slide2 from "../assets/images/myFamilyFirst/slide2.png";
import Slide3 from "../assets/images/myFamilyFirst/slide3.png";
import Slide4 from "../assets/images/myFamilyFirst/slide4.png";
import Slide5 from "../assets/images/myFamilyFirst/slide5.png";

// gift slider

export const GIFT_SLIDER = [
    {
        title: "We both sisters saw a dream to travel and  we achieved this.",
        image: `${Slide1}`,
    },
    {
        title: "Who just found their heart",
        image: `${Slide2}`,
    },
    {
        title: "Special lunch arrangements",
        image: `${Slide3}`,
    },
    {
        title: "5-star experiences",
        image: `${Slide4}`,
    },
    {
        title: "Comfortable travel",
        image: `${Slide5}`,
    },
]


// gift packages

export const GIFT_PACKAGES = [
    {
        id: 1,
        title: "Melt away the miles and keep the smiles on your loved ones, do something unique for them",
        image: [`${Package1}`],
        imageMobi: [`${Package1Mobi}`],
        desc: "Looking for a gift for your family away from you?  An anniversary, a birthday, a special occasion that you want to commemorate?",
    },
    {
        id: 2,
        title: "A personal gift like no other…for your special people who are like no other.",
        image: [`${Package2}`],
        imageMobi: [`${Package2Mobi}`],
        desc: "Have you run the gamut of chocolates, flowers and cakes delivered? Then consider an exciting alternative... Gift them an unforgettable and unique experience that will warm their hearts."
    },
    {
        id: 3,
        title: "Plan with us for a customized gifting experience",
        image: [`${Package3}`],
        imageMobi: [`${Package3Mobi}`],
        desc: "Maybe a chauffeured limousine for a movie star style, 5 star dine out... complete with flowers - wine - candle lit dinner - personalised gifts. We can add in candid photographs of the evening, for a night that can be lived over a 100 times."
    }
];