import {
  API,
  BASE_URL,
  CC_PAYMENT_KEY,
  DC_PAYMENT_KEY,
  TEST_PAYMENT_KEY,
} from "../../config/api";
import {
  axiosPostWithToken,
  axiosMediaUpload,
  axiosGetWithToken,
  axiosPost,
  axiosGet,
} from "../../services/apiServices";
import { ERROR_ALERT } from "./commonAction";
import { ALL_LOCATIONS_LIST, ALL_COUNTRY_LIST } from "../actions/types";

export const VOUCHER_CHECKOUT_DETAILS = "VOUCHER_CHECKOUT_DETAILS";
export const VOUCHER_PAYMENT_DETAILS = "VOUCHER_PAYMENT_DETAILS";
export const BOOKING_STATUS = "BOOKING_STATUS";
export const HOLIDAYS_CATEGORY = "HOLIDAYS_CATEGORY";
export const INITIAL_HOLIDAY_CATEGORY_ID = "INITIAL_HOLIDAY_CATEGORY_ID";
export const CATEGORY_ID = "CATEGORY_ID";
export const VISAS_AND_MORE = "VISAS_AND_MORE";
export const HOLIDAYS_LIST = "HOLIDAYS_LIST";
export const SEARCH_HOLIDAYS_BY_LOCATION = "SEARCH_HOLIDAYS_BY_LOCATION";
export const HOLIDAY_PACKAGE_DETAILS = "HOLIDAY_PACKAGE_DETAILS";
export const HOLIDAY_BOOKING_INFORMATION_DATA =
  "HOLIDAY_BOOKING_INFORMATION_DATA";
export const CHANGE_DATA_FOR_PROCEED_TO_BOOK =
  "CHANGE_DATA_FOR_PROCEED_TO_BOOK";
export const DATA_CHANGED_BOOL_VAL = "DATA_CHANGED_BOOL_VAL";
export const CHECKOUT_DETAILS = "CHECKOUT_DETAILS";
export const EDITED_CORP_HOLIDAY_NAME = "EDITED_CORP_HOLIDAY_NAME";
export const EDITED_CORP_HOLIDAY_NAME_BOOL = "EDITED_CORP_HOLIDAY_NAME_BOOL";
export const EDITED_CORP_HOLIDAY_NUMBER = "EDITED_CORP_HOLIDAY_NUMBER";
export const EDITED_CORP_HOLIDAY_NUMBER_BOOL =
  "EDITED_CORP_HOLIDAY_NUMBER_BOOL";
export const HOLIDAY_NON_CORPORATE_PAYMENT = "HOLIDAY_NON_CORPORATE_PAYMENT";
export const SIMILAR_HOLIDAYS = "SIMILAR_HOLIDAYS";
export const SELECTED_MONTHS = "SELECTED_MONTHS";
export const CUSTOMIZE_HOLIDAY = "CUSTOMIZE_HOLIDAY";
export const SET_SUCCESS = "SET_SUCCESS";
export const NEAR_BY_EXPERIENCES = "NEAR_BY_EXPERIENCES";
export const MORE_HOLIDAYS = "MORE_HOLIDAYS";
export const ROUTE_MESSAGE = "ROUTE_MESSAGE";
export const SET_SCROLL = "SET_SCROLL";
export const HOLIDAYS_PACKAGES_LIST = "HOLIDAYS_PACKAGES_LIST";
export const RESET_HOLIDAY_LIST = "RESET_HOLIDAY_LIST";
export const HOLIDAY_REVIEW_REFERENCE_ID = "HOLIDAY_REVIEW_REFERENCE_ID";
export const HOLIDAY_REVIEW_POST = "HOLIDAY_REVIEW_POST";
export const HOLIDAY_REVIEW_LIST = "HOLIDAY_REVIEW_LIST";
export const FILTER_VALUES = "FILTER_VALUES";
export const PAGE_SIZE = "PAGE_SIZE";
export const SET_SEARCH_VALUE = "SET_SEARCH_VALUE";
export const HOLIDAY_FILTER_VALUES = "HOLIDAY_FILTER_VALUES";
export const HOLIDAY_TESTIMONIALS = "HOLIDAY_TESTIMONIALS";
export const HOLIDAY_REVIEWLIST_TOTAL_PAGE="HOLIDAY_REVIEWLIST_TOTAL_PAGE"
export const HOLIDAY_REVIEWLIST_CURRENT_PAGE="HOLIDAY_REVIEWLIST_CURRENT_PAGE"
export const HOLIDAY_ACTIVE_PACKAGE_INDEX="HOLIDAY_ACTIVE_PACKAGE_INDEX"

export const handleBuyVoucher = (data) => {
  return async (dispatch, getState) => {
    const body = {
      price: data.price,
      points: data.points,
      title: data.title,
      image: data.image,
      request: data.request,
      message: data.message,
      array: data.name,
      email: data.email,
      contactNumber: data.contactNumber,
    };
    localStorage.setItem("voucherData", JSON.stringify(body));
    dispatch({
      type: "BUY_VOUCHER",
      payload: body,
    });
  };
};

// checkout function
// CONDITION 1: If points are greater then amount pay with points and amount will be equal to zero (for corporate users only).
// CONDITION 2: Else pay with amount redirect's to bank payment screen (can also use points and can pay balance amount) for corporate users.
// CONDITION 3: for non corporate users
export const handleCheckoutVoucher = (data, corporate) => {
  // console.log(data)
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const isCorporateAccount = corporate;
    const body = {
      total_points: data?.total_points,
      amount: Math.floor(data?.amount),
      pack_title: data?.title,
      pack_image: data?.image,
      special_request: data?.request,
      pack_description: data?.message?.split("_")[1],
      contact_name: data?.name,
      contact_email: data?.email,
      contact_mobile: data?.contactNumber,
      recipient_name: data?.recipients_name,
      recipient_email: data?.recipients_email,
      recipient_mobile: data?.recipients_contact,
    };

    if (data?.amount === 0) {
      axiosPostWithToken(`${API.GIFT_VOUCHER_CORP_ONLY_POINTS}`, body, token)
        .then((res) => {
          // console.log(res, "points only")
          if (res.data.status_code === 200) {
            if (res?.data?.data?.booking_status === "purchased") {
              // console.log(res.data, "pay with only points api response")
              dispatch({
                type: BOOKING_STATUS,
                payload: res.data,
              });
              dispatch({
                type: VOUCHER_CHECKOUT_DETAILS,
                payload: body,
              });
            }
          }
        })
        .catch((err) => {
          dispatch({
            type: ERROR_ALERT,
            payload:
              (err && err.response && err.response.data) ||
              "Something went wrong.",
          });
        });
    } else if (isCorporateAccount === true) {
      axiosPostWithToken(`${API.GIFT_VOUCHER_CORP_PAYMENT}`, body, token)
        .then((res) => {
          if (res.data.status_code === 200) {
            const resBody = res?.data?.data;
            const paymentBody = {
              surl: `${BASE_URL}/user/payment/send-gift-corp-success/`,
              curl: `${BASE_URL}/user/payment/cancelled/`,
              furl: `${BASE_URL}/user/payment/send-gift-corp-failure/`,
              // key: res?.data?.data?.pay_mode == "CC" ? `${CC_PAYMENT_KEY}` : `${DC_PAYMENT_KEY}`, // live server payment's key
              key: `${TEST_PAYMENT_KEY}`, // test server payment's key
              phone: data?.contactNumber,
            };
            const payment_details = { ...resBody, ...paymentBody };
            localStorage.setItem("checkoutData", JSON.stringify(body));
            dispatch({
              type: VOUCHER_PAYMENT_DETAILS,
              payload: payment_details,
            });
            dispatch({
              type: VOUCHER_CHECKOUT_DETAILS,
              payload: body,
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: ERROR_ALERT,
            payload:
              (err && err.response && err.response.data) ||
              "Something went wrong.",
          });
        });
    } else if (isCorporateAccount === false) {
      axiosPostWithToken(`${API.GIFT_VOUCHER_NON_CORP_PAYMENT}`, body, token)
        .then((res) => {
          if (res.data.status_code === 200) {
            const resBody = res?.data?.data;
            const paymentBody = {
              surl: `${BASE_URL}/user/payment/send-gift-user-success/`,
              curl: `${BASE_URL}/user/payment/cancelled/`,
              furl: `${BASE_URL}/user/payment/send-gift-user-failure/`,
              // key: res?.data?.data?.pay_mode == "CC" ? `${CC_PAYMENT_KEY}` : `${DC_PAYMENT_KEY}`, // live server payment's key
              key: `${TEST_PAYMENT_KEY}`, // test server payment's key
              phone: data?.contactNumber,
            };
            const payment_details = { ...resBody, ...paymentBody };
            localStorage.setItem("checkoutData", JSON.stringify(body));
            dispatch({
              type: VOUCHER_PAYMENT_DETAILS,
              payload: payment_details,
            });
            dispatch({
              type: VOUCHER_CHECKOUT_DETAILS,
              payload: body,
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: ERROR_ALERT,
            payload:
              (err && err.response && err.response.data) ||
              "Something went wrong.",
          });
        });
    }
  };
};

export const uploadVoucherPic = (file_type, file, updatePic) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosMediaUpload(API.VOUCHER_PIC_UPLOAD, file_type, file, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          updatePic(res.data.data);
        }
      })
      .catch((err) => {
        updatePic(null);
        // console.log("err");
      });
  };
};

// get holidays section's or categories list API
export const getHolidaySections = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosGetWithToken(`${API.HOLIDAYS_CATEGORY_LIST}`, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: HOLIDAYS_CATEGORY,
            payload: res.data,
          });
          dispatch({
            type: INITIAL_HOLIDAY_CATEGORY_ID,
            payload: res.data.data[0].id,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
};

// setting category id in state
export const setCategoryId = (id) => {
  return async (dispatch) => {
    dispatch({
      type: CATEGORY_ID,
      payload: id,
    });
    localStorage.setItem("holidayCategory", id);
  };
};

// setting visas and more boolean value
export const setVisasAndMore = (boolean) => {
  return async (dispatch) => {
    dispatch({
      type: VISAS_AND_MORE,
      payload: boolean,
    });
  };
};

// setting customize holiday boolean value
export const setCustomize = (boolean) => {
  return async (dispatch) => {
    dispatch({
      type: CUSTOMIZE_HOLIDAY,
      payload: boolean,
    });
  };
};

// POST API list of holiday packages
export const getHolidayPackages = (id, page) => {
  return async (dispatch, getState) => {
    const catId = getState().holidays.cat_id;
    const body = {
      sec_id: id,
      next_page_id: page,
      page_size: 8,
    };
    axiosPost(`${API.HOLIDAYS_PACKAGES_LIST}`, body)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: HOLIDAYS_LIST,
            payload: res.data,
          });
          if (page == 1) {
            dispatch({
              type: RESET_HOLIDAY_LIST,
              payload: res.data.data.listing.results,
            });
          } else {
            dispatch({
              type: HOLIDAYS_PACKAGES_LIST,
              payload: res.data.data.listing.results,
            });
          }
        }
      })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
};

// search holiday packages by location
export const searchByLocation = (
  categoryID,
  searchInput,
  minVal,
  maxVal,
  page
) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const catId = getState().corporates.cat_id;
    const body = {
      search_term: searchInput,
      sec_id: categoryID,
      min_val: minVal,
      max_val: maxVal,
      next_page_id: page,
      page_size: 8,
    };
    axiosPostWithToken(`${API.SEARCH_HOLIDAYS_BY_LOCATION}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: HOLIDAYS_LIST,
            payload: res.data,
          });
          if (page == 1) {
            dispatch({
              type: RESET_HOLIDAY_LIST,
              payload: res.data.data.listing.results,
            });
          } else {
            dispatch({
              type: HOLIDAYS_PACKAGES_LIST,
              payload: res.data.data.listing.results,
            });
          }
        }
      })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
};

// GET list of all locations
export const getLocationsList = (categoryID) => {
  return async (dispatch, getState) => {
    axiosGet(`${API.HOLIDAYS_LOCATIONS_LIST + categoryID}/`)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: ALL_LOCATIONS_LIST,
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
};
export const getCountryList = (secId) => {
  return async (dispatch, getState) => {
    axiosGet(`${API.HOLIDAYS_COUNTRY_LIST + secId}/`)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: ALL_COUNTRY_LIST,
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
};

export const setFilterValues = (value) => {
  return async (dispatch) => {
    dispatch({
      type: HOLIDAY_FILTER_VALUES,
      payload: value,
    });
    // callback()
  };
};

export const setSearchValue = (value) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_SEARCH_VALUE,
      payload: value,
    });
  };
};

export const setPageSize = (page) => {
  // console.log(page)
  return async (dispatch) => {
    dispatch({
      type: PAGE_SIZE,
      payload: page,
    });
  };
};
// Review Post Check
export const ReviewPostCheck = (id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      pack_id: id,
    };
    axiosPostWithToken(API.HOLIDAYS_REVIEW_POST_CHECK, body, token)
      .then((res) => {
        
        dispatch({
          type: HOLIDAY_REVIEW_REFERENCE_ID,
          payload: res?.data?.data.map(e=>e.ref_id).filter(f=>f!=null),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const reviewListing = (id,page) => {
  return async (dispatch, getState) => {
    const body = {
      next_page_id: page,
      page_size: 5,
    };
    axiosPost(`${API.HOLIDAYS_REVIEW_LIST + id}/`, body)
      .then((res) => {
        dispatch({
          type: HOLIDAY_REVIEW_LIST,
          payload: res?.data?.data.review_data,
        });
        dispatch({
          type:HOLIDAY_REVIEWLIST_TOTAL_PAGE,
          payload:res?.data?.data.review_data.total_pages
        })
        dispatch({
          type:HOLIDAY_REVIEWLIST_CURRENT_PAGE,
          payload:res?.data?.data.review_data.current_page
        })
      
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const reviewStatusClearUp = () => {
  return async (dispatch) => {
    dispatch({
      type: HOLIDAY_REVIEW_POST,
      payload: null,
    });
  };
};
export const changePackageIndex = (index) => {
  return async (dispatch) => {
    dispatch({
      type: HOLIDAY_ACTIVE_PACKAGE_INDEX,
      payload: index,
    });
  };
};
export const postReview = (pkgid, cmt, str, refid) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      pack_id: pkgid,
      review_star: str,
      review_comment: cmt,
      ref_id: refid,
    };
    axiosPostWithToken(API.HOLIDAYS_REVIEW_POST, body, token)
      .then((res) => {
        dispatch({
          type: HOLIDAY_REVIEW_POST,
          payload: res?.data?.data.message,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
// GET API to get the details of individual holiday
export const getHolidayDetails = (id, loader) => {
  return async (dispatch, getState) => {
    axiosGet(`${API.HOLIDAY_PACKAGE_DETAILS_API + id}/`)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: HOLIDAY_PACKAGE_DETAILS,
            payload: res.data,
          });
          localStorage.setItem("packInfo", JSON.stringify(res?.data));
          loader();
        }
      })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
        loader();
      });
  };
};

//Get API to get the testimonials
export const getTestimonials = (id) => {
  return async (dispatch) => {
    axiosGet(`${API.HOLIDAYS_TESTIMONIALS + id}/`)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: HOLIDAY_TESTIMONIALS,
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
};

export const getFilteredHolidayDetails = (id, loader) => {
  return async (dispatch, getState) => {
    axiosGet(`${API.HOLIDAY_FILTERED_PACKAGE_DETAILS_API + id}/`)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: HOLIDAY_PACKAGE_DETAILS,
            payload: res.data,
          });
          localStorage.setItem("packInfo", JSON.stringify(res?.data));
          loader();
        }
      })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
        loader();
      });
  };
};

// on click of proceed to book dispatching data to HOLIDAY_BOOKING_INFORMATION_DATA
export const handleProceedToBook = (data) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const fromDate = getState().corporates.pacStartDate;
    const toDate = getState().corporates.pacEndDate;
    const flexiDates = getState().corporates.pacFlexibleDates;
    const body = {
      pack_id: data.pack_id,
      schedule_data:
        data?.resAPISelectedDates?.length > 0
          ? data?.resAPISelectedDates
          : {
              from: data?.resAPIStartDate,
              to: data?.resAPIEndDate,
            },
      adults: data.adultGuestCount,
      children: data.childrensGuestCount,
      infants: data.count,
      special_request: data.request,
      total_price: data.totalPrice,
      amount: Number(data.points).toFixed(2),
      points: data.points,
      contact_name: data.name,
      contact_email: data.email,
      contact_number: data.contactNumber,
      schedule_type: data?.schedule_type,
    };
    localStorage.setItem("bookData", JSON.stringify(body));
    dispatch({
      type: HOLIDAY_BOOKING_INFORMATION_DATA,
      payload: body,
    });
  };
};
export const updateBookingData = (data) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const fromDate = getState().corporates.pacStartDate;
    const toDate = getState().corporates.pacEndDate;
    const flexibleDates = getState().corporates.pacFlexibleDates;
    const booking_information_data =
      getState().holidays.booking_information_data;
    const body = {
      ...booking_information_data,
      schedule_data:
        data?.resAPISelectedDates?.length > 0
          ? data?.resAPISelectedDates
          : {
              from: data?.resAPIStartDate,
              to: data?.resAPIEndDate,
            },
      adults: data?.adults,
      children: data?.children,
      infants: data?.infants,
      contact_name: data?.contact_name,
      contact_email: data?.contact_email,
      contact_number: data?.contact_number,
      special_request: data?.special_request,
    };
    localStorage.setItem("bookData", JSON.stringify(body));
    dispatch({
      type: HOLIDAY_BOOKING_INFORMATION_DATA,
      payload: body,
    });
  };
};

export const changeDataforProceedToBook = (dataChanged, dataChangedBool) => {
  return async (dispatch, getState) => {
    dispatch({
      type: CHANGE_DATA_FOR_PROCEED_TO_BOOK,
      payload: dataChanged,
    });
    dispatch({
      type: DATA_CHANGED_BOOL_VAL,
      payload: dataChangedBool,
    });
    localStorage.setItem("dataChangedBool", JSON.stringify(dataChangedBool));
    localStorage.setItem("dataChanged", JSON.stringify(dataChanged));
  };
};

export const corpHolidayEditedName = (name, bool) => {
  return async (dispatch, getState) => {
    dispatch({
      type: EDITED_CORP_HOLIDAY_NAME,
      payload: name,
    });
    dispatch({
      type: EDITED_CORP_HOLIDAY_NAME_BOOL,
      payload: bool,
    });
  };
};

export const corpHolidayEditedNumber = (number, bool) => {
  return async (dispatch) => {
    dispatch({
      type: EDITED_CORP_HOLIDAY_NUMBER,
      payload: number,
    });
    dispatch({
      type: EDITED_CORP_HOLIDAY_NUMBER_BOOL,
      payload: bool,
    });
  };
};

// POST API to get similar holidays
export const getSimilarHolidays = (id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const packid = {
      pack_id: id,
    };
    axiosPostWithToken(`${API.SIMILAR_HOLIDAYS_API}`, packid, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: SIMILAR_HOLIDAYS,
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
};

// checkout function
// CONDITION 1: If points are greater then amount pay with points and amount will be equal to zero.
// CONDITION 2: Else pay with amount redirect's to bank payment screen (can also use points and can pay balance amount)
export const checkOut = (data, corporate, paidWithPoints, payAmount) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const fromDate = getState().corporates.pacStartDate;
    const toDate = getState().corporates.pacEndDate;
    const flexibleDates = getState().corporates.pacFlexibleDates;
    const isCorporateAccount = corporate;
    const body = {
      pack_id: data?.pack_id,
      schedule:
        data?.resAPISelectedDates?.length > 0
          ? data?.resAPISelectedDates
          : {
              from: data?.resAPIStartDate,
              to: data?.resAPIEndDate,
            },
      adults: data?.adults,
      children: data?.children,
      infants: data?.infants,
      special_request: data?.special_request,
      total_points: data?.total_points,
      amount: data?.amount,
      contact_name: data?.contact_name,
      contact_email: data?.contact_email,
      contact_number: data?.contact_number,
      pay_mode: data?.amount > 0 ? data?.pay_mode : "",
    };

    if (data?.amount === 0) {
      axiosPostWithToken(`${API.CORP_BOOKING_WITH_POINTS}`, body, token)
        .then((res) => {
          if (res.data.status_code === 200) {
            if (res.data.data.serializer.booking_status === "booked") {
              dispatch({
                type: BOOKING_STATUS,
                payload: res.data,
              });
              paidWithPoints();
            }
          }
        })
        .catch((err) => {
          dispatch({
            type: ERROR_ALERT,
            payload:
              (err && err.response && err.response.data) ||
              "Something went wrong.",
          });
        });
    } else if (isCorporateAccount === true) {
      axiosPostWithToken(`${API.HOLIDAY_CORPORATE_PAYMENT}`, body, token)
        .then((res) => {
          if (res.data.status_code === 200) {
            const resBody = res.data.data;
            const paymentBody = {
              surl: `${BASE_URL}/user/payment/holiday-corp-success/`,
              curl: `${BASE_URL}/user/payment/cancelled/`,
              furl: `${BASE_URL}/user/payment/holiday-corp-failure/`,
              // key: res?.data?.data?.pay_mode == "CC" ? `${CC_PAYMENT_KEY}` : `${DC_PAYMENT_KEY}`, // live server payment's key
              key: `${TEST_PAYMENT_KEY}`, // test server payment's key
              phone: data?.contact_number,
            };
            const payment_details = { ...resBody, ...paymentBody };
            const checkout_details = { ...body, ...payment_details };
            dispatch({
              type: CHECKOUT_DETAILS,
              payload: payment_details,
            });
            payAmount(checkout_details);
          }
        })
        .catch((err) => {
          dispatch({
            type: ERROR_ALERT,
            payload:
              (err && err.response && err.response.data) ||
              "Something went wrong.",
          });
        });
    } else if (isCorporateAccount === false) {
      axiosPostWithToken(`${API.HOLIDAY_NON_CORPORATE_PAYMENT}`, body, token)
        .then((res) => {
          if (res.data.status_code === 200) {
            const resBody = res.data.data;
            const paymentBody = {
              surl: `${BASE_URL}/user/payment/holiday-user-success/`,
              curl: `${BASE_URL}/user/payment/cancelled/`,
              furl: `${BASE_URL}/user/payment/holiday-user-failure/`,
              // key: res?.data?.data?.pay_mode == "CC" ? `${CC_PAYMENT_KEY}` : `${DC_PAYMENT_KEY}`, // live server payment's key
              key: `${TEST_PAYMENT_KEY}`, // test server payment's key
              phone: data?.contact_number,
            };
            const payment_details = { ...resBody, ...paymentBody };
            const checkout_details = { ...body, ...payment_details };
            dispatch({
              type: CHECKOUT_DETAILS,
              payload: payment_details,
            });
            payAmount(checkout_details);
          }
        })
        .catch((err) => {
          dispatch({
            type: ERROR_ALERT,
            payload:
              (err && err.response && err.response.data) ||
              "Something went wrong.",
          });
        });
    }
    return;
  };
};

export const availabilityRequest = (
  data,
  corporate,
  handleSuccess,
  payAmount
) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const fromDate = getState().corporates.pacStartDate;
    const toDate = getState().corporates.pacEndDate;
    const flexibleDates = getState().corporates.pacFlexibleDates;
    const isCorporateAccount = corporate;
    const body = {
      pack_id: data?.pack_id,
      schedule:
        data?.resAPISelectedDates?.length > 0
          ? data?.resAPISelectedDates
          : {
              from: data?.resAPIStartDate,
              to: data?.resAPIEndDate,
            },
      adults: data?.adults,
      children: data?.children,
      infants: data?.infants,
      special_request: data?.special_request,
      contact_name: data?.contact_name,
      contact_email: data?.contact_email,
      contact_number: data?.contact_number,
      amount: data?.amount,
    };
    if (isCorporateAccount) {
      axiosPostWithToken(
        `${API.HOLIDAYS_AVAILABILITY_REQUEST_CORP}`,
        body,
        token
      )
        .then((res) => {
          if (res.data.status_code === 200) {
            if (res.data.data.serializer.booking_status === "requested") {
              handleSuccess();
            }
          }
        })
        .catch((err) => {
          dispatch({
            type: ERROR_ALERT,
            payload:
              (err && err.response && err.response.data) ||
              "Something went wrong.",
          });
        });
    } else {
      axiosPostWithToken(
        `${API.HOLIDAYS_AVAILABILITY_REQUEST_NON_CORP}`,
        body,
        token
      )
        .then((res) => {
          if (res.data.status_code === 200) {
            if (res.data.data.serializer.booking_status === "requested") {
              handleSuccess();
            }
          }
        })
        .catch((err) => {
          dispatch({
            type: ERROR_ALERT,
            payload:
              (err && err.response && err.response.data) ||
              "Something went wrong.",
          });
        });
    }
  };
};

export const setSelectedMonths = (array) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SELECTED_MONTHS,
      payload: array,
    });
  };
};

export const setSuccess = (bool) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_SUCCESS,
      payload: bool,
    });
  };
};

export const submitInternationalRequest = (body, handleSuccess) => {
  return async (dispatch, getState) => {
    axiosPost(`${API.INTERNATIONAL_REQUEST}`, body)
      .then((res) => {
        if (res.data.status_code === 201) {
          handleSuccess();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const submitCustomizeRequest = (body, handleSuccess) => {
  return async (dispatch, getState) => {
    axiosPost(`${API.CUSTOMIZE_REQUEST}`, body)
      .then((res) => {
        if (res.data.status_code === 201) {
          handleSuccess();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// GET API to get nearby experiences
export const getNearByExperiences = (id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const packid = {
      pack_id: id,
    };
    axiosGetWithToken(`${API.HOLIDAYS_NEARBY_PACKAGES + id}/`, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: NEAR_BY_EXPERIENCES,
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
};
// GET API to get more holidays
export const getMoreHolidays = (id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosGetWithToken(`${API.HOLIDAYS_NEARBY_PACKAGES + id}/?more=true`, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: MORE_HOLIDAYS,
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
};

export const setRouteValue = (message) => {
  localStorage.setItem("holiday-section", message);
  return async (dispatch) => {
    dispatch({
      type: ROUTE_MESSAGE,
      payload: message,
    });
  };
};

export const setScroll = (value) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_SCROLL,
      payload: value,
    });
  };
};
