import React, {useEffect} from 'react'
import bookingImg from '../../../assets/images/dummy/booking.png';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import * as ProfileAction from '../../../redux/actions/profileAction';
import { ScrollToTop } from '..';

function MyBookings() {
    const dispatch = useDispatch()
    const history = useHistory();
    const BOOKING_DETAILS = useSelector((state) => state.userProfile.booking_details.data);
    const userInfo = JSON.parse(localStorage.getItem("userData"));
    const isCorporateAccount = userInfo?.user_details?.corporate_account;
    
    useEffect(() => {
        if(isCorporateAccount){
            dispatch(ProfileAction.getBookings())
        }
        else {
            dispatch(ProfileAction.getNonCorporateBookings());
        }
        if(!userInfo){
            history.push('/login')
        }
    }, []);

    const goToDetails = (data) => {
        if(data?.pack_image){
        history.push(`/booking-details`, data);
        window.scrollTo(0,0);
        }
    }

    return (
        <div className="container">
        <div className="my-bookings">
            <div className="row">
            {
                BOOKING_DETAILS && BOOKING_DETAILS?.length > 0 && BOOKING_DETAILS.map((item) => (
                    <div className="col-12 col-md-6">
                        <div className="card-wrapper d-flex cursor-pointer" onClick={() => goToDetails(item)}>
                            {item?.pack_image&&
                            <div className="img-holder">
                                <img src={item.pack_image} alt={item.pack_title} />
                            </div>}
                            <div className={`info ${item.pack_image?"":"internal-info"}`}>
                                <h5 title={item.pack_title}>{item.pack_title}</h5>
                                {item?.location_country ? 
                                <p title={item.location_country}>{item?.gift_voucher == true ? "" : item.location_country}</p>:
                                <p title={item.address}>{item?.gift_voucher == true ? "" : item.address}</p>
                            }
                                <div className="line"></div>
                                <p className="status">Booking status: <span className={item?.txn_status === 'failure' ?"failure-text":''} style={{color: item?.txn_status === 'failure' ? 'red':''}}>{item?.txn_status === 'failure'?"Failed":item.booking_status}</span> 
                                </p>
                                {
                                    item?.offline_payment && <p><b>Mode of payment :</b> <span style={{color: 'green', fontWeight: '700' }}>Offline</span><br></br></p> 
                                }
                                {
                                    item?.offline_payment ? <br></br> : null 
                                }
                                <p> {item.ref_id ? <b>Ref. ID :</b> : null} {item.ref_id}</p>
                                <p> {item.txnid ? <b>TxnID :</b> : null} {item.txnid}</p>
                                {
                                    item.txn_status  ? <p><b>Txn status :</b> <span style={{color: item?.txn_status === 'success' ? 'green' : 'red', fontWeight: '700', textTransform: 'capitalize' }}>{item.txn_status}</span></p> : null
                                }
                                <p className="price mb-0"> 
                                {
                                    (item.total_points === "" || item.total_points === 0 || item?.total_points === undefined) ?  null : <span>{item.total_points} pts </span> 
                                }
                                {
                                   (item.amount_paid === 0 || item.amount_paid == 0.00 || item.amount_paid === "" || item.total_points === "" || item.total_points === 0 || item?.total_points === undefined) ? null : <span>and </span>
                                }
                                {
                                    (item.amount_paid === 0 || item.amount_paid == 0.00 || item.amount_paid === "" ) ? null : <span>Rs {item.amount_paid}</span>
                                }
                                 
                                </p>
                            </div>
                        </div>
                    </div>
                ))
            }
            </div>
        </div>

        </div>
    )
}

export default MyBookings
