export const SUCCESSFUL_LOGIN = "Successfully logged in";
export const SUCCESSFUL_LOGIN_WELCOME_MSG = "Welcome back to Hammock";
export const SUCCESSFUL_SIGNUP = "Successfully Signed Up!";
export const SUCCESSFUL_SIGNUP_WELCOME_MSG = "Welcome to Hammock";
export const REQUEST_SUBMITTED = "Gift request submitted, please check your Email"
export const CONNECT_TEAM_MESSAGE = "Our team will connect with you shortly"
export const INFORMATION_SUBMITTED = "Successfully sent"
export const NUMBER_OF_GUESTS = "Please enter number of guests";
export const CHILDREN_INFANT_ERROR = "For Infants/children at least 1 adult is required";
export const PEOPLE = "Number of guests";
export const SELECTED_DATE = "Selected date";
export const SPECIFIC_REQUEST = "Your Request";