import React, {useEffect} from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AuthIndex from "../components/auth/index";
import HomeLayout from "./home";
import CorporateDetails from "../components/corporates/packageDetails";
import CorporateGiftVouchers from "../components/corporates/giftDetails";
import MyProfile from "../components/common/profile/";
import Checkout from "../components/common/payment/checkout";
import CheckoutDetails from "../components/common/payment/checkoutDetails";
import PaymentSuccess from "../components/common/payment/paymentSuccess";
import PaymentFailure from "../components/common/payment/paymentFailure";
import PaymentCancelled from "../components/common/payment/paymentCancelled";
import GiftCheckout from "../components/corporates/giftCheckout";
import BookingDetails from "../components/common/profile/bookingDetails";
import Buying from "../components/holidays/sendGift/buying";
import VoucherCheckout from "../components/holidays/sendGift/voucherCheckout";
import TermsAndConditions from "../components/common/termsAndConditions"
import ContactUs from "../components/common/contactUs";
import EventDetails from "../components/events/eventDetails"
import LaunchingSoon from "../components/common/launcingSoon";
import EventCheckout from "../components/events/eventCheckout";
import EventCheckoutDetails from "../components/events/eventCheckoutDetails"
import RefundAndCancellations from "../components/common/refundAndCancellations"
import PrivacyPolicy from "../components/common/privacyPolicy";
import EventsPaymentSuccess from "../components/events/paymentStatus/paymentSuccess"
import GiftVoucherPaymentSuccess from "../components/holidays/sendGift/paymentStatus/paymentSuccess"
import VirtualEvents from "../components/homepage/virtualExperience";
import HolidayDetails from "../components/holidays/holidayDetails";
import HolidayCheckout from "../components/holidays/holidayCheckout";
import HolidayCheckoutDetails from "../components/holidays/holidayCheckoutDetails";
import HolidayInternational from "../components/holidays/international";
import ExperienceDetails from "../components/experiences/experienceDetails";
import ExperienceCheckout from "../components/experiences/experienceCheckout";
import ExperienceCheckoutDetails from "../components/experiences/experienceCheckoutDetails";
import StoriesDetails from "../components/myFamilyFirst/stories_details";
import WeddingStoryDetails from "../components/weddings/stories-details"
import SearchResultsPage from "../components/common/searchResults/index";
import Careers from "../components/common/careers";
import JobDiscription from "../components/common/careers/jobdiscription";
import InstantCheckout from "../components/common/payment/instantCheckout";
import AboutUs from "../components/common/about_us";
// import NewExpDetails from "../components/newExp/newExpDetails";
// import NewDetails from "../components/newExp/Holidays/holidayDetails";

const Main = () => {
  useEffect(()=>{
    localStorage.setItem('urlHistory', null);
    localStorage.setItem('prevUrl', '');
  }, []);
  return (
    <BrowserRouter>
      <div className="App">
        <Switch>
          <Route exact path="/login" component={AuthIndex} />
          <Route exact path="/signup" component={AuthIndex} />
          <Route exact path="/about-us" component={AboutUs} />
          <Route exact path="/booking-details" component={BookingDetails} />
          <Route exact path="/contact-us" component={ContactUs} />
          <Route exact path="/events/event-details/:id" component={EventDetails} />
          <Route exact path="/events/checkout" component={EventCheckout} />
          <Route exact path="/events/checkout-details" component={EventCheckoutDetails} />
          <Route exact path="/search" component={SearchResultsPage} />

          <Route exact path="/holidays/holiday-details/:id" component={HolidayDetails} />
          <Route exact path="/holidays/international/:id" component={HolidayInternational} />
          <Route exact path="/holidays/checkout" component={HolidayCheckout} />
          <Route exact path="/holidays/checkout-details" component={HolidayCheckoutDetails} />

          <Route exact path="/experiences/experience-details/:id" component={ExperienceDetails} />
          <Route exact path="/experiences/checkout" component={ExperienceCheckout} />
          <Route exact path="/experiences/checkout-details" component={ExperienceCheckoutDetails} />

          <Route exact path="/my-family-first/stories-details/:id" component={StoriesDetails} />
          <Route exact path="/my-family-first/honeymoon/stories-details/:id" component={StoriesDetails} />
          <Route exact path="/my-family-first/anniversary/stories-details/:id" component={StoriesDetails} />
          <Route exact path="/weddings/stories-details/:id" component={WeddingStoryDetails} />

          <Route exact path="/launching-soon" component={LaunchingSoon} />
          <Route path="/careers" component={Careers} />
          <Route path="/job-description" component={JobDiscription} />
          <Route path="/user" component={MyProfile} />
          <Route
            exact
            path="/corporates/package-details/:id"
            component={CorporateDetails}
          />
          <Route
            exact
            path="/corporates/gift-voucher/:id"
            component={CorporateGiftVouchers}
          />
          <Route
            exact
            path="/corporate/checkout"
            component={Checkout}
          />
          <Route
            exact
            path="/buying"
            component={Buying}
          />
          <Route
            exact
            path="/voucherCheckout"
            component={VoucherCheckout}
          />
          <Route exact path="/payment-successful" component={PaymentSuccess} />
          <Route exact path="/payment-failure" component={PaymentFailure} />
          <Route exact path="/payment-cancelled" component={PaymentCancelled} />

          {
            // event points redeem routes
            <Route exact path="/events/payment-successful" component={EventsPaymentSuccess} />
          }
          <Route exact path="/gift-voucher/payment-successfull" component={GiftVoucherPaymentSuccess} />

          <Route
            exact
            path="/corporate/checkout-details/:name"
            component={CheckoutDetails}
          />
          <Route
            exact
            path="/checkout-details/:id"
            component={InstantCheckout}
          />
          <Route
            exact
            path="/payment"
            component={InstantCheckout}
          />
          <Route exact path="/gift-checkout" component={GiftCheckout} />
          <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
          <Route exact path="/refund-and-cancellations" component={RefundAndCancellations} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/virtual-interactive-experiences" component={VirtualEvents} />
          <Route path="/" component={HomeLayout} />
          
        </Switch>
      </div>
    </BrowserRouter>
  );
};

export default Main;
