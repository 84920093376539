import React, { useState } from "react";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import WhatsappLogo from "../../../assets/images/common/whatsapp_logo.svg";
import { testEmail } from "../../../utils/common";
import SubscripeModel from "../subscripeModel";
const ItineraryEnquiryForm = ({ packageType, isFromExperience,isInternational,showForm }) => {
  const [showModal, setShowModal] = useState(false);
  const [state, setState] = useState({
    name: "",
    email: "",
    mobile: "",
    subscribeemail: "",
  });

  const handleChange = (key, value) => {
    setState(() => ({
      ...state,
      [key]: value.target.value,
    }));
  };
  const [error, seterror] = useState({
    name: false,
    email: false,
    mobile: false,
    valid_email: false,
    subscribeemail: false,
    subscribevalid_email: false,
  });

  const handleSendRequest = (e) => {
    e.preventDefault();
    if (!state.name || state.name === "") {
      seterror({ name: true });
    } else if (!state.email || state.email === "") {
      seterror({ email: true });
    } else if (!testEmail(state.email)) {
      seterror({ valid_email: true });
    } else if (
      (state.mobile.startsWith("+91") && state.mobile.length !== 13) ||
      state.mobile == "" ||
      state.mobile.length < 10 ||
      state.mobile.length > 13
    ) {
      seterror({ mobile: true });
    } else {
      seterror({ mobile: false });
    }
  };
  return (
    <>
      {packageType&&!showForm && (
        <>
       <div className="login-holder d-none d-md-block contact-holder ">
       <h5>Speak to our Expert</h5>
      
        <div className="line"></div>
     
       
        <div className="contact-card mb-3 d-flex align-items-center justify-content-between">
          <div>
            <p>
              {isInternational ? 
              <a href="tel:+91 90082 53000">+91 90082 53000</a> :
              <a href="tel:+919902064657">+91 9902064657</a> }
            </p>
            <p>Our number</p>
          </div>
          <div>
            <a href={isInternational ?"https://wa.me/+919008253000":"https://wa.me/+919902064657"} rel="noreferrer" target="_blank">
              <img src={WhatsappLogo} alt="WhatsappLogo" />
            </a>
          </div>
        </div>
      
      </div>
        </>
      )}
      {packageType&&!showForm ? (
        <div className="itinerary-enquiry-holder">
          <p className="tittle">To know more about our offers</p>

          <button onClick={()=>setShowModal(true)} className="btn enquire w-100">
            Subscribe
          </button>
        </div>
      ):null}
         <SubscripeModel showModal={showModal} setShowModal={setShowModal} />
    </>
  );
};

export default ItineraryEnquiryForm;
