import { API } from "../../config/api";
import {
    axiosPost
} from "../../services/apiServices"

export const visaCallbackRequest = (body, handleSuccess, handleErrors) => {
    return async () => {
        axiosPost(API.VISA_CALLBACK, body)
            .then((res) => {

                if (res.data.status_code === 201) {
                    handleSuccess();
                }
                if (res.data.status_code === 400) {
                    handleErrors(res.data.data.message)
                }
            })
            .catch((err) => {
                handleErrors(err.response.data?.data?.message);
            });
    };
};