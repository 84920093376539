import React from 'react'
import {CUSTOMIZE_DATA} from "../../../resources/holidaysData"

function customizeCards() {
    return (
        <div>
        <div className="giftCard-holder customcard-holder">
        {
          CUSTOMIZE_DATA && CUSTOMIZE_DATA.length > 0 && CUSTOMIZE_DATA.map((item) => (
              <div className="giftCard">
              <div className="row overflow-hidden w-100-vw justify-content-center" >
                  <div className="col-12 col-lg-6 col-md-6 pr-lg-0">
        
                      <div>
                          <div className="giftCard-img d-none d-md-block">
                          <img src={item?.image} className="img-fluid" />
                          </div>
                          <div className="giftCard-img d-block d-md-none">
                          <img src={item?.image} className="img-fluid" style={{borderRadius: '0px'}}/>
                          </div>
                      </div>
                  </div>
                  <div className="col-12 col-lg-6 col-md-6 pr-lg-0">
                      <div className="giftCard-content">
                          <div className="title">
                              <p>{item?.title}</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          ))
        }
            
        </div>
        </div>
    )
}

export default customizeCards
