import { useSnackbar } from "notistack";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  NavLink, useHistory
} from "react-router-dom";
import "../../../assets/css/partials/common/userProfile.scss";
import closeIcon from "../../../assets/images/common/close-icon.svg";
import EditImage from "../../../assets/images/common/edit-image.svg";
import Logo from "../../../assets/images/common/logo.svg";
import banner from "../../../assets/images/common/profile-banner.webp";
import CorporatesBanner from "../../../assets/images/home/Converted/corporate-banner.webp";
import CorporatesMobileBanner from "../../../assets/images/home/Converted/corp_mobile_banner.webp";
import ProfileEdit from "../../../assets/images/common/profile-edit.svg";
import vector1 from "../../../assets/images/common/Vector1.svg";
import vector2 from "../../../assets/images/common/Vector2.svg";
import * as ProfileAction from "../../../redux/actions/profileAction";
import * as ExperienceAction from '../../../redux/actions/experiencesAction';
import * as HolidaysAction from '../../../redux/actions/holidaysActions';
import * as CorporateAction from '../../../redux/actions/corporatesAuthActions';
import Footer from "../footer";
import ProfileTabHolder from "./tabHolder";
import MyProfile from "./myProfile";
import MyBookings from "./myBookings";
import { ScrollToTop } from "..";

function UserProfile({fromNav,fromCheckout}) {
  const history = useHistory();
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const USER_DETAILS = useSelector(
    (state) => state.userProfile?.profile_details?.data
  );
  const pacStartDate = useSelector( (state) => state.corporates.pacStartDate);
  const pacEndDate = useSelector( (state) => state.corporates.pacEndDate);
  const pacFlexibleDates = useSelector( (state) => state.corporates.pacFlexibleDates);
  const [file, setFile] = useState("");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [state, setState] = useState({
    full_name: USER_DETAILS?.user?.full_name,
  });
  const loginUserData = useSelector((state) => state.auth.login_user_otp);
  const googleLogin = useSelector((state) => state.auth.login_with_google);
  const userDetails = JSON.parse(localStorage.getItem("userData"));
  const isCorporateAccount = userDetails?.user_details?.corporate_account;
  const isSocialUser = userDetails?.user_details?.social_account;
  useEffect(() => {
   
    setState({
      full_name: USER_DETAILS?.user?.full_name,
    });


  }, [USER_DETAILS?.user?.full_name]);
  const isLoggedIn = () => {
    return (
      (Object.keys(loginUserData).length !== 0 &&
        loginUserData.constructor === Object) ||
      (Object.keys(googleLogin).length !== 0 &&
        googleLogin.constructor === Object)
    );
  };
useEffect(()=>{
  if(isLoggedIn()||userDetails?.user_details){
  if (isCorporateAccount) {
    dispatch(ProfileAction.getProfileDetails());
  }
  else if(isSocialUser || !isCorporateAccount){
    dispatch(ProfileAction.getNonCorporateProfileDetails());
  }
}
},[])
  const [disabled, setDisabled] = useState(true);
  const [hide, setHide] = useState(false);
  // const inputRef = useRef();

  const handleEditClick = () => {
    setDisabled(!disabled);
    setHide(!hide);
    // inputRef.current.focus();
  };
  const handleChange = (key, value) => {
    setState({
      ...state,
      [key]: value.target.value,
    });
  };

  // update name
  const handleDone = (e) => {
    e.preventDefault();
    if (!state.full_name || state.full_name === "" || state.full_name.trim()==="") {
      enqueueSnackbar("Please enter your name", {
        variant: "error",
      });
    }
    else if (isCorporateAccount) {
      localStorage.setItem("full_name", state.full_name);
      dispatch(ProfileAction.updateName(state, handleNameSuccess, handleErrors));
      setHide(!hide);
      setDisabled(!disabled);
    }
    else {
      localStorage.setItem("full_name", state.full_name);
      dispatch(ProfileAction.updateNonCorpUserDetails(state, handleNameSuccess, handleErrors));
      setHide(!hide);
      setDisabled(!disabled);
    }
  };
  const handleNameSuccess = () => {
    enqueueSnackbar("Name updated successfully", {
      variant: "success",
    });
    let updatedDatabookData = JSON.parse(localStorage.getItem("bookData"))
    if(updatedDatabookData){
      const updatedData = {
        ...updatedDatabookData,
        resAPIStartDate: pacStartDate, 
        resAPIEndDate: pacEndDate, 
        resAPISelectedDates: pacFlexibleDates, 
        contact_name: state.full_name,

      }
      localStorage.setItem("bookData", JSON.stringify(updatedData))
      dispatch(ExperienceAction.updateBookingData(updatedData));
      dispatch(HolidaysAction.updateBookingData(updatedData));
      dispatch(CorporateAction.updateBookingData(updatedData));
    }
    refreshProfileDetails();
  };

  const handleSuccess = () => {
    enqueueSnackbar("Image updated successfully", {
      variant: "success",
    });
    refreshProfileDetails();
  };

  const handleErrors = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
    });
  };
  const refreshProfileDetails = () => {
    if (isCorporateAccount) {
      dispatch(ProfileAction.getProfileDetails());
    }
    else {
      dispatch(ProfileAction.getNonCorporateProfileDetails())
    }
  };

  const uploadProfileImage = (event) => {
    dispatch(
      ProfileAction.uploadProfilePic("image", event.target.files[0], updatePic)
    );
  };
const goBack=()=>{
if(fromCheckout){
  history.push("/")
}else{
  history.goBack()
}
}
  const updatePic = (data) => {
    if (data?.url) {
      if (isCorporateAccount) {
        dispatch(
          ProfileAction.updateUserProfilePic(
            { image: data.url },
            handleSuccess,
            handleErrors
          )
        );
        setState({ ...state, profile_pic: data.url });
        setFile("");
      }
      else {
        dispatch(
          ProfileAction.updateNonCorpUserDetails
          (
            { image: data.url },
            handleSuccess,
            handleErrors
          )
        );
        setState({ ...state, profile_pic: data.url });
        setFile("");
      }

    } else {
      // disaptch error
    }
  };

  return (
    <div>
      <div className="banner-holder userProfile-holder">
        <div className="header-holder">
          <Link to={isCorporateAccount? '/corporates' : '/'}>
            <div className={(window.location.pathname === '/user/profile' || window.location.pathname === '/user/bookings' )? 'd-none d-md-block' : 'logo d-none d-md-block'}>
              <img src={Logo} alt="hammock-logo" />
            </div>
          </Link>
          {!fromNav ? 
          <div className="cta-buttons d-block mobi-profile-goBack">
            <a style={{ cursor: "pointer" }}
              className="mr-4"
              onClick={goBack}   >
              <img src={closeIcon} alt="closeIcon" />
            </a>
          </div> : null }
        </div>
        {/* Banner images */}
        <div className="banner">
          {fromNav ?<>
          <img src={CorporatesBanner} className="img-fluid d-none d-md-block" alt="CorporatesBanner" />
          <img src={CorporatesMobileBanner} className="img-fluid d-block d-md-none" alt="CorporatesBanner" />
          </>
          :
          <img src={banner} className="img-fluid" alt="" /> 
          }
        </div>

        {/* Banner text */}

        <div className="profile-holder">
        {!fromCheckout&&(fromNav && userData? 
          <div className="image-wrapper">
            <div className="corp-logo">
                <img src={userData && userData.organisation?.logo} alt={USER_DETAILS?.user?.full_name} />
            </div>
          </div> :
          <div className="image-wrapper">
            {USER_DETAILS &&
              (USER_DETAILS.user.image === "" ||
                USER_DETAILS.user.image === null) ? (
              <div className="character-gradient">
                <div className="image-char">
                  <p>{USER_DETAILS?.user?.full_name.charAt(0).toUpperCase()}</p>
                </div>
              </div>
            ) : (
              <div className="profile-image">
                <img src={USER_DETAILS?.user?.image} alt={USER_DETAILS?.user?.full_name} />
              </div>
            )}
            <div class="edit-image">
              <div>
                <input
                  className="upload-file"
                  type="file"
                  onChange={uploadProfileImage}
                />
                <img src={EditImage} alt="EditImage" />
              </div>
            </div>
          </div> )}

          {!fromCheckout&&   <div className="fullName">
            <form>
              <div className="form-group">
                <input
                  type="text"
                  class="form-control"
                  value={state.full_name}
                  onChange={(value) => handleChange("full_name", value)}
                  ref={ref => ref && ref.focus()}
                  onFocus={(e) => e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
                  disabled={disabled}
                  onKeyPress={(event) => {
                    if (!/[a-zA-Z\s]/.test(event.key)) {
                        event.preventDefault();
                    }
                    if(event.key === "Enter"){
                      event.preventDefault()
                      event.target.blur()
                      handleDone(event)
                  }
                  }}
                />
              </div>
              { hide ? (
                <div className="edit-icon">
                  <button
                    type="button"
                    className="btn editName-btn"
                    onClick={handleDone} >
                    Done
                  </button>
                </div>
              ) : (
                <div className="edit-icon">
                  <button
                    type="button"
                    className="btn"
                    onClick={handleEditClick} >
                    <img src={ProfileEdit} alt="ProfileEdit" />
                  </button>
                </div>
              )}
            </form>
          </div>
}
{
  fromCheckout&&<p className="d-flex align-items-center justify-content-center checkout-title">Checkout</p>
}
        </div>

        <div class="vector-holder vector1">
          <img src={vector1} className="img-fluid" alt="vector1" />
        </div>
        <div class="vector-holder vector2">
          <img src={vector2} className="img-fluid" alt="vector2" />
        </div>
      </div>
      {
        !fromNav&&!fromCheckout ? <ProfileTabHolder  profilePage={USER_DETAILS}/> : null
      }
      
      {/* <Switch>
        <Route exact path="/user/profile">
          <MyProfile profilePage={USER_DETAILS} />
        </Route>
        <Route exact path="/user/bookings">
          <MyBookings />
        </Route>
      </Switch> */}
      <ScrollToTop />
      {
        !fromNav&&!fromCheckout ? <Footer /> : null
      }
    </div>
  );
}

export default UserProfile;
