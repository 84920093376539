import React, { useState, useEffect } from 'react';
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import EditIcon from '../../../assets/images/common/feather_edit.svg';
import AddIcon from '../../../assets/images/common/feather_plus.svg';
import CloseIcon from '../../../assets/images/common/close-modal.svg';
import * as ProfileAction from '../../../redux/actions/profileAction';
function EditAddressModal({ addressDetails }) {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [showAddress, setAddressShow] = useState(false);
    const handleAddressClose = () => setAddressShow(false);
    const handleAddressShow = () => setAddressShow(true);

    const userInfo = JSON.parse(localStorage.getItem("userData"));
    const isCorporateAccount = userInfo?.user_details?.corporate_account;


    const [state, setState] = useState({
        title: addressDetails.title,
        street: addressDetails.street,
        city: addressDetails.city,
        state: addressDetails.state,
        pincode: addressDetails.pincode,
    });

    const handleChange = (key, value) => {
        setState({
            ...state,
            [key]: value.target.value,
        });
    };
    const [error, seterror] = useState({
        title: false,
        street: false,
        city: false,
        state: false,
        pincode: false,
    })

    const updateAddress = (e) => {
        e.preventDefault();
        if (!state.title || state.title === "") {
            seterror({ title: true })
        }
        else if (!state.street || state.street === "") {
            seterror({ street: true })
        }
        else if (!state.city || state.city === "") {
            seterror({ city: true })
        }
        else if (!state.state || state.state === "") {
            seterror({ state: true })
        }
        else if (!state.pincode || state.pincode === "") {
            seterror({ pincode: true })
        }
        else {
            dispatch(ProfileAction.updateUserAddress(state, addressDetails.id, handleSuccess, handleErrors));
            handleAddressClose()
        }
    }

    const deleteAddress = () => {
        dispatch(ProfileAction.deleteAddress(addressDetails.id,handleDeleteSuccess,handleErrors));
        handleAddressClose()
    }

    const handleSuccess = () => {
        enqueueSnackbar("Address updated successfully", {
            variant: "success",
        });
        refreshProfileDetails();
    };

    const handleErrors = (message) => {
        enqueueSnackbar(message, {
            variant: "error",
        });
    };

    const handleDeleteSuccess = () => {
        enqueueSnackbar("Address deleted successfully", {
            variant: "success",
        });
        refreshProfileDetails();
    };

    const refreshProfileDetails = () => {
        if(isCorporateAccount){
            dispatch(ProfileAction.getProfileDetails());
        }
        else {
            dispatch(ProfileAction.getNonCorporateProfileDetails())
        }
    }
    return (
        <div>
            <div className="cursor-pointer" onClick={handleAddressShow}>
                <img src={EditIcon} alt="" />
            </div>
            <Modal className="custom-modal mob-custom-modal" show={showAddress} onHide={handleAddressClose}>
                <Modal.Header>
                    <Modal.Title>Editing an address</Modal.Title>
                    <div className="cursor-pointer" onClick={handleAddressClose}>
                        <img src={CloseIcon} alt="" />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div class="form-group custom-input">
                            <input type="text" className="form-control" value={state.title} name="title" onChange={(value) => handleChange("title", value)} 
                            onKeyPress={(event) => {
                                if (!/[a-zA-Z\s]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            />
                            <label>Name of address (Ex: Home or Office)</label>
                            {error.title === true ? <p className="errorMsg">Name of address required</p> : null}
                        </div>
                        <div class="form-group custom-input">
                            <textarea type="text" className="form-control" value={state.street} onChange={(value) => handleChange("street", value)} ></textarea>
                            <label>Street</label>
                            {error.street === true ? <p className="errorMsg">Street required</p> : null}
                        </div>
                        <div class="form-group custom-input d-flex">
                            <div className="mr-2">
                                <input type="text" className="form-control" value={state.city} onChange={(value) => handleChange("city", value)} 
                                onKeyPress={(event) => {
                                    if (!/[a-zA-Z\s]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                />
                                <label>City</label>
                                {error.city === true ? <p className="errorMsg">City required</p> : null}
                            </div>
                            <div>
                                <input type="text" className="form-control" value={state.state} onChange={(value) => handleChange("state", value)} 
                                onKeyPress={(event) => {
                                    if (!/[a-zA-Z\s]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                />
                                <label>State</label>
                                {error.state === true ? <p className="errorMsg">State required</p> : null}
                            </div>
                            <div className="ml-2">
                                <input type="text" className="form-control" value={state.pincode} onChange={(value) => handleChange("pincode", value)}
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }} 
                                
                                />
                                <label>Pincode</label>
                                {error.pincode === true ? <p className="errorMsg">Enter a valid Pincode</p> : null}
                            </div>
                        </div>
                        <div className="text-right mt-3">
                            <Button className="btn btn-delete" onClick={deleteAddress}>
                                Delete
                            </Button>
                            <Button className="btn btn-done ml-3" onClick={updateAddress}>
                                Update
                             </Button>

                        </div>
                    </form>
                </Modal.Body>

            </Modal>
        </div>
    )
}

export default EditAddressModal
