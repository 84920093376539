import React, { useState, useRef, useEffect } from 'react';
import { useHistory, withRouter } from "react-router-dom";
import moment from 'moment';
import '../../../assets/css/partials/common/bookingDetails.scss';
import Header from '../header';
import Footer from '../footer';
import backIcon from "../../../assets/images/common/back-icon.svg";
import { useSelector } from "react-redux";
import RecipientDetailsHolder from './recipientDetailsHolder';
import ContactDetailsCard from '../../common/contactDetailsCard';
import { Helmet } from 'react-helmet';
import DatePopup from '../../common/datePopup';
import HolidayHeader from '../holidayHeader';

function BookingDetails(data) {
    const isCorporate = data?.location?.state?.corporate;
    const history = useHistory();
    const bookingDetails = data?.location?.state;

    const isGiftVoucher = bookingDetails && bookingDetails?.gift_voucher;
    const loginUserData = useSelector((state) => state.auth.login_user_otp);
    const [showModal, setShowModal] = useState(false)
    const wrapperRef = useRef(null);
    useModalOutsideClose(wrapperRef);

    function useModalOutsideClose(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setShowModal(false)
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    };

    const getGuestCount = (adult, kids, infant)=>{
        
        let tmpGuestsArray = [];
        if(adult>0){
            tmpGuestsArray.push(`${adult} adult${adult>1?'s':''}`);
        }
        if(kids>0){
            tmpGuestsArray.push(`${kids} kid${kids>1?'s':''}`);
        }
        if(infant>0){
            tmpGuestsArray.push(`${infant} infant${infant>1?'s':''}`);
        }
        return tmpGuestsArray.join(', ');
    };

    return (
        <>
        <Helmet>
          <title>Booking Details - Hammock Holidays</title>
          <meta name="description" content="Gift memorable Experiences Make a gift with us for your loved ones" />
        </Helmet>
        <div className="booking-details-page">
            <div className={Object.keys(loginUserData).length !== 0 &&
                loginUserData.constructor === Object ? 'innerPage-header hide-header-mobi' : 'innerPage-header hide-header-mobi pre-login-header'}>
             <div className="d-lg-none">
                    <Header />
                </div>
                <div className="d-none d-lg-block">
                    <HolidayHeader />
                </div>
            </div>
            <div className="container innerPage-container innerPage-container-mobi">
                <a href onClick={() => history.goBack()}>
                    <div className="back-button back-button-mobi">
                        <img src={backIcon} alt="go-back" />
                    </div>
                </a>
                <div className="package-holder">
                    <div className="package-title d-none d-md-block">
                        <h1>
                            {bookingDetails?.pack_title}
                        </h1>
                        <p style={{ textAlign: "center" }}>
                            {bookingDetails && bookingDetails?.gift_voucher == true ? "" : bookingDetails?.location_country ? bookingDetails?.location_country : bookingDetails?.address}
                        </p>
                    </div>
                    <div className="package-details">
                        <div className="row">
                            <div className="col-12 col-lg-7">
                                <div className="package-image booking-image">
                                    <img src={bookingDetails?.pack_image} className="img-fluid w-100" alt={bookingDetails?.pack_title} />{" "}
                                </div>
                                <div className="package-title d-block d-md-none">
                                <h1>
                                    {bookingDetails?.pack_title}
                                </h1>
                                <p style={{ textAlign: "center" }}>
                                {bookingDetails && bookingDetails?.gift_voucher == true ? "" : bookingDetails.location_country ? bookingDetails.location_country : bookingDetails.address}
                                </p>
                            </div>
                                <div className="package-description mb-5">
                                    <p dangerouslySetInnerHTML={{ __html: bookingDetails?.pack_description }} ></p>
                                </div>
                                {
                                    // for send a gift
                                    // ((bookingDetails?.event_pack === undefined) && (bookingDetails?.adults === undefined || bookingDetails?.children === undefined || bookingDetails?.infants)) ?
                                    //     <RecipientDetailsHolder data={data} />
                                    //     : null
                                }

                            </div>
                            <div className="col-12 col-lg-5">
                                <div className="login-holder booking-holder">
                               { bookingDetails&&bookingDetails?.txn_status === 'failure'?
                                    <h4 style={{ textTransform: 'capitalize', lineHeight: '42px',color:"red"}}>Failed</h4>:
                                    <h5 style={{ textTransform: 'capitalize', lineHeight: '42px' }}>{bookingDetails?.booking_status}</h5>
                            }
                                    <p className="fill-details" style={{ fontWeight: 700, fontSize: '14px', marginBottom: "5px" }}>
                                       {bookingDetails?.ref_id ? `Ref. ID:` : null}  {bookingDetails?.ref_id}
                                    </p>
                                    <p className="fill-details" style={{ fontWeight: 700, fontSize: '14px' }}>
                                       {bookingDetails?.txnid ? `TxnID:` : null}  {bookingDetails?.txnid}
                                    </p>
                                    {
                                        (bookingDetails?.event_pack === undefined && bookingDetails?.experience_pack === undefined && isGiftVoucher === undefined ) ?
                                            <div className="schedule-holder">
                                                {bookingDetails?.schedule?.length > 0 ? 
                                                    <p>{bookingDetails?.schedule?.slice(0,2).map((date, index) => <span>{moment(date, 'DD.MM.YYYY').format('Do-MMM YYYY')}{bookingDetails?.schedule?.length == 1 ? '' : index == 1 ? '' : "," } </span>)} {bookingDetails?.schedule?.length > 2 ?
                                                        <span style={{fontSize: '14px', fontWeight: 'normal'}} onClick={() => setShowModal(true)}>
                                                            +{bookingDetails?.schedule?.length - 2} more
                                                            </span> : 
                                                    null}</p> :
                                                    <p>{moment(bookingDetails?.schedule?.from, 'DD.MM.YYYY').format('Do MMM')} - {moment(bookingDetails?.schedule?.to, 'DD.MM.YYYY').format('Do MMM, YYYY')}</p>
                                                }
                                                {bookingDetails?.schedule?.length > 0 ? 
                                                <DatePopup showModal={showModal} data={bookingDetails} wrapperRef={wrapperRef} fromBookingDetails={true}/> 
                                                : null}
                                            </div> : null
                                    }

                                    {
                                        bookingDetails?.experience_pack !== undefined ?
                                            <div className="schedule-holder">
                                                
                                                {bookingDetails?.schedule?.length > 0 ? 
                                                <p>{bookingDetails?.schedule?.slice(0,2).map((date, index) => <span>{moment(date, 'DD.MM.YYYY').format('Do-MMM YYYY')}{bookingDetails?.schedule?.length == 1 ? '' : index == 1 ? '' : "," } </span>)} {bookingDetails?.schedule?.length > 2 ?
                                                    <span style={{fontSize: '14px', fontWeight: 'normal'}} onClick={() => setShowModal(true)}>
                                                        +{bookingDetails?.schedule?.length - 2} more
                                                        </span> : 
                                                null}</p> : 
                                                <p>{moment(bookingDetails?.schedule?.from, 'DD.MM.YYYY').format('Do MMM, YYYY')} { bookingDetails?.schedule?.to ? `- ${moment(bookingDetails?.schedule?.to, 'DD.MM.YYYY').format('Do MMM, YYYY')}`: null}</p>
                                                }
                                                {bookingDetails?.schedule?.length > 0 ? 
                                                <DatePopup showModal={showModal} data={bookingDetails} wrapperRef={wrapperRef} fromBookingDetails={true}/> 
                                                : null}
                                                
                                            </div> : null
                                    }
                                    {
                                        bookingDetails?.corporate_pack !== undefined ?
                                            <div className="schedule-holder">
                                                
                                                {bookingDetails?.schedule?.length > 0 ? 
                                                <p>{bookingDetails?.schedule?.slice(0,2).map((date, index) => <span>{moment(date, 'DD.MM.YYYY').format('Do-MMM YYYY')}{bookingDetails?.schedule?.length == 1 ? '' : index == 1 ? '' : "," } </span>)} {bookingDetails?.schedule?.length > 2 ?
                                                    <span style={{fontSize: '14px', fontWeight: 'normal'}} onClick={() => setShowModal(true)}>
                                                        +{bookingDetails?.schedule?.length - 2} more
                                                        </span> : 
                                                null}</p> : 
                                                <p>{moment(bookingDetails?.schedule?.from, 'DD.MM.YYYY').format('Do MMM, YYYY')} { bookingDetails?.schedule?.to ? `- ${moment(bookingDetails?.schedule?.to, 'DD.MM.YYYY').format('Do MMM, YYYY')}`: null}</p>
                                                }
                                                {bookingDetails?.schedule?.length > 0 ? 
                                                <DatePopup showModal={showModal} data={bookingDetails} wrapperRef={wrapperRef} fromBookingDetails={true}/> 
                                                : null}
                                                
                                            </div> : null
                                    }

                                    {
                                        ((bookingDetails?.event_pack !== undefined) && (bookingDetails?.schedule?.date != null || bookingDetails?.schedule?.date !== '' || bookingDetails?.schedule?.date !== undefined) && bookingDetails?.schedule?.from != null) ?
                                            <div className="schedule-holder">
                                                <p>{moment(bookingDetails?.schedule?.date, 'DD.MM.YYYY').format('ddd Do MMM, YYYY')} - {moment(bookingDetails?.schedule?.from, 'hh:mm').format('LT')}</p>
                                            </div> : null
                                    }
                                  


                                    {
                                        (bookingDetails?.adults === undefined || bookingDetails?.children === undefined || bookingDetails?.infants === undefined || isGiftVoucher) ? null :
                                            <div className="schedule-holder">
                                                {/* <p>{bookingDetails?.adults} adult(s), {bookingDetails?.children} kid(s),  {bookingDetails?.infants} infant(s)</p> */}
                                                <p>{getGuestCount(bookingDetails?.adults, bookingDetails?.children, bookingDetails?.infants)}</p>
                                            </div>
                                    }


                                    <div className="points-holder">
                                        {
                                            (isCorporate && bookingDetails?.total_points !== 0) ? <p>{bookingDetails?.total_points} pts </p> : null
                                        }
                                        {
                                            (isCorporate && bookingDetails?.total_points !== 0 && bookingDetails?.amount_paid !== "0.00") ? <p className="ml-2">and</p> : null
                                        }
                                        {
                                            (bookingDetails?.amount_paid !== 0 && bookingDetails?.amount_paid !== "0.00")  ? <p className="ml-2">Rs {bookingDetails?.amount_paid}</p> : null
                                        }
                                    </div>
                                    {
                                        (bookingDetails?.special_request === "" || bookingDetails?.special_request === undefined || bookingDetails?.special_request === null) ? null
                                            : <div className="schedule-holder special-request">
                                                <p>{bookingDetails?.special_request}</p>
                                            </div>
                                    }
                                {bookingDetails?.txn_status !== 'failure'&&
                                    <div className="representative">
                                        <p>Hammock representive will get in touch with you shortly</p>
                                    </div>
                                    }

                                    {
                                        bookingDetails?.contact_email !== "" ?
                                            <div className="schedule-holder contact-details">
                                                <p>{bookingDetails?.contact_email}</p>
                                                <p>on Your Email id</p>
                                            </div> : null
                                    }
                                    {
                                        (bookingDetails?.contact_number === "" || bookingDetails?.contact_mobile === "") ? null
                                            : <div className="schedule-holder contact-details">
                                                {(bookingDetails?.contact_number === undefined) ?
                                                    <p>{bookingDetails?.contact_mobile}</p> :
                                                    <p>{bookingDetails?.contact_number}</p>
                                                }
                                                <p>on Your Contact number</p>
                                            </div>
                                    }

                                </div>
                                <div className="bookings-contact ">
                                    <ContactDetailsCard />
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <Footer />
        </div>
        </>
    )
}

export default BookingDetails
