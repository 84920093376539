import { Rating } from "@material-ui/lab";
import React from "react";

const PostReviewPopup = ({
  packageTitle,
  showForm,
  closePopup,
  setReviewStar,
  setReviewCommant,
  postReview,
  error,
}) => {
  return (
    <div className="contact-modal">
      <div className="Review-popup-holder d-flex flex-column">
        <p>{packageTitle && packageTitle}</p>
        {error.star === true ? (
          <div className="errorMsg text-center">
            Please Select Rating Stars
          </div>
        ) : error.commant === true ? (
          <div className="errorMsg text-center">Please Write a commands</div>
        ) 
         : null}
        <div className="review-rating">
          <Rating
            onChange={(e) => setReviewStar(e.target.value)}
            name="size-medium"
            defaultRating={0}
          />
        </div>
        <textarea
          onChange={(e) => setReviewCommant(e.target.value)}
          placeholder="Share details of your own experience at this place"
        ></textarea>
        <div className="review-buttons d-flex">
          <button onClick={closePopup}>cancel</button>
          <button onClick={postReview}>post</button>
        </div>
      </div>
    </div>
  );
};

export default PostReviewPopup;
