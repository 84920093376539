import React, { useState, useEffect, useRef } from 'react';
import InputComponent from '../common/inputComponent';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { useSnackbar } from "notistack";
import Edit from '../../assets/images/common/Edit.svg';
import { useDispatch, useSelector } from "react-redux";
import * as EventAction from '../../redux/actions/eventsAction';
import { updateMN } from '../../redux/actions/profileAction';
import useClickOutside from '../../hooks/useClickOutside';

function IntlContactNumber({ mobile, numberChange, hasError, disabled, hideSaveBtn, inputClass, labelClass, titleValue, hideInputContainer, hasUnderline, hasSeprator, hasFormLine, enableInlineEdit }) {
    // const [editNumber, setEditNumber] = useState(!mobile.startsWith('+')?`+${mobile}`:mobile);
    const [numDisabled, setnumDisabled] = useState(disabled);
    const [defaultNumber, setDefaultNumber] = useState(null);
    const [outSideClickActive, setOutSideClickActive] = useState(false);
    const mobileInputRef = useRef();
    // const [editNumberError, setEditNumberError] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    useEffect(()=>{
        if(!mobile?.toString().startsWith('+') && mobile?.toString().length>2){
            setDefaultNumber(`${mobile}`);
        } else {
            setDefaultNumber(mobile);
        }
        
    }, []);
    useClickOutside(mobileInputRef, () => mobileUpdateSuccess());
    // useClickOutside(mobileInputRef, () => {
    //     console.log('object')
    //     console.log(mobileInputRef.current);
    //     if(hideSaveBtn && !enableInlineEdit){
    //         numberChange(defaultNumber);
    //         if(disabled){
    //             setnumDisabled(true);
    //         }
            
    //     }
    //     if(enableInlineEdit && !hideSaveBtn && !outSideClickActive){
    //         setOutSideClickActive(true)
    //         if(`${defaultNumber}` !== `${mobile}`){
    //             handleEditNumberClick("save");
    //         }
    //     }
    //     setTimeout(()=>{
    //         setOutSideClickActive(false);
    //     }, 2000);
        
    // });
    const mobileUpdateSuccess = () => {
        if(hideSaveBtn && !enableInlineEdit){
            numberChange(defaultNumber);
            if(disabled){
                setnumDisabled(true);
            }
            
        }
        if(enableInlineEdit && !hideSaveBtn && !outSideClickActive){
            setOutSideClickActive(true)
            if(`${defaultNumber}` !== `${mobile}`){
                handleEditNumberClick("save");
            }
        }
        setTimeout(()=>{
            setOutSideClickActive(false);
        }, 2000);
    }
    useEffect(()=>{
        numberChange(`${mobile}`);
        if(hideSaveBtn && !enableInlineEdit){
            setDefaultNumber(mobile);
            
        }

        //Added below code to fix Phone number input bug in mobile browser
        if(mobile&&mobile.length<6){
            console.log(mobile);
            setTimeout(()=>{
                window.$('.PhoneInputInput').val('')
                if(mobile){
                    window.$('.PhoneInputInput').val(mobile);
                } else {
                    window.$('.PhoneInputInput').val('+91');
                }
                
            });
        }

       
       
    }, [mobile]);
    const handleEditNumberClick = (type) => {
       
        if(type==='save'){
            if(window.document.activeElement.className!=='PhoneInputInput' && (hideSaveBtn || enableInlineEdit)){
                console.log(window.document.activeElement.className);
                return false;
            }
            if(mobile){
                
                if((mobile.startsWith('+91') && mobile.length!==13)  || !isPossiblePhoneNumber(mobile)){
                    var elementPosition = document.querySelector('.PhoneInputInput').getBoundingClientRect().top;
                    var offsetPosition = elementPosition + window.pageYOffset - 145;
                    window.scrollTo({top: offsetPosition, behavior: "smooth" });
                    setTimeout(()=>{
                        document.querySelector('.PhoneInputInput').focus();
                    });
                    enqueueSnackbar('Phone Number is not valid', {
                        variant: "error",
                    });
                    return false;
                }
            } 
            else {
                var elementPosition = document.querySelector('.PhoneInputInput').getBoundingClientRect().top;
                var offsetPosition = elementPosition + window.pageYOffset - 145;
               window.scrollTo({top: offsetPosition, behavior: "smooth" })
               document.querySelector('.PhoneInputInput').focus();
                enqueueSnackbar('Please Enter Mobile Number', {
                    variant: "error",
                });
                return false;
            }
            if(enableInlineEdit && !numDisabled && typeof defaultNumber !=='number' && mobile !==defaultNumber){
                // dispatch(updateMN({mobile:mobile}));
                // localStorage.setItem("mobile",mobile);
                enqueueSnackbar('Mobile number updated successfully', {
                    variant: "success",
                });

            }
            setDefaultNumber(mobile);
            if(!hideSaveBtn && !enableInlineEdit && disabled){
            setnumDisabled(true);
            }
        } else if(type==='edit'){
            console.log(mobile);
            if(mobile){
                if(!mobile?.toString().startsWith('+') && mobile?.toString().length>2){
                    numberChange(`${mobile}`);
                }
            }
            if(!hideSaveBtn && !enableInlineEdit){
                setnumDisabled(false);
            }
            
        }
        // console.log(numDisabled)
        
    }

    return (
        <form>
            <div class="input-holder intl-number" ref={mobileInputRef} onClick={()=>enableInlineEdit&&handleEditNumberClick('edit')}>
                <div class="form-group mb-0">

                    <InputComponent
                        type="phonenumber"
                        name="number"
                        placeholder="Enter mobile number"
                        // placeholder={addNew?'Add your mobile number':'Edit your mobile number'}
                        // value={mobile?.startsWith('+')?mobile?.toString():`+${mobile}`}
                        value={`${mobile}`}
                        onChange={numberChange}
                        titleValue={titleValue?titleValue:'Your mobile number'}
                        disabled={numDisabled}
                        // msg={'Enter a valid  mobile number'}
                        // isTrue={hasError}
                        inputClass={inputClass}
                        labelClass={labelClass}
                        hideInputContainer={hideInputContainer}
                        hasUnderline={hasUnderline}
                        hasSeprator={hasSeprator}
                        hasFormLine={hasFormLine}
                        mobileUpdateSuccess={mobileUpdateSuccess}

                    />
                    {/* <label for="">Your Contact number</label> */}
                    
                    {
                        hasError ? <p style={{ color: 'red', fontSize: '12px' }}>Please enter a valid mobile number</p> : ''
                    }
                </div>
                {hideSaveBtn || enableInlineEdit?'':<span>
                {/* {
                    numDisabled ? <div class="edit" onClick={()=>handleEditNumberClick('edit')}>
                        <img src={Edit} alt="" />
                    </div> : <p className="save--btn" onClick={()=>handleEditNumberClick('save')}>Save</p>
                } */}
                </span>}

            </div>
        </form>
    )
};

export default IntlContactNumber