import React, { useState, useRef, useEffect } from 'react';
import About2 from '../../../assets/images/holidays/Converted/about1.webp';
import About1 from '../../../assets/images/holidays/Converted/about2.webp';

import About1Mobi from '../../../assets/images/holidays/Converted/about1-mobi.webp';
import About2Mobi from '../../../assets/images/holidays/Converted/about2-mobi.webp';

import OwlCarousel from "react-owl-carousel";
import { useSelector, useDispatch } from "react-redux";
import { traveller_options } from "../../../resources/carouselOptions";
import CallbackForm from '../callbackForm';
import '../../../assets/css/partials/holidays/visasMore/visasMore.scss';
import useClickOutside from '../../../hooks/useClickOutside';

import {
    CERTIFICATION_LOGO_LIST1,
    CERTIFICATION_LOGO_LIST2,
    CERTIFICATION_LOGO_LIST3,
    APPLICATIONS_DATA,
    HOLIDAY_PACKAGES
} from '../../../resources/holidaysData';
import InternationalTravelDesk from './internationalTravelDesk'


function Visa() {
    const [showContact, setShowContact] = useState(false)
    const [showForm, setShowForm] = useState(false)
    const VISAS_MORE = useSelector((state) => state.holidays?.visas_more);
    const handleContact = () => {
        setShowContact(true)
    }

    const handleForm = () => {
        setShowForm(true)
    }
    const contactModalRef = useRef();
    useClickOutside(contactModalRef, () => {
        if(showContact){
            setTimeout(() => setShowContact(false),100) 
        }
        
    });
    const callbackFormRef = useRef();
    useClickOutside(callbackFormRef, () => {
        setShowForm(false)
        
    });

    useEffect(()=>{
        if(showContact || showForm){
          document.querySelector('html').style.overflow = "hidden"
    
        } else {
          document.querySelector('html').style.overflow = "scroll"
        }
    
      }, [showContact, showForm])

    return (
        <div>
            {
                
                <div>
                    <div className="container">
                        <div className="line"></div>
                        <div className="visa-holder employeeRewards-holder mt-lg-5">
                            <div className="about d-flex">
                                <div className="d-md-flex d-none d-md-block">
                                    <div className="img1">
                                        <img src={About1} alt="" />
                                    </div>
                                    <div className="img2">
                                        <img src={About2} alt="" />
                                    </div>
                                </div>
                                <div className="mobiImages d-flex d-md-none">
                                    <div className="img1">
                                        <img src={About2Mobi} alt="" />
                                    </div>
                                    <div className="img2">
                                        <img src={About1Mobi} alt="" />
                                    </div>
                                </div>
                                <div >
                                    <div className="about-info employee-programs-holder visas-content-wrapper">
                                        <h1 className="text-left"><span>Hassle free</span> International travel</h1>
                                        <ul>
                                            <li>We take care of your paperwork</li>
                                            <li>Student, Business or Tourist Visas</li>
                                            <li>Covering the hardest countries to get Visas for</li>
                                            <li>A professional team for your Visas, Passports, Insurance and so on..</li>
                                            <li>We keep you abreast of the International COVID protocols</li>
                                        </ul>
                                        <div className="learn-more-holder d-none d-md-block" onClick={handleForm}>
                                            <button className="btn btn-learn-more w-100" >Get travel help</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="custom-divider "></div>
                    </div>

                    <div className="container">
                        <div className="cerp-corp-wrapper my-family-wrapper" style={{ marginTop: '65px' }}>
                            {
                                HOLIDAY_PACKAGES.length > 0 && HOLIDAY_PACKAGES.map((service) => (
                                    <div className="cerp-corp my-family-card">
                                        <div className="row justify-content-center">
                                            <div className="col-12 col-md-4 corp-mobi-p0">
                                                <div className="d-none d-md-block">
                                                    <img src={service?.image} className="w-100" alt="" />
                                                </div>
                                                <div className="d-block d-md-none">
                                                    <img src={service?.imageMobi} className="w-100" alt="" />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-7 vertical-center">
                                                <div className="info">
                                                    <p>{service?.title}</p>
                                                    <p>{service?.desc}</p>
                                                    <div className="learn-more-holder d-none d-md-block" onClick={handleForm}>
                                                        <button className="btn btn-learn-more w-100">Get travel help </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                ))
                            }
                        </div>
                        <div className="line visas-line"></div>
                    </div>


                    {
                        // mobile view
                    }
                    <div className="visa-travelInfo d-block d-lg-none">
                        <div className="">
                            <div className="info-holder">
                                <div className="card-wrapper">
                                    {APPLICATIONS_DATA.length > 0 &&
                                        APPLICATIONS_DATA.map((item) => (
                                            <div className="info-card">

                                                <div className="img-holder">
                                                    <img src={item.image} alt="" />
                                                </div>
                                                <div className="card-content">
                                                    <h2>{item.title}</h2>
                                                    <p>{item.description}</p>
                                                </div>
                                            </div>
                                        ))}

                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        // desktop view
                    }
                    <div className="container d-none d-lg-block">
                        <div className="row">
                            <div className="col-7">
                                <div className="visa-travelInfo visa-desktop ">
                                    <div className="">
                                        <div className="info-holder">
                                            <div className="card-wrapper">
                                                {APPLICATIONS_DATA.length > 0 &&
                                                    APPLICATIONS_DATA.map((item) => (
                                                        <div className="info-card">

                                                            <div className="img-holder">
                                                                <img src={item.image} alt="" />
                                                            </div>
                                                            <div className="card-content">
                                                                <h2>{item.title}</h2>
                                                                <p>{item.description}</p>
                                                            </div>
                                                        </div>
                                                    ))}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-5">
                                <div className="d-none d-md-block">
                                    <InternationalTravelDesk />
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        // mobile view
                        <div className="d-block d-md-none">
                            <InternationalTravelDesk showContact={showContact} setShowContact={setShowContact} contactModalRef={contactModalRef}/>
                        </div>
                    }

                    <div className="container">
                        <div className="custom-divider visas-divider"></div>
                        <div className="row justify-content-center">
                            <div className="col-10">
                                <div className="visa-callback-form">
                                    <CallbackForm handleContact={handleContact} showContactForm={showForm} setShowForm={setShowForm} callbackFormRef={callbackFormRef}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="recognitions-holder partners-holder mt-lg-5 pt-lg-5" style={{ marginTop: "50px" }}>
                            <h5>Our Certifications</h5>
                            <div className="partners-wrapper">
                                <div className="d-flex list1">
                                    {CERTIFICATION_LOGO_LIST1.length > 0 &&
                                        CERTIFICATION_LOGO_LIST1.map((item) => (
                                            <div className="card-wrapper">
                                                <div className="img-holder">
                                                    <img src={item.image} alt="" style={{maxWidth:'100%', maxHeight:'100%'}} />
                                                </div>
                                                <div className="info">
                                                    <p className="mb-0">{item.name}</p>
                                                </div>
                                            </div>
                                        ))}
                                    <div className="px-dummy"></div>
                                </div>
                                <div className="d-flex ">
                                    {CERTIFICATION_LOGO_LIST2.length > 0 &&
                                        CERTIFICATION_LOGO_LIST2.map((item) => (
                                            <div className="card-wrapper">
                                                <div className="img-holder">
                                                    <img src={item.image} alt="" />
                                                </div>
                                                <div className="info">
                                                    <p className="mb-0">{item.name}</p>
                                                </div>
                                            </div>
                                        ))}
                                    <div className="px-dummy pr-md-2"></div>
                                </div>
                                <div className="d-flex list1">
                                    {CERTIFICATION_LOGO_LIST3.length > 0 &&
                                        CERTIFICATION_LOGO_LIST3.map((item) => (
                                            <div className="card-wrapper">
                                                <div className="img-holder">
                                                    <img src={item.image} alt="" />
                                                </div>
                                                <div className="info">
                                                    <p className="mb-0">{item.name}</p>
                                                </div>
                                            </div>
                                        ))}
                                    <div className="px-dummy"></div>
                                </div>
                            </div>
                        </div>
                        <div className="container disclaimer-holder">
                            <div className="custom-divider mt-5"></div>
                            <p style={{ fontSize: '14px' }}>Disclaimer : We at Hammock are not involved in the process of assessment of visa applications or their grant or refusal, and shall not be liable or responsible for any delay by the concerned Embassy in assessing, granting or rejecting a visa application.</p>
                        </div>
                    </div>
                </div>
            }
        </div>

    )
}


export default Visa;
