import { Rating } from "@material-ui/lab";
import React from "react";
import Amenities from "./amenities";

const StayFeaturesCard = ({
  initialPackageData,
  fromCorporate,
  calculateDays,
  isWipro,
  isInternational,

}) => {
  const {
    location_city,
    location_country,
    price,
    revised_price,
    location_type,
    group_size,
    average_review_count,
    total_review_counts,
    points
  } = initialPackageData && initialPackageData;
  let fLCapital = (s) => s.replace(/./, (c) => c.toUpperCase());
  const isSecondLocation = initialPackageData?.location && initialPackageData.location[1]?.address
  const secondLocation = isSecondLocation?.includes(',') ? isSecondLocation.split(',')[0] : isSecondLocation
  const state = initialPackageData?.location && initialPackageData.location[0]?.state
  const latitude = initialPackageData?.location && initialPackageData.location[0]?.latitude
  const longitude = initialPackageData?.location && initialPackageData.location[0]?.longitude
  return (
    <div className=" d-flex row  stay-features-holder  ">
      <div className="d-flex    loc-features" >
        <div className=" d-flex  flex-column stay-location">
          <div className="sum-keys d-flex ">
            <p>{secondLocation ? 'Locations' : 'Location'}:</p>
            <div className="d-flex location flex-column">
              <p>
                {" "}
                {!isInternational && !secondLocation ? (
                  <a
                    href={`https://maps.google.com/maps?q=${latitude},${longitude}&hl=es;z=14&amp;output=embed`}
                    target="_blank"
                  >
                    {location_city && `${fLCapital(location_city)},`} {state}
                  </a>
                ) : (
                  isInternational?fLCapital(location_country) :secondLocation? location_city && `${fLCapital(location_city)}, ${fLCapital(secondLocation)}`:null 
                )}
              </p>
              <span style={{textTransform:"capitalize",color:"#600ABD"}}> {location_type}</span>
            </div>
          </div>
          <div className="d-flex sum-values">
            <p>Guests:</p>
            {group_size?.min && group_size?.max ?
              <p>
                Min. {group_size.min} - Max. {group_size.max}
              </p> : <p>-</p>}
          </div>
        </div>
        <div className=" d-flex justify-content-between  stay-features">
          <Amenities
            isfromcard={true}
            initialPackageData={initialPackageData && initialPackageData}
          />
        </div>
      </div>
      <div className="hol-rating    d-flex flex-column align-items-center">
        <Rating name="size-medium" value={average_review_count} readOnly />
        <div className="rating-count d-flex">
          <p>
            {average_review_count} / 5
          </p>
          <p>from</p>
          <p>{total_review_counts} reviews</p>
        </div>
      </div>
      <div className="hd-sum-prices">
        {!fromCorporate &&
          (revised_price != 0 && <p className="normal">{!isWipro ? "Rs. " : ""}{(price ? price : points).toLocaleString('en-IN')} {isWipro ? "pts" : ""}</p>)
        }
        <div className="d-flex flex-wrap justify-content-center align-items-center">
          <p>
            From <span className="discount">{!isWipro ? "Rs. " : ""}{(fromCorporate ? points : revised_price != 0 ? revised_price : price).toLocaleString('en-IN')} {isWipro ? "pts" : ""}</span>
          </p>

          <p>{calculateDays}</p>
        </div>
      </div>
    </div>
  );
};

export default StayFeaturesCard;
