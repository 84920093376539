import React, { useState, useEffect, useRef } from 'react';
import { useSnackbar } from "notistack";
import Header from '../../components/common/header';
import Footer from '../../components/common/footer';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import Edit from '../../assets/images/common/Edit.svg';
import backIcon from '../../assets/images/common/back-icon.svg';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { checkOut, giftVoucherEditName, giftVoucherEditNumber } from '../../redux/actions/corporatesAuthActions';
import IntlContactNumber from "../common/intlContactNumber";
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import UserDetails from '../common/userDetails'
import useClickOutside from '../../hooks/useClickOutside';
import { testEmail } from "../../utils/common";


const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    input: {
        width: 42,
    },
});


const GiftCheckout = () => {

    const [value, setValue] = useState(70);
    const [showGiftCheckOut, setShowCheckout] = useState(false)
    const [disabled, setDisabled] = useState(true);
    const [numDisabled, setnumDisabled] = useState(true);
    const [emailDisabled, setEmailDisabled] = useState(true);
    const { enqueueSnackbar } = useSnackbar();
    const [isEditName, setIsEditName] = useState(false);
    const [editName, setEditName] = useState('');
    const [isEditEmail, setIsEditEmail] = useState(false);
    const [editEmail, setEditEmail] = useState('');
    const [isEditNumber, setIsEditNumber] = useState(false);
    const [editNumber, setEditNumber] = useState('');
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [editNumberError, setEditNumberError] = useState(false);

    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch()

    const corpUserData = JSON.parse(localStorage.getItem('userData'));
    const corpPackageDetails = useSelector(state => state.corporates?.corporates_package_details)
    const giftPackage = corpPackageDetails.data && corpPackageDetails.data?.corporate?.packages[0];
    const location = corpPackageDetails && corpPackageDetails.data?.corporate.packages[0].location[0];
    // const giftVoucherPrice = corpPackageDetails && corpPackageDetails.data?.corporate.packages[0].points;
    const giftVoucherPrice = parseInt(localStorage.getItem("giftVoucherPrice"))
    
    const points = parseInt(localStorage.getItem("points"))
    // console.log(points, giftVoucherPrice, "price")
    const giftImage = giftPackage && giftPackage.images[0].image;
    const [isRewardPointsBtnBool, setIsRewardPointsBtnBool] = useState(false);
    let mobile = localStorage.getItem("mobile");
    if(isNaN(mobile)) {
        let userDataStored = JSON.parse(localStorage.getItem("userData"));
        mobile = userDataStored.user_details.mobile;
    }
    const fullName = localStorage.getItem("full_name");
    const [balance, setBalance] = useState(giftVoucherPrice);
    const [usedRewardPoints, setUsedRewardPoints] = useState(0);
    const [pointsLeft, setPointsLeft] = useState(points);
    const [gstAmount, setGstAmount] = useState(giftVoucherPrice);
    const [initialAmount, setInitialAmount] = useState(giftVoucherPrice);
    const [includingGST, setIncludingGST] = useState(giftVoucherPrice)
    const [afterCheckedGST, setAfterCheckedGST] = useState(giftVoucherPrice)
    const [scroll, setScroll] = useState(false);
    const [radiovalue, setRadioValue] = useState('DC');
    const [isNameUpdate, setisNameUpdate] = useState(false);
    const [isEmailUpdate, setisEmailUpdate] = useState(false);
    const [editNameError, setEditNameError] = useState(false);
    const [editEmailError, setEditEmailError] = useState(false);
    const [editEmailValidError, setEditEmailValidError] = useState(false);
    const nameInputRef = useRef();
    const emailInputRef = useRef();
    useClickOutside(nameInputRef, () => nameUpdateSuccess());
    useClickOutside(emailInputRef, () => emailUpdateSuccess());
    const nameUpdateSuccess = () => {
        if(isNameUpdate && editName == ""){
            enqueueSnackbar("Please enter your name", {
                variant: "error",
            });
        }else if(isNameUpdate){
            enqueueSnackbar("Your name updated successfully", {
                variant: "success",
            });
        }
        setisNameUpdate(false)
    }

    const emailUpdateSuccess = () => {
        if(isEmailUpdate && editEmail == ""){
            enqueueSnackbar("Please enter your email", {
                variant: "error",
            });
        }else if(isEmailUpdate && !testEmail(editEmail)){
            enqueueSnackbar("Please enter valid email", {
                variant: "error",
            });
            
        }else if(isEmailUpdate){
            enqueueSnackbar("Your email updated successfully", {
                variant: "success",
            });
            
        }
        setisEmailUpdate(false)
    }
    // const [changedAmount, setChangedAmount] = useState(changeDataProceedToBook?.points)

    useEffect(() => {
        const initialPrice = giftVoucherPrice;
        const calculateGST = (initialPrice * 5 / 100) + initialPrice
        setInitialAmount(calculateGST - initialPrice);
        setIncludingGST(calculateGST)
        setPointsLeft(points);

        // hide/show confirm button on scroll
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 950);
        });

    }, [giftVoucherPrice, radiovalue])

    const myRef = useRef(null)
    const executeScroll = () => myRef.current.scrollIntoView()

    const userInfo = JSON.parse(localStorage.getItem("userData"));
    const companyDomain = userInfo?.organisation?.email_domain;
    const isWipro = (companyDomain === "wipro.com");

    const balanceUpdateHandler = () => {
        const checkBox = document.querySelector('#giftPointsCheck');
        if (checkBox.checked == true) {
            setIsRewardPointsBtnBool(true);
            setIsCheckboxChecked(true);
            if (giftVoucherPrice === points) {
                setBalance(0);
                setPointsLeft(0);
                setUsedRewardPoints(giftVoucherPrice);
                setValue(0);
                setGstAmount(0);
                setAfterCheckedGST(0);
            }
            else if (giftVoucherPrice > points) {
                setBalance(giftVoucherPrice - points);
                setPointsLeft(0);
                setUsedRewardPoints(points);
                setValue(0);
                const getBalance = giftVoucherPrice - points
                const calcGST = (getBalance * 5 / 100) + getBalance
                setGstAmount(calcGST);
                setAfterCheckedGST(calcGST - getBalance)
            }
            else if (giftVoucherPrice < points) {
                setBalance(0);
                setPointsLeft(points - giftVoucherPrice);
                setUsedRewardPoints(giftVoucherPrice);
                const getPointsLeft = points - giftVoucherPrice
                const calculatePercentage = getPointsLeft * 100 / points;
                setValue(calculatePercentage);
                setGstAmount(0);
                setAfterCheckedGST(0);
            }
        }
        else if (checkBox.checked == false) {
            setIsCheckboxChecked(false);
            setIsRewardPointsBtnBool(false);
            setPointsLeft(points);
            setUsedRewardPoints(0);
            setBalance(giftVoucherPrice);
            const getBalance = giftVoucherPrice;
            setGstAmount((getBalance * 5 / 100) + getBalance);
            setAfterCheckedGST(0);
            if (points > 0) {
                setValue(100);
            }
        };
    };

    const handleCheckout = () => {
        const data = {
            pack_id: giftPackage.id,
            amount: !isRewardPointsBtnBool ? includingGST : gstAmount,
            contact_name: isEditName ? editName : fullName,
            contact_email: isEditEmail ? editEmail : corpUserData?.user_details?.email,
            contact_number: isEditNumber ? editNumber : mobile,
            total_points: usedRewardPoints,
            pay_mode: radiovalue
        };
        if (isEditName && (editName === "" || editName === null)) {
            setEditNameError(true)
            setEditEmailError(false)
            setEditEmailValidError(false)
        }
        else if (isEditEmail && (editEmail === "" || editEmail === null)) {
            setEditEmailError(true)
            setEditEmailValidError(false)
            setEditNameError(false)
        }
        else if (isEditEmail && !testEmail(editEmail)) {
            setEditEmailValidError(true)
            setEditEmailError(false)
            setEditNameError(false)
        }
        else if((editNumber.startsWith('+91') && editNumber.length!==13)  || !isPossiblePhoneNumber(editNumber)){
            setEditNumberError(true)
            setEditEmailError(false)
            setEditEmailValidError(false)
            setEditNameError(false)
        }else{
            if (data.amount > 0) {
                dispatch(checkOut(data, paidWithPoints, payAmount));
                // history.push(`/checkout-details/gift`, data);
            }
            else {
                dispatch(checkOut(data, paidWithPoints, payAmount));
            }
        }
        
    };

    const paidWithPoints = () => {
        history.push(`/payment-successful`);
        dispatch(giftVoucherEditName(null, false));
        dispatch(giftVoucherEditNumber(null, false));
    }

    const payAmount = (data) => {
        history.push(`/checkout-details/gift`, data);
    }

    const handleEditNameClick = () => {
        setDisabled(!disabled);
    };

    const handleEditEmailClick = () => {
        setEmailDisabled(!emailDisabled);
    };

    const handleEditNumberClick = () => {
        setnumDisabled(!numDisabled);
    };

    const changeName = (e) => {
        setIsEditName(true)
        setisNameUpdate(true)
        setEditName(e.target.value);
        dispatch(giftVoucherEditName(e.target.value, true))
    };

    const emailChange = (e) => {
        setIsEditEmail(true);
        setisEmailUpdate(true)
        setEditEmail(e.target.value);
    };

    const paymentModeChange = (e) => {
        setRadioValue(e.target.value);
    }

    const numberChange = (val) => {
        setIsEditNumber(true)
        if(val){
            setEditNumber(val)
            dispatch(giftVoucherEditNumber(val, true))
        } else {
            setEditNumber('');
            dispatch(giftVoucherEditNumber('', false));
        }
        
    };
   
    const handleSliderChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <div className="innerPage-header hide-header-mobi">
                <Header />
            </div>
            <div className="container innerPage-container innerPage-container-mobi">
                <a href onClick={() => history.goBack()}>
                    <div className="back-button back-button-mobi">
                        <img src={backIcon} alt="" />
                    </div>
                </a>
                <div className="purchase-details">
                    <h1>Buying</h1>
                    <div className="line"></div>
                    <div className="row mb-5 pb-5">
                        <div className="col-12 col-lg-7">
                            {corpPackageDetails && corpPackageDetails?.data?.corporate?.packages.map(item => (
                                <div className="product-holder product-holder-mobi giftVoucher-mobi">
                                        <div className="checkoutProd-img">
                                            <img src={giftImage} alt="" />
                                        </div>
                                    <div className="prod-info">
                                        <h5>{corpPackageDetails?.data?.corporate?.title}</h5>
                                        {/* <div className="locations-list">
                                            <p>{location?.address}</p>
                                        </div> */}
                                        <div className="line"></div>
                                        <div className="details">
                                            <p>{isWipro ? 'Points' : 'Rs'} {giftVoucherPrice}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <UserDetails 
                            changeName={changeName}
                            emailChange={emailChange}
                            numberChange={numberChange} 
                            contactName={fullName}
                            editName={editName} 
                            isEditName={isEditName}
                            contact_email={corpUserData?.user_details?.email}
                            editEmail={editEmail}
                            isEditEmail={isEditEmail}
                            isEditNumber={isEditNumber}
                            editNumber={editNumber}
                            mobile={mobile}
                            editNumberError={editNumberError}
                            editEmailError={editEmailError}
                            editNameError={editNameError}
                            editEmailValidError={editEmailValidError}
                            nameInputRef={nameInputRef}
                            emailInputRef={emailInputRef}
                            nameUpdateSuccess={nameUpdateSuccess}
                            emailUpdateSuccess={emailUpdateSuccess}
                            />
                            {/* <div className="buyer-details">
                                <h6>Our team will connect with you on below details</h6>
                                <form>
                                    <div class="input-holder">
                                        <div class="form-group mb-0">
                                            <input type="text" className="form-control" 
                                            size={!isEditName ? corpUserData.user_details.full_name.length : editName?.length}
                                                placeholder="name"
                                                disabled={disabled}
                                                value={!isEditName ? `${fullName}` : editName}
                                                onChange={changeName}
                                            />
                                            <label for="">Your Name</label>
                                        </div>
                                        

                                        {
                                            disabled ? <div class="edit" onClick={handleEditNameClick}>
                                                <img src={Edit} alt="" />
                                            </div> : <p className="save--btn" onClick={handleEditNameClick}>Save</p>
                                        }
                                    </div>
                                </form>
                                <form>
                                    <div class="input-holder">
                                        <div class="form-group mb-0">
                                            <input type="text" className="form-control" size={corpUserData.user_details.email.length + 2}
                                                placeholder="email"
                                                disabled={emailDisabled}
                                                value={!isEditEmail ? `${corpUserData.user_details.email}` : editEmail}
                                                onChange={emailChange}
                                            />
                                            <label for="">Your Email id</label>
                                        </div>
                                      
                                        {
                                            emailDisabled ? <div class="edit" onClick={handleEditEmailClick}>
                                                <img src={Edit} alt="" />
                                            </div> : <p className="save--btn" onClick={handleEditEmailClick}>Save</p>
                                        }
                                    </div>
                                </form>
                                <IntlContactNumber disabled={true} mobile={!isEditNumber ? `${mobile}` : editNumber} numberChange={numberChange} hasError={editNumberError} />
                            </div> */}
                        </div>
                        <div className="col-12 col-lg-5">

                            <div className={scroll ? "d-none" : "d-block"}>
                                <div className="mobile-buttons text-center">
                                    <div className="stcky-rightButton">
                                        <div className="custom-button" onClick={executeScroll}>
                                            <a >Confirm</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {corpPackageDetails && corpPackageDetails?.data?.corporate?.packages.map(item => (
                                <div className="price-holder" ref={myRef}>
                                    <h4>Price</h4>
                                    <div className="price-line"></div>
                                    <div className="total-cost">
                                        <div className="total">
                                            <p>Total cost</p>
                                        </div>
                                        <div className="amount">
                                            <p>{giftVoucherPrice}</p>
                                        </div>
                                    </div>
                                    {
                                        (points == 0 && !isWipro) ? null : 
                                    <div className="rewards-holder">
                                        <div className="my-rewards">
                                            <div >
                                                <p className="rewards">My Reward Points</p>
                                            </div>
                                            <div>
                                                <p className="pts">{`-${isRewardPointsBtnBool ? usedRewardPoints : 0}pts`}</p>
                                            </div>
                                        </div>
                                        <div className={classes.root}>
                                            <Grid container spacing={2} alignItems="center">
                                                <Grid item>
                                                </Grid>
                                                <Grid item xs>
                                                    <Slider
                                                        value={(typeof value === 'number' && isRewardPointsBtnBool) ? value : points > 0 ? 100 : 0}
                                                        onChange={handleSliderChange}
                                                        aria-labelledby="input-slider" disabled
                                                    />
                                                </Grid>
                                            </Grid>
                                            <p className="points-left">{isRewardPointsBtnBool ? pointsLeft : points} pts left</p>
                                            <div className="use-rewards">
                                                <div>
                                                    <p className="reward-points">Use reward points</p>
                                                </div>
                                                <div>
                                                    <label className="switch">
                                                        <input type="checkbox"
                                                            onChange={balanceUpdateHandler}
                                                            id="giftPointsCheck"
                                                        />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                }
                                    <div className="balance-holder d-flex justify-content-between">
                                        <div>
                                            <p>Balance to be paid </p>
                                            {
                                                (isRewardPointsBtnBool && balance === 0) ? '' : <p>GST 5%</p>
                                            }
                                        </div>
                                        <div className="text-right">
                                            <p>Rs {isRewardPointsBtnBool ? balance.toFixed(2) : (giftVoucherPrice).toFixed(2)}</p>
                                            {
                                                (isRewardPointsBtnBool && balance === 0) ? '' : <p><span>+</span> Rs {isRewardPointsBtnBool ? afterCheckedGST.toFixed(2) : initialAmount.toFixed(2)}</p>
                                            }
                                        </div>
                                    </div>
                                    {
                                        (isRewardPointsBtnBool && balance === 0) ? null : 
                                    <div className="balance-holder payment-mode-checkbox" >
                                        <p>Select payment mode</p>
                                        <div >
                                            <label for="DC" className={radiovalue === 'DC' ? 'border-blue' : ''}>
                                                <div className="check-btns">
                                                    <input type="radio" id="DC" name="payment_mode" value='DC'
                                                        onChange={paymentModeChange}
                                                        defaultChecked
                                                    />
                                                    <span class="checkmark"></span>
                                                    <div className="checkBtn-info">
                                                        <h5>Use Debit Card to pay</h5>
                                                        <p>Select this payment option</p>
                                                    </div>
                                                </div>
                                            </label>
                                            <label for="CC" className={radiovalue === 'CC' ? 'border-blue' : ''}>
                                                <div className="check-btns">
                                                    <input type="radio" id="CC" name="payment_mode" value='CC'
                                                        onChange={paymentModeChange}

                                                    />
                                                    <span class="checkmark"></span>
                                                    <div className="checkBtn-info">
                                                        <h5>Use Credit Card / Net Banking to pay</h5>
                                                        <p>Select this payment option</p>
                                                    </div>
                                                </div>
                                            </label>
                                        </div>

                                    </div>
                                }

                                    <button type="button" className="btn proceed-to-book w-100" href
                                        onClick={handleCheckout} >
                                        {(isRewardPointsBtnBool && balance === 0) ? 'Buy with points' : 'Buy'}
                                    </button>
                                </div>
                            ))}

                        </div>

                    </div>
                </div>
            </div>
            <Footer />
            {showGiftCheckOut &&
                <p>go to checkout page</p>
            }
        </div>
    )
}


export default GiftCheckout
