import React, { useState, useEffect, useRef } from "react";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/css/partials/corporates/corporates.scss";
import "../../assets/css/partials/holidays/holidays.scss"
import PrepareFormModal from './PrepareGiftFormModal';
import GuestsModal from "../common/guestsModal";
import '../../assets/css/partials/corporates/corpGuestModal.scss';
import closeIcon from '../../assets/images/common/close-modal.svg';
import ContactDetailsCard from '../common/contactDetailsCard'
import WhatsappLogo from '../../assets/images/corporates/whatsapp-logo.svg'
import CalenderModal from "../corporates/calenderModal";
import "../corporates/calenderModal.css";
import Dialog from '@material-ui/core/Dialog';
import About1 from '../../assets/images/myFamilyFirst/1.jpg';
import About2 from '../../assets/images/myFamilyFirst/2.jpg';
// import About1 from '../../assets/images/myFamilyFirst/creative1.png';
// import About2 from '../../assets/images/myFamilyFirst/creative2.png';
import moment from "moment";
import AboutMobi1 from '../../assets/images/myFamilyFirst/Converted/1.webp';
import AboutMobi2 from '../../assets/images/myFamilyFirst/Converted/2.webp';
import honey1 from '../../assets/images/myFamilyFirst/Converted/honey1.webp';
import honey2 from '../../assets/images/myFamilyFirst/Converted/honey2.webp';
import anniversary1 from '../../assets/images/myFamilyFirst/Converted/anniversary1.webp';
import anniversary2 from '../../assets/images/myFamilyFirst/Converted/anniversary2.webp';
import honey3 from '../../assets/images/myFamilyFirst/Converted/honey3.webp';
import anniversary3 from '../../assets/images/myFamilyFirst/Converted/anniversary3.webp';
import anniversarymobile3 from '../../assets/images/myFamilyFirst/Converted/anniversarymobile3.webp'
import honeymobile3 from '../../assets/images/myFamilyFirst/Converted/honeymobile3.webp'
import { Helmet } from "react-helmet";
import useClickOutside from '../../hooks/useClickOutside';
import {
    GIFT_PACKAGES, GIFT_SLIDER
} from "../../resources/myFamilyFirstData";
import Stories from "./stories";
import { myfamilyCollage } from "../../resources/corporateData";
import Category from "../common/category";
import Header from "../weddings/header";
import * as giftRequestAction from "../../redux/actions/giftRequestAction";
import Destinations from "../weddings/destinations";
import Services from "../weddings/services";
import Experts from "../weddings/experts";
import CategoryImages from "../corporates/categoryImages";
import * as HolidaysAction from "../../redux/actions/holidaysActions";
import StoriesList from "../weddings/stories";
import { useLocation } from "react-router-dom";

function Index() {
    const dispatch = useDispatch();
    const location=useLocation()
    const [showModal, setShowModal] = useState(false);
    const [modal, setModal] = useState(false);
    const [adultGuestCount, setAdultGuestCount] = useState(0);
    const [childrensGuestCount, setChildrensGuestCount] = useState(0);
    const [count, setCount] = useState(0);
    const [showForm, setShowForm] = useState(false)
    const [showContactModal, setShowContactModal] = useState(false)
    const [guestclear, setGuestClear] = useState(true);
    const [scheduleClear, setScheduleClear] = useState(true);
    const [showCalenderModal, setShowCalenderModal] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isVisible, setIsVisible] = useState(false);
    const [height, setHeight] = useState(0);
    const [myfamily, setMyfamily] = useState(false);
    const id = useSelector((state) => state.giftRequest.categoryId);
    const handleContactModalClose = () => {
        setShowContactModal(false)
    }
    const giftStoriesList = useSelector((state) => state.giftRequest.stories_list);
    const STORIES_LIST = useSelector((state) => state.giftRequest.stories_list);
    const honeymoonDestinationsList = useSelector((state) => state.giftRequest.honeymoon_destinations_list);
    const honeymoonStoriesList = useSelector((state) => state.giftRequest.honeymoon_stories_list);
    const anniversaryStoriesList = useSelector((state) => state.giftRequest.anniversary_stories_list);
    const handleNumberOfGuests = () => {
        setShowModal(true);
        // document.getElementById("guestModal").classList.remove("hide");
        // document.getElementById("guestModal").classList.add("show");
    };
    useEffect(()=>{
        if(location.state){
    ChangeCategory(location.state?.id)
        }
      },[location.state])
    const ChangeCategory = (newId) => {
        dispatch(giftRequestAction.myFamilyFirstCategoryId(newId))
    }
    const [state, setState] = useState({
        location: "",
        occasion: "",
        guest: "",
        // tempGuest:"",
        message: "",
        schedule: ""
    });
    const anniversaryServicesData={
        venue:"A perfect venue based on the type of party you wish to throw and the guests or even a cozy venue for just the close ones!!",
        accommodation:"May it be a 1000 or just the both of you, comfort, delight and joyous experience is a promise!",
        Itinerary:"Some events are meant to be celebrated for more than a day! Let us plan for you how!!"
      }
      const honeymoonServicesData={
        venue:"Have the honeymoon of your dreams!! Whether you want to relax by the beachside, explore a bustling new city, or go on an exotic food tour, a romantic gateway with just the both of you and the seasons is open here!",
        accommodation:"Luxurious honeymoon destination with luxury amenities, customized to your personality type, and opulent experiences, to relax and bond.",
        Itinerary:"Not only make your most awaited getaway tour a hassle-free experience but also make sure it allows you to soak in some quality time together, and create some memorable moments as spouses."
      }
    const [error, setError] = useState({
        location: false,
        occasion: false,
        guest: false,
        schedule: false,
        infantValidate: false
    })

    const handleChange = (key, value) => {
        setState(() => ({
            ...state,
            [key]: value.target.value,
        }));
        setError({
            ...error,
            [key]: false
        })
    };

    const contactModalRef = useRef();
    useClickOutside(contactModalRef, (e) => {
        if (showContactModal) {
            setTimeout(() => setShowContactModal(false), 100);
        }
    });

    const popupRef = useRef();
    useClickOutside(popupRef, () => {
        if (showForm) {
            closeFormHandler()
        }
    });
    useEffect(() => {
        if (id == 1) {
            dispatch(giftRequestAction.getHoneymoonDestinationsList("honeymoon"))
            dispatch(giftRequestAction.getHoneymoonStoriesList("honeymoon"))
        }
        else if (id == 2) {
            dispatch(giftRequestAction.getHoneymoonDestinationsList("anniversary"))
            dispatch(giftRequestAction.getHoneymoonStoriesList("anniversary"))
        }
        else {
            dispatch(giftRequestAction.getStoriesList())
        }
      
    }, [id])
  
    const guestDatahandler = (adultData, childrenData, countData) => {
        let guestRes = [];
        setShowModal(false);
        setAdultGuestCount(adultData);
        setChildrensGuestCount(childrenData);
        setCount(countData);
        if (adultData > 0) {
            guestRes.push(`${adultData} adult${adultData > 1 ? 's' : ''}`);
        }
        if (childrenData > 0) {
            guestRes.push(`${childrenData} kid${childrenData > 1 ? 's' : ''}`);
        }
        if (countData > 0) {
            guestRes.push(`${countData} infant${countData > 1 ? 's' : ''}`);
        }
        setState(() => ({
            ...state,
            'guest': guestRes.join(',')
        }))
        if (adultData || childrenData || countData) {
            setError({
                ...error,
                guest: false,
                infantValidate: adultData == 0 ? error.infantValidate : false
            })
        }
    };
    const selectDateHandler = () => {
        setShowCalenderModal(true);
        setMyfamily(true)
    };
    const myRef = useRef(null);
    const handleDatesData = (sDate, eDate, bool) => {
        setShowCalenderModal(false);
        if (bool) {
            setStartDate(null);
            setEndDate(null);
            setState(() => ({
                location: "",
                occasion: "",
                message: "",
                guest: "",
                schedule: ""
            }))
        } else {
            setStartDate(sDate);
            setEndDate(eDate);
            setState(() => ({
                ...state,
                'schedule': {
                    "to": `${eDate}`,
                    "from": `${sDate}`
                }
            }))
        }
        if (sDate || eDate) {
            setError({
                ...error,
                schedule: false
            })
        }
    };

    useEffect(() => {
        let footer = document
            .querySelector(
                ".footer-holder"

            )
            ?.getBoundingClientRect().top+600;
        window.addEventListener("scroll", () => {
            setIsVisible(window.scrollY > footer);


        })
    }, [])

    const handleEnquiry = (e) => {
        e.preventDefault();
        if (!state.location || state.location === "") {
            focusInput('#location');
            setError({ location: true })
        } else if (!state.occasion || state.occasion === "") {
            focusInput('#occasion');
            setError({ occasion: true })
        } else if (!state.schedule || state.schedule === "" || startDate === false) {
            focusInput('#schedule');
            setError({ schedule: true })
        } else if (!state.guest || state.guest === "" || state.guest === `${0} adults, ${0} kids, ${0} infants`) {
            focusInput('#guest');
            setError({ guest: true })
        } else if ((count >= 1 || childrensGuestCount >= 1) && adultGuestCount === 0) {
            focusInput('#guest');
            setError({ infantValidate: true })
        }
        else {
            setModal(true);
        }
    };

    const inputFieldsClear = () => {
        setState({
            ...state,
            location: "",
            occasion: "",
            guest: "",
            message: "",
            schedule: ""
        })


    }
    const handleForm = () => {
        setState({
            ...state,
            location: "",
            occasion: "",
            message: "",
        })
        setError({
            location: false,
            occasion: false,
            guest: false,
            schedule: false,
            infantValidate: false
        })
        setCount(0)
        setChildrensGuestCount(0)
        setAdultGuestCount(0)
        setStartDate(null)
        setEndDate(null)
        handleDatesData(null, null, true)
        setShowForm(true)
    }
    const handleContactModal = () => {
        setShowContactModal(true)
    }

    const closeFormHandler = () => {
        setState({
            ...state,
            location: "",
            occasion: "",
            message: "",
        })
        setError({
            location: false,
            occasion: false,
            guest: false,
            schedule: false,
            infantValidate: false
        })
        setCount(0)
        setChildrensGuestCount(0)
        setAdultGuestCount(0)
        setStartDate(null)
        setEndDate(null)
        handleDatesData(null, null, true)
        setShowForm(false)
    }

    const getGuestCount = (adult, kids, infant) => {
        let tmpGuestsArray = [];
        if (adult > 0) {
            tmpGuestsArray.push(`${adult} adult${adult > 1 ? 's' : ''}`);
        }
        if (kids > 0) {
            tmpGuestsArray.push(`${kids} kid${kids > 1 ? 's' : ''}`);
        }
        if (infant > 0) {
            tmpGuestsArray.push(`${infant} infant${infant > 1 ? 's' : ''}`);
        }
        return tmpGuestsArray.join(', ');
    };

    const focusInput = (inputDom) => {
        var elementPosition = document.querySelector(`${inputDom}`).getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - 145;
        // window.scrollTo({top: offsetPosition, behavior: "smooth" });
        setTimeout(() => {
            document.querySelector(inputDom).focus();
        });
    };

    useEffect(() => {
        if (showContactModal || showForm) {
            document.querySelector('html').style.overflow = "hidden"

        } else {
            document.querySelector('html').style.overflow = "scroll"
        }

    }, [showContactModal, showForm])
    const headerText = () => {
        return (
            <div className="wedding-info my-family-holder">
                {id == 1 ? <h1 className="first">
                    The feeling that you are at the far end of the world with your <span>newly-wed spouse on a honeymoon</span>  you have always dreamt of is enough to make you feel ecstatic.

                </h1> : <h1 className="first">A wedding anniversary is a special occasion for every couple.<span> It’s a time to relive all the good moments </span> you have spent together and renew the vows once again. </h1>}
                <p>
                    Special moments . Fine Wine & Dine . Customized Trips{" "}
                </p>
            </div>
        );
    };
 
    return (
        <>
            <Helmet>
                <title>My Family First - Hammock Holidays</title>
                <meta name="description" content="Gift memorable Experiences Make a gift with us for your loved ones" />
            </Helmet>
            <div className="container">
                <div className="row">
                    <div className="myfamily-first-category-holder">
                        <Category CATEGORY={myfamilyCollage} ChangeCategory={ChangeCategory} id={id} />
                    </div>
                    {id == 3 ?
                        <div className="row">
                            <div className="col-12">
                                <div className="visa-holder employeeRewards-holder mt-lg-3">
                                    <div className="about d-flex">
                                        <div className="d-md-flex d-none d-md-block">
                                            <div className="img1">
                                                <img src={About1} alt="About1" />
                                            </div>
                                            <div className="img2">
                                                <img src={About2} alt="About2" />
                                            </div>
                                        </div>
                                        <div className="mobiImages d-flex d-md-none">
                                            <div className="img1">
                                                <img src={AboutMobi1} alt="About1" />
                                            </div>
                                            <div className="img2">
                                                <img src={AboutMobi2} alt="About2" />
                                            </div>
                                        </div>
                                        <div >
                                            <div className="about-info employee-programs-holder my-family-holder">
                                                <h1>Want to gift your loved ones a <span>memorable experience</span>? We have you covered.</h1>
                                                <div className="employee-programs">
                                                    <p>Special occasions . We help you plan in under a week</p>
                                                    <p>Fine Dine & Wine . Domestic & International trips</p>
                                                </div>
                                                <div>
                                                    <div className="learn-more-holder d-none d-md-block" onClick={handleForm}>
                                                        <button className="btn btn-learn-more w-100" >Create a Gift</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> : <Header headerText={headerText} wedding1={id == 1 ? honey1 : anniversary1} wedding2={id == 1 ? honey2 : anniversary2} mobile1={id == 1 ? honey1 : anniversary1} mobile2={id == 1 ? honey2 : anniversary2} handleForm={handleForm} section={id == 1 ? 'honeymoon' : 'anniversary'} />}
                </div>
            </div>
            {id == 3 ?
                <div className="container">
                    <div className="cerp-corp-wrapper my-family-wrapper">
                        {
                            GIFT_PACKAGES.length > 0 && GIFT_PACKAGES.slice(0, 1).map((service) => (
                                <div className="cerp-corp my-family-card">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-4 corp-mobi-p0">
                                            <div className="d-none d-md-block">
                                                <img src={service?.image} className="w-100" alt={service?.title} style={{ borderRadius: '54px' }} />
                                            </div>
                                            <div className="d-block d-md-none">
                                                <img src={service?.imageMobi} className="w-100" alt={service?.title} style={{ height: "400px", objectFit: "cover" }} />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-7 vertical-center">
                                            <div className="info">
                                                <p>{service?.title}</p>
                                                <p>{service?.desc}</p>
                                                <div className="learn-more-holder d-none d-md-block" onClick={handleForm}>
                                                    <button className="btn btn-learn-more w-100">Create a Gift</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            ))
                        }
                    </div>
                </div> :
                <Destinations WEDDING_DESTINATIONS_LIST={honeymoonDestinationsList} tittle={id == 1 ? "Honeymoon" : "Anniversary"} />
            }
            {id != 3 &&
                <Services data={id==1?honeymoonServicesData:anniversaryServicesData} fromHoneyMoon={id == 1 ? true : false} />}
            {id == 3 &&
                <div className="stories ">
                    {STORIES_LIST && STORIES_LIST.length > 0 ?
                        <div className="heading container">
                            <h2>Get inspired by <span>stories</span> from our happy users</h2>
                        </div>
                        : null}
                    <Stories categoryId={id} STORIES_LIST={giftStoriesList} />
                </div>
            }
            {
                id != 3 &&
                <Experts section={id == 1 ? 'honeymoon' : 'anniversary'} wedding3={id == 1 ? honey3 : anniversary3} mobile3={id == 1 ? honeymobile3 : anniversarymobile3} handleForm={handleForm} />
            }
            <div className="container wedding-collage">
                {id == 3 ?
                    <div className="cerp-corp-wrapper my-family-wrapper">
                        {
                            GIFT_PACKAGES.length > 0 && GIFT_PACKAGES.slice(1, 3).map((service) => (
                                <div className="cerp-corp my-family-card">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-4 corp-mobi-p0">
                                            <div className="d-none d-md-block">
                                                <img src={service?.image} className="w-100" alt={service?.title} style={{ borderRadius: '54px' }} />
                                            </div>
                                            <div className="d-block d-md-none">
                                                <img src={service?.imageMobi} className="w-100" alt={service?.title} style={{ height: "400px", objectFit: "cover" }} />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-7 vertical-center">
                                            <div className="info">
                                                <p>{service?.title}</p>
                                                <p>{service?.desc}</p>
                                                <div className="learn-more-holder d-none d-md-block" onClick={handleForm}>
                                                    <button className="btn btn-learn-more w-100">Create a Gift</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            ))
                        }
                    </div> : <div className="corporate-services">
                        {/* <div className="col-12  mt-lg-2 mb-5"> */}
                        <CategoryImages fromHoneyMoon={id == 1 ? true : false} fromAnniversary={id == 2 ? true : false}  section={id == 1 ? 'honeymoon' : 'anniversary'}/>
                        {/* </div> */}
                    </div>
                }


                {showContactModal ?
                    <div className="contact-modal">
                        <div className="login-holder contact-holder" ref={contactModalRef}>

                            <h5>Contact Us</h5>
                            <span onClick={handleContactModalClose}><img src={closeIcon} className="close-icon" alt='close-icon' /></span>
                            <div className="line"></div>
                            <div className="contact-card mb-3 d-flex align-items-center justify-content-between">
                                <div>
                                    <p>
                                        <a href="tel:+919902064657">+91 9902064657</a>
                                    </p>
                                    <p>Our number</p>
                                </div>
                                <div>
                                    <a href="https://wa.me/+919902064657" rel="noreferrer" target="_blank">
                                        <img src={WhatsappLogo} alt="WhatsappLogo" />
                                    </a>
                                </div>
                            </div>
                            <div className="contact-card">
                                <p>
                                    <a
                                        href="mailto:ramananda@hammockholidays.com"
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        ramananda@hammockholidays.com{" "}
                                    </a>
                                </p>
                                <p>Our email</p>
                            </div>
                        </div>
                    </div>
                    : null}
            </div>

            {id != 3 &&
                <div className="stories ">
                    

                    <StoriesList WEDDING_STORIES_LIST={id==1?honeymoonStoriesList:anniversaryStoriesList} categoryId={id} />
                </div>
            }
            <div className="myfamily-holder" style={{ position: 'relative' }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className=" col-12 col-lg-10">
                            <div className={showForm ? "prepareGift-mobile" : "prepareGift-desk"} >
                                <div className={showForm ? "prepareGift-holder create-gift-holder" : "prepareGift-holder"} style={{ margin: "100px auto" }} ref={popupRef}>
                                    <span onClick={closeFormHandler}><img src={closeIcon} alt='close' className={showForm ? "d-block close-icon" : "close-icon"} /></span>
                                    <h5>Create {id == 2 ? "an" : "a"} {id == 1 ? "Honeymoon Package" : id == 2 ? "Anniversary Package" : "Family Gift"}</h5>
                                    <h3>Fill details below to proceed</h3>
                                    <form>
                                        <div className="row">
                                            <div className="col-12 col-lg-6">
                                                <div className="input-holder">
                                                    <div className="custom-input card--bg mb20">
                                                        <input
                                                            value={state.location}
                                                            type="text"
                                                            id="location"
                                                            className="form-control"
                                                            autocomplete="off"
                                                            placeholder="Bangalore, Goa, Pudu"
                                                            onChange={(value) => handleChange("location", value)}
                                                            onKeyPress={(event) => {
                                                                if (!/^[A-Za-z ]+$/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                        />
                                                        <div className="form-line"></div>
                                                        <label>Locations preferred</label>
                                                        {error.location === true ? <span className="errorMsg text-center">Preferred locations required</span> : null}
                                                    </div>
                                                </div>

                                                <div className="input-holder">
                                                    <p></p>
                                                    <div className="custom-input card--bg mb20">
                                                        <input
                                                            value={state.occasion}
                                                            type="text"
                                                            id="occasion"
                                                            autocomplete="off"
                                                            className="form-control"
                                                            placeholder={`Anniversary, ${id == 3 ? "Birthday" : "Honeymoon"}`}
                                                            onChange={(value) => handleChange("occasion", value)}
                                                        />
                                                        <div className="form-line"></div>
                                                        <label>Occasions</label>
                                                        {error.occasion === true ? <span className="errorMsg text-center">Occasion required</span> : null}
                                                    </div>
                                                    <div className="custom-input card--bg mb20 position-relative">
                                                        <input

                                                            type="text"
                                                            className="form-control"
                                                            readOnly
                                                            id="schedule"
                                                            placeholder="Pick Your Dates"
                                                            onChange={(value) => handleChange("schedule", value)}
                                                            onClick={selectDateHandler}
                                                            value={
                                                                startDate &&
                                                                    endDate !== null ?
                                                                    `${moment(startDate).format('ddd-DD MMM')} to ${moment(endDate).format('ddd-DD MMM')}` : ""
                                                            }
                                                        />
                                                        <div className="form-line"></div>
                                                        <label>When are you planning</label>
                                                        {error.schedule === true ? <span className="errorMsg text-center">Please select dates required</span> : null}
                                                        {showCalenderModal ? (
                                                            <CalenderModal
                                                                showMyModal={showCalenderModal}
                                                                handleDatesData={handleDatesData}
                                                                myfamily={myfamily}
                                                                prevStartDate={startDate}
                                                                prevEndDate={endDate}
                                                            />
                                                        ) : null}
                                                    </div>
                                                    <div className="custom-input card--bg mb20 position-relative">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="guest"
                                                            readOnly
                                                            placeholder="Number of guests"
                                                            onChange={(value) => handleChange("guest", value)}
                                                            onClick={handleNumberOfGuests}
                                                            value={
                                                                adultGuestCount !== 0 ||
                                                                    childrensGuestCount !== 0 ||
                                                                    count !== 0
                                                                    ? getGuestCount(adultGuestCount, childrensGuestCount, count)
                                                                    : ""
                                                            }
                                                        />
                                                        <div className="form-line"></div>
                                                        <label>Guests</label>
                                                        {error.guest === true ? <span className="errorMsg text-center">No of guests required</span> : null}
                                                        {error.infantValidate === true ? <span className="errorMsg text-center">For Infants/children at least 1 adult is required</span> : null}
                                                        {showModal ? <GuestsModal
                                                            showMyModal={showModal}
                                                            guestDatahandler={guestDatahandler}
                                                            adultCount={adultGuestCount}
                                                            childrensCount={childrensGuestCount}
                                                            infantCount={count}
                                                        /> : null}
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <div className="input-holder mt-lg-4">
                                                    <div className="custom-input card--bg2 mb20">
                                                        <h2>Any specific request</h2>
                                                        <div className="inner-card">
                                                            <input
                                                                value={state.message}
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter here"
                                                                onChange={(value) => handleChange("message", value)}
                                                            />
                                                            <div className="form-line"></div>
                                                            <label>Example:</label>
                                                            <label>Add Cadbury celeberations chocolate box</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="line"></div>
                                                <div className="text-center">
                                                    <button type="button" className="btn otp-btn prepare-btn" onClick={handleEnquiry}>
                                                        {id == 3 ? "Request for a Gift" : "Request for a Package"}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            {
                                modal && <PrepareFormModal
                                    id={id}
                                    closeFormHandler={closeFormHandler}
                                    handleClose={() => setModal(false)}
                                    showModal={modal}
                                    giftData={state}
                                    inputFieldsClear={inputFieldsClear} />
                            }
                        </div>

                    </div>
                </div>


            </div>


            {
                !isVisible && <div className="mobile-buttons">
                    <div className="stcky-leftButton">
                        <div className="white-button" onClick={handleContactModal}>
                            <a >Contact</a>
                        </div>
                    </div>
                    <div className="stcky-rightButton">
                        <div className="custom-button" onClick={handleForm}>
                            <a >{id == 3 ? "Create a Gift" : "Book Now"}</a>
                        </div>
                    </div>
                </div>
            }





        </>
    );
}

export default Index;
