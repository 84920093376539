import React, { useEffect } from 'react';
import successIcon from '../../../assets/images/common/success.svg';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as EventAction from '../../../redux/actions/eventsAction';

function PaymentSuccess() {
    const purchaseDetails = useSelector(state => state?.events?.booking_details?.data?.serializer);
    const refID = useSelector(state => state?.events?.booking_details?.data?.ref_id);
    const dispatch = useDispatch()
    const history = useHistory();
    // useEffect(() => {
    //     dispatch(EventAction.checkOut())
    // }, [])

    const goToHome = () => {
        history.push(`/`);
    }

    return (
        <div>
            <div className="payment-status">
                <div className="text-center">
                    <img src={successIcon} alt="" />
                </div>
                <div className="payment-details text-center">
                    {purchaseDetails?.corporate ?
                    <p className="status">Successfully {purchaseDetails?.total_points !== 0  ? `Paid ${purchaseDetails?.total_points} Pts` : `Booked`}</p>: 
                    <p className="status">Successfully {purchaseDetails?.amount_paid !== "0.00"  ? `Paid ${purchaseDetails?.amount_paid} Pts` : `Booked`}</p>
                    }
                    <p className="booking-for">Booking received for <span>{purchaseDetails?.pack_title}</span>  </p>
                    <p className="transactionId">Ref. ID : {refID}</p>
                    <p className="transactionId">Transaction ID : {purchaseDetails?.txnid}</p>
                    {
                        purchaseDetails?.booking_status === "booked" ? <p className="transactionId">Status: Success</p> : null

                    }
                    <p className="message">Thank you for booking, an email will be sent to {purchaseDetails?.contact_email} with all details </p>

                    <div class="goto-home">
                        <button className="cursor-pointer btn-goto-home" onClick={goToHome}>Go To Home</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentSuccess
