import React, {useEffect, useRef} from 'react'
import OwlCarousel from "react-owl-carousel";
import { useDispatch, useSelector } from "react-redux";
import { corporate_options } from '../../resources/carouselOptions';
import { useHistory, Link } from "react-router-dom";
import * as EventsAction from '../../redux/actions/eventsAction';

function SimilarOffers({similarEvents, isPastDate, myRef}) {
    const dispatch = useDispatch();
    const history = useHistory();
    const SIMILAR_EVENTS = useSelector((state) => state?.events?.similar_events?.data);
    const userDetails = JSON.parse(localStorage.getItem("userData"));
    const isCorporateAccount = userDetails?.user_details?.corporate_account;
    const id = similarEvents;
    const userInfo = JSON.parse(localStorage.getItem("userData"));
    const companyDomain = userInfo?.organisation?.email_domain;
    const isWipro = (companyDomain === "wipro.com");

    useEffect(() => {
        dispatch(EventsAction.getSimilarEvents(id));
    }, [id, JSON.stringify(SIMILAR_EVENTS)]);

    // const refreshSimilarPackages = () => {
    //     dispatch(EventsAction.getSimilarEvents(id));
    // }
    const handlePackageDetails = (event_slug) => {
        history.push(`/events/event-details/${event_slug}`);
        window.scrollTo(0, 0);
      };
    const handleSeeAll = () => {
        history.push({pathname: `/events`,fromSimilarOffer: {bool: true}});
        window.scrollTo(0, 0);
    }
    return (<>
        <div ref={myRef}>
        {
            SIMILAR_EVENTS && SIMILAR_EVENTS.length > 0 ?  
        <div className="container d-none d-lg-block">
            <div className="nearby-experiences similar-offers">
                {isPastDate ? <h5>Explore our <span>Upcoming Events </span></h5> : <h5>You may also like</h5>}
                <div className="divider"></div>
                <OwlCarousel className="owl-theme" {...corporate_options}>
                {
                    SIMILAR_EVENTS && SIMILAR_EVENTS.length > 0 && SIMILAR_EVENTS.map((items) => (
                        <div className="item" key={items?.id}>
                            <Link to={`/events/event-details/${items.event_slug}`}>
                            <div className="card-wrapper d-lg-flex flex-column" style={{cursor: 'pointer'}} >
                                <img src={items.image} className="w-100" alt="" />
                                <div className="info">
                                   <p className="title">{items.pack_title}</p>
                                   {/* <div className="desc">
                                        <p>{items.brief_description}</p>
                                   </div> */}
                                   { items.price !== 0 ?
                                    (isCorporateAccount && isWipro) ? <p className="points">{items.price} pts</p> :   <p className="points">Rs {items.price}</p> : <p className="points">Book for free</p>
                                   }
                                  
                                </div>
                            </div>
                            </Link>
                        </div>
                    ))
                }{
                    SIMILAR_EVENTS && SIMILAR_EVENTS.length > 5 ?
                    <div className="item">
                        <div className="card-wrapper see-all" style={{cursor: 'pointer'}} onClick={() => handleSeeAll()}>
                        <p>  See all <br />
                        Events </p>
                        </div>
                    </div>: null
                }
                </OwlCarousel>
            </div>
        </div> : null
        }
        </div>
        
        <div className="stories d-lg-none ">
            {SIMILAR_EVENTS && SIMILAR_EVENTS.length > 0 ?
            <div className="container ">
                <div className="nearby-experiences  mb-10">
                {isPastDate ? <h5>Explore our <span>Upcoming Events </span></h5> : <h5>You may also like</h5>}
                    <div className="divider"></div>
                </div>
            </div> : null}
            <div className="view-more-holder">
                <div className="wrapper">        
                {
                    SIMILAR_EVENTS && SIMILAR_EVENTS.length > 0 && SIMILAR_EVENTS.map((items) => (
                        <div className="item" style={{cursor: 'pointer'}} >
                            <div className="card-wrapper" onClick={() => handlePackageDetails(items.event_slug)}>
                                <div className="card-img">
                                    <img src={items.image} className="w-100" alt="" />
                                </div>
                                <div className="info">
                                    <p className="title">{items.pack_title}</p>
                                    { items.price !== 0 ?
                                        (isCorporateAccount && isWipro) ? <p className="points">{items.price} pts</p> :   <p className="points">Rs {items.price}</p> : <p className="points">Book for free</p>
                                    }
                                </div>
                            </div>
                        </div>
                        ))
                }
                {
                        SIMILAR_EVENTS && SIMILAR_EVENTS.length > 5 ?
                        <div className="item">
                            <div className="card-wrapper see-all" style={{cursor: 'pointer'}} onClick={() => handleSeeAll()}>
                            <p>  See all <br />
                            Events </p>
                            </div>
                        </div>: null
                    }
                </div>
            </div>
        </div>
        </>
    )
}

export default SimilarOffers
