import React from "react";
import wifi from "../../../assets/images/Amenities/wifi.svg";
import confrence from "../../../assets/images/Amenities/confrence.svg";
import AC from "../../../assets/images/Amenities/AC.svg";
import wildLife from "../../../assets/images/Amenities/wildLife.svg";
import Restarents from "../../../assets/images/Amenities/Restarents.svg";
import tv from "../../../assets/images/Amenities/tv.svg";
import balcony from "../../../assets/images/Amenities/balcony.svg";
import gym from "../../../assets/images/Amenities/gym.svg";
import roomservice from "../../../assets/images/Amenities/roomservice.svg";
import coffee from "../../../assets/images/Amenities/coffe.svg";
import locker from "../../../assets/images/Amenities/locker.svg";
import dryer from "../../../assets/images/Amenities/dryer.svg";
import walk from "../../../assets/images/Amenities/walk.svg";
import swim from "../../../assets/images/Amenities/swim.svg";
import game from "../../../assets/images/Amenities/game.svg";
import medkit from "../../../assets/images/Amenities/medkit.svg";
const Amenities = ({ initialPackageData, isfromcard }) => {
  const { featured_amenities, hotel_resort_facilities, room_amenities } =
    initialPackageData;
  const filterList = (arr) => {
    return arr
      ?.map((e) => {
        if (e.value === true) {
          return e.label;
        }
      })
      .filter((e) => e !== undefined);
  };
  const activefeaturedAmenitiesList = filterList(featured_amenities);

  const activehotelResortFacilitiesList = filterList(hotel_resort_facilities);
  const activeRoomAmenitiesList = filterList(room_amenities);

  
  const featuredRoomAmenities = [
    {
      label: "Television",
      image: tv,
    },

    {
      label: "Safe Locker",
      image: locker,
    },

    {
      label: "Air Conditioning",
      image: AC,
    },
    {
      label: "Tea/Coffee Cattle",
      image: coffee,
    },
    {
      label: "Tea/Coffee Kettle",
      image: coffee,
    },
  ];
  const featuredHotelAmenities = [
    {
      label: "Swiming Pool",
      image: swim,
    },
    {
      label: "Wifi",
      image: wifi,
    },
    {
      label: "Doctor on Call",
      image: medkit,
    },
    {
      label: "Restaurants",
      image: Restarents,
    },
  ];
  const hotelResortFacilities = [
    {
      label: "Sit-out/Balcony",
      image: balcony,
    },
    {
      label: "Room Service",
      image: roomservice,
    },
    {
      label: "Tolietries, hair dryer",
      image: dryer,
    },
  ];
  const roomAmenities = [
    {
      label: "Conference room",
      image: confrence,
    },
    {
      label: "Nature walks",
      image: walk,
    },
    {
      label: "Spa and Fitness center",
      image: gym,
    },
    {
      label: "Indoor/Outdoor games",
      image: game,
    },
    {
      label: "Wildlife area",
      image: wildLife,
    },
  ];

  function filterAmenities(arr, farr) {
    return arr?.filter(function (e) {
      return farr?.some(function (e2) {
        return e.label == e2;
      });
    });
  }
  const activeroomAmenites = filterAmenities(
    roomAmenities,
    activeRoomAmenitiesList
  );
  const activeHotelAmenites = filterAmenities(
    hotelResortFacilities,
    activehotelResortFacilitiesList
  );
  const activeFeaturedroomAmenites = filterAmenities(
    featuredRoomAmenities,
    activefeaturedAmenitiesList
  );
  const activeFeaturedHotelAmenities = filterAmenities(
    featuredHotelAmenities,
    activefeaturedAmenitiesList
  );
 
  return (
    <>
      {isfromcard && (
        <>
          <div className="amenities-wrapper d-flex justify-content-between">
            {activeFeaturedroomAmenites.concat(activeFeaturedHotelAmenities)?.slice(0, 2)?.map((data, idx) => (
              <div className="d-flex    stay-amenities" key={idx}>
                <img src={data.image} alt={data.label} />
                <span className="stay-amenities-txt">{data.label=="Tea/Coffee Cattle"?"Tea/Coffee Kettle":data.label}</span>
              </div>
            ))}
          </div>
          <div className="amenities-wrapper d-flex justify-content-between">
            {activeFeaturedroomAmenites.concat(activeFeaturedHotelAmenities)?.slice(2, 4)?.map((data, i) => (
              <div className="d-flex    stay-amenities" key={i}>
                <img src={data.image} alt={data.label}/>
                <span className="stay-amenities-txt">{data.label=="Tea/Coffee Cattle"?"Tea/Coffee Kettle":data.label}</span>
              </div>
            ))}
          </div>
        </>
      )}
      {!isfromcard && (
        <>
          <div className="amenities-holder">
            {(activeHotelAmenites?.length>0||activeFeaturedHotelAmenities?.length>0) && (
              <div className="amenities-sub-holder">
                <div className="title">Hotel / Resort facilities</div>
                <div className="amenities-list d-flex ">
                  {activeHotelAmenites
                    .concat(activeFeaturedHotelAmenities)
                    .map((data, i) => (
                      <div className="d-flex    stay-amenities" key={i}>
                        <img src={data.image} alt={data.label} />
                        <span className="stay-amenities-txt">{data.label=="Tea/Coffee Cattle"?"Tea/Coffee Kettle":data.label}</span>
                      </div>
                    ))}
                </div>
              </div>
            )}
             {(activeroomAmenites?.length>0||activeFeaturedroomAmenites.length>0) && (
              <div className="amenities-sub-holder">
                <div className="title">Room amenities</div>
                <div className="amenities-list d-flex ">
                  {activeroomAmenites
                    .concat(activeFeaturedroomAmenites)
                    .map((data, i) => (
                      <div className="d-flex    stay-amenities" key={i}>
                        <img src={data.image}  alt={data.label}/>
                        <span className="stay-amenities-txt">{data.label=="Tea/Coffee Cattle"?"Tea/Coffee Kettle":data.label}</span>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Amenities;
