import React, {useEffect} from 'react'
import OwlCarousel from "react-owl-carousel";
import { useDispatch, useSelector } from "react-redux";
import { corporate_options } from '../../resources/carouselOptions';
import { useHistory, Link } from "react-router-dom";
import * as giftRequestAction from "../../redux/actions/giftRequestAction";

function SimilarStories({STORY_ID, STORIES_LIST, closeFormHandler}) {
    const dispatch = useDispatch();
    const history = useHistory();
    const FIlTERED_SIMILAR_STORIES = STORIES_LIST.filter(item => {
        if(item.id !== JSON.parse(STORY_ID)){
            return item
        }
    })
    
    const handleStoriesDetails = (id) => {
        closeFormHandler()
        history.push(`/my-family-first/stories-details/${id}`)
        window.scroll(0,0)
    }
    return (
        <div>
        {
            FIlTERED_SIMILAR_STORIES && FIlTERED_SIMILAR_STORIES.length > 0 ?  
        <div className="container">
            <div className="nearby-experiences similar-offers">
                <h5>Read similar  <span>stories</span>  like this</h5>
                <div className="divider"></div>
                <OwlCarousel className="owl-theme" {...corporate_options} >
                {
                    FIlTERED_SIMILAR_STORIES && FIlTERED_SIMILAR_STORIES.length > 0 && FIlTERED_SIMILAR_STORIES.map((items) => (
                        <div className="item" key={items?.id} onClick={() => handleStoriesDetails(items?.id)}>
                            {/* <Link to={`/my-family-first/stories-details/${items.id}`}> */}
                            <div className="card-wrapper d-lg-flex flex-column" style={{cursor: 'pointer'}} >
                                {items.media.length > 0 && items.media.map((image) => {
                                    if(image.thumbnail){
                                        return <img src={image.image} className="w-100" alt={items.title} />
                                    }
                                }) }
                                
                                <div className="info">
                                   <p className="title">{items.title}</p>
                                  
                                </div>
                            </div>
                            {/* </Link> */}
                        </div>
                    ))
                }
                </OwlCarousel>
            </div>
        </div> : null
        }
           
        </div>
    )
}

export default SimilarStories
