// recognitions
import recognition1 from "../assets/images/home/recognitions/Incredible-India.svg";
import recognition2 from "../assets/images/home/recognitions/lato.svg";
import recognition3 from "../assets/images/home/recognitions/adtoi.svg";
import recognition4 from "../assets/images/home/recognitions/taai.svg";
import recognition5 from "../assets/images/home/recognitions/tafi.svg";
import recognition6 from "../assets/images/home/recognitions/atoai.svg";
import recognition7 from "../assets/images/home/recognitions/ktf.svg";
import recognition8 from "../assets/images/home/recognitions/tids.svg";

// partners

import partner1 from "../assets/images/home/partners/taj.svg";
import partner2 from "../assets/images/home/partners/leela-palace.svg";
import partner3 from "../assets/images/home/partners/evolve-black.svg";
import partner4 from "../assets/images/home/partners/ananda.svg";
import partner5 from "../assets/images/home/partners/jungle-lodges.svg";
import partner6 from "../assets/images/home/partners/cgh-earth.svg";
import partner7 from "../assets/images/home/partners/windflower.svg";

import partner8 from "../assets/images/home/partners/vythri.svg";
import partner9 from "../assets/images/home/partners/royal-orchid.svg";
import partner10 from "../assets/images/home/partners/sarovar.svg";
import partner11 from "../assets/images/home/partners/sterling.svg";
import partner12 from "../assets/images/home/partners/club-mahindra.svg";
import partner13 from "../assets/images/home/partners/raviz.svg";
import partner14 from "../assets/images/home/partners/muddy-bots.svg";
import partner15 from "../assets/images/home/partners/unhurried.svg";

import partner16 from "../assets/images/home/partners/rare-circle.svg";
import partner17 from "../assets/images/home/partners/lemon-tree.svg";
import partner18 from "../assets/images/home/partners/secrets-retreat.svg";
import partner19 from "../assets/images/home/partners/team-activators.svg";

export const RECOGNITIONS = [
  {
    name: "Department of Tourism, Government of India",
    image: `${recognition1}`,
  },
  {
    name: "Indian Association of Tour Operators",
    image: `${recognition2}`,
  },
  {
    name: "Travel Agents Federation Of India",
    image: `${recognition5}`,
  },
  {
    name: "Association of Domestic Tour Operators of India",
    image: `${recognition3}`,
  },
 
  
];

export const RECOGNITION2 = [
  {
    name: "Travel Agents Association Of India",
    image: `${recognition4}`,
  },
  {
    name: "Travel Industry Designator Service",
    image: `${recognition8}`,
  },
  {
    name: "Karnataka Tourism Forum",
    image: `${recognition7}`,
  },
]

export const PARTNERS_LIST1 = [
  
  {
    name: "Taj Hotels",
    image: `${partner1}`,
  },
  {
    name: "The Leela Palaces, Hotels and Resorts",
    image: `${partner2}`,
  },
  {
    name: "Evolve Back Luxury Resorts",
    image: `${partner3}`,
  },
  {
    name: "Jungle Lodges and Resorts",
    image: `${partner5}`,
  },
  {
    name: "CGH Earth Hotels And Resorts",
    image: `${partner6}`,
  },
  {
    name: "The Windflower Resorts & Spa",
    image: `${partner7}`,
  },
  {
    name: "RARE India Luxury Travel",
    image: `${partner16}`,
  },
  {
    name: "SECRET Retreats",
    image: `${partner18}`,
  },
  {
    name: "Lemon tree Hotels",
    image: `${partner17}`,
  },
];

export const PARTNERS_LIST2 = [
  
  {
    name: "Vythri Resort",
    image: `${partner8}`,
  },
  {
    name: "Royal Orchid Hotels",
    image: `${partner9}`,
  },
  {
    name: "Sarovar Hotels",
    image: `${partner10}`,
  },
  {
    name: "Sterling Holiday Resorts (India) Limited",
    image: `${partner11}`,
  },
  {
    name: "Club Mahindra Holidays",
    image: `${partner12}`,
  },
  {
    name: "Team Activators",
    image: `${partner19}`,
  },
  {
    name: "The Raviz",
    image: `${partner13}`,
  },
  {
    name: "Muddy Boots",
    image: `${partner14}`,
  },
  {
    name: "Unhurried Walks",
    image: `${partner15}`,
  },
 
];
