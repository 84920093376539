import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Home from "./home";
import CorporatePackages from "./corporatePackages";
import PackageDetails from "./packageDetails";
import { useHistory, useLocation } from "react-router-dom";
import CategoryHolder from "./categoryHolder";
import { useSnackbar } from "notistack";

function Index() {
  const history=useHistory()
  const { enqueueSnackbar } = useSnackbar();
  const isCorporate = useSelector(
    (state) => state.auth.login_user_otp?.corporate_account
  );
  const SCROLL_REF = useSelector((state) => state.userProfile.set_Scroll);
  const CORP_LIST = useSelector(
    (state) => state.corporates?.corporates_category.data
  ); 
  const location=useLocation()
  const redirectUrl=sessionStorage.getItem("corporateReadirectUrl")
  const[category,setCategory]=useState(null)
  useEffect(() => {
    if(isCorporate){
    const fromCheckout=localStorage.getItem("checkoutParams")
    if(fromCheckout){
      history.push(`/checkout-details/${fromCheckout}`)
      setTimeout(() => {
        localStorage.removeItem("checkoutParams")
      },1000);
    }
    if(redirectUrl){
      history.push(redirectUrl)
      sessionStorage.removeItem("corporateReadirectUrl")
    }
  }
  }, [isCorporate]);

useEffect(()=>{
  if(location?.state?.redirectUrl&&!isCorporate&&SCROLL_REF){
    enqueueSnackbar("Please Login With Your Corporate Account", {
      variant: "error",
    });
  }
},[redirectUrl])
  return (
    <div>
      {isCorporate ?<>
      {CORP_LIST&&
        <div className={`d-none ${CORP_LIST?.length>5?'d-lg-block':''}`}>
<CategoryHolder setCategory={setCategory} categoryList={CORP_LIST}  />
</div>
}
<CorporatePackages categoryData={category} /></> : <Home />}

    </div>
  );
}

export default Index;
