import React from 'react'
import RightIcon from "../../assets/icons/right_icon.svg"
import { useHistory } from "react-router-dom";
const Breadcrum = ({ goBackFun, section, module, corporate}) => {

    const history = useHistory();
    const prevUrl = localStorage.getItem("prevUrl")?.split('/')
    let path = module.toLowerCase().replaceAll(" ", "-")
    const handleRoute = (route) => {
        if(route == "holidays"){
            history.push({ pathname: `/${route}/bharat-dekho`});
        }else{
            history.push({ pathname: `/${route}`});
        }
        window.scroll(0,0)
      };
    const handleCorpRoute = (route) => {
        localStorage.setItem("corporateCategory", section?.id )
        history.push({ pathname: `/${route}`});
        window.scroll(0,0)
    }
      
            

  return (
        <p>
            <span><a onClick={() => handleRoute('')}>Home</a></span><img src={RightIcon} />
            {corporate ?<span><a onClick={() => handleCorpRoute('corporates')}>{module}</a></span> : <span><a onClick={() => handleRoute(`${path}`)}>{module}</a></span>}
            {/* <img src={RightIcon} />
            <span>{sectionName}</span> */}
            {path == "my-family-first" || path == "weddings" ? "" : <img src={RightIcon} /> }
            {corporate ? <span><a onClick={() => handleCorpRoute('corporates')}>{section?.name}</a></span> :<span><a onClick={() => handleRoute(`${path}${corporate ? "" : "/"}${corporate ? "" : section?.section_slug}`)}>{section?.name}</a></span> }
        </p>
  )
}

export default Breadcrum