import React, {useState} from "react";

import "../../assets/css/partials/common/filterCards.scss";
import "../../assets/css/partials/corporates/corporates.scss";


const Category = (props) =>{

    //const [id, setId] = useState(1)
    const {CATEGORY,id ,ChangeCategory} = props

    const selectCategory = (event) => {
        ChangeCategory(event.currentTarget.id)
    }
    return(
        <>
        <div className="filter-holder corporate-category-filter">
            <div id="filter-wrapper" className={CATEGORY && CATEGORY.length <= 2 ? 'text-center card-wrapper draggable-wrapper' : 'text-center filter-wrapper draggable-wrapper'}>
                {
                    CATEGORY &&
                    CATEGORY.length > 0 &&
                    CATEGORY.map((item) => 
                    (
                      <div 
                      className={ id == item.id ? "filter-card active-category" : "filter-card" }
                      onClick={selectCategory}
                      id={item.id} >
                        <div className="img-gradient">
                          <div className="cat-img">
                            <img src={item.image} alt={item.title} />
                          <div class="pseudo"></div>
                        </div>
                        <div className="card-info">
                          <p>{item.title}</p>
                        </div>
                      </div>
                    </div>
                    ))
                }
            </div>
        </div>
        </>
    )
}
export default Category