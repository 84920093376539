import offer1 from '../assets/images/dummy/offer1.png';
import offer2 from '../assets/images/dummy/offer2.png';
import offer3 from '../assets/images/dummy/offer3.png';
import offer4 from '../assets/images/dummy/offer4.png';


export const EXPERIENCES_NEARBY = [
    {
        image: `${offer1}`,
        title: 'Wine tour 1 hr away',
        description: 'Private vineyard with 25 years of heritage, guided tour and tasting',
        points: '1,000'
    },
    {
        image: `${offer2}`,
        title: 'Microlight flying',
        description: 'Experience 30 min of sailing at 5000 ft in the sky',
        points: '6,000'
    },
    {
        image: `${offer3}`,
        title: 'Paddington Resort & Spa – Coorg',
        description: 'A luxury offering near the banks of Mother Cauvery, abodes those subtleti..',
        points: '10,000'
    },
    {
        image: `${offer4}`,
        title: 'Royal Orchid each Resort -South Goa',
        description: 'A beach-front 5-Star resort in India’s favorite holiday destination, Royal Orchid Beach Resort & Spa is the favoured',
        points: '15,000'
    }
];

export const SIMILAR_OFFERS = [
    {
        image: `${offer3}`,
        title: 'Paddington Resort & Spa – Coorg',
        description: 'A luxury offering near the banks of Mother Cauvery, abodes those subtleti..',
        points: '10,000'
    },
    {
        image: `${offer4}`,
        title: 'Royal Orchid each Resort -South Goa',
        description: 'A beach-front 5-Star resort in India’s favorite holiday destination, Royal Orchid Beach Resort & Spa is the favoured',
        points: '15,000'
    },
    {
        image: `${offer1}`,
        title: 'Wine tour 1 hr away',
        description: 'Private vineyard with 25 years of heritage, guided tour and tasting',
        points: '1,000'
    },
    {
        image: `${offer2}`,
        title: 'Microlight flying',
        description: 'Experience 30 min of sailing at 5000 ft in the sky',
        points: '6,000'
    }
   
];