import React, { useState } from 'react';
import giftCard from '../../../assets/images/holidays/sendGift/Gift.svg';
import '../../../assets/css/partials/holidays/sendGift/giftWrapBox.scss';
import '../../../assets/css/partials/holidays/sendGift/sendGift.scss';
import '../../../assets/css/partials/holidays/sendGift/giftVoucher.scss';
import EditImage from "../../../assets/images/common/edit-image.svg";
import icon from "../../../assets/images/holidays/sendGift/icon.svg";
import { useHistory } from "react-router-dom";
import { Slider, Snackbar } from "@material-ui/core";
import * as HolidaysAction from "../../../redux/actions/holidaysActions";
import CloseIcon from '../../../assets/images/common/close-modal.svg';
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Fragment } from "react";
import styled from "styled-components";
import Dialog from '@material-ui/core/Dialog';
import Edit from '../../../assets/images/common/Edit.svg';
import {
    VOUCHERS_DATA,
} from '../../../resources/holidaysData';
import LoginPopup from '../../auth/login'


function SendGift() {

    const [voucherPrice, setVoucherPrice] = useState(0)
    // const { enqueueSnackbar } = useSnackbar();
    const [showModal, setShowModal] = useState(false);
    const userDetails = JSON.parse(localStorage.getItem("userData"));
    const isCorporateAccount = userDetails?.user_details?.corporate_account;
    const dispatch = useDispatch();
    const [isImageUpload, setIsImageUpload] = useState(false)
    const [state, setState] = useState({
        price: 15000,
        title: '',
        image: '',
        message: '',
        request: '',
        name: '',
        email: '',
        contactNumber: ''

    })

    const [error, setError] = useState({
        voucher: false,
        minPrice: false,
        titleLimit: false,
        messageLimit: false,
        uploadedImage: false,
    })

    const [selected, setSelected] = useState({
        id: "",
    })
    const [uploadedImage, setUploadedImage] = useState({
        image: "",
    })

    const history = useHistory();
    const StyledDialog = styled(Dialog)`
    & > .MuiDialog-container > .MuiPaper-root {
        width: 430px;
        background-color: #F5F5F5;
        position: relative
    }
    & > .MuiDialog-container > .MuiPaper-root > .close-login {
        position: absolute;
        top: 12px;
        right: 23px;
        cursor: pointer
      }
    `;

    const handleRangeSlider = (event, value) => {
        setVoucherPrice(value)
        document.querySelector('#price').style.color = "#222";
        if (!state.title || state.title == "") {
            setError({
                voucher: true,

            })
        } else {
            setError({ voucher: false })
            setState({
                ...state,
                // price: Math.ceil(value / 100) * 100,
                price: value
            })
              
        }
    }

    const [updatedMessage, setUpdatedMessage] = useState({
        message: ''
    })
    const onChangeHandler = (key, e, index) => {
        if( e.target.value.length > 129){
            setError({
                messageLimit: true,
            })
        } else{
            setError({
                messageLimit: false,
            })
        }
        setState({
            ...state,
            [key]: `${index}_${e.target.value}`
        })
        setUpdatedMessage({
            [key]: `${index}_${e.target.value}`
        })
        
    }

    const onChangeTitleHandler = (key, e) => {
        if(e.target.value.length > 25){
            setError({
                titleLimit: true,
            })
        } else{
            setError({
                titleLimit: false,
            })
        }
        setState({
            ...state,
            [key]: e.target.value
        })
    }

    const requestMessageHandler = (key, e) => {
        setState({
            ...state,
            [key]: e.target.value
        })
    }
    const amountHandler = (value) => {
        setState({
            ...state,
            price: value
        })

    }

    const submitHandler = (e) => {
        // e.preventDeault()

        const getToken = localStorage.getItem("token");
        if (getToken === "" || getToken == null) {
            // alert("please login");
            setShowModal(true);
        } else if(state.title == ""){
            setError({
                voucher: true
            })
        }  else if(state.price <= 1000){
            setError({
                minPrice: true
            })
        } else if(state.title == "Custom Title" && !isImageUpload){
            setError({
                uploadedImage: true
            })
        } 
        else {
            let userData = JSON.parse(localStorage.getItem("userData"));
            const points = parseInt(localStorage.getItem("points"));
            if (isCorporateAccount) {
                const data = {
                    ...state,
                    image: isImageUpload ? uploadedImage.image : state.image,
                    points: points,
                };
                dispatch(HolidaysAction.handleBuyVoucher(data));
            } else {
                const data = {
                    ...state,
                    image: isImageUpload ? uploadedImage.image : state.image,
                };
                dispatch(HolidaysAction.handleBuyVoucher(data));
            }
            // dispatch(HolidaysAction.handleBuyVoucher(data));
            history.push("/buying");
            window.scrollTo(0, 0);
        }
    }

    const uploadVoucherImage = (event) => {
        dispatch(
            HolidaysAction.uploadVoucherPic("image", event.target.files[0], updatePic)
        );
    };

    const updatePic = (data) => {
        if (data?.url) {
            setUploadedImage({ ...uploadedImage, image: data.url });
            setIsImageUpload(true)
        } else {
            // disaptch error
        }
    };

    const handleVoucher = (item, index) => {
        let userData = JSON.parse(localStorage.getItem("userData"));
        setError({ voucher: false })
        setSelected({
            id: index
        })
        if (state.title == '') {
            setState({
                ...state,
                // price: val,
                image: item.image,
                title: item.title,
                message: `${index}_${item.description}`,
                name: userData?.user_details.full_name,
                email: userData?.user_details.email,
                contactNumber: userData?.user_details.mobile,
            })

        } else {
            setState({
                ...state,
                // price: val,
                image: item.title == "Custom Title" ? uploadedImage.image : item.image,
                title: item.title == "Custom Title" ? state.title : item.title,
                // image: item.title == "Custom Title" ? state.image : item.image,
                // title: item.title == "Custom Title" ? state.title : item.title,
                message: updatedMessage.message == "" ? `${index}_${item.description}` : updatedMessage.message,
            })
        }

    }

    const handleClose = () => {
        setShowModal(false);
    }

    return (
        <Fragment>
            <div className="sendGift-holder">
                <div className="row">
                    <div className="col-lg-7 col-sm-12 col-md-12">

                        <div className="giftWrap-img">
                            <img src={giftCard} alt="" className="img-fluid" />
                            <div className="giftWrap-content">
                                <span>Rs {state.price}</span>
                                <p>Select an amount range you'd like to gift someone for an occasion, and we will help the recipient plan  their dream trip.</p>
                            </div>
                        </div>
                        <div className=""></div>
                        <div className="giftVoucher-wrapper container-fluid desk">
                            {VOUCHERS_DATA && VOUCHERS_DATA.length > 0 &&
                                VOUCHERS_DATA.map((item, index) => (

                                    <div
                                        id={index}
                                        className={selected.id === index ? 'giftVoucher-holder voucher-selected' : 'giftVoucher-holder  '}
                                        onClick={() => handleVoucher(item, index)}
                                    >
                                        <div className="gift-voucher">
                                            <div className="img-wrapper">
                                                {item.title !== 'Custom Title' ? <img src={item.image} alt="voucher-image" className="voucher-image" /> : ""}

                                                {item.title == 'Custom Title' ?
                                                    <div>
                                                        <img src={ isImageUpload ? uploadedImage.image : item.image } alt="" className="voucher-image" />
                                                        <div class="edit-image">
                                                            <div>
                                                                <input
                                                                    className="upload-file"
                                                                    type="file"
                                                                    onChange={uploadVoucherImage}

                                                                />
                                                                <img src={EditImage} alt="" />
                                                            </div>
                                                        </div>
                                                    </div> : null}

                                            </div>
                                            {selected.id === index ? <img src={icon} alt="" class="select-icon" /> : ''}
                                            <div className="voucher-content">
                                                {item.title !== "Custom Title" ?
                                                    <h2>{item.title}</h2>
                                                    : null}
                                                {item.title == "Custom Title" ?
                                                    <input
                                                        type="text"
                                                        placeholder={item.title}
                                                        maxlength="26"
                                                        onChange={(value) => onChangeTitleHandler('title', value, index)} />
                                                    : null}
                                                <div className="line"></div>
                                                {/* <p>{item.description}</p> */}
                                                <div className="description">
                                                    <div className="input-holder">
                                                        <textarea
                                                            // type="text"
                                                            cols="auto"
                                                            placeholder={item.description}
                                                            value={state.message.split("_")[0] == `${index}` ? state.message.split("_")[1] : item.description}
                                                            onChange={(value) => onChangeHandler('message', value, index)}
                                                            maxLength="130"
                                                        />
                                                    </div>
                                                    
                                                </div>
                                                {item.title == "Custom Title" && error.titleLimit ? <span className="errorMsg mb-4 text-center" style={{ display: 'block',position: 'absolute' }}>Excedded title limit (max 25 characters) </span> : null}
                                                { state.title == item.title && error.messageLimit ? <span className="errorMsg mb-4 text-center" style={{ display: 'block',position: 'absolute' }}>Excedded message limit (max 130 characters) </span> : null}
                                            </div>
                                        </div>
                                    </div>
                                    
                                ))
                            }
                        </div>
                    </div>
                    <div className="col-lg-5 col-sm-12 col-md-12">
                        <div className="login-holder giftForm-holder desk">
                            <h1>Send a Gift</h1>
                            <h2>Fill details below to proceed</h2>
                            <div className="info-box">
                                <h6>Sending a {
                                    state.title
                                } gift worth</h6>

                            </div>
                            {error.voucher ? <span className="errorMsg mb-4 text-center" style={{ display: 'block' }}>Please select voucher </span> : null}

                            <form >
                                <div className="input-holder">
                                    <div className="custom-input price-color">
                                        <input
                                            id="price"
                                            name="price"
                                            type="text"
                                            class="form-control"
                                            value={state.price}
                                            // onChange={(value) => amountHandler(value.target.value)}

                                        />
                                    </div>
                                    <br />
                                    <div >
                                        <Slider 
                                            // min="1000"
                                            max="50000"
                                            value={state.price}
                                            onChange={handleRangeSlider}
                                            aria-labelledby="input-slider"
                                            step="500" 
                                        />
                                    </div>
                                    <br />
                                </div>
                                {error.minPrice ? <span className="errorMsg mb-4 text-center" style={{ display: 'block' }}>Minimum voucher price 1000 </span> : null}
                                <div className="input-holder card--bg2">
                                    <div class="custom-input  mb20">
                                        <p>Any request from us</p>
                                        <div className="inner-card">
                                            <input
                                                value={state.request}
                                                type="text"
                                                class="form-control"
                                                placeholder="How would you like hammock to help you"
                                                onChange={(value) => requestMessageHandler('request', value)}
                                            />
                                            <label>Example:</label>
                                            <label>I am out of points, Can i extend my stay by paying</label>
                                        </div>

                                    </div>
                                </div>
                                <div className="line"></div>
                                {error.uploadedImage ? <span className="errorMsg mb-4 text-center" style={{ display: 'block' }}>Please upload image </span> : null}
                                <button type="button" className="btn otp-btn gift-send-btn" onClick={submitHandler}>
                                    Send Gift Voucher
                            </button>
                            </form>
                        </div>
                    </div>
                    <div className="container">
                        <div className="col-12">
                            <div className="giftForm-wrapper mobile">

                                {error.voucher ? <span className="errorMsg mb-4 text-center" style={{ display: 'block' }}>Please select voucher </span> : null}

                                <form >
                                    <div className="input-holder">
                                        <div className="custom-input">
                                            <input
                                                name="price"
                                                type="text"
                                                class="form-control"
                                                value={state.price}

                                            />
                                        </div>
                                        <br />
                                        <div >
                                            <Slider 
                                            max="50000"
                                            value={state.price}
                                            onChange={handleRangeSlider}
                                            aria-labelledby="input-slider"
                                            step="500"
                                            />
                                        </div>
                                        <br />
                                    </div>
                                    {error.minPrice ? <span className="errorMsg mb-4 text-center" style={{ display: 'block' }}>Minimum voucher price 1000 </span> : null}
                                    <div className="giftVoucher-wrapper container-fluid">
                                        {VOUCHERS_DATA && VOUCHERS_DATA.length > 0 &&
                                            VOUCHERS_DATA.map((item, index) => (
                                                
                                                <div
                                                    id={index}
                                                    className={selected.id === index ? 'giftVoucher-holder voucher-selected' : 'giftVoucher-holder  '}
                                                    onClick={() => handleVoucher(item, index)}
                                                >
                                                    <div className="gift-voucher">
                                                        <div className="img-wrapper">
                                                            {item.title !== 'Custom Title' ? <img src={item.image} alt="" className="voucher-image" /> : ""}

                                                            {item.title == 'Custom Title' ?
                                                                <div>
                                                                    <img src={ isImageUpload ? uploadedImage.image : item.image } alt="" className="voucher-image" />
                                                                    <div class="edit-image">
                                                                        <div>
                                                                            <input
                                                                                className="upload-file"
                                                                                type="file"
                                                                                onChange={uploadVoucherImage}

                                                                            />
                                                                            <img src={EditImage} alt="" />
                                                                        </div>
                                                                    </div>
                                                                </div> : null}

                                                        </div>
                                                        {selected.id === index ? <img src={icon} alt="" class="select-icon" /> : ''}
                                                        <div className="voucher-content">
                                                            {item.title !== "Custom Title" ?
                                                                <h2>{item.title}</h2>
                                                                : null}

                                                            {item.title == "Custom Title" ?
                                                                <input
                                                                    type="text"
                                                                    placeholder={item.title}
                                                                    maxlength="26"
                                                                    onChange={(value) => onChangeTitleHandler('title', value, index)} />
                                                                : null}
                                                            <div className="line"></div>
                                                            {/* <p>{item.description}</p> */}
                                                            <div className="description">
                                                                <div className="input-holde">
                                                                    <textarea
                                                                        // type="text"
                                                                        cols="auto"
                                                                        placeholder={item.description}
                                                                        value={state.message.split("_")[0] == `${index}` ? state.message.split("_")[1] : item.description}
                                                                        onChange={(value) => onChangeHandler('message', value, index)}
                                                                        maxLength="130"
                                                                    />
                                                                </div>
                                                            </div>{item.title == "Custom Title" && error.titleLimit ? <span className="errorMsg mb-4 text-center" style={{ display: 'block',position: 'absolute' }}>Excedded title limit (max 25 characters) </span> : null}
                                                            { state.title == item.title && error.messageLimit ? <span className="errorMsg mb-4 text-center" style={{ display: 'block',position: 'absolute' }}> (max 130 characters) </span> : null}
                                                        </div>
                                                    </div>
                                                </div>

                                            ))
                                        }
                                    </div>
                                    <div className="input-holder card--bg2">
                                        <div class="custom-input  mb20">
                                            <p style={{ textAlign: 'center' }}>Any request from us</p>
                                            <div className="inner-card">
                                                <input
                                                    value={state.request}
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="How would you like hammock to help you"
                                                    onChange={(value) => requestMessageHandler('request', value)}
                                                />
                                                <label>Example:</label><br />
                                                <label>I am out of points, Can i extend my stay by paying</label>
                                            </div>

                                        </div>
                                    </div>
                                    {/* <div className="line"></div> */}
                                    {error.uploadedImage ? <span className="errorMsg mb-4 text-center" style={{ display: 'block' }}>Please upload image </span> : null}
                                    <button type="button" className="btn otp-btn gift-send-btn" onClick={submitHandler}>
                                        Send Gift Voucher
                                </button>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="events-login-holder">
                    <StyledDialog
                        open={showModal}>
                        <div className="close-login" onClick={handleClose}>
                            <img src={CloseIcon} />
                        </div>
                        <LoginPopup />
                    </StyledDialog>
                </div>
            </div>
        </Fragment>
    )
}

export default SendGift;
