import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import * as corporateActions from "../../redux/actions/corporatesAuthActions";
import "../../assets/css/partials/corporates/corporates.scss";
import { testEmail } from "../../utils/common";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import requestReceived from '../../assets/images/corporates/employer-form.svg';
import closeIcon from '../../assets/images/common/close-modal.svg';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import IntlContactNumber from '../common/intlContactNumber';
import { TextField } from "@material-ui/core";


function EmployerForm({ openModal, employerFormMobileRef,onInputFocus }) {
  const { enqueueSnackbar } = useSnackbar();
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [state, setState] = useState({
    organisation: "",
    organisation_length: "",
    emp_name: "",
    email: "",
    mobile: "",
    message: "",
  });

  const [error, setError] = useState({
    organisation: false,
    emp_name: false,
    organisation_length: false,
    email: false,
    mobile: false,
    message: false,
    valid_email: false,
  })

  const handleChange = (key, value) => {
    if(key==="emp_name"){
      const re = /^[A-Za-z ]+$/;
      if (value.target.value === '' || re.test(value.target.value)) {
        setState(() => ({
          ...state,
          [key]: value.target.value,
        }));
      }
    }else{
    setState(() => ({
      ...state,
      [key]: value.target.value,
    }));
  }
  setError({
    ...error,
    [key]: false,
  });
  };
  const mobileNumberChange=(val)=>{
    setState(() => ({
      ...state,
      mobile: val,
    }))
    setError({
      ...error,
      mobile: false,
    });
  }

  const handleEnquiry = (e) => {
    e.preventDefault();
    console.log('has error');
    if (!state.emp_name || state.emp_name === "") {
      focusInput(employerFormMobileRef ? '#emp_name_mobile' : '#emp_name_form');
      setError({ emp_name: true })
    }
    else if (!state.organisation || state.organisation === "") {
      focusInput(employerFormMobileRef ? '#organisation_mobile' : '#organisation_form');
      setError({ organisation: true })
    }
    else if (state.organisation.length < 2) {
      focusInput(employerFormMobileRef ? '#organisation_mobile' : '#organisation_form');
      setError({ organisation_length: true })
    }
    else if (!state.email || state.email === "") {
      focusInput(employerFormMobileRef ? '#email_mobile' : '#email_form');
      setError({ email: true })
    }
    else if (!testEmail(state.email)) {
      focusInput(employerFormMobileRef ? '#email_mobile' : '#email_form');
      setError({ valid_email: true })
    }
    else if ((state.mobile.startsWith('+91') && state.mobile.length !== 13) || !isPossiblePhoneNumber(state.mobile)) {
      focusInput('.PhoneInputInput');
      setError({ mobile: true })
    }
    else if (!state.message || state.message === "") {
      focusInput('#message_form');
      setError({ message: true })
    } else {
      dispatch(
        corporateActions.corporateEnquiry({ ...state, mobile: state.mobile }, handleSuccess, handleErrors)
      );

    }
  };

  // form submitted successfully
  const handleSuccess = () => {
    setShowModal(true)
    setTimeout(() => {
      refreshState();
      setShowModal(false);
      openModal && openModal(false);
    }, 2000)
  };

  const refreshState = () => {
    setState({
      organisation: "",
      emp_name: "",
      email: "",
      mobile: "",
      message: "",
    });
    setError({
      organisation: false,
      emp_name: false,
      email: false,
      mobile: false,
      message: false,
    });
  }

  const handleErrors = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
    });
  };
  const handleClose = () => {
    openModal(false);
  };

  const focusInput = (inputDom) => {
    var elementPosition = document.querySelector(`${inputDom}`).getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - 145;
    window.scrollTo({ top: offsetPosition, behavior: "smooth" });
    setTimeout(() => {
      document.querySelector(inputDom).focus();
    });
    if(!employerFormMobileRef){
      onInputFocus()
    }
  };

  return (
    <div>
      <div className="login-holder corp-employer-form" ref={employerFormMobileRef}>
        <h5 class="connect-title"><span>Connect with us</span></h5>
        <span className="d-block d-md-none closeIcon" onClick={handleClose}><img src={closeIcon} className="close-icon" /></span>
        <div class="line"></div>
        <form onSubmit={handleEnquiry}>
          <div className="row">

            <div class="col-12 col-md-6 pr-lg-2 corporate-enquiry-columns">
              <div className="input-holder">
                <TextField label="Name*" variant="standard"
                  className="w-100"
                  value={state.emp_name}
                  type="text"
                  onKeyPress={(event) => {
                    if (!/^[A-Za-z ]+$/.test(event.key)) {
                        event.preventDefault();
                    }
                }}
                  id={employerFormMobileRef ? 'emp_name_mobile' : 'emp_name_form'}

                  onChange={(value) => handleChange("emp_name", value)} />

                <br />
                {error.emp_name === true ? <span className="errorMsg">Please enter name </span> : null}
              </div>

              <div className="input-holder">


                <TextField label="Email-id*" variant="standard" value={state.email}
                  id={employerFormMobileRef ? 'email_mobile' : 'email_form'}
                  type="text"
                  className="w-100"
                  onChange={(value) => handleChange("email", value)} />

                <br />
                {error.email === true ? <span className="errorMsg">Please enter a email Id </span> : null}
                {error.valid_email === true ? <span className="errorMsg">Please enter a valid email Id</span> : null}
              </div>
              <div className="input-holder">

                <TextField label="Additional message"
                  className="w-100"
                  value={state.message}
                  type="text"
                  id={employerFormMobileRef ? 'message_mobile' : 'message_form'}
                  onChange={(value) => handleChange("message", value)} variant="standard" />
                <br />
                {error.message === true ? <span className="errorMsg">Please enter message </span> : null}
              </div>

            </div>
            <div class="col-12 col-md-6 pl-lg-2 corporate-enquiry-columns-right ">
              <div className="input-holder">
                <TextField className="w-100" id={employerFormMobileRef ? 'organisation_mobile' : 'organisation_form'}


                  value={state.organisation}
                  onChange={(value) => handleChange("organisation", value)}
                  label="Organisation*" variant="standard" />



                <br />
                {error.organisation === true ? <span className="errorMsg">Please enter Organisation name </span> : null}
                {error.organisation_length === true ? <span className="errorMsg">Organisation name should be of minimum 2 characters</span> : null}

              </div>

              {/* <div class="custom-input card--bg mb20">
                <input
                  value={state.mobile}
                  type="text"
                  class="form-control"
                  placeholder="Mobile number"
                  onChange={(value) => handleChange("mobile", value)} onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
                <div class="form-line mb20"></div>
                <label>Mobile number</label>
                <br />
                {error.mobile === true ? <span className="errorMsg">Please enter a valid 10 digits mobile number</span> : null}
              </div> */}
              <div className="coprate-mobile">
                <IntlContactNumber disabled={false} hasFormLine={true} titleValue={'Mobile number*'} labelClass={'w-100'} hideSaveBtn={true} mobile={`${state.mobile}`} numberChange={(val) => mobileNumberChange(val)}
                  hasError={error.mobile} />
              </div>
              <div className="text-right">
                <button type="sumbit" className="btn enquiry-btn w-auto">
                  Send enquiry
                </button>
              </div>


            </div>
          </div>


        </form>
      </div>

      <div className="login-success-popup">
        <Dialog
          open={showModal}
        >
          <DialogTitle>
            <div className="success-holder" style={{ margin: '50px' }}>
              <div>
                <img src={requestReceived} alt="successful" />
              </div>
              <div className="success-message">
                <p>Request recieved</p>
                <p>We will go through your request, <br />
                  and connect with you shortly</p><hr></hr>
              </div>

            </div>
          </DialogTitle>
        </Dialog>
      </div>
    </div>
  );
}

export default EmployerForm;
