import React from 'react'
import failureIcon from '../../../assets/images/common/failure.svg';

function paymentFailure() {
    return (
        <div>
        <div className="payment-status">
            <div className="text-center">
                <img src={failureIcon} alt="" />
            </div>
            <div className="payment-details text-center">
                <p className="status">Payment failed of Rs 5000</p>
                <p className="transactionId">Transaction ID : EfgD25sdfgfDFSDF</p>
                <p className="message">Amount wasn’t recieved, if any debited amount will be <br/> credited to you soon</p>
            </div>
        </div>
        </div>
    )
}

export default paymentFailure
