import {
  LOGIN_USER,
  LOGIN_USER_ID,
  LOGIN_USER_OTP,
  LOGIN_USER_NOT_EXIST_ERROR_MESSAGE,
  GET_EMAIL,
  SIGN_UP,
  SIGN_UP_USER_ID,
  SIGN_UP_USER_OTP,
  LOGIN_WITH_GOOGLE,
  GOOGLE_ERROR_MESSAGE,
  SIGNUP_USER_EXIST_ERROR_MESSAGE,
  LOGIN_INVALID_OTP_ERROR_MESSAGE,
  SIGNUP_INVALID_OTP_ERROR_MESSAGE, SET_TOKEN, SIGNUP_EMAIL,
  OTP_SENT_TO_MESSAGE,
  SIGNUP_OTP_SENT_TO_MESSAGE,
  CORP_OTP_SEND_MESSAGE,
  IS_CORP
} from "../actions/types";

const initialState = {
  login: [],
  otpSentToMessage:"",
  signupOtpSentToMessage:"",
  corpOtpSendMessage:"",
  login_user_id: "",
  login_user_otp: {},
  login_error_message: {},
  get_email: "",
  login_invalid_otp_message: {},
  signup_invalid_otp_message: {},
  signup_user_otp: {},
  signUp: [],
  signup_email: "",
  signup_user_id: "",
  login_with_google: "",
  google_error_message: {},
  signUpUserExist: [],
  token: localStorage.getItem("token"),
  userData: localStorage.getItem("userData"),
  isCorp: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        login: action.payload,
      };
    case OTP_SENT_TO_MESSAGE:
      return {
        ...state,
        otpSentToMessage: action.payload,
      };
    case LOGIN_USER_ID:
      return {
        ...state,
        login_user_id: action.payload,
      };
    case LOGIN_USER_OTP:
      return {
        ...state,
        login_user_otp: action.payload,
      };
    case GET_EMAIL:
      return {
        ...state,
        get_email: action.payload,
      }; 
    case SIGN_UP_USER_OTP:
      return {
        ...state,
        signup_user_otp: action.payload,
      };
    case CORP_OTP_SEND_MESSAGE:
      return {
        ...state,
        corpOtpSendMessage: action.payload,
      };
    case SIGNUP_OTP_SENT_TO_MESSAGE:
      return {
        ...state,
        signupOtpSentToMessage: action.payload,
      };
    case LOGIN_USER_NOT_EXIST_ERROR_MESSAGE:
      return {
        ...state,
        login_error_message: action.payload,
      };
    case LOGIN_INVALID_OTP_ERROR_MESSAGE:
      return {
        ...state,
        login_invalid_otp_message: action.payload,
      };
    case SIGNUP_INVALID_OTP_ERROR_MESSAGE:
      return {
        ...state,
        signup_invalid_otp_message: action.payload,
      };
    case SIGN_UP:
      return {
        ...state,
        signUp: action.payload,
      };
    case SIGNUP_EMAIL:
      return {
        ...state,
        signup_email: action.payload,
      };
    case SIGN_UP_USER_ID:
      return {
        ...state,
        signup_user_id: action.payload,
      };
    case LOGIN_WITH_GOOGLE:
      return {
        ...state,
        login_with_google: action.payload,
      };
    case GOOGLE_ERROR_MESSAGE:
      return {
        ...state,
        google_error_message: action.payload,
      };

    case SIGNUP_USER_EXIST_ERROR_MESSAGE:
      return {
        ...state,
        signUpUserExist: action.payload,
      };
     case SET_TOKEN:
       return {
         ...state,
         token: action.payload
       } 
     case IS_CORP:
       return {
         ...state,
         isCorp: action.payload
       } 
    default:
      return state;
  }
};
export default authReducer