import {
  LOGIN_USER,
  LOGIN_USER_ID,
  LOGIN_USER_OTP,
  GET_EMAIL,
  LOGIN_USER_NOT_EXIST_ERROR_MESSAGE,
  SIGN_UP,
  SIGN_UP_USER_ID,
  SIGN_UP_USER_OTP,
  LOGIN_WITH_GOOGLE,
  LOGIN_INVALID_OTP_ERROR_MESSAGE,
  SIGNUP_INVALID_OTP_ERROR_MESSAGE, SET_TOKEN,
  OTP_SENT_TO_MESSAGE,
  SIGNUP_OTP_SENT_TO_MESSAGE,
  CORP_OTP_SEND_MESSAGE,
  IS_CORP
} from "../actions/types";
import axios from "axios";
import {
  axiosPost,
} from "../../services/apiServices";
import { API, BASE_URL } from "../../config/api";
export const createUserLogin = (email, enableOtp, handleErrors, fromEventModal, setInfoPopup) => (
  dispatch
) => {
  dispatch(resetUserLoginValues());
  axios({
    method: "post",
    url: `${BASE_URL}/user/account/login/`,
    data: {
      email,
    },
  })
    .then((res) => {
      dispatch({
        type: LOGIN_USER,
        payload: res.data,
      });
      dispatch({
        type: LOGIN_USER_ID,
        payload: res.data.data.user_id,
      });
      dispatch({
        type: GET_EMAIL,
        payload: email,
      });
      dispatch({
        type: OTP_SENT_TO_MESSAGE,
        payload: res.data.data.message,
      });
      // dispatch({
      //   type:USER_MESSAGE,
      //   payload:
      // })
      if(res.data.data.corp && fromEventModal){
        setInfoPopup(true)
      }else{
        enableOtp();
      }
    })
    .catch((err) => {
      handleErrors(err.response.data?.data?.message);
      // dispatch({
      //   type: LOGIN_USER_NOT_EXIST_ERROR_MESSAGE,
      //   payload: err.response.data,
      // });
    });
};

export const createCorpLoginUser = (email, enableOtp, disabledOtp, handleErrors, showCompanyNotExistsModal, hideCompanyNotExistsModal) => (
  dispatch
) => {
  axios({
    method: "post",
    url: `${BASE_URL}/user/account/corp-login/`,
    data: {
      email,
    },
  })
    .then((res) => {
      if(res.status === 200){
        dispatch({
          type: LOGIN_USER,
          payload: res.data,
        });
        dispatch({
          type: LOGIN_USER_ID,
          payload: res.data.data.user_id,
        });
        dispatch({
          type: GET_EMAIL,
          payload: email,
        });
        dispatch({
          type: CORP_OTP_SEND_MESSAGE,
          payload: res.data.data.message,
        });
        enableOtp();
      }
    })
    .catch((err) => {
      if(err.response.data.status_code === 400){
        if(err.response.data?.data?.message === "Company does not exist"){
          disabledOtp();
          showCompanyNotExistsModal()
          setTimeout(() => {
            hideCompanyNotExistsModal()
          }, 2000)
        }
        else {
          handleErrors(err.response.data?.data?.message);
          disabledOtp();
        }
      }
      // dispatch({
      //   type: LOGIN_USER_NOT_EXIST_ERROR_MESSAGE,
      //   payload: err.response.data,
      // });
    });
};


export const createSignUpUser = (signUpData, enableOtp, handleErrors, fromEventModal, setInfoPopup) => (
  dispatch
) => {
  axios({
    method: "post",
    url: `${BASE_URL}/user/account/signup/`,
    data: signUpData,
  })
    .then((res) => {
      console.log("vzxbvbvb", res)
      dispatch({
        type: SIGN_UP,
        payload: res,
      });
      dispatch({
        type: SIGN_UP_USER_ID,
        payload: res.data.data.user_id,
      });
      dispatch({
        type: GET_EMAIL,
        payload: signUpData.email,
      });
      dispatch({
        type: SIGNUP_OTP_SENT_TO_MESSAGE,
        payload: res.data.data.message,
      });
      dispatch({
        type: IS_CORP,
        payload: res.data.data.corp,
      });
      if(res.data.data.corp && fromEventModal){
        setInfoPopup(true)
      }else{
        enableOtp();
      }
    })
    .catch((err) => {
      handleErrors(err.response.data?.data?.message);
      // dispatch({
      //   type: SIGNUP_USER_EXIST_ERROR_MESSAGE,
      //   payload: err.response.data,
      // });
    });
};

export const createUserOtpLogin = (
  otpNumber,
  refreshState,
  handleErrors,
  showSuccessModal
) => (dispatch, getState) => {
  dispatch(resetInvalOtpErrMsg());
  const userId = getState().auth.login_user_id;
  axios
    .post(`${BASE_URL}/user/account/login-verify/`, {
      user_id: userId,
      otp: otpNumber,
    })
    .then((res) => {
      showSuccessModal();
      setTimeout(() => {
        dispatch({
          type: LOGIN_USER_OTP,
          payload: res.data,
        });
        dispatch({
          type: SET_TOKEN,
          payload: res.data.data.token,
        });
        saveDataToLocal(res.data.data);
        refreshState();
      }, 1000);
    })
    .catch((err) => {
      // dispatch({
      //   type: LOGIN_INVALID_OTP_ERROR_MESSAGE,
      //   payload: err.response.data,
      // });
      handleErrors(err.response.data?.data?.message);
    });
};

// verify otp for corporate page login
export const createCorpUserOtpLogin = (otp, refreshState, handleErrors, showCorporateSuccessModal) => {
  return async (dispatch, getState) => {
    const userId = getState().auth.login_user_id;
    const body = {
      user_id: userId,
      otp: otp,
    }
    axiosPost(API.CORPORATE_LOGIN, body)
      .then((res) => {
        if (res.data.status_code === 200) {
          showCorporateSuccessModal();
          setTimeout(() => {
            saveDataToLocal(res.data.data);
            dispatch({
              type: LOGIN_USER_OTP,
              payload: res.data.data.user_details,
            });
            dispatch({
              type: SET_TOKEN,
              payload: res.data.data.token,
            });
            refreshState();
          }, 2000)
        }
        else if (res.data.status_code === 400) {
          handleErrors(res.data?.data?.message);
        }
      })
      .catch((err) => {
        handleErrors(err.response.data?.data?.message);
      });
  };
};


// resend otp

export const resendOtp = (userId,handleOtpResendSuccess,handleErrors) => {
  return async (dispatch, getState) => {
    // const userId = getState().auth.login_user_id;
    const body = {
      user_id: userId,
    }
    axiosPost(API.RESEND_OTP, body)
    .then((res) => {
      if (res.data.status_code === 200) {
        handleOtpResendSuccess(res.data?.data?.message)
      }
    })
    .catch((err) => {
      handleErrors(err.data?.data?.message);
    });
  }
}


const saveDataToLocal = (data) => {
  localStorage.setItem("userData", JSON.stringify(data));
  localStorage.setItem("token", data.token);
  localStorage.setItem("points", data.points);
  localStorage.setItem("mobile", data.user_details.mobile);
  localStorage.setItem("full_name", data.user_details.full_name);
  localStorage.setItem("profileImage", data.user_details.image);
  if(data.user_details.mobile){
    localStorage.setItem("mobileAlertShown", true);
  }
};



export const setUserData = (data) => (dispatch, getState) => {
  dispatch({
    type: LOGIN_USER_OTP,
    payload: data,
  });
  dispatch({
    type: SET_TOKEN,
    payload: data.token,
  });
};

export const createUserOtpSignUp = (otpNumber, refreshState, handleErrors) => (
  dispatch,
  getState
) => {
  dispatch(resetInvalOtpErrMsgSignup());
  const userId = getState().auth.signup_user_id;
  axios
    .post(`${BASE_URL}/user/account/signup-verify/`, {
      user_id: userId,
      otp: otpNumber,
    })
    .then((res) => {
      dispatch({
        type: LOGIN_USER_OTP,
        payload: res.data,
      });
      saveDataToLocal(res.data.data);
      refreshState();
    })
    .catch((err) => {
      handleErrors(err.response.data?.data?.message);
    });
};
// login with google
export const loginWithGoogle = (response, handleErrors) => (dispatch) => {
  axios({
    method: "post",
    url: `${BASE_URL}/user/account/social-login/`,
    data: {
      email: response.additionalUserInfo.profile.email,
      uid: response.user.uid,
      displayName: response.user.displayName,
      providerID: response.additionalUserInfo.providerId,
      photoURL: response.user.photoURL,
    },
  })
    .then((res) => {
      dispatch({
        type: LOGIN_WITH_GOOGLE,
        payload: res.data,
      });
      saveDataToLocal(res.data.data);
      window.location.reload();
    })
    .catch((err) => {
      handleErrors(err.response.data?.data?.message);
    });
};

export const resetUserLoginValues = () => (dispatch) => {
  dispatch({
    type: LOGIN_USER,
    payload: [],
  });
  dispatch({
    type: LOGIN_USER_ID,
    payload: "",
  });
  dispatch({
    type: LOGIN_USER_NOT_EXIST_ERROR_MESSAGE,
    payload: {},
  });
};

export const resetInvalOtpErrMsg = () => (dispatch) => {
  dispatch({
    type: LOGIN_INVALID_OTP_ERROR_MESSAGE,
    payload: {},
  });
};

export const resetInvalOtpErrMsgSignup = () => (dispatch) => {
  dispatch({
    type: SIGNUP_INVALID_OTP_ERROR_MESSAGE,
    payload: {},
  });
};

export const handleUserLogout = () => (dispatch) => {
  dispatch({
    type: LOGIN_USER_OTP,
    payload: {},
  });
  dispatch({
    type: SIGN_UP_USER_OTP,
    payload: {},
  });
  dispatch({
    type: LOGIN_WITH_GOOGLE,
    payload: {},
  });
};
