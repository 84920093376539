import corporate1 from "../assets/images/corporates/corporate1.png";
import corporate2 from "../assets/images/corporates/corporate2.png";
import corporate3 from "../assets/images/corporates/corporate3.png";
import corporate4 from "../assets/images/corporates/corporate4.png";
import corporate5 from "../assets/images/corporates/corporate5.png";
import corporate6 from "../assets/images/corporates/corporate6.png";
import corporate7 from "../assets/images/corporates/Converted/corporate7.webp";
import corporate8 from "../assets/images/corporates/corporate8.png";
import corporate9 from "../assets/images/corporates/corporate9.png";
import corporate10 from "../assets/images/corporates/Converted/corporate10.webp";
import corporate11 from "../assets/images/corporates/Converted/corporate11.webp";
import corporate12 from "../assets/images/corporates/Converted/corporate12.webp";

import engagement1 from "../assets/images/corporates/engagement1.png";
import engagement2 from "../assets/images/corporates/engagement2.png";
import engagement3 from "../assets/images/corporates/engagement3.png";
import engagement4 from "../assets/images/corporates/engagement4.png";
import engagement5 from "../assets/images/corporates/engagement5.png";
import engagement6 from "../assets/images/corporates/engagement6.png";

import wedding4 from '../assets/images/weddings/Converted/wedding4.webp'
import wedding5 from '../assets/images/weddings/Converted/wedding5.webp'
import wedding6 from '../assets/images/weddings/Converted/wedding6.webp'
import wedding7 from '../assets/images/weddings/Converted/wedding7.webp'
import wedding8 from '../assets/images/weddings/Converted/wedding8.webp'
import wedding_mobile4 from '../assets/images/weddings/Converted/mobile4.webp'
import wedding_mobile5 from '../assets/images/weddings/Converted/mobile5.webp'
import wedding_mobile6 from '../assets/images/weddings/Converted/mobile6.webp'
import wedding_mobile7 from '../assets/images/weddings/Converted/mobile7.webp'
import wedding_mobile8 from '../assets/images/weddings/Converted/mobile8.webp'


import CERP from "../assets/images/corporates/CERP.png";
import CORPe from "../assets/images/corporates/CORPe.png";

import CERP_MOBI from "../assets/images/corporates/CERP-mobi.png";
import CORPe_MOBI from "../assets/images/corporates/CORPe-mobi.png";


import Package1 from "../assets/images/corporates/corporate-package1.png";
import Package2 from "../assets/images/corporates/corporate-package2.png";
import Package3 from "../assets/images/corporates/corporate-package3.png";

import packageDetails1 from '../assets/images/corporates/package1.png';
import packageDetails2 from '../assets/images/corporates/package2.png';
import packageDetails3 from '../assets/images/corporates/package3.png';
import packageDetails4 from '../assets/images/corporates/package4.png';
import packageDetails5 from '../assets/images/corporates/package5.png';

import testimonial1 from '../assets/images/corporates/Converted/testimoni5.webp'
import testimonial2 from '../assets/images/corporates/Converted/testimoni2.webp'
import testimonial3 from '../assets/images/corporates/Converted/testimoni3.webp'
import testimonial4 from '../assets/images/corporates/Converted/testimoni4.webp'

import image1 from '../assets/images/corporates/collage/1.jpg';
import image2 from '../assets/images/corporates/collage/2.png';
import image3 from '../assets/images/corporates/collage/3.jpg';
import image4 from '../assets/images/corporates/collage/4.jpg';
import image5 from '../assets/images/corporates/collage/5.jpg';
import image6 from '../assets/images/corporates/collage/6.png';
import image7 from '../assets/images/corporates/collage/7.png';
import image8 from '../assets/images/corporates/collage/8.png';

import mobile1 from '../assets/images/corporates/Converted/mobile/1.webp';
import mobile2 from '../assets/images/corporates/Converted/mobile/2.webp';
import mobile3 from '../assets/images/corporates/Converted/mobile/3.webp';
import mobile4 from '../assets/images/corporates/Converted/mobile/4.webp';
import mobile5 from '../assets/images/corporates/Converted/mobile/5.webp';
import mobile6 from '../assets/images/corporates/Converted/mobile/6.webp';
import mobile7 from '../assets/images/corporates/Converted/mobile/7.webp';
import mobile8 from '../assets/images/corporates/Converted/mobile/8.webp';

import engMobile1 from '../assets/images/corporates/collage/engagemnet/mobile/1.png';
import engMobile2 from '../assets/images/corporates/collage/engagemnet/mobile/2.png';
import engMobile3 from '../assets/images/corporates/collage/engagemnet/mobile/3.png';
import engMobile4 from '../assets/images/corporates/collage/engagemnet/mobile/4.png';
import engMobile5 from '../assets/images/corporates/collage/engagemnet/mobile/5.png';

import eventMobile1 from '../assets/images/corporates/collage/events/mobile/event1.png';
import eventMobile2 from '../assets/images/corporates/collage/events/mobile/event2.png';
import eventMobile3 from '../assets/images/corporates/collage/events/mobile/event3.png';
import eventMobile4 from '../assets/images/corporates/collage/events/mobile/event4.png';
import eventMobile5 from '../assets/images/corporates/collage/events/mobile/event5.png';

import groupMobile1 from '../assets/images/corporates/collage/group/mobile/group1.png';
import groupMobile2 from '../assets/images/corporates/collage/group/mobile/group2.png';
import groupMobile3 from '../assets/images/corporates/collage/group/mobile/group3.png';
import groupMobile4 from '../assets/images/corporates/collage/group/mobile/group4.png';
import groupMobile5 from '../assets/images/corporates/collage/group/mobile/group5.png';

import honeymoon from '../assets/images/myFamilyFirst/Converted/honeymoon.webp'
import anniversary from '../assets/images/myFamilyFirst/Converted/anniversary.webp'
import anniversary4 from '../assets/images/myFamilyFirst/Converted/anniversary4.webp'
import anniversary5 from '../assets/images/myFamilyFirst/Converted/anniversary5.webp'
import anniversary6 from '../assets/images/myFamilyFirst/Converted/anniversary6.webp'
import anniversary7 from '../assets/images/myFamilyFirst/Converted/anniversary7.webp'
import anniversary8 from '../assets/images/myFamilyFirst/Converted/anniversary8.webp'
import honeymoon4 from '../assets/images/myFamilyFirst/Converted/honeymoon4.webp'
import honeymoon5 from '../assets/images/myFamilyFirst/Converted/honeymoon5.webp'
import honeymoon6 from '../assets/images/myFamilyFirst/Converted/honeymoon6.webp'
import honeymoon7 from '../assets/images/myFamilyFirst/Converted/honeymoon7.webp'
import honeymoon8 from '../assets/images/myFamilyFirst/Converted/honeymoon8.webp'
import anniversarymob1 from '../assets/images/myFamilyFirst/Converted/anniversarymob1.webp'
import anniversarymob2 from '../assets/images/myFamilyFirst/Converted/anniversarymob2.webp'
import anniversarymob3 from '../assets/images/myFamilyFirst/Converted/anniversarymob3.webp'
import anniversarymob4 from '../assets/images/myFamilyFirst/Converted/anniversarymob4.webp'
import anniversarymob5 from '../assets/images/myFamilyFirst/Converted/anniversarymob5.webp'
import honeymob1 from '../assets/images/myFamilyFirst/Converted/honeymob1.webp'
import honeymob2 from '../assets/images/myFamilyFirst/Converted/honeymob2.webp'
import honeymob4 from '../assets/images/myFamilyFirst/Converted/honeymob4.webp'
import honeymob5 from '../assets/images/myFamilyFirst/Converted/honeymob5.webp'
import gifts from '../assets/images/myFamilyFirst/Converted/gifts.webp'

export const WEDDING_COLLAGE = [wedding4,wedding5,wedding6,wedding7,wedding8];
export const honeyMoonCollage=[honeymoon4,honeymoon5,honeymoon6,honeymoon7,honeymoon8]
export const anniversaryCollage=[anniversary4,anniversary5,anniversary6,anniversary8,anniversary7]
export const WEDDING_MOBILE_COLLAGE = [
  {
    image : `${wedding_mobile4}`
  },
  {
    image : `${wedding_mobile5}`
  },
  {
    image : `${wedding_mobile6}`
  },
  {
    image : `${wedding_mobile7}`
  },
  {
    image : `${wedding_mobile8}`
  },
]
export const honeymoonMobileCollagge = [
  {
    image : `${honeymob1}`
  },
  {
    image : `${honeymob2}`
  },
  {
    image : `${wedding_mobile8}`
  },
  {
    image : `${honeymob4}`
  },
  {
    image : `${honeymob5}`
  },
]
export const anniversaryMobileCollagge = [
  {
    image : `${anniversarymob1}`
  },
  {
    image : `${anniversarymob2}`
  },
  {
    image : `${anniversarymob3}`
  },
  {
    image : `${anniversarymob4}`
  },
  {
    image : `${anniversarymob5}`
  },
]
export const CORPORATE_FILTERS = [
  {
    name: "Virtual Team Engagement",
    image: `${engagement1}`,
  },
  {
    name: "In-Person Engagement",
    image: `${corporate2}`,
  },
  {
    name: "Hybrid Team Engagement",
    image: `${engagement2}`,
  },
  {
    name: "New Employee Engagement",
    image: `${engagement3}`,
  },
  {
    name: "Conferences",
    image: `${engagement4}`,
  },
  {
    name: "Group Incentive Trips",
    image: `${engagement5}`,
  },
  {
    name: "Team Outings",
    image: `${engagement6}`,
  },
];
export const CORPORATE_REWARDS = [
  {
    name: "Holidays",
    image: `${corporate10}`,
  },
  {
    name: "Adventure",
    image: `${corporate7}`,
  },
  {
    name: "Experiences",
    image: `${corporate4}`,
  },
  {
    name: "Workcations",
    image: `${corporate6}`,
  },
  {
    name: "Leisure",
    image: `${corporate8}`,
  },
  {
    name: "Wellness",
    image: `${corporate9}`,
  },
];

// corporate services

export const CORPORATE_SERVICES = [
  {
    title: "Corporate Employee Reward Programs (CERP)",
    description: "Corporate Employee Reward Program (CERP) : Offers exciting reward options - Leisure experiences for the family, Wellness programs, Shopping - Gift cards, Curated Holidays, Fine dining and more..",
    image: `${CERP}`,
    mobiImage: `${CERP_MOBI}`
  },
  {
    title: "Corporate Events (CORPe)",
    description: `Corporate Events (CORPe) : Facilitates your requirements for Virtual Events
    & Meetings - Virtual Team Building & Leadership development modules, Sales Events/ Incentives, Team Lunches/ Get-togethers and more..`,
    image: `${CORPe}`,
    mobiImage: `${CORPe_MOBI}`

  },

];

// corporate packages

export const CORPORATE_PACKAGES = [
  {
    title: "Exclusive Corporate packages curated for Employees",
    image: `${Package1}`,
  },
  {
    title: "Take that break you’ve been waiting for, we’ve got you covered",
    image: `${Package2}`,
  },
  {
    title: "Work hard, but travel harder",
    image: `${Package3}`,
  }
];

// corporate package

export const CORPORATE_PACKAGE_DETAILS = [
  {
    title: 'Tropical Blooms Homestay',
    image: [`${packageDetails1}`],
    destination: 'Coorg . Karnataka',
    nights: '5',
    days: '6',
    description: 'Paddington Resorts & Spa – Resort in Coorg, Madikeri A luxury offering near the banks of Mother Cauvery, abodes those subtleties of nature which the heart longs for. Nestled in the midst of a dense jungle, this is a place where spirits remain untainted and wilted life springs up again...',
    points: '5000',
  },
  {
    title: 'Hotel Kalimpong Park - Kalimpong',
    image: [`${packageDetails2}`],
    destination: 'Kalimpong . West Bengal',
    nights: '1',
    days: '2',
    description: 'Kalimpong Park Hotel in Kalimpong, once known as Dinajpur House, the former summer residence of the Maharaja of Dinajpur is a hotel with character, situated on a viewpoint, uphill from the downtown area. This hotel in Kalimpong faces north-east and has a 270 degree panoramic view of the hills and valleys, with Kachenjunga massif standing majestically on the left.',
    points: '5000',
  },
  {
    title: 'Tall Trees Resort - Munnar',
    image: [`${packageDetails4}`],
    destination: 'Munnar . Kerela',
    nights: '3',
    days: '4',
    description: 'The Tall Trees Resorts stands tall and aloof among the other such establishments in and around Munnar. What makes it standout is mainly the bio-diverse eco-system it has successfully preserved in the sprawling 66 acres, even though all the modern creature comforts are tastefully arranged in each of the well appointed cottages nestling among the greenery',
    points: '15000',
  },
  {
    title: 'Royal Orchid each Resort -South Goa',
    image: [`${packageDetails3}`, `${packageDetails4}`, `${packageDetails5}`],
    destination: 'Goa',
    nights: '3',
    days: '4',
    description: 'A beach-front 5-Star resort in India’s favorite holiday destination, Royal Orchid Beach Resort & Spa is the favoured choice holiday-goers in South Goa. Spread across an area of 5-acres.',
    points: '15000',
  },
  {
    title: 'Golden Palms Hotel & Spa  – Bangalore',
    image: [`${packageDetails5}`],
    destination: 'Bangalore . Karnataka',
    nights: '3',
    days: '4',
    description: 'The Golden Palms Hotel & Spa built in the rugged grandeur of the Moorish - Spanish casitas, The Golden Palms Hotel & Spa is steeped in luxury. Nestling amongst 14 acres of lush foliage, sprawling lawns, lagoon and waterfalls.The Hotel comprises of 132 Deluxe rooms, 16 Deluxe suites, 2 Presidential suites, a 75,000 square feet Spa Building, Theme Restaurants and Bars, a 135 metres free styled swimming pool, Conference a...',
    points: '10000',
  },

]


// testimonials 

export const TESTIMONIALS = [
  {
    name: 'Hemanath Chakka',
    designation: 'Manager, India Procurement',
    image: `${testimonial1}`,
    testimoni: '"To put it on record, Hammock Holidays has been of good support to CGI. Your team has partnered with CGI for various offsites. Whether it range of options, or the support before and after offsite, Hammock Holiday’s support has been appreciated all across the board of participants. I would like to thank you for always keeping the cost under control and giving  the required value addition in achieving the best value for the buck"'
  },
  {
    name: 'Kesavan Venugopalan',
    designation: 'Senior Vice President and Corporate Controller',
    image: `${testimonial2}`,
    testimoni: '"It had been a wonderful trip – well organized with no issues and perfect coordination. Type of lodging was very good and overall the arrangement with respect to road travel was very good. Icing in the cake is your attitude to untiringly take feedback/comments and implement them or clarify them then and there without much time being taken."'
  },
  {
    name: 'Ritesh Deokar',
    designation: 'Country Manager',
    image: `${testimonial3}`,
    testimoni: '"Best Holiday Planner. Availing their services from almost 10 years now. The team patiently listens to your requirement and comes up with the best suitable and customized holiday plan for you. Hammock only recommends the best and nothing less. Good Job guys. Keep up the good work"'
  },
  {
    name: 'Girija Sankar Swain',
    designation: 'Client Solution Head',
    image: `${testimonial4}`,
    testimoni: '"Holiday to Agraharam Resort, Wayanad - This was a great experience. The stay was good and the staff were very good. The food was excellent."'
  }
]

export const CORPORATE_REWARDS_PROGG = [
  {
    id: 1,
    title: "Employee Rewards Programme",
    image: `${corporate10}`,
  },
  {
    id: 2,
    title: "Employee Engagement Programme",
    image: `${corporate7}`,
  },
  {
    id: 3,
    title: "Corporate Events",
    image: `${corporate12}`,
  },
  {
    id: 4,
    title: "Group Incentive Travel",
    image: `${corporate11}`,
  },
]
export const myfamilyCollage = [
  {
    id: 1,
    title: "Honeymoon Packages",
    image: `${honeymoon}`,
  },
  {
    id: 2,
    title: "Anniversary Packages",
    image: `${anniversary}`,
  },
  {
    id: 3,
    title: "Gifting",
    image: `${gifts}`,
  }
]
export const REWARDS_COLLAGE = [
  {
    id: 1,
    image: `${mobile1}`,
  },
  {
    id: 2,
    image: `${mobile2}`,
  },
  {
    id: 3,
    image: `${mobile3}`,
  },
  {
    id: 4,
    image: `${mobile4}`,
  },
  {
    id: 5,
    image: `${mobile5}`,
  },
  {
    id: 6,
    image: `${mobile6}`,
  },
  {
    id: 7,
    image: `${mobile7}`,
  },
  {
    id: 8,
    image: `${mobile8}`,
  },
]
export const ENGAGEMENT_COLLAGE = [
  {
    id: 1,
    image: `${engMobile1}`,
  },
  {
    id: 2,
    image: `${engMobile2}`,
  },
  {
    id: 3,
    image: `${engMobile3}`,
  },
  {
    id: 4,
    image: `${engMobile4}`,
  },
  {
    id: 5,
    image: `${engMobile5}`,
  },
]
export const EVENT_COLLAGE = [
  {
    id: 1,
    image: `${eventMobile1}`,
  },
  {
    id: 2,
    image: `${eventMobile2}`,
  },
  {
    id: 3,
    image: `${eventMobile3}`,
  },
  {
    id: 4,
    image: `${eventMobile4}`,
  },
  {
    id: 5,
    image: `${eventMobile5}`,
  },
]
export const GROUP_COLLAGE = [
  {
    id: 1,
    image: `${groupMobile1}`,
  },
  {
    id: 2,
    image: `${groupMobile2}`,
  },
  {
    id: 3,
    image: `${groupMobile3}`,
  },
  {
    id: 4,
    image: `${groupMobile4}`,
  },
  {
    id: 5,
    image: `${groupMobile5}`,
  },
]