import React, {useEffect} from "react";
import {useLocation} from 'react-router-dom'
import { Tabs, Tab } from "react-bootstrap";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import "../../assets/css/partials/tabs-holder.scss";
import Corporates from "../corporates/index";
import { useSelector, useDispatch } from "react-redux";
import * as CorporateAction from '../../redux/actions/corporatesAuthActions';
import * as HolidaysAction from '../../redux/actions/holidaysActions';

function TabHolder() {
  const loginUserData = useSelector((state) => state.auth.login_user_otp);
  const location = useLocation();
  const userData = JSON.parse(localStorage.getItem("userData"))
  const section_slug = localStorage.getItem("section_slug")
  const isCorporateAccount = userData ? userData?.corporate_account : false
  const scrollTop = () => {
    window.scrollTo(0,0);
  } 
  const dispatch = useDispatch();
  const clearFilter = () => {
    dispatch(CorporateAction.getSearchValue(""))
    dispatch(CorporateAction.getFilterValues(""))
  }
  const clearHolidayFilter = () => {
    dispatch(HolidaysAction.setSearchValue(""))
    dispatch(HolidaysAction.setFilterValues(""))

  }

  useEffect(() => {
    setTimeout(()=>{
      if(location.pathname!=='/' && !isCorporateAccount){
          let activeLink = document.body.querySelector(`#${location.pathname.split('/')[1]}`);
          var scrollContent = document.body.querySelector(".hor-nav");
          if(activeLink){
            console.log('scrollwidth', activeLink);
            scrollContent.scrollLeft = activeLink.offsetLeft-40
            console.log(location);
          }
          
      } else {
        if(document.body.querySelector(".hor-nav")?.scrollLeft){
          document.body.querySelector(".hor-nav").scrollLeft = 0;
        }
      }
      
      


    },200);
    
  }, [location])

  return (
    <div className="nav-holder">
      <div>
        <div className="container" style={{padding:"0px"}}>
        {
                  loginUserData &&
                  loginUserData.corporate_account &&
                  loginUserData.user_details ? 
                
          <nav>
            <ul className="d-flex justify-content-center hor-nav">
              <li onClick={clearFilter}  id="corporates">
                <NavLink to={{ pathname: "/corporates", data: {bool: true}}} activeClassName="active" exact={true}>
                Deals
                </NavLink>
              </li>
              <li onClick={clearHolidayFilter}  id="holidays">
                <NavLink to={{ pathname: "/profile", data: {bool: true}}} activeClassName="active">
                  Profile
                </NavLink>
              </li>
              <li id="my-family-first">
                <NavLink to="/bookings" activeClassName="active">
                  Bookings
                </NavLink>
              </li>
            </ul>
          </nav> :
          <nav>
            <ul className="d-flex hor-nav">
              <li onClick={scrollTop}  id="home">
                <NavLink to="/" exact={true} activeClassName="active">
                  Home
                </NavLink>
              </li>
              
              
               
              <li onClick={clearHolidayFilter}  id="holidays">
                <NavLink to={{ pathname: `/holidays${section_slug?`/${section_slug}`:''}`, data: {bool: true}}} activeClassName="active" isActive={(match,location) =>  {
                  return location.pathname.indexOf("/holidays") > -1
                }}>
                  
                  Holidays
                </NavLink>
              </li>
              <li onClick={clearFilter}  id="corporates">
                <NavLink to={{ pathname: "/corporates", data: {bool: true}}} activeClassName="active" >
                {
                  loginUserData &&
                  loginUserData.corporate_account &&
                  loginUserData.user_details ? 'Your Deals' : 'Corporates'
                }
                </NavLink>
              </li>
              <li onClick={clearHolidayFilter} id="experiences">
                  <NavLink to={{ pathname: "/experiences", data: {bool: true}}} activeClassName="active">
                    Experiences
                  </NavLink>
                </li>
              <li id="my-family-first">
                <NavLink to="/my-family-first" activeClassName="active">
                  My Family First
                </NavLink>
              </li>
              {/* <li  id="events">
                <NavLink to="/events" activeClassName="active">
                  Events
                </NavLink>
              </li> */}
              <li  id="weddings">
                <NavLink to="/weddings" activeClassName="active">
                  Weddings
                </NavLink>
              </li>
            </ul>
          </nav> }
        </div>
      </div>
    </div>
  );
}

export default TabHolder;
