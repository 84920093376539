import React, {useEffect} from 'react';
import IntlContactNumber from "./intlContactNumber";

function UserDetails({changeName, emailChange, numberChange, contactName, editName, isEditName, contact_email, editEmail, isEditEmail, isEditNumber, editNumber, mobile, editNumberError, setEditNumberError, editEmailError, editNameError, editEmailValidError, nameInputRef, emailInputRef, nameUpdateSuccess, emailUpdateSuccess}) {
    useEffect(()=>{
        console.log(editNameError);
        console.log(editEmailError);
        
    }, [editNameError]);
    // const nameOnBlur = ()=>{
    //     console.log('focus lost');
    //     if(isEditName && !editName){
    //         var elementPosition = document.querySelector('#name').getBoundingClientRect().top;
    //         var offsetPosition = elementPosition + window.pageYOffset - 145;
    //         window.scrollTo({top: offsetPosition, behavior: "smooth" });
    //         console.log('editNameError', editNameError);
    //         setTimeout(()=>{
    //             if(editNameError){
    //                 document.querySelector('#name').focus();
    //             }
                
    //         });
    //     }
    // };
    // const emailOnBlur = ()=>{
    //     console.log('focus lost');
    //     if(isEditEmail && !editEmail){
    //         var elementPosition = document.querySelector('#email').getBoundingClientRect().top;
    //         var offsetPosition = elementPosition + window.pageYOffset - 145;
    //         window.scrollTo({top: offsetPosition, behavior: "smooth" });
    //         setTimeout(()=>{
    //             // document.querySelector('#email').focus();
    //             if(editEmailError){
    //                 document.querySelector('#email').focus();
    //             }
    //         });
    //     }
    // };
    return (
        <div className="buyer-details mt-4">
            <h6>Our team will connect with you on below details</h6>
            <form>
                <div class="input-holder">
                    <div class="form-group  mr-3" ref={nameInputRef}>
                        <input type="text" className="form-control" 
                            size={!isEditName ? contactName?.length : editName?.length}
                            value={!isEditName ? `${contactName}` : editName}
                            // disabled={disabled}
                            onKeyPress={(event) => {
                                if (!/[a-zA-Z\s]/.test(event.key)) {
                                    event.preventDefault();
                                }
                                if(event.key === "Enter"){
                                    event.preventDefault()
                                    event.target.blur()
                                    nameUpdateSuccess()
                                }
                            }}
                            // onBlur={()=>nameOnBlur()}
                            id="name"
                            onChange={changeName} />
                        <label for="">Your name</label>
                        {
                            editNameError ? <p style={{ color: 'red', fontSize: '12px' }}>Name is required</p> : ''
                        }
                    </div>

                    
                </div>
            </form>
            <form>
                <div class="input-holder">
                    <div class="form-group  mr-3" ref={emailInputRef}>
                        <input type="text" className="form-control" size={!isEditEmail ? contact_email?.length + 2 : editEmail?.length +2}
                            // disabled={emailDisabled}
                            value={!isEditEmail ? `${contact_email}` : editEmail}
                            id="email"
                            // onBlur={()=>emailOnBlur()}
                            onChange={emailChange}
                            onKeyPress={(e) => {
                                if(e.key === "Enter"){
                                    e.preventDefault()
                                    e.target.blur()
                                    emailUpdateSuccess()
                                }
                            }}
                        />
                        <label for="">Your email</label>
                        {
                            editEmailError ? <p style={{ color: 'red', fontSize: '12px' }}>Email Id is required</p> : ''
                        }
                        {
                            editEmailValidError ? <p style={{ color: 'red', fontSize: '12px' }}>Please enter a valid email Id</p> : ''
                        }

                    </div>

                    
                </div>
            </form>
            <IntlContactNumber disabled={false} mobile={isEditNumber?editNumber:mobile} numberChange={numberChange} hasError={editNumberError} enableInlineEdit={true} />
        </div>
    )
}

export default UserDetails
