import React, {useEffect} from 'react'
import OwlCarousel from "react-owl-carousel";
import { useDispatch, useSelector } from "react-redux";
import { corporate_options } from '../../resources/carouselOptions';
import { Link, useHistory } from "react-router-dom";
import * as HolidaysAction from '../../redux/actions/holidaysActions';
import { Item } from 'semantic-ui-react';

function NearByExperiences({similarExperiences, resetFormFields,startLoader,holidayID}) {
    const dispatch = useDispatch();
    const history = useHistory();
    const SIMILAR_EXPERIENCES = useSelector((state) => state.holidays.nearby_experiences.data);
    const id = similarExperiences;
    const userInfo = JSON.parse(localStorage.getItem("userData"));
    const isCorporateAccount = userInfo?.user_details?.corporate_account;
    const companyDomain = userInfo?.organisation?.email_domain;
    const isWipro = (companyDomain === "wipro.com");
    
    useEffect(() => {
        if(id){
        dispatch(HolidaysAction.getNearByExperiences(id));
    }
    }, [id]);

    const refreshSimilarPackages = () => {
        dispatch(HolidaysAction.getNearByExperiences(id));
    }
    const handleExperienceDetails = (id) => {
 if(id !== holidayID){
        startLoader()
 }
        resetFormFields()
        window.scrollTo(0, 0);
      };
      
    return (
        <div>
        {
            SIMILAR_EXPERIENCES && SIMILAR_EXPERIENCES.length > 0 ?  <div className="container">
            <div className="nearby-experiences similar-offers stays-nearby">
                <h5><span>Recommendations</span> for you</h5>
                <div className="divider"></div>
                <OwlCarousel className="owl-theme" {...corporate_options}>
                {
                    SIMILAR_EXPERIENCES && SIMILAR_EXPERIENCES.length > 0 && SIMILAR_EXPERIENCES.map((items,idx) => (
                        <Link to={{ pathname: `/holidays/holiday-details/${items.holiday_slug}`, state: { id: items?.id } }} key={idx}>
                        <div className="item">
                            <div className="card-wrapper d-lg-flex flex-column" style={{cursor: 'pointer'}} onClick={() => handleExperienceDetails(items.holiday_slug,items.id)}>
                                <img src={items.image} className="w-100" alt={items.pack_title} />
                                <div className="info">
                                   <p>{items.pack_title}</p>
                                   {/* <div className="desc">
                                        <p>{items.brief_description}</p>
                                   </div> */}
                                   {
                                    (isCorporateAccount && isWipro) ? <p className="points">{(items.revised_price?items.revised_price:items.price).toLocaleString('en-IN')} pts</p> : 
                                     <p className="points"> Rs {(items.revised_price?items.revised_price:items.price).toLocaleString('en-IN')}</p>
                                   }

                                </div>
                            </div>
                        </div>
                        </Link>
                    ))
                }
                </OwlCarousel>
            </div>
        </div> : null
        }
        </div>
    )
}

export default NearByExperiences
