import React from "react";

const CategoryDetails = (props) =>{

    const {id} = props;
    return(
        <>
        {id == 1 ?
          <div className="employee-reward">
              <h5> <span className="gradient"> Employee Rewards Programme </span>by Hammock</h5>
              <p>Create an inspiring work environment for your employees with Hammock's Employee Rewards Program by recognising and rewarding their efforts </p>
              <div className="tagline">
                  <p>Long service awards . Loyalty rewards </p>
                  <p>Performance awards . Spot awards </p>
              </div>
              <h6>Hammock provides a wide variety of reward options like Holidays, Adventure Activities, Experiences, Workcations, Leisure, Wellness and Gift Cards</h6>
          </div>:
          id == 2 ?
          <div className="employee-reward">
              <h5> <span className="gradient"> Employee Engagement Programme </span>by Hammock</h5>
              <p>Develop a culture of positivity and create an environtment your employees would thrive in with Hammock’s Employee Engagement Programs </p>
              <div className="tagline padding">
                  <p>Conferences .  Group Incentive Trips . Team Outings Virtual Team Engagement . In-Person Engagement Hybrid Team Engagement . New Employee Engagement </p>
              </div>
          </div>:
          id == 3 ?
          <div className="employee-reward">
              <h5><span className="gradient">Corporate Events </span>by Hammock</h5>
              <p>We can help you with multiple location & venue options, pan India; for your Agents/ Dealer meets, Conferences & Get-togethers. Once you lock-in your preferred venue, our experts can customize your event, according to your specific requirement and budgets.</p>
              <p>Needless to mention creating extraordinary experiences is our forte. Our wide network of relationships across India, allows us to provide value for money propositions.  Try Hammock for your next corporate event.</p>
              <div className="tagline padding">
                  <p>Agents/Dealer Meets .  Conferences . Get-Togethers</p>
              </div>
          </div>:
        <div className="employee-reward">
            <h5><span className="gradient">Group Incentive Travel </span>by Hammock</h5>
            <p>A highly rewarding experience awaits your partners/ employees which can result in boosting sales and driving targets!  We offer a plethora of exotic locales within India & worldwide,  Our planning is client focused (based on desired outcomes as well as budgets).</p>
            <p>Other than organising Team building, Theme parties and gala dinners during travel, we can also help customize Corporate Social Responsibility Programs (CSR), which allows participants to contribute sustainably, back to nature. </p>
            <div className="tagline padding">
                <p>Team Building .  Theme Parties . Gala Dinners</p>
            </div>
        </div>
        }
        </>
    )
}
export default CategoryDetails