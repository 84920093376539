import React from 'react'
import { useSelector } from "react-redux";
import Header from "./header";
import Footer from "./footer";
import backIcon from "../../assets/images/common/back-icon.svg";
import { useHistory } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { ScrollToTop } from '.';
function RefundAndCancellations() {
    const history = useHistory();
    const loginUserData = useSelector((state) => state.auth.login_user_otp);
    return (
        <div>
            <Helmet>
                <title>Refund & Cancellations - Hammock Holidays</title>
                <meta name="description" content="" />
            </Helmet>
        <div className={Object.keys(loginUserData).length !== 0 &&
            loginUserData.constructor === Object ? 'innerPage-header' : 'innerPage-header pre-login-header'} >
            <Header />
        </div>
        <div className="container innerPage-container">
        <a onClick={() => history.goBack()}>
            <div className="back-button">
                <img src={backIcon} alt="backIcon" />
            </div>
        </a>
        <div className="package-holder">
            <div className="package-title">
                <h1>
                    Refund & Cancellations
          </h1>
            </div>
        </div>
        <div className="terms-conditions-content">

            <div className="content-wrapper mb-5">
                <h4 className="mb-3">Cancellation / Refund policy
           </h4>
                <p>Cancellation charges will be payable, depending upon the number of days prior to departure that Hammock Holidays receives notice of cancellation and on hotel/ resort/ airlines/ transporters/ supplier’s cancellation policy and will include a service fee. Some hotels do not permit changes to or cancellations of reservations after they are made, as indicated in the rules and restrictions for the hotel reservation and for bookings with these suppliers no amount will be refunded.
           </p>
                <p>If the User does not cancel or change the reservation before the cancellation policy period applicable to the hotel/ package that was reserved, which varies by hotel, the user will be subject to a charge of applicable room rates, tax recovery charges of the hotel and the service fees for cancellation.
           </p>
                <p>
                    Cancellation policy for the various packages and offers which are specifically for corporate programs will be governed by the agreement with the respective corporate and the various suppliers of Hammock Holidays for that particular program.
           </p>
                <p>
                    Cancellation/ amendment/ delay in airlines / trains / coaches will not entitle the user to any refunds for the tours / land packages/ hotels/ cruises and the suppliers will apply cancellation rules as per their policies.
           </p>
            </div>

            <div className="content-wrapper mb-5">
                <h4 className="mb-3">Refunds
           </h4>
                <p>
                    Requests for refund should be made in writing within 3 working days after the scheduled completion of the related services. All refunds for international products will be made only in Indian Rupees as per RBI Regulations. Refund requests for curtailed stay cannot be processed unless provided with documentary evidence from the supplier. All refund requests are subject to obtaining a refund approval from our suppliers, and no refunds can be given without this approval. The supplier reserves the right to process and whether or not to approve the refund, request and if necessary, charge an administrative fee for packages. No refunds can be processed for partially utilized services and no shows.

           </p>
                <p>
                    Refund may be made in the form of a credit note/ voucher such that it may be used for future travel /utilisation of service subject to terms applicable thereon or may be done in cash, cheque or net banking. In all cases, mode of refund (credit note or cash) is dependent upon the Service Provider only.
           </p>
            </div>

            <div className="content-wrapper mb-5">
                <h4 className="mb-3">Covid-19 Bookings, Refund And Cancellations
       </h4>
                <p>
                    The Customer agrees that due to recent outbreak of COVID-19 which has been declared as pandemic, there may be instances, where the end service provider (including Travel Agent) either cancels the booking or refuses to provide the services, as agreed with the Customer, by assigning or not assigning any specific reason for such refusal. Refusal of service may be due to the reasons including but not limited to the Customer:
           </p>
                <ul className="ml-lg-5">
                    <li>Exhibiting any symptoms associated with COVID 19.</li>
                    <li>Refusing to comply with safety advisories, like wearing protective gear, complying with social distancing norms etc.</li>
                    <li>Suffering of any ailment making the Customer vulnerable to the virus</li>
                    <li>Posing a threat to the health and safety of others or</li>
                    <li>Any other condition put forward by the Central Government, State Governments and statutory bodies etc.</li>
                </ul>

                <p>
                    In such cases Hammock Holidays will assume no liability for the refusal of service by the end service provider. The Customer also agrees that refund, if any, for such bookings will be processed by Hammock Holidays to the Customer subject to receipt of the same from the end service provider.
            </p>
                <p>
                    The Customer further understands that due to the outbreak of COVID-19, there may be frequent changes in guidelines and/ or SOPs issued by the Central Government, State Governments and statutory bodies etc. that a traveler will have to follow in order to undertake the travel. Likewise, it shall be the obligation of the customer to ensure that your Covid Vaccination Certificate is valid and issued by a competent authority for travel within India or out of India as applicable.
            </p>
                <p>
                    The Customer agrees to abide with all such guidelines/SOP while undertaking any travel. In case Customer fails to adhere to the same which results in the refusal to travel, TR Hammock Holidays will not be held liable for such refusal and refund, if any, shall be strictly as per the terms of the service provider.
            </p>
            </div>

            <div className="content-wrapper mb-5">
                <h5 className="mb-3">Customer service / Help desk contact number:</h5>
                <p>Hammock Leisure Holidays Pvt Ltd </p>
                <p className="mb-2">
                    3291, 1st Floor, 12th Main, HAL II Stage, Indiranagar, Bangalore 560 008.</p>
                <p className="mb-2">
                   Tel :<a href="tel:91-80- 4202 3333" > 91-80- 4202 3333</a></p>
                 <p className="mb-2">
                Email : <a href="mailto:customercare@hammockholidays.com" rel="noreferrer" target="_blank">customercare@hammockholidays.com</a>
                 </p>  
                 <p>
                Web : <a href="https://www.hammockholidays.com/" rel="noreferrer" target="_blank"> www.hammockholidays.com </a>
                 </p> 
            </div>

        </div>
    </div>
    <ScrollToTop showBelow={250}/>
    <Footer />
        </div>
    )
}

export default RefundAndCancellations
