

import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';

const Tab = (props) => {
    const [ isLoginActive, setIsLoginActive] = useState(false);
    const [ isSignupActive, setIsSignupActive] = useState(false);
   
    useEffect(() => {
        if(props.location.pathname === '/login') {
            setIsLoginActive(true);
        }
        else if(props.location.pathname === '/signup') {
            setIsSignupActive(true);
        }
    }, [props.location]);

    return (
        <div className="auth-toggle-button">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item">
                    <Link 
                        className={`nav-link ${isLoginActive ? 'active' : ''}`} 
                        id="pills-login-tab" 
                        data-toggle="pill" 
                        to="/login" 
                        role="tab" 
                        aria-controls="pills-login" 
                        aria-selected="true"
                    >
                        Login
                    </Link>
                </li>
                <li className="nav-item">
                    <Link 
                        className={`nav-link ${isSignupActive ? 'active' : ''}`}
                        id="pills-signup-tab" 
                        data-toggle="pill" 
                        to="/signup" 
                        role="tab" aria-controls="pills-signup" aria-selected="false">Sign Up
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export default withRouter(Tab);

