import React, {useEffect, useState, useRef} from 'react'
import closeIcon from '../../assets/images/common/close-modal.svg';
import WhatsappLogo from '../../assets/images/corporates/whatsapp-logo.svg'
import useClickOutside from '../../hooks/useClickOutside';

function ContactDetailsCard({ openModal, stickyBtnText, isInternational, isEnablePayment, contactModalRef , isPastDate}) {
  const [isStuck, setIsStuck] = useState(false);

  const handleClose = () => {
    openModal(false);
  };

  useEffect(()=>{
    apply_stickies()

        window.addEventListener('scroll', function() {
            apply_stickies()
        })

        function apply_stickies() {
            var _$stickies = [].slice.call(document.querySelectorAll('.sticky-contact'))
            _$stickies.forEach(function(_$sticky) {
                if (CSS.supports && CSS.supports('position', 'sticky')) {
                    apply_sticky_class(_$sticky)
                }
            })
        }

        function apply_sticky_class(_$sticky) {
            var currentOffset = _$sticky.getBoundingClientRect().top
            var stickyOffset = parseInt(getComputedStyle(_$sticky).top.replace('px', ''))
            var isStuckStatus = currentOffset <= stickyOffset
            setIsStuck(isStuckStatus);
            // _$sticky.classList.toggle('js-is-sticky', isStuck)
        }
  }, []);

  const bookNowFun = ()=>{
    if(!isInternational){
      document.body.scrollTop=document.querySelector('.package-details').offsetTop+550;  // For Safari
      document.documentElement.scrollTop=document.querySelector('.package-details').offsetTop+75; // For Chrome, Firefox, IE and Opera
    } else {
      document.body.scrollTop=document.querySelector('.package-details').offsetTop+550;  // For Safari
      document.documentElement.scrollTop=document.querySelector('.package-details').offsetTop+550; // For Chrome, Firefox, IE and Opera
    }
    
  }

  return (
    <div>
      {isStuck&&<><div>{isPastDate ? " " :
      <button type="button" class="btn book-now w-100 mb-4" onClick={()=>bookNowFun()}>{stickyBtnText?stickyBtnText: isEnablePayment ? 'Book now' : "Enquire now"}</button>}
      </div>
      <div className="login-holder contact-holder" ref={contactModalRef}>
        <h5>Contact Us</h5>
        <span className="d-block d-md-none closeIcon" onClick={handleClose}><img src={closeIcon} className="close-icon" alt='close' /></span>
        <div className="line"></div>
        <div className="contact-card mb-3 d-flex align-items-center justify-content-between">
          <div>
            <p>
              {isInternational ? 
              <a href="tel:+91 90082 53000">+91 90082 53000</a> :
              <a href="tel:+919902064657">+91 9902064657</a> }
            </p>
            <p>Our number</p>
          </div>
          <div>
            <a href={isInternational ?"https://wa.me/+919008253000":"https://wa.me/+919902064657"} rel="noreferrer" target="_blank">
              <img src={WhatsappLogo} alt="WhatsappLogo" />
            </a>
          </div>
        </div>
        <div className="contact-card">
          <p>{ isInternational ?
            <a
              href="mailto:outbound@hammockholidays.com"
              rel="noreferrer"
              target="_blank"
            >
              outbound@hammockholidays.com{" "}
            </a> :
            <a
              href="mailto:ramananda@hammockholidays.com"
              rel="noreferrer"
              target="_blank"
            >ramananda@hammockholidays.com
              {" "}
            </a> }
          </p>
          <p>Our email</p>
        </div>
      </div>
      </>
      }
    </div>
  )
}

export default ContactDetailsCard
