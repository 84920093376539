import React from 'react';
import moment from 'moment';

function DatePopup({showModal, wrapperRef, data, fromBookingDetails}) {
    return (
        <div className={`dateModal-wrapper ${showModal ? 'show' : 'hide'}`}>
            <div className="outer-box" ref={wrapperRef}>
                
                    <div className="inner-box ">
                        <p>{!fromBookingDetails ? `Selected dates` : null}</p>
                        <div className={data?.schedule?.length > 5 ? "overflow" : "dates"}>
                        {data?.schedule?.map(date => <p style={{fontWeight: 700, marginBottom: "10px"}}>{moment(date, 'DD-MM-YYYY').format('ddd-DD MMM')}</p>)}
                        </div>
                    </div>
                
            </div>
        </div>
    )
}

export default DatePopup
