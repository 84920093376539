import React from 'react';
import moment from 'moment';
import parse from 'html-react-parser';

function Testimonial({testimonialsData, testimonialSH, handleTestimonialPopup}) {

    console.log(testimonialsData);
    return (
        <div className="package-testimonial">
            <div className="heading">
                <h2>Testimonials</h2>
            </div>
            <div className="line"></div>
            <div className="cards-wrapper">
            {
                testimonialsData && testimonialsData?.length > 0 && testimonialsData?.map((item, index) => {  
                return (
                <div className="testimonial-card">
                    <div className="header d-none d-md-flex">
                        <p className="profile" >
                                {item.guest_image !== "" ?
                                    <img src={item.guest_image} alt='item.guest_name' />
                                    : <div className="nav-proile-icon" >
                                        <p>{item.guest_name !== undefined && item.guest_name?.charAt(0).toUpperCase()}</p>
                                    </div>}
                                    <p>{item.guest_name}</p>
                            </p>
                          
                        <p>{moment(item?.modified, 'YYYY-MM-DD').format('MMMM, YYYY')}</p>
                    </div>
                    <div className="header-mobile d-block d-md-none">
                        <p className="profile">
                        {item.guest_image !== "" ?
                                    <img src={item.guest_image} alt={item.guest_name} />
                                    : <div className="nav-proile-icon" >
                                        <p>{item.guest_name !== undefined && item.guest_name?.charAt(0).toUpperCase()}</p>
                                    </div>}
                            <p>{item.guest_name}<br></br><span>{moment(item?.modified, 'YYYY-MM-DD').format('MMMM, YYYY')}</span></p>
                        </p> 
                    </div>
                    <div className="content" >
                        <div className="testimonial-block" id={`testimonial-block-${index}`}>
                            {parse(item.message)}
                            {testimonialSH[`testimonial-block-${index}`]>88&&<div className="testimonial-readmore">
                            <span  className="readmore-span"><a  onClick={() => handleTestimonialPopup(item)}>... read more </a> </span>
                            </div>}
                        </div>
                    </div>
                </div>
                )}) 
            }
            </div>
        </div>
    )
}

export default Testimonial
