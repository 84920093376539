import {
    EXPERIENCE_CATEGORY,
    INITIAL_EXPERIENCE_CATEGORY_ID,
    CATEGORY_ID,
    EXPERIENCES_LIST,
    EXPERIENCE_PACKAGE_DETAILS,
    EXPERIENCE_BOOKING_INFORMATION_DATA,
    CHANGE_DATA_FOR_PROCEED_TO_BOOK,
    DATA_CHANGED_BOOL_VAL,
    BOOKING_STATUS,
    EDITED_CORP_EXPERIENCE_NAME,
    EDITED_CORP_EXPERIENCE_NAME_BOOL,
    EDITED_CORP_EXPERIENCE_NUMBER,
    EDITED_CORP_EXPERIENCE_NUMBER_BOOL,
    SIMILAR_EXPERIENCES,
    NEAR_BY_EXPERIENCES,
    MORE_EXPERIENCES,
    EXPERIENCES_PACKAGES_LIST,
    RESET_EXPERIENCES_LIST,
    EXPERIENCE_CATEGORY_ID,
    EXP_ROUTE_MESSAGE,
    EXPERIENCE_TESTIMONIALS,
    EXPERIENCE_REVIEW_LIST,
    EXPERIENCE_REVIEW_POST,
    EXPERIENCE_REVIEW_REFERENCE_ID,
    EXPERIENCE_REVIEWLIST_TOTAL_PAGE,
    EXPERIENCE_REVIEWLIST_CURRENT_PAGE,
    EXPERIENCE_ACTIVE_PACKAGE_INDEX
} from '../actions/experiencesAction'

const initialState = {
    experience_categories: [],
    cat_id: null,
    experience_packages: [],
    experience_details: localStorage.getItem("packInfo") ? JSON.parse(localStorage.getItem("packInfo")) : null,
    booking_information_data: localStorage.getItem("bookData") ? JSON.parse(localStorage.getItem("bookData")) : [],
    change_data_to_proceed_to_book: localStorage.getItem("dataChanged") ? JSON.parse(localStorage.getItem("dataChanged")) : {},
    data_changed_bool_val: localStorage.getItem("dataChangedBool") ? JSON.parse(localStorage.getItem("dataChangedBool")) : null,
    booking_details: [],
    corpEditedExperienceName: null,
    corpEditedExperienceNameBool: false,
    corpEditedExperienceNumber: null,
    corpEditedExperienceNumberBool: false,
    similar_experiences: [],
    near_by_experiences: [],
    more_experiences: [],
    experience_packages_list: [],
    route_message: "",
    experience_testimonials: {},
    referenceId: null,
    reviewStatus: null,
    reviewList: null,
    reviewListTotalPage: null,
    reviewListCurrentPage: null,
    activePackageIndex: 0
}

const experienceReducer = (state = initialState, action) => {
    switch (action.type) {
        case EXPERIENCE_CATEGORY:
            return {
                ...state,
                experience_categories: action.payload
            }
        case EXPERIENCE_CATEGORY_ID:
            return {
                ...state,
                cat_id: action.payload,
            }
        case INITIAL_EXPERIENCE_CATEGORY_ID:
            return {
                ...state,
                cat_id: action.payload
            }
        case EXPERIENCES_LIST:
            return {
                ...state,
                experience_packages: action.payload,
            }
        case RESET_EXPERIENCES_LIST:
            return {
                ...state,
                experience_packages_list: action.payload,
            }
        case EXPERIENCES_PACKAGES_LIST:
            return {
                ...state,
                experience_packages_list: state.experience_packages_list.concat(action.payload),
            }
        case EXPERIENCE_PACKAGE_DETAILS:
            return {
                ...state,
                experience_details: action.payload,
            }
        case EXPERIENCE_BOOKING_INFORMATION_DATA:
            return {
                ...state,
                booking_information_data: action.payload,
            }

        case CHANGE_DATA_FOR_PROCEED_TO_BOOK:
            return {
                ...state,
                change_data_to_proceed_to_book: action.payload,
            }

        case DATA_CHANGED_BOOL_VAL:
            return {
                ...state,
                data_changed_bool_val: action.payload,
            }
        case BOOKING_STATUS:
            return {
                ...state,
                booking_details: action.payload
            }
        case EDITED_CORP_EXPERIENCE_NAME:
            return {
                ...state,
                corpEditedExperienceName: action.payload
            }
        case EDITED_CORP_EXPERIENCE_NAME_BOOL:
            return {
                ...state,
                corpEditedExperienceNameBool: action.payload
            }
        case EDITED_CORP_EXPERIENCE_NUMBER:
            return {
                ...state,
                corpEditedExperienceNumber: action.payload
            }
        case EDITED_CORP_EXPERIENCE_NUMBER_BOOL:
            return {
                ...state,
                corpEditedExperienceNumberBool: action.payload
            }
        case SIMILAR_EXPERIENCES:
            return {
                ...state,
                similar_experiences: action.payload
            }
        case NEAR_BY_EXPERIENCES:
            return {
                ...state,
                near_by_experiences: action.payload
            }
        case MORE_EXPERIENCES:
            return {
                ...state,
                more_experiences: action.payload
            }
        case EXP_ROUTE_MESSAGE:
            return {
                ...state,
                route_message: action.payload
            }
        case EXPERIENCE_TESTIMONIALS:
            return {
                ...state,
                experience_testimonials: action.payload
            }
        case EXPERIENCE_REVIEW_REFERENCE_ID:
            return {
                ...state,
                referenceId: action.payload
            }
        case EXPERIENCE_REVIEW_POST:
            return {
                ...state,
                reviewStatus: action.payload
            }
        case EXPERIENCE_REVIEW_LIST:
            return {
                ...state,
                reviewList: action.payload
            }
        case EXPERIENCE_REVIEWLIST_TOTAL_PAGE:
            return {
                ...state,
                reviewListTotalPage: action.payload
            }
        case EXPERIENCE_REVIEWLIST_CURRENT_PAGE:
            return {
                ...state,
                reviewListCurrentPage: action.payload
            }
        case EXPERIENCE_ACTIVE_PACKAGE_INDEX:
            return {
                ...state,
                activePackageIndex: action.payload
            }
        default:
            return state
    }
}
export default experienceReducer