import { API, BASE_URL, TEST_PAYMENT_KEY, CC_PAYMENT_KEY, DC_PAYMENT_KEY } from "../../config/api";
import {
  axiosGet, axiosGetWithToken,
  axiosPost, axiosPostWithToken
} from "../../services/apiServices";
import { ERROR_ALERT } from "./commonAction";
import { ALL_LOCATIONS_LIST, BOOKING_INFORMATION_DATA, BOOKING_STATUS, CATEGORY_ID, CHANGE_DATA_FOR_PROCEED_TO_BOOK, CHECKOUT_DETAILS, CORPORATES_CATEGORY, CORPORATES_PACKAGES, CORPORATES_PACKAGES_LIST, CORPORATE_PACKAGE_DETAILS, DATA_CHANGED_BOOL_VAL, EDITED_CORP_HOLIDAY_NAME, EDITED_CORP_HOLIDAY_NAME_BOOL, EDITED_CORP_HOLIDAY_NUMBER, EDITED_CORP_HOLIDAY_NUMBER_BOOL, EDITED_GIFTVOUCHER_NAME, EDITED_GIFTVOUCHER_NAME_BOOL, EDITED_GIFTVOUCHER_NUMBER, EDITED_GIFTVOUCHER_NUMBER_BOOL, GUEST_DATA, INITIAL_CATEGORY_ID, NEAR_BY_EXPERIENCES, NEAR_BY_PACKAGES, MORE_PACKAGES, PACKAGE_END_DATE, PACKAGE_START_DATE, PAGE_SIZE, SIMILAR_GIFTS, RESET_CORP_LIST, GET_SEARCH_VALUE, FILTER_VALUES, PACKAGE_FLEXIBLE_DATES, CORPORATE_TESTIMONIALS, CORP_COUNTRY_LIST, CORPORATE_REVIEW_REFERENCE_ID, CORPORATE_REVIEW_LIST, CORPORATE_REVIEWLIST_TOTAL_PAGE, CORPORATE_REVIEWLIST_CURRENT_PAGE, CORPORATE_REVIEW_POST, CORPORATE_ACTIVE_PACKAGE_INDEX, INTERNAL_PACK_DATA, INTERNAL_CHECKOUT_DETAILS } from './types';

// Contact form

export const contactForm = (body, handleSuccess, handleErrors) => {
  return async (dispatch, getstate) => {
    axiosPost(API.CONTACT_US, body)
      .then((res) => {
        if (res.data.status_code === 201) {
          handleSuccess();
        }
        else if (res.data.status_code === 400) {
          handleErrors(res.data?.data?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
}

// POST API corporate enquiry form
export const corporateEnquiry = (body, handleSuccess, handleErrors) => {
  return async (dispatch, getstate) => {
    axiosPost(API.CORPORATE_ENQUIRY, body)
      .then((res) => {
        if (res.data.status_code === 201) {
          handleSuccess();
        }
        else if (res.data.status_code === 400) {
          handleErrors(res.data?.data?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
};

// get the corporate categories list
export const corporateCategories = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosGetWithToken(`${API.CORPORATE_CATEGORY_LIST}`, token).then((res) => {
      if (res.data.status_code === 200) {
        // localStorage.setItem('corporateCategory', JSON.stringify(res.data.data[0].id))
        dispatch({
          type: CORPORATES_CATEGORY,
          payload: res.data,
        });
        dispatch({
          type: INITIAL_CATEGORY_ID,
          payload: res.data.data[0].id,
        });
      }
    })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  }
};

// on click of proceed to book dispatching data to BOOKING_INFORMATION_DATA
export const handleProceedToBook = (data) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const fromDate = getState().corporates.pacStartDate;
    const toDate = getState().corporates.pacEndDate;
    const flexibleDates = getState().corporates.pacFlexibleDates;
    const body = {
      "pack_id": data.pack_id,
      "schedule_data": data?.resAPISelectedDates?.length > 0 ? data?.resAPISelectedDates : {
        "from": data?.resAPIStartDate,
        "to": data?.resAPIEndDate
      },
      "adults": data.adultGuestCount,
      "children": data.childrensGuestCount,
      "infants": data.count,
      "special_request": data.request,
      "total_price": data.totalPrice,
      "amount": data.points.toFixed(2),
      "points": data.points,
      "contact_name": data.name,
      "contact_email": data.email,
      "contact_number": data.contactNumber,
      "schedule_type": data?.schedule_type
    };
    localStorage.setItem("bookData", JSON.stringify(body));
    dispatch({
      type: BOOKING_INFORMATION_DATA,
      payload: body
    })
  }
}

export const updateBookingData = (data) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const fromDate = getState().corporates.pacStartDate;
    const toDate = getState().corporates.pacEndDate;
    const flexibleDates = getState().corporates.pacFlexibleDates;
    const booking_information_data = getState().corporates.booking_information_data
    const body = {
      ...booking_information_data,
      "schedule_data": data?.resAPISelectedDates?.length > 0 ? data?.resAPISelectedDates : {
        "from": data?.resAPIStartDate,
        "to": data?.resAPIEndDate
      },
      "adults": data?.adults,
      "children": data?.children,
      "infants": data?.infants,
      "contact_name": data?.contact_name,
      "contact_email": data?.contact_email,
      "contact_number": data?.contact_number,
      "special_request": data?.special_request,
    };
    localStorage.setItem("bookData", JSON.stringify(body));
    dispatch({
      type: BOOKING_INFORMATION_DATA,
      payload: body
    })
  }
}

// POST API list of corporate packages 
export const getCorporatePackages = (CATEGORY_ID, page) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const catId = getState().corporates.cat_id;
    // console.log("page getCorporatePackages", page)
    const body = {
      // "sec_id": catId,
      "sec_id": CATEGORY_ID,
      "next_page_id": page,
      "page_size": 8
    }
    // console.log("body", body)
    axiosPostWithToken(`${API.CORPORATE_PACKAGES_LIST}`, body, token).then((res) => {
      if (res.data.status_code === 200) {
        // handleLoader()
        dispatch({
          type: CORPORATES_PACKAGES,
          payload: res.data,
        });
        if (page == 1) {
          dispatch({
            type: RESET_CORP_LIST,
            payload: res.data.data.listing.results,
          })
        } else {

          dispatch({
            type: CORPORATES_PACKAGES_LIST,
            payload: res.data.data.listing.results,
          });
        }


      }
    })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      })

  }
}


export const setCategoryId = (id) => {
  return async (dispatch) => {
    dispatch({
      type: CATEGORY_ID,
      payload: id,
    });
    localStorage.setItem("corporateCategory", id);
  }
}


export const setPageSize = (page) => {
  // console.log(page)
  return async (dispatch) => {
    dispatch({
      type: PAGE_SIZE,
      payload: page,
    });
  }
}


// GET API to get the details of individual package
export const getPackageDetails = (id, loader) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosGetWithToken(`${API.CORPORATE_PACKAGE_DETAILS_API + id}/?request_type=corporate_user`, token).then((res) => {
      if (res.data.status_code === 200) {

        dispatch({
          type: CORPORATE_PACKAGE_DETAILS,
          payload: res.data,
        });
        localStorage.setItem("packageDetails", JSON.stringify(res?.data))
        loader();

      }
    })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
        loader()
      });
  }
}

// GET API to get the testimonial of individual events
export const getTestimonials = (id) => {
  return async (dispatch, getState) => {
    axiosGet(`${API.CORPORATE_TESTIMONIALS + id}/`).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: CORPORATE_TESTIMONIALS,
          payload: res.data,
        });
      }
    })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  }
}
//reviews actions
export const ReviewPostCheck = (id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      pack_id: id,
    };
    axiosPostWithToken(API.CORPORATE_REVIEW_POST_CHECK, body, token)
      .then((res) => {
        dispatch({
          type: CORPORATE_REVIEW_REFERENCE_ID,
          payload: res?.data?.data.map(e => e.ref_id).filter(f => f != null),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const reviewListing = (id, page) => {
  return async (dispatch, getState) => {
    const body = {
      next_page_id: page,
      page_size: 5,
    };
    axiosPost(`${API.CORPORATE_REVIEW_LIST + id}/`, body)
      .then((res) => {
        dispatch({
          type: CORPORATE_REVIEW_LIST,
          payload: res?.data?.data.review_data,
        });
        dispatch({
          type: CORPORATE_REVIEWLIST_TOTAL_PAGE,
          payload: res?.data?.data.review_data.total_pages
        })
        dispatch({
          type: CORPORATE_REVIEWLIST_CURRENT_PAGE,
          payload: res?.data?.data.review_data.current_page
        })

      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const reviewStatusClearUp = () => {
  return async (dispatch) => {
    dispatch({
      type: CORPORATE_REVIEW_POST,
      payload: null,
    });
  };
};
export const changePackageIndex = (index) => {
  return async (dispatch) => {
    dispatch({
      type: CORPORATE_ACTIVE_PACKAGE_INDEX,
      payload: index,
    });
  };
};
export const postReview = (pkgid, cmt, str, refid) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      pack_id: pkgid,
      review_star: str,
      review_comment: cmt,
      ref_id: refid,
    };
    axiosPostWithToken(API.CORPORATE_REVIEW_POST, body, token)
      .then((res) => {
        dispatch({
          type: CORPORATE_REVIEW_POST,
          payload: res?.data?.data.message,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getFilteredPackageDetails = (id, loader) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosGetWithToken(`${API.CORPORATE_FILTERED_PACKAGE_DETAILS_API + id}/?request_type=corporate_user`, token).then((res) => {
      if (res.data.status_code === 200) {

        dispatch({
          type: CORPORATE_PACKAGE_DETAILS,
          payload: res.data,
        });
        localStorage.setItem("packageDetails", JSON.stringify(res?.data))
        loader();

      }
    })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
        loader()
      });
  }
}

// filter packages by points
export const filterByPointsListing = (categoryID, searchInput, minVal, maxVal, page, handleLoader) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const catId = getState().corporates.cat_id;
    const body = {
      "sec_id": categoryID,
      "min_val": minVal,
      "max_val": maxVal,
      "search_term": searchInput,
      "request_type": "corporate_user",

      "next_page_id": page,
      "page_size": 8
    };
    axiosPostWithToken(`${API.SEARCH_BY_LOCATION}`, body, token).then((res) => {
      if (res.data.status_code === 200) {
        handleLoader()
        dispatch({
          type: CORPORATES_PACKAGES,
          payload: res.data,
        });
        if (page == 1) {
          dispatch({
            type: RESET_CORP_LIST,
            payload: res.data.data.listing.results,
          })
        } else {
          dispatch({
            type: CORPORATES_PACKAGES_LIST,
            payload: res.data.data.listing.results,
          });
        }
      }
    })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  }
}
// POST API to get similar packages for gift vouchers
export const getSimilarPackagesOrGifts = (id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const packid = {
      pack_id: id
    };
    axiosPostWithToken(`${API.SIMILAR_PACKAGE_GIFTS_API}`, packid, token).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: SIMILAR_GIFTS,
          payload: res.data,
        });
      }
    })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });

  }
}
// POST API to get similar packages for packages
export const getSimilarPackages = (id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const packid = {
      pack_id: id
    };
    axiosPostWithToken(`${API.GET_SIMILAR_OFFERS}`, packid, token).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: SIMILAR_GIFTS,
          payload: res.data,
        });
      }
    })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });

  }
}

// search packages by location
export const searchByLocation = (categoryID, searchInput, minVal, maxVal, page, handleLoader) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;;
    const catId = getState().corporates.cat_id;
    const body = {
      "search_term": searchInput,
      "sec_id": categoryID,
      "min_val": minVal,
      "request_type": "corporate_user",
      "max_val": maxVal,
      "next_page_id": page,
      "page_size": 8
    };
    axiosPostWithToken(`${API.SEARCH_BY_LOCATION}`, body, token).then((res) => {
      if (res.data.status_code === 200) {
        handleLoader()
        dispatch({
          type: CORPORATES_PACKAGES,
          payload: res.data,
        });
        if (page == 1) {
          dispatch({
            type: RESET_CORP_LIST,
            payload: res.data.data.listing.results,
          })
        } else {
          dispatch({
            type: CORPORATES_PACKAGES_LIST,
            payload: res.data.data.listing.results,
          });
        }
      }
    })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  }
}

export const getSearchValue = (value) => {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_SEARCH_VALUE,
      payload: value
    })
  }
};

export const getFilterValues = (value, callback) => {

  return async (dispatch, getState) => {
    dispatch({
      type: FILTER_VALUES,
      payload: value
    })
    // callback()
  }
};

export const handleStartAndEndDate = (sDate, eDate, selectedDates) => {
  const startAndEndDate = {
    startDate: sDate,
    endDate: eDate,
    flexibleDates: selectedDates
  }
  localStorage.setItem("startAndEndDate", JSON.stringify(startAndEndDate));
  return async (dispatch, getState) => {
    dispatch({
      type: PACKAGE_START_DATE,
      payload: sDate
    })
    dispatch({
      type: PACKAGE_END_DATE,
      payload: eDate
    })
    dispatch({
      type: PACKAGE_FLEXIBLE_DATES,
      payload: selectedDates
    })

  }
}
export const guestData = (guestData) => {
  return async (dispatch, getState) => {
    dispatch({
      type: GUEST_DATA,
      payload: guestData
    })
  }
};

export const changeDataforProceedToBook = (dataChanged, dataChangedBool) => {
  return async (dispatch, getState) => {
    dispatch({
      type: CHANGE_DATA_FOR_PROCEED_TO_BOOK,
      payload: dataChanged
    })
    dispatch({
      type: DATA_CHANGED_BOOL_VAL,
      payload: dataChangedBool
    })
    localStorage.setItem("dataChangedBool", JSON.stringify(dataChangedBool));
    localStorage.setItem("dataChanged", JSON.stringify(dataChanged));
  }
}

// checkout function
// CONDITION 1: If points are greater then amount pay with points and amount will be equal to zero.
// CONDITION 2: Else pay with amount redirect's to bank payment screen (can also use points and can pay balance amount)
export const checkOut = (data, paidWithPoints, payAmount) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const fromDate = getState().corporates.pacStartDate;
    const toDate = getState().corporates.pacEndDate;
    const flexibleDates = getState().corporates.pacFlexibleDates;
    const body = {
      "pack_id": data?.pack_id,
      "schedule": data?.resAPISelectedDates?.length > 0 ? data?.resAPISelectedDates : {
        "from": data?.resAPIStartDate,
        "to": data?.resAPIEndDate
      },
      "adults": data?.adults,
      "children": data?.children,
      "infants": data?.infants,
      "special_request": data?.special_request,
      "total_points": data?.total_points,
      "amount": data?.amount,
      "contact_name": data?.contact_name,
      "contact_email": data?.contact_email,
      "contact_number": data?.contact_number,
      "pay_mode": data?.amount > 0 ? data?.pay_mode : "",

    };

    if (data?.amount === 0) {
      axiosPostWithToken(`${API.CORPORATE_BOOKING_CHECKOUT}`, body, token).then((res) => {
        if (res.data.status_code === 200) {
          if (res.data.data.serializer.booking_status === "booked") {
            dispatch({
              type: BOOKING_STATUS,
              payload: res.data,
            });
            paidWithPoints();
          }
        }
      })
        .catch((err) => {
          dispatch({
            type: ERROR_ALERT,
            payload:
              (err && err.response && err.response.data) ||
              "Something went wrong.",
          });
        })
    }
    else {
      axiosPostWithToken(`${API.CORPORATE_BOOKING_PAYMENT_GATEWAY}`, body, token).then((res) => {
        if (res.data.status_code === 200) {
          const resBody = res.data.data;
          const paymentBody = {
            surl: `${BASE_URL}/user/payment/success/`,
            curl: `${BASE_URL}/user/payment/cancelled/`,
            furl: `${BASE_URL}/user/payment/failure/`,
            // key: res?.data?.data?.pay_mode == "CC" ? `${CC_PAYMENT_KEY}` : `${DC_PAYMENT_KEY}`, // live server payment's key
            key: `${TEST_PAYMENT_KEY}`, // test server payment's key
            phone: data?.contact_number,
          }
          const payment_details = { ...resBody, ...paymentBody }
          const checkout_details = { ...body, ...payment_details }
          dispatch({
            type: CHECKOUT_DETAILS,
            payload: payment_details,
          });
          payAmount(checkout_details);
        }
      })
        .catch((err) => {
          dispatch({
            type: ERROR_ALERT,
            payload:
              (err && err.response && err.response.data) ||
              "Something went wrong.",
          });
        });
    }
    return
  }
};
export const internalPackcheckOut = (data,paidWithPoints, payAmount) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const fromDate = getState().corporates.pacStartDate;
    const toDate = getState().corporates.pacEndDate;
    const flexibleDates = getState().corporates.pacFlexibleDates;
    
    const body = {
      "total_points":data?.total_points,
      "amount":data?.amount,
      "contact_name":data?.contact_name,
      "contact_email":data?.contact_email,
      "contact_number":data?.contact_number,
      "pay_mode":data?.amount > 0 ? data?.pay_mode : "",
      "pack_slug":data?.pack_slug
      }

    if (data?.amount === 0) {
      axiosPostWithToken(`${API.INTERNAL_PACKAGE_BOOKING}`, body, token).then((res) => {
        if (res.data.status_code === 200) {
            dispatch({
              type: BOOKING_STATUS,
              payload: res.data,
            });
            paidWithPoints();
          }
        
      })
        .catch((err) => {
          dispatch({
            type: ERROR_ALERT,
            payload:
              (err && err.response && err.response.data) ||
              "Something went wrong.",
          });
        })
    }
    else {
      axiosPostWithToken(`${API.INTRNAL_PACKAGE_GATEWAY}`, body, token).then((res) => {
        if (res.data.status_code === 200) {
          const resBody = res.data.data;
          const paymentBody = {
            surl: `${BASE_URL}/user/payment/internal-pack-user-success/`,
            curl: `${BASE_URL}/user/payment/cancelled/`,
            furl: `${BASE_URL}/user/payment/internal-pack-user-failure/`,
            // key: res?.data?.data?.pay_mode == "CC" ? `${CC_PAYMENT_KEY}` : `${DC_PAYMENT_KEY}`, // live server payment's key
            key: `${TEST_PAYMENT_KEY}`, // test server payment's key
            phone: data?.contact_number,
          }
          const payment_details = { ...resBody, ...paymentBody }
          const checkout_details = { ...body, ...payment_details }
          dispatch({
            type: INTERNAL_CHECKOUT_DETAILS,
            payload: payment_details,
          });
          payAmount(checkout_details);
        }
        else{
          dispatch({
            type: ERROR_ALERT,
            payload:
              (res.data.data.message) ||
              "Something went wrong.",
          });
        }
      })
        .catch((err) => {
          dispatch({
            type: ERROR_ALERT,
            payload:
              (err && err.response && err.response.data) ||
              "Something went wrong.",
          });
        });
    }
    return
  }
};

export const availabilityRequest = (data, handleSuccess, payAmount) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const fromDate = getState().corporates.pacStartDate;
    const toDate = getState().corporates.pacEndDate;
    const flexibleDates = getState().corporates.pacFlexibleDates;
    const body = {
      "pack_id": data?.pack_id,
      "schedule": data?.resAPISelectedDates?.length > 0 ? data?.resAPISelectedDates : {
        "from": data?.resAPIStartDate,
        "to": data?.resAPIEndDate
      },
      "adults": data?.adults,
      "children": data?.children,
      "infants": data?.infants,
      "special_request": data?.special_request,
      "contact_name": data?.contact_name,
      "contact_email": data?.contact_email,
      "contact_number": data?.contact_number,
      "amount": data?.price

    };
    axiosPostWithToken(`${API.CORPORATE_CHECK_AVAILABILITY}`, body, token).then((res) => {
      if (res.data.status_code === 200) {
        if (res.data.data.serializer.booking_status === "requested") {
          handleSuccess();
        }
      }
    })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      })
  }
}

// GET list of all locations

export const getLocationsList = (categoryID) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosGetWithToken(`${API.LOCATIONS_LIST}?sec_id=${categoryID}`, token).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: ALL_LOCATIONS_LIST,
          payload: res.data,
        });
      }
    })
      .catch((err) => {
        console.log("err", err)
      });
  }
};

export const getCountryList = (secId) => {
  return async (dispatch, getState) => {
    axiosGet(`${API.COUNTRY_LIST + secId}/`).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: CORP_COUNTRY_LIST,
          payload: res.data,
        });
      }
    })
      .catch((err) => {
        console.log("err", err)
      });
  }
};

export const giftVoucherEditName = (name, bool) => {
  return async (dispatch) => {
    dispatch({
      type: EDITED_GIFTVOUCHER_NAME,
      payload: name
    });
    dispatch({
      type: EDITED_GIFTVOUCHER_NAME_BOOL,
      payload: bool
    });
  }
};

export const giftVoucherEditNumber = (number, bool) => {
  return async (dispatch) => {
    dispatch({
      type: EDITED_GIFTVOUCHER_NUMBER,
      payload: number
    });
    dispatch({
      type: EDITED_GIFTVOUCHER_NUMBER_BOOL,
      payload: bool
    });
  }
}

export const corpHolidayEditedName = (name, bool) => {
  return async (dispatch, getState) => {
    dispatch({
      type: EDITED_CORP_HOLIDAY_NAME,
      payload: name
    });
    dispatch({
      type: EDITED_CORP_HOLIDAY_NAME_BOOL,
      payload: bool
    });
  }
};

export const corpHolidayEditedNumber = (number, bool) => {
  return async (dispatch) => {
    dispatch({
      type: EDITED_CORP_HOLIDAY_NUMBER,
      payload: number
    });
    dispatch({
      type: EDITED_CORP_HOLIDAY_NUMBER_BOOL,
      payload: bool
    });
  }
};

// GET API to get nearby packages
export const getNearByPackages = (id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const packid = {
      pack_id: id
    };
    axiosGetWithToken(`${API.CORPORATE_NEARBY + id}`, token).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: NEAR_BY_PACKAGES,
          payload: res.data,
        });
      }
    })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  }
}
// GET API to get more packages
export const getMorePackages = (id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const packid = {
      pack_id: id
    };
    axiosGetWithToken(`${API.CORPORATE_NEARBY + id}/?more=true`, token).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: MORE_PACKAGES,
          payload: res.data,
        });
      }
    })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  }
}
//get internal pack details
export const getInternapPackDetails = (id,setHandleError) => {
  return async (dispatch, getState) => {
    axiosGet(`${API.GET_INTERNAL_PACK + id}/`)
      .then((res) => {
 if(res.data.status_code===200){
        dispatch({
          type: INTERNAL_PACK_DATA,
          payload: res?.data?.data,
        });
        
      }
      else{
        setHandleError(true)
      }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};