import leftArrow from "../assets/images/common/left.svg";
import rightArrow from "../assets/images/common/right.png";

import leftIcon from "../assets/images/corporates/left-arrow.svg";
import rightIcon from "../assets/images/corporates/right-arrow.svg";

export const story_options = {
  items: 3,
  margin: 32,
  responsiveClass: true,
  nav: true,
  navText: [`<img src=${leftArrow} />`, `<img src=${rightArrow} />`],
  loop: false,
  stagePadding: 85,
  navRewind: false,
  dots: false,
  autoplay: false,
  smartSpeed: 500,
  responsive: {
    0: {
      items: 1,
      nav: false,
      margin: 16,
      stagePadding: 40

    },
    767: {
      items: 1,
      nav: false,
      margin: 16,
      stagePadding: 50
    },
    992: {
      items: 2,
      nav: true,
    },
    1200: {
      items: 2,
      nav: true,
    }

  }
}
export const service_options = {
  items: 1,
  margin: 0,
  nav: false,
  loop: false,
  rewind:true,
  dots: true,
  autoplay: false, 
  smartSpeed: 100,
  animateIn: 'fadeIn',
  animateOut: 'fadeOut',
  responsive: {
    0: {
      items: 1,
      stagePadding:0

    },
    767: {
      items: 1,
      stagePadding: 20
      
    },
  }
}
export const responsive = {
  items: 4,
  margin: 32,
  responsiveClass: true,
  nav: true,
  navText: [`<img src=${leftArrow} />`, `<img src=${rightArrow} />`],
  loop: false,
  stagePadding: 150,
  navRewind: false,
  dots: false,
  autoplay: false,
  smartSpeed: 500,
  responsive : {
  0: {
      items: 1,
      nav: false,
      margin: 16,
      stagePadding: 60

    },
  767: {
    items: 1,
    nav: false,
    margin: 16,
    stagePadding: 60
  },
  992: {
    items: 3,
    nav: true,
  },
  1200: {
    items: 3,
    nav: true,
  }
  }
}
export const categoryOptions = {
  items: 4,
  margin: 34,
  responsiveClass: true,
  nav: true,
  navText: [`<img src=${leftArrow} />`, `<img src=${rightArrow} />`],
  loop: false,
  stagePadding: 150,
  navRewind: false,
  autoWidth:true,
  dots: false,
  smartSpeed: 0,
  responsive : {
  992: {
    items: 3,
    nav: true,
    autoWidth:false,
  },
  1050:{
    items: 3,
    nav: true,
    autoWidth:false,
  },
  1200: {
    items: 3,
    nav: true,
  },
  1400: {
    items: 5,
    nav: true,
    stagePadding: 0,
    margin:24,
    
  },
  1600: {
    items: 5,
    nav: true,
    margin: 24,
    slideBy: 2
  },
  1800:{
    items: 5,
    nav: true,
    stagePadding: 100,
    slideBy: 3
  },
  
  }
}
export const options = {
  items: 4,
  margin: 32,
  responsiveClass: true,
  nav: true,
  navText: [`<img src=${leftArrow} alt="left-arrow"/>`, `<img src=${rightArrow} alt="right-arrow"/>`],
  loop: false,
  stagePadding: 85,
  navRewind: false,
  dots: false,
  autoplay: false,
  smartSpeed: 500,
  responsive: {
    0: {
      items: 1,
      nav: false,
      margin: 16,
      stagePadding: 40

    },
    767: {
      items: 1,
      nav: false,
      margin: 16,
      stagePadding: 50
    },
    768: {
      items: 2,
      nav: false,
      margin: 16,
      stagePadding: 50
    },
    992: {
      items: 3,
      nav: true,
    },
    1200: {
      items: 3,
      nav: true,
    }

  }
};

export const traveller_options = {
  items: 2,
  margin: 32,
  stagePadding: 85,
  responsiveClass: true,
  nav: false,
  loop: false,
  navRewind: false,
  dots: false,
  autoplay: false,
  smartSpeed: 500,
};

export const recognitions_options = {
  margin: 20,
  autoWidth: true,
  responsiveClass: true,
  nav: false,
  loop: false,
  navRewind: false,
  dots: false,
  autoplay: false,
  smartSpeed: 500,
};



export const corporate_options = {
  items: 4,
  margin: 22,
  responsiveClass: true,
  nav: true,
  navText: [`<img src=${leftIcon} />`, `<img src=${rightIcon} />`],
  loop: false,
  navRewind: false,
  dots: false,
  autoplay: false,
  smartSpeed: 500,
  responsive: {
    0: {
      items: 1,
      nav: false,
      margin: 12,
      stagePadding: 40

    },
    767: {
      items: 2,
      nav: false,
      margin: 16,
      stagePadding: 50
    },
    768: {
      items: 2,
      nav: false,
      margin: 16,
      stagePadding: 50
    },
    992: {
      items: 3,
      nav: true,
    }

  }
};


export const slider_options = {
  items: 2,
  margin: 10,
  nav: false,
  loop: false,
  rewind:true,
  dots: false,
  autoplay: false, 
  smartSpeed: 100,
  stagePadding: 40

}

export const testimonial_options = {
  items: 1,
  margin: 0,
  nav: false,
  loop: false,
  rewind:true,
  dots: true,
  autoplay: true, 
  smartSpeed: 100,
  animateIn: 'fadeIn',
  animateOut: 'fadeOut',
  responsive: {
    0: {
      items: 1,
      stagePadding: 20

    },
    767: {
      items: 1,
      stagePadding: 20
      
    },
    998: {
      items: 1,
      stagePadding: 0
      
    }
  }
}