import React from 'react'
import WhatsappLogo from '../../../assets/images/corporates/whatsapp-logo.svg'
import closeIcon from '../../../assets/images/common/close-modal.svg';

function InternationalTravelDesk({ showContact, setShowContact, contactModalRef }) {
    const closeFormHandler = () => {
        setShowContact(false)
    }
    return (
        <div>
            <div className={showContact ? "visa-enquire-mobile" : "visa-enquire-desk"}>
                <div className="mobile-bg" ref={contactModalRef}>
                    {showContact ? <span onClick={closeFormHandler}><img src={closeIcon} className="close-icon" /></span> : null}
                    {showContact ?
                        <div className="heading">
                            <h2>Contact Us</h2>
                        </div> : null}
                    <div className="login-holder">
                        <h5>International Travel desk</h5>
                        <div class="line"></div>
                        <div class="contact-card mb-3 d-flex align-items-center justify-content-between">
                            <div>
                                <p>
                                    <a href="tel:+919008253000">+91 90082 53000</a>
                                </p>
                                <p>Our number</p>
                            </div>
                            <div>
                                <a href="https://wa.me/+919008253000" rel="noreferrer" target="_blank">
                                    <img src={WhatsappLogo} alt="" />
                                </a>
                            </div>
                        </div>
                        <div class="contact-card">
                            <p>
                                <a href="mailto:outbound@hammockholidays.com" rel="noreferrer"
                                    target="_blank" >
                                    outbound@hammockholidays.com </a>
                            </p>
                            <p>Our email</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default InternationalTravelDesk
