import React from 'react';
import OwlCarousel from "react-owl-carousel";
import { useEffect } from 'react';

import {slider_options} from "../../resources/carouselOptions"
import {
    REWARDS_COLLAGE,
    ENGAGEMENT_COLLAGE,
    EVENT_COLLAGE,
    GROUP_COLLAGE,
    WEDDING_COLLAGE,
    WEDDING_MOBILE_COLLAGE,
    honeyMoonCollage,
    anniversaryCollage,
    anniversaryMobileCollagge,
    honeymoonMobileCollagge
  } from "../../resources/corporateData";

import image1 from '../../assets/images/corporates/Converted/1.webp';
import image2 from '../../assets/images/corporates/Converted/2.webp';
import image3 from '../../assets/images/corporates/Converted/3.webp';
import image4 from '../../assets/images/corporates/Converted/4.webp';
import image5 from '../../assets/images/corporates/Converted/5.webp';
import image6 from '../../assets/images/corporates/Converted/6.webp';
import image7 from '../../assets/images/corporates/Converted/7.webp';
import image8 from '../../assets/images/corporates/Converted/8.webp';
import enage1 from '../../assets/images/corporates/collage/engagemnet/1.png';
import enage2 from '../../assets/images/corporates/collage/engagemnet/2.png';
import enage3 from '../../assets/images/corporates/collage/engagemnet/3.png';
import enage4 from '../../assets/images/corporates/collage/engagemnet/4.png';
import enage5 from '../../assets/images/corporates/collage/engagemnet/5.png';
import group1 from '../../assets/images/corporates/collage/group/group1.png';
import group2 from '../../assets/images/corporates/collage/group/group2.png';
import group3 from '../../assets/images/corporates/collage/group/group3.png';
import group4 from '../../assets/images/corporates/collage/group/group4.png';
import group5 from '../../assets/images/corporates/collage/group/group5.png';
import event1 from '../../assets/images/corporates/collage/events/event1.png';
import event2 from '../../assets/images/corporates/collage/events/event2.png';
import event3 from '../../assets/images/corporates/collage/events/event3.png';
import event4 from '../../assets/images/corporates/collage/events/event4.png';
import event5 from '../../assets/images/corporates/collage/events/event5.png';

const CategoryImages = ({id,fromWedding,fromHoneyMoon,fromAnniversary,section}) => {

    const enage = [enage1,enage2,enage3,enage4,enage5];
    const groups = [group1,group2,group3,group4,group5];
    const events = [event1,event2,event3,event4,event5];
    let images = [];
    fromHoneyMoon?images.push(...honeyMoonCollage):fromAnniversary?images.push(...anniversaryCollage): fromWedding? images.push(...WEDDING_COLLAGE) :id == 2 ? images.push(...enage):id == 3 ? images.push(...events): images.push(...groups);
    const owlimg= [];
    fromHoneyMoon?owlimg.push(...honeymoonMobileCollagge):fromAnniversary?owlimg.push(...anniversaryMobileCollagge): fromWedding? owlimg.push(...WEDDING_MOBILE_COLLAGE) : id == 1 ? owlimg.push(...REWARDS_COLLAGE):id == 2 ? owlimg.push(...ENGAGEMENT_COLLAGE):id == 3 ? owlimg.push(...EVENT_COLLAGE): owlimg.push(...GROUP_COLLAGE);
    // useEffect(()=>{
    //   if(fromWedding){
    //     images.push(...WEDDING_COLLAGE)
    //   }
    // },[])
    return(

        <>
        {
            id == 1 ?
          <>
          <div className="rewards-collage mt-5 d-none d-md-block">
            <div className="container">
              <div className="row no-gutters">
                <div className="col-5 px-1">
                  <div className="first" style={{position: "relative"}}>
                    {/* <div className="img-overlay"></div> */}
                    <img src={image1} alt={section}/> 
                  </div>
                </div>
                <div className="col-7 px-1">
                  <div className="row no-gutters">
                    <div className="col-6 pr-2">
                      <div className="middle">
                        <div className="mb-2" style={{position: "relative"}}>
                          {/* <div className="img-overlay"></div> */}
                          <img src={image2} alt={section}/>
                        </div>
                        <div>
                          <img src={image3} alt={section}/>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 pr-2">
                      <div className="last">
                        <div className="inner pb-2">
                          <div className="row no-gutters">
                            <div className="col-6 pr-1">
                              <div className="left">
                                <img src={image5} className="pb-2" alt={section}/>
                                <img src={image6} alt={section}/>
                              </div>
                            </div>
                            <div className="col-6 pl-1">
                              <div className="right">
                                <img src={image7} className="pb-2" alt={section}/>
                                <img src={image8} alt=""/>
                              </div>
                            </div>
                          </div>
                        </div>
                          <img src={image4} alt={section}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="mobile-reward-slider mt-5 d-block d-md-none"> 
            <OwlCarousel className="owl-theme" {...slider_options} >
              {
                REWARDS_COLLAGE && REWARDS_COLLAGE.length > 0 && REWARDS_COLLAGE?.map((item) => (
                  <div>
                    <div className="img-overlay"></div>
                    <img src={item?.image} alt="" />
                  </div>
                ))
              }
            </OwlCarousel>
          </div> */}
          </>
          :
          <>
          <div className="engagement-collage mt-5 d-none d-md-block">
            <div className="container">
              <div className="row no-gutters">
                <div className="col-5 px-1">
                  <div className="first">
                    <div className="img-overlay"></div>
                    <img src={images[0]} alt={section}/> 
                  </div>
                </div>
                <div className="col-7 px-1">
                  <div className="row no-gutters">
                    <div className="col-6 pr-2">
                      <div className="middle">
                          <img src={images[1]} className="mb-2" alt={section}/>
                          <img src={images[4]} alt={section}/>
                        
                      </div>
                    </div>
                    <div className="col-6 pr-2">
                      <div className="last">
                          <img src={images[2]} className="mb-2" alt={section}/>
                          <img src={images[3]} alt={section}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          </>
          }
          <div className="mobile-reward-slider mt-5 d-block d-md-none"> 
            <OwlCarousel className="owl-theme" {...slider_options} >
              {
                owlimg && owlimg.length > 0 && owlimg?.map((item,idx) => (
                  <div>
                    <div className="img-overlay"></div>
                    <img src={item?.image} alt={`${section} ${idx+1}`} />
                  </div>
                ))
              }
            </OwlCarousel>
          </div>
        </>
    )
}
export default CategoryImages