import React, { useEffect } from 'react';
import successIcon from '../../../assets/images/common/success.svg';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as corporateAction from '../../../redux/actions/corporatesAuthActions';

function PaymentSuccess() {
    const purchaseDetails = useSelector(state => state.corporates.booking_details.data?.serializer);
    const bookingDetails = useSelector(state => state.corporates.booking_details.data);
    const dispatch = useDispatch()
    const history = useHistory();
    useEffect(() => {
        dispatch(corporateAction.checkOut())
    }, [])

    const goToHome = () => {
        if(purchaseDetails?.corporate){
            history.push(`/corporates`);
        }else{
            history.push(`/`);
        }
    }

    return (
        <div>
            <div className="payment-status">
                <div className="text-center">
                    <img src={successIcon} alt="successIcon" />
                </div>
                <div className="payment-details text-center">
                    <p className="status">Successfully Paid {purchaseDetails?.total_points||bookingDetails?.total_points} Pts</p>
                    <p className="booking-for">Booking received for <span>{purchaseDetails?.pack_title||bookingDetails?.productinfo}</span>  </p>
                    <p className="transactionId">Ref. ID : {bookingDetails?.ref_id}</p>
                    <p className="transactionId">Transaction ID : {purchaseDetails?.txnid||bookingDetails?.productinfo}</p>
                    {/* {
                        purchaseDetails?.booking_status === "booked" ? <p className="transactionId">Status: Success</p> : null
                    } */}
                    <p className="message">Thank you for booking, an email will be sent to {purchaseDetails?.contact_email||bookingDetails?.email} with all details</p>
                    <div class="goto-home">
                        <button className="cursor-pointer btn-goto-home" onClick={goToHome}>Continue Exploring</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentSuccess
