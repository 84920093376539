import React, {useEffect, useState, useRef} from 'react';
import OwlCarousel from "react-owl-carousel";
import $ from 'jquery';
import VideoPopup from './videoPopup';
import * as commonAction from '../../redux/actions/commonAction';
import { useDispatch, useSelector } from "react-redux";
import { ScrollToTop } from '.';
import useClickOutside from "../../hooks/useClickOutside";
// import leftIcon from "../../assets/images/corporates/left-arrow.svg";
// import rightIcon from "../../assets/images/corporates/right-arrow.svg";
import leftArrow from "../../assets/images/common/left.svg";
import rightArrow from "../../assets/images/common/right.png";

const PackageDetailsCarousel = React.memo(({ dataChangedBool, dataChanged, initialPackageData})=>{
   let owl_images = {
    items: 1,
    margin: 0,
    nav: false,
    loop: false,
    rewind:false,
    dots: false,
    autoplay: false, 
    smartSpeed: 100,
    animateIn: 'fadeIn',
    animateOut: 'fadeOut',
    video: true,
    responsive: {
        0:{
            items: 1,
            nav: false,
            margin: 0,
            dots: true,
            rewind:true,
            autoplay:true
        },
        767:{
            items: 1,
            nav: true,
            margin: 10,
            dots: false,
            rewind: false,
            stagePadding: 80,
            navText: [`<img alt='leftArrow' src=${leftArrow} />`, `<img alt='rightArrow' src=${rightArrow} />`],
        },
        992: {
          items: 2,
          nav: true,
          margin: 12,
          stagePadding: 40,
          navText: [`<img alt='leftArrow' src=${leftArrow} />`, `<img alt='rightArrow' src=${rightArrow} />`],

        }
    },
    onClick: function(){
        let owl = window.$('.owl-carousel')
        owl.owlCarousel();
        owl.trigger('stop.owl.autoplay');
    },
    onTranslate: function(index){
        $('.owl-item').find('video').each(function() {
            this.pause();
        });
        let player = $('iframe');
        $('.video_mask').width('100%');
        $('.video_mask').height('100%');
        let owl = window.$('.owl-carousel')
        owl.owlCarousel();
        // setStartPosition(index.item.index)
        if(player){
            player.map((index) => {
                $('iframe')[index].contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
                $('iframe')[index].contentWindow.postMessage('{"method":"pause","value":"' + 'pauseVideo' + '","args":""}', '*');
            })
        }
    }
};
    
    const [startPosition, setStartPosition] = useState(0);
    const [showVideoPopup, setShowVideoPopup] = useState(false);
    const dispatch = useDispatch();
    const popupRef = useRef();
    useClickOutside(popupRef, (e) => {
        setTimeout(() => setShowVideoPopup(false), 100);
    });

    const videoClicked = (vm, type)=>{
        let owl = window.$('.owl-carousel')
        owl.owlCarousel();
        owl.trigger('stop.owl.autoplay');
        
        window.$('.video_mask').width(0);
        window.$('.video_mask').height(0);
        
        if(type == "youtube" || type == "other"){
            window.document.querySelector(`.${vm}`).contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
        window.document.querySelector(`.${vm}`).contentWindow.postMessage('{"method":"play","value":"' + 'playVideo' + '","args":""}', '*');
        }else if(type == "video"){
            window.document.querySelector(`.${vm}`).play()
        }else{
            return null;
        }
        
    };
    const mobileVideoClicked = (item, index) => {
        dispatch(commonAction.setVideo(item));
        setStartPosition(index)
        handleVideoPopup()
        let owl = window.$('.owl-carousel')
        owl.owlCarousel();
        owl.trigger('stop.owl.autoplay');
        
        
        
    }
    const handleVideoPopup = () => {
        setShowVideoPopup(true);
      };

    useEffect(()=>{
        // let owl = window.$('.owl-carousel')
        // owl.owlCarousel();
        // owl.trigger('play.owl.autoplay');
    }, [initialPackageData, dataChanged]);

    return <div>
    <OwlCarousel  startPosition={startPosition} className="owl-theme" {...owl_images}
    >
        { dataChangedBool ? 
            dataChanged?.images?.map((image, index) => {
                if(image.media_type == "image"){
                    return <div className="item">
                                <div className="package-image">
                                    <img
                                        src={image?.image}
                                        className="img-fluid"
                                        alt="images"
                                    />
                                    {console.log(image, dataChangedBool, "packImages")}
                                </div>
                            </div>
                }else if(image?.media_type == "youtube"){
                    return (
                    <div className="package-video" >
                    <div className="video_mask" onClick={()=>videoClicked(`manage-video-${index}`, image?.media_type)}></div>
                    <div className="mobile_video_mask" onClick={()=> mobileVideoClicked(image, index)}></div>
                    <iframe className={`youtube-video manage-video-${index}`}
                    src={`https://www.youtube.com/embed/${new URL(image?.video).searchParams.get("v")}?modestbranding=1&enablejsapi=1&version=3&rel=0&playerapiid=ytplayer`}
                    title="YouTube video player" 
                    frameborder="0" 
                    
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowfullscreen="allowfullscreen"
                    
                    >
                    
                    </iframe>
                    </div>)
                }else if(image.media_type == "other" && new URL(image?.video).hostname==='vimeo.com'){
                    return (
                        <div className="package-video" >
                            <div className="video_mask" onClick={()=>videoClicked(`manage-video-${index}`, image?.media_type)}></div>
                            <div className="mobile_video_mask" onClick={()=> mobileVideoClicked(image, index)}></div>
                            <iframe  className={`vimeo-video manage-video-${index}`}
                            src={`https://player.vimeo.com/video${new URL(image?.video).pathname}?api=1`} 
                    
                    frameborder="0" 
                    allow="autoplay; fullscreen; picture-in-picture" 
                    allowfullscreen>
            </iframe>
            </div>)
                }
                else if(image.media_type == "video"){
                    return (
                        <div className=" package-video">
                            <div className="video_mask" onClick={()=>videoClicked(`manage-video-${index}`, image.media_type)}></div>
                            <div className="mobile_video_mask" onClick={()=> mobileVideoClicked(image, index)}></div>
                                <video className={`video manage-video-${index}`} controls>
                                <source src={`${image.video}`} type="video/mp4" />
                                </video>
                            
                        </div>
                    )
                }else if(!image.media_type
                    ){
                        return <div className="item">
                                <div className="package-image">
                                    <img
                                        src={image?.image}
                                        className="img-fluid"
                                        alt="images"
                                    />
                                </div>
                            </div>
                }
                }) : 
                initialPackageData?.images?.map((image, index) => {
                    if(image.media_type == "image"){
                        return <div className="item">
                                    <div className="package-image">
                                        <img
                                            src={image?.image}
                                            className="img-fluid"
                                            alt={`${initialPackageData?.pack_title} ${index+1}`}
                                        />
                                    </div>
                                </div>
                    }else if(image?.media_type == "youtube"){
                        return (
                        <div className="package-video" >
                        <div className="video_mask" onClick={()=>videoClicked(`manage-video-${index}`, image?.media_type)}></div>
                        <div className="mobile_video_mask" onClick={()=> mobileVideoClicked(image, index)}></div>
                        <iframe className={`youtube-video manage-video-${index}`}
                        src={`https://www.youtube.com/embed/${new URL(image?.video).searchParams.get("v")}?modestbranding=1&enablejsapi=1&version=3&rel=0&playerapiid=ytplayer`}
                        title="YouTube video player" 
                        frameborder="0" 
                        alt={`${initialPackageData?.pack_title} ${index+1}`}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowfullscreen="allowfullscreen"
                        
                        >
                        
                        </iframe>
                        </div>)
                    }else if(image.media_type == "other" && new URL(image?.video).hostname==='vimeo.com'){
                        return (
                            <div className="package-video" >
                                <div className="video_mask" onClick={()=>videoClicked(`manage-video-${index}`, image?.media_type)}></div>
                                <div className="mobile_video_mask" onClick={()=> mobileVideoClicked(image, index)}></div>
                                <iframe  className={`vimeo-video manage-video-${index}`}
                                src={`https://player.vimeo.com/video${new URL(image?.video).pathname}?api=1`} 
                                alt={`${initialPackageData?.pack_title} ${index+1}`}
                        frameborder="0" 
                        allow="autoplay; fullscreen; picture-in-picture" 
                        allowfullscreen>
                </iframe>
                </div>)
                    }
                    else if(image.media_type == "video"){
                        return (
                            <div className=" package-video">
                                <div className="video_mask" onClick={()=>videoClicked(`manage-video-${index}`, image.media_type)}></div>
                                <div className="mobile_video_mask" onClick={()=> mobileVideoClicked(image, index)}></div>
                                    <video className={`video manage-video-${index}`} controls >
                                    <source src={`${image.video}`} type="video/mp4" alt={`${initialPackageData?.pack_title} ${index+1}`} />
                                    </video>
                                
                            </div>
                        )
                    }else if(!image.media_type
                        ){
                            return <div className="item">
                                    <div className="package-image">
                                        <img
                                            src={image?.image}
                                            className="img-fluid"
                                            alt={`${initialPackageData?.pack_title} ${index+1}`}
                                        />
                                    </div>
                                </div>
                    }
                    }) 
              
        }

    </OwlCarousel>
    {
        showVideoPopup ?
        <VideoPopup popupRef={popupRef} /> : null
    }

    </div>
})

export default PackageDetailsCarousel