import React, { useState, useEffect, useRef } from 'react'
import CommonButton from '../common/authCommonButton';
import DateRangePicker from './dateRangePicker';
import './calenderModal.css';
import * as CorporateAction from "../../redux/actions/corporatesAuthActions";
import { useDispatch } from "react-redux";
import moment from 'moment'
import 'animate.css'; 
const options = { weekday: 'short', day: 'numeric', month: 'long' };

const CalenderModal = ({ showMyModal, handleDatesData, isRecurring, isExperience, isPackage, isSpecific, recurringDaysList, specificDaysList, myfamily, getSpecificDatesMonth, packageDays, packageNights, isFlexible, value, prevStartDate, prevEndDate, handleResAPIStartAndEndDate }) => {
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(prevStartDate ? moment(prevStartDate, 'ddd-DD MMM YYYY')._d : null);
    const [endDate, setEndDate] = useState(prevEndDate ? moment(prevEndDate, 'ddd-DD MMM YYYY')._d : null);
    const [selectedDates, setSelectedDates] = useState(value);
    const [hasDateError, setHasDateError] = useState({
        hasError:false,
        msg:''
    });
    const wrapperRef = useRef(null);
    useModalOutsideClose(wrapperRef);
    const handleStartAndEndDates = (sDate, eDate) => {
        if (sDate !== null) {
            setStartDate(sDate._d);
        } else {
            setStartDate(null)
        }
        if (eDate !== null) {
            setEndDate(eDate._d);
        } else {
            setEndDate(null)
        }
        
    };
    
    const handleFlexDates = (selectedDateList)=>{
        const format = moment(selectedDateList).format('ddd, MM, YY')
        setSelectedDates(selectedDateList);
        
    }
    
    let resAPIStartDate = startDate !== null && startDate.getDate() + "-" + (startDate.getMonth() + 1) + "-" + startDate.getFullYear();
    let resAPIEndDate = endDate !== null && endDate.getDate() + "-" + (endDate.getMonth() + 1) + "-" + endDate.getFullYear();


    let resStartDate = startDate !== null && moment(startDate).isValid() && moment(startDate).format('ddd-DD MMM YYYY');
    let resEndDate = endDate !== null && moment(endDate).isValid() && moment(endDate).format('ddd-DD MMM YYYY');

    const handleData = (e, cancel) => {
        e&&e.preventDefault();
        if((isPackage || myfamily) && !resEndDate && resStartDate && !cancel && !isFlexible){
            setHasDateError({
                hasError:true,
                msg:`Please Select ${myfamily?'To':'Check-Out'} Date` 
            })
            return;
        } else if(isPackage && !resStartDate && !resEndDate && !cancel && !isFlexible){
            setHasDateError({
                hasError:true,
                msg:`Please Select ${myfamily?'From ':'Check-In'} & ${myfamily?'To ':'Check-Out'} Dates`
            })
            return;
        }else if(isSpecific && !resStartDate && !resEndDate && !cancel){
            setHasDateError({
                hasError:true,
                msg:`Please Select Date`
            })
            return;
        }else if(isFlexible && selectedDates?.length == 0 && !cancel){
            setHasDateError({
                hasError:true,
                msg:`Please Select Dates`
            })
            return;
        }else if(isRecurring && !resStartDate && !resEndDate && !cancel){
            setHasDateError({
                hasError:true,
                msg:`Please Select Date`
            })
            return;
        }

        if(!myfamily){
            if(isPackage && moment(endDate).diff(moment(startDate), 'days')<packageNights && !cancel){
                setHasDateError({
                    hasError:true,
                    msg:`Please select atleast ${packageDays} Day${packageDays>1&&'s'}/${packageNights} Night${packageNights>1&&'s'}`
                })
                return;
            }
            if(isFlexible){
                const changedFormat = selectedDates.map(date => moment(date, 'DD-MM-YYYY').format('MM/DD/YYYY'))
                const sorted = changedFormat.sort((a,b) => new Date(a).getTime() - new Date(b).getTime() );
                const selectedDatesList = sorted.map(date => moment(date, 'MM/DD/YYYY').format('DD-MM-YYYY'))
                handleDatesData(resStartDate, resEndDate, selectedDatesList);
                handleResAPIStartAndEndDate(moment(resAPIStartDate, "DD-MM-YYYY").format("DD-MM-YYYY") , moment(resAPIEndDate, "DD-MM-YYYY").format("DD-MM-YYYY"), selectedDatesList)
                // dispatch(CorporateAction.handleStartAndEndDate(resAPIStartDate, resAPIEndDate, selectedDatesList));
            }else{
                handleDatesData(resStartDate, resEndDate, selectedDates);
                handleResAPIStartAndEndDate(moment(resAPIStartDate, "DD-MM-YYYY").format("DD-MM-YYYY") , moment(resAPIEndDate, "DD-MM-YYYY").format("DD-MM-YYYY"), selectedDates)
                // dispatch(CorporateAction.handleStartAndEndDate(resAPIStartDate, resAPIEndDate, selectedDates));
            }
        } else {
            handleDatesData(resStartDate, resEndDate);
            dispatch(CorporateAction.handleStartAndEndDate(resAPIStartDate, resAPIEndDate));
        }
    };

    function useModalOutsideClose(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    handleData(event, true)
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    };


    return (
        <div className={`modal-wrapper ${showMyModal ? 'show' : 'hide'}`}>
            <div className='calendar-overlay'></div>
            <div className="card-box calender-modal animated_only_mobile animate__animated animate__fadeInUp animate_faster" ref={wrapperRef}>
                <div className="row">
                    <div className="col-md-8 order-12 order-md-1">
                        {hasDateError.hasError&&<p style={{color:'red'}}>{hasDateError.msg}</p>}
                        {isSpecific ? <p  className="schedule-date">In the month of {getSpecificDatesMonth()}</p> : null}
                        {isFlexible ? <p  className="schedule-date">Flexible Days</p> : null}
                        {isRecurring ? <p className="schedule-date">Every {recurringDaysList?.map((index) => {
                            switch(index){
                                case 0:
                                    return <span> Sun</span>
                                case 1:
                                    return <span>, Mon</span>
                                case 2:
                                    return <span>, Tue</span>
                                case 3:
                                    return <span>, Wed</span>
                                case 4:
                                    return <span>, Thu</span>
                                case 5:
                                    return <span>, Fri</span>
                                case 6:
                                    return <span>, Sat</span>
                            }
                        })}</p> : null}
                        { isPackage ? 
                        <p className="schedule-date"><span>
                            {(packageDays && packageDays !== "0") ? (packageDays && packageDays === "1") ? `${packageDays} day ` : `${packageDays} days ` : ""} 
                            {(packageNights && packageNights !== "0") ? (packageNights && packageNights === "1") ? `${packageNights} night` : `${packageNights} nights` : ""}
                        </span> 
                        </p>
                        :
                         null}
                        
                        <DateRangePicker
                            handleDates={isFlexible?handleFlexDates:handleStartAndEndDates}
                            isRecurring={isRecurring}
                            isExperience={isExperience}
                            isPackage={isPackage}
                            isSpecific={isSpecific}
                            recurringDaysList={recurringDaysList}
                            specificDaysList={specificDaysList}
                            isFlexible={isFlexible}
                            // maxDate={maxDate}
                            selectedDates={selectedDates}
                            prevStartDate={startDate ? startDate : null}
                            prevEndDate={endDate ? endDate : null}
                            packageDays={packageDays}
                            packageNights={packageNights}
                        />
                    </div>
                    {
                        ((isExperience && !isPackage) || isFlexible) ?
                            <div className="col-md-4 order-1 d-flex d-md-block justify-content-around justify-content-md-left">
                                <div className="row check-in">
                                    <p>{isFlexible?'Selected Dates':'Selected date'}</p>
                                    {isExperience&&isSpecific&&<h5>{resStartDate && resStartDate !== "Invalid date" ? moment(resStartDate, 'ddd-DD MMM YYYY').format('ddd-DD MMM') : null}</h5>}
                                    {isExperience&&isRecurring&&<h5>{resStartDate && resStartDate !== "Invalid date" ? moment(resStartDate, 'ddd-DD MMM YYYY').format('ddd-DD MMM') : null}</h5>}
                                    {isFlexible&&<div style={{maxHeight:220, overflowY:'scroll'}}>
                                        {
                                            selectedDates?.map((date)=>{
                                                return <h5>{moment(date, 'DD-MM-YYYY').format('ddd-DD MMM')}</h5>
                                            })
                                        }
                                    </div>}
                                </div>
                            </div> :
                            <div className="col-md-4 order-1 d-flex d-md-block justify-content-around justify-content-md-left">
                                <div className="row check-in">
                                   {myfamily ? <p>From date</p> : <p>Check-In</p>}
                                    <h5>{resStartDate && resStartDate !== "Invalid date" ? moment(resStartDate, 'ddd-DD MMM YYYY').format('ddd-DD MMM') : null}</h5>
                                </div>
                                <div className="row check-out">
                                {myfamily ? <p>To date</p> : <p>Check-Out</p>}
                                    <h5>{resEndDate && resEndDate !== "Invalid date" ? moment(resEndDate, 'ddd-DD MMM YYYY').format('ddd-DD MMM') : null}</h5>
                                </div>

                            </div>
                    }
                </div>
                <div className="row calender-footer-btn">
                    <CommonButton children="Done" onClick={handleData} />
                </div>
            </div>
        </div>
    )
}

export default CalenderModal