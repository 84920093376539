import React from "react";
import "../../assets/css/partials/home.scss";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  RECOGNITIONS,
  PARTNERS_LIST1,
  PARTNERS_LIST2,
  RECOGNITION2
} from "../../resources/recognitionsAndPartners";
var settings = {
  rows: 2,
  dots: false,
  arrows: true,
  infinite: false,
  speed: 300,
  slidesToShow: 6,
  slidesToScroll: 6,
};
function recognitionsAndPartners({fromAbout}) {
  return (
    <div>
      {!fromAbout&&
      <div className="recognitions-holder partners-holder">
        <h5>Our Recognitions & Memberships</h5>
        <div className="partners-wrapper" style={{paddingLeft : 0}}>
          <div className="d-flex justify-content-xl-center">
            {RECOGNITIONS.length > 0 &&
              RECOGNITIONS.map((item,idx) => (
                <div className="item" key={idx}>
                  <div className="card-wrapper">
                    <div className="img-holder">
                      <img src={item.image} alt={item.name} />
                    </div>
                    <div className="info">
                      <p className="mb-0">{item.name}</p>
                    </div>
                  </div>
                </div>
              ))}
            <div className="px-dummy pr-md-2"></div>
          </div>
        </div>
        <div className="partners-wrapper" style={{paddingLeft : 0}}>
          <div className="d-flex justify-content-xl-center">
            {RECOGNITION2.length > 0 &&
              RECOGNITION2.map((item,idx) => (
                <div className="item" key={idx}>
                  <div className="card-wrapper">
                    <div className="img-holder">
                      <img src={item.image} alt={item.name} />
                    </div>
                    <div className="info">
                      <p className="mb-0">{item.name}</p>
                    </div>
                  </div>
                </div>
              ))}
            <div className="px-dummy pr-md-2"></div>
          </div>
        </div>
      </div>}

      <div className="recognitions-holder partners-holder">
       <h5 className={fromAbout?"about-us-partners about-us-txt":""}>Our Partners</h5>
        <div className="partners-wrapper">
          <div className="d-flex list1">
            {PARTNERS_LIST1.length > 0 &&
              PARTNERS_LIST1.map((item,idx) => (
                <div className="card-wrapper" key={idx}>
                  <div className="img-holder">
                    <img src={item.image} alt={item.name} />
                  </div>
                  <div className="info">
                    <p className="mb-0">{item.name}</p>
                  </div>
                </div>
              ))}
            <div className="px-dummy pr-md-2"></div>
          </div>
          <div className="d-flex">
            {PARTNERS_LIST2.length > 0 &&
              PARTNERS_LIST2.map((item,idx) => (
                <div className="card-wrapper" key={idx}>
                  <div className="img-holder">
                    <img src={item.image} alt={item.name} />
                  </div>
                  <div className="info">
                    <p className="mb-0">{item.name}</p>
                  </div>
                </div>
              ))}
            <div className="px-dummy pr-md-2"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default recognitionsAndPartners;
