import React, { useState, useEffect, useLayoutEffect } from 'react';
// import '../../assets/css/partials/holidays/holidays.scss';
import { useSelector, useDispatch } from "react-redux";
import "../../assets/css/partials/travelInfo.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Visa from './visa/index';
import Customize from './customize/index';
import { useHistory, Link } from "react-router-dom";
import * as HolidaysAction from '../../redux/actions/holidaysActions';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import SearchLocationAndPriceRange from "../holidays/searchLocationAndPriceRange";
import SanitizedStayTag from '../common/sanitizedStayTag';
import SendGiftModule from './sendGift/index';
import LazyLoadingLoader from '../common/lazyLodaingLoader';
import { calculateDaysFromDateRange } from '../utilities/calculateDaysFromDateRange'
import { Helmet } from 'react-helmet';
import CategoryHolder from '../corporates/categoryHolder';

const owl_packages = {
    items: 1,
    margin: 5,
    nav: false,
    loop: true,
    dots: true,
    autoplay: true,
    smartSpeed: 500,
    animateIn: 'fadeIn',
    animateOut: 'fadeOut',
};

function Index(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const [state, setState] = useState(false);
    const internationalModule = localStorage.getItem("international") && JSON.parse(localStorage.getItem("international"))
    const [international, setInternational] = useState(internationalModule ? internationalModule : false);

    const sendGiftModule = localStorage.getItem("send_gift") && JSON.parse(localStorage.getItem("send_gift"))
    const [sendGift, setSendGift] = useState(sendGiftModule ? sendGiftModule : false);

    const HOLIDAYS_SECTIONS = useSelector((state) => state?.holidays?.holiday_categories?.data);
    const activesectionId = location?.pathname.split("/")[location?.pathname.split("/").length - 1]
    const scrollactiveHolCategory = localStorage.getItem("scrollactiveHolCategory")
    const ROUTE_VALUE = useSelector((state) => state?.holidays?.route_message);
    const HOLIDAYS_DATA = useSelector((state) => state?.holidays?.holiday_packages?.data);
    const HOLIDAY_PACKAGES = useSelector((state) => state?.holidays?.holiday_packages?.data?.listing);
    const HOLIDAY_PACKAGES_LIST = useSelector((state) => state?.holidays?.holiday_packages_list);
    const HOLIDAY_ID = useSelector((state) => state.holidays?.cat_id)
    const CATEGORY_ID = localStorage.getItem("holidayCategory") !== "undefined" ? JSON.parse(localStorage.getItem("holidayCategory")) : HOLIDAY_ID;
    const VISAS_MORE = localStorage.getItem("section_slug") == "visas-more" || props?.match?.params?.sectionid === 'visas-more';
    // const VISAS_MORE = useSelector((state) => state?.holidays?.visas_more)
    const CUSTOMIZE_HOLIDAY = props?.match?.params?.sectionid === 'customize' || localStorage.getItem("section_slug") == "customize";
    const userDetails = JSON.parse(localStorage.getItem("userData"));
    const sectionSlug = location?.pathname.split("/").length > 2 ? activesectionId : localStorage.getItem("section_slug") ? localStorage.getItem("section_slug") :
        HOLIDAYS_SECTIONS && HOLIDAYS_SECTIONS[0].section_slug
    const isCorporateAccount = userDetails?.user_details?.corporate_account;
    const NEXT_PAGE = HOLIDAY_PACKAGES?.next_page_id;
    const CURRENT_PAGE = HOLIDAY_PACKAGES?.current_page;
    const SEARCH_VALUE = useSelector((state) => state.holidays?.searchValue);
    const minVal = useSelector((state) => state.holidays?.filterValues?.minVal);
    const maxVal = useSelector((state) => state.holidays?.filterValues?.maxVal);
    const companyDomain = userDetails?.organisation?.email_domain;
    const isWipro = (companyDomain === "wipro.com");
    const [changeCategory, setChangeCategory] = useState(CATEGORY_ID);
    const [loadMoreBtn, setLoadMoreBtn] = useState(false);
    const [loader, setLoader] = useState(false)
    let [pages, setPages] = useState(1);
    const [searchBool, setSearchBool] = useState(false)
    const [isFirstRender, setIsFirstRender] = useState(history.location?.data?.bool)
    const routeFromHome = JSON.parse(localStorage.getItem("routeFromHome"))
    const [isBot, setIsBot] = useState(false);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const page = params.get('page');
    const [category, setCategory] = useState(null)
    useEffect(() => {
        let bots = ['googlebot', 'bingbot', 'yandex', 'baiduspider', 'facebookexternalhit', 'twitterbot', 'rogerbot', 'linkedinbot', 'embedly', 'quora link preview', 'showyoubot', 'outbrain', 'pinterest/0.', 'pinterestbot', 'slackbot', 'vkShare', 'W3C_Validator', 'whatsapp', 'redditbot', 'applebot', 'flipboard', 'tumblr', 'bitlybot', 'skypeuripreview', 'nuzzel', 'discordbot', 'google page speed', 'qwantify', 'bitrix link preview', 'xing-contenttabreceiver', 'chrome-lighthouse', 'telegrambot', 'prerender'];
        let checkbot = bots.filter((bot) => {
            return navigator.userAgent.toLowerCase().indexOf(bot) > -1
        });
        setIsBot(checkbot.length > 0)

    }, []);
    const handleCategories = (name, id, isFromClick, slug) => {
        dispatch(HolidaysAction.setCategoryId(id));
        handleCustomAndVisas(name);
        setChangeCategory(name);
        if (routeFromHome || isFromClick) {
            dispatch(HolidaysAction.getHolidayPackages(id, 1));
        }
        dispatch(HolidaysAction.setPageSize(1));
        dispatch(HolidaysAction.setFilterValues(""))
        dispatch(HolidaysAction.setSearchValue(""))
        setIsFirstRender(false)
        localStorage.setItem("section_slug", slug)
    }

    useLayoutEffect(() => {
        if (sectionSlug && HOLIDAYS_SECTIONS) {
            if (sectionSlug == "holidays") {
                document.querySelector(`#Holidays`)?.classList.add("active-category-card");
            } else {
                document.querySelector(`#Holidays`)?.classList.remove("active-category-card");
            }
            HOLIDAYS_SECTIONS?.map((item) => {
                if (item?.section_slug == sectionSlug) {
                    document.querySelector(`#${item.section_slug}`)?.classList.add("active-category-card");
                                } else {
                    document.querySelector(`#${item.section_slug}`)?.classList.remove("active-category-card");
                }
            })

        }
    }, [sectionSlug, HOLIDAYS_SECTIONS])
    const handleCustomAndVisas = (name) => {
        if (name == "Visas & More") {
            setState(true)
            dispatch(HolidaysAction.setVisasAndMore(true));
            dispatch(HolidaysAction.setCustomize(false));
            setInternational(false);
            setSendGift(false);
            dispatch(HolidaysAction.setRouteValue("Visas & More"));
        } else if (name == "International") {
            setState(false)
            dispatch(HolidaysAction.setVisasAndMore(false));
            dispatch(HolidaysAction.setCustomize(false));
            setInternational(true);
            setSendGift(false);
            dispatch(HolidaysAction.setRouteValue(""));

        } else if (name == "Customize") {
            setState(true)
            dispatch(HolidaysAction.setVisasAndMore(false));
            dispatch(HolidaysAction.setCustomize(true));
            setInternational(false);
            setSendGift(false);
            dispatch(HolidaysAction.setRouteValue(""));
        }
        else if (name == "Send a Gift") {
            setState(false)
            dispatch(HolidaysAction.setVisasAndMore(false));
            dispatch(HolidaysAction.setCustomize(false));
            setSendGift(true);
            dispatch(HolidaysAction.setRouteValue("Send a Gift"));
        }
        else {
            setState(false)
            dispatch(HolidaysAction.setVisasAndMore(false));
            dispatch(HolidaysAction.setCustomize(false));
            setInternational(false);
            setSendGift(false);
            dispatch(HolidaysAction.setRouteValue(""));
        }
    }

    // const goToLaunchingSoon = () => {
    //     history.push(`/launching-soon`);
    //     window.scrollTo(0, 0);
    // };

    // redirect to holiday details page
    const handleHolidayDetails = (id) => {
        //Commented to implement SEO Links
        // history.push(`/holidays/holiday-details/${id}`);
        localStorage.removeItem("filteredList")
        // window.scrollTo(0, 0);
    };

    const handleHolidayFilteredDetails = (id) => {
        //Commented to implement SEO Links
        // history.push(`/holidays/holiday-details/${id}`);
        localStorage.setItem("filteredList", true)
        // window.scrollTo(0, 0);
    };

    // redirect to international details page
    const handleInternational = (id) => {
        //Commented to implement SEO Links
        // history.push(`/holidays/international/${id}`);
        // window.scrollTo(0, 0);
    }

    useEffect(() => {
        dispatch(HolidaysAction.getHolidaySections());

        if (localStorage.getItem('prevUrl')?.indexOf('experiences') > -1) {
            HOLIDAYS_SECTIONS && HOLIDAYS_SECTIONS.forEach((item) => {
                if (props?.match?.params?.sectionid === item.section_slug) {
                    handleCategories(item.name, parseInt(item.id), true, item.section_slug);
                }
            })
            // dispatch(ExperienceAction.setRouteValue(""));
            dispatch(HolidaysAction.setFilterValues(""));
            dispatch(HolidaysAction.setSearchValue(""));
            setIsFirstRender(false)
        }
    }, [])

    useEffect(() => {
        HOLIDAYS_SECTIONS && HOLIDAYS_SECTIONS.forEach((item) => {
            if (ROUTE_VALUE === item.name) {
                handleCategories(item.name, parseInt(item.id), false, item.section_slug);

            }
            setTimeout(() => scrollToActiveSec())
        })
        return (() => {
            localStorage.setItem("routeFromHome", false)
        })
    }, [ROUTE_VALUE, HOLIDAYS_SECTIONS]);

    useEffect(() => {
        // handleCategories(item.name, parseInt(item.id), false, item.section_slug);

        let selcSec = HOLIDAYS_SECTIONS?.filter(sl => sl.section_slug === props?.match?.params?.sectionid);
        if (selcSec && selcSec.length && CATEGORY_ID != parseInt(selcSec[0].id)) {
            handleCategories(selcSec[0].name, parseInt(selcSec[0].id), true, selcSec[0].section_slug);
            // dispatch(HolidaysAction.setCategoryId(parseInt(selcSec[0].id)));  
        }
    }, [props?.match?.params?.sectionid]);

    useEffect(() => {
        if (HOLIDAYS_SECTIONS?.length && props?.match?.params?.sectionid) {
            let secId = HOLIDAYS_SECTIONS.filter(sl => sl.section_slug === props?.match?.params?.sectionid)[0]?.id;
            dispatch(HolidaysAction.setCategoryId(secId));
        }
        else if (HOLIDAYS_SECTIONS && HOLIDAYS_SECTIONS.length) {
            dispatch(HolidaysAction.setCategoryId(CATEGORY_ID ? CATEGORY_ID : HOLIDAYS_SECTIONS && HOLIDAYS_SECTIONS[0]?.id));
        }
    }, [HOLIDAYS_SECTIONS])

    useEffect(() => {
        // dispatch(HolidaysAction.getHolidaySections());
        dispatch(HolidaysAction.setVisasAndMore(false));
        dispatch(HolidaysAction.setCustomize(false));
        if (ROUTE_VALUE == "Visas & More") {
            setState(true)
            HOLIDAYS_SECTIONS && HOLIDAYS_SECTIONS.forEach((item) => {
                if (ROUTE_VALUE === item.name) {
                    dispatch(HolidaysAction.setCategoryId(item.id));
                }
            })
            dispatch(HolidaysAction.setVisasAndMore(true));
            dispatch(HolidaysAction.setCustomize(false));
            setInternational(false);
            setSendGift(false)
        }
        else if (ROUTE_VALUE == "International") {
            setState(false)
            dispatch(HolidaysAction.setVisasAndMore(false));
            dispatch(HolidaysAction.setCustomize(false));
            setInternational(true);
            setSendGift(false)
            HOLIDAYS_SECTIONS && HOLIDAYS_SECTIONS.forEach((item) => {
                if (ROUTE_VALUE === item.name) {
                    dispatch(HolidaysAction.setCategoryId(item.id));
                }
            })
        }
        else if (ROUTE_VALUE == "Bharat Dekho") {
            setState(false)
            dispatch(HolidaysAction.setVisasAndMore(false));
            dispatch(HolidaysAction.setCustomize(false));
            setInternational(false);
            setSendGift(false);
            localStorage.removeItem("customize");
            localStorage.removeItem("visas_more");
            localStorage.removeItem("send_gift");
            localStorage.removeItem("international");
            HOLIDAYS_SECTIONS && HOLIDAYS_SECTIONS.forEach((item) => {
                if (ROUTE_VALUE === item.name) {
                    dispatch(HolidaysAction.setCategoryId(item.id));
                }
            })
        }
        else {
            dispatch(HolidaysAction.setCategoryId(CATEGORY_ID ? CATEGORY_ID : HOLIDAYS_SECTIONS && HOLIDAYS_SECTIONS[0].id));
            dispatch(HolidaysAction.setRouteValue(""));
        }

    }, [HOLIDAY_PACKAGES]);

    useEffect(() => {
        if ((CURRENT_PAGE == undefined) && CATEGORY_ID !== null && CATEGORY_ID !== undefined && !isFirstRender) {
            dispatch(HolidaysAction.getHolidayPackages(CATEGORY_ID, page || 1));

        }
        if (!routeFromHome && isFirstRender && SEARCH_VALUE == "" && CATEGORY_ID !== null && CATEGORY_ID !== undefined) {
            dispatch(HolidaysAction.getHolidayPackages(CATEGORY_ID, page || 1));
        }
        mouseDrag();

    }, [CATEGORY_ID])
    useEffect(() => {
        if (category && category?.id !== CATEGORY_ID) {

            handleCategories(category.name, category.id, true, category.slug)
            history.push(`/holidays/${category.slug}`)
        }
    }, [category])
    useEffect(() => {
        if (HOLIDAYS_SECTIONS || HOLIDAYS_SECTIONS && scrollactiveHolCategory) {
            if (HOLIDAYS_SECTIONS) {
                HOLIDAYS_SECTIONS?.map((item, idx) => {
                    if (sectionSlug == item?.section_slug) {
                        let owl = window.$('.owl-carousel')
                        owl.owlCarousel();
                        owl.trigger('to.owl.carousel', idx);
                        setTimeout(() => localStorage.removeItem("scrollactiveHolCategory"), 500)
                    }
                })

            }
        }
    }, [HOLIDAYS_SECTIONS, scrollactiveHolCategory])
    useEffect(() => {
        if (history?.location?.fromViewMore?.bool) {
            dispatch(HolidaysAction.getHolidayPackages(CATEGORY_ID, 1));
            scrollToActiveSec()
        }
    }, [])
    const scrollToActiveSec = () => {
        let scrollContent = document.body.querySelector(`#filter-wrapper`);
        let activeLink = document.body.querySelector(`.active-category`);
        if (scrollContent && activeLink) {

            scrollContent.scrollLeft = activeLink.offsetLeft - 140
        }
    }
    const requiredUrl = () => {

        return HOLIDAYS_SECTIONS?.filter(sl => sl.id === CATEGORY_ID)[0]?.section_slug
    }
    const requiredTitle = () => {

        return HOLIDAYS_SECTIONS?.filter(sl => sl.id === CATEGORY_ID)[0]?.name
    }
    const requiredMetaDesc = () => {

        return HOLIDAYS_SECTIONS?.filter(sl => sl.id === CATEGORY_ID)[0]?.description
    }

    useEffect(() => {
        if (NEXT_PAGE !== null) {
            setLoadMoreBtn(true)
        } else {
            setLoadMoreBtn(false)
            setLoader(false)
        }
    }, [NEXT_PAGE])

    const refreshPackages = () => {

    }
    const handleSearch = () => {
        dispatch(HolidaysAction.setPageSize(pages));
        setSearchBool(!searchBool)
    }

    const mouseDrag = () => {
        const slider = document.querySelector('.draggable-wrapper');
        let mouseDown = false;
        let startX, scrollLeft;

        let startDragging = function (e) {
            mouseDown = true;
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        };
        let stopDragging = function (event) {
            mouseDown = false;
        };

        slider.addEventListener('mousemove', (e) => {
            e.preventDefault();
            if (!mouseDown) { return; }
            const x = e.pageX - slider.offsetLeft;
            const scroll = x - startX;
            slider.scrollLeft = scrollLeft - scroll;
        });

        // Add the event listeners
        slider.addEventListener('mousedown', startDragging, false);
        slider.addEventListener('mouseup', stopDragging, false);
        slider.addEventListener('mouseleave', stopDragging, false);
    }

    const fetchMorePackages = () => {
        setLoadMoreBtn(false)
        setLoader(true)
        if ((SEARCH_VALUE == undefined || SEARCH_VALUE == "") && (maxVal == undefined || maxVal == "")) {
            dispatch(HolidaysAction.getHolidayPackages(CATEGORY_ID, NEXT_PAGE));
            //   console.log("list without price and location filter")
        } else if ((SEARCH_VALUE == undefined || SEARCH_VALUE == "") && (maxVal !== undefined || maxVal !== "")) {
            dispatch(HolidaysAction.searchByLocation(CATEGORY_ID, SEARCH_VALUE, minVal, maxVal, NEXT_PAGE));
            //   console.log("filter with points only")
        } else if ((SEARCH_VALUE !== undefined || SEARCH_VALUE !== "") && (maxVal == undefined || maxVal == "")) {
            dispatch(HolidaysAction.searchByLocation(CATEGORY_ID, SEARCH_VALUE, minVal, maxVal, NEXT_PAGE));
            //   console.log("filter with location only")
        } else {
            dispatch(HolidaysAction.searchByLocation(CATEGORY_ID, SEARCH_VALUE, minVal, maxVal, NEXT_PAGE));
            //   console.log("filter with location and points")
        }

    }

    // const renderLocation = (location) => {
    //     if (location.length === 1) {

    //         return `${location[0].city},${location[0].state}`
    //     }

    //     else {

    //         return `${location[0].city},${location[1].address}`
    //     }
    // }

    return (
        <div>
            <Helmet>
                <title>{`${requiredTitle()}`} - Hammock Holidays</title>
                <meta name="description" content={`${requiredMetaDesc()}`} />
            </Helmet>
            {HOLIDAYS_SECTIONS &&
                <div className={`d-none ${HOLIDAYS_SECTIONS?.length > 5 ? 'd-lg-block' : ''}`}>
                    <CategoryHolder setCategory={setCategory} categoryList={HOLIDAYS_SECTIONS} />
                </div>
            }
            <div className={`filter-holder corporate-category-filter holiday-holder ${HOLIDAYS_SECTIONS?.length > 5 ? 'd-lg-none' : ''}`}>
                <div id="filter-wrapper" className={HOLIDAYS_SECTIONS && HOLIDAYS_SECTIONS.length <= 4 ? 'card-center card-wrapper draggable-wrapper' : 'filter-wrapper draggable-wrapper'}>
                    {
                        HOLIDAYS_SECTIONS && HOLIDAYS_SECTIONS.length > 0 &&
                        HOLIDAYS_SECTIONS.map((item, index) => (
                            <Link to={`/holidays/${item.section_slug}`} key={index}>
                                <div className={CATEGORY_ID == item?.id ? "filter-card active-category" : " filter-card"}
                                    onClick={CATEGORY_ID !== item?.id ? () => handleCategories(item?.name, item?.id, true, item.section_slug) : null} >
                                    <div className="img-gradient">
                                        <div className="cat-img">
                                            <img src={item?.image} alt={item?.name} />
                                            <div class="pseudo"></div>
                                        </div>
                                        <div className="card-info">
                                            <p >{item?.name}</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        ))
                    }

                </div>
            </div>
            {
                (VISAS_MORE || CUSTOMIZE_HOLIDAY || sendGift) ? null :
                    <div className="container">
                        {
                            (VISAS_MORE || CUSTOMIZE_HOLIDAY || sendGift) ? null :
                                <SearchLocationAndPriceRange
                                    changeCategory={changeCategory}
                                    fromHoliday={true}
                                    categoryID={CATEGORY_ID}
                                    handleSearch={handleSearch}
                                    refreshPackages={refreshPackages}
                                    isFirstRender={isFirstRender}
                                    secName={HOLIDAY_PACKAGES_LIST && HOLIDAY_PACKAGES_LIST[0]?.sec_name}
                                />
                        }
                        {
                            // international && <div class="line"></div>
                        }

                        <div className="">
                            {
                                HOLIDAY_PACKAGES_LIST?.length > 0 ?
                                    <div className="corporatePackages-holder">
                                        {HOLIDAY_PACKAGES_LIST && HOLIDAY_PACKAGES_LIST.length > 0 &&
                                            HOLIDAY_PACKAGES_LIST.map((packageInfo, index) => {

                                                return (
                                                    <Link to={{ pathname: `/holidays/holiday-details/${packageInfo.holiday_slug}`, state: { id: packageInfo?.id } }}>
                                                        <div style={{ cursor: "pointer" }}
                                                            className="package" key={packageInfo?.id}
                                                            onClick={() => handleHolidayDetails(packageInfo?.holiday_id)}
                                                        >

                                                            <div className="row overflow-hidden w-100-vw">
                                                                <div className="col-12 col-lg-5 pr-lg-0">
                                                                    {
                                                                        packageInfo?.image?.length === 1 ?
                                                                            <div className="package-image">
                                                                                <img
                                                                                    src={packageInfo?.image}
                                                                                    className="w-100"
                                                                                    alt={HOLIDAYS_DATA?.filter_data ? packageInfo?.pack_title : packageInfo?.title}
                                                                                />
                                                                            </div> :
                                                                            <OwlCarousel
                                                                                className="owl-theme owl-corporate"
                                                                                {...owl_packages} >
                                                                                {packageInfo.image.map((images,idx) => {
                                                                                    if (images) {
                                                                                        return (
                                                                                            <div className="item" key={idx}>
                                                                                                <div className="package-image">
                                                                                                    <img src={images} className="w-100" alt={`${HOLIDAYS_DATA?.filter_data ? packageInfo?.pack_title : packageInfo?.title} ${idx+1}`} />
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                })}
                                                                            </OwlCarousel>
                                                                    }
                                                                </div>
                                                                <div className="col-12 col-lg-7 pl-lg-0">
                                                                    <div className="package-details">
                                                                        <div className="d-flex" style={{ flexWrap: 'wrap' }}>
                                                                            {packageInfo &&
                                                                                packageInfo.covid_safe === true ?
                                                                                <><SanitizedStayTag /></> : null
                                                                            }
                                                                            <div className="bestseller-tag">
                                                                                {
                                                                                    packageInfo?.best_seller ? <span>BEST SELLER</span> : ''
                                                                                }
                                                                            </div>
                                                                        </div>

                                                                        <h4 className="">{HOLIDAYS_DATA?.filter_data ? packageInfo?.pack_title : packageInfo?.title}
                                                                            {

                                                                                packageInfo?.opening_soon !== null ? <span className="workshop-type text-center mb-0" style={{ textTransform: 'uppercase' }}>REOPENING ON {moment(packageInfo?.opening_soon).format('MMM, YYYY')}</span> : ''

                                                                            }

                                                                        </h4>

                                                                        <div className="info">
                                                                            {packageInfo?.sec_name === "International" ?
                                                                                <p>
                                                                                    {packageInfo.location_country ? packageInfo.location_country : ''}</p> :
                                                                                  <p>{packageInfo?.location?.length > 1 ? `${(packageInfo?.location_city || packageInfo?.location?.[0]?.city)}, ${packageInfo?.location?.[1]?.address}` :
                                                                                  `${(packageInfo?.location_city || packageInfo?.location?.[0]?.city)}${(packageInfo?.location?.[0]?.state?",":"")} ${(packageInfo?.location?.[0]?.state)}`
                                                                              }</p>
                                                                            }
                                                                            <div className="line"></div>
                                                                            {
                                                                                (packageInfo?.schedule_type !== "package") ? "" :
                                                                                    <p>
                                                                                        {packageInfo?.schedule_data?.days} {packageInfo?.schedule_data?.days == 1 ? 'Day' : 'Days'}  {packageInfo?.schedule_data?.nights} {
                                                                                            packageInfo?.schedule_data?.nights == 1 ? 'Night' : 'Nights'
                                                                                        }
                                                                                    </p>
                                                                            }
                                                                            {
                                                                                packageInfo?.schedule_type === "date_range" ?
                                                                                    <p>
                                                                                        {calculateDaysFromDateRange(packageInfo)}
                                                                                    </p> : ""
                                                                            }
                                                                            {
                                                                                packageInfo?.flexi_schedule ?
                                                                                    <p>
                                                                                        Flexible Days
                                                                                    </p> : ""
                                                                            }
                                                                            <p style={{ color: "#797979" }}>{packageInfo.brief_description}</p>
                                                                        </div>
                                                                        <div className="points">
                                                                            {
                                                                                (isCorporateAccount && isWipro) ? <p>Starting {(packageInfo.revised_price ? packageInfo.revised_price : packageInfo.price).toLocaleString('en-IN')} pts</p> : <p>Starting Rs. {(packageInfo.revised_price ? packageInfo.revised_price : packageInfo.price).toLocaleString('en-IN')}</p>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                );
                                            })}
                                        {loadMoreBtn || isBot ?
                                            <div className="text-center">
                                                {isBot ? <div className="d-flex justify-content-between">
                                                    {CURRENT_PAGE > 1 && <a href={`/holidays/${requiredUrl()}?page=${CURRENT_PAGE - 1}`}>
                                                        <button type="submit" className="load-more-btn" ><span>Prev page</span></button></a>}
                                                    {NEXT_PAGE && <a href={`/holidays/${requiredUrl()}?page=${NEXT_PAGE}`}>
                                                        <button type="submit" className="load-more-btn" ><span>Next page</span></button></a>}
                                                </div> : <button type="submit" class="load-more-btn" onClick={() => fetchMorePackages()}><span>Load more...</span></button>}
                                            </div> : loader ? <LazyLoadingLoader /> : null}
                                    </div> : (VISAS_MORE || CUSTOMIZE_HOLIDAY || sendGift) ? null : <p className="text-center">Currently no packages available for selected category</p>
                            }
                        </div>
                    </div>
            }
            <div className="">
                {
                    VISAS_MORE && <Visa />
                }
                {
                    CUSTOMIZE_HOLIDAY && <Customize />
                }
                {
                    // sendGift && 
                    // <div className="container mt-3 pt-lg-3">
                    //     <SendGiftModule />
                    // </div>
                }

            </div>

        </div>
    )
}

export default Index;

