import React, { useEffect, useRef, useState } from 'react'
import CareersTop from '../../../assets/images/home/Converted/careerstopbanner.webp'
import backIcon from "../../../assets/images/common/back-icon.svg";
import Logo from "../../../assets/images/common/logo.svg";
import CloseIcon from "../../../assets/images/common/close-icon.svg";
import closeIcon from '../../../assets/images/common/close-modal.svg';
import * as ProfileAction from "../../../redux/actions/profileAction";
import { jobData } from './jobData';
import { useLocation, useHistory } from "react-router-dom";
import Footer from '../footer';
import { Form } from 'react-bootstrap';
import { testEmail } from '../../../utils/common';
import Header from '../header';
import useClickOutside from '../../../hooks/useClickOutside';
import { Dialog, DialogTitle } from "@material-ui/core";
import SuccessfulLogo from "../../../assets/icons/authIcons/successfulLogo.png";
import { axiosMediaUpload } from '../../../services/apiServices';
import { resumeUpload } from '../../../services/coreApiServices';
import { useDispatch } from 'react-redux';
const JobDiscription = () => {
  const [jobDetails, setJobDetails] = useState([])
  const [fileName, setFileName] = useState("Upload Resume/CV");
  const [responsemsg, setresponsemsg] = useState(null)
  const [showForm, setShowForm] = useState(false)
  const [resumeURL, setResumeURL] = useState(null)
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const [state, setState] = useState({
    name: "",
    email: "",
    mobile: "",
    resume: "",
    note: "",
  });

  const [error, setError] = useState({
    name: false,
    email: false,
    mobile: false,
    resume: false,
    invalidResume:false,
    note: false,
  })
  useEffect(() => {
    if (state.resume) {
      console.log(state.resume)
      uploadResume(state.resume)
    }
  }, [state.resume])
  useEffect(() => {
    if (resumeURL) {

    }
  }, [resumeURL])

  useEffect(() => {
    if (responsemsg) {
      closeForm()
      setTimeout(() => { setresponsemsg(null) }, 1000)
    }
  }, [responsemsg])
  const clearResume=()=>{
    setFileName("Upload Resume/CV")
    setResumeURL(null)
  }
  const uploadResume = (resume) => {

    if(resume){
      if(resume.name.split(".")[1]=="pdf"||resume.name.split(".")[1]=="doc"||resume.name.split(".")[1]=="docx"){
    dispatch(
      ProfileAction.uploadCareersResume("document", resume, setResumeURL)
    );
    setFileName(resume.name)
    setError({ invalidResume: false })
    }else{
      setError({ invalidResume: true })
    }
  }
  };
  const popupRef = useRef();
  useClickOutside(popupRef, () => {
    if (showForm) {
      closeForm()
    }
  });
  const handleChange = (key, value) => {
    if (key === "name") {
      const re = /^[A-Za-z ]+$/;
      if (value === '' || re.test(value)) {
        setState(() => ({
          ...state,
          [key]: value,
        }));
      }
    }
    else {
      setState(() => ({
        ...state,
        [key]: value,
      }));

    }
    setError({
      ...error,
      [key]: false,
    });
  }
  const closeForm = () => {
    setShowForm(false)
    setResumeURL(null)
    setFileName("Upload Resume/CV")
    setState({
      name: "",
      email: "",
      mobile: "",
      resume: "",
      note: "",
    })
    setError({
      name: false,
      email: false,
      mobile: false,
      resume: false,
      invalidResume:false,
      note: false,
    })
  }
  const submitHandler = (e) => {
    e.preventDefault();
    if (!state.name || state.name === "") {
      setError({
        name: true
      });
    } else if (!testEmail(state.email
    )) {
      setError({ email: true });
    } else if (state.mobile==""||state.mobile.length!==10) {
      setError({ mobile: true });
    } else if (!resumeURL) {
      setError({ resume: true })
    }
    else if(state.note.length>250){
      setError({ note: true })
    }
    else {
      const data = {
        name: state.name,
        email: state.email,
        mobile_no: state.mobile,
        resume_url: resumeURL,
        role_id: jobDetails.id,
        note: state.note
      };

      if (data) {
        dispatch(ProfileAction.careersRequest(data, setresponsemsg))
      }
    }
  };
  useEffect(() => {
    if (location?.state) {
      jobData?.map(e => {
        if (e.name == location?.state) {
          setJobDetails(e)
        }
      })
    }
  }, [location?.state])
  useEffect(() => {
    window.scrollTo(0, 0)

  }, [])
  useEffect(() => {
    if (showForm) {
      document.querySelector("html").style.overflow = "hidden";
    } else {
      document.querySelector("html").style.overflow = "scroll";
    }
    return (() => { document.querySelector("html").style.overflow = "scroll"; })
  }, [showForm]);
  return (
    <div >
      <div className=" d-md-none">
        <Header />
      </div>
      <div className='career-logo d-flex justify-content-between'>
        <img src={Logo} alt='logo' />
        <img onClick={() => history.goBack()} className='close-icon d-none d-md-block' alt='close-icon' src={CloseIcon} />
      </div>
      <div className='banner-holder job-banner careers-banner-holder'>
        <div className={`banner`}>
          <img src={CareersTop} className="img-fluid" alt="CareersTop" />
        </div>
        <div className='text-holder'>
          <p className='main-title'>Careers</p>
          <p className='sub-title'>Join our Journey</p>
        </div>

      </div>
      <div className='container'>
        <div className="job-details">
          <div className='job-title d-flex'>
            <div
              onClick={() => history.goBack()}
              className={`back-button back-button-mobi careers-back `}
            >
              <img src={backIcon} alt="backIcon" />
            </div>
            <p className='gradient-text'>
              {jobDetails?.name}
            </p>
          </div>
          <div className='job-discription'>
            <div className='about'>
              <h2>About us:</h2>
              <p>Hammock Leisure Holidays Pvt. Ltd. was set up in 1996, with our Headquarters in Bangalore. We are primarily holiday planners and consultants who specialize in tailor-making Domestic & International holidays to suit individual preferences, budgets, and time. We also offer special interest tours such as Culinary tours, Women- Only Holidays, Spiritual Tourism, Driving Holidays, School Outings/Field trips, Village Tourism, and many more.</p>

            </div>
            <div className='about mt-4'>
              <h2>What will you be doing:</h2>
              <ul>
                {
                  jobDetails?.responsibilities?.map((e, i) => (
                    <li key={i}>{e}</li>
                  ))
                }
              </ul>
            </div>
            <div className='about mt-4'>
              <h2>Skills that we would value in you:</h2>
              <ul>
                {
                  jobDetails?.skills?.map((e, i) => (
                    <li key={i}>{e}</li>
                  ))
                }
              </ul>
            </div>
            <div className='about mt-4'>
              <h2>Qualifications:</h2>
              <p className='qualification'>{jobDetails?.qualification}</p>

            </div>
            <div className='d-flex justify-content-center align-items-center mt-4'>
              <button className='apply-now' onClick={() => setShowForm(true)}>Apply Now</button>
            </div>

            <div className={`apply-model ${showForm ? "d-flex" : "d-none"}`}>
              <div className='d-flex flex-column job-apply-form' ref={popupRef}>
                <div className='d-flex justify-content-end'><img className='carees-model-close' alt='close' onClick={closeForm} src={closeIcon} /></div>
                <h5>Your Journey starts here</h5>
                <div className='title-line'></div>
                <div className='careers-input-group d-flex flex-column'>
                  <input
                    value={state.name}
                    onChange={(value) => handleChange("name", value.target.value)}
                    placeholder='Name' />
                  <div className='form-line'></div>
                  <label>Your name</label>
                  {error.name === true ? (
                    <p className="errorMsg mob-error">Your name is required</p>
                  ) : null}
                </div>
                <div className='careers-input-group d-flex flex-column'>
                  <input value={state.email}
                    onChange={(value) => handleChange("email", value.target.value)}
                    placeholder='Email Id' />
                  <div className='form-line'></div>
                  <label>Your email</label>
                  {error.email === true ? (
                    <p className="errorMsg mob-error">Enter valid email</p>
                  ) : null}
                </div>
                <div className='careers-input-group d-flex flex-column'>
                  <input onKeyPress={(event) => {
                    if (!/[0-9-+]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }} value={state.mobile} onChange={(value) => handleChange("mobile", value.target.value)} placeholder='Phone Number' />
                  <div className='form-line'></div>
                  <label>Your Contact number</label>
                  {error.mobile === true ? (
                    <p className="errorMsg mob-error">Enter valid phone no</p>
                  ) : null}
                </div>
                <div className='form-file d-flex'>
                <div className='d-flex file-name-wrapper'><p className='filename'>{fileName}</p><img className={`carees-model-close ${resumeURL?'':'d-none'}`} onClick={clearResume} src={closeIcon} alt='close' /></div>
                  <Form.File
                    accept=".pdf,.doc,.docx"
                    type="file"
                    id="inputGroupFile01"
                    label={<div className='d-flex d-none'></div>}
                    value={state.resume}
                    onChange={(value) => uploadResume(value.target.files[0])}
                    custom
                  />

                </div>
                {error.resume === true ? (
                  <p className="errorMsg mob-error ml-4 pt-2">Upload Your Resume</p>
                ) :error.invalidResume===true?<p className="errorMsg mob-error">Upload Your Resume In .pdf or .doc or .docx format</p>: null}
                <div className='careers-input-group d-flex flex-column add-note'>
                  <textarea value={state.note} onChange={(value) => handleChange("note", value.target.value)} placeholder='Add a note...' />  
                </div>
                {error.note === true ? (
                    <p className="errorMsg mob-error mb-2">Please Enter Below 250 Characters</p>
                  ) : null}
                <button className='careers-submit' onClick={submitHandler}>Submit</button>

              </div>


            </div>

          </div>
        </div>
      </div>
      {responsemsg && (
        <div

        >
          <div className="login-success-popup">
            <Dialog
              open={true}
              maxWidth="xs"
              fullWidth={true}

            >
              <DialogTitle>
                <div className="success-holder" style={{}}>
                  <div>
                    <img src={SuccessfulLogo} alt="successful" />
                  </div>
                  <div className="success-message">
                    <p>{responsemsg}</p>
                  </div>
                </div>
              </DialogTitle>
            </Dialog>
          </div>
        </div>
      )}
      <Footer />
    </div>
  )
}

export default JobDiscription