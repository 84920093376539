import React, { useEffect, useState } from "react";
import "../../assets/css/partials/sliders.scss";
import OwlCarousel from "react-owl-carousel";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import experience1 from "../../assets/images/home/experience1.png";
import experience2 from "../../assets/images/home/experience2.png";
import experience3 from "../../assets/images/home/experience3.png";
import {options} from '../../resources/carouselOptions';
import { useSnackbar } from "notistack";
import * as homeAction from "../../redux/actions/homeAction";
import * as HolidaysAction from "../../redux/actions/holidaysActions";


function ExperiencesSlider() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loader, setLoader] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const EXPERIENCE_LIST = useSelector(
    (state) => state.homepage.experienceList.data
  );

  const handleRoute = (route) => {
    // history.push(`/${route}`);
    history.push({pathname: `/${route}`, seeAll: {bool: true}});
    dispatch(HolidaysAction.setFilterValues(""))
    dispatch(HolidaysAction.setSearchValue(""))
    window.scroll(0,0)
  };

  useEffect(() => {
    dispatch(homeAction.experiencePackages(handleLoader));
  }, []);

  const handleLoader = () => {
    setLoader(false);
  };

  const goToPackageDetails = (id) => {
    history.push(`/experiences/experience-details/${id}`);
    window.scrollTo(0, 0);
  };

  return (
    <div className="slider-holder">
    <div className="container">
       <h4>
         <span>Experience</span> something new
       </h4>
    </div>
    {!loader ? (
      <div className="carousel-wrapper carousel-mobi-wrapper">
        <OwlCarousel className="owl-theme" {...options}>
          {EXPERIENCE_LIST &&
            EXPERIENCE_LIST.length > 0 &&
            EXPERIENCE_LIST.map((item,idx) => (
              <Link to={{pathname:`/experiences/experience-details/${item.experience_slug}`,state: { id: item?.id}}} key={idx} >
              <div className="item cursor-pointer" onClick={() => localStorage.removeItem("filteredList")}>
                <div className="card-wrapper" >
                  <img src={item.image} alt={item.pack_title} />
                  <div className="info">
                    <p>{item.pack_title}</p>
                   { 
                    //  <p>{item.points} pts</p>
                    }
                  </div>
                </div>
              </div>
              </Link>
            ))}
            {
              EXPERIENCE_LIST?.length > 3 ? 
              <div className="item">
              <div
                className="card-wrapper see-all"
                onClick={() => handleRoute("experiences")} >
                <p>  See all <br />
                Experiences </p>
              </div>
            </div> : null
            }
         
        </OwlCarousel>
      </div>
    ) : null}
   </div>

  )
}

export default ExperiencesSlider
