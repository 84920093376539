import {
  ERROR_ALERT,
  INFO_ALERT,
  SUCCESS_ALERT,
  WARNING_ALERT,
  REQUIRED_FIELDS,
  SHOW_NAV,
  SET_VIDEO
} from "../actions/commonAction";
import {ALL_LOCATIONS_LIST, ALL_COUNTRY_LIST, CORP_COUNTRY_LIST} from "../actions/types";

const initialState = {
  fields: [],
  all_locations_list:[],
  all_country_list:[],
  corp_country_list:[],
  showNav:false,
  video: {}


};

const commonReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SUCCESS_ALERT:
      return {
        ...state,
        alertType: "success",
        alertMessage: payload,
      };
    case ERROR_ALERT:
      return {
        ...state,
        alertType: "error",
        alertMessage: payload,
      };
    case WARNING_ALERT:
      return {
        ...state,
        alertType: "warning",
        alertMessage: payload,
      };
    case INFO_ALERT:
      return {
        ...state,
        alertType: "info",
        alertMessage: payload,
      };
    case ALL_LOCATIONS_LIST:
      return {
          ...state,
          all_locations_list: payload
      };
    case ALL_COUNTRY_LIST:
      return {
          ...state,
          all_country_list: payload
      };
    case CORP_COUNTRY_LIST:
      return {
          ...state,
          corp_country_list: payload
      };
    case SHOW_NAV:
      return {
        ...state,
        showNav:payload
      };
    case SET_VIDEO:
          return {
              ...state,
              video: payload
          } 
    default:
      return {
        ...state,
        alertType: null,
        alertMessage: null,
      };
  }
};

export default commonReducer;
