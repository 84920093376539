import React, { useState } from "react";
import "../../assets/css/partials/corporates/corporates.scss";
import Dialog from '@material-ui/core/Dialog';
import closeIcon from '../../assets/images/common/close-modal.svg';
import moment from 'moment';
import parse from 'html-react-parser';


function TestimonialPopup({ openModal , item}) {
  const [state, setState] = useState({
    
  });

  const handleClose = () => {
    openModal(false);
  };
  

  return (
    <div>
      <Dialog
        open={openModal}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxWidth: '830px',
            width: '800px'
          },
        }}
      >
        <div className="login-holder employerForm-holder">
            <div className="testimonial-popup">
                <div className="header">
                    <div className="profile">
                    {item.guest_image !== "" ?
                                    <img src={item.guest_image} alt={item.guest_name} />
                                    : <div className="nav-proile-icon" >
                                        <p>{item.guest_name !== undefined && item.guest_name?.charAt(0).toUpperCase()}</p>
                                    </div>}
                        <p className="guest_name">{item.guest_name} <span>{moment(item?.modified, 'YYYY-MM-DD').format('MMMM, YYYY')}</span></p>
                        
                    </div>
                </div>
            <span className="closeIcon" onClick={handleClose}><img alt='close' src={closeIcon} className="close-icon" /></span>
            <div class="line"></div>
            <div className="content">
                {/* <p>{item.message}</p> */}
                <p >{parse(item.message)}</p>
            </div>
            </div>
        </div>
      </Dialog>
    </div>
  );
}

export default TestimonialPopup;
