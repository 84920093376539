import React, { useEffect, useState } from "react";
import "../../assets/css/partials/home.scss";
import { useDispatch, useSelector } from "react-redux";
import ExperiencesSlider from "./experiencesSlider";
import CorporateSlider from "./corporateSlider";
import HolidaySlider from "./holidaySlider";
import TravelInfo from "./travelInfo";
import RecognitionsAndPartners from "./recognitionsAndPartners";
import category1 from "../../assets/images/home/rewards.webp";
import category2 from "../../assets/images/home/Deals.webp";
import category3 from "../../assets/images/home/customize.webp";
import category4 from "../../assets/images/home/bharath.webp";
import category5 from "../../assets/images/home/international.webp";
import category6 from "../../assets/images/home/visas.webp";
import category7 from "../../assets/images/home/funOuter.webp";
import holidaytxt from "../../assets/images/home/holidaytxt.png";
import funtxt from "../../assets/images/home/Fun.png";
import * as HolidaysAction from '../../redux/actions/holidaysActions';
import * as CorporateAction from '../../redux/actions/corporatesAuthActions';
import * as ExperenceAction from '../../redux/actions/experiencesAction';
import { useHistory, Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ScrollToTop } from "../common/Scroll";
import Subscribe from "../common/subscribe";
import moment from "moment";

function Home() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const internationalModule = localStorage.getItem("international") && JSON.parse(localStorage.getItem("international"))
    const [international, setInternational] = useState(internationalModule ? internationalModule : false);
  const EXPERIENCE_SECTIONS = useSelector((state) => state?.experiences?.experience_categories?.data);
  useEffect(()=>{
    if(location.logoClicked){
      setTimeout(()=>{
        window.scrollTo(0,0);
      })
    }
  },[]);
  const handleRoute = (route) => {
    if(route == "experiences"){
      dispatch(ExperenceAction.setRouteValue(""));
    }
    dispatch(CorporateAction.getSearchValue(""))
    dispatch(CorporateAction.getFilterValues(""))
    dispatch(HolidaysAction.setSearchValue(""))
    dispatch(HolidaysAction.setFilterValues(""))
    history.push({ pathname: `/${route}`, data: {bool: true}});
    // history.push(`/${route}`);
    // window.scrollTo(0, 0);
  };

  const handleLaunchingSoonRoute = (route, message) => {
    history.push(`/${route}`, message);
    // window.scrollTo(0, 0);
  };

  const handleConditionalRoute = (route, message) => {
    dispatch(HolidaysAction.setRouteValue(message));
    dispatch(CorporateAction.getSearchValue(""))
    dispatch(CorporateAction.getFilterValues(""))
    dispatch(HolidaysAction.setSearchValue(""))
    dispatch(HolidaysAction.setFilterValues(""))
    if(message === "Visas & More"){
      dispatch(HolidaysAction.setVisasAndMore(true));
      setInternational(false);
      dispatch(HolidaysAction.setCustomize(false));
      dispatch(HolidaysAction.setRouteValue("Visas & More"));
    }
    // else if(message === "International"){
    //   localStorage.setItem("international", true);
    //   localStorage.removeItem("visas_more");
    //   localStorage.removeItem("customize");
    // }
    localStorage.setItem("routeFromHome", true)
    history.push(`/${route}`);
  };
  const handleConditionalRouteExp = (route, message) => {
    dispatch(ExperenceAction.setRouteValue(message));
    history.push(`/${route}`);
  };
  const EXP_IN_YEARS = moment().diff('1996-07-09', 'years')
  useEffect(() => {
    document.body.style.overflowX = 'hidden';
  }, [])
//   const requiredUrl = () => {
//     return EXPERIENCE_SECTIONS?.filter(sl=>sl.id===22)[0]?.section_slug
// }


  return (
    <div>
      <Helmet>
        <title>Hammock Holidays - Holidays & Gifts, Virtual Events, Experiences, Holidays, Corporate deals, My Family First</title>
        <meta name="description" content={`${EXP_IN_YEARS}yrs of creating Magical Experiences - Holidays & Gifts . Virtual Events . Corporate deals . and a lot more`} />
      </Helmet>
      <div className="container desktop">
        <div className="row">
          <div className="col-12 col-lg-5">
            {/* <Link to="/corporates"> */}
            <div
              className="category-image cursor-pointer"
              onClick={() => handleRoute("corporates")}
            >
              <img src={category1} className="img-fluid w-100 cat1" alt="Reward Your Employees" width="100%" title="Reward Your Employees" />
              <div class="category-txt cat1-txt w-auto d-flex align-items-center flex-column">
                <p>Reward</p> 
                  <span>Your Employees </span>
              </div>
            </div>
            {/* </Link> */}
          </div>
          <div className="col-12 col-lg-7 pl-2">
            <div className="card-columns">
              <div className="card category-image cursor-pointer" onClick={() => handleRoute("experiences")}>
                <img className="card-img cat2" src={category2} alt="Deals" width='100%' />
                <div class="category-txt cat2-txt">
                  <p>Deals</p>
                </div>
              </div>
              {
                  <Link to={`/holidays/international`}>
              <div className="card category-image cursor-pointer" 
              // onClick={() => handleConditionalRouteExp("experiences", "Experiential Stays")}
              >
                <img className="card-img cat5" src={category5} alt="Fun Outdoor Activities" width='100%' />
                <div class="category-txt cat5-txt d-flex align-items-center flex-column">
                  <img src={holidaytxt}/>
                </div>
              </div>
              </Link> 
              }
              
              <div className="card category-image cursor-pointer" onClick={() => handleRoute("my-family-first")}>
                <img className="card-img cat3" src={category3} alt="Customize My Holiday" width='100%' />
                <div class=" category-txt cat3-txt d-flex align-items-start flex-column">
                  <span>Customize</span>
                  <p>My Holiday</p>
                </div>
              </div>
              <Link to={`/holidays/visas-more`}>
              <div className="card category-image cursor-pointer" 
              // onClick={() => handleConditionalRoute("holidays", "Visas & More")}
              >
                <img className="card-img cat6" src={category6} alt="Visas & More" width='100%' />
                <div class="d-flex category-txt cat6-txt align-items-start flex-column">
                  <p>Visa</p>
                  <span>& More</span>
                </div>
              </div>
              </Link>
              <Link to={`/holidays/bharat-dekho`}>
              <div className="card category-image cursor-pointer" 
              // onClick={() => handleConditionalRoute("holidays", "Bharat Dekho")}
              >
                <img className="card-img cat4" src={category4} alt="Bharat Dekho" width='100%' />
                <div class="d-flex category-txt cat4-txt align-items-center flex-column">
                  <p>Bharat</p>
                  <p>Dekho</p>
                </div>
              </div>
              </Link>
              <Link to={`/experiences/experiential-stays`}>
              <div className="card category-image cursor-pointer" 
              // onClick={() => handleConditionalRoute("holidays", "International")}
              >
                <img className="card-img cat7" src={category7} alt="International Holidays" width='100%' />
                <div class=" d-flex category-txt cat7-txt align-items-start flex-column">
                  <img src={funtxt}/>
                  <div className="fun-txt">
                  <p>Outdoor</p>
                  <p>Activities</p>
                  </div>
                </div>
              </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      
      {/* For Mobile view only STARTS*/}
      <div className="mobile mb-5 pb-2">
        <div className="rows">


          <div className="card-wrappers">
            <div className="cards" onClick={() => handleRoute("corporates")}>
              <img src={category1} className="img-fluid card-img cat1" alt="Reward Your Employees" width='100%' />
              <div class="category-txt cat1-txt w-auto d-flex align-items-center flex-column">
                <p>Reward</p> 
                  <span>Your Employees </span>
              </div>
            </div>
            <div className="cards" onClick={() => handleRoute("experiences")}>
              <img className="card-img cat2" src={category2} alt="Deals" width='100%' />
              <div class="category-txt cat2-txt">
                  <p>Deals</p>
                </div>
            </div>
            <Link to={`/holidays/international`}>
            <div className="cards" 
            // onClick={() => handleConditionalRouteExp("experiences", "Experiential Stays")}
            >
              <img className="card-img cat5" src={category5} alt="Fun Outdoor Activities"  width='100%'/>
              <div class="category-txt cat5-txt d-flex align-items-center flex-column">
                  <img src={holidaytxt}/>
                </div>
           
            </div>
            </Link>
            <div className="cards" onClick={() => handleRoute("my-family-first")}>
              <img className="card-img cat3" src={category3} alt="Customize My Holiday" width='100%' />
              <div class=" category-txt cat3-txt d-flex align-items-start flex-column">
                  <span>Customize</span>
                  <p>My Holiday</p>
                </div>
            </div>
            <Link to={`/holidays/visas-more`}>
            <div className="cards" onClick={() => handleConditionalRoute("holidays", "Visas & More")}>
              <img className="card-img cat6" src={category6} alt="Visas & More" width='100%' />
              <div class="d-flex category-txt cat6-txt align-items-start flex-column">
                  <p>Visa</p>
                  <span>& More</span>
                </div>
            </div>
            </Link>
            <Link to={`/holidays/bharat-dekho`}>
            <div className="cards" onClick={() => handleConditionalRoute("holidays", "Bharat Dekho")}>
              <img className="card-img cat4" src={category4} alt="Bharat Dekh" width='100%' />
              <div class="d-flex category-txt cat4-txt align-items-center flex-column">
                  <p>Bharat</p>
                  <p>Dekho</p>
                </div>
            </div>
            </Link>
       
            <Link to={`/experiences/experiential-stays`}>
            <div className="cards" onClick={() => handleConditionalRoute("holidays", "International")}>
              <img className="card-img cat7" src={category7} alt="International Holiday" width='100%' />
              <div class=" d-flex category-txt cat7-txt align-items-start flex-column">
                  <img src={funtxt}/>
                  <div className="fun-txt">
                  <p>Outdoor</p>
                  <p>Activities</p>
                  </div>
                </div>
            </div>
            </Link>
          </div>

        </div>
      </div>
      {/* For Mobile view only ENDS */}


      <ExperiencesSlider />
      <CorporateSlider />
      <HolidaySlider />
      <TravelInfo />
      <Subscribe />
      <RecognitionsAndPartners />
    </div>
  );
}

export default Home;
