import React, { useEffect } from 'react';
import successIcon from '../../../../assets/images/common/success.svg';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// import * as EventAction from '../../../redux/actions/eventsAction';

function PaymentSuccess() {
    const purchaseDetails = useSelector(state => state?.holidays?.booking_details?.data);
    const dispatch = useDispatch()
    const history = useHistory();

    // useEffect(() => {
    //     dispatch(EventAction.checkOut())
    // }, [])

    const goToHome = () => {
        history.push(`/`);
    }

    return (
        <div>
            <div className="payment-status">
                <div className="text-center">
                    <img src={successIcon} alt="" />
                </div>
                <div className="payment-details text-center">
                    <p className="status">Succesfully Paid {purchaseDetails?.total_points} pts</p>
                    <p className="booking-for">Gift Voucher Purchased for <span>{purchaseDetails?.pack_title}</span>  </p>
                    <p className="transactionId">Transaction ID : {purchaseDetails?.txnid}</p>
                    {
                        purchaseDetails?.booking_status === "booked" ? <p className="transactionId">Status: Success</p> : null

                    }
                    <p className="message">We will connect with you shortly</p>
                    <div class="goto-home">
                        <button className="cursor-pointer btn-goto-home" onClick={goToHome}>Go To Home</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentSuccess
