import { API, SUBSCRIBE_KEY } from "../../config/api";
import { axiosGet,axiosPostWithHeader } from "../../services/apiServices";
import { CORPORATE_HOMEPAGE_LIST, EXPERIENCE_HOMEPAGE_LIST, HOLIDAY_HOMEPAGE_LIST, SET_SCROLL_CORPORATES, SET_SCROLL_VIX, SEARCH_SUGGESTIONS, SET_GLOBAL_SEARCH_VAL, GLOBAL_SEARCH_SUGGESTIONS } from "./types";

export const corporateHomepagePackages = (loader) => {
  return async (dispatch, getState) => {
    axiosGet(`${API.CORPORATE_FEATURED_LIST}`)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: CORPORATE_HOMEPAGE_LIST,
            payload: res.data,
          });
          loader();
        }
      })
      .catch((err) => {
        loader();
        console.log(err);
      });
  };
};

export const experiencePackages = (loader) => {
  return async (dispatch, getState) => {
    axiosGet(`${API.EXPERIENCE_FEATURED_LIST}`)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: EXPERIENCE_HOMEPAGE_LIST,
            payload: res.data,
          });
          loader();
        }
      })
      .catch((err) => {
        loader();
        console.log(err);
      });
  };
};

export const holidayPackages = (loader) => {
  return async (dispatch, getState) => {
    axiosGet(`${API.HOLIDAY_FEATURED_LIST}`)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: HOLIDAY_HOMEPAGE_LIST,
            payload: res.data,
          });
          loader();
        }
      })
      .catch((err) => {
        loader();
        console.log(err);
      });
  };
};
export const getSearchSuggestions = (value, isCorp) => {
  return async (dispatch, getState) => {
    axiosGet(`${API.GLOBAL_SEARCH_API}?search=${value}&corp=${isCorp}`)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: SEARCH_SUGGESTIONS,
            payload: res.data.data,
          });
          localStorage.setItem("searchResults", JSON.stringify(res.data.data))
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getGlobalSearchResults = (value, isCorp) => {
  return async (dispatch, getState) => {
    axiosGet(`${API.GLOBAL_SEARCH_API}?search=${value}&corp=${isCorp}`)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: GLOBAL_SEARCH_SUGGESTIONS,
            payload: res.data.data,
          });
          localStorage.setItem("searchResults", JSON.stringify(res.data.data))
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};



export const setScroll = (value) => {
  return async (dispatch, getState) => {
    console.log("value", value)
    dispatch({
      type: SET_SCROLL_CORPORATES,
      payload:value
    })
  };
}

export const setScrollVix = (value) => {
  return async (dispatch, getState) => {
    console.log("value", value)
    dispatch({
      type: SET_SCROLL_VIX,
      payload:value
    })
  };
}

export const setGlobalSearchVal = (value) => {
  return async (dispatch, getState) => {
    localStorage.setItem("globalSearchVal", value)
    dispatch({
      type: SET_GLOBAL_SEARCH_VAL,
      payload:value
    })
  };
}

export const newsLetter = (body, handleSuccess, handleErrors) => {
  
  return async () => {
    axiosPostWithHeader(API.SUBSCRIBE_US, body, SUBSCRIBE_KEY)
          .then((res) => {
              if (res.data.status_code === 200) {
                  handleSuccess(res.data.data.message);
                  
              }
              if (res.data.status_code === 201) {
                  handleErrors(res.data.data.message);
                  
              }
              if (res.data.status_code === 400) {
                  handleErrors(res.data.data.message)
              }
          })
          .catch((err) => {
              handleErrors(err.response.data?.data?.message);
          });
  };
};