import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import OtpComponent from "../../components/auth/otpComponent";
import SocialLogin from "../../components/common/socialLogin";
import InputComponent from "../common/inputComponent";
import CommonButton from "../common/authCommonButton";
import { useHistory, useLocation } from "react-router-dom";
import { testEmail } from "../../utils/common";
import exclamationMark from '../../assets/icons/authIcons/confirmation-icon.png';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as ProfileAction from "../../redux/actions/profileAction";

import {
  createUserLogin,
  createUserOtpLogin,
  setUserData,
} from "../../redux/actions/authAction";
import {
  SUCCESSFUL_LOGIN,
  SUCCESSFUL_LOGIN_WELCOME_MSG,
} from "../../utils/constants";
import Modal from "../common/commonModal";
import { useSnackbar } from "notistack";
import { Helmet } from "react-helmet";


function Login({closeModalEvent, closeModalHome, toggleModalEvent, toggleModalHome, clickType,checkoutParams})  {
  const fromEventModal = closeModalEvent
  //console.log(closeModalHome);
  const history = useHistory();
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar();
  const [isRecieveOtp, setIsRecievedOtp] = useState(true);
  const [isLoginEnabled, setIsLoginEnabled] = useState(false);
  const [email, setEmail] = useState("");
  const [isTrue, setIsTrue] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [msg, setMsg] = useState("");
  const [autoComplete, setAutoComplete] = useState(false);
  const [infoPopup, setInfoPopup] = useState(false);

  const loginUserData = useSelector((state) => state?.auth?.login_user_otp);

  useEffect(()=>{
    if(clickType){
      setIsRecievedOtp(true);
      setIsLoginEnabled(false);
      setEmail("");
    }
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ){
      setAutoComplete(true)
      } 
  }, [clickType]);

  const dispatch = useDispatch();

  const enableOtp = () => {
    setIsLoginEnabled(true);
    setIsRecievedOtp(false);
  };

  const handleErrors = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
    });
    setIsTrue(false);
  };

  const refreshState = () => {
    setShowModal(false);
    window.scrollTo(0, 0);
    closeNav();
    if(closeModalHome){
      //history.push("/");
      window.location.reload();
    }
  };
  function closeNav() {
    // let element = document.getElementById("myNav");
    // element.style.display = "none";
    // document.body.style.overflowY = "scroll";
    // document.getElementById("myNav").style.height = "0%";
  }
  

  useEffect(() => {
    if (
      Object.keys(loginUserData).length !== 0 &&
      loginUserData.constructor === Object
    ) {
      if(fromEventModal){
        toggleModalEvent(false)
        window.location.reload();
        window.scrollTo(0,0);
        localStorage.setItem("token", loginUserData.data.token);
      }
      else {
        if(loginUserData?.data?.corporate_account){
          history.push("/corporates");
          localStorage.setItem("token", loginUserData?.data?.token);
        }else{
          history.push("/");
          localStorage.setItem("token", loginUserData?.data?.token);
        }
      }
    }
  }, [loginUserData]);

  let intRegex = /[0-9 -()+]+$/;
  let eml = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

  const emailValidate = (e) => {
    setEmail(e.target.value);
    setIsTrue(false);
    let ep_emailval = e.target.value;
    if (intRegex.test(ep_emailval)) {
      if (ep_emailval.length < 10 || !intRegex.test(ep_emailval)) {
        setMsg("Enter 10-digit Number");
      }
    } else {
      if (eml.test(ep_emailval) == false) {
        setMsg("Enter Valid Email");
      }
    }
  };

  const submitHandler = (e) => {
    setIsTrue(false);
    e.preventDefault();
    if (email === "") {
      enqueueSnackbar("Please enter a email Id", {
        variant: "error",
      });
    } 
    else if (!testEmail(email)) {
      enqueueSnackbar("Please enter a valid email Id", {
        variant: "error",
      });
    }
    else {
      dispatch(createUserLogin(email, enableOtp, handleErrors, fromEventModal, setInfoPopup));
    }
  };

  const handleOtpSubmit = (modalVal, otp) => {
    setIsRecievedOtp(false);
    dispatch(
      createUserOtpLogin(otp, refreshState, handleErrors, showSuccessModal)
    );
  };

  const showSuccessModal = () => {
    if(fromEventModal){
      console.log("success");
      localStorage.setItem("isJustLoggedIn", 'true');
    }
    else {
      setShowModal(true);
    }
  };
  const handleGoBack = () => {
    setIsLoginEnabled(false);
    setIsRecievedOtp(true);
    setEmail('');
  }
 const goToCorpLogin = () => {
  history.push('/corporates')
  dispatch(ProfileAction.setScroll(true));
  if(checkoutParams){
    localStorage.setItem("checkoutParams",checkoutParams)
  }
 }
 const handleClose = () => {
   setInfoPopup(false)
 }
  return (
    <>
    <Helmet>
          <title>Login - Hammock Holidays</title>
          <meta name="description" content="" />
      </Helmet>
    <div className="card-box event-card-box">
      {!isLoginEnabled && isRecieveOtp && (
        <form onSubmit={submitHandler}>
          <InputComponent
            type="text"
            name="email"
            placeholder="Enter Your Email"
            value={email}
            onChange={emailValidate}
            titleValue="Email"
            msg={msg}
            isTrue={isTrue}
            autoComplete={autoComplete}
          />
          <CommonButton>Receive OTP</CommonButton>
        </form>
      )}
      {isLoginEnabled && !isRecieveOtp && (
        <div className="login-page-otp">
          <OtpComponent onOtpSubmitHandler={handleOtpSubmit} btnTitle="Login" 
            handleGoBack={handleGoBack}
          />
        </div>
      )}
      <hr />
      <Modal
        showMyModal={showModal}
        modalSubHeadingText={SUCCESSFUL_LOGIN}
        modalHeadingText={SUCCESSFUL_LOGIN_WELCOME_MSG}
      />
      <div className="login-success-popup" >
        <Dialog
            open={infoPopup}
            onClose={handleClose}
        >
            <DialogTitle>
                <div className="success-holder" >
                    <div>
                        <img src={exclamationMark} alt="successful" />
                    </div>
                    <div className="success-message">
                        <p style={{textAlign: "center", fontWeight: 500 }} > Are you trying to login as a corporate employee?</p>
                        <div className="confirmation-btn mt-5">
                            <button className="cursor-pointer " style={{fontWeight: 700}} onClick={() => setInfoPopup(false)}>Try different mail id</button>
                            <button className="cursor-pointer corporate" onClick={() => goToCorpLogin()}>Login as a corporate</button>
                        </div>
                    </div>

                </div>
            </DialogTitle>
        </Dialog>
        </div>
    { 
       <div className="login-footer">
        <SocialLogin fromPopover={fromEventModal} title="Or Login Using" />
      </div>
    }
    </div>
    </>
  );
};

export default withRouter(Login);
