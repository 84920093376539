import React, { useState, useEffect, useRef } from 'react';
import CommonButton from './authCommonButton';
import PlusIcon from '../../assets/images/corporates/plusIcon.png'
import minusIcon from '../../assets/images/corporates/minusIcon.png';
import 'animate.css';
import '../../assets/css/partials/corporates/corpGuestModal.scss';
import { useDispatch } from 'react-redux';

const GuestsModal = ({ showMyModal, guestDatahandler, adultCount, childrensCount, infantCount }) => {
    const [adultGuestCount, setAdultGuestCount] = useState(adultCount);
    const [childrensGuestCount, setChildrensGuestCount] = useState(childrensCount);
    const [count, setCount] = useState(infantCount);

    const dispatch = useDispatch();
    const wrapperRef = useRef(null);
    useModalOutsideClose(wrapperRef);

    const adultGuestIncrementHandler = () => {
        setAdultGuestCount(adultGuestCount + 1)
    }

    const adultGuestIDecrementHandler = () => {
        if (adultGuestCount <= 0) {
            setAdultGuestCount(0);
        } else {
            setAdultGuestCount(adultGuestCount - 1)
        }
    };

    const childrensGuestIncrementHandler = () => {
        setChildrensGuestCount(childrensGuestCount + 1);
    }

    const childrensGuestIDecrementHandler = () => {
        if (childrensGuestCount <= 0) {
            setChildrensGuestCount(0);
        } else {
            setChildrensGuestCount(childrensGuestCount - 1)
        }
    };

    const increment = () => {
        setCount(count + 1)
    };

    const decrement = () => {
        if (count <= 0) {
            setCount(0);
        } else {
            setCount(count - 1)
        }
    };

    const guestData = () => {
        document.getElementById("guestModal").classList.remove("hide");
        document.getElementById("guestModal").classList.add("show");
        guestDatahandler(adultGuestCount, childrensGuestCount, count);
    };

    function useModalOutsideClose(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    document.getElementById("guestModal").classList.add("hide");
                    document.getElementById("guestModal").classList.remove("show");
                    guestDatahandler(adultGuestCount, childrensGuestCount, count);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    };

    return (
        <div className={`modal-wrapper ${showMyModal ? 'show' : 'hide'}`} id="guestModal">
            <div className='calendar-overlay'></div>
            <div className="card-box card-box-index guest-modal calender-modal animated_only_mobile animate__animated animate__fadeInUp animate_faster" ref={wrapperRef}>
                <div className="row corp-row">
                    <div className="col-md-8 corp-content">
                        <div className="row corp-content-single-row">
                            <div className="col-6 col-md-6">
                                <h6>Adults</h6>
                                <p>12 Yrs and above</p>
                            </div>
                            <div className="col-md-6 corp-icon">

                                <img alt="minusIcon"
                                    src={minusIcon}
                                    onClick={adultGuestIDecrementHandler}
                                />
                                <span className="count">{adultGuestCount}</span>
                                <img alt="PlusIcon"
                                    src={PlusIcon}
                                    onClick={adultGuestIncrementHandler}
                                />
                            </div>
                        </div>

                        <div className="row corp-row corp-content-single-row">
                            <div className="col-6 col-md-6">
                                <h6>Childrens</h6>
                                <p> 5-12 Yrs </p>
                            </div>
                            <div className="col-md-6 corp-icon">

                                <img alt="minusIcon"
                                    src={minusIcon}
                                    onClick={childrensGuestIDecrementHandler}
                                />
                                <span className="count">{childrensGuestCount}</span>
                                <img alt="PlusIcon"
                                    src={PlusIcon}
                                    onClick={childrensGuestIncrementHandler}
                                />
                            </div>
                        </div>

                        <div className="row corp-row corp-content-single-row pb-md-0">
                            <div className="col-6 col-md-6">
                                <h6>Below 5 yrs</h6>
                                <p>0-5 Yrs of age</p>
                            </div>
                            <div className="col-md-6 corp-icon">

                                <img src={minusIcon} alt="minusIcon" onClick={decrement} />
                                <span className="count">{count}</span>
                                <img
                                    src={PlusIcon} alt="PlusIcon"
                                    onClick={increment}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="otp-btn-container">
                            <button type="button" className="btn-common" onClick={guestData}>Done</button>
                        </div>
                        <p className="additional-note">Additional charge for every extra adult/child joining the group after payment</p>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default GuestsModal;