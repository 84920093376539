import React from "react";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import SnackAlert from "./components/utilities/SnackAlert";

import "bootstrap/dist/css/bootstrap.min.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import './components/auth/auth.css'
import "./App.scss";
import Main from "./layouts";
import store from "./redux/store";

const App = () => {
  return (
    <SnackbarProvider
      autoHideDuration={3000}
      maxSnack={3}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Provider store={store}>
        <SnackAlert />
        <Main />
      </Provider>
    </SnackbarProvider>
  );
};

export default App;
