import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import SuccessfulLogo from '../../assets/icons/authIcons/successfulLogo.png'
import "../../assets/css/partials/corporates/corporates.scss";
import OtpComponent from "../../components/auth/otpComponent";
import AlertIcon from '../../assets/images/common/jam_alert.svg';
import CloseIcon from '../../assets/images/common/close-modal.svg';

import {
  createCorpLoginUser,
  createCorpUserOtpLogin,
  setUserData,
} from "../../redux/actions/authAction";
import * as ProfileAction from "../../redux/actions/profileAction";
import { useSnackbar } from "notistack";
import CommonModal from "../common/commonModal";

const Login = ({onInputFocus}) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = useState("");
  const [isTrue, setIsTrue] = useState(false);
  const [corpSuccessFullModal, setCorpSuccessFullModal] = useState(false)
  const [companyNotExistModal, setCompanyNotExistModal] = useState(false)

  const dispatch = useDispatch();
  let eml = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

  const emailValidate = (e) => {
    setEmail(e.target.value);
  };
  const corporateSubmitHandler = (e) => {
    e.preventDefault();
    if (email === "") {
      enqueueSnackbar("Please enter email", {
        variant: "error",
      });
    } else if (eml.test(email) === false) {
      enqueueSnackbar("Please enter a valid email id", {
        variant: "error",
      });
    } else {
      dispatch(createCorpLoginUser(email, enableOtp, disabledOtp, handleErrors, showCompanyNotExistsModal, hideCompanyNotExistsModal));
    }
  };

  const refreshState = () => {
    let data = JSON.parse(localStorage.getItem("userData"));
    if (data?.token) {
      dispatch(setUserData(data));
    }
    if(window.innerWidth<768){
      document.body.scrollTop=document.querySelector('.nav-holder').offsetTop || 0;  // For Safari
      document.documentElement.scrollTop=document.querySelector('.nav-holder').offsetTop || 0; // For Chrome, Firefox, IE and Opera
    } else {
      document.body.scrollTop=0;  // For Safari
      document.documentElement.scrollTop=0; // For Chrome, Firefox, IE and Opera
    }
    window.scroll(0,0)
    dispatch(ProfileAction.getProfileDetails());
    closeNav();
  };
  function closeNav() {
    // document.getElementById("myNav").style.display = "none";
  }
  const corpOtphandleOtpSubmit = (showMyModal, otp) => {
    dispatch(createCorpUserOtpLogin(otp, refreshState, handleErrors, showCorporateSuccessModal));
  };

  const enableOtp = () => {
    setIsTrue(true);
  };

  const disabledOtp = () => {
    setIsTrue(false);
  };

  const showCorporateSuccessModal = () => {
    setCorpSuccessFullModal(true)
  }

  const showCompanyNotExistsModal = () => {
    setCompanyNotExistModal(true)
  }

  const hideCompanyNotExistsModal = () => {
    setCompanyNotExistModal(false)
  }

  const handleErrors = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
    });
    setIsTrue(true);
  };

  const handleGoBack = () => {
    setIsTrue(false);
    setEmail('');
  }

  const goToContactUsPage = () => {
    history.push(`/contact-us`);
    window.scrollTo(0,0)
}

  return (
    <div className="login-holder">
      <p >Existing User?</p>
      <h5>Login/Signup to  <span>Redeem your Rewards</span>  or find <span>Exclusive Deals</span></h5>

      <div class="line"></div>
      {!isTrue ? (
        <form onSubmit={corporateSubmitHandler}>
          <div className="input-holder">
            <p>Login/Signup to redeem your rewards</p>
            <div class="custom-input card--bg mb20">
              <input
                name="email"
                type="text"
                class="form-control"
                placeholder="Email ID"
                id="email"
                value={email}
                onChange={emailValidate}
                onFocus={onInputFocus}
              />
              <div class="form-line"></div>
              <label>Your Company Email ID</label>
            </div>
          </div>
          <div className="text-center">
            <div className="corp-receive-otp" >
              <button className="btn btn-receive-otp">
                Receive E-mail OTP
              </button>
            </div>
          </div>
        </form>
      ) : (
        <div className="corporate-otp-holder">
          <OtpComponent
            onOtpSubmitHandler={corpOtphandleOtpSubmit}
            btnTitle="Login"
            handleGoBack={handleGoBack}
            iscorpUserLogin={true}
          />
        </div>
      )}
      <div className="login-success-popup">
        <Dialog
          open={corpSuccessFullModal}
        >
          <DialogTitle>
            <div className="success-holder" style={{}}>
              <div>
                <img src={SuccessfulLogo} alt="successful" />
              </div>
              <div className="success-message">
                <p>Successfully logged in</p>
                <p>Welcome back to Hammock Corporates</p><hr></hr>
              </div>

            </div>
          </DialogTitle>
        </Dialog>
      </div>
      {
        /* company does not exists */
      }
      <div className="login-success-popup not-registered-alert">
        <Dialog
          open={companyNotExistModal}
        >

          <DialogTitle>

            <div className="success-holder position-relative" style={{}}>
              <div className="goBack" onClick={hideCompanyNotExistsModal}>
                <img src={CloseIcon} alt="" />
              </div>
              <div>
                <img src={AlertIcon} alt="alert-message" />
              </div>

              <div className="success-message alert-message">
                <p>Hey, unfortunately your company is not registered.</p>
                <p>Please get in touch with us, for further steps.</p>
                <hr></hr>
                <div className="text-center my-2" onClick={goToContactUsPage}>
                  <button className="btn otp-btn mt-3">Get in touch</button>
                </div>
              </div>

            </div>
          </DialogTitle>
        </Dialog>
      </div>

    </div>
  );
};

export default Login;
