import React, { useEffect, useState, useRef } from "react";
import OwlCarousel from "react-owl-carousel";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import * as CorporateAction from '../../redux/actions/corporatesAuthActions';
import GuestsModal from '../common/guestsModal';
import CalenderModal from './calenderModal';
import Header from '../common/header';
import Footer from '../common/footer';
import Loader from '../common/loader';
import { withRouter } from 'react-router-dom'
import "../../assets/css/partials/corporates/packageDetails.scss";
import backIcon from "../../assets/images/common/back-icon.svg";
import YouMayAlsoLike from "./youMayAlsoLike";
import ContactDetailsCard from '../common/contactDetailsCard';
import { Slider } from "@material-ui/core";
import moment from 'moment'
import { Helmet } from "react-helmet";
import useClickOutside from '../../hooks/useClickOutside';

const owl_images = {
  items: 1,
  margin: 2,
  nav: false,
  loop: true,
  dots: true,
  autoplay: true,
  smartSpeed: 500,
  animateIn: 'fadeIn',
  animateOut: 'fadeOut',
};

const GiftDetails = (packageDetailsPage) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const packageID = packageDetailsPage.match.params.id;
  const [loader, setLoader] = useState(true);
  const [height, setHeight] = useState(0)
  const [isVisible, setIsVisible] = useState(true);

  const [showButtons, setShowButtons] = useState(true);
  const [scrollPos, setScrollPos] = useState(0);
  
  

  const refreshGifts = () => {
    dispatch(CorporateAction.getPackageDetails(packageID));
  }
  
  const buyGiftVoucherHandler = () => {
    localStorage.setItem('giftVoucherPrice', JSON.stringify(isPointsChanged ? giftVoucherPrice : MIN_POINTS))
    history.push('/gift-checkout')
    window.scrollTo(0, 0);
  }
  const PACKAGE_DETAILS = useSelector((state) => state.corporates.corporates_package_details.data);
  const PACKAGE_IMAGES = PACKAGE_DETAILS?.corporate?.packages[0].images;
  const PACKAGE_VALIDITY = PACKAGE_DETAILS?.corporate?.packages[0].valid_till;
  const PACKAGE_PRICE = PACKAGE_DETAILS?.corporate?.packages[0];
  const min_points = PACKAGE_DETAILS?.corporate?.packages[0].min_points;
  const [showContactModal, setShowContactModal] = useState(false);

  const userInfo = JSON.parse(localStorage.getItem("userData"));
  const packageInfo = location.state;
  const MAX_POINTS = PACKAGE_PRICE?.max_points;
  const MIN_POINTS = PACKAGE_PRICE?.min_points;
  const companyDomain = userInfo?.organisation?.email_domain;
  const isWipro = (companyDomain === "wipro.com");
  const [isPointsChanged, setIsPointsChanged] = useState(false)
  const [giftVoucherPrice, setGiftVoucherPrice] = useState("")
  const [sliderVal, setSliderVal] = useState(0)
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const similarOffer = params.get('isSimilar');
  const isSimilarOffer = JSON.parse(similarOffer);

  const contactModalRef = useRef();
    useClickOutside(contactModalRef, (e) => {
       setTimeout( () => setShowContactModal(false),100)
        
    });
  useEffect(() => {
    setSliderVal(0)
    // setIsPointsChanged(MIN_POINTS)
    window.scrollTo(0, 0)
    if(isSimilarOffer){
      dispatch(CorporateAction.getFilteredPackageDetails(packageID, handleLoader));
    }
    else {
    dispatch(CorporateAction.getPackageDetails(packageID, handleLoader));
    setTimeout(() => {
      dispatch(CorporateAction.getPackageDetails(packageID, handleLoader));
    }, 500)

  }

  }, [packageID]);

  const handleLoader = () => {
    setLoader(false);
    
  };

  const handleContactModal = () => {
    setShowContactModal(true)
  }

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () =>
        window.removeEventListener("scroll", listenToScroll);
}, [])

const listenToScroll = () => {
    let heightToHideFrom = document.body.scrollHeight - 850;
    const winScroll = document.body.scrollTop ||
        document.documentElement.scrollTop;
    setHeight(winScroll);
    if (winScroll > heightToHideFrom) {
        setIsVisible(false);
    } else {
        setIsVisible(true);
    }
};

const handleRangeSlider = (event, value) => {
  
  setSliderVal(value)
  setIsPointsChanged(true)
  const total = MIN_POINTS + value;
  setGiftVoucherPrice(total)
}

const goBackFun = ()=>{
  let urlHistory = JSON.parse(localStorage.getItem('urlHistory'));
  
  if(!localStorage.getItem('prevUrl')){
      history.push('/corporates');
      localStorage.setItem('corporateCategory', 58)
     
  }  else if ((urlHistory[0].pathname===urlHistory[urlHistory.length-1].pathname) && urlHistory.length>2) {
    localStorage.setItem('corporateCategory', 58)
    history.push('/corporates');
  }  else if (localStorage.getItem('prevUrl')==='/') {
      history.push('/');
  } else {
      history.goBack();
  } 
};

  return (
    <div>
      <Helmet>
            <title>
            {`${PACKAGE_DETAILS?.corporate?.title}`}
            </title>
            <meta 
            name="description" 
            content={`${PACKAGE_DETAILS?.corporate?.packages[0]?.brief_description}`} />
        </Helmet>
      <div className="innerPage-header hide-header-mobi">
        <Header />
      </div>
      {!loader ? (
        <div className="container innerPage-container innerPage-container-mobi">
          <a href onClick={() => goBackFun()}>
            <div className="back-button back-button-mobi">
              <img src={backIcon} alt="" />
            </div>
          </a>
          <div className="package-holder">
            <div className="package-title d-none d-md-block">
              <h1>{PACKAGE_DETAILS && PACKAGE_DETAILS.corporate.title}</h1>
              <p className="voucher">GIFT VOUCHER</p>
              {/* <p className="destination">
                {PACKAGE_DETAILS &&
                  PACKAGE_DETAILS.corporate.packages[0].location[0].address}
              </p> */}
            </div>
            <div className="package-details">
              <div className="row">
                <div className="col-12 col-lg-7">
                  {
                    <div className="images--holder gift-images">
                      {PACKAGE_IMAGES && PACKAGE_IMAGES.length > 1 ? 
                        <OwlCarousel className="owl-theme" {...owl_images}>
                          {PACKAGE_IMAGES &&
                            PACKAGE_IMAGES.length > 0 &&
                            PACKAGE_IMAGES.map((items) => (
                              <div className="item gift-bg-image">
                                <div className="package-image " >
                                  <img
                                    src={items.image}
                                    className="img-fluid object-contain"
                                    alt="" />
                                  <div className="blur">
                                    <img
                                      src={items.image}
                                      className="img-fluid blur-img"
                                      alt="" />
                                  </div>
                                </div>
                              </div>
                            ))}
                        </OwlCarousel> : 
                        <div className="item gift-bg-image">
                          <div className="package-image " >
                            <img
                              src={PACKAGE_IMAGES[0].image}
                              className="img-fluid object-contain"
                              alt="" />
                            <div className="blur">
                              <img
                                src={PACKAGE_IMAGES[0].image}
                                className="img-fluid blur-img"
                                alt="" />
                            </div>
                          </div> 
                        </div>}
                    </div>
                  }
                  <div className="package-title d-block d-md-none mt-4">
                    <h1>{PACKAGE_DETAILS && PACKAGE_DETAILS?.corporate?.title}</h1>
                    <p className="voucher">GIFT VOUCHER</p>
                    {/* <p className="destination">
                      {PACKAGE_DETAILS &&
                        PACKAGE_DETAILS.corporate.packages[0].location[0].address}
                    </p> */}
                  </div>

                  {/* {PACKAGE_DETAILS &&
                    PACKAGE_DETAILS.points_list.map((points) => (
                      <div>
                        <div className="select-points giftVoucher-points">
                          {
                            isWipro ? <p>{isPointsChanged} pts</p> : <p>Rs {isPointsChanged}</p>
                          }
                        </div>
                      </div>
                    ))} */}
                  
                  <div className="package-description mt-5">
                    <p className="mb-3">
                        Validity till :{" "}
                        {PACKAGE_DETAILS
                          ? moment(PACKAGE_VALIDITY).format('Do MMMM, YYYY')
                          : PACKAGE_VALIDITY !== undefined &&
                          moment(PACKAGE_VALIDITY).format('Do MMMM, YYYY')}
                      </p>
                    <p dangerouslySetInnerHTML={{
                      __html: PACKAGE_DETAILS &&
                        PACKAGE_DETAILS.corporate.packages[0].description
                    }} >
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-5">
                  <div className="login-holder booking-holder gift-mobi-amount">
                    {PACKAGE_DETAILS &&
                      PACKAGE_DETAILS.points_list.map((points) => (
                        <div>
                          {
                            isWipro ? <h5>{isPointsChanged ? giftVoucherPrice : MIN_POINTS} pts</h5> : <h5>Rs {isPointsChanged ? giftVoucherPrice : MIN_POINTS}</h5>
                          }
                          {
                            isWipro ? <p className="points-worth">Worth Rs {isPointsChanged ? giftVoucherPrice : MIN_POINTS}</p> : null
                          }

                        </div>
                      ))}
                      <p className="fill-details" style={{fontWeight: 'bold'}}>Select a voucher amount on the slider</p>
                      <div className="points-slider-wrapper">
                        <Slider 
                            // min="500"
                            // max={MAX_POINTS}
                            max={MAX_POINTS - MIN_POINTS}
                            value={sliderVal}
                            onChange={handleRangeSlider}
                            aria-labelledby="input-slider"
                            step="500" 
                            // valueLabelDisplay="on"
                        />
                      </div>
                      
                    <button
                      type="button"
                      className="btn proceed-to-book w-100 d-none d-md-block"
                      href
                      onClick={buyGiftVoucherHandler} >
                      Buy this Gift Voucher
                    </button>
                  </div>

                  {
                    // mobile view modal
                    showContactModal ? <div className="contact-modal">
                      <ContactDetailsCard openModal={setShowContactModal} contactModalRef={contactModalRef}/>
                    </div> : null
                  }

                  <div className="d-none d-md-block sticky-contact">
                    <ContactDetailsCard stickyBtnText={'Buy Now'} />
                  </div>

                </div>
              </div>
            </div>
          </div>
          {PACKAGE_DETAILS && PACKAGE_DETAILS.corporate.packages[0].id ? (
              <YouMayAlsoLike
                giftInfo={
                  PACKAGE_DETAILS && PACKAGE_DETAILS.corporate.packages[0].id
                }
                giftVoucher={true}
                />
          ) : null}
        </div>
      ) : <Loader />}

      
      {
        isVisible && 
        <div className={showButtons ? 'd-block' : 'd-none'}>
         <div className="mobile-buttons">
          <div className="stcky-leftButton">
            <div className="white-button" onClick={handleContactModal}>
              <a >Contact</a>
            </div>
          </div>
          <div className="stcky-rightButton">
            <div className="custom-button" onClick={buyGiftVoucherHandler}>
              <a>Buy this</a>
            </div>
          </div>
        </div>
        </div>
      }
        
      


      <Footer />
    </div>
  );
};

export default withRouter(GiftDetails)