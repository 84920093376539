import React, { useState, useEffect } from "react";
import InputComponent from "../common/inputComponent";
import CommonButton from "../common/authCommonButton";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resendOtp } from '../../redux/actions/authAction';
import { FaArrowLeft } from 'react-icons/fa';
import { useSnackbar } from "notistack";
import { createUserLogin } from '../../redux/actions/authAction'

const OtpComponent = (props) => {
  const { enqueueSnackbar } = useSnackbar();

  const [otp, setOtp] = useState("");
  const [showMyModal, setShowMyModal] = useState(true);
  const [otpMsg, setOtpMsg] = useState("");
  const [isOtpTrure, setIsOtpTrure] = useState(false);
  const [isTrue, setIsTrue] = useState(false);
  const [initialMinute, setInitialMinute] = useState(1);
  const [initialSeconds, setInitialSeconds] = useState(0);
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);
  const dispatch = useDispatch();

  const emailId = useSelector(state => state?.auth?.get_email);
  const otpSendToMsg = useSelector(state => state?.auth?.otpSentToMessage);
  const signupOtpSendToMsg = useSelector(state => state?.auth?.signupOtpSentToMessage);
  const corpOtpSendToMsg = useSelector(state => state?.auth?.corpOtpSendMessage);
  const userId = useSelector(state => state?.auth?.signup_user_id);
  const loginUserId = useSelector(state => state?.auth?.login_user_id);
  console.log("userDetails", userId)
  console.log("signupOtpSendToMsg", signupOtpSendToMsg)

  useEffect(()=>{
    document.querySelector('.otpInput').focus()
  }, []);

  const otpNumberValidation = (e) => {
    const re = /[0-9]/;
    if (e.target.value === '' || re.test(e.target.value)) {
    setOtp(e.target.value);
    setIsOtpTrure(false);
    }
  };
  const otpHandler = (e) => {
    e.preventDefault();
    if (otp === "") {
      setOtpMsg(" Please enter recieved otp");
      setIsOtpTrure(true);
    } else if (isNaN(otp)) {
      setOtpMsg("Enter only number");
      setIsOtpTrure(true);
    } else {
      setShowMyModal(true);
      props.onOtpSubmitHandler(showMyModal, otp);
    }
  };

  const resendOtpTimer = () => {
    setMinutes(1);
    setSeconds(0);
    let myInterval = setTimeout(() => {
      if (seconds > 0) {
        // setSeconds(0);
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  };

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });
  const shakeOTPmsg = ()=>{
    document.querySelector('.otpmsg').classList.remove('shake')
    
    setTimeout(()=>{
      document.querySelector('.otpmsg').classList.add('shake')
    });
  }

  const otpResendHandler = () => {
    shakeOTPmsg();
    document.querySelector('.otpInput').focus();
    resendOtpTimer();
     dispatch(resendOtp(loginUserId,handleOtpResendSuccess, handleErrors))
  }
  
  const signUpOtpResendHandler = () => {
    shakeOTPmsg();
    document.querySelector('.otpInput').focus()
    resendOtpTimer();
    dispatch(resendOtp(userId, handleOtpResendSuccess, handleErrors))
  };

  const handleErrors = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
    });
    setIsTrue(false);
  };

  const handleOtpResendSuccess = (message) => {
    enqueueSnackbar(message, {
      variant: "info",
    });
    setIsTrue(false);
  };

  return (
    <form onSubmit={otpHandler} autoComplete="off" >
      <div style={{marginBottom:"10px"}}>
        <span onClick={props.handleGoBack} style={{cursor:"pointer"}}><FaArrowLeft/></span>
        {minutes === 0 && seconds === 0 ? (
          ""
        ) : (
          <span className="otp-timer" style={{textAlign:"left", marginLeft:"10px"}}>
            {" "}
            <span >
              {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
            </span>{" "}
            Seconds Left
          </span>
        )}
      </div>
      <p className="otpmsg shake" style={{marginBottom: '10px', color:'#127412', fontWeight:'500', wordWrap:'break-word'}}>{props.isSignup?signupOtpSendToMsg:props.iscorpUserLogin ?corpOtpSendToMsg:otpSendToMsg}</p>
      <InputComponent
        type="tel"
        name="otpNumber"
        placeholder="Enter received OTP here"
        value={otp}
        inputClass="otpInput"
        // disabled= {
        //   minutes ===  0 && seconds === 0 
        //   ?  "disabled"
        //    : ''
        // }
        onChange={otpNumberValidation}
        titleValue=
        {minutes <=  1 && seconds >= 0 
          ?  <a onClick={props.isSignup?signUpOtpResendHandler : otpResendHandler}  className="resend-otp cursor-pointer" style={{opacity:`${minutes === 0 && seconds === 0?"":".2"}`, cursor:`${minutes === 0 && seconds === 0?"pointer": "none"}` ,pointerEvents:`${minutes === 0 && seconds === 0?"all": "none"}`}}>Resend OTP</a>
           : <a className="resend-otp" style={{ opacity: 0.6, cursor:"auto"}}>Resend OTP</a>
        }
        msg={otpMsg}
        isTrue={isOtpTrure}
      />
      <CommonButton children={props.btnTitle}></CommonButton>
    </form>
  );
};

export default withRouter(OtpComponent);