import React from 'react'
import {
    INTERNATIONAL_COVERAGE1,
    INTERNATIONAL_COVERAGE2,
} from '../../../resources/holidaysData';

function InternationalCoverage() {
    return (
        <div>
            <div className="recognitions-holder partners-holder">
                <h5>Some of our International coverage</h5>
                <div className="partners-wrapper">
                    <div className="d-flex list1">
                        {INTERNATIONAL_COVERAGE1.length > 0 &&
                            INTERNATIONAL_COVERAGE1.map((item) => (
                                <div className="card-wrapper">
                                    <div className="img-holder">
                                        <img src={item.image} alt="" />
                                    </div>
                                    <div className="info">
                                        <p className="mb-0">{item.name}</p>
                                    </div>
                                </div>
                            ))}
                           <div className="px-dummy pr-md-2"></div>
                    </div>
                    <div className="d-flex ">
                        {INTERNATIONAL_COVERAGE2.length > 0 &&
                            INTERNATIONAL_COVERAGE2.map((item) => (
                                <div className="card-wrapper">
                                    <div className="img-holder">
                                        <img src={item.image} alt="" />
                                    </div>
                                    <div className="info">
                                        <p className="mb-0">{item.name}</p>
                                    </div>
                                </div>
                            ))}
                       <div className="px-dummy pr-md-2"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InternationalCoverage
