import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Header from '../../components/common/header';
import Footer from '../../components/common/footer';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useSnackbar } from "notistack";
import backIcon from '../../assets/images/common/back-icon.svg';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import Input from '@material-ui/core/Input';
import '../../assets/css/partials/common/checkout.scss';
import Edit from '../../assets/images/common/Edit.svg';
import { testEmail } from "../../utils/common";
import * as EventAction from '../../redux/actions/eventsAction';
import { Helmet } from "react-helmet";
import InputComponent from '../common/inputComponent';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import IntlContactNumber from '../common/intlContactNumber';
import useClickOutside from '../../hooks/useClickOutside';
import { ScrollToTop } from '../common';

function EventCheckout() {
    const useStyles = makeStyles({
        root: {
            width: '100%',
        },
        input: {
            width: 42,
        },
    });
    
    const myRef = useRef(null)
    const executeScroll = () => myRef.current.scrollIntoView()
    const { enqueueSnackbar } = useSnackbar();


    const dispatch = useDispatch()
    const history = useHistory();
    const classes = useStyles();
    const [value, setValue] = useState(100);
    const [disabled, setDisabled] = useState(true);
    const [emailDisabled, setEmailDisabled] = useState(true);
    const [disabledLastname, setDisabledLastname] = useState(true);
    const [numDisabled, setnumDisabled] = useState(true);
    const [dateDisabled, setDateDisabled] = useState(true);
    const [isEditName, setIsEditName] = useState(false);
    const [isNameUpdate, setisNameUpdate] = useState(false);
    const [isEmailUpdate, setisEmailUpdate] = useState(false);
    // const [isEditLastName, setIsEditLastName] = useState(false);
    const userDetails = JSON.parse(localStorage.getItem("userData"));
    const isCorporateAccount = userDetails?.user_details?.corporate_account;

    const bookingInformationData = useSelector(state => state?.events?.booking_info);
    const PACKAGE_DETAILS = useSelector(state => state?.events?.event_details?.data);
    const packageData = PACKAGE_DETAILS && PACKAGE_DETAILS?.event?.packages[0];
    const { schedule_data, contact_email, contact_name, contact_number, total_price } = bookingInformationData;
    const profile_details = useSelector(state => state?.userProfile?.profile_details);
    const points = parseInt(localStorage.getItem("points")) ? parseInt(localStorage.getItem("points")) : profile_details?.data?.points;
    // let mobile = localStorage.getItem("mobile");
    let mobile = contact_number;
    if (isNaN(mobile)) {
        let userDataStored = JSON.parse(localStorage.getItem("userData"));
        mobile = userDataStored.user_details.mobile;
    }
    // const fullName = localStorage.getItem("full_name");
    const fullName = contact_name;
    const [balance, setBalance] = useState(total_price);
    const [pointsLeft, setPointsLeft] = useState(points);
    const [isRewardPointsBtnBool, setIsRewardPointsBtnBool] = useState(false);
    const [usedRewardPoints, setUsedRewardPoints] = useState(0);
    const [showPriceModal, setShowPriceModal] = useState(false)

    const [editName, setEditName] = useState('');
    const [editLastName, setEditLastName] = useState('');
    const [isEditEmail, setIsEditEmail] = useState(false);
    const [editNameError, setEditNameError] = useState(false);
    const [editLastNameError, setEditLastNameError] = useState(false);
    const [editEmail, setEditEmail] = useState('');
    const [editEmailError, setEditEmailError] = useState(false);
    const [editEmailValidError, setEditEmailValidError] = useState(false);
    const [isEditNumber, setIsEditNumber] = useState(false);
    const [editNumber, setEditNumber] = useState('');
    const [editNumberError, setEditNumberError] = useState(false);

    const [initialAmount, setInitialAmount] = useState(packageData?.price);
    const [gstAmount, setGstAmount] = useState(total_price);
    const [includingGST, setIncludingGST] = useState(total_price)
    const [includingChangedGST, setIncludingChangedGST] = useState(total_price)
    const [afterCheckedGST, setAfterCheckedGST] = useState(total_price)

    const [scroll, setScroll] = useState(false);
    const [radiovalue, setRadioValue] = useState('DC');
    const userInfo = JSON.parse(localStorage.getItem("userData"));
    const companyDomain = userInfo?.organisation?.email_domain;
    const isWipro = (companyDomain === "wipro.com");
    const nameInputRef = useRef();
    const emailInputRef = useRef();
    useClickOutside(nameInputRef, () => nameUpdateSuccess());
    useClickOutside(emailInputRef, () => emailUpdateSuccess());
    const nameUpdateSuccess = () => {
        if(isNameUpdate && editName == ""){
            enqueueSnackbar("Please enter your name", {
                variant: "error",
            });
        }else if(isNameUpdate){
            enqueueSnackbar("Your name updated successfully", {
                variant: "success",
            });
            setEditNameError(false)
        }
        setisNameUpdate(false)
    }

    const emailUpdateSuccess = () => {
        if(isEmailUpdate && editEmail == ""){
            enqueueSnackbar("Please enter your email", {
                variant: "error",
            });
        }else if(isEmailUpdate && !testEmail(editEmail)){
            enqueueSnackbar("Please enter valid email", {
                variant: "error",
            });
            
        }else if(isEmailUpdate){
            enqueueSnackbar("Your email updated successfully", {
                variant: "success",
            });
            setEditEmailError(false)
            setEditEmailValidError(false)
            
        }
        setisEmailUpdate(false)
    }

    useEffect(() => {
        const initialPrice = packageData?.price;
        const calculateGST = (initialPrice * 5 / 100) + initialPrice; // Calculating 5% GST 
        setInitialAmount(calculateGST - initialPrice);
        setIncludingGST(calculateGST);
        setPointsLeft(points);

        // hide/show confirm button on scroll
        window.addEventListener("scroll", () => {
            if(packageData && packageData?.price == 0){
                setScroll(window.scrollY > 400);
            }else{
                setScroll(window.scrollY > 950);
            }
        });

    }, [packageData?.price, total_price, radiovalue])

    useEffect(() => {
        window.scrollTo(0,0);
        localStorage.removeItem("isJustLoggedIn");
        if(!mobile?.toString().startsWith('+') && mobile?.toString().length>2){
            setEditNumber(`+${mobile}`);
        } else {
            setEditNumber(`${mobile}`);
        }
    },[])

    const balanceUpdateHandler = () => {
        const checkBox = document.querySelector('#rewardPointsCheck')
        if (checkBox.checked === true) {
            setIsRewardPointsBtnBool(true);
            if (total_price === points) {
                setBalance(0);
                setPointsLeft(0);
                setUsedRewardPoints(total_price);
                setValue(0);
                setGstAmount(0);
                setAfterCheckedGST(0);
            }
            else if (total_price > points) {
                setBalance(total_price - points);
                setPointsLeft(0);
                setUsedRewardPoints(points);
                setValue(0);
                const getBalance = total_price - points;
                const calcGST = (getBalance * 5 / 100) + getBalance
                setGstAmount(calcGST);
                setAfterCheckedGST(calcGST - getBalance)
            }
            else if (total_price < points) {
                setBalance(0);
                setPointsLeft(points - total_price);
                setUsedRewardPoints(total_price);
                const getPointsLeft = points - total_price
                const calculatePercentage = getPointsLeft * 100 / points;
                setValue(calculatePercentage);
                setGstAmount(0);
                setAfterCheckedGST(0);
            }
        }
        else if (checkBox.checked === false) {
            setIsRewardPointsBtnBool(false);
            setUsedRewardPoints(0);
            setBalance(total_price);
            const getBalance = total_price;
            setGstAmount((getBalance * 5 / 100) + getBalance);
            setAfterCheckedGST(0)
            if (points > 0) {
                setValue(100);
            }
        }
    }

    const handleSliderChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleSelectedDateClick = () => {
        setDateDisabled(!disabled);
    }

    const changeName = (e) => {
        setIsEditName(true)
        setisNameUpdate(true)
        setEditName(e.target.value)
        dispatch(EventAction.nameChangeOnUpdate(e.target.value, true));
        setEditNameError(false)
    };

    // const changeLastName = (e) => {
    //     setIsEditLastName(true)
    //     setEditLastName(e.target.value)
    // }

    const emailChange = (e) => {
        setIsEditEmail(true)
        setisEmailUpdate(true)
        setEditEmail(e.target.value)
        setEditEmailError(false)
        setEditEmailValidError(false)
    };

    const numberChange = (val) => {

        console.log(val)
        
        setIsEditNumber(true);
        setEditNumberError(false)
        
        
        
        // localStorage.setItem("mobile",e.target.value);
        if(val){
            setEditNumber(val);
            dispatch(EventAction.eventEditNumberChange(val, true));
        } else {
            setEditNumber('');
            dispatch(EventAction.eventEditNumberChange('', false));
        }
        
    };

    const handleEditNameClick = () => {
        setDisabled(!disabled);
    };

    // const handleEditLastNameClick = () => {
    //     setDisabledLastname(!disabled);
    // }


    const handleEditEmailClick = () => {
        setEmailDisabled(!emailDisabled);
    }
    // const handleEditNumberClick = () => {

    //     if((editNumber.startsWith('+91') && editNumber.length!==13)  || !isPossiblePhoneNumber(editNumber)){
    //         enqueueSnackbar('Phone Number is not valid', {
    //             variant: "error",
    //         });
    //         return false;
    //     }
    //     setnumDisabled(!numDisabled);
    // }

    const focusInput = (inputDom)=>{
        var elementPosition = document.querySelector(`${inputDom}`).getBoundingClientRect().top;
                    var offsetPosition = elementPosition + window.pageYOffset - 145;
                    window.scrollTo({top: offsetPosition, behavior: "smooth" });
                    setTimeout(()=>{
                        document.querySelector(inputDom).focus();
                    });
    };

    const handleCheckout = (e) => {
        // e.preventDefault();
        const { pack_id, contact_email, contact_name, contact_number, total_price, schedule } = bookingInformationData;
        if ((isEditName && (editName === "" || editName === null)) || (!isEditName && (fullName === "" || fullName === null))) {
            setEditNameError(true)
            setEditEmailError(false)
            setEditEmailValidError(false)
            setEditLastNameError(false)
            focusInput('#name');
        }
        // else if ((isEditLastName && (editLastName === "" || editLastName === null)) || (!isEditLastName && contact_last_name === "")) {
        //     setEditLastNameError(true)
        //     setEditNameError(false)
        //     setEditEmailError(false)
        //     setEditEmailValidError(false)
        // }
        else if ((isEditEmail && (editEmail === "" || editEmail === null)) || (!isEditEmail && (contact_email === "" || contact_email == null))) {
            setEditEmailError(true)
            setEditEmailValidError(false)
            setEditNameError(false)
            setEditLastNameError(false)
            focusInput('#email');

        }
        else if (isEditEmail && !testEmail(editEmail)) {
            setEditEmailValidError(true)
            setEditEmailError(false)
            setEditNameError(false)
            setEditLastNameError(false)
            focusInput('#email');
        }
        else if((editNumber.startsWith('+91') && editNumber.length!==13)  || !isPossiblePhoneNumber(editNumber)){
            setEditNumberError(true)
            setEditEmailError(false)
            setEditEmailValidError(false)
            setEditNameError(false)
            focusInput('.PhoneInputInput');
        }

        else {
            let data = {
                pack_id: pack_id,
                schedule: schedule,
                total_points: usedRewardPoints,
                total_price: total_price,
                amount: !isRewardPointsBtnBool ? includingGST : gstAmount,
                contact_name: isEditName ? editName : fullName,
                // contact_last_name: isEditLastName ? editLastName : contact_last_name,
                contact_email: isEditEmail ? editEmail : contact_email,
                contact_number: isEditNumber ? editNumber : mobile,
                pay_mode: radiovalue
            }
            const updatedData = {
                ...bookingInformationData,
                contact_name: isEditName ? editName : fullName,
                contact_email: isEditEmail ? editEmail : contact_email,
                contact_number: isEditNumber ? editNumber : mobile,
            }
            dispatch(EventAction.updateBookingData(updatedData));
            // if amount is greated then zero redirecting to payment's page
            // and if paying with points after redeeming points redirecting to success page
            if (data?.pack_id !== "" || data?.pack_id !== null) {
                if (data?.amount > 0) {
                    dispatch(EventAction.checkOut(data, isCorporateAccount, paidWithPoints, payAmount, handleErrors));
                    // history.push(`/events/checkout-details`, data);
                    window.scrollTo(0, 0)
                }
                else if(packageData?.price == 0){
                    dispatch(EventAction.freeEventBooking(data, isCorporateAccount, paidWithPoints, payAmount, handleErrors, handleSuccess));
                }else {
                    dispatch(EventAction.checkOut(data, isCorporateAccount, paidWithPoints, payAmount, handleErrors));
                }
            }
        }
    }

    const payAmount = () => {
        history.push(`/events/checkout-details`);
        dispatch(EventAction.nameChangeOnUpdate(null, false))
        dispatch(EventAction.eventEditNumberChange(null, false))
    }
    const handleSuccess = () => {
        history.push(`/events/payment-successful`);
    }

    const paidWithPoints = () => {
        history.push(`/events/payment-successful`);
        dispatch(EventAction.nameChangeOnUpdate(null, false))
        dispatch(EventAction.eventEditNumberChange(null, false))
    }

    const handleErrors = (message) => {
        enqueueSnackbar(message, {
            variant: "error",
        });
    };

    const paymentModeChange = (e) => {
        setRadioValue(e.target.value);
    }

    const handlePriceDetailsModal = () => {
        setShowPriceModal(true)
    }
    
    

    return (
        <>
        <Helmet>
            <title>{`${packageData?.pack_title}`} - Hammock Holidays</title>
            <meta name="description" content={`${packageData?.brief_description}`} />
        </Helmet>
        <div className="events-checkout-page">
            <div className="innerPage-header hide-header-mobi">
                <Header />
            </div>
            <div className="container innerPage-container innerPage-container-mobi">
                <a href onClick={() => history.goBack()}>
                    <div className="back-button back-button-mobi">
                        <img src={backIcon} alt="" />
                    </div>
                </a>
                <div className="purchase-details">
                    <h1>Your Details</h1>
                    <div className="line"></div>
                    <div className="row mb-5 pb-5">
                        <div className="col-12 col-lg-7">
                            <div className="d-flex d-md-block mobi-overflow">
                                <div className="product-holder product-holder-mobi">
                                    <div className="prod-img">
                                    {packageData?.images.map(image => {
                                        if(image?.thumbnail == true){
                                            return <img src={image?.image} alt="" />
                                        }
                                    })}
                                    </div>
                                    <div className="prod-info">
                                        <h5>{packageData?.pack_title}</h5>
                                        <div className="line"></div>
                                        <div className="details">
                                            {
                                                packageData?.workshop ? <p>Online workshop</p> : null
                                            }
                                            {/* <p>Worth Rs {packageData?.price}</p> */}
                                            <p>{`${moment(bookingInformationData?.schedule?.date, 'DD-MM-YYYY').format('ddd Do MMM,')} ${moment(bookingInformationData?.schedule?.from, 'hh:mm').format('LT')} to ${moment(bookingInformationData?.schedule?.to, 'hh:mm').format('LT')}`}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="buyer-details buyer-details-mobi mb-4 p-4">
                                    <form>
                                        <div class="input-holder mb-0">
                                            <div class="form-group mb-0">
                                                <input type="text" className="form-control" value={moment(bookingInformationData?.schedule?.date, 'DD.MM.YYYY').format('ddd Do MMM, YYYY')} disabled={dateDisabled}
                                                    style={{ background: 'transparent' }}
                                                />
                                                <label for="">Selected Date</label>
                                            </div>
                                            {
                                                // <div class="edit" onClick={handleSelectedDateClick}>
                                                //     <img src={Edit} alt="" />
                                                // </div>
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="buyer-details">
                                <h6>Our team will connect with you on below details</h6>
                                <form>
                                    <div class="input-holder">
                                        <div class="form-group mb-0" ref={nameInputRef}>
                                            <input type="text" className="form-control" 
                                            id="name"
                                            size={!isEditName ? fullName?.length : editName.length } 
                                            value={!isEditName ? `${fullName}` : editName}
                                                // disabled={disabled}
                                                onKeyPress={(event) => {
                                                    if (!/[a-zA-Z\s]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                    if(event.key === "Enter"){
                                                        event.preventDefault()
                                                        event.target.blur()
                                                        nameUpdateSuccess()
                                                    }
                                                }}
                                                onChange={changeName}
                                            />
                                            <label for="">Your name</label>
                                            {
                                                editNameError ? <p className="mb-0" style={{ color: 'red', fontSize: '12px' }}> Name is required</p> : ''
                                            }
                                        </div>
                                        {
                                            disabled ? <div class="edit" >
                                                {/* <img src={Edit} alt="" /> */}
                                            </div> : <p className="save--btn" onClick={handleEditNameClick}>Save</p>
                                        }
                                    </div>
                                </form>
                                <form>
                                    <div class="input-holder">
                                        <div class="form-group mb-0" ref={emailInputRef}>
                                            <input type="text" 
                                            id="email"
                                            className="form-control" 
                                            size={!isEditEmail ? bookingInformationData?.contact_email?.length + 2 : editEmail?.length +2}
                                            value={!isEditEmail ? `${bookingInformationData?.contact_email}` : editEmail}
                                            onChange={emailChange}
                                            onKeyPress={(e) => {
                                                if(e.key === "Enter"){
                                                    e.preventDefault()
                                                    e.target.blur()
                                                    emailUpdateSuccess()
                                                }
                                            }}
                                            
                                            />
                                            <label for="">Your email</label>
                                            {
                                                editEmailError ? <p className="mb-0" style={{ color: 'red', fontSize: '12px' }}>Email Id is required</p> : ''
                                            }
                                            {
                                                editEmailValidError ? <p className="mb-0" style={{ color: 'red', fontSize: '12px' }}>Please enter a valid email Id</p> : ''
                                            }
                                        </div>
                                        {
                                            emailDisabled ? <div class="edit" >
                                                {/* <img src={Edit} alt="" /> */}
                                            </div> : <p className="save--btn" onClick={handleEditEmailClick}>Save</p>
                                        }
                                    </div>
                                </form>
                                {/* <form>
                                    <div class="input-holder">
                                        <div class="form-group mb-0">
                                            <input type="text" className="form-control" size={bookingInformationData?.contact_number?.length + 2}
                                                value={!isEditNumber ? mobile : editNumber}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                onChange={numberChange}
                                                disabled={numDisabled} />
                                           
                                              
                                            
                                            {
                                                editNumberError ? <p style={{ color: 'red', fontSize: '12px' }}>Please enter a valid mobile number</p> : ''
                                            }
                                        </div>
                                        {
                                            numDisabled ? <div class="edit" onClick={handleEditNumberClick}>
                                                <img src={Edit} alt="" />
                                            </div> : <p className="save--btn" onClick={handleEditNumberClick}>Save</p>
                                        }

                                    </div>
                                </form> */}
                                {/* {isEditNumber?editNumber:mobile} */}
                                <IntlContactNumber disabled={false} mobile={isEditNumber?editNumber:mobile} numberChange={numberChange} hasError={editNumberError} enableInlineEdit={true}/> 
                            </div>
                        </div>
                        <div className="col-12 col-lg-5">
                            <div className={scroll ? "d-none" : "d-block"}>
                                <div className="mobile-buttons text-center">
                                    <div className="stcky-rightButton">
                                        <div className="custom-button" onClick={executeScroll}>
                                            <a >Confirm</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {packageData && packageData?.price !== 0 ? 
                            <div className="price-holder" ref={myRef}>
                                <h4>Price</h4>
                                <div className="price-line"></div>
                                {
                                    // If user is corporate user show points left and user can use points to purchase
                                    // else show amount to be paid
                                    isCorporateAccount ?
                                        (<div>
                                            <div className="total-cost">
                                                <div className="total">
                                                    <p>Total cost</p>
                                                </div>
                                                <div className="amount">
                                                    <p>{packageData?.price}</p>
                                                </div>
                                            </div>
                                            <p className="note"></p>
                                            {
                                                (points == 0 && !isWipro) ? null :
                                                    <div className="rewards-holder">
                                                        <div className="my-rewards">
                                                            <div >
                                                                <p className="rewards">My Reward Points</p>
                                                            </div>
                                                            <div>
                                                                <p className="pts">{`-${isRewardPointsBtnBool ? usedRewardPoints : 0}pts`}</p>
                                                            </div>
                                                        </div>
                                                        <div className={classes.root}>
                                                            <Grid container spacing={2} alignItems="center">
                                                                <Grid item>
                                                                </Grid>
                                                                <Grid item xs>
                                                                    <Slider
                                                                        value={typeof value === 'number' ? value : 70}
                                                                        onChange={handleSliderChange}
                                                                        aria-labelledby="input-slider" disabled
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <p className="points-left">{isRewardPointsBtnBool ? pointsLeft : points} pts left</p>
                                                            <div className="use-rewards">
                                                                <div>
                                                                    <p className="reward-points">Use reward points</p>
                                                                </div>
                                                                <div>
                                                                    <label className="switch">
                                                                        <input type="checkbox" onChange={balanceUpdateHandler}
                                                                            id="rewardPointsCheck"
                                                                        />
                                                                        <span className="slider round"></span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            }
                                            <div className="balance-holder d-flex justify-content-between">
                                                <div>
                                                    <p>Balance to be paid</p>
                                                    {
                                                        (isRewardPointsBtnBool && balance === 0) ? '' : <p>GST 5%</p>
                                                    }
                                                </div>
                                                <div className="text-right">
                                                    <p>Rs {!isRewardPointsBtnBool ? (packageData?.price).toFixed(2) : balance.toFixed(2)}</p>
                                                    {
                                                        (isRewardPointsBtnBool && balance === 0) ? '' : <p ><span>+</span> Rs {isRewardPointsBtnBool ? afterCheckedGST.toFixed(2) : initialAmount.toFixed(2)}</p>
                                                    }
                                                </div>
                                            </div>
                                            {
                                                (isRewardPointsBtnBool && balance === 0) ? null :
                                                    <div className="balance-holder payment-mode-checkbox" >
                                                        <p>Select payment mode</p>
                                                        <div >
                                                            <label for="DC" className={radiovalue === 'DC' ? 'border-blue' : ''}>
                                                                <div className="check-btns">
                                                                    <input type="radio" id="DC" name="payment_mode" value='DC'
                                                                        onChange={paymentModeChange}
                                                                        defaultChecked
                                                                    />
                                                                    <span class="checkmark"></span>
                                                                    <div className="checkBtn-info">
                                                                        <h5>Use Debit Card / UPI to pay</h5>
                                                                        <p>Select this payment option</p>
                                                                    </div>
                                                                </div>
                                                            </label>
                                                            <label for="CC" className={radiovalue === 'CC' ? 'border-blue' : ''}>
                                                                <div className="check-btns">
                                                                    <input type="radio" id="CC" name="payment_mode" value='CC'
                                                                        onChange={paymentModeChange}

                                                                    />
                                                                    <span class="checkmark"></span>
                                                                    <div className="checkBtn-info">
                                                                        <h5>Use Credit Card / Net Banking to pay</h5>
                                                                        <p>Select this payment option</p>
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        </div>

                                                    </div>
                                            }
                                        </div>) :
                                        (<div>
                                            <div className="non-corporate-holder">
                                                <div className="non-corporate-price total-cost">
                                                    <div className="total">
                                                        <p>Event amount</p>
                                                    </div>
                                                    <div className="amount">
                                                        <p>Rs {packageData?.price}</p>
                                                    </div>
                                                </div>
                                                <div className="line"></div>
                                                <div className="non-corporate-price total-price">
                                                    <div className="total">
                                                        <p className="mb-2">Amount to be paid</p>
                                                        <p>GST 5%</p>
                                                    </div>
                                                    <div className="amount text-right">
                                                        <p className="mb-2">Rs {(packageData?.price).toFixed(2)}</p>
                                                        <p><span>+</span> Rs {initialAmount.toFixed(2)}</p>
                                                    </div>
                                                </div>
                                                <p className="additional-charges textOfUser">Note :  Additional charges for extra adults/children.</p>
                                                <div className="line ml-0 mb-5 w-100"></div>
                                            </div>

                                            <div className="balance-holder payment-mode-checkbox" >
                                                <p>Select payment mode</p>
                                                <div >
                                                    <label for="DC" className={radiovalue === 'DC' ? 'border-blue' : ''}>
                                                        <div className="check-btns">
                                                            <input type="radio" id="DC" name="payment_mode" value='DC'
                                                                onChange={paymentModeChange}
                                                                defaultChecked
                                                            />
                                                            <span class="checkmark"></span>
                                                            <div className="checkBtn-info">
                                                                <h5>Use Debit Card / UPI to pay</h5>
                                                                <p>Select this payment option</p>
                                                            </div>
                                                        </div>
                                                    </label>
                                                    <label for="CC" className={radiovalue === 'CC' ? 'border-blue' : ''}>
                                                        <div className="check-btns">
                                                            <input type="radio" id="CC" name="payment_mode" value='CC'
                                                                onChange={paymentModeChange}

                                                            />
                                                            <span class="checkmark"></span>
                                                            <div className="checkBtn-info">
                                                                <h5>Use Credit Card / Net Banking to pay</h5>
                                                                <p>Select this payment option</p>
                                                            </div>
                                                        </div>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>)
                                }
                                <button type="button" className="btn proceed-to-book w-100" href onClick={handleCheckout}>
                                    {
                                        (isRewardPointsBtnBool && balance === 0) ? 'Pay with points' : 'Checkout'
                                    } </button>
                            </div>: 
                            <div className="price-holder" ref={myRef}>
                                <button type="button" className="btn proceed-to-book w-100" href onClick={handleCheckout}>
                                    Book Now</button>
                            </div>
                            }
                        </div>
                    </div>

                </div>

            </div>
            <ScrollToTop/>
            <Footer />
        </div>
        </>
    )
}

export default EventCheckout