import React from 'react'
import contact from '../../assets/images/corporates/corporatecontact.png';
const ContactFloatIngButton = ({ isFormInViewport, executeScrollToForm }) => {
    return (
        <>
            {!isFormInViewport &&
                <div className='floating-contact-button  flex-column align-items-center d-none d-md-flex' onClick={executeScrollToForm}>
                    <img src={contact} />
                    <p>Connect
                        with us</p>
                </div>
            }
        </>
    )
}

export default ContactFloatIngButton