import React, { useEffect, useState } from 'react';
import AutoSuggest from "react-autosuggest";
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import '../../assets/css/partials/common/searchLocationAndPriceRange.scss';
import * as CorporateAction from "../../redux/actions/corporatesAuthActions";
import { filterByPointsListing, searchByLocation } from '../../redux/actions/corporatesAuthActions';
import * as HolidaysAction from '../../redux/actions/holidaysActions';

const SearchLocationAndPriceRange = ({secName, fromHoliday, changeCategory, handleSearch, categoryID, handleLoader, fromCorporate, refreshPackages }) => {
    const LOCATIONS_LIST = useSelector(state => state.common?.all_locations_list?.data?.city_list);
    const COUNTRIES_LIST = useSelector(state => state.common?.corp_country_list?.data?.country_list);
    const searchTerm = useSelector((state) => state.corporates?.searchValue);
    const priceValues = useSelector((state) => state.corporates?.filterValues);
    const catId = useSelector((state) => state.corporates?.cat_id);
    const userDetails = JSON.parse(localStorage.getItem("userData"));
    const isCorporateAccount = userDetails?.user_details?.corporate_account;
    const [searchInput, setSearchInput] = useState("");
    // const [value, setValue] = useState("");
    
    const [suggestions, setSuggestions] = useState([]);
    const [btnDisable, setButtonDisable] = useState(false);
    const [inputDisabled, setInputDisabled] = useState(false);
    const [defaultPrice, setDefaultPrice] = useState("Price")
    const [clearable, setClearable] = useState(false)
    const [state, setState] = useState({
        minVal: "",
        maxVal: "",
    });
    let minVal;
    let maxVal;
    const dispatch = useDispatch();
    const companyDomain = userDetails?.organisation?.email_domain;
    const isWipro = (companyDomain === "wipro.com");
    const isInternational = secName === "International"
    const [dropdownValue, setDropdownValue] = useState("Price");
    const filterOptions = [
        {
            key: '0k - 5k pts',
            text: (isCorporateAccount && isWipro) ? 'less than 5k pts' : 'less than Rs 5000',
            value: '0k - 5k pts',
        },
        {
            key: '5k - 10k pts',
            text: (isCorporateAccount && isWipro) ? '5k - 10k pts' : 'Rs 5000 - 10000',
            value: '5k - 10k pts',
        },
        {
            key: '10k - 15k pts',
            text: (isCorporateAccount && isWipro) ? '10k - 15k pts' : 'Rs 10000 - 15000',
            value: '10k - 15k pts',
        },
        {
            key: 'more than 15k pts',
            text: (isCorporateAccount && isWipro) ? 'more than 15k pts' : 'more than Rs 15000',
            value: 'more than 15k pts',
        },
    ]


    const filterByPoints = (e, data) => {
        if(!data.value && document.querySelector('.price-range .menu').classList.contains('visible')){
            document.querySelector('.price-range .ui.selection.dropdown').click()
        }
        setClearable(true)
        if (data.value === '0k - 5k pts') {
            state.minVal = 0;
            state.maxVal = 5000
            setDropdownValue(e.target.value)
            setClearable(true)
        }
        else if (data.value === '5k - 10k pts') {
            state.minVal = 5000;
            state.maxVal = 10000
            setDropdownValue(e.target.value)
            setClearable(true)
        }
        else if (data.value === '10k - 15k pts' ) {
            state.minVal = 10000;
            state.maxVal = 15000;
            setDropdownValue(e.target.value)
            setClearable(true)
        }
        else if (data.value === 'more than 15k pts' ){
            state.minVal = 15000;
            state.maxVal = 1000000;
            setDropdownValue(e.target.value)
            setClearable(true)
        }else{
            setDropdownValue((isCorporateAccount && isWipro) ? "Points" : "Price")
            dispatch(CorporateAction.getFilterValues(""))
            setClearable(false)
            if(searchTerm == ""){
                // console.log("getCorporatePackages clear")
                dispatch(CorporateAction.getCorporatePackages(categoryID, 1, handleLoader));
            }else{
                // console.log("clear with location")
                dispatch(filterByPointsListing(categoryID, searchTerm, "", "", 1, handleLoader));
            }
        }

        if (fromHoliday) {

            dispatch(HolidaysAction.searchByLocation(searchInput, state.minVal, state.maxVal));

        } else if (fromCorporate && e.target.innerHTML !== "" ) {
            // console.log('filterByPointsListing')
            dispatch(CorporateAction.getFilterValues(state))
            dispatch(filterByPointsListing(categoryID, searchTerm, state.minVal, state.maxVal, 1, handleLoader));
            
        } else {
            return null
        }


    }

    useEffect(() => {
        setSearchInput("")
        // setButtonDisable(true)
        // setInputDisabled(false)
        if(isInternational){
            dispatch(CorporateAction.getCountryList(categoryID)); 
        }
    }, [categoryID, secName])
    useEffect(()=>{
        if(categoryID&&!isInternational){
            dispatch(CorporateAction.getLocationsList(categoryID));
        }
    },[categoryID])

    const getlocationslist = LOCATIONS_LIST && LOCATIONS_LIST.length > 0 && LOCATIONS_LIST.map(item => {
        return {
            id: item.id,
            name: item.city.toLowerCase()
        };
    });
    const getcountrieslist = COUNTRIES_LIST && COUNTRIES_LIST.length > 0 && COUNTRIES_LIST.map(item => {
        return {
            id: item.id,
            name: item.country.toLowerCase()
        };
    });
    

    const searchByLocationHanler = (e) => {
        e.preventDefault();
        if (fromCorporate) {
            handleSearch();
        }

        if (fromHoliday) {
            dispatch(HolidaysAction.searchByLocation(searchInput, state.minVal, state.maxVal));
        }
        else if (fromCorporate) {
            // console.log('searchByLocation')
            dispatch(searchByLocation(categoryID, searchTerm, priceValues.minVal, priceValues.maxVal, 1, handleLoader));

        }
    }

    function getSuggestions(value) {
        setSearchInput(value)
        dispatch(CorporateAction.getSearchValue(value))
        if(isInternational){
            return getcountrieslist && getcountrieslist.filter(item =>
                item.name.startsWith(value.trim().toLowerCase())
            );
        }else{
            return getlocationslist && getlocationslist.filter(item =>
                item.name.startsWith(value.trim().toLowerCase())
            );
        }
    }

    useEffect(() => {
        // if (fromHoliday) {
        //     dispatch(HolidaysAction.getLocationsList());
        // }
        // else {
        //     dispatch(CorporateAction.getLocationsList(categoryID));
        // }
        // if (value == "") {
        //     refreshPackages()
        // }


    }, []);
    
    useEffect(() => {
        if(searchTerm == ""){
            setButtonDisable(true)
            setInputDisabled(false)
        }else{
            setButtonDisable(false)
        }
    }, [searchTerm])
    
// }, [searchInput]);


    const handleClearInput = () => {
        setInputDisabled(false)
        setButtonDisable(true)
        dispatch(CorporateAction.getSearchValue(""))
        // setValue("")
        if(priceValues.minVal == undefined && priceValues.maxVal == undefined){
            // console.log("listing")
            dispatch(CorporateAction.getCorporatePackages(categoryID, 1, handleLoader));
        }else {
            // console.log("fliter with points only")
            dispatch(filterByPointsListing(categoryID, "", priceValues.minVal, priceValues.maxVal, 1, handleLoader));
        }

    }

    useEffect(() => {
        // setValue(searchTerm);
    
        if(priceValues){
            setClearable(true)
            if(priceValues.minVal == '0' && priceValues.maxVal == '5000'){
                setDropdownValue((isCorporateAccount && isWipro) ? `less than 5k pts` : `less than Rs 5000`);
            } else if(priceValues.minVal == '5000' && priceValues.maxVal == '10000'){
                setDropdownValue((isCorporateAccount && isWipro) ? ` 5k - 10k pts`:`Rs 5000 - 10000`);
            } else if(priceValues.minVal == '10000' && priceValues.maxVal == '15000'){
                setDropdownValue((isCorporateAccount && isWipro) ? ` 10k - 15k pts`:`Rs 10000 - 15000`);
            } 
            else {
                setDropdownValue((isCorporateAccount && isWipro) ? `more than 15k pts`: `more than Rs 15000 `);
            }
        }else{
            
            setDropdownValue((isCorporateAccount && isWipro) ? "Points" : "Price")
            setClearable(false)
        }
      

        if(searchTerm !== ""){
            setButtonDisable(false)
            setInputDisabled(true)
        }

        setState({ minVal: "", maxVal: "" })
        return(() => {
            setDropdownValue("")
        })
    }, [changeCategory])

    return (
        <div className="search-location-price-range" >
            <div className="search-location">
                <form className="form-inline inline-search-mobi" id="form-rest" onSubmit={searchByLocationHanler}>
                    <div className="form-group mb-0" style={{ flexGrow: 1 }}>
                        <div className={searchTerm ? "input-wrapper" : ""}>
                            <div class="buttonIn">
                                <AutoSuggest
                                    suggestions={suggestions}
                                    onSuggestionsClearRequested={() => setSuggestions([])}
                                    onSuggestionsFetchRequested={({ value }) => {
                                        // setValue(value);
                                        dispatch(CorporateAction.getSearchValue(value))
                                        setSuggestions(getSuggestions(value));
                                    }}
                                    onSuggestionSelected={(_, { suggestionValue }) => {
                                        setSuggestions(getSuggestions(suggestionValue));

                                    }
                                    }
                                    getSuggestionValue={suggestion => suggestion.name}
                                    renderSuggestion={suggestion => <span className="sashsja" 
                                    // onClick={() => setInputDisabled(!inputDisabled)}
                                    >{suggestion.name}</span>}
                                    inputProps={{
                                        placeholder: "What's on your mind ?",
                                        // value: value,
                                        value: searchTerm,
                                        disabled: inputDisabled,
                                        onChange: (_, { newValue, method }) => {
                                            // setValue(newValue);
                                            dispatch(CorporateAction.getSearchValue(newValue))
                                            if (newValue !== "") {
                                                setButtonDisable(false)
                                            } else {
                                                setButtonDisable(true)
                                                setSearchInput("")
                                            }

                                        }
                                    }}
                                    highlightFirstSuggestion={true}
                                />
                                {btnDisable ? "" : <span id="clear" className="clear-button" onClick={handleClearInput}>x</span> }
                            </div>
                        </div>
                    </div>
                    {
                        btnDisable ? <button type="submit" className="btn btn--search ml-2" disabled>Search</button>
                            :
                            <button type="submit" className="btn btn--search ml-2" onClick={() => setInputDisabled(true)}>Search</button>
                    }
                </form>
            </div>
            {!isInternational ? 
            <div className="price-range" >
            
                <Dropdown className="dropdown--btn"
                    placeholder={(isCorporateAccount && isWipro) ? 'Points' : 'Price'}
                    fluid
                    selection
                    clearable={clearable}
                 
                    text={dropdownValue}
                    selectOnBlur={false}
                    options={filterOptions}
                    onChange={(e, data) => filterByPoints(e, data)}
                    value={dropdownValue}
                />
            </div>: null
            }
        </div>
    )
}

export default SearchLocationAndPriceRange;
