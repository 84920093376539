import React, { useState, useEffect } from 'react';
import AutoSuggest from "react-autosuggest";
import * as HomeAction from '../../redux/actions/homeAction'
import { useDispatch, useSelector } from "react-redux";
import '../../assets/css/partials/common/globalSearch.scss'
import { useHistory } from 'react-router';
import searchIcon from '../../assets/images/corporates/search.svg'
import searchIconWhite from '../../assets/images/corporates/search-white.svg'
import searchIconBlack from '../../assets/images/corporates/search-black.svg'
import backIcon from "../../assets/images/common/back-icon.svg";
import 'animate.css';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match'
import AutosuggestHighlightParse from 'autosuggest-highlight/parse'


const GlobalSearch = ({ isWhiteIcon, className, onBlur, initFocus, enableGlobalSearch, handleSearchValue, fromHome, fromSearchPage }) => {
  const [suggestions, setSuggestions] = useState([])

  const [suggestionsList, setSuggestionsList] = useState([]);
  const history = useHistory()
  const [value, setValue] = useState("")
  const dispatch = useDispatch();
  const loginUserData = useSelector((state) => state.auth.login_user_otp);
  const SUGGESTIONS_LIST = useSelector((state) => state.homepage.search_suggestions_list);
  const languages = [
    {
      name: 'C',
      year: 1972
    },
    {
      name: 'Elm',
      year: 2012
    },
  ];

  useEffect(() => {

    let getSuggestionsList = SUGGESTIONS_LIST && SUGGESTIONS_LIST.length > 0 && SUGGESTIONS_LIST.map(item => {

      return {
        pack_title: item.pack_title,
        location_city: item.location_city,
        location__city: item.location__city,
        location_country: item.location_country,
        location__state: item.location__state,
        module: item.module,
        pack_slug: item.module == "events" ? item.event__slug : item.pack_slug,
        main_slug: item.module == "experience" ? item.experience_slug : item.module == "holidays" ? item.holiday_slug : item.corporate_slug,
        id: item.id,
        location: item.location,
        sectionName: item?.holiday_section_name || item?.corp_section_name || item?.experience_section_name
      };
    });
    setSuggestions(getSuggestionsList);
  }, [SUGGESTIONS_LIST]);
  useEffect(() => {
    if (!value) {
      setSuggestions([]);
    }
    if (value) {
      const getSuggestions=setTimeout(()=>{
      dispatch(HomeAction.getSearchSuggestions(value, loginUserData?.corporate_account || false));
    },250)
    return () => clearTimeout(getSuggestions)
    }
    
  }, [value]);

  useEffect(() => {
    setValue('');
    history.listen(() => setValue(''));
  }, [history, loginUserData])

  //   const getSuggestions = value => {
  //     // setSearchInput(value)
  //     // dispatch(CorporateAction.getSearchValue(value))
  //     let tmpSuggList = [...suggestionsList];
  //     return tmpSuggList && tmpSuggList.filter(item =>{
  //         return(
  //             {
  //                 pack_title: item.pack_title,
  //                 location_city: item.location_city,
  //                 location__city: item.location__city,
  //                 location__state: item.location__state,
  //                 location_country:item.location_country,
  //                 module: item.module,
  //                 pack_slug: item.module == "events" ? item.slug : item.pack_slug
  //             }
  //         )
  //     }


  //     );
  //   };
  const openLink = (link) => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {

      history.push(link)
    } else {

      window.open(link, "_blank");
    }
  }

  const handlePackageDetails = (suggestion) => {


    document.querySelector(".react-autosuggest__input").blur()

    if (suggestion.module == "experience") {
      // localStorage.setItem("filteredList", true)
      // history.push(`/experiences/experience-details/${suggestion.pack_slug}`)
      openLink(`/experiences/experience-details/${suggestion?.main_slug}?isPack=true`);
      localStorage.setItem("activePackageId", suggestion?.id)
    } else if (suggestion.module == "corporate") {
      // localStorage.setItem("filteredList", true)
      // history.push(`/corporates/package-details/${suggestion.pack_slug}`)
      openLink(`/corporates/package-details/${suggestion.main_slug}?isPack=true`);
      localStorage.setItem("activePackageId", suggestion?.id)
    } else if (suggestion.module == "events") {
      // history.push(`/events/event-details/${suggestion.pack_slug}`)
      openLink(`/events/event-details/${suggestion.pack_slug}`);
    } else if (suggestion.module == "holidays") {
      // localStorage.setItem("filteredList", true)
      // history.push(`/holidays/holiday-details/${suggestion.pack_slug}`)
      openLink(`/holidays/holiday-details/${suggestion?.main_slug
        }?isPack=true`);
      localStorage.setItem("activePackageId", suggestion?.id)
    }


  }



  useEffect(() => {
    if (initFocus) {
      document.querySelector('.mobile-search .react-autosuggest__input').focus()
    }
  }, []);
  const focusInput = () => {
    document.querySelector('.react-autosuggest__input').focus()
  };
  const handleClearInput = () => {
    setValue("")
    if (fromHome || fromSearchPage) {
      handleSearchValue("")
    }
  }
  const renderLocation = (location) => {
    if (location.length === 1) {

      return `${location[0].city},${location[0].state}`
    }

    else {

      return `${location[0].city},${location[1].address}`
    }
  }

  return (
    <div className={`globalSearch animated_only_mobile animate__animated animate__lightSpeedInLeft animate_faster ${className && className} ${(value && fromHome) ? 'padding' : ''}`} >
      {/* animated_only_mobile animate__animated animate__lightSpeedInLeft animate_faster */}
      {enableGlobalSearch && <span className="search-icon-mobile " onClick={focusInput}>
        <img src={searchIconBlack} alt="search-icon"/>
      </span>}
      <AutoSuggest
        suggestions={suggestions}
        onSuggestionsClearRequested={() => setSuggestions([])}

        onSuggestionsFetchRequested={({ value }) => {


          // setSuggestions(getSuggestions(value));
        }}
        onSuggestionSelected={(_, { suggestionValue, suggestion }) => {
          // setSuggestions(getSuggestions(suggestion.pack_title));

          handlePackageDetails(suggestion)
          setValue('');

        }}
        getSuggestionValue={suggestion => suggestion?.pack_title}
        shouldRenderSuggestions={(value, reason) => {

          if (value && reason === 'input-focused') {
            dispatch(HomeAction.getSearchSuggestions(value, loginUserData?.corporate_account || false));
          }
          if (value) {
            return true
          }
        }}
        // alwaysRenderSuggestions={()=>true}
        // renderSuggestion={suggestion => {
        // return (
        // <div 
        // // onClick={() => {  handlePackageDetails(suggestion) }
        // >
        // <div className="title" >
        //     <span>{suggestion.pack_title}</span>
        // </div>
        // <div className="info">
        // {/* suggestion.location_country */}
        //     <p>{suggestion.location_city} {suggestion.location_city ? '.' : null} {suggestion.location__city} {suggestion.location__city ? '.' : null} <span>{suggestion.location__state}</span> <span>{suggestion.location_country}</span></p>
        // </div>
        // </div>
        // )}}

        renderSuggestion={(suggestion, { query, isHighlighted }) => {

          const matches = AutosuggestHighlightMatch(suggestion.pack_title, query);
          const parts = AutosuggestHighlightParse(suggestion.pack_title, matches);
          // console.log(document.querySelector("li[aria-selected='true']"));

          return (
            <span className={`suggestion-content ${isHighlighted ? 'sugg-highlighted' : ''}`}>
              <span className="name">
                <div
                >
                  <div className="title" >
                    <span>{
                      parts.map((part, index) => {
                        const className = part.highlight ? 'highlight' : null;

                        return (
                          <span className={className} key={index}>{part.text}</span>
                        );
                      })
                    }</span>
                  </div>
                  <div className="info">
                    <p>
                      {suggestion.sectionName !== "International" && (suggestion?.location?.length > 1 ? `${(suggestion?.location_city || suggestion?.location[0]?.city)}. ${suggestion?.location[1]?.address}` :
                        `${(suggestion?.location_city || suggestion?.location[0]?.city)}${(suggestion?.location[0]?.state) ? '. ' : null}`)

                      }<span>{suggestion.sectionName !== "International" &&suggestion?.location?.length ===1&& suggestion?.location[0]?.state ? suggestion?.location[0]?.state : ''}</span>
                      <span>{suggestion.sectionName === "International" && suggestion?.location_country}</span>
                    </p>

                  </div>
                </div>

              </span>
            </span>
          );
        }}



        inputProps={{
          placeholder: "What's on your mind ?",
          value: value,
          onBlur: () => { onBlur && onBlur() },
          onKeyPress: (e) => {
            if (e.key === "Enter" && !fromSearchPage) {
              if (SUGGESTIONS_LIST.length !== 0) {
                dispatch(HomeAction.setGlobalSearchVal(value))
                localStorage.setItem('sec_name', 'see all')
                history.push('/search')
                setValue(value)
              }
            }
          },
          onChange: (_, { newValue, method }) => {

            // loginUserData &&
            // loginUserData.corporate_account

            if (fromHome || fromSearchPage) {
              handleSearchValue(newValue)
            }
            if (['down', 'up'].indexOf(method) === -1) {
              setValue(newValue);
              
            } else {
              // document.querySelector('.sugg-highlighted')?.scrollIntoView({ behavior: 'smooth', block: 'end' })

              // document.querySelector('.react-autosuggest__suggestions-list').scrollTop = document.querySelector('.sugg-highlighted')?.offsetTop;

              document.querySelector('.react-autosuggest__suggestions-list').scrollTop = document.querySelector('.sugg-highlighted')?.offsetTop - document.querySelector('.react-autosuggest__suggestions-list')?.offsetTop + 20;
            }



          }
        }}
      />
      {value && fromHome ? <span id="clear" className="clear-button" onClick={handleClearInput}>x</span> : null}
      {!enableGlobalSearch && <span>
        {isWhiteIcon ? <span className="search_icon"><img src={searchIconWhite} alt="search-icon"/></span>
          : <span className="search_icon">{value || fromSearchPage ? null : <img src={searchIcon} alt="search-icon"/>}</span>}
      </span>}

    </div>
  )
}

export default GlobalSearch
