import React, { useEffect, useRef, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import "../../assets/css/partials/sliders.scss";
import * as homeAction from "../../redux/actions/homeAction";
import { options } from "../../resources/carouselOptions";
import { useSnackbar } from "notistack";
import * as CorporateAction from '../../redux/actions/corporatesAuthActions';


function CorporateSlider() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loader, setLoader] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const handleRoute = (route) => {
    dispatch(CorporateAction.getSearchValue(""))
    dispatch(CorporateAction.getFilterValues(""))
    dispatch(homeAction.setScroll(true));
    // history.push(`/${route}`);
    history.push({ pathname: `/${route}`, seeAll: {bool: true}});
    window.scroll(0,0)
  };
  const CORPORATE_LIST = useSelector(
    (state) => state.homepage.corporateList.data
  );
  const SCROLL_REF = useSelector((state) => state.homepage.set_Scroll);
  
  useEffect(() => {
    dispatch(homeAction.corporateHomepagePackages(handleLoader));
    if (SCROLL_REF) {
      executeScroll();
    }
  }, [SCROLL_REF]);

  // useEffect(()=>{
  //   setTimeout(()=>{
  //     let prevUrl=localStorage.getItem('prevUrl');
  //     if(prevUrl?.length){
  //       if(prevUrl?.split('/').slice(0, -1).join('/')==='/corporates/package-details'){
  //         myRef.current.scrollIntoView();
  //       }
  //     }
  //   });
    
  // }, []);
  const myRef = useRef(null)
  const executeScroll = () => {
    myRef.current.scrollIntoView();
    dispatch(homeAction.setScroll(false));
  }

  const handleLoader = () => {
    setLoader(false);
  };

  const goToPackageDetails = (id) => {
    let userData = localStorage.getItem("userData")
    let userObj = JSON.parse(userData)
    dispatch(homeAction.setScroll(true));
    const user_details = userObj?.user_details;
    if (user_details && !user_details?.corporate_account) {
      history.push(`/corporates`);
      enqueueSnackbar("Please login with your corporate account", {
        variant: "info",
      });
      window.scrollTo(0, 0);
    }else if(user_details?.corporate_account){
      localStorage.removeItem("filteredList")
      history.push(`/corporates/package-details/${id}`);
    }
    else {
      history.push(`/corporates`);
      window.scrollTo(0, 0);
    }
  };

  return (
    <div className="slider-holder" ref={myRef}>
      <div className="container">
        <h4>
        Offers for <span>Corporate</span> employees
        </h4>
      </div>
      {!loader ? (
        <div className="carousel-wrapper carousel-mobi-wrapper">
          <OwlCarousel className="owl-theme" {...options}>
            {CORPORATE_LIST &&
              CORPORATE_LIST.length > 0 &&
              CORPORATE_LIST.map((item,idx) => (
                // <Link to={`/corporates/package-details/${item.corp_id}`}>
                <div className="item cursor-pointer" onClick={() => goToPackageDetails(item.corp_slug)} key={idx}>
                  <div className="card-wrapper" >
                    <img src={item.image} alt={item.pack_title} />
                    <div className="info">
                      <p>{item.pack_title}</p>
                     { 
                      //  <p>{item.points} pts</p>
                      }
                    </div>
                  </div>
                </div>
                //  </Link>
              ))}
              {
                CORPORATE_LIST?.length > 3 ? 
                <div className="item">
                <div
                  className="card-wrapper see-all"
                  onClick={() => handleRoute("corporates")} >
                  <p>  See all <br />
                    Corporate packages </p>
                </div>
              </div> : null
              }
           
          </OwlCarousel>
        </div>
      ) : null}
    </div>
  );
}

export default CorporateSlider;
