import React, { useState } from 'react';
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import AddIcon from '../../../assets/images/common/feather_plus.svg';
import CloseIcon from '../../../assets/images/common/close-modal.svg';
import * as ProfileAction from '../../../redux/actions/profileAction';

function AddressModal() {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const userInfo = JSON.parse(localStorage.getItem("userData"));
    const isCorporateAccount = userInfo?.user_details?.corporate_account;
    
    const [show, setShow] = useState(false);
    const [state, setState] = useState({
        title: "",
        street: "",
        city: "",
        state: "",
        pincode: "",
    });

    const handleChange = (key, value) => {
        setState(() => ({
            ...state,
            [key]: value.target.value,
        }));
    };
    const [error, seterror] = useState({
        title: false,
        street: false,
        city: false,
        state: false,
        pincode: false,
    })
    const handleClose = () => {
        setShow(false);
        setState({
            title: "",
            street: "",
            city: "",
            state: "",
            pincode: "",
        })
    } 
    const handleShow = () => setShow(true);

    const addAddress = (e) => {
        e.preventDefault();
        if (!state.title || state.title === "") {
            seterror({ title: true })
        }
        else if (!state.street || state.street === "") {
            seterror({ street: true })
        }
        else if (!state.city || state.city === "") {
            seterror({ city: true })
        }
        else if (!state.state || state.state === "") {
            seterror({ state: true })
        }
        else if (!state.pincode || state.pincode === "" ) {
            seterror({ pincode: true })
        }
        else {
            dispatch(ProfileAction.addUserAddress(state, handleSuccess, handleErrors));
            handleClose()
        }
    }

    const handleSuccess = () => {
        setState({
            title: "",
            street: "",
            city: "",
            state: "",
            pincode: "",
        });
        enqueueSnackbar("Address added successfully", {
            variant: "success",
        });
        refreshProfileDetails();
    };

    const handleErrors = (message) => {
        enqueueSnackbar(message, {
            variant: "error",
        });
    };

    const refreshProfileDetails = () => {
        if(isCorporateAccount){
            dispatch(ProfileAction.getProfileDetails());
        }
        else {
            dispatch(ProfileAction.getNonCorporateProfileDetails())
        }
    }


    return (
        <div >
            <div className="cursor-pointer" onClick={handleShow}>
                <img src={AddIcon} alt="" />
            </div>
            <Modal className="custom-modal mob-custom-modal" show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>Adding an address</Modal.Title>
                    <div className="cursor-pointer" onClick={handleClose}>
                        <img src={CloseIcon} alt="" />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div class="form-group custom-input">
                            <input type="text" className="form-control" value={state.title} name="title" onChange={(value) => handleChange("title", value)}
                            onKeyPress={(event) => {
                                if (!/[a-zA-Z\s]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            />
                            <label>Name of address (Ex: Home or Office)</label>
                            {error.title === true ? <p className="errorMsg">Name of address required</p> : null}
                        </div>
                        <div class="form-group custom-input">
                            <textarea type="text" className="form-control" value={state.street} onChange={(value) => handleChange("street", value)} ></textarea>
                            <label>Street</label>
                            {error.street === true ? <p className="errorMsg">Street required</p> : null}
                        </div>
                        <div class="form-group custom-input d-flex">
                            <div className="mr-2">
                                <input type="text" className="form-control" value={state.city} onChange={(value) => handleChange("city", value)}
                                onKeyPress={(event) => {
                                    if (!/[a-zA-Z\s]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                />
                                <label>City</label>
                                {error.city === true ? <p className="errorMsg">City required</p> : null}
                            </div>
                            <div>
                                <input type="text" className="form-control" value={state.state} onChange={(value) => handleChange("state", value)} 
                                onKeyPress={(event) => {
                                    if (!/[a-zA-Z\s]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                />
                                <label>State</label>
                                {error.state === true ? <p className="errorMsg">State required</p> : null}
                            </div>
                            <div className="ml-2">
                                <input type="text" className="form-control" value={state.pincode} onChange={(value) => handleChange("pincode", value)}
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }} 
                                />
                                <label>Pincode</label>
                                {error.pincode === true ? <p className="errorMsg">Enter a valid  Pincode</p> : null}
                            </div>
                        </div>
                        <div className="text-right mt-3">
                            <Button className="btn btn-done" onClick={addAddress}>
                                Done
                         </Button>
                        </div>
                    </form>
                </Modal.Body>

            </Modal>
        </div>
    )
}

export default AddressModal
