export const LOGIN_USER = "LOGIN_USER"
export const MOBILE_NUMBER_ALREADY_EXIST = "MOBILE_NUMBER_ALREADY_EXIST"
export const OTP_SENT_TO_MESSAGE = "OTP_SENT_TO_MESSAGE"
export const SIGNUP_OTP_SENT_TO_MESSAGE = "SIGNUP_OTP_SENT_TO_MESSAGE"
export const LOGIN_USER_ID = "LOGIN_USER_ID"
export const LOGIN_USER_OTP = "LOGIN_USER_OTP"
export const LOGIN_USER_NOT_EXIST_ERROR_MESSAGE = "LOGIN_USER_NOT_EXIST_ERROR_MESSAGE"
export const GET_EMAIL = "GET_EMAIL"
export const SHOW_OTP_MODAL_FALSE = "SHOW_OTP_MODAL_FALSE"
export const LOGIN_RECIEVED_OTP = "SIGN_UP_USER_OTP"
export const SIGN_UP = "SIGN_UP"
export const SIGNUP_EMAIL = "SIGNUP_EMAIL"
export const SIGN_UP_USER_OTP = "SIGN_UP_USER_OTP"
export const SIGN_UP_RECIEVED_OTP = "SIGN_UP_USER_OTP"
export const SIGN_UP_USER_ID = "SIGN_UP_USER_ID"
export const IS_CORP = "IS_CORP"
export const LOGIN_WITH_GOOGLE = "LOGIN_WITH_GOOGLE"
export const GOOGLE_ERROR_MESSAGE = "GOOGLE_ERROR_MESSAGE"
export const LOGIN_WITH_FACEBOOK = "LOGIN_USER_WITH_GOOGLE"
export const SIGNUP_USER_EXIST_ERROR_MESSAGE = "SIGNUP_USER_EXIST_ERROR_MESSAGE"
export const CORP_OTP_SEND_MESSAGE = "CORP_OTP_SEND_MESSAGE"
export const LOGIN_INVALID_OTP_ERROR_MESSAGE = "LOGIN_INVALID_OTP_ERROR_MESSAGE"
export const SIGNUP_INVALID_OTP_ERROR_MESSAGE = "SIGNUP_INVALID_OTP_ERROR_MESSAGE"
export const CORPORATES_LOGIN = "CORPORATES_LOGIN"
export const CORPORATES_LOGIN_USER_ID = "CORPORATES_LOGIN_USER_ID"
export const CORPORATES_CATEGORY = "CORPORATES_CATEGORY";
export const CORPORATES_PACKAGES = "CORPORATES_PACKAGES"
export const CATEGORY_ID = "CATEGORY_ID";
export const INITIAL_CATEGORY_ID = "INITIAL_CATEGORY_ID";
export const SET_TOKEN = "SET_TOKEN";
export const CORPORATES_PAGE_NUM="CORPORATES_PAGE_NUM";
export const CORPORATES_PACKAGES_LIST="CORPORATES_PACKAGES_LIST";
export const CORPORATE_PACKAGE_LIST_RESET="CORPORATE_PACKAGE_LIST_RESET";
export const CORPORATE_PACKAGE_RESET="CORPORATE_PACKAGE_RESET";
export const RESET_CORP_LIST="RESET_CORP_LIST";
export const GET_SEARCH_VALUE="GET_SEARCH_VALUE";
export const FILTER_VALUES="FILTER_VALUES";
export const HOLIDAY_FILTER_VALUES = "HOLIDAY_FILTER_VALUES";
export const SEARCH_SUGGESTIONS = "SEARCH_SUGGESTIONS";
export const GLOBAL_SEARCH_SUGGESTIONS = "GLOBAL_SEARCH_SUGGESTIONS";

export const CORPORATE_PACKAGE_DETAILS = "CORPORATE_PACKAGE_DETAILS";
export const CORPORATE_PACKAGE_GIFTS_DETAILS = "CORPORATE_PACKAGE_GIFTS_DETAILS";
export const PACKAGE_START_DATE = "PACKAGE_START_DATE";
export const PACKAGE_END_DATE = "PACKAGE_END_DATE";
export const PACKAGE_FLEXIBLE_DATES = "PACKAGE_FLEXIBLE_DATES";
export const GUEST_DATA = "GUEST_DATA";
export const BOOKING_INFORMATION_DATA = "BOOKING_INFORMATION_DATA";
export const CHANGE_DATA_FOR_PROCEED_TO_BOOK = "CHANGE_DATA_FOR_PROCEED_TO_BOOK";
export const NEAR_BY_EXPERIENCES="NEAR_BY_EXPERIENCES";
export const NEAR_BY_PACKAGES="NEAR_BY_PACKAGES";
export const MORE_PACKAGES="MORE_PACKAGES";
export const CORPORATE_HOMEPAGE_LIST = "CORPORATE_HOMEPAGE_LIST";
export const EXPERIENCE_HOMEPAGE_LIST="EXPERIENCE_HOMEPAGE_LIST";
export const HOLIDAY_HOMEPAGE_LIST= "HOLIDAY_HOMEPAGE_LIST";
export const SET_SCROLL_CORPORATES="SET_SCROLL_CORPORATES";
export const SET_SCROLL_VIX="SET_SCROLL_VIX";
export const PAGE_SIZE="PAGE_SIZE";
export const CORPORATE_TESTIMONIALS="CORPORATE_TESTIMONIALS";
export const CORPORATE_REVIEW_REFERENCE_ID = "CORPORATE_REVIEW_REFERENCE_ID";
export const CORPORATE_REVIEW_POST = "CORPORATE_REVIEW_POST";
export const CORPORATE_REVIEW_LIST = "CORPORATE_REVIEW_LIST";
export const INTERNAL_PACK_DATA ="INTERNAL_PACK_DATA"
export const CORPORATE_REVIEWLIST_TOTAL_PAGE="CORPORATE_REVIEWLIST_TOTAL_PAGE"
export const CORPORATE_REVIEWLIST_CURRENT_PAGE="CORPORATE_REVIEWLIST_CURRENT_PAGE"
export const CORPORATE_ACTIVE_PACKAGE_INDEX="CORPORATE_ACTIVE_PACKAGE_INDEX"
// user profile
export const USER_PROFILE_DETAILS = "USER_PROFILE_DETAILS";
export const USER_BOOKING_DETAILS = "USER_BOOKING_DETAILS";
export const NON_CORP_USER_PROFILE_DETAILS = "NON_CORP_USER_PROFILE_DETAILS";
export const UPDATED_MOBILE_NUMBER = "UPDATED_MOBILE_NUMBER";
export const UPDATE_USER_DEATILS_MESSAGE = "UPDATE_USER_DEATILS_MESSAGE";
export const INVALID_OTP_MESSAGE_PROFILE = "INVALID_OTP_MESSAGE_PROFILE";
//
export const CHECKOUT_DETAILS = "CHECKOUT_DETAILS";
export const INTERNAL_CHECKOUT_DETAILS = "INTERNAL_CHECKOUT_DETAILS";
export const BOOKING_STATUS = "BOOKING_STATUS";
export const SIMILAR_GIFTS = "SIMILAR_GIFTS";
export const DATA_CHANGED_BOOL_VAL = "DATA_CHANGED_BOOL_VAL";

// 
export const ALL_LOCATIONS_LIST = "ALL_LOCATIONS_LIST";
export const ALL_COUNTRY_LIST = "ALL_COUNTRY_LIST";
export const CORP_COUNTRY_LIST = "CORP_COUNTRY_LIST";
//my famaily first schedule 
export const PLANNING_START_DATE = "PLANNING_START_DATE";
export const PLANNING_END_DATE = "PLANNING_END_DATE";
export const STORIES_LIST = "STORIES_LIST";
export const HONEYMOON_DESTINATION_LIST="HONEYMOON_DESTINATION_LIST"
export const HONEYMOON_STORIES_LIST="HONEYMOON_STORIES_LIST"
export const MY_FAMILY_CATEGORY_ID="MY_FAMILY_CATEGORY_ID"
export const ANNIVERSARY_STORIES_LIST="ANNIVERSARY_STORIES_LIST"

//My Weddings
export const WEDDING_STORIES_LIST = "WEDDING_STORIES_LIST"
export const WEDDING_DESTINATIONS = "WEDDING_DESTINATIONS"
export const BOOK_WEDDINGS = "BOOK_WEDDINGS"

//edited name and number
export const EDITED_GIFTVOUCHER_NAME = "EDITED_GIFTVOUCHER_NAME"
export const EDITED_GIFTVOUCHER_NAME_BOOL = "EDITED_GIFTVOUCHER_NAME_BOOL"
export const EDITED_GIFTVOUCHER_NUMBER = "EDITED_GIFTVOUCHER_NUMBER"
export const EDITED_GIFTVOUCHER_NUMBER_BOOL = "EDITED_GIFTVOUCHER_NUMBER_BOOL"
export const EDITED_CORP_HOLIDAY_NAME = "EDITED_CORP_HOLIDAY_NAME"
export const EDITED_CORP_HOLIDAY_NAME_BOOL = "EDITED_CORP_HOLIDAY_NAME_BOOL"
export const EDITED_CORP_HOLIDAY_NUMBER = "EDITED_CORP_HOLIDAY_NUMBER"
export const EDITED_CORP_HOLIDAY_NUMBER_BOOL = "EDITED_CORP_HOLIDAY_NUMBER_BOOL"

export const SET_SCROLL="SET_SCROLL"
export const SET_GLOBAL_SEARCH_VAL="SET_GLOBAL_SEARCH_VAL"