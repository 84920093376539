import React, { useState, useEffect } from "react";

import SubscripeModel from "./subscripeModel";

export default function Subscribe() {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className="container">
        <div className="subscribe-holder">
          <div className="heading">
            <h1>Subscribe to our Newsletter</h1>
            <p>Be the first to know when we add new properties.</p>
          </div>
          <div className="subscribe-button" onClick={() => setShowModal(true)}>
            <span>Subscribe</span>
          </div>
        </div>
      </div>
      <SubscripeModel showModal={showModal} setShowModal={setShowModal} />
    </>
  );
}
