import React from "react";
import { Card } from 'react-bootstrap';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../../assets/css/partials/weddings/weddings.scss"
import {service_options} from '../../resources/carouselOptions';
import Itinerary from '../../assets/images/weddings/Itinerary.svg'
import accommodation from '../../assets/images/weddings/accommodation.svg'
import venue from '../../assets/images/weddings/venue.svg'

const Services = ({fromHoneyMoon,data}) =>{

    return(
        <>
        <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="wedding-holder wedding-holder-mob mt-lg-5 mb-lg-5">
                            <div className="wedding-info">
                                <h1 className=" mb-lg-3">Our category of <span>Services</span> </h1>
                            </div>
                            <div className="d-none d-md-block">
                            <div className="d-flex acheivements-holder service-holder">
                            <Card className={`services-card ${fromHoneyMoon?"honeymoon-service-card":""}`}>
                                    <Card.Title className="service-icon">
                                        <img src={venue} alt='venue' />
                                    </Card.Title>
                                    <Card.Title className="service-title">Venue Selection</Card.Title>
                                    <Card.Body className={`service-body ${fromHoneyMoon?"honeymoon-sevice-body":""}`}>{data.venue}</Card.Body>
                                </Card>
                                <Card className={`services-card ${fromHoneyMoon?"honeymoon-service-card":""}`}>
                                    <Card.Title className="service-icon">
                                        <img src={accommodation} alt='accommodation' />
                                    </Card.Title>
                                    <Card.Title className={`service-title ${fromHoneyMoon?"honeymoon-title":""}`}>{fromHoneyMoon?"Accomodation":"Guest Accomodation"}</Card.Title>
                                    <Card.Body className={`service-body ${fromHoneyMoon?"sevice-accom honeymoon-sevice-body":""}`}>{data.accommodation}</Card.Body>
                                </Card>
                                <Card className={`services-card ${fromHoneyMoon?"honeymoon-service-card":""}`}>
                                    <Card.Title className="service-icon">
                                        <img src={Itinerary} alt='Itinerary' />
                                    </Card.Title>
                                    <Card.Title className="service-title">Itinerary Planning</Card.Title>
                                    <Card.Body className={`service-body ${fromHoneyMoon?"honeymoon-sevice-body":""}`}>{data.Itinerary}</Card.Body>
                                </Card>
                            </div>
                            </div>
                            
                        </div>
                        <div className="wedding-mob-holder d-block d-md-none mb-5"> 
                            <OwlCarousel className="owl-theme" {...service_options} >
                            <Card className={`services-card ${fromHoneyMoon?"honeymoon-service-card":""}`}>
                                    <Card.Title className="service-icon">
                                        <img src={venue} alt='venue' />
                                    </Card.Title>
                                    <Card.Title className="service-title">Venue Selection</Card.Title>
                                    <Card.Body className={`service-body ${fromHoneyMoon?"honeymoon-sevice-body":""}`}>{data.venue}</Card.Body>
                                </Card>
                                <Card className={`services-card ${fromHoneyMoon?"honeymoon-service-card":""}`}>
                                    <Card.Title className="service-icon">
                                        <img src={accommodation} alt='accommodation' />
                                    </Card.Title>
                                    <Card.Title className="service-title">{fromHoneyMoon?"Accomodation":"Guest Accomodation"}</Card.Title>
                                    <Card.Body className={`service-body ${fromHoneyMoon?"sevice-accom":""}`}>{data.accommodation}</Card.Body>
                                </Card>
                                <Card className={`services-card ${fromHoneyMoon?"honeymoon-service-card":""}`}>
                                    <Card.Title className="service-icon">
                                        <img src={Itinerary} alt='Itinerary' />
                                    </Card.Title>
                                    <Card.Title className="service-title">Itinerary Planning</Card.Title>
                                    <Card.Body className={`service-body ${fromHoneyMoon?"honeymoon-sevice-body":""}`}>{data.Itinerary}</Card.Body>
                                </Card>
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Services
