import React from 'react'

const Header = ({mobile1,mobile2,wedding1,wedding2,handleForm,headerText,section}) => {
  return (
   
    <div className="row w-100 m-0">
        <div className="col-12">
            <div className="wedding-holder  wedding-holder-mob mt-lg-5 mb-lg-5">
                <div className="about d-flex">
                    <div className="mobiImages d-flex d-md-none">
                        <div className="img1">
                            <img src={mobile1} alt={section} />
                        </div>
                        <div className="img2">
                            <img src={mobile2} alt={section} />
                        </div>
                    </div>
                    <div>
                       {headerText()}
                        <div>
                            <div onClick={handleForm} className="learn-more-holder d-none d-md-block">
                                <button className="btn btn-learn-more w-100" >Book Now</button>
                            </div>
                        </div>
                    </div>
                    <div className="d-md-flex d-none d-md-block">
                        <div className="img1">
                            <img src={wedding1} alt={section} />
                        </div>
                        <div className="img2">
                            <img src={wedding2} alt={section} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

  )
}

export default Header