import React, {useEffect} from 'react';
import Lottie from "react-lottie";
import animationData from "../../assets/json/loader.json";

function Loader() {
    
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return ()=> document.body.style.overflow = 'unset';
    }, [])

    const animationOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <div className="lottie-loader">
            <Lottie options={animationOptions} height={400} width={400} style={{position: 'absolute',
                top: '50%', left: '50%', transform: 'translate(-50%, -50%)', cursor: 'default'}} />
        </div>
    )
}

export default Loader
