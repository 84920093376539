import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import * as corporateActions from "../../redux/actions/corporatesAuthActions";
import "../../assets/css/partials/corporates/corporates.scss";
import { testEmail } from "../../utils/common";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import requestReceived from '../../assets/images/corporates/employer-form.svg';
import closeIcon from '../../assets/images/common/close-modal.svg';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import IntlContactNumber from '../common/intlContactNumber';


function EmployerModal({ openModal, employerFormRef }) {
  const { enqueueSnackbar } = useSnackbar();
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [state, setState] = useState({
    organisation: "",
    organisation_length: "",
    emp_name: "",
    email: "",
    mobile: "",
    message: "",
  });

  const [error, setError] = useState({
    organisation: false,
    emp_name: false,
    organisation_length: false,
    email: false,
    mobile: false,
    message: false,
    valid_email: false,
  })

  const handleClose = () => {
    openModal(false);
  };

  const handleChange = (key, value) => {
    setState(() => ({
      ...state,
      [key]: value.target.value,
    }));
  };

  const handleEnquiry = (e) => {
    e.preventDefault();
    if (!state.organisation || state.organisation === "") {
      focusInput('#organisation_pop');
      setError({ organisation: true })
    }
    else if (state.organisation.length < 2) {
      focusInput('#organisation_pop');
      setError({ organisation_length: true })
    }
    else if (!state.emp_name || state.emp_name === "") {
      focusInput('#emp_name_pop');
      setError({ emp_name: true })
    }
    else if (!state.email || state.email === "") {
      focusInput('#email_pop');
      setError({ email: true })
    }
    else if (!testEmail(state.email)) {
      focusInput('#email_pop');
      setError({ valid_email: true })
    }
    else if((state.mobile.startsWith('+91') && state.mobile.length!==13)  || !isPossiblePhoneNumber(state.mobile)){   
      setError({ mobile: true })
    }
    else if (!state.message || state.message === "") {
      focusInput('#message_pop');
      setError({ message: true })
    } else {
      dispatch(
        corporateActions.corporateEnquiry({...state, mobile:state.mobile}, handleSuccess, handleErrors)
      );

    }
  };

  // form submitted successfully
  const handleSuccess = () => {
    setShowModal(true)
    setTimeout(() => {
      refreshState();
      setShowModal(false);
      openModal(false);
    }, 3000)
  };

  const refreshState = () => {
    setState({
      organisation: "",
      emp_name: "",
      email: "",
      mobile: "",
      message: "",
    });
    setError({
      organisation: false,
      emp_name: false,
      email: false,
      mobile: false,
      message: false,
    });
  }

  const handleErrors = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
    });
  };

  const numberChange = (val)=>{
    setState({
        ...state,
        mobile: val,
    }) 
  };

  const focusInput = (inputDom)=>{
    var elementPosition = document.querySelector(`${inputDom}`).getBoundingClientRect().top;
                var offsetPosition = elementPosition + window.pageYOffset - 145;
                window.scrollTo({top: offsetPosition, behavior: "smooth" });
                setTimeout(()=>{
                    document.querySelector(inputDom).focus();
                });
};

  return (
    <div>
      <Dialog
        open={openModal}
        PaperProps={{
          style: {
            maxWidth: '830px',
            width: '800px'
          },
        }}
      >
        <div className="login-holder employerForm-holder" ref={employerFormRef}>
          <h5 class="connect-title"><span>Connect with us</span></h5>
          <span className="closeIcon" onClick={handleClose}><img src={closeIcon} className="close-icon" /></span>
          <div class="line"></div>
          <form onSubmit={handleEnquiry}>
            <div className="row">
              <div class="col-12 col-md-6 pr-lg-2">
                <div className="input-holder">
                  <p>Your Organisation details</p>
                  <div class="custom-input card--bg mb20">
                    <input
                      value={state.organisation}
                      type="text"
                      id="organisation_pop"
                      class="form-control"
                      placeholder="Appiness Interactive Pvt. Ltd."
                      onChange={(value) => handleChange("organisation", value)}
                    />
                    <div class="form-line"></div>
                    <label>Organisation name</label>
                    <br />
                    {error.organisation === true ? <span className="errorMsg">Please enter Organisation name </span> : null}
                    {error.organisation_length === true ? <span className="errorMsg">Organisation name should be of minimum 2 characters</span> : null}
                  </div>
                </div>
                <div className="input-holder">
                  <p>Message</p>
                  <div class="custom-input card--bg mb20">
                    <input
                      value={state.message}
                      type="text"
                      id="message_pop"
                      class="form-control"
                      placeholder="Hi, I would like to know more ..."
                      onChange={(value) => handleChange("message", value)}
                    />
                    <div class="form-line"></div>
                    <label>Additional message</label>
                    <br />
                    {error.message === true ? <span className="errorMsg">Please enter message </span> : null}
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 pl-lg-2">
                <div className="input-holder">
                  <p>Your details</p>
                  <div class="custom-input card--bg mb20">
                    <input
                      value={state.emp_name}
                      type="text"
                      id="emp_name_pop"
                      class="form-control"
                      placeholder="Jane Doe"
                      onChange={(value) => handleChange("emp_name", value)}
                    />
                    <div class="form-line"></div>
                    <label>Name</label>
                    <br />
                    {error.emp_name === true ? <span className="errorMsg">Please enter name </span> : null}
                  </div>
                  <div class="custom-input card--bg mb20">
                    <input
                      value={state.email}
                      type="text"
                      id="email_pop"
                      class="form-control"
                      placeholder="Jane@appinessworld.com"
                      onChange={(value) => handleChange("email", value)}
                    />
                    <div class="form-line"></div>
                    <label>Employee email</label>
                    <br />
                    {error.email === true ? <span className="errorMsg">Please enter a email Id </span> : null}
                    {error.valid_email === true ? <span className="errorMsg">Please enter a valid email Id</span> : null}
                  </div>
                  {/* <div class="custom-input card--bg mb20">
                    <input
                      value={state.mobile}
                      type="text"
                      class="form-control"
                      placeholder="Mobile number"
                      onChange={(value) => handleChange("mobile", value)} onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                    <div class="form-line"></div>
                    <label>Mobile number</label>
                    <br />
                    {error.mobile === true ? <span className="errorMsg">Please enter a valid 10 digits mobile number</span> : null}
                  </div> */}

                  <div className="custom-input card--bg mb20">
                    <IntlContactNumber disabled={false} hasFormLine={true} titleValue={'Mobile Number'} labelClass={'w-100'}  hideSaveBtn={true} mobile={`${state.mobile}`} numberChange={numberChange} 
                                    hasError={error.mobile} /> 
                            </div>  
                </div>
              </div>
            </div>

            <div className="text-right">
              <button type="sumbit" className="btn otp-btn w-auto">
                Send enquiry
              </button>
            </div>
          </form>
        </div>
      </Dialog>

      <div className="login-success-popup">
        <Dialog
          open={showModal}
        >
          <DialogTitle>
            <div className="success-holder" style={{ margin: '50px' }}>
              <div>
                <img src={requestReceived} alt="successful" />
              </div>
              <div className="success-message">
                <p>Request recieved</p>
                <p>We will go through your request, <br />
                  and connect with you shortly</p><hr></hr>
              </div>

            </div>
          </DialogTitle>
        </Dialog>
      </div>
    </div>
  );
}

export default EmployerModal;
