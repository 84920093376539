import React, { useState, useEffect, useRef } from "react";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { useHistory, Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import GlobalSearch from "./globalSearch";
import searchIconBlack from "../../assets/images/corporates/search-black.svg";
import BlackLogo from "../../assets/images/common/logoheader.png";
import userIcon from "../../assets/icons/feather_user.svg";
import "../../assets/css/partials/header-footer.scss";
import * as HolidaysAction from "../../redux/actions/holidaysActions";
import * as ExperienceAction from "../../redux/actions/experiencesAction";
import * as ProfileAction from "../../redux/actions/profileAction";
import * as CorporateAction from "../../redux/actions/corporatesAuthActions";
import UserDetails from "./userDetails";
import { useSnackbar } from "notistack";
import { handleUserLogout, setUserData } from "../../redux/actions/authAction";
import useClickOutside from "../../hooks/useClickOutside";
const navebar = {
  backgroundColor: "#fff",
  boxShadow: "0px 4px 32px rgb(50 40 40 / 21%)",
};
const HolidayHeader = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [enableGlobalSearch, setEnableGlobalSearch] = useState(false);
  const profileRef=useRef()
  const { enqueueSnackbar } = useSnackbar();
  const location=useLocation()
  const searchBtnClicked = () => {
    setEnableGlobalSearch(true);
  };
  const gsDisplayLocation = [
    "/events",
    "/experiences",
    "/holidays",
    "/corporates",
    "/my-family-first",
  ];
  const HOLIDAYS_SECTIONS = useSelector(
    (state) => state?.holidays?.holiday_categories?.data
  );
  const isCorporateAccount = useSelector((state) => state.auth.login_user_otp.corporate_account);
  const EXPERIENCE_SECTIONS = useSelector(
    (state) => state?.experiences?.experience_categories?.data
  );
  const CORP_LIST = useSelector(
    (state) => state.corporates?.corporates_category.data
  );
  const loginUserData = useSelector((state) => state.auth.login_user_otp);
  const googleLogin = useSelector((state) => state.auth.login_with_google);
  const USER_DETAILS = useSelector(
    (state) => state.userProfile?.profile_details?.data
  );
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("mobileAlertShown");
    localStorage.clear();
    dispatch(handleUserLogout());
    history.push("/");
    enqueueSnackbar("Logged out successfully", {
      variant: "info",
    });
    window.location.reload()
setShow(false)
  };
  const [secName, setSecName] = useState("");
  const handleCategory = (id, name,slug) => {
    dispatch(CorporateAction.setCategoryId(id));
    dispatch(CorporateAction.getCorporatePackages(id, 1));
    setSecName(name)
    localStorage.setItem("activeCorpCategory",slug)
    localStorage.setItem("scrollactiveCorpCategory",true)
    
  }
  useClickOutside(profileRef, (e) => {
    setTimeout(() => setShow(false), 100);
  });
  useEffect(() => {
  if(location.pathname?.indexOf("/corporates")) {
    dispatch(HolidaysAction.getHolidaySections());
    dispatch(ExperienceAction.getExperienceSections());
  }
  console.log(location.pathname)
  }, [location]);

const goToProfile=()=>{
  history.push({pathname:isLoggedIn() ? '/user/profile' : '/login',state:{fromHeader:true}})
  window.scrollTo(0,0)
}
const gotoSections=(path,state)=>{
  if(path==="/profile"){
    history.push({ pathname: "/profile", data: {bool: true}})
  }else{
    history.push({pathname:path,state:state?state:null})
    if(path.startsWith('/holidays')){
      localStorage.setItem("scrollactiveHolCategory",true)
    }
    if(path.startsWith('/exp')){
      localStorage.setItem("scrollactiveExpCategory",true)
    }
  }
  window.scrollTo(0,0)
}
  const isLoggedIn = () => {
    return (
      (Object.keys(loginUserData).length !== 0 &&
        loginUserData.constructor === Object) ||
      (Object.keys(googleLogin).length !== 0 &&
        googleLogin.constructor === Object)
    );
  };
  useEffect(()=>{
    if(isLoggedIn()){
      dispatch(ProfileAction.getNonCorporateProfileDetails())
    }
    if(isCorporateAccount){
      dispatch(ProfileAction.getProfileDetails());
      dispatch(CorporateAction.corporateCategories());
    }
  },[isLoggedIn(),isCorporateAccount])
  return (
    <div className="d-none d-md-block">
    
      <Navbar collapseOnSelect expand="lg" style={navebar} fixed="top" className="package-header">
        <Container fluid>
          <Navbar.Brand>
            <Link to="/">
              <div className="logo">
                <img src={BlackLogo} alt="logo" style={{ width: "85px" }} />
              </div>
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          {/* inherit */}
          <Navbar.Collapse
            id="responsive-navbar-nav"
            style={{ justifyContent: "space-between" }}
          >
              { loginUserData &&
                  loginUserData.corporate_account &&
                  loginUserData.user_details ? <Nav style={{width:"70%",justifyContent:"right",paddingRight:"10%" }}>
                      <NavDropdown
                  title="Deals"
                  id="collasible-nav-dropdown"
                  className="nav-link-section"
                >
                  {CORP_LIST &&
                    CORP_LIST.length > 0 &&
                    CORP_LIST.map((item, index) => (
                      <NavDropdown.Item onClick={()=>handleCategory(item.id, item.name,item.section_slug)}>
                        <a  onClick={()=>gotoSections("/corporates","fromHeader")}>
                          {item?.name}
                        </a>
                      </NavDropdown.Item>
                    ))}
                      
                </NavDropdown>
                <Nav.Link className="nav-link-section">
                <a onClick={()=>gotoSections("/profile")} >Profile</a>
              </Nav.Link>
              <Nav.Link  className="nav-link-section">
                <a onClick={()=>gotoSections("/bookings")} >Bookings</a>
              </Nav.Link></Nav>:
            <Nav  className="me-auto holiday-header-sections">
              <NavDropdown
                title="Holidays"
                id="collasible-nav-dropdown"
                className="nav-link-section"
              >
                {HOLIDAYS_SECTIONS &&
                  HOLIDAYS_SECTIONS.length > 0 &&
                  HOLIDAYS_SECTIONS.map((item, index) => (
                    <NavDropdown.Item>
                      <a  onClick={()=>gotoSections(`/holidays/${item.section_slug}`)} >
                        {item?.name}
                      </a>
                    </NavDropdown.Item>
                  ))}
              </NavDropdown>
              <NavDropdown
                title="Corporates"
                id="collasible-nav-dropdown"
                className="nav-link-section"
              >
                <NavDropdown.Item>
                  <a
                  onClick={()=>gotoSections("/corporates",{
                    id: 1,
                  })}
                  >
                    Employee Rewards Programme
                  </a>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <a
                     onClick={()=>gotoSections("/corporates",{
                      id: 2,
                    })}
                  >
                    Employee Engagement Programme
                  </a>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <a
                    onClick={()=>gotoSections("/corporates",{
                      id: 3,
                    })}
                  >
                    Corporate Events
                  </a>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <a
                    onClick={()=>gotoSections("/corporates",{
                      id: 4,
                    })}
                    
                  >
                    Group Incentive Travel
                  </a>
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title="Experiences"
                id="collasible-nav-dropdown"
                className="nav-link-section"
              >
                {EXPERIENCE_SECTIONS &&
                  EXPERIENCE_SECTIONS.length > 0 &&
                  EXPERIENCE_SECTIONS.map((item, index) => (
                    <NavDropdown.Item>
                      <a onClick={()=>gotoSections(`/experiences/${item.section_slug}`)} >
                        {item?.name}
                      </a>
                    </NavDropdown.Item>
                  ))}
              </NavDropdown>
              {/* <Nav.Link href="#" className="nav-link-section">
                <a onClick={()=>gotoSections("/my-family-first")} >My Family First</a>
              </Nav.Link> */}
                <NavDropdown
                title="My Family First"
                id="collasible-nav-dropdown"
                className="nav-link-section"
              >
                <NavDropdown.Item>
                  <a
                  onClick={()=>gotoSections("/my-family-first",{
                    id: 1,
                  })}
                  >
                    Honeymoon Packages
                  </a>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <a
                     onClick={()=>gotoSections("/my-family-first",{
                      id: 2,
                    })}
                  >
                    Anniversary Packages
                  </a>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <a
                    onClick={()=>gotoSections("/my-family-first",{
                      id: 3,
                    })}
                  >
                   Gifting
                  </a>
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="#" className="nav-link-section">
                <a onClick={()=>gotoSections("/weddings")}>  Weddings</a>
              </Nav.Link>
            </Nav>

                  }
            <Nav style={{width:"20%",justifyContent:"right"}} className="nav-profile-search">
          
                <div
                  className={
                    history.location.pathname == "/"
                      ? "d-md-none"
                      : history.location.pathname == "/search"
                      ? "d-none"
                      : ""
                  }
                >
                  {!enableGlobalSearch &&
                    (gsDisplayLocation.indexOf(history.location.pathname) >
                      -2 ||
                      history.location.pathname == "/") && (
                      <button
                        className="search_btn search-btn-holiday"
                        onClick={searchBtnClicked}
                      >
                        <img src={searchIconBlack} alt="searchIconBlack" />
                      </button>
                    )}
                  {enableGlobalSearch && (
                    <GlobalSearch
                      className="mobile-search"
                      enableGlobalSearch={enableGlobalSearch}
                      initFocus={true}
                      onBlur={() => setEnableGlobalSearch(false)}
                    />
                  )}
                </div>
             
              <Nav.Link ref={profileRef} eventKey={2} href="#" className="nav-link-end">
                <div className="nav-proile-icon" onClick={()=>isLoggedIn() ? setShow(!show):goToProfile()}>
                  {isLoggedIn() ?       <>
                  {USER_DETAILS &&
                   (USER_DETAILS.user.image === "" ||
                     USER_DETAILS.user.image === null) ? (
                   
                     <div className="nameChar">
                       <p>{USER_DETAILS?.user?.full_name.charAt(0).toUpperCase()}</p>
                    
                   </div>
                 ) : (
                   <div className="profileImage">
                     <img src={USER_DETAILS?.user?.image} alt={USER_DETAILS?.user?.full_name}  />
                   </div>
                 )}
                 </>
                  : (
                    
                      <div className="userIcon">
                        <img src={userIcon} alt="userIcon" />
                      </div>
                    
                  )}
                </div>
                <div  className={`my-profile-dropdown ${show?"d-block":"d-none"}`}>
<p className="my-profile" onClick={goToProfile}>My Profile</p>
<p onClick={handleLogout}>Logout</p>
      </div>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
     
    </div>
  );
};

export default HolidayHeader;
