import React from "react";
import { Helmet } from "react-helmet";
import { useState, useEffect, useRef } from "react";
import "../../assets/css/partials/weddings/weddings.scss";
import Services from "./services";
import Destinations from "./destinations";
import useClickOutside from "../../hooks/useClickOutside";
import GuestsModal from "../common/guestsModal";
import moment from "moment";
import CategoryImages from "../corporates/categoryImages";
import CalenderModal from "../corporates/calenderModal";
import "../corporates/calenderModal.css";
import GiftFormModal from "./giftFormModel";
import wedding1 from "../../assets/images/weddings/Converted/wedding1.webp";
import wedding2 from "../../assets/images/weddings/Converted/wedding2.webp";
import wedding3 from "../../assets/images/weddings/Converted/wedding3.webp";
import mobile1 from "../../assets/images/weddings/Converted/mobile1.webp";
import mobile2 from "../../assets/images/weddings/Converted/mobile2.webp";
import mobile3 from "../../assets/images/weddings/Converted/mobile3.webp";
import closeIcon from "../../assets/images/common/close-modal.svg";
import WhatsappLogo from "../../assets/images/corporates/whatsapp-logo.svg";
import Stories from "./stories";
import * as HolidaysAction from "../../redux/actions/holidaysActions";
import { useDispatch, useSelector } from "react-redux";
import Header from "./header";
import * as weddinsActions from "../../redux/actions/weddingsActions";
import Experts from "./experts";
import StoriesList from "./stories";

function Index() {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [modal, setModal] = useState(false);
  const [height, setHeight] = useState(0);
  const [count, setCount] = useState(0);
  const [adultGuestCount, setAdultGuestCount] = useState(0);
  const [childrensGuestCount, setChildrensGuestCount] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [myfamily, setMyfamily] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showCalenderModal, setShowCalenderModal] = useState(false);
  const SCROLL_REF = useSelector((state) => state.holidays.set_Scroll);
  const handleNumberOfGuests = () => {
    setShowModal(true);
  };
  const WEDDING_STORIES_LIST = useSelector(
    (state) => state.weddings.wedding_stories_list?.results
  );
  const WEDDING_DESTINATIONS_LIST = useSelector(
    (state) => state.weddings.wedding_destinations_list
  );
  useEffect(() => {
    dispatch(weddinsActions.getStoriesList());
    dispatch(weddinsActions.getDestinationsList());
  }, []);

  const [state, setState] = useState({
    location: "",
    occasion: "",
    guest: "",
    message: "",
    schedule: "",
  });
  const [error, setError] = useState({
    location: false,
    occasion: false,
    guest: false,
    schedule: false,
    infantValidate: false,
  });
  const handleChange = (key, value) => {
    setState(() => ({
      ...state,
      [key]: value.target.value,
    }));
    setError({
      ...error,
      [key]: false,
    });
  };
  const servicesData={
    venue:"Your wedding day should be just as special as you two! Our experts can help you narrow down your options",
    accommodation:"Let your guests bask in style, comfort, and value. Professional designers are bringing you decor that is fresh, unique, and affordable",
    Itinerary:"We'd rather give you your wedding the way you want it, so let us arrange everything"
  }
  const guestDatahandler = (adultData, childrenData, countData) => {
    let guestRes = [];
    setShowModal(false);
    setAdultGuestCount(adultData);
    setChildrensGuestCount(childrenData);
    setCount(countData);
    if (adultData > 0) {
      guestRes.push(`${adultData} adult${adultData > 1 ? "s" : ""}`);
    }
    if (childrenData > 0) {
      guestRes.push(`${childrenData} kid${childrenData > 1 ? "s" : ""}`);
    }
    if (countData > 0) {
      guestRes.push(`${countData} infant${countData > 1 ? "s" : ""}`);
    }
    setState(() => ({
      ...state,
      guest: guestRes.join(","),
    }));
    if (adultData || childrenData || countData) {
      setError({
        ...error,
        guest: false,
        infantValidate: adultData == 0 ? error.infantValidate : false,
      });
    }
  };
  const selectDateHandler = () => {
    setShowCalenderModal(true);
    setMyfamily(true);
  };
  const handleDatesData = (sDate, eDate, bool) => {
    setShowCalenderModal(false);
    if (bool) {
      setStartDate(null);
      setEndDate(null);
      setState(() => ({
        location: "",
        occasion: "",
        message: "",
        guest: "",
        schedule: "",
      }));
    } else {
      setStartDate(sDate);
      setEndDate(eDate);
      setState(() => ({
        ...state,
        schedule: {
          to: `${eDate}`,
          from: `${sDate}`,
        },
      }));
    }
    if (sDate || eDate) {
      setError({
        ...error,
        schedule: false,
      });
    }
  };
  const handleContactModal = () => {
    setShowContactModal(true);
  };
  const handleContactModalClose = () => {
    setShowContactModal(false);
  };
  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);
  const listenToScroll = () => {
    // let heightToHideFrom = document.body.scrollHeight - 850;
    // const winScroll = document.body.scrollTop ||
    //     document.documentElement.scrollTop;
    // setHeight(winScroll);
    // if (winScroll > heightToHideFrom) {
    //     setIsVisible(false);
    // } else {
    //     setIsVisible(true);
    // }
  };
  const handleEnquiry = (e) => {
    e.preventDefault();
    if (!state.location || state.location === "") {
      focusInput("#location");
      setError({ location: true });
    } else if (!state.occasion || state.occasion === "") {
      focusInput("#occasion");
      setError({ occasion: true });
    } else if (
      !state.schedule ||
      state.schedule === "" ||
      startDate === false
    ) {
      focusInput("#schedule");
      setError({ schedule: true });
    } else if (
      !state.guest ||
      state.guest === "" ||
      state.guest === `${0} adults, ${0} kids, ${0} infants`
    ) {
      focusInput("#guest");
      setError({ guest: true });
    } else if (
      (count >= 1 || childrensGuestCount >= 1) &&
      adultGuestCount === 0
    ) {
      focusInput("#guest");
      setError({ infantValidate: true });
    } else {
      setModal(true);
    }
  };
  const focusInput = (inputDom) => {
    var elementPosition = document
      .querySelector(`${inputDom}`)
      .getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - 145;
    // window.scrollTo({top: offsetPosition, behavior: "smooth" });
    setTimeout(() => {
      document.querySelector(inputDom).focus();
    });
  };
  const inputFieldsClear = () => {
    setState({
      ...state,
      location: "",
      occasion: "",
      guest: "",
      message: "",
      schedule: "",
    });
  };
  const handleForm = () => {
    setState({
      ...state,
      location: "",
      occasion: "",
      message: "",
    });
    setError({
      location: false,
      occasion: false,
      guest: false,
      schedule: false,
      infantValidate: false,
    });
    setCount(0);
    setChildrensGuestCount(0);
    setAdultGuestCount(0);
    setStartDate(null);
    setEndDate(null);
    handleDatesData(null, null, true);
    setShowForm(true);
  };
  const closeFormHandler = () => {
    setState({
      ...state,
      location: "",
      occasion: "",
      message: "",
    });
    setError({
      location: false,
      occasion: false,
      guest: false,
      schedule: false,
      infantValidate: false,
    });
    setCount(0);
    setChildrensGuestCount(0);
    setAdultGuestCount(0);
    setStartDate(null);
    setEndDate(null);
    handleDatesData(null, null, true);
    setShowForm(false);
  };
  const getGuestCount = (adult, kids, infant) => {
    let tmpGuestsArray = [];
    if (adult > 0) {
      tmpGuestsArray.push(`${adult} adult${adult > 1 ? "s" : ""}`);
    }
    if (kids > 0) {
      tmpGuestsArray.push(`${kids} kid${kids > 1 ? "s" : ""}`);
    }
    if (infant > 0) {
      tmpGuestsArray.push(`${infant} infant${infant > 1 ? "s" : ""}`);
    }
    return tmpGuestsArray.join(", ");
  };
  useEffect(() => {
    if (showContactModal || showForm) {
      document.querySelector("html").style.overflow = "hidden";
    } else {
      document.querySelector("html").style.overflow = "scroll";
    }
  }, [showContactModal, showForm]);
  useEffect(() => {
    if (SCROLL_REF) {
      executeScroll();
    }
  }, [SCROLL_REF]);
  useEffect(() => {
    let footer = document
        .querySelector(
            ".footer-holder"

        )
        ?.getBoundingClientRect().top+600;
    window.addEventListener("scroll", () => {
        setIsVisible(window.scrollY > footer);


    })
}, [])

  const popupRef = useRef();
  // useClickOutside(popupRef, () => {
  //     if(!modal){
  //         closeFormHandler()
  //     }
  // });
  const contactModalRef = useRef();
  // useClickOutside(contactModalRef, () => {
  // setTimeout(() => setShowContactModal(false),100)
  // });
  const headerText = () => {
    return (
      <div className="wedding-info my-family-holder">
        <h1 className="first">
          Your <span>wedding day</span> is a{" "}
          <span>once-in-a-lifetime opportunity</span> to celebrate your love and
          share the most amazing moments of your life?
        </h1>
        <p >
          Special moments . Fine Wine & Dine . Customized Trips{" "}
        </p>
      </div>
    );
  };
  const myRef = useRef(null);
  const executeScroll = () => {
    myRef.current.scrollIntoView();
    dispatch(HolidaysAction.setScroll(false));
  };
  return (
    <div>
      <Helmet>
        <title>Weddings</title>
        <meta
          name="description"
          content="Employee Recognition Programs Inspiring Rewards – Inspiring Performance"
        />
      </Helmet>
      <div className="container">
        <Header
          mobile1={mobile1}
          mobile2={mobile2}
          wedding1={wedding1}
          wedding2={wedding2}
          handleForm={handleForm}
          headerText={headerText}
          section={'weddings'}
        />
      </div>
      <Services data={servicesData} />

      <Destinations WEDDING_DESTINATIONS_LIST={WEDDING_DESTINATIONS_LIST} tittle={"Wedding"} />
      <Experts mobile3={mobile3} wedding3={wedding3} handleForm={handleForm}section={'weddings'} />
      <div className="container wedding-collage">
        <div className="corporate-services">
          {/* <div className="col-12  mt-lg-2 mb-5"> */}
          <CategoryImages fromWedding={true} section={'weddings'} />
          {/* </div> */}
        </div>
      </div>

      <StoriesList WEDDING_STORIES_LIST={WEDDING_STORIES_LIST} myRef={myRef} />

      <div className="myfamily-holder" style={{ position: "relative" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className=" col-12 col-lg-10">
              <div
                className={showForm ? "prepareGift-mobile" : "prepareGift-desk"}
              >
                <div
                  className={
                    showForm
                      ? "prepareGift-holder create-gift-holder"
                      : "prepareGift-holder"
                  }
                  style={{ margin: "100px auto" }}
                  ref={popupRef}
                >
                  <span onClick={closeFormHandler}>
                    <img
                      src={closeIcon}
                      className={showForm ? "d-block close-icon" : "close-icon"}
                      alt='close'
                    />
                  </span>
                  <h5>Book Now for Your Weddings</h5>
                  <h3>Fill details below to proceed</h3>
                  <form>
                    <div className="row">
                      <div className="col-12 col-lg-6">
                        <div className="input-holder">
                          <div className="custom-input card--bg mb20">
                            <input
                              value={state.location}
                              type="text"
                              id="location"
                              className="form-control"
                              placeholder="Bangalore, Goa, Pudu"
                              onChange={(value) =>
                                handleChange("location", value)
                              }
                              
                              onKeyPress={(event) => {
                                if (!/^[A-Za-z ]+$/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                            <div className="form-line"></div>
                            <label>Locations preferred</label>
                            {error.location === true ? (
                              <span className="errorMsg text-center">
                                Preferred locations required
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="input-holder">
                          <div className="custom-input card--bg mb20">
                            <input
                              value={state.occasion}
                              type="text"
                              id="occasion"
                              className="form-control"
                              placeholder="Wedding"
                              onChange={(value) =>
                                handleChange("occasion", value)
                              }
                            />
                            <div className="form-line"></div>
                            <label>Occasion</label>
                            {error.occasion === true ? (
                              <span className="errorMsg text-center">
                                Occasion required
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="input-holder">
                          <p></p>
                          <div className="custom-input card--bg mb20">
                            <input
                              type="text"
                              className="form-control"
                              readOnly
                              id="schedule"
                              placeholder="Pick Your Dates"
                              onChange={(value) =>
                                handleChange("schedule", value)
                              }
                              onClick={selectDateHandler}
                              value={
                                startDate && endDate !== null
                                  ? `${moment(startDate).format(
                                    "ddd-DD MMM"
                                  )} to ${moment(endDate).format(
                                    "ddd-DD MMM"
                                  )}`
                                  : ""
                              }
                            />
                            <div className="form-line"></div>
                            <label>When are you planning</label>
                            {error.schedule === true ? (
                              <span className="errorMsg text-center">
                                Please select dates required
                              </span>
                            ) : null}
                            {showCalenderModal ? (
                              <CalenderModal
                                showMyModal={showCalenderModal}
                                handleDatesData={handleDatesData}
                                myfamily={myfamily}
                                prevStartDate={startDate}
                                prevEndDate={endDate}
                              />
                            ) : null}
                          </div>
                        </div>
                        <div className="input-holder">
                          <div className="custom-input card--bg mb20">
                            <input
                              type="text"
                              className="form-control"
                              id="guest"
                              readOnly
                              placeholder="Number of guests"
                              onChange={(value) => handleChange("guest", value)}
                              onClick={handleNumberOfGuests}
                              value={
                                adultGuestCount !== 0 ||
                                  childrensGuestCount !== 0 ||
                                  count !== 0
                                  ? getGuestCount(
                                    adultGuestCount,
                                    childrensGuestCount,
                                    count
                                  )
                                  : ""
                              }
                            />
                            <div className="form-line"></div>
                            <label>No. of guests</label>
                            {error.guest === true ? (
                              <span className="errorMsg text-center">
                                No of guests required
                              </span>
                            ) : null}
                            {error.infantValidate === true ? (
                              <span className="errorMsg text-center">
                                For Infants/children at least 1 adult is
                                required
                              </span>
                            ) : null}
                            {showModal ? (
                              <GuestsModal
                                showMyModal={showModal}
                                guestDatahandler={guestDatahandler}
                                adultCount={adultGuestCount}
                                childrensCount={childrensGuestCount}
                                infantCount={count}
                              />
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="input-holder mt-lg-4">
                          <div className="custom-input card--bg2 mb20">
                            <h2>Any specific request</h2>
                            <div className="inner-card">
                              <input
                                value={state.message}
                                type="text"
                                className="form-control"
                                placeholder="Enter here"
                                onChange={(value) =>
                                  handleChange("message", value)
                                }
                              />
                              <div className="form-line"></div>
                              <label>Example:</label>
                              <label>
                                Add Cadbury celeberations chocolate box
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="line"></div>
                        <div className="text-center">
                          <button
                            type="button"
                            className="btn otp-btn prepare-btn"
                            onClick={handleEnquiry}
                          >
                            Request for your Wedding arrangements
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {modal && (
                <GiftFormModal
                  closeFormHandler={closeFormHandler}
                  handleClose={() => setModal(false)}
                  showModal={modal}
                  giftData={state}
                  inputFieldsClear={inputFieldsClear}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        {showContactModal ? (
          <div className="contact-modal">
            <div className="login-holder contact-holder" ref={contactModalRef}>
              <h5>Contact Us</h5>
              <span onClick={handleContactModalClose}>
                <img src={closeIcon} className="close-icon" alt='close' />
              </span>
              <div className="line"></div>
              <div className="contact-card mb-3 d-flex align-items-center justify-content-between">
                <div>
                  <p>
                    <a href="tel:+919902064657">+91 9902064657</a>
                  </p>
                  <p>Our number</p>
                </div>
                <div>
                  <a
                    href="https://wa.me/+919902064657"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <img src={WhatsappLogo} alt="WhatsappLogo" />
                  </a>
                </div>
              </div>
              <div className="contact-card">
                <p>
                  <a
                    href="mailto:ramananda@hammockholidays.com"
                    rel="noreferrer"
                    target="_blank"
                  >
                    ramananda@hammockholidays.com{" "}
                  </a>
                </p>
                <p>Our email</p>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {!isVisible && (
        <div className="mobile-buttons">
          <div className="stcky-leftButton">
            <div className="white-button" onClick={handleContactModal}>
              <a>Contact</a>
            </div>
          </div>
          <div className="stcky-rightButton">
            <div className="custom-button" onClick={handleForm}>
              <a>Book Now</a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Index;
