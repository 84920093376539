import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { testEmail } from "../../utils/common";
import '../../assets/css/partials/launcingSoon.scss';
import OwlCarousel from "react-owl-carousel";
import Logo from "../../assets/images/common/logo.svg";
import WhatsappLogo from "../../assets/images/common/whatsapp_logo.svg"
import CloseIcon from '../../assets/images/common/close-modal.svg';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import requestReceived from '../../assets/images/corporates/employer-form.svg';
import { useHistory } from "react-router-dom";
import * as corporateActions from "../../redux/actions/corporatesAuthActions";
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import IntlContactNumber from '../common/intlContactNumber';

const owl_images = {
    items: 1,
    margin: 1,
    nav: false,
    loop: false,
    dots: false,
    autoplay: false,
};

function LauncingSoon(props) {
    const data = props.location.state;
    const dispatch = useDispatch();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [showModal, setShowModal] = useState(false);
    const [show, setShow] = useState(false);
    const [state, setState] = useState({
        name: "",
        email: "",
        mobile: "",
    });

    const handleChange = (key, value) => {
        setState(() => ({
            ...state,
            [key]: value.target.value,
        }));
    };
    const [error, seterror] = useState({
        name: false,
        email: false,
        mobile: false,
        valid_email: false,
    })

    const handleSendRequest = () => {
        if (!state.name || state.name === "") {
            seterror({ name: true })
        }
        else if (!state.email || state.email === "") {
            seterror({ email: true })
        }
        else if (!testEmail(state.email)) {
            seterror({ valid_email: true })
        }
        else if (!state.mobile || state.mobile === "" || state.mobile.length < 10 ||
            state.mobile.length > 10) {
            seterror({ mobile: true })
        }
        else if (state.mobile.match(/^[0-9]+$/) == null) {
            seterror({ mobile: true })
        }
        else {
            dispatch(
                corporateActions.contactForm(state, handleSuccess, handleErrors)
            );
        }
    }

    // form submitted successfully
    const handleSuccess = () => {
        setShowModal(true)
        setTimeout(() => {
            refreshState();
            setShowModal(false)
        }, 3500)
    };

    const refreshState = () => {
        setState({
            name: "",
            email: "",
            mobile: "",
        });
        seterror({
            name: false,
            email: false,
            mobile: false,
            valid_email: false,
        });
    }

    const handleErrors = (message) => {
        enqueueSnackbar(message, {
            variant: "error",
        });
    };


    return (
        <div className="launcimg-soon-page">
            <div className="container-fluid">
                <div className="row height-100vh">
                    <div className="col-12 col-lg-6 px-0">
                        <div className="banner-holder">
                            <div className="content-holder">
                                <div className="logo"> <img src={Logo} alt="" className="mb-0" /> </div>
                                       { 
                                        //    <div className="intro">
                                        //     <p>We're launching {data} soon</p>
                                        //     <h4>But! We love to create magical experiences for you
                                        //     </h4>
                                        // </div>
                                    }
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 px-0">
                        <div className="contact-bg">
                            <div className="goBack d-none d-lg-block" onClick={() => history.goBack()}>
                                <img src={CloseIcon} alt="" />
                            </div>
                            <div className="contact-holder">
                                <p className="get-in-touch text-center">Please get in touch to customize a magical experience</p>
                                <div className="custom-box contact-details">
                                    <div className="contact-card mb-3 d-flex align-items-center justify-content-between">
                                        <div>
                                            <p> <a href="tel:9902064657">9902064657</a> / <a href="tel:080- 25219666">080-
                                            25219666</a></p>
                                            <p>Our number</p>
                                        </div>
                                        <div> <a href="https://wa.me/+919902064657" target="_blank" rel="noreferrer"> <img
                                            src={WhatsappLogo} alt="" /> </a> </div>
                                    </div>
                                    <div className="contact-card">
                                        <p> <a href="mailto:ramananda@hammockholidays.com" rel="noreferrer"
                                            target="_blank">ramananda@hammockholidays.com</a></p>
                                        <p>Our email</p>
                                    </div>
                                </div>
                                <div className="or-text text-center">
                                    <p>Or</p>
                                </div>
                                <div className="custom-box contact-form">
                                    <h5>We’ll connect with you</h5>
                                    <div className="line custom-line"></div>
                                    <form id="contact_form">
                                        <div className="custom-input card--bg mb20">
                                            <input type="text" className="form-control" placeholder="Name"
                                                value={state.name}
                                                onKeyPress={(event) => {
                                                    if (!/[a-zA-Z\s]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                onChange={(value) => handleChange("name", value)}
                                            />
                                            <div className="line my8"></div>
                                            <label>Your name</label>
                                            {error.name === true ? <p className="errorMsg">Name is required</p> : null}
                                        </div>
                                        <div className="custom-input card--bg mb20">
                                            <input type="text" className="form-control" placeholder="Email ID"
                                                value={state.email}
                                                onChange={(value) => handleChange("email", value)}
                                            />
                                            <div className="line my8"></div>
                                            <label>Your email</label>
                                            {error.email === true ? <span className="errorMsg">Please enter a email Id </span> : null}
                                            {error.valid_email === true ? <span className="errorMsg">Please enter a valid email Id</span> : null}
                                        </div>
                                        {/* <div className="custom-input card--bg">
                                            <input type="text" className="form-control" placeholder="Mobile number"
                                                value={state.mobile}
                                                onChange={(value) => handleChange("mobile", value)} onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                            />
                                            <div className="line my8"></div>
                                            <label>Your Mobile number</label>
                                            {error.mobile === true ? <span className="errorMsg">Please enter a valid 10 digits mobile number</span> : null}
                                        </div> */}
                                        <div class="custom-input card--bg">
                                        <IntlContactNumber disabled={false} hasUnderline={true}  hideSaveBtn={true} mobile={state.mobile} numberChange={(val)=>setState(() => ({
                                                            ...state,
                                                            mobile: val,
                                                        })) } 
                                                        hasError={error.mobile} /> 
                                        </div> 
                                        <div className="contact-line"></div>
                                        <div className="text-center text-lg-right mt-1">
                                            <button type="button" className="btn btn--send" onClick={handleSendRequest}>Send
                                        Request</button>

                                        </div>
                                    </form>
                                </div>
                                <div className="custom-box contact-form meet-us">
                                    <h5>Meet us at our Office</h5>
                                    <div className="line custom-line"></div>
                                    <div className="contact-card">
                                        <p>3291, 1st Floor, 12th Main, HAL II Stage, Indiranagar</p>
                                        <p>Bengaluru, Karnataka 560008</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="login-success-popup">
                <Dialog
                    open={showModal}
                >
                    <DialogTitle>
                        <div className="success-holder" style={{ margin: '50px' }}>
                            <div>
                                <img src={requestReceived} alt="successful" />
                            </div>
                            <div className="success-message">
                                <p>Request recieved</p>
                                <p>Thanks for reaching out to us,<br />
                    The hammock team will get back to you shortly</p><hr></hr>
                            </div>
                        </div>
                    </DialogTitle>
                </Dialog>
            </div>
        </div>
    )
}

export default LauncingSoon
