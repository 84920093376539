import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../../assets/css/partials/header-footer.scss";
import Logo from "../../assets/images/common/logo.svg";
import BlackLogo from "../../assets/images/common/black-logo.png";
import { Link, withRouter, useHistory, useLocation } from "react-router-dom";
import { handleUserLogout, setUserData } from "../../redux/actions/authAction";
import ProfileImage from "../../assets/images/home/profile.png";
import { useSnackbar } from "notistack";
import * as ProfileAction from "../../redux/actions/profileAction";
import * as CommonAction from "../../redux/actions/commonAction";
import Book from '../events/bookThisForm';
import CloseIcon from '../../assets/images/common/close-modal.svg';
import Dialog from '@material-ui/core/Dialog';
import styled from "styled-components";
import LoginPopup from '../auth/login';
import SignupPopup from '../auth/signUp';
import { Tabs, Tab, Navbar, Container, Nav, NavDropdown} from "react-bootstrap";
import ReactDOM from 'react-dom';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {MdInfoOutline} from 'react-icons/md';
import 'animate.css';
import HamNav from './hamNav';
import GlobalSearch from "./globalSearch";
import searchIconBlack from '../../assets/images/corporates/search-black.svg'

const Header = (props) => {
  // console.log("props", props)
  const dispatch = useDispatch();
  const routeLocation = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [showUpdateMobileAlert, setShowUpdateMobileAlert] = useState(false);
  // const signUpUserData = useSelector((state) => state.auth.signup_user_otp);
  const loginUserData = useSelector((state) => state.auth.login_user_otp);
  const userData = useSelector((state) => state.auth.userData);
  const goggleLogin = useSelector((state) => state.auth.login_with_google);
  const CORPORATE_PACKAGES_ID = useSelector((state) => state.corporates?.cat_id);
  // console.log("CORPORATE_PACKAGES_ID", CORPORATE_PACKAGES_ID)
  const USER_DETAILS = useSelector((state) => state.userProfile.profile_details.data);
  const showNav = useSelector((state)=>state.common.showNav);
// console.log(showNav);
  const userInfo = JSON.parse(localStorage.getItem("userData"));
  const isCorporateAccount = userInfo?.user_details?.corporate_account;
  const [showModal, setShowModal] = useState(false);
  const [clickType, setClickType] = useState("login");
  const [enableGlobalSearch, setEnableGlobalSearch] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [signupScroll, setSignupScroll] = useState(false);
  // const [showNav, setShowNav] = useState(false);
  const history = useHistory()
  const StyledDialog = styled(Dialog)`
  & > .MuiDialog-container > .MuiPaper-root {
      width: 430px;
      background-color: #F5F5F5;
      position: relative
  }
  @media (max-width:767px){
    & > .MuiDialog-container > .MuiPaper-root {
      width: 100% !important;
      max-width:100% !important;
      background-color: #F5F5F5;
      position: fixed;
      bottom:0;
      margin: 1px;
      max-inline-size: -webkit-fill-available;
  }
}
  & > .MuiDialog-container > .MuiPaper-root > .close-login {
      position: fixed;
      margin-top: -60px;
      left: 45%;
      padding: 10px;
      border-radius: 100%;
      cursor: pointer;
      background-color: #fff
  }
  & > .MuiDialog-container > .MuiPaper-root > .close-login > img{
    height: 15px;
    width: 25px
  }
  `;

  const onRouteChange = ()=>{
    const userDetails = JSON.parse(localStorage.getItem("userData"));
    const isCorpAcc = userDetails?.user_details?.corporate_account;
    if(isCorpAcc){
      console.log('is corporate');
      if(history.location.pathname == "/" || history.location.pathname.indexOf("/events")>-1 || history.location.pathname.indexOf("/experiences")>-1 || history.location.pathname.indexOf("/holidays")>-1 || history.location.pathname == "/my-family-first" || history.location.pathname.indexOf("/event-details")>-1 || history.location.pathname.indexOf("/experience-details")>-1 || history.location.pathname.indexOf("/holiday-details")>-1 || history.location.pathname.indexOf("/stories-detail")>-1 || history.location.pathname == "/package-details"){
        history.push('/corporates')
      }
    }
  };
  useEffect(()=>{
    // console.log(localStorage.getItem("mobileAlertShown"));
    // console.log(true);
    let mobileAlertShown = localStorage.getItem("mobileAlertShown");
    if(loginUserData.email&&!loginUserData.mobile&&(!mobileAlertShown||mobileAlertShown==='false')){
    // setTimeout(function(){ 
    //   setShowUpdateMobileAlert(true);
    //   localStorage.setItem("mobileAlertShown", true);
    // }, 1500);
    }
    setTimeout(()=>{
      // document.getElementById("myNav").style.display = "none";
      // setShowNav(false);
      dispatch(CommonAction.showNav(false));
      document.body.style.overflowY = "scroll";
    }, 100);
    
    
      history.listen(onRouteChange);
    
     
    
  }, [loginUserData]);

  useEffect(() => {
    // if (isCorporateAccount) {
    //   dispatch(ProfileAction.getProfileDetails());
    // }
    let data = JSON.parse(localStorage.getItem("userData"));
    if (data?.token) {
      dispatch(setUserData(data));
      localStorage.setItem("points", USER_DETAILS?.points)
    }
  }, [USER_DETAILS?.points]);

  useEffect(()=>{
    setUserDetails(JSON.parse(localStorage.getItem("userData")));
  }, [USER_DETAILS]);

  //navbar
  //const [navbarOpen, setNavbarOpen] = useState(false);

  useEffect(()=>{
    setTimeout(()=>{
      let urlHisto = JSON.parse(localStorage.getItem('urlHistory')); 
      // console.log('history', urlHisto);
      if(!urlHisto){
          let tmpUrlHist = [];
          tmpUrlHist.push(history.location);
          localStorage.setItem('urlHistory', JSON.stringify(tmpUrlHist));
      }
    });
    history.listen((location)=>{
      // console.log('history', localStorage.getItem('urlHistory'));
      let urlHist = JSON.parse(localStorage.getItem('urlHistory')); 
      if(!!urlHist && Array.isArray(urlHist)){
          if(!urlHist.length || (urlHist.length && urlHist[urlHist.length-1].pathname!==location.pathname)){
              urlHist.push(location);
          }
          
          if(urlHist.length>10){
            urlHist.shift();
          }
          if(urlHist.length>1){
            localStorage.setItem('prevUrl', urlHist[urlHist.length-2].pathname);
          }
          localStorage.setItem('urlHistory', JSON.stringify(urlHist));
    
        }
      // console.log('history', location);
      
    })

  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("mobileAlertShown");
    localStorage.clear();
    dispatch(handleUserLogout());
    props.history.push("/");
    enqueueSnackbar("Logged out successfully", {
      variant: "info",
    });
    window.location.reload()
    // document.body.style.overflowY = "scroll";
    // document.getElementById("myNav").display = "none";
  };
  const handleSubmit = (type) => {
    // closeNav();
    const getToken = localStorage.getItem("token");
    if (getToken === "" || getToken == null) {
      // alert("please login");
      setShowModal(true);
    }
    setClickType(type);
  }
  const handleClose = () => {
    setTimeout(()=>{
      // document.getElementById("myNav").style.display = "none";
      dispatch(CommonAction.showNav(false));
      // setShowNav(false);
      document.body.style.overflowY = "scroll";
    }, 100);
    setShowModal(false);
  }

  const handleLogin = () => {
    props.history.push("/login");
  };

  const handleSignUp = () => {
    props.history.push("/signup");
  };

  window.onclick = function (event) {
    // if (event.target.id === "myNav") {
    //   closeNav();
    // }
  }

  function openNav() {
    // let element = document.getElementById("myNav");
    // element.style.display = "block"
    // setShowNav(true);
    // console.log('show nav');
    dispatch(CommonAction.showNav(true));
    document.body.style.height = "100vh";
    document.body.style.overflowY = "hidden";
  }

  function closeNav() {
    // let element = document.getElementById("myNav");
    // element.style.display = "none";
    // setShowNav(false);
    dispatch(CommonAction.showNav(false));
    document.body.style.overflowY = "scroll";
    //ReactDOM.findDOMNode(element).classList.remove("shadowClass")
  }

  const goToProfilePage = (value) => {
    props.history.push(`/user/profile`);
    localStorage.setItem("profileTab", value)
    // console.log("val", value)
  };

  const goToBookingsPage = () => {
    props.history.push(`/user/bookings`);
    document.body.style.overflowY = "scroll";
  };

  const goToContactUs = () => {
    props.history.push(`/contact-us`);
    window.scrollTo(0, 0);
    document.body.style.overflowY = "scroll";
  };

  const handleSignUpScroll = () => {
    props.history.push(`/corporates`);
    dispatch(ProfileAction.setScroll(true));
  }

  const handleUpdateMobileAlert = (val)=>{
      setShowUpdateMobileAlert(false);
  };
 
  // const userDetails = JSON.parse(localStorage.getItem("userData"));
  const full_name = localStorage.getItem("full_name");
  
  useEffect(()=>{
    setUserDetails(JSON.parse(localStorage.getItem("userData")));
  }, []);
  const gsDisplayLocation = ['/events', '/experiences','/holidays','/corporates','/my-family-first',];
  const isDetailsPage = ()=>{
    
    const detailsPages = ['event-details', 'experience-details', 'holiday-details', 'stories-details', 'package-details']
    console.log(detailsPages.indexOf(history.location.pathname.split('/')[2]));
    return detailsPages.indexOf(history.location.pathname.split('/')[2]) > -1
  };
  const profileImage = localStorage.getItem("profileImage");
  const searchBtnClicked = ()=>{
    setEnableGlobalSearch(true);
  }
  
  return (
    <div>
      <HamNav handleSubmit={handleSubmit} handleLogout={handleLogout} goToProfilePage={goToProfilePage} full_name={full_name? full_name : null} loginUserData={loginUserData} googleLogin={goggleLogin} />
      <div className="header-holder">
        {props.match.path === '/corporates/package-details/:id' || props.match.path === '/corporates/gift-voucher/:id' || props.match.path === '/checkout' || props.match.path === '/checkout-details' || props.match.path === '/buying' || props.match.path === '/voucherCheckout' || props.match.path === '/checkout-details/:name' || props.match.path === '/booking-details' || props.match.path === '/gift-checkout' || props.match.path === '/terms-and-conditions' || props.match.path === '/refund-and-cancellations' || props.match.path === '/privacy-policy' || props.match.path === '/events/event-details/:id' || props.match.path === '/events/checkout' || props.match.path === '/events/checkout-details' || props.match.path === '/holidays/holiday-details/:id' || props.match.path === '/holidays/checkout' || props.match.path === '/holidays/checkout-details' || props.match.path === '/holidays/international/:id' || props.match.path === '/experiences/experience-details/:id' || props.match.path === '/experiences/checkout' || props.match.path === '/experiences/checkout-details' || props.match.path === '/my-family-first/stories-details/:id' || props.match.path === '/weddings/stories-details/:id' ? <Link to={{pathname:`${loginUserData.corporate_account ? '/corporates' : '/'}`, logoClicked:true}}>
          <div className="logo">
            <img src={BlackLogo} alt="Logo" />
          </div>
        </Link> : <Link to={{pathname:`${loginUserData.corporate_account ? '/corporates' : '/'}`, logoClicked:true}} className="d-md-block d-none">
          <div className="logo">
            <img src={Logo} alt="Logo" />
          </div>
        </Link>}
        { !enableGlobalSearch && history.location.pathname == "/" ? <Link to={{pathname:"/", logoClicked:true}} className="d-md-none">
          <div className="logo">
            <img src={Logo} alt="Logo" />
          </div>
        </Link> : null}
        {/* {gsDisplayLocation.indexOf(history.location.pathname) >-1 || isDetailsPage() ? <div className="main-header-search d-none d-md-block"><GlobalSearch isWhiteIcon={true && !isDetailsPage()} /></div> : null} */}
        {/* <Navbar expand="lg" style={{backgroundColor:"#fff"}}>
          <Container>
              <Nav className="me-auto">
                <NavDropdown title="Holidays" id="basic-nav-dropdown">
                  <NavDropdown.Item href="#"></NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Corporates" id="basic-nav-dropdown">
                  <NavDropdown.Item href="#"></NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Experience" id="basic-nav-dropdown">
                  <NavDropdown.Item href="#"></NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href="#">My Family First</Nav.Link>
                <Nav.Link href="#">Events</Nav.Link>
              </Nav>
          </Container>
        </Navbar> */}
        {/* search_btn */}
        <div className={history.location.pathname == '/' ? "d-md-none" : history.location.pathname == '/search' ? "d-none" : ""}>
        {!enableGlobalSearch&& (gsDisplayLocation.indexOf(history.location.pathname) >-2 || history.location.pathname == '/') &&(history.location.pathname!=="/careers"||history.location.pathname=="/job-description")&&(history.location.pathname!=="/about-us")&& <button className="search_btn" onClick={searchBtnClicked} ><img src={searchIconBlack} alt='search-icon' /></button>}
        {enableGlobalSearch&&<GlobalSearch className="mobile-search" enableGlobalSearch={enableGlobalSearch} initFocus={true} onBlur={()=>setEnableGlobalSearch(false)} /> }
        </div>
        {/*  */}
        {((Object.keys(loginUserData).length !== 0 &&
          loginUserData.constructor === Object) || (Object.keys(goggleLogin).length !== 0 &&
            goggleLogin.constructor === Object)) ? (<>
              {/* <div className="profile-holder mob-profile-holder">
                <div className="dropdown">
                  <button
                    className="btn profile-dropdown dropdown-toggle profile--btn"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false" >
                    <div class="gradient-border">
                      {
                        profileImage === "" || profileImage == null ? <div className="name-char"> <p>{userDetails?.user_details?.full_name !== undefined && userDetails?.user_details?.full_name.charAt(0).toUpperCase()}</p>  </div> : <img src={profileImage} alt="" />
                      }
                    </div>
                  </button>
                  <div
                    className="dropdown-menu profile-list"
                    aria-labelledby="dropdownMenuButton" >
                    <a className="dropdown-item" href onClick={() => goToProfilePage("profile")}>
                      My profile
                    </a>
                    <a className="dropdown-item" href onClick={() => goToProfilePage("bookings")}>
                      Bookings
                    </a>
                    <a className="dropdown-item" href onClick={goToContactUs}>
                      Contact Hammock
                    </a>
                    <hr />
                    <a onClick={handleLogout}
                      className="dropdown-item" >
                      Logout
                    </a>
                  </div>
                </div>

              </div> */}
              {/* <div className="profile-holder orig-mob-profile-holder">
                <div className="dropdown">
                  <button
                    className="btn profile-dropdown dropdown-toggle profile--btn"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false" >
                    <div class="gradient-border" onClick={() => goToProfilePage("profile")}>
                      {
                        profileImage === "" || profileImage == null ? <div className="name-char"> <p>{userDetails?.user_details?.full_name !== undefined && userDetails?.user_details?.full_name.charAt(0).toUpperCase()}</p>  </div> : <img src={profileImage} alt="" />
                      }
                    </div>
                  </button>


                </div>

              </div> */}
              {/* After Login */}
              {/* {showNav&&<div id="myNav" 
                className="headerModel ">
                <div  className="overlays afterLogin mobi-boxshadow">
                  <p>hammock</p>
                  <a href="javascript:void(0)" className="closebtn" onClick={closeNav} style={{ color: "black" }}>&times;</a>

                  <div className="overlays-content">

                    <a onClick={() => goToProfilePage("bookings")}>My bookings</a>
                    <a onClick={goToContactUs}>Contact us</a>
                    <a onClick={handleLogout}>Log out</a>


                  </div>
                </div>
              </div>} */}
              {/* <div className="ham newham"><a href="javascript:void(0);" className="icon" style={{ color: "white" }} onClick={openNav}>
                <i className="fa fa-bars"></i>
              </a></div> */}
             

            </>

        ) : (
          <>
            {/* <div className="cta-buttons">
              <Link to={`/contact-us`}>
              <a  className="cursor-pointer contact-btn mr-4" >
                Contact Us
              </a>
              </Link>

              <Link to={`/login`}><a className="outline--btn mr-4 btn" >
                Login
              </a></Link>
              {
                window.location.pathname === '/corporates' ? <a className="signup--btn btn" onClick={handleSignUpScroll}>
                  Employee ? Sign up
                </a> : <Link to={`/signup`}><a className="signup--btn btn" >
                  Sign up
                </a>
                </Link>
              }
            </div> */}

            {/* Mobile view */}
            {/* {(showNav)&&<div  className="headerModel">
              <div  style={showNav ? {} : { display: 'none' }}  className="overlays mobi-boxshadow animate__animated animate__slideInRight animate__faster animate__backOutDown">
                <p>hammock</p>
                <a href="javascript:void(0)" className="closebtn" onClick={closeNav} style={{ color: "black" }}>&times;</a>
                <div className="profile-ham-menu">
                    Guest profile
                </div>
                <div class=" d-flex justify-content-evenly overlays-content">

                  <div>
                    <button className="btn mobiBtns yo1"><a onClick={() => handleSubmit("login")}>Login</a></button><br />
                  </div>
                  <div>
                    <button className="btn mobiBtns yo2"><a onClick={() => handleSubmit("signup")}>Sign Up</a></button>
                  </div>
                </div>
                <hr />
                <div className="ham-nav">
                  <ul >
                    <li><Link to="/events" ><a>Events</a></Link></li>
                    <li><Link to="/events" ><a>Experiences</a></Link></li>
                    <li><Link to="/events" ><a>Holidays</a></Link></li>
                    <li><Link to="/events" ><a>Corporate</a></Link></li>
                    <li><Link to="/events" ><a>My Family First</a></Link></li>
                  </ul>
                </div>
                <hr />
                <div className="ham-nav thin-list">
                  <ul >
                    <li><Link to="/events" ><a>Terms & Conditions</a></Link></li>
                    <li><Link to="/events" ><a>Privacy Policy</a></Link></li>
                    <li><Link to="/events" ><a>Refund & cancellations</a></Link></li>
                  </ul>
                </div>
                <hr />
                <div>
                  <button>Contact hammock</button>
                </div>
              </div>
            </div>} */}
            {/* <div className="ham"><a href="javascript:void(0);" className="icon" style={{ color: "white" }} onClick={openNav} >
              <i className="fa fa-bars"></i>
            </a></div> */}


            <div className="mob-popup">

              <StyledDialog
                open={showModal}
                PaperProps={{
                  style: {
                    width: '430px',
                    backgroundColor: '#fff',
                  },
                }}
              >
                <div className="close-login" onClick={handleClose}>
                  <img src={CloseIcon} alt='close-icon' />
                </div>
                <div className="events-auth-tabs auth-mob">
                  <Tabs defaultActiveKey={clickType == "login" ? 'login' : 'signup'} id="uncontrolled-tab-example">
                    <Tab eventKey="login" title="Login">
                      <div className="events-login-holder">
                        <LoginPopup
                          closeModalHome={showModal}
                          toggleModalHome={setShowModal}
                        />
                      </div>
                    </Tab>
                    <Tab eventKey="signup" title="Signup">
                      <div className="events-login-holder">
                        <SignupPopup closeModalHome={showModal}
                          toggleModalHome={setShowModal}
                        />
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </StyledDialog>
            </div>
          </>
        )}
      </div>
      <Dialog
        open={showUpdateMobileAlert}
        onClose={handleUpdateMobileAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
      >
        {/* <DialogTitle>
         Please  Add your Mobile Number
        </DialogTitle> */}
        <DialogContent>
          <div>
            <div className="d-flex justify-content-center ">
              <p className="h1 text-secondary">
                <MdInfoOutline />
              </p>
            </div>
            <div className="text-center border p-3 bg-light" style={{borderRadius:25}}>
              <h4 class="py-4">Update Your Contact Details</h4>
              <p>Adding phone number to your account will allow us to stay in touch with you. Most of our users have already added their mobile number.</p>
              <div className="col text-center py-4">
              <button onClick={()=>goToProfilePage("profile")} className="btn btn-sm text-white px-4 py-2" style={{background:'linear-gradient(91.61deg, #600ABD 3.87%, #115297 100%)', borderRadius:15}}>
                Add Mobile Number
              </button>
            </div>
            </div>
            
            
          </div>
       
        </DialogContent>
        <DialogActions>
          
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withRouter(Header);
