import React, { useEffect, useRef, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import Header from "../common/header";
import Footer from "../common/footer";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import backIcon from "../../assets/images/common/back-icon.svg";
import moment from "moment";
import * as ExperienceAction from "../../redux/actions/experiencesAction";
import * as CorporateAction from "../../redux/actions/corporatesAuthActions";
import ContactDetailsCard from "../common/contactDetailsCard";
import Testimonials from "../common/testimonial";
import Loader from "../common/loader";
import * as HolidaysAction from "../../redux/actions/holidaysActions";
import HolidayRangePicker from "../holidays/holidayRangePicker";
import CalenderModal from "../corporates/calenderModal";
import GuestsModal from "../common/guestsModal";
import LinkIcon from "../../assets/images/common/feather_external-link.svg";
import Dialog from "@material-ui/core/Dialog";
import styled from "styled-components";
import LoginPopup from "../auth/login";
import SignupPopup from "../auth/signUp";
import { Tabs, Tab } from "react-bootstrap";
import CloseIcon from "../../assets/images/common/close-modal.svg";
import SimilarOffers from "./similarOffers";
import NearByExperiences from "./nearByExperiences";
import ViewMoreExperiences from "./viewMoreExperiences";
import SanitizedStayTagPopup from "../common/sanitizedStayPopup";
import { Helmet } from "react-helmet";
import PackageDetailsCarousel from "../common/packageDetailsCarousel";
import Breadcrum from "../common/breadcrum";

import $ from "jquery";
import {
  scheduleDuration,
  specificScheduleDuration,
} from "../utilities/utillFunc";
import TestimonialPopup from "../common/testimonialPopup";
import useClickOutside from "../../hooks/useClickOutside";
import { ScrollToTop } from "../common";
import "../../assets/css/partials/common/priceNotes.scss";
import PriceNote from "../common/priceNote";
import ItineraryFeatures from "../common/packageDetails/itineraryFeaturesCard";
import PackageTabsHolder from "../common/packageDetails/packageTabsHolder";
import StayFeaturesCard from "../common/packageDetails/stayFeaturesCard";
import CustomerReviews from "../common/packageDetails/customerRevews";
import ItineraryPackageDates from "../common/packageDetails/itineraryPackageDates";
import ItineraryEnquiryForm from "../common/packageDetails/itineraryEnquiryForm";
import HolidayHeader from "../common/holidayHeader";
import { StayPrimaryLandscapeSharp } from "@material-ui/icons";
import Amenities from "../common/packageDetails/amenities";
const owl_images = {
  items: 1,
  margin: 0,
  nav: false,
  loop: true,
  dots: true,
  autoplay: true,
  smartSpeed: 500,
  animateIn: "fadeIn",
  animateOut: "fadeOut",
};

const StyledDialog = styled(Dialog)`
  & > .MuiDialog-container > .MuiPaper-root {
    width: 430px;
    background-color: #f5f5f5;
    position: relative;
  }
  & > .MuiDialog-container > .MuiPaper-root > .close-login {
    position: absolute;
    top: 12px;
    right: 23px;
    cursor: pointer;
  }
`;

function ExperienceDetails(experienceDetailsPage) {
  const location = useLocation();

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const isPack = params.get("isPack");
  const isNearByExperience = JSON.parse(isPack);

  const isSimilarOffer = JSON.parse(isPack);

  const pointsEl = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [loader, setLoader] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [request, setRequest] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [adultGuestCount, setAdultGuestCount] = useState(0);
  const [childrensGuestCount, setChildrensGuestCount] = useState(0);
  const [count, setCount] = useState(0);
  const [clickType, setClickType] = useState("login");
  const [contactTourDates, setcontactTourDates] = useState(true)
  let dateRangeMinDays = null;
  let dateRangeMaxDays = null;
  const [showTestimonialPopup, setShowTestimonialPopup] = useState(false);
  const [popupData, setPopupData] = useState("");

  const [dataChangedBool, setDataChangedBool] = useState(false);
  const [dataChanged, setDataChanged] = useState({});
  const [showCalenderModal, setShowCalenderModal] = useState(false);
  const [showDateRangeModal, setShowDateRangeModal] = useState(false);
  const [showPoupupDateRangeModal, setShowPoupupDateRangeModal] = useState(true);
  const [showContactModal, setShowContactModal] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [resAPIStartDate, setResAPIStartDate] = useState(null);
  const [resAPIEndDate, setResAPIEndDate] = useState(null);
  const [resAPISelectedDates, setResAPISelectedDates] = useState(null);
  const [calendarDuration, setCalendarDuration] = useState();
  const [selectedDates, setSelectedDates] = useState({});
  const [flexiDates, setFlexiDates] = useState([]);
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [isStuck, setIsStuck] = useState(false);
  const [category, setCategory] = useState("Overview");
  const [categoryId, setCategoryId] = useState("overview");
  const experienceID = experienceDetailsPage.match.params.id;
  const loginUserData = useSelector((state) => state.auth.login_user_otp);
  const activePackageIndex = useSelector(
    (state) => state?.experiences?.activePackageIndex
  );
  const EXPERIENCE_DETAILS = useSelector(
    (state) => state?.experiences?.experience_details?.data
  );
  const discriptionRef = useRef();
  const stayRef = useRef();
  const SECTION = useSelector(
    (state) => state?.experiences?.experience_details?.data?.experience?.section
  );
  const IMAGES =
    EXPERIENCE_DETAILS && EXPERIENCE_DETAILS?.experience?.packages[activePackageIndex]?.images;
  const initialPackageData =
    EXPERIENCE_DETAILS && EXPERIENCE_DETAILS?.experience?.packages[activePackageIndex];
  const EXPERIENCE_INFO = EXPERIENCE_DETAILS?.experience?.packages[activePackageIndex];
  const packageType = EXPERIENCE_INFO && EXPERIENCE_INFO.package_type;
  const userDetails = JSON.parse(localStorage.getItem("userData"));
  const [categoryChanged, setcategoryChanged] = useState(false);
  const isCorporateAccount =
    userDetails?.user_details && userDetails.user_details.corporate_account;
  const companyDomain =
    userDetails?.organisation && userDetails.organisation.email_domain;
  const isWipro = companyDomain === "wipro.com";
  const SCROLL_REF = useSelector((state) => state.holidays.set_Scroll);
  const PRICE = useSelector(
    (state) => state?.experiences?.experience_details?.data
  );
  const [activePackId, setActivePackId] = useState(initialPackageData?.id)
  const packageRef = useRef()
  const isStay = packageType == "staybased"
  const isItinerary = packageType == "itinerary"
  const executeScrollTop = () => {

    packageRef?.current.scrollIntoView()
    dispatch(HolidaysAction.setScroll(false))
    window.scrollTo(0, 0)

  };
  useEffect(() => {
    if (SCROLL_REF) {
      let idx = localStorage.getItem("exp-actidx");
      if (idx) {
        packageChangeHandler(idx)
      }
    }
    if (SCROLL_REF && !loader) {
      executeScrollTop();
    }
  }, [SCROLL_REF, loader]);
  useEffect(() => {
    packageRef?.current?.scrollIntoView()
  }, [packageRef?.current])
  const [totalPrice, setotalPrice] = useState(EXPERIENCE_INFO?.price);
  const [packageIdGet, setPackageId] = useState(
    EXPERIENCE_DETAILS?.experience?.packages[0].id
  );
  const [testimonialSH, setTestimonialSH] = useState({});

  const EXPERIENCE_TESTIMONIALS = useSelector(
    (state) => state?.experiences?.experience_testimonials?.data
  );
  const referenceId = useSelector((state) => state?.experiences?.referenceId);
  const reviewStatus = useSelector((state) => state?.experiences?.reviewStatus);
  const reviewList = useSelector((state) => state?.experiences?.reviewList);
  const experienceReviewListTotalPage = useSelector((state) => state?.experiences?.reviewListTotalPage);
  const experienceReviewListCurrentPage = useSelector((state) => state?.experiences?.reviewListCurrentPage);
  const latitude = dataChangedBool
    ? dataChanged?.location[0]?.latitude
    : initialPackageData?.location[0]?.latitude;
  const longitude = dataChangedBool
    ? dataChanged?.location[0]?.longitude
    : initialPackageData?.location[0]?.longitude;
  const isRecurring = dataChangedBool
    ? dataChanged?.schedule_type == "recurring"
    : initialPackageData?.schedule_type == "recurring";
  const isPackage = dataChangedBool
    ? dataChanged?.schedule_type == "package"
    : initialPackageData?.schedule_type == "package";
  const isSpecific = dataChangedBool
    ? dataChanged?.schedule_type == "specific_dates"
    : initialPackageData?.schedule_type == "specific_dates";
  const isDateRange = dataChangedBool
    ? dataChanged?.schedule_type == "date_range"
    : initialPackageData?.schedule_type == "date_range";
  const isFlexible = dataChangedBool
    ? dataChanged?.flexi_schedule
    : initialPackageData?.flexi_schedule;
  let flexiDatesLength = flexiDates?.length;
  const isEnablePayment = dataChangedBool
    ? dataChanged?.enable_payment
    : initialPackageData?.enable_payment;
  const recurringData = dataChangedBool
    ? dataChanged?.schedule_data
    : initialPackageData?.schedule_data;
  const packageData = dataChangedBool
    ? dataChanged?.schedule_data
    : initialPackageData?.schedule_data;
  const specificData = dataChangedBool
    ? dataChanged?.schedule_data
    : initialPackageData?.schedule_data;
  const days = packageData?.days;
  const nights = packageData?.nights;
  const isFiltered = JSON.parse(localStorage.getItem("filteredList"));

  const getSpecificDatesMonth = () => {
    const months = [];
    specificData?.multipleDates.map((date) => {
      months.push(moment(date).format("MMM"));
    });
    const uniqueMonths = [...new Set(months)];
    const specificDatesMonths = uniqueMonths.map((item, index) => {
      return (
        <span> {`${item}${uniqueMonths.length == index + 1 ? "" : ","} `}</span>
      );
    });
    return specificDatesMonths;
  };

  const [scroll, setScroll] = useState(false);
  const myRef = useRef(null);
  const executeScroll = () => {
    if (isItinerary) {
      if (categoryId == "dates") {
        scrolltoView()
      }
      setCategory(Dates);
      setCategoryId("dates");
      setcategoryChanged(true);


    } else {
      myRef.current.scrollIntoView();
      setTimeout(() => document
        .querySelector(".moblie-tab-holder")
        ?.classList.remove("fixed"), 200)
      pauseVideos();
    }
  };
  const id = initialPackageData && initialPackageData.id;
  const contactModalRef = useRef();
  useClickOutside(contactModalRef, (e) => {
    setTimeout(() => setShowContactModal(false), 100);
  });

  const [state, setState] = useState({
    dates: "",
    guest: "",
    infantValidate: "",
    message: "",
  });

  const [error, setError] = useState({
    dates: false,
    guest: false,
    infantValidate: false,
  });

  // calculate days between selected dates
  const calculateDays = () => {
    const arr = [];
    if (!dataChangedBool) {
      if (
        initialPackageData &&
        initialPackageData?.schedule_type == "date_range"
      ) {
        initialPackageData &&
          initialPackageData?.schedule_data?.selectedDates?.map((date) => {
            const startDate = moment(`${date.to}`, "DD-MM-YYYY");
            const endDate = moment(`${date.from}`, "DD-MM-YYYY");
            const result = endDate.diff(startDate, "days");
            arr.push(Math.round(Math.abs(result) + 1));
          });
      }
    } else {
      if (dataChanged && dataChanged?.schedule_type == "date_range") {
        dataChanged &&
          dataChanged?.schedule_data?.selectedDates?.map((date) => {
            const startDate = moment(`${date.to}`, "DD-MM-YYYY");
            const endDate = moment(`${date.from}`, "DD-MM-YYYY");
            const result = endDate.diff(startDate, "days");
            arr.push(Math.round(Math.abs(result) + 1));
          });
      }
    }

    const min = Math.min(...arr);
    const max = Math.max(...arr);
    dateRangeMinDays = min;
    dateRangeMaxDays = max;
    if (min !== max) {
      return (
        <span>
          Min {min} {min == 1 ? "day" : "days"}, Max {max}{" "}
          {max == 1 ? "day" : "days"}
        </span>
      );
    } else {
      return (
        <span>
          {" "}
          Max {max} {min == 1 ? "day" : "days"}
        </span>
      );
    }
  };

  useEffect(() => {
    let isJustLoggedIn = localStorage.getItem("isJustLoggedIn");
    if (isJustLoggedIn === "true") {
      handleSubmit();
    }
    document.getElementById("scroller").scrollIntoView();
    // setMaxDate(initialPackageData?.valid_till);

    let pid = localStorage.getItem("exp-pid");



    dispatch(ExperienceAction.changePackageIndex(0))
    if (pid) {
      setTimeout(() => {
        pointsChangeHandler(parseInt(pid));
      });
    }
    return () => {
      localStorage.removeItem("exp-pid");
    };
  }, []);
  useEffect(() => {
    if (isSimilarOffer) {
      const activePkgId = localStorage.getItem("activePackageId")
      EXPERIENCE_DETAILS?.experience?.packages?.map((pack, idx) => {
        if (activePkgId == pack?.id) {
          dispatch(ExperienceAction.changePackageIndex(idx))
          setActivePackId(pack?.id)
        }
      })
    }
  }, [EXPERIENCE_DETAILS])
  useEffect(() => {
    if (isSimilarOffer) {
      setDataChangedBool(false);
      setShowDateRangeModal(false)
    }
     if (experienceID) {
      dispatch(
        ExperienceAction.getExperienceDetails(experienceID, handleLoader)
      );

    }
    // hide/show confirm button on scroll
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 680);
    });
    localStorage.removeItem("dateRangeIndex");

  }, [experienceID]);

  useEffect(() => {
    setotalPrice(EXPERIENCE_INFO?.revised_price ? EXPERIENCE_INFO?.revised_price : EXPERIENCE_INFO?.price);
    EXPERIENCE_DETAILS?.experience?.packages?.filter((data) => {
      if (data?.schedule_type == "date_range") {
        setSelectedDates(data);
      }
    });
  }, [EXPERIENCE_INFO?.price]);

  const handleLoader = () => {
    setLoader(false);
  };
  const startLoader = () => {
    setLoader(true)
  }
  const packageChangeHandler = (id) => {
    resetFormFields()
    EXPERIENCE_DETAILS?.experience?.packages?.map((pack, idx) => {
      if (id === pack?.id) {
        dispatch(ExperienceAction.changePackageIndex(idx))
        setActivePackId(id)
      }
    })

    // window.scrollTo(0, 0)
  }

  const pointsChangeHandler = (pid) => {
    localStorage.setItem("exp-pid", pid);
    setStartDate(null);
    setEndDate(null);
    setFlexiDates([]);
    setError({
      dates: false,
      guest: false,
      infantValidate: false,
    });
    setState(() => ({
      ...state,
      dates: "",
    }));
    setRequest("");

    dispatch(ExperienceAction.getExperienceDetails(experienceID, handleLoader));
    let packageDuration = dataChangedBool
      ? dataChanged?.schedule_data?.days?.match(/\d+/)[0]
      : initialPackageData !== undefined &&
      initialPackageData?.schedule_data?.days?.match(/\d+/)[0];
    setCalendarDuration(packageDuration);
    setShowDateRangeModal(false);
    EXPERIENCE_DETAILS?.experience?.packages?.filter((data) => {
      if (data?.id == pid) {
        setDataChanged(data);
        setPackageId(data.id);
        setotalPrice(data.price);
      }
    });

    EXPERIENCE_DETAILS?.experience?.packages?.filter((data) => {
      if (data?.schedule_type == "date_range") {
        setSelectedDates(data);
      }
    });
    calculateDays();
    setAdultGuestCount(0);
    setChildrensGuestCount(0);
    setCount(0);
    setDataChangedBool(true);
  };

  const handleChange = (key, value) => {
    setState(() => ({
      ...state,
      [key]: value.target.value,
    }));
  };
  const closeHandler = () => {
    resetFormFields();
    setShowForm(false);
    setShowPoupupDateRangeModal(true)
  };
  const resetFormFields = () => {
    setStartDate(null);
    setEndDate(null);
    setFlexiDates([]);
    setError({
      dates: false,
      guest: false,
      infantValidate: false,
    });
    setState(() => ({
      ...state,
      dates: "",
    }));
    setAdultGuestCount(0);
    setChildrensGuestCount(0);
    setCount(0);
    setRequest("");
  };
  const selectDateRangeHandler = () => {
    setShowDateRangeModal(true);

    pauseVideos();
    if (showDateRangeModal) {
      document.getElementById("holidayModal")?.classList.remove("hide");
      document.getElementById("holidayModal")?.classList.add("show");
    }
    pauseVideos();
  };

  const pauseVideos = () => {
    let player = $("iframe");

    if (player) {
      player.map((index) => {
        $("iframe")[index].contentWindow.postMessage(
          '{"event":"command","func":"' + "pauseVideo" + '","args":""}',
          "*"
        );
        $("iframe")[index].contentWindow.postMessage(
          '{"method":"pause","value":"' + "pauseVideo" + '","args":""}',
          "*"
        );
      });
    }
    $(".owl-item")
      .find("video")
      .each(function () {
        this.pause();
      });
  };
  const handleShowForm = (sDate, eDate) => {
    resetFormFields();

    if (sDate || eDate !== undefined) {
      handleResAPIStartAndEndDate(sDate, eDate, null);
      setShowPoupupDateRangeModal(false)
      setStartDate(sDate);
      setEndDate(eDate);
      setState(() => ({
        ...state,
        dates: `${sDate} - ${eDate}`,
        guest: "",
      }));
    } else {
      setState(() => ({
        ...state,
        dates: "",
        guest: "",
      }));
    }
    setShowCalenderModal(false);
    setShowForm(true);
  };
  useEffect(() => {
    setCategory("Overview");
    setcategoryChanged(false);
    setCategoryId("overview");
    if (initialPackageData?.id) {

      dispatch(ExperienceAction.reviewListing(initialPackageData?.id, 1));
      setActivePackId(initialPackageData?.id)
      setShowDateRangeModal(false)
    }
  }, [initialPackageData?.id]);
  useEffect(() => {
    if (initialPackageData?.id && Object.keys(loginUserData).length !== 0 &&
      loginUserData.constructor === Object) {
      dispatch(ExperienceAction.ReviewPostCheck(initialPackageData?.id));
    }
  },
    [
      initialPackageData?.id, loginUserData
    ])
  const handleReviewListPagination = (e, value) => {
    e.preventDefault();
    dispatch(ExperienceAction.reviewListing(initialPackageData?.id, value));

  }
  const selectDateHandler = () => {
    let packageDuration = dataChangedBool
      ? dataChanged?.schedule_data?.days?.match(/\d+/)[0]
      : initialPackageData !== undefined &&
      initialPackageData?.schedule_data?.days?.match(/\d+/)[0];
    setCalendarDuration(packageDuration);
    setShowCalenderModal(true);
    pauseVideos();
  };

  const handleDatesData = (sDate, eDate, selectedDates) => {
    setShowCalenderModal(false);
    setStartDate(sDate);
    setEndDate(eDate);
    setState(() => ({
      ...state,
      dates: `${sDate} - ${eDate}`,
    }));
    if (selectedDates && selectedDates?.length > 0) {
      setFlexiDates(selectedDates);
      setStartDate(selectedDates[0]);
      setState(() => ({
        ...state,
        dates: selectedDates,
      }));
    }
  };

  const handleResAPIStartAndEndDate = (
    resStartDate,
    resEndDate,
    selectedDates
  ) => {
    setResAPIStartDate(resStartDate);
    setResAPIEndDate(resEndDate);
    setResAPISelectedDates(selectedDates);
  };

  const handleNumberOfGuests = () => {
    setShowModal(true);
    pauseVideos();
    if (showModal) {
      document.getElementById("guestModal").classList.remove("hide");
      document.getElementById("guestModal").classList.add("show");
    }
  };

  const guestDatahandler = (adultData, childrenData, countData) => {
    setShowModal(false);
    setAdultGuestCount(adultData);
    setChildrensGuestCount(childrenData);
    setCount(countData);
    setState(() => ({
      ...state,
      guest: `${adultData} adults, ${childrenData} kids, ${countData} infants`,
    }));
  };

  const similarPackageEmptyData = () => {
    localStorage.removeItem("exp-actidx")
    setAdultGuestCount(0);
    setChildrensGuestCount(0);
    setCount(0);
    setStartDate(null);
    setEndDate(null);
    setFlexiDates([]);
    setState(() => ({
      ...state,
      dates: "",
    }));
    setRequest("");
    setError({
      dates: false,
      guest: false,
      infantValidate: false,
    });
  };

  const calcTestimonialScrollHeight = () => {
    // setTestimonialSH
    if (EXPERIENCE_TESTIMONIALS && EXPERIENCE_TESTIMONIALS.length) {
      let tempTestimonialSH = {};
      EXPERIENCE_TESTIMONIALS?.forEach((val, index) => {
        tempTestimonialSH[`testimonial-block-${index}`] =
          document.getElementById(`testimonial-block-${index}`)?.scrollHeight;
      });
      setTestimonialSH(tempTestimonialSH);
    }
  };

  const handleTestimonialPopup = (item) => {
    setShowTestimonialPopup(true);
    setPopupData(item);
    pauseVideos();
  };

  useEffect(() => {
    setTimeout(() => {
      calcTestimonialScrollHeight();
    });
  }, [EXPERIENCE_TESTIMONIALS]);

  useEffect(() => {
    if (initialPackageData?.id) {
      dispatch(
        ExperienceAction.getTestimonials(
          dataChangedBool ? dataChanged?.id : initialPackageData?.id
        )
      );
    }
  }, [initialPackageData?.id]);
  useEffect(() => {
    if (location?.state?.id && EXPERIENCE_DETAILS?.experience?.packages?.length&&!SCROLL_REF) {
      EXPERIENCE_DETAILS?.experience?.packages?.map((pack, idx) => {
        if (location.state?.id === pack.id) {
          dispatch(ExperienceAction.changePackageIndex(idx))
          setActivePackId(pack?.id)
        }
      })

    }
  }, [location?.state?.id, EXPERIENCE_DETAILS?.experience?.packages])
  const sortedPackages = EXPERIENCE_DETAILS?.experience?.packages?.map((pack, idx) => {
    return {
      id: pack?.id,
      price: pack.revised_price ? pack.revised_price : pack.price
    }
  }).sort((a, b) => a.price - b.price)
  useEffect(() => {
    if (resAPIStartDate || resAPIEndDate || resAPISelectedDates?.length) {
      setError({ dates: false });
    }
  }, [resAPIEndDate, resAPIEndDate, resAPISelectedDates]);
  useEffect(() => {
    if (adultGuestCount !== 0 || childrensGuestCount !== 0 || count !== 0) {
      setError({ guest: false });
    }
  }, [adultGuestCount, childrensGuestCount, count]);

  const openMap = (e, lat, lng) => {
    window.open(`http://www.google.com/maps/place/${lat},${lng}`);
    e.stopPropagation();
  };

  const scrollToFormView = () => {
    var elementPosition = document
      .querySelector(".booking-holder")
      .getBoundingClientRect().top;
    var add = isItinerary ? 150 : 100
    var offsetPosition = elementPosition + window.pageYOffset - add;
    window.scrollTo({ top: offsetPosition, behavior: "smooth" });
  };

  const handleSubmit = () => {
    const formData = JSON.parse(localStorage.getItem("formData"));
    let data = JSON.parse(localStorage.getItem("userData"));
    let mobile = localStorage.getItem("mobile");
    let full_name = localStorage.getItem("full_name");
    const bookData = {
      pack_id: formData?.pack_id,
      adultGuestCount: formData.adultGuestCount,
      childrensGuestCount: formData?.childrensGuestCount,
      count: formData?.count,
      request: formData?.request,
      totalPrice: formData?.totalPrice,
      points: isCorporateAccount ? data?.points : "",
      amount: totalPrice - data.points,
      name: full_name,
      email: data?.user_details?.email,
      contactNumber: mobile,
      resAPIStartDate: formData?.resAPIStartDate,
      resAPIEndDate: formData?.resAPIEndDate,
      resAPISelectedDates: formData?.resAPISelectedDates,
      schedule_type: formData?.schedule_type,
    };
    dispatch(ExperienceAction.handleProceedToBook(bookData));
    dispatch(
      ExperienceAction.changeDataforProceedToBook(
        formData?.dataChanged,
        formData?.dataChangedBool
      )
    );
    dispatch(
      CorporateAction.handleStartAndEndDate(
        formData?.resAPIStartDate,
        formData?.resAPIEndDate,
        formData?.resAPISelectedDates
      )
    );
    history.push("/experiences/checkout");
  };

  const submitHandler = (e) => {
    const getToken = localStorage.getItem("token");
    e.preventDefault();
    if (!state.dates || state.dates === "" || startDate === false) {
      setError({ dates: true });
      scrollToFormView();
    } else if (
      !state.guest ||
      state.guest === "" ||
      state.guest === `${0} adults, ${0} kids, ${0} infants`
    ) {
      setError({ guest: true });
      scrollToFormView();
    } else if (
      (count >= 1 || childrensGuestCount >= 1) &&
      adultGuestCount === 0
    ) {
      setError({ infantValidate: true });
      scrollToFormView();
    } else {
      if (getToken === "" || getToken == null) {
        // alert("please login");
        const formData = {
          pack_id: dataChangedBool ? dataChanged?.id : initialPackageData?.id,
          adultGuestCount,
          childrensGuestCount,
          count,
          request,
          totalPrice,
          resAPIStartDate,
          resAPIEndDate,
          resAPISelectedDates,
          schedule_type: dataChangedBool
            ? dataChanged?.schedule_type
            : initialPackageData?.schedule_type,
          dataChanged: dataChanged,
          dataChangedBool: dataChangedBool,
        };
        localStorage.setItem("formData", JSON.stringify(formData));
        setShowLoginModal(true);
      } else {
        let data = JSON.parse(localStorage.getItem("userData"));
        let mobile = localStorage.getItem("mobile");
        let full_name = localStorage.getItem("full_name");
        const bookData = {
          pack_id: dataChangedBool ? dataChanged?.id : initialPackageData?.id,
          adultGuestCount,
          childrensGuestCount,
          count,
          request,
          totalPrice,
          points: isCorporateAccount ? data?.points : "",
          amount: totalPrice - data.points,
          name: full_name,
          email: data?.user_details?.email,
          contactNumber: mobile,
          resAPIStartDate,
          resAPIEndDate,
          resAPISelectedDates,
          schedule_type: dataChangedBool
            ? dataChanged?.schedule_type
            : initialPackageData?.schedule_type,
        };
        dispatch(ExperienceAction.handleProceedToBook(bookData));
        dispatch(
          ExperienceAction.changeDataforProceedToBook(
            dataChanged,
            dataChangedBool
          )
        );
        dispatch(
          CorporateAction.handleStartAndEndDate(
            resAPIStartDate,
            resAPIEndDate,
            resAPISelectedDates
          )
        );
        history.push("/experiences/checkout");
      }
    }
  };

  const handleClose = () => {
    setShowLoginModal(false);
  };

  const handleContactModal = () => {
    setShowContactModal(true);
    pauseVideos();
  };

  const sanitisedTag = (str) => {
    return str;
  };

  const getGuestCount = () => {
    // `${adultGuestCount} adult(s), ${childrensGuestCount} kid(s), ${count} infant(s)` //old code
    let tmpGuestsArray = [];
    if (adultGuestCount > 0) {
      tmpGuestsArray.push(
        `${adultGuestCount} adult${adultGuestCount > 1 ? "s" : ""}`
      );
    }
    if (childrensGuestCount > 0) {
      tmpGuestsArray.push(
        `${childrensGuestCount} kid${childrensGuestCount > 1 ? "s" : ""}`
      );
    }
    if (count > 0) {
      tmpGuestsArray.push(`${count} infant${count > 1 ? "s" : ""}`);
    }
    return tmpGuestsArray.join(", ");
  };

  const goBackFun = () => {
    let urlHistory = JSON.parse(localStorage.getItem("urlHistory"));
    if (!localStorage.getItem("prevUrl")) {
      history.push("/experiences");
    } else if (
      urlHistory[0].pathname === urlHistory[urlHistory.length - 1].pathname &&
      urlHistory.length > 2
    ) {
      history.push("/experiences");
    } else if (localStorage.getItem("prevUrl") === "/") {
      history.push("/");
    } else if (urlHistory[0].pathname?.includes("/checkout") && localStorage.getItem("prevUrl")?.includes("/checkout")) {
      history.push("/experiences")
    }
    else {
      history.goBack();

    }
  };

  useEffect(() => {
    if (showContactModal || showForm) {
      document.querySelector("html").style.overflow = "hidden";
    } else {
      document.querySelector("html").style.overflow = "scroll";
    }
  }, [showContactModal, showForm]);
  const itineraryDescriptions = {
    overview: initialPackageData && initialPackageData.description,
    itinerary: initialPackageData && initialPackageData.itinerary,
    accommodation: initialPackageData && initialPackageData.accomodations,
    essential_info: initialPackageData && initialPackageData.essential_info,
    faqs: initialPackageData && initialPackageData.faq,
  };
  const Dates = initialPackageData?.schedule_type == "package" ? "Schedule" : "Dates"
  const itineraryTabData = [
    { name: "Overview", id: "overview" },
    { name: "Itinerary", id: "itinerary" },
    { name: "Accommodation", id: "accommodation" },
    { name: "Essential Info", id: "essential_info" },
    { name: "Reviews", id: "reviews" },
    { name: Dates, id: "dates" },
    { name: "FAQs", id: "faqs" },
  ];
  const stayBasedTabData = [
    { name: "Overview", id: "overview" },
    { name: "Reviews", id: "reviews" },
    { name: "Amenities", id: "amenities" },
  ];

  const itineraryFilterTabData = itineraryTabData.filter(item => {
    if (item.id === "reviews" || item.id === "dates") {

      return item

    }
    else {

      if (itineraryDescriptions[item.id] !== null) {
        if (itineraryDescriptions[item.id] !== undefined) {
          if (itineraryDescriptions[item.id] !== "") {
            if (!itineraryDescriptions[item.id]?.startsWith("<span")) {
              return item

            }
          }
        }
      }
    }
  })

  const Tabdata =
    isStay ? stayBasedTabData : itineraryFilterTabData;

  const scrolltoView = (id) => {
    if (isStay) {
      stayRef?.current?.scrollIntoView({
        behavior: "smooth",
      });
    } else {
      discriptionRef?.current?.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (categoryChanged) {
      scrolltoView();
    }
  }, [categoryId, experienceReviewListCurrentPage]);
  function chaneCategory(name, from) {
    if (categoryId == "dates") {
      scrolltoView()
    }
    if (from == "tab") {
      Tabdata.map((e) => {
        if (e.name == name) {
          setCategory(e.name);
          setCategoryId(e.id);
          setcategoryChanged(true);
        }
      });
    } else {
      Tabdata.map((e) => {
        if (e.id == name) {
          setCategory(e.name);
          setCategoryId(e.id);
        }
      });
    }
  }
  const calculateDaysProps = () => {
    if (isDateRange) {
      return calculateDays();
    } else if (isSpecific) {
      return (
        <span>
          In the month of {getSpecificDatesMonth()}{" "}
          {specificScheduleDuration(
            dataChangedBool,
            dataChanged,
            initialPackageData
          )}
        </span>
      );
    } else if (isPackage) {
      return (
        <span>
          {days && days !== "0"
            ? days && days === "1"
              ? `${days} day `
              : `${days} days `
            : ""}
          {nights && nights !== "0"
            ? nights && nights === "1"
              ? `${nights} night`
              : `${nights} nights`
            : ""}
        </span>
      );
    } else if (isRecurring) {
      return (
        <p>
          Every
          {(
            dataChangedBool
              ? dataChanged?.schedule_type == "recurring"
              : initialPackageData !== undefined &&
              initialPackageData?.schedule_type == "recurring"
          )
            ? recurringData && recurringData?.newSelectedDyas?.length > 0
              ? recurringData?.newSelectedDyas.map((index) => {
                switch (index) {
                  case 0:
                    return <span> Sun</span>;
                  case 1:
                    return <span>, Mon</span>;
                  case 2:
                    return <span>, Tue</span>;
                  case 3:
                    return <span>, Wed</span>;
                  case 4:
                    return <span>, Thu</span>;
                  case 5:
                    return <span>, Fri</span>;
                  case 6:
                    return <span>, Sat</span>;
                }
              })
              : ""
            : null}
          {scheduleDuration(dataChangedBool, dataChanged, initialPackageData)}
        </p>
      );
    } else if (isFlexible) {
      return "Flexible Days";
    }
  };

  const prices = () => {
    if (initialPackageData?.revised_price != 0) {
      return (
        <div className="d-flex  pricess">
          {" "}
          <p className="normal">Rs {(initialPackageData.price).toLocaleString('en-IN')}</p>
          <p className="discount"><span>From</span> Rs {(initialPackageData.revised_price).toLocaleString('en-IN')}</p>
        </div>
      );
    } else {
      return (
        <div className="d-flex  pricess">
          <p className="discount"><span>From</span> Rs {(initialPackageData.price).toLocaleString('en-IN')}</p>
        </div>
      );
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {`${initialPackageData !== undefined &&
            initialPackageData?.pack_title
            }`}
        </title>
        <meta
          name="description"
          content={`${initialPackageData !== undefined && initialPackageData?.brief_description
            }`}
        />

        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={
            dataChangedBool
              ? dataChanged?.pack_title
              : initialPackageData?.pack_title
          }
        />
        <meta
          property="og:description"
          content={
            dataChangedBool
              ? dataChanged?.brief_description
              : initialPackageData?.brief_description
          }
        />
        <meta
          property="og:image"
          content={
            dataChangedBool
              ? dataChanged?.images?.filter((im) => im.thumbnail)[0]?.image
              : initialPackageData?.images?.filter((im) => im.thumbnail)[0]
                ?.image
          }
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content={window.location.host} />
        <meta property="twitter:url" content={window.location.href} />
        <meta
          name="twitter:title"
          content={
            dataChangedBool
              ? dataChanged?.pack_title
              : initialPackageData?.pack_title
          }
        />
        <meta
          name="twitter:description"
          content={
            dataChangedBool
              ? dataChanged?.brief_description
              : initialPackageData?.brief_description
          }
        />
        <meta
          name="twitter:image"
          content={
            dataChangedBool
              ? dataChanged?.images?.filter((im) => im.thumbnail)[0]?.image
              : initialPackageData?.images?.filter((im) => im.thumbnail)[0]
                ?.image
          }
        ></meta>
      </Helmet>
      <div id="scroller">
        <div
          className={
            Object.keys(loginUserData).length !== 0 &&
              loginUserData.constructor === Object
              ? "innerPage-header hide-header-mobi"
              : "innerPage-header hide-header-mobi pre-login-header"
          }
        >
          <div className=" d-lg-none d-block">
            <Header />
          </div>
          <div className="d-none d-lg-block">
            <HolidayHeader />
          </div>
        </div>

        {!loader ? (
          <div>
            <div ref={packageRef} className=" innerPage-container itinerary-container innerPage-container-mobi">
              <div className="package-holder" key={EXPERIENCE_INFO?.id}>
                <div className="package-details">
                  {
                    <div className="images--holder">
                      {initialPackageData &&
                        initialPackageData?.images?.length > 0 ? (
                        <PackageDetailsCarousel
                          dataChangedBool={dataChangedBool}
                          initialPackageData={initialPackageData}
                          dataChanged={dataChanged}
                        // showVideoPopup={showVideoPopup}
                        // handleVideoPopup={handleVideoPopup}
                        // popupRef={popupRef}
                        />
                      ) : null}
                    </div>
                  }
                   <div
                            onClick={goBackFun}
                            className="back-button-mobi back-button d-md-none"
                          >
                            <img src={backIcon} alt="backIcon" />
                          </div>
                  <div className={isStay ? "container-fluid row   stay-container" : "container"}>
                    <div className={isStay ? "col-lg-11 col-12 stay-header" : "row"}>


                      <div className={`itinerary-header col-12 p-0 ${!isStay && "itinerary-mob"}`}>
                        <div className={`itinerary-package-title ${packageType==null?"pb-4": !initialPackageData?.covid_safe && !initialPackageData?.note||EXPERIENCE_DETAILS?.experience?.packages
                            .length > 1 ? "pb-5" : "pb-2"}`}>
                          <div
                            onClick={goBackFun}
                            className="back-button d-none d-md-inline-flex"
                          >
                            <img src={backIcon} alt="" />
                          </div>
                          <p className={packageType === null && "d-none d-md-block"}>
                            <span>
                              {" "}
                              {
                                initialPackageData !== undefined &&
                                initialPackageData.pack_title}
                            </span>
                          </p>
                        </div>
                        {EXPERIENCE_DETAILS &&
                          EXPERIENCE_DETAILS.experience?.packages
                            .length > 1 && packageType !== null &&
                          <div className={`${isStay ? "col-lg-11" : "col-lg-12"} d-flex packages-prices p-0 ${!initialPackageData.covid_safe && !initialPackageData?.note ? "mb-4" : ""}`}>
                            {

                              sortedPackages && sortedPackages.map((pack, idx) => (


                                <button onClick={() => packageChangeHandler(pack.id)} key={idx} className={`pkg-buttons ${pack?.id == activePackId ? "active-pkg" : "non-active-pkg"}`}><span>Rs.{(pack.price).toLocaleString('en-IN')}</span></button>


                              ))

                            }
                          </div>
                        }
                        {packageType !== null && (
                          <div
                            className={` row d-flex  ${initialPackageData?.note &&
                              !initialPackageData?.covid_safe === true
                              ? "note"
                              : `safe-note ${!initialPackageData?.note && "sanitized-row"}`
                              }  align-items-center`}
                          >
                            <div
                              className={
                                initialPackageData?.note
                                  ? "col-12 col-lg-6 "
                                  : "col-12 col-lg-12 "
                              }
                            >
                              {initialPackageData &&
                                initialPackageData?.covid_safe === true ? (
                                <SanitizedStayTagPopup
                                  pauseVideos={pauseVideos}
                                />
                              ) : null}
                            </div>
                            <div
                              className={
                                initialPackageData?.covid_safe === true
                                  ? "col-12 col-lg-6 "
                                  : "col-12 col-lg-12 "
                              }
                            >
                              {initialPackageData?.note && (
                                <PriceNote note={initialPackageData.note} />
                              )}
                            </div>
                          </div>
                        )}
                      </div>

                    </div>

                    {isItinerary && (
                      <ItineraryFeatures
                        data={initialPackageData}
                        calculateDays={calculateDaysProps()}
                        chaneCategory={chaneCategory}
                        dates={Dates}
                      />
                    )}
                    <div
                      ref={discriptionRef}
                      id={categoryId}
                      className={
                        packageType !== null ? ` row ${isItinerary ? "itinerary-package-Tabs-description" : "packageTabs-description col-lg-11 col-12 row p-0"}` : "row"
                      }
                    >

                      {isItinerary && (
                        <div className="col-12 col-lg-12">
                          <PackageTabsHolder
                            isFromExperience={true}
                            isStay={isStay}
                            isItinerary={isItinerary}
                            isStuck={isStuck}
                            setIsStuck={setIsStuck}
                            category={category}
                            setCategory={chaneCategory}
                            Tabdata={Tabdata.map((e) => e.name)}
                          />
                        </div>
                      )}

                      <div
                        className={
                          packageType == null || isItinerary && category === "Overview" ||
                            category === "Accommodation" ||
                            category === "Essential Info" ||
                            category === "Itinerary"
                            ? "col-12 col-lg-8 "
                            : isStay
                              ? "col-12 col-lg-9 stay-tabs-discription "
                              : "col-12 col-lg-12 "
                        }
                      >
                        {packageType === null && (
                          <div>
                            <div className="package-title d-none d-md-block">

                              <p className="d-flex ">
                              {initialPackageData&& initialPackageData?.location?.length > 1 ? `${(initialPackageData?.location_city || initialPackageData?.location[0]?.city)}, ${initialPackageData?.location?.[1].address}` :
                                    `${(initialPackageData?.location_city || initialPackageData?.location[0]?.city)}${(initialPackageData?.location[0]?.state)?",":""} ${(initialPackageData?.location[0]?.state)}`
                                  }
                                <span
                                  className="open-maps d-flex "
                                  onClick={(e) =>
                                    openMap(e, latitude, longitude)
                                  }
                                >
                                  See in map <img src={LinkIcon} alt='LinkIcon' />{" "}
                                </span>
                              </p>
                              {calculateDaysProps()}
                            </div>
                            <div
                              className="line d-none d-md-block"
                              style={{ marginTop: "-30px" }}
                            ></div>
                            {/* mobileview */}
                            <div className="package-title d-block d-md-none">
                              <h1>
                                {
                                  initialPackageData?.pack_title}
                              </h1>
                              <p className="d-flex">
                              {initialPackageData&& initialPackageData?.location?.length > 1 ? `${(initialPackageData?.location_city || initialPackageData?.location[0]?.city)}, ${initialPackageData?.location?.[1]?.address}` :
                                    `${(initialPackageData?.location_city || initialPackageData?.location[0]?.city)}${(initialPackageData?.location[0]?.state)?",":""} ${(initialPackageData?.location[0]?.state)}`
                                  }
                                <span
                                  className="open-maps d-flex align-items-center"
                                  onClick={(e) =>
                                    openMap(e, latitude, longitude)
                                  }
                                >
                                  See in map <img src={LinkIcon} alt='LinkIcon' />{" "}
                                </span>
                              </p>
                              {calculateDaysProps()}
                            </div>
                            <div>
                              {EXPERIENCE_DETAILS && (
                                <p className="mb-price">
                                  {isCorporateAccount && isWipro ? (
                                    <label ref={pointsEl}>
                                      {(initialPackageData?.price).toLocaleString('en-IN')}{" "}
                                      pts{" "}
                                    </label>
                                  ) : (
                                    <label ref={pointsEl}>
                                      Rs{" "}
                                      {(initialPackageData?.price).toLocaleString('en-IN')}{" "}
                                    </label>
                                  )}
                                </p>
                              )}
                            </div>
                            <div className="select-points d-none">
                              <form>
                                <div className="d-flex flex-wrap">
                                  {EXPERIENCE_DETAILS &&
                                    EXPERIENCE_DETAILS?.experience?.packages
                                      .length > 0 &&
                                    EXPERIENCE_DETAILS?.experience?.packages.map(
                                      (pack, index) => (
                                        <div>
                                          <input
                                            type="radio"
                                            id={"points"[index]}
                                            name="points"
                                            value={pack.id}
                                            onClick={() =>
                                              pointsChangeHandler(pack.id)
                                            }
                                            defaultChecked={
                                              parseInt(
                                                localStorage.getItem(
                                                  "exp-pid"
                                                )
                                              ) === pack.id ||
                                              EXPERIENCE_DETAILS.experience
                                                .packages[0].id === pack.id
                                            }
                                          />
                                          {isCorporateAccount && isWipro ? (
                                            <label
                                              for={"points"[index]}
                                              ref={pointsEl}
                                            >
                                              {pack.price} pts{" "}
                                            </label>
                                          ) : (
                                            <label
                                              for={"points"[index]}
                                              ref={pointsEl}
                                            >
                                              Rs {pack.price}{" "}
                                            </label>
                                          )}
                                        </div>
                                      )
                                    )}
                                </div>
                              </form>
                            </div>
                            {EXPERIENCE_DETAILS &&
                          EXPERIENCE_DETAILS?.experience?.packages
                            .length > 1 &&
                          <div className={`${isStay ? "col-lg-11" : "col-lg-12"} d-flex packages-prices p-0 ${!initialPackageData.covid_safe && !initialPackageData?.note ? "mb-4" : ""}`}>
                            {

                              sortedPackages && sortedPackages.map((pack, idx) => (


                                <button onClick={() => packageChangeHandler(pack.id)} key={idx} className={`pkg-buttons ${pack?.id == activePackId ? "active-pkg" : "non-active-pkg"}`}><span>Rs.{(pack.price).toLocaleString('en-IN')}</span></button>


                              ))

                            }
                          </div>
                        }
                            {initialPackageData &&
                              initialPackageData.note ? (
                              <PriceNote note={initialPackageData?.note} />
                            ) : null}
                            {initialPackageData &&
                              initialPackageData.covid_safe === true ? (
                              <SanitizedStayTagPopup
                                experienceStay={sanitisedTag}
                                pauseVideos={pauseVideos}
                              />
                            ) : null}
                          </div>
                        )}

                        {isStay && (
                          <StayFeaturesCard
                            initialPackageData={
                              initialPackageData && initialPackageData
                            }
                            calculateDays={calculateDaysProps()}
                          />
                        )}
                        <div className={
                          isStay
                            ? "stay-package-tabs-description" : ""}>
                          {isStay && (
                            <PackageTabsHolder
                              category={category}
                              setCategory={chaneCategory}
                              Tabdata={Tabdata.map((e) => e.name)}
                              isStay={isStay}
                              isItinerary={isItinerary}
                              isStuck={isStuck}
                              setIsStuck={setIsStuck}
                            />
                          )}

                          <div
                            ref={stayRef}
                            className={
                              isItinerary
                                ? "itinerary-package-description stay-desc" : isStay && isStuck ? "stay-sticky-description stay-desc"
                                  : isStay
                                    ? "stay-package-description stay stay-desc"
                                    : "package-description"
                            }
                          >
                            {packageType === null && (
                              <p className="mb-3">
                                Validity till :{" "}
                                {initialPackageData !== undefined &&
                                  moment(initialPackageData.valid_till).format(
                                    "Do MMMM, YYYY"
                                  )}
                              </p>
                            )}
                            {category !== "Amenities" && category !== "Reviews" && categoryId !== "dates" &&
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: itineraryDescriptions[categoryId],
                                }}
                              ></p>
                            }
                            {category == "Reviews" && (
                              <CustomerReviews
                                packageTitle={
                                  initialPackageData &&
                                  initialPackageData?.pack_title
                                }
                                Id={id}
                                chaneCategory={chaneCategory}
                                reviewList={reviewList}
                                reviewStatus={reviewStatus}
                                isFromExperience={true}

                                referenceId={referenceId}
                                totalPage={experienceReviewListTotalPage}
                                handlePagination={handleReviewListPagination}
                                currentPage={experienceReviewListCurrentPage}
                              />
                            )}
                            {category == "Amenities" && (
                              <Amenities
                                initialPackageData={
                                  initialPackageData && initialPackageData
                                }
                              />
                            )}
                            {categoryId == "dates" && (
                              <ItineraryPackageDates
                                handleShowForm={handleShowForm}
                                packageData={
                                  EXPERIENCE_INFO.schedule_data.selectedDates
                                }
                                calculateDaysProps={calculateDaysProps}
                              />
                            )}
                          </div>
                        </div>
                      </div>

                      <div
                        className={
                          packageType == null || isItinerary && category == "Overview" ||
                            category == "Accommodation" ||
                            category == "Essential Info" ||
                            category == "Itinerary" ||
                            showForm
                            ? "col-12 col-lg-4"
                            : isStay
                              ? "col-12 col-lg-3 stay-forms"
                              : "d-none"
                        }
                        ref={myRef}
                      >
                        {packageType === null && (
                          <div>
                            {" "}
                            {EXPERIENCE_DETAILS &&
                              isCorporateAccount &&
                              isWipro ? (
                              <p className="price" ref={pointsEl}>
                                {(initialPackageData?.price).toLocaleString('en-IN')}{" "}
                                pts{" "}
                              </p>
                            ) : (
                              <p className="price" ref={pointsEl}>
                                Rs{" "}
                                {(initialPackageData?.price).toLocaleString('en-IN')}{" "}
                              </p>
                            )}
                          </div>
                        )}
                        <div>
                          <div
                            className={showForm ? "tour-planner-model" : ""}
                          >
                            <div
                              className={
                                showForm
                                  ? "login-holder booking-holder contact-tour-planner"
                                  : "login-holder booking-holder "
                              }
                            >
                              <span className="d-flex" onClick={closeHandler}>
                                <img
                                  src={CloseIcon}
                                  alt='close'
                                  className={
                                    showForm ? "d-block close-icon" : "d-none"
                                  }
                                />
                              </span>

                              {isStay && (
                                <div className="stay-price  d-flex flex-column  align-items-center">
                                  {prices()}
                                  <p>{calculateDaysProps()}</p>
                                </div>
                              )}
                              <form>
                                {(
                                  initialPackageData !== undefined &&
                                  initialPackageData?.schedule_type ==
                                  "date_range"
                                ) ? (
                                  <div className="form-group position-relative position-relative">
                                    <input
                                      className="form-control"
                                      type="text"
                                      placeholder="From - To"
                                      readOnly
                                      onClick={selectDateRangeHandler}
                                      value={
                                        startDate && endDate !== null
                                          ? `${moment(
                                            startDate,
                                            "DD-MM-YYYY"
                                          ).format(
                                            "ddd-DD MMM "
                                          )} to ${moment(
                                            endDate,
                                            "DD-MM-YYYY"
                                          ).format("ddd-DD MMM")}`
                                          : ""
                                      }
                                      onChange={(value) =>
                                        handleChange("dates", value)
                                      }
                                    />
                                    <label for="">Preferred dates</label>
                                    {error.dates === true ? (
                                      <div className="errorMsg text-center">
                                        Please select dates
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    {showDateRangeModal && showPoupupDateRangeModal && (
                                      <HolidayRangePicker
                                        showMyModal={showDateRangeModal}
                                        handleDatesData={handleDatesData}
                                        handleResAPIStartAndEndDate={
                                          handleResAPIStartAndEndDate
                                        }
                                        dateRangeMinDays={dateRangeMinDays}
                                        dateRangeMaxDays={dateRangeMaxDays}
                                        dateList={
                                          dataChangedBool
                                            ? dataChanged?.schedule_data
                                              .selectedDates
                                            : initialPackageData !==
                                            undefined &&
                                            initialPackageData
                                              ?.schedule_data.selectedDates
                                        }
                                      />
                                    )}
                                  </div>
                                ) : (
                                  <div className="form-group position-relative position-relative">
                                    {isDateRange || isPackage ? (
                                      <input
                                        className="form-control"
                                        type="text"
                                        readOnly
                                        placeholder={
                                          isPackage
                                            ? "Check in - Check out"
                                            : "Select dates"
                                        }
                                        onClick={selectDateHandler}
                                        value={
                                          startDate && endDate !== null
                                            ? `${moment(startDate).format(
                                              "ddd-DD MMM"
                                            )} to ${moment(endDate).format(
                                              "ddd-DD MMM"
                                            )}`
                                            : ""
                                        }
                                        onChange={(value) =>
                                          handleChange("dates", value)
                                        }
                                      />
                                    ) : !isFlexible ? (
                                      <input
                                        className="form-control"
                                        type="text"
                                        readOnly
                                        placeholder={
                                          isPackage
                                            ? "Check in - Check out"
                                            : "Select a date"
                                        }
                                        onClick={selectDateHandler}
                                        value={
                                          startDate && endDate !== null
                                            ? `${moment(startDate).format(
                                              "ddd-DD MMM"
                                            )}`
                                            : ""
                                        }
                                        onChange={(value) =>
                                          handleChange("dates", value)
                                        }
                                      />
                                    ) : (
                                      <div className="flexiDates">
                                        <input
                                          className={
                                            flexiDatesLength > 2
                                              ? "form-control text-left pl-0"
                                              : "form-control"
                                          }
                                          type="text"
                                          readOnly
                                          placeholder={
                                            isFlexible ? "Select dates" : ""
                                          }
                                          onClick={selectDateHandler}
                                          value={
                                            flexiDates &&
                                              flexiDates.length > 0
                                              ? flexiDates &&
                                                flexiDates.length == 1
                                                ? `${moment(
                                                  flexiDates[0],
                                                  "DD-MM-YYYY"
                                                ).format("ddd-DD MMM")} `
                                                : `${moment(
                                                  flexiDates[0],
                                                  "DD-MM-YYYY"
                                                ).format(
                                                  "ddd-DD MMM"
                                                )}, ${moment(
                                                  flexiDates[1],
                                                  "DD-MM-YYYY"
                                                ).format("ddd-DD MMM")} `
                                              : ""
                                          }
                                          onChange={(value) =>
                                            handleChange("dates", value)
                                          }
                                        />
                                        {flexiDatesLength > 2 ? (
                                          <span onClick={selectDateHandler}>
                                            +{flexiDatesLength - 2} more
                                          </span>
                                        ) : null}
                                      </div>
                                    )}

                                    <label for="">
                                      {isPackage
                                        ? "Preferred dates"
                                        : "Preferred dates"}
                                    </label>
                                    {error.dates === true ? (
                                      <div className="errorMsg text-center">
                                        Please pick a date
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    {showCalenderModal ? (
                                      <CalenderModal
                                        duration={calendarDuration}
                                        showMyModal={showCalenderModal}
                                        handleDatesData={handleDatesData}
                                        handleResAPIStartAndEndDate={
                                          handleResAPIStartAndEndDate
                                        }
                                        isRecurring={isRecurring}
                                        recurringData={
                                          isRecurring ? recurringData : ""
                                        }
                                        packageDays={days}
                                        packageNights={nights}
                                        isExperience={true}
                                        isPackage={isPackage}
                                        isSpecific={isSpecific}
                                        isFlexible={isFlexible}
                                        // maxDate={maxDate}
                                        value={flexiDates}
                                        prevStartDate={startDate}
                                        prevEndDate={endDate}
                                        getSpecificDatesMonth={
                                          getSpecificDatesMonth
                                        }
                                        recurringDaysList={
                                          dataChangedBool
                                            ? dataChanged?.schedule_data
                                              ?.newSelectedDyas
                                            : initialPackageData !==
                                            undefined &&
                                            initialPackageData
                                              ?.schedule_data
                                              ?.newSelectedDyas
                                        }
                                        specificDaysList={
                                          dataChangedBool
                                            ? dataChanged?.schedule_data
                                              ?.multipleDates
                                            : initialPackageData !==
                                            undefined &&
                                            initialPackageData
                                              ?.schedule_data?.multipleDates
                                        }
                                      />
                                    ) : null}
                                  </div>
                                )}
                                <div className="form-group position-relative position-relative">
                                  <input
                                    type="text"
                                    className="form-control"
                                    readOnly
                                    placeholder="Number of guests"
                                    onChange={(value) =>
                                      handleChange("guest", value)
                                    }
                                    onClick={handleNumberOfGuests}
                                    value={
                                      adultGuestCount !== 0 ||
                                        childrensGuestCount !== 0 ||
                                        count !== 0
                                        ? getGuestCount()
                                        : ""
                                    }
                                  />
                                  <label for="">Guests</label>
                                  {error.guest === true ? (
                                    <div className="errorMsg text-center">
                                      Please enter number of guests
                                    </div>
                                  ) : null}
                                  {error.infantValidate === true ? (
                                    <div className="errorMsg text-center">
                                      For Infants/children at least 1 adult is
                                      required
                                    </div>
                                  ) : null}
                                  {showModal && (
                                    <GuestsModal
                                      showMyModal={showModal}
                                      guestDatahandler={guestDatahandler}
                                      adultCount={adultGuestCount}
                                      childrensCount={childrensGuestCount}
                                      infantCount={count}
                                    />
                                  )}
                                </div>
                                {/* <div className="form-group position-relative specific-request">
                              <label for="">Any specific request</label>
                              <div className="custom-request">
                                <input
                                  type="text"
                                  name=""
                                  id=""
                                  placeholder="Enter here"
                                  value={request}
                                  onChange={(e) => setRequest(e.target.value)}
                                />
                                <div class="form-line"></div>
                                <div className="example">
                                  <p>Example:</p>
                                  <p>
                                    I am out of points, Can I extend my stay by
                                    paying
                                  </p>
                                </div>
                              </div>
                            </div> */}
                                <div className="line-custom"></div>
                                {/* {isEnablePayment && (
                              <div className="total-amount">
                                {isCorporateAccount && isWipro ? (
                                  <p>{totalPrice} pts</p>
                                ) : (
                                  <p>Rs {totalPrice}</p>
                                )}
                              </div>
                            )} */}
                                <div>
                                  <button
                                    type="button"
                                    className="btn proceed-to-book w-100"
                                    onClick={submitHandler}
                                  >
                                    {isEnablePayment
                                      ? `Proceed to book`
                                      : `Enquire now`}
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                          <ItineraryEnquiryForm
                            packageType={packageType}
                            showForm={showForm}
                          />
                        </div>


                        <div
                          className={`d-none ${packageType === null ? "d-md-block" : ""
                            }  sticky-contact`}
                        >
                          <ContactDetailsCard
                            isEnablePayment={isEnablePayment}
                          />
                        </div>
                      </div>
                      {showContactModal ? (
                        <div className="contact-modal">
                          <ContactDetailsCard
                            openModal={setShowContactModal}
                            contactModalRef={contactModalRef}
                          />
                        </div>
                      ) : null}
                    </div>
                    {EXPERIENCE_TESTIMONIALS &&
                      EXPERIENCE_TESTIMONIALS?.length > 0 ? (
                      <div className={isStay ? "col-lg-11 col-12" : ""}>
                        <Testimonials
                          testimonialsData={EXPERIENCE_TESTIMONIALS}
                          testimonialSH={testimonialSH}
                          handleTestimonialPopup={handleTestimonialPopup}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className={scroll ? "d-none" : "d-block"}>
              <div className="mobile-buttons">
                <div className="stcky-leftButton">
                  <div className="white-button" onClick={handleContactModal}>
                    <a>Contact</a>
                  </div>
                </div>
                <div className="stcky-rightButton">
                  <div className="custom-button" onClick={executeScroll}>
                    <a>
                      {isItinerary ? Dates : isEnablePayment
                        ? `Book this`
                        : `Enquire Now`}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Loader />
        )}
        <div>
          {
            // open login and signup modal
          }
          {showTestimonialPopup ? (
            <TestimonialPopup
              openModal={setShowTestimonialPopup}
              item={popupData}
            />
          ) : null}
          <StyledDialog
            open={showLoginModal}
            PaperProps={{
              style: {
                width: "430px",
                backgroundColor: "#fff",
              },
            }}
          >
            <div className="close-login" onClick={handleClose}>
              <img src={CloseIcon} alt='close' />
            </div>
            <div className="events-auth-tabs">
              <Tabs
                defaultActiveKey={clickType == "login" ? "login" : "signup"}
                onSelect={(k) => setClickType(k)}
                id="uncontrolled-tab-example"
              >
                <Tab eventKey="login" title="Login">
                  <div className="events-login-holder">
                    <LoginPopup
                      closeModalEvent={showLoginModal}
                      toggleModalEvent={setShowLoginModal}
                      clickType={clickType}
                    />
                  </div>
                </Tab>
                <Tab eventKey="signup" title="Signup">
                  <div className="events-login-holder">
                    <SignupPopup
                      closeModalEvent={showLoginModal}
                      toggleModalEvent={setShowLoginModal}
                      clickType={clickType}
                    />
                  </div>
                </Tab>
              </Tabs>
            </div>
          </StyledDialog>
        </div>
        {/* {
                <SimilarOffers
                    similarPackages={
                        dataChangedBool ? dataChanged?.id : initialPackageData?.id
                    }
                    similarPackageEmptyData={similarPackageEmptyData}
                />
            } */}
        {
          <NearByExperiences
            similarPackageEmptyData={similarPackageEmptyData}
            startLoader={startLoader}
            experienceID={experienceID}
            similarPackages={
              dataChangedBool ? dataChanged?.id : initialPackageData?.id
            }
          />
        }
        {
          <ViewMoreExperiences
            similarPackageEmptyData={similarPackageEmptyData}
            startLoader={startLoader}
            experienceID={experienceID}
            similarPackages={
              dataChangedBool ? dataChanged?.id : initialPackageData?.id
            }
          />
        }
        <ScrollToTop />
        <Footer />
      </div>
    </>
  );
}

export default ExperienceDetails;
