import React, { useEffect, useState } from "react";
import CallbackForm from "../../../holidays/callbackForm";
import PostReviewPopup from "./postReviewPopup";
import * as HolidaysAction from "../../../../redux/actions/holidaysActions";
import * as ExperienceAction from "../../../../redux/actions/experiencesAction";
import * as CorporateAction from "../../../../redux/actions/corporatesAuthActions";
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";
import ReviewCard from "./reviewCard";
import { Dialog, DialogTitle } from "@material-ui/core";
import SuccessfulLogo from "../../../../assets/icons/authIcons/successfulLogo.png";
import { Pagination } from "@material-ui/lab";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .Mui-selected": {
      border: "1px solid",
      borderColor:"#600ABD",
      backgroundColor:"#fff",
      color:"#600ABD"
    }
  }
}));
const CustomerReviews = ({
  packageTitle,
  referenceId,
  Id,
  isFromExperience,
  reviewStatus,
  reviewList,
  totalPage,
  handlePagination,
  currentPage,
  isFromCorporate,
  chaneCategory
}) => {
  const [error, setError] = useState({
    star: false,
    commant: false,
  });
  const dispatch = useDispatch();

  
  const classes = useStyles();
  const [showForm, setShowForm] = useState(false);
  const [reviewStar, setReviewStar] = useState(0);
  const [reviewCommant, setReviewCommant] = useState("");
  const [showModal, setShowModal] = useState(false);
  const setpopup = () => {
    setShowForm(!showForm);
  };

  useEffect(() => {
    if (reviewStatus !== null) {
      setShowModal(true);
      setTimeout(function () {
        setShowModal(false);
        if(isFromCorporate){
          dispatch(CorporateAction.ReviewPostCheck(Id));
          dispatch(CorporateAction.reviewStatusClearUp())
        }
        else{
        if (isFromExperience) {
          dispatch(ExperienceAction.ReviewPostCheck(Id));
          dispatch(ExperienceAction.reviewStatusClearUp())
        } else {
          dispatch(HolidaysAction.ReviewPostCheck(Id));
          dispatch(HolidaysAction.reviewStatusClearUp());
        }
      
      }
      if(reviewList?.results?.length === 0||referenceId&&referenceId.length===0){
        chaneCategory("Overview","tab")
      }
      }, 1000);
    }
  }, [reviewStatus]);
  const postReview = (e) => {
    e.preventDefault();

    if (reviewCommant.length == 0) {
      setError({ commant: true });
    } else if (reviewStar === 0) {
      setError({ star: true });
    } else {
      setError({ star: false });
    }
    if (reviewStar != 0 && reviewCommant.length != 0) {
      if(isFromCorporate){
        dispatch(
          CorporateAction.postReview(
            Id,
            reviewCommant,
            parseInt(reviewStar),
            referenceId[0]
          ))
      }else{
      if (isFromExperience) {
        dispatch(
          ExperienceAction.postReview(
            Id,
            reviewCommant,
            parseInt(reviewStar),
            referenceId[0]
          )
        );
      } else {
        dispatch(
          HolidaysAction.postReview(
            Id,
            reviewCommant,
            parseInt(reviewStar),
            referenceId[0]
          )
        );
      }
    }
      setShowForm(false);
      setReviewStar(0);
      setReviewCommant("");
    }
  };
  const closePopup=()=>{
    setShowForm(false)
    setError({
      star: false,
      commant: false,
    })
  }
  return (
    <div className="customer-reviews">
      <div className="reviews-header d-flex justify-content-between align-items-center">
   { reviewList?.results?.length!==0 || referenceId!==null&&referenceId?.length!==0?  <h1>Customer reviews</h1>:null}
        {referenceId&&referenceId.length!==0&&
        <button
          onClick={() => setpopup()}
          disabled={
            referenceId?.length===0 || referenceId === undefined||referenceId===null ? true : false
          }
          className={
            referenceId?.length===0|| referenceId === undefined ||referenceId===null 
              ? "disabled"
              : "btn header-btn"
          }
        >
          Post your review
        </button>
}
       
      </div>
      <div className="reviews-list" style={{}}>
          {
            reviewList?.results?.map((data,i)=>(
            <ReviewCard name={data.user?data.user.full_name:data.custom_user_name}
            date={!data.user?data.custom_date?data.custom_date:data.created:data.created}
            //  date={data.created} 
             rating={data.review_star} img={data.user?data.user.image:""} commant={data.review_comment} key={i}/>
            ))
          }
          {reviewList?.results?.length>0&&<div className="end-line d-block d-md-none"></div>}
        </div>
        {totalPage!==null&&totalPage!==1&&
        <div className="d-flex justify-content-end">
        <Pagination page={currentPage} count={totalPage}  variant="outlined" shape="rounded" onChange={(event, value) => handlePagination(event, value)} className={classes.root} />
        </div>}
      {showForm && (
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-10">
              <div className="visa-callback-form">
                <PostReviewPopup
                  setReviewStar={setReviewStar}
                  setReviewCommant={setReviewCommant}
                  packageTitle={packageTitle && packageTitle}
                  showForm={showForm}
                  closePopup={closePopup}
                  postReview={postReview}
                  error={error}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {showModal && (
        <div
         
        >
             <div className="login-success-popup">
        <Dialog
          open={showModal}
          maxWidth="xs"
          fullWidth={true}
         
        >
          <DialogTitle>
            <div className="success-holder" style={{}}>
              <div>
                <img src={SuccessfulLogo} alt="successful" />
              </div>
              <div className="success-message">
                <p>{reviewStatus.includes("Succes")?"Thank you for posting your review!...":reviewStatus}</p>
              </div>
            </div>
          </DialogTitle>
        </Dialog>
      </div>
        </div>
      )}
    </div>
  );
};

export default CustomerReviews;
