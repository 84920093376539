import help1 from "../assets/images/home/Converted/help1.webp";
import help2 from "../assets/images/home/Converted/help2.webp";

import school1 from "../assets/images/home/Converted/school1.webp";
import school2 from "../assets/images/home/Converted/school2 2.webp";

import hospitality1 from "../assets/images/home/Converted/hospitality1.webp";
import hospitality2 from "../assets/images/home/Converted/hospitality2.webp";
import hospitality3 from "../assets/images/home/Converted/hospitality3.webp";
import hospitality4 from "../assets/images/home/hospitality4.jpg";

// Data for traveller info
export const HELP_TRAVELLERS = [
  {
    title: "Visa application",
    description: "Our professional services hep you in completing all your Visa formalities with clear documentation, as per individual country specific regulations.  Our Visa facilitation services cover regular & E-Visas; for Leisure travel - Corporate travel - Group travel - Students and so on..",
    image: `${help1}`,
  },
  {
    title: "Passport renewal / application",
    description: 'Documentation & Assistance in obtaining passports, Authentication of support documents, Forex, Travel insurance are all part of our service portfolio.',
    image: `${help2}`,
  },
];

// Data for school experiences
export const EXPERIENCE_SCHOOL = [
  {
    title: "Field Trips",
    description: "Combining Experiential Learning, Community Service and Hands on Activities, imparting a rich memorable experience with far reaching benefits.",
    image: `${school1}`,
    fieldtrip: true
  },
  {
    title: "Virtual Interactive Experiences",
    description: 'A customized virtual experiential learning program for students of various curriculums, delivered by experienced trainers.',
    image: `${school2}`,
    fieldtrip: false
  },
];


// Data for 25 Years in Travel & Hospitality
export const TRAVEL_N_HOSPITALITY = [
  {
    title: "We plan for you",
    description: "Online booking can be tricky & confusing. Some of the descriptions could even be vague and unclear or in some cases unreliable. We are holiday planners delivering the whole experience at competitive rates.",
    image: `${hospitality1}`,
  },
  // {
  //   title: "Certification of Thailand specialist",
  //   description: "We have earned a certification from tourism authority Thailand, India for completing the Thailand specialist programme. ",
  //   image: `${hospitality4}`,
  // },
  {
    title: "Serving since 1996",
    description: 'Hammock group companies are headquartered in Bengaluru, Karnataka, India.  Over the past 26 years we have diversified into Travel & Hospitality, Destination Management services, Experiential Learning & CAS requisites of International schools, Virtual events, Webinars & Online workshops.',
    image: `${hospitality2}`,
  },
  {
    title: "Tailor made experiences",
    description: 'We recognize that each individual has his or her own concept of a dream holiday. We invest time to understand your specific requirements and provide the ideal customized solution to suit your needs.',
    image: `${hospitality3}`,
  },
];
