import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import "../../assets/css/partials/myFamilyFirst/myFamilyFirst.scss";
import OwlCarousel from "react-owl-carousel"
import "owl.carousel/dist/assets/owl.carousel.css"
import "owl.carousel/dist/assets/owl.theme.default.css"
import "../../assets/css/partials/weddings/weddings.scss"
import { Link } from "react-router-dom"

import { story_options } from '../../resources/carouselOptions'
import { useHistory } from "react-router-dom";

const StoriesList = ({ myRef, WEDDING_STORIES_LIST, categoryId }) => {


    const history = useHistory();

    const handleStoriesDetails = (id) => {
        if (categoryId == 2) {
            history.push({ pathname: `/my-family-first/anniversary/stories-details/${id}`, state: { categoryId } })
            window.scroll(0, 0)

        }
        else if (categoryId == 1) {
            history.push({ pathname: `/my-family-first/honeymoon/stories-details/${id}`, state: { categoryId } })
            window.scroll(0, 0)
        } else {
            history.push(`/weddings/stories-details/${id}`)
            window.scroll(0, 0)
        }
    }
    return (
        <>{WEDDING_STORIES_LIST && WEDDING_STORIES_LIST.length > 0 &&
            <div className="slider-holder mt-lg-5" ref={myRef}>
                <div className="container">
                    <div className="wedding-holder wedding-holder-mob">
                        <div className="wedding-info">
                            <h1>Get inspired by <span>stories</span> from our happy users</h1>
                        </div>
                    </div>
                </div>
                <div className="carousel-wrapper  story_carousel carousel-mobi-wrapper mt-lg-3" >
                    {WEDDING_STORIES_LIST && WEDDING_STORIES_LIST.length > 0 &&
                        <OwlCarousel className="owl-theme" {...story_options}>
                            {
                                WEDDING_STORIES_LIST?.map(item => (

                                    // <Link>
                                    <div className="item cursor-pointer" onClick={() => handleStoriesDetails(item?.id)}>
                                        <div className="card-wrapper">
                                            {
                                                item.media.map((images) => {
                                                    if (images) {
                                                        if (images?.thumbnail == true) {
                                                            return (
                                                                <img src={images?.image} alt={item?.title} />
                                                            )
                                                        }
                                                    }
                                                })
                                            }
                                            <div className="info">
                                                <p>{item?.title}</p>
                                            </div>
                                        </div>
                                    </div>
                                    // </Link>
                                ))
                            }
                        </OwlCarousel>
                    }
                </div>
            </div>
        }

        </>
    )
}
export default StoriesList