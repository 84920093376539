import React from 'react'

const CommonButton = ({onClick, children}) => {
    return (
        <div className="otp-btn-container">
            <button type="submit" className="btn-common" onClick={onClick}><span>{children}</span></button>
        </div>
    );
};

export default CommonButton;
