import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import * as EventsAction from '../../redux/actions/eventsAction';
import closeIcon from '../../assets/images/common/close-modal.svg';
import ContactDetailsCard from '../common/contactDetailsCard';
import Dialog from '@material-ui/core/Dialog';
import styled from "styled-components";
import LoginPopup from '../auth/login';
import SignupPopup from '../auth/signUp'

import CloseIcon from '../../assets/images/common/close-modal.svg';
import { Tabs, Tab } from "react-bootstrap";
import useClickOutside from '../../hooks/useClickOutside';

const StyledDialog = styled(Dialog)`
    & > .MuiDialog-container > .MuiPaper-root {
        width: 430px;
        background-color: #F5F5F5;
        position: relative
    }
    & > .MuiDialog-container > .MuiPaper-root > .close-login {
        position: absolute;
        top: 12px;
        right: 23px;
        cursor: pointer
      }
    `;
    
function BookThisForm({pauseVideos, isEnablePayment, scroll, similarEvents}) {
    const dispatch = useDispatch();
    const history = useHistory();
    const EVENT_DETAILS = useSelector(state => state?.events?.event_details?.data?.event);
    const EVENT_INFO = EVENT_DETAILS?.packages[0];
    const PRICE = useSelector(state => state?.events?.event_details?.data);
    const userDetails = JSON.parse(localStorage.getItem("userData"));
    const isCorporateAccount = userDetails?.user_details?.corporate_account;
    const [totalPrice, setotalPrice] = useState(EVENT_INFO?.price);
    const [showModal, setShowModal] = useState(false);
    const [showDate, setShowDate] = useState(false);
    const [clickType, setClickType] = useState("login");

    const [showForm, setShowForm] = useState(false)
    const [showContactModal, setShowContactModal] = useState(false)
    const [isVisible, setIsVisible] = useState(true);
    const [height, setHeight] = useState(0)
    const userInfo = JSON.parse(localStorage.getItem("userData"));
    const companyDomain = userInfo?.organisation?.email_domain;
    const isWipro = (companyDomain === "wipro.com");
    const contactModalRef = useRef()
    const bookThisRef = useRef()

    useClickOutside(contactModalRef, (e) => {
        setTimeout( () => setShowContactModal(false),100)
     });
    useClickOutside(bookThisRef, (e) => {
        if(showForm){
            setTimeout( () => setShowForm(false),100)
        }
     });

    useEffect(() => {
        setotalPrice(EVENT_INFO?.price);
    }, [EVENT_INFO?.price]);

    const handleSubmit = (id) => {
        const getToken = localStorage.getItem("token");
        if (getToken === "" || getToken == null) {
            // alert("please login");
            setShowModal(true);
        }
        else {
            let data = JSON.parse(localStorage.getItem("userData"));
            const points = parseInt(localStorage.getItem("points"))
            let mobile = localStorage.getItem("mobile");
            let full_name = localStorage.getItem("full_name");
            if (isCorporateAccount) {
                const bookData = {
                    pack_id: EVENT_INFO?.id,
                    total_price: totalPrice,
                    schedule: {
                        date: EVENT_INFO?.schedule_data?.date,
                        from: EVENT_INFO?.schedule_data?.from,
                        to: EVENT_INFO?.schedule_data?.to
                    },
                    points: points,
                    amount: totalPrice,
                    name: full_name,
                    email: data?.user_details?.email,
                    contactNumber: mobile,
                };
                dispatch(EventsAction.handleProceedToBook(bookData, isCorporateAccount));
            }

            else {
                const bookData = {
                    pack_id: EVENT_INFO?.id,
                    total_price: totalPrice,
                    schedule: {
                        date: EVENT_INFO?.schedule_data?.date,
                        from: EVENT_INFO?.schedule_data?.from,
                        to: EVENT_INFO?.schedule_data?.to
                    },
                    amount: totalPrice,
                    name: full_name,
                    email: data?.user_details?.email,
                    contactNumber: mobile,
                };
                dispatch(EventsAction.handleProceedToBook(bookData, isCorporateAccount));
            }
            // dispatch(CorporateAction.changeDataforProceedToBook(dataChanged, dataChangedBool));
            history.push(`/events/checkout`);
            window.scrollTo(0, 0);

        }
    };



    const handleForm = () => {
        setShowForm(true);
        pauseVideos();
    }
    const handleContactModal = () => {
        setShowContactModal(true);
        pauseVideos();
    }
    const closeFormHandler = () => {
        setShowForm(false)
    }
    const handleClose = () => {
        setShowModal(false);
    }

    useEffect(() => {
        let isJustLoggedIn = localStorage.getItem("isJustLoggedIn");
        if(isJustLoggedIn==='true'){
            // history.push('/events/checkout');
            handleSubmit();
        }
        window.addEventListener("scroll", listenToScroll);
        return () =>
            window.removeEventListener("scroll", listenToScroll);
    }, [])

    useEffect(()=>{
        if(showContactModal || showForm){
          document.querySelector('html').style.overflow = "hidden"
    
        } else {
          document.querySelector('html').style.overflow = "scroll"
        }
    
      }, [showContactModal, showForm])

    const listenToScroll = () => {
        console.log('book this form')
        if(showForm || showModal){
            return false;
        }
        let heightToHideFrom = document.body.scrollHeight - 850;
        const winScroll = document.body.scrollTop ||
            document.documentElement.scrollTop;
        setHeight(winScroll);
        // console.log("heightToHideFrom", winScroll, heightToHideFrom);

        if (winScroll > heightToHideFrom) {
            setIsVisible(false);
        } else {
            setIsVisible(true);
        }
    };

    const isPastDate = () => {
        const todayDate = moment().format('YYYY-MM-DD')
        const isBefore = moment(EVENT_INFO?.valid_till).isBefore(todayDate)
        return isBefore
    }

    return (
        <div className="events-bookThis">
            <div className={showForm ? "openModal-mobile" : "bookThis-desktop"}>
                <div className="openModal-holder login-holder booking-holder" ref={bookThisRef}>
                    <span className="closeIcon-holder d-block d-md-none" onClick={closeFormHandler}><img src={closeIcon} className="close-icon" /></span>
                    <h5 className={isPastDate() ? "grey-title" : ""}>Book this</h5>
                    <form >
                        <div className="form-group padding-20-mobi mt-4">
                            <p className="mb-0 eventTime">{isPastDate() ? <span >Held on: </span>: ""}{`${moment(EVENT_INFO?.schedule_data?.date, 'DD-MM-YYYY').format('ddd Do MMM,')} `}</p><p className="mb-0 eventTime">
                            {`${moment(EVENT_INFO?.schedule_data?.from, 'hh:mm').format('LT')} to ${moment(EVENT_INFO?.schedule_data?.to, 'hh:mm').format('LT')}`}</p>
                        </div>
                        {isPastDate() && similarEvents?.length > 0?
                                     <div className="free-tagline" onClick={scroll}><p>See Upcoming Events</p></div> :<>
                        {PRICE?.price_list[0] == 0 ? <div className="free-tagline"><p>Free session</p></div>
                        :<div className="total-amount">
                            {
                                (isCorporateAccount && isWipro) ? <p>{PRICE?.price_list} pts</p> : <p>Rs {PRICE?.price_list[0]}</p>
                            }
                        </div>}</>}
                        <div>
                        {isPastDate() ?
                            <button
                                  type="button"
                                  className="btn proceed-to-book disabled w-100" >
                                  Proceed to book
                              </button>    
                            :<button
                                type="button"
                                className="btn proceed-to-book w-100"
                                onClick={handleSubmit} >
                                Proceed to book
                            </button>}
                        </div>
                    </form>
                </div>
            </div>
            {
                isVisible && <div className="mobile-buttons">
                    <div className="stcky-leftButton">
                        <div className="white-button" onClick={handleContactModal}>
                            <a>Contact</a>
                        </div>
                    </div>
                    <div className="stcky-rightButton">
                        <div className={isPastDate() ? "custom-button disabled" : "custom-button"} onClick={isPastDate() ? "" : handleForm}>
                            <a>Book this</a>
                        </div>
                    </div>
                </div>
            }

            <div>
                <StyledDialog
                    open={showModal}
                    PaperProps={{
                        style: {
                            width: '430px',
                            backgroundColor: '#fff',
                        },
                    }}
                >
                    <div className="close-login" onClick={handleClose}>
                        <img src={CloseIcon} />
                    </div>
                    <div className="events-auth-tabs">
                        <Tabs defaultActiveKey={clickType == "login" ? 'login' : 'signup'}  onSelect={(type)=>setClickType(type)} id="uncontrolled-tab-example">
                            <Tab eventKey="login" title="Login">
                                <div className="events-login-holder">
                                    <LoginPopup
                                        closeModalEvent={showModal}
                                        toggleModalEvent={setShowModal}
                                        clickType={clickType}
                                    />
                                </div>
                            </Tab>
                            <Tab eventKey="signup" title="Signup">
                                <div className="events-login-holder">
                                <SignupPopup closeModalEvent={showModal}
                                        toggleModalEvent={setShowModal}
                                        clickType={clickType}
                                    />
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </StyledDialog>
            </div>
            {
                showContactModal ? <div className="contact-modal">
                    <ContactDetailsCard openModal={setShowContactModal} contactModalRef={contactModalRef}/>
                </div> : null
            }
            <div className="d-none d-md-block sticky-contact" >
                <ContactDetailsCard isEnablePayment={isEnablePayment} isPastDate={isPastDate()}/>
            </div>
        </div>
    )
}

export default BookThisForm
