import Hikes from "../../../assets/images/itinerary/Hikes.svg";
import Treks from "../../../assets/images/itinerary/Treks.svg";
import Camping from "../../../assets/images/itinerary/Camping.svg";
import Cycling from "../../../assets/images/itinerary/Cycling.svg";
import Kayaking from "../../../assets/images/itinerary/Kayaking.svg";
import Mountaineering from "../../../assets/images/itinerary/Mountaineering.svg";
import ScubaDiving from "../../../assets/images/itinerary/Scuba Diving.svg";
import WW from "../../../assets/images/itinerary/WW).svg";
import Adventure from "../../../assets/images/itinerary/Adventure.svg";
import Pilgrimage from "../../../assets/images/itinerary/Pilgrimage.svg";
import Nature from "../../../assets/images/itinerary/Nature.svg";
import Watersports from "../../../assets/images/itinerary/Water sports.svg";
import Biking from "../../../assets/images/itinerary/Biking.svg";
import { Tune } from "@material-ui/icons";
import leisure from "../../../assets/images/itinerary/Lesuire.svg";
const activityTypes = [
  { type: "walks_or_hikes", name: "Walks/Hikes", image: Hikes },
  { type: "treks", name: "Treks", image: Treks },
  { type: "rafting(river/WW)", name: "Rafting (River/WW)", image: WW },
  { type: "mountaineering", name: "Mountaineering", image: Mountaineering },
  { type: "camping", name: "Camping", image: Camping },
  { type: "cycling", name: "Cycling", image: Cycling },
  { type: "kayaking", name: "Kayaking", image: Kayaking },
  { type: "scuba_diving", name: "Scuba Diving", image: ScubaDiving },
  { type: "leisure_or_nature", name: "Leisure / Nature", image: Nature},
  { type: "pilgrimage", name: "Pilgrimage", image: Pilgrimage},
  { type: "water_sports", name: "Water Sports", image: Watersports},
  { type: "adventure", name: "Adventure", image: Adventure},
  { type: "biking", name: "Biking", image: Biking},
  { type: "leisure", name: "Leisure", image: leisure},
]

export const getActivityType = (type, format) => {
  let data;
  activityTypes.filter((activity) => {
    if (type == activity.type) {
      data = activity;
    }
  });

  if (data && format == "txt") {
    return data.name;
  }
  if (data && format == "img") {
    return data.image;
  }
};
