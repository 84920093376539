import React from 'react'
import { useSelector } from "react-redux";
import Header from "./header";
import Footer from "./footer";
import backIcon from "../../assets/images/common/back-icon.svg";
import { useHistory } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { ScrollToTop } from '.';

function TermsAndConditions() {
    const history = useHistory();
    const loginUserData = useSelector((state) => state.auth.login_user_otp);
    return (
        <div>
            <Helmet>
                <title>Terms & Conditions - Hammock Holidays</title>
                <meta name="description" content="" />
            </Helmet>
            <div className={Object.keys(loginUserData).length !== 0 &&
                loginUserData.constructor === Object ? 'innerPage-header' : 'innerPage-header pre-login-header'} >
                <Header />
            </div>
            <div className="container innerPage-container">
                <a onClick={() => history.goBack()}>
                    <div className="back-button">
                        <img src={backIcon} alt="backIcon" />
                    </div>
                </a>
                <div className="package-holder">
                    <div className="package-title">
                        <h1>
                            Terms & Conditions
                  </h1>
                    </div>
                </div>
                <div className="terms-conditions-content">
                    <div className="content-wrapper mb-5">
                        <h4 className="mb-3">Privacy Policy</h4>
                        <p>By accessing this Site, certain information about you, such as Internet protocol (IP) addresses, navigation through the Site, the systems and programs used and the time spent, along with other similar information, may be stored on our servers. The same may be used by us for web site traffic analysis or for such other purposes as we may deem appropriate.
                          </p>

                        <p>Any personally identifiable information you submit will only be disclosed to suppliers who are linked to your bookings (with an intention to protect misrepresentation/fraudulent use of services & offers purchased/gifted to by a User), and are required to fulfill your request for certain products or services. We will not disclose, sell, share or in any way reveal your information to any other third party.
                          </p>

                        <p>
                            There are a number of products and services offered by third parties included on our site (e.g., loyalty/performance awards, gift coupons, service incentives). These products and services constitute Hammock Holidays offers. If you choose to use these separate products or services, disclose information to the providers, and/or grant them permission to collect information about you, then their use of your information is governed by their privacy policy.
                          </p>

                        <p>
                            Notwithstanding the foregoing, Hammock Holidays reserves the right (and you authorize Hammock Holidays) to share or disclose your Registration Information and Account information when Hammock determines, in its sole discretion, that the disclosure of such information is necessary to identify, contact, or bring legal action against you if you are or may be violating this Privacy and Security Policy or Hammock Holidays Terms & Conditions/ Terms of Use to prevent potentially prohibited or illegal activities or if required by any applicable law, rule regulation, subpoena or other legal process.
                          </p>

                        <p>
                            We use a combination of firewall barriers, encryption techniques and authentication procedures, among others, to maintain the security of your online session and to protect Hammock Holidays accounts and systems from unauthorized access.
                          </p>

                        <p>
                            Our servers are in a secure facility and access requires multiple levels of authentication. Our databases are protected from general employee access both physically and logically. All Registration Information and Account Information is stored in an encrypted database, and all backup drives and tapes are encrypted with different keys.
                          </p>

                        <p>
                            If you have a security related concern, please contact us at <a rel="noreferrer" target="_blank" href="mailto:customercare@hammockholidays.com">customercare@hammockholidays.com</a>. We will work closely with you to ensure a rapid and personal response to your concerns.
                          </p>

                        <p>
                            If you have any questions, comments, concerns or feedback regarding this Privacy and Security Policy, send an email to <a rel="noreferrer" target="_blank"  href="mailto:customercare@hammockholidays.com">customercare@hammockholidays.com</a>.

                          </p>

                    </div>
                    <div className="content-wrapper mb-5">
                        <h4 className="mb-3">Customer Registration process and guidelines
                           </h4>
                        <p>As a condition of your use of this Website, you warrant that (i) you are at least 18 years of age and are of sound mind, (ii) you possess the legal authority to create a binding legal obligation, (iii) you will use this Website in accordance with this Agreement, (iv) you will only use this Website to make legitimate reservations/ selections/ payment for you or for another person for whom you are legally authorized to act, (v) you will inform such other persons about the terms and conditions that apply to the reservations/ selections/ payments you have made on their behalf, including all rules and restrictions applicable thereto.
                           </p>
                        <p>Registration for all Corporate Employees that are part of Hammock Holidays various corporate/ loyalty/ rewards programs will be dictated by the terms and conditions agreed on between Hammock Holidays and the respective corporate/agency. Registration for the said programs will require some form of identification such as Employee ID numbers/ Employee Email ID or any other form of identification/ approvals as has been decided upon in the agreement between Hammock Holidays and the respective Corporate. All products & services offered by Hammock Holidays are meant for use only by the respective employees who are eligible for the offers and shall not be misused/ offered to anyone else without obtaining due approval and written permission from Hammock Holidays.
                           </p>
                    </div>

                    <div className="content-wrapper mb-5">
                        <h4 className="mb-3">Product / Service Description
                       </h4>
                        <p>Hammock Leisure Holidays are primarily holiday planners & travel consultants who also undertake various travel/ leisure or reward-based programs for select Corporate entities. Air / train / bus tickets, airport / railway station transfers, hotel accommodation, sightseeing, guide services etc are arranged by Hammock Holidays, only in conjunction with hotel reservations.
                       </p>
                        <p>All products/services that are listed on our Site have a validity period, related terms and conditions of purchase and use. Certain outdoor activity linked packages/ products come with a health & safety warning. It is expressly understood that the User has understood all related terms; conditions of sale and in no manner will hold Hammock Holidays, our agents or representatives responsible for any untoward outcome related to use of such services/products.
                       </p>
                        <p>
                            Hammock Holidays does not control or operate any airline/ railways/shipping company/ transport/ hotels/ coaches etc., and hence have no control over operations. Hammock Holidays cannot be held responsible for any delay or deficiency in the services provided by any independent agency / airline/ transport/ cruise/ hotels or any other provider of services.
                       </p>
                    </div>

                    <div className="content-wrapper mb-5">
                        <h4 className="mb-3">Pricing and Payment Policy
                       </h4>
                        <p>Price and availability is not guaranteed until the reservation is confirmed by the user. What your tour price/ product price includes will be specified by Hammock Holidays in the quote. This means that all/any other item not mentioned in the Tour price includes is not a part of the quotation/ pricing.
                       </p>
                        <p className="mb-5">In case of any revision in the flight charges/ airline taxes due to rise of fuel prices, the same will be levied to the traveller. Government actions such as changes in GST, Govt. of India tax rates, any other state government taxes or any other government-imposed changes and currency changes in relation to an exchange rate variation may also vary the price after you have booked. Optionally, if the User chooses to pay for the holiday in full at the time of booking, in which case the holiday price will be fixed at the cost quoted by Hammock Holidays at that time. Hammock Holidays reserves the right to charge service of fees for services rendered.
                       </p>
                        <p>
                            Various packages, promotions and special offers will have their own validity period and will be applicable as per the agreement between the supplier and Hammock Holidays. It is the responsibility of the User to check the validity date of the offer selected, before opting for a particular package/option. Certain holiday packages may or may not be applicable during the peak holidays’ seasons/ days. The user is advised to read all the terms and conditions attached to the particular package/ option before selecting the same.
                     </p>
                        <p className="mb-5">
                            Payments by cheques should be drawn in favour of Hammock Leisure Holidays Pvt. Ltd payable at Bangalore. Interest @ 18% per annum will be charged on bills remaining unpaid after 14 days from the date of billing. Payment through Credit cards & Net Banking will incur an additional transaction fee on the Gross Bill.
                     </p>
                        <p>
                            HDFC Bank Ltd has been authorized to process online payment transactions on behalf of Hammock Holidays. All/ any transaction fee charged by our bankers on Credit cards & Net Banking is to be borne by the customer/card holder/net banking <strong>user</strong>.
                     </p>
                        <p>
                            We as a merchant shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any Transaction, on Account of the Cardholder
                     </p>
                        <p>
                            Your financial institution may impose an additional fee or other charges to use their payment services (Payment Servicing Fees), and you are solely responsible for payment for any and all such Payment Servicing Fees. Payment Servicing Fees are not included in the purchase price displayed on any packages or the receipts provided by Hammock Holidays.
                     </p>
                    </div>

                    <div className="content-wrapper mb-5">
                        <h4 className="mb-3">Cancellation / Refund policy
                   </h4>
                        <p>Cancellation charges will be payable, depending upon the number of days prior to departure that Hammock Holidays receives notice of cancellation and on hotel/ resort/ airlines/ transporters/ supplier’s cancellation policy and will include a service fee. Some hotels do not permit changes to or cancellations of reservations after they are made, as indicated in the rules and restrictions for the hotel reservation and for bookings with these suppliers no amount will be refunded.
                   </p>
                        <p>If the User does not cancel or change the reservation before the cancellation policy period applicable to the hotel/ package that was reserved, which varies by hotel, the user will be subject to a charge of applicable room rates, tax recovery charges of the hotel and the service fees for cancellation.
                   </p>
                        <p>
                            Cancellation policy for the various packages and offers which are specifically for corporate programs will be governed by the agreement with the respective corporate and the various suppliers of Hammock Holidays for that particular program.
                   </p>
                        <p>
                            Cancellation/ amendment/ delay in airlines / trains / coaches will not entitle the user to any refunds for the tours / land packages/ hotels/ cruises and the suppliers will apply cancellation rules as per their policies.
                   </p>
                    </div>

                    <div className="content-wrapper mb-5">
                        <h4 className="mb-3">Refunds
                   </h4>
                        <p>
                            Requests for refund should be made in writing within 3 working days after the scheduled completion of the related services. All refunds for international products will be made only in Indian Rupees as per RBI Regulations. Refund requests for curtailed stay cannot be processed unless provided with documentary evidence from the supplier. All refund requests are subject to obtaining a refund approval from our suppliers, and no refunds can be given without this approval. The supplier reserves the right to process and whether or not to approve the refund, request and if necessary, charge an administrative fee for packages. No refunds can be processed for partially utilized services and no shows.

                   </p>
                        <p>
                            Refund may be made in the form of a credit note/ voucher such that it may be used for future travel /utilisation of service subject to terms applicable thereon or may be done in cash, cheque or net banking. In all cases, mode of refund (credit note or cash) is dependent upon the Service Provider only.
                   </p>
                    </div>

                    <div className="content-wrapper mb-5">
                        <h4 className="mb-3">Covid-19 Bookings, Refund And Cancellations
               </h4>
                        <p>
                            The Customer agrees that due to recent outbreak of COVID-19 which has been declared as pandemic, there may be instances, where the end service provider (including Travel Agent) either cancels the booking or refuses to provide the services, as agreed with the Customer, by assigning or not assigning any specific reason for such refusal. Refusal of service may be due to the reasons including but not limited to the Customer:
                   </p>
                        <ul className="ml-lg-5">
                            <li>Exhibiting any symptoms associated with COVID 19.</li>
                            <li>Refusing to comply with safety advisories, like wearing protective gear, complying with social distancing norms etc.</li>
                            <li>Suffering of any ailment making the Customer vulnerable to the virus</li>
                            <li>Posing a threat to the health and safety of others or</li>
                            <li>Any other condition put forward by the Central Government, State Governments and statutory bodies etc.</li>
                        </ul>

                        <p>
                            In such cases Hammock Holidays will assume no liability for the refusal of service by the end service provider. The Customer also agrees that refund, if any, for such bookings will be processed by Hammock Holidays to the Customer subject to receipt of the same from the end service provider.
                    </p>
                        <p>
                            The Customer further understands that due to the outbreak of COVID-19, there may be frequent changes in guidelines and/ or SOPs issued by the Central Government, State Governments and statutory bodies etc. that a traveler will have to follow in order to undertake the travel. Likewise, it shall be the obligation of the customer to ensure that your Covid Vaccination Certificate is valid and issued by a competent authority for travel within India or out of India as applicable.
                    </p>
                        <p>
                            The Customer agrees to abide with all such guidelines/SOP while undertaking any travel. In case Customer fails to adhere to the same which results in the refusal to travel, TR Hammock Holidays will not be held liable for such refusal and refund, if any, shall be strictly as per the terms of the service provider.
                    </p>
                    </div>

                    <div className="content-wrapper mb-5">
                        <h5 className="mb-3">Customer service / Help desk contact number:</h5>
                        <p>Hammock Leisure Holidays Pvt Ltd </p>
                        <p className="mb-2">
                            3291, 1st Floor, 12th Main, HAL II Stage, Indiranagar, Bangalore 560 008.</p>
                        <p className="mb-2">
                           Tel :<a href="tel:91-80- 4202 3333" > 91-80- 4202 3333</a></p>
                         <p className="mb-2">
                        Email : <a href="mailto:customercare@hammockholidays.com" rel="noreferrer" target="_blank">customercare@hammockholidays.com</a>
                         </p>  
                         <p>
                        Web : <a href="https://www.hammockholidays.com/" rel="noreferrer" target="_blank"> www.hammockholidays.com </a>
                         </p> 
                    </div>

                </div>
            </div>
            <ScrollToTop showBelow={250}/>
            <Footer />
        </div>
    )
}

export default TermsAndConditions
