import { EVENTS_CATEGORY, INITIAL_EVENTS_CATEGORY_ID, CATEGORY_ID, EVENTS_LIST, EVENT_PACKAGE_DETAILS, SELECTED_DATE, SELECTED_TIME, EVENT_BOOKING_INFORMATION_DATA, END_TIME, BOOKING_STATUS, CHECKOUT_DETAILS, SIMILAR_EVENTS, NAME_CHANGE_ON_UPDATE_EVENT, NAME_CHANGE_ON_UPDATE_EVENT_BOOL, NUMBER_CHANGE_ON_UPDATE_EVENT, NUMBER_CHANGE_ON_UPDATE_EVENT_BOOL, RESET_EVENTS_LIST, EVENTS_PACKAGES_LIST, EVENTS_CATEGORY_ID, EVENTS_TESTIMONIALS, SET_PAST_EVENTS} from '../actions/eventsAction';
const initialState = {
    cat_id: null,
    events_category: [],
    events_list: [],
    event_details: localStorage.getItem("eventDetails") ? JSON.parse(localStorage.getItem("eventDetails")) : [],
    selected_date: null,
    selected_time: null,
    end_time:null,
    booking_info: localStorage.getItem("bookData") ? JSON.parse(localStorage.getItem("bookData")) : [],
    booking_details: [],
    checkout_details: [],
    similar_events: [],
    nameChangedOnUpdates: null,
    nameChangedOnUpdatesBool: false,
    numberChangedOnUpdates:null,
    numberChangedOnUpdatesBool:false,
    events_packages_list: [],
    events_testimonial: {},
    past_events: false
}

const eventsReducers = (state = initialState, action) => {
    switch (action.type) {
        case EVENTS_CATEGORY_ID:
            return {
                ...state,
                cat_id: action.payload,
            }
        case INITIAL_EVENTS_CATEGORY_ID:
            return {
                ...state,
                cat_id: action.payload,
            }
        case EVENTS_CATEGORY:
            return {
                ...state,
                events_category: action.payload
            }
        case EVENTS_LIST:
            return {
                ...state,
                events_list: action.payload
            }
        case RESET_EVENTS_LIST:
            return {
                ...state,
                events_packages_list: action.payload
            }
        case EVENTS_PACKAGES_LIST:
            return {
                ...state,
                events_packages_list: state.events_packages_list.concat(action.payload)
            }
        case EVENT_PACKAGE_DETAILS:
            return {
                ...state,
                event_details: action.payload
            }
        case SELECTED_DATE:
            return {
                ...state,
                selected_date: action.payload
            }
        case SELECTED_TIME:
            return {
                ...state,
                selected_time: action.payload
            }
        case END_TIME:
            return {
                ...state,
                end_time: action.payload
            }
        case EVENT_BOOKING_INFORMATION_DATA:
            return {
                ...state,
                booking_info: action.payload
            }
        case SIMILAR_EVENTS:
            return {
               ...state,
               similar_events: action.payload
            }     
        case BOOKING_STATUS:
            return {
                ...state,
                booking_details: action.payload
            }  
        case CHECKOUT_DETAILS:
            return {
                ...state,
                checkout_details: action.payload
            } 
        case NAME_CHANGE_ON_UPDATE_EVENT:
            return {
                ...state,
                nameChangedOnUpdates: action.payload
            } 
        case NAME_CHANGE_ON_UPDATE_EVENT_BOOL:
            return {
                ...state,
                nameChangedOnUpdatesBool: action.payload
            } 
        case NUMBER_CHANGE_ON_UPDATE_EVENT:
            return {
                ...state,
                numberChangedOnUpdates: action.payload
            } 
        case NUMBER_CHANGE_ON_UPDATE_EVENT_BOOL:
            return {
                ...state,
                numberChangedOnUpdatesBool: action.payload
            } 
        case EVENTS_TESTIMONIALS:
            return {
                ...state,
                events_testimonial: action.payload
            } 
        case SET_PAST_EVENTS:
            return {
                ...state,
                past_events: action.payload
            } 

        default:
            return state
    }
}
export default eventsReducers;