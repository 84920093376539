
import { API } from "../../config/api";
import {
  axiosDelete,
  axiosGetWithToken,
  axiosMediaUpload,
  axiosPost,
  axiosPostWithToken,
  axiosPut,
  axiosResumeUpload,
} from "../../services/apiServices";
import { resumeUpload } from "../../services/coreApiServices";
import { ERROR_ALERT } from "./commonAction";
import { USER_BOOKING_DETAILS, USER_PROFILE_DETAILS, NON_CORP_USER_PROFILE_DETAILS, UPDATED_MOBILE_NUMBER, UPDATE_USER_DEATILS_MESSAGE, INVALID_OTP_MESSAGE_PROFILE, MOBILE_NUMBER_ALREADY_EXIST, SHOW_OTP_MODAL_FALSE, SET_SCROLL } from "./types";


// get corporate user profile details
export const getProfileDetails = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosGetWithToken(`${API.GET_PROFILE_DETAILS}`, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: USER_PROFILE_DETAILS,
            payload: res.data,
          });
          localStorage.setItem("mobile",res.data?.data?.user?.mobile);
        }
      })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
};

// get non-corporate user profile details
export const getNonCorporateProfileDetails = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosGetWithToken(`${API.GET_NON_CORP_PROFILE_DETAILS}`, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: NON_CORP_USER_PROFILE_DETAILS,
            payload: res.data,
          });
          dispatch({
          
          })
        }
      })
      .catch((err) => {
        // console.log("err",err);
        // dispatch({
        //   type: ERROR_ALERT,
        //   payload:
        //     (err && err.response && err.response.data) ||
        //     "Something went wrong.",
        // });
      });
  };
};


// Add user address
export const addUserAddress = (body, handleSuccess, handleErrors) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosPostWithToken(`${API.ADD_ADDRESS}`, body, token)
      .then((res) => {
        if (res.data.status_code === 201) {
          handleSuccess();
        }
      })
      .catch((err) => {
        handleErrors(err.response.data?.data?.message);
      });
  };
};

// Update user address
export const updateUserAddress = (body, id, handleSuccess, handleErrors) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosPut(`${API.UPDATE_ADDRESS + id + "/"}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          handleSuccess();
          console.log("resupdate", res);
        }
      })
      .catch((err) => {
        handleErrors(err.response.data?.data?.message);
      });
  };
};

// update user phone number

export const updateUserPhoneNumber = (body, handleSuccess, handleErrors, refreshProfileDetails) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosPostWithToken(`${API.UPDATE_MOBILE}`, { mobile: body}, token)
      .then((res) => {
        // if (res.data.status_code === 200) {
        //   handleSuccess();
        //   dispatch({
        //     type:UPDATE_USER_DEATILS_MESSAGE,
        //     payload:res.data
        //   })
        // }
        if (res.data.status_code === 400) {
          handleErrors(res?.data?.data?.message);
          dispatch({
            type: MOBILE_NUMBER_ALREADY_EXIST,
            payload: true
          });
        }else{
          handleSuccess();
          dispatch({
            type:UPDATE_USER_DEATILS_MESSAGE,
            payload:res?.data
          })
        }
      })
      .catch((err) => {
        handleErrors(err.response?.data?.data?.message);
      });
  };
};


// update non-corporate user phone number
export const updateNonCorpUserPhoneNumber = (body, handleSuccess, handleErrors, refreshProfileDetails) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosPostWithToken(`${API.UPDATE_MOBILE}`, { mobile: body}, token)
      .then((res) => {
        if (res.data.status_code === 400) {
          handleErrors(res.data?.data?.message);
          dispatch({
            type: MOBILE_NUMBER_ALREADY_EXIST,
            payload: true
          });
        }else{
          handleSuccess();
          dispatch({
            type:UPDATE_USER_DEATILS_MESSAGE,
            payload:res.data
          })
        }
      })
      .catch((err) => {
        // handleErrors(err?.response?.data?.data?.message);
      });
  };
};


export const corpUserOtpVerify = (otp, handleSuccess, handleErrors) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const upDatedMobile = getState().userProfile.updatedMobileNum;
    const body = {
      mobile:upDatedMobile,
      otp:otp
    }
    axiosPostWithToken(`${API.CORP_USER_OTP_VERIFY}`, body, token)
      .then((res) => {
        if (res.data.status_code === 400) {
          handleErrors(res.data.data.message)
        }else{
          handleSuccess();
        }
      })
      .catch((err) => {
        handleErrors(err.response.data?.data?.message);
      });
  };
};

export const nonCorpUserOtpVerify = (otp, handleSuccess, handleErrors) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const upDatedMobile = getState().userProfile.updatedMobileNum;
    const body = {
      mobile:upDatedMobile,
      otp:otp
    }
    axiosPostWithToken(`${API.CORP_USER_OTP_VERIFY}`, body, token)
      .then((res) => {
        if (res.data.status_code === 400) {
          handleErrors(res.data.data.message)
        }else{
          handleSuccess();
        }
      })
      .catch((err) => {
        handleErrors(err.response.data?.data?.message);
      });
  };
};
export const updatedMobileNumber = (mobile) => {
  return async (dispatch, getState) => {
     dispatch({
       type:UPDATED_MOBILE_NUMBER,
       payload:mobile
     })
  };
};
// Delete user address
export const deleteAddress = (id, handleDeleteSuccess, handleErrors) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosDelete(`${API.DELETE_ADDRESS + id + "/"}`, token)
      .then((res) => {
        if (res.status === 204) {
          handleDeleteSuccess();
        }
      })
      .catch((err) => {
        handleErrors(err.response.data?.data?.message);
      });
  };
};
export const modalBoolVal = (showmodal) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    dispatch({
      type:SHOW_OTP_MODAL_FALSE,
      payload:showmodal
    })
  };
};


// update Mobile Corporate without OTP 
export const updateMN = (body, handleMobileSuccess, handleErrors) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosPut(`${API.UPDATE_NON_CORP_PROFILE}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          handleMobileSuccess&&handleMobileSuccess();
        } else if (res.data.status_code === 400){
          handleErrors&&handleErrors(res.data?.data?.message);
        } ;
      })
      .catch((err) => {
        handleErrors(err.response.data?.data?.message);
      });
  };
};

// update user name
export const updateName = (body, handleNameSuccess, handleErrors) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosPut(`${API.UPDATE_PROFILE}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          handleNameSuccess();
        }
      })
      .catch((err) => {
        handleErrors(err.response.data?.data?.message);
      });
  };
};

// update non corporate user details
export const updateNonCorpUserDetails = (body, handleNameSuccess, handleErrors) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosPut(`${API.UPDATE_NON_CORP_PROFILE}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          console.log(res);
          console.log(handleNameSuccess);
          handleNameSuccess();
        } else if (res.data.status_code === 400){
          handleErrors(res.data?.data?.message);
        } ;
      })
      .catch((err) => {
        console.log(err);
        console.log(handleErrors)
        handleErrors(err.response.data?.data?.message);
      });
  };
};

// upload profile pic

export const uploadProfilePic = (file_type, file, updatePic) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosMediaUpload(API.PROFILE_PIC_UPLOAD, file_type, file, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          updatePic(res.data.data);
          localStorage.setItem("profileImage", res.data.data.url)
        }
      })
      .catch((err) => {
        updatePic(null);
        // handleErrors(err.response.data?.data?.message);
        console.log("err");
      });
  };
};
export const uploadCareersResume = (file_type, file, updateResume) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosResumeUpload(API.UPLOAD_RESUME,file_type,file)
      .then((res) => {
        if (res.data.status_code === 200) {
          updateResume(res.data.data.url);
        }
      })
      .catch((err) => {
        updateResume(null)
        // handleErrors(err.response.data?.data?.message);
        console.log("err");
      });
  };
};
export const careersRequest = (data,msg) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosPost(API.CAREERS_REQUEST,data)
      .then((res) => {
        if (res.data) {
          msg(res.data.data.message);
        }
      })
      .catch((err) => {
        msg(null)
        // handleErrors(err.response.data?.data?.message);
        console.log("err");
      });
  };
};
// update user profile pic
export const updateUserProfilePic = (body, handleSuccess, handleErrors) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosPut(`${API.UPDATE_PROFILE}`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          handleSuccess();
        }
      })
      .catch((err) => {
        handleErrors(err.response.data?.data?.message);
      });
  };
};

// get list of all the bookings of corporate user
export const getBookings = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosGetWithToken(`${API.GET_BOOKINGS_API}`, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: USER_BOOKING_DETAILS,
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
};

// get list of all the bookings of non-corporate user
export const getNonCorporateBookings = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosGetWithToken(`${API.GET_NON_CORP_BOOKINGS_API}`, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: USER_BOOKING_DETAILS,
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
};

export const setScroll = (value) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_SCROLL,
      payload:value
    })
  };
}