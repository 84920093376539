import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../../assets/css/partials/common/filterCards.scss";
import "../../assets/css/partials/corporates/corporatePackages.scss";
import "../../assets/css/partials/events/events.scss";
import moment from 'moment';
import * as EventsAction from '../../redux/actions/eventsAction';
import { Helmet } from 'react-helmet';
import LazyLoadingLoader from '../common/lazyLodaingLoader';
import pastEventsImg from '../../assets/images/events/perso-events.png'

const owl_packages = {
    items: 1,
    margin: 5,
    nav: false,
    loop: true,
    dots: true,
    autoplay: true,
    smartSpeed: 500,
    animateIn: 'fadeIn',
    animateOut: 'fadeOut',
};

function Index(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const EVENTS_CATEGORY_LIST = useSelector((state) => state.events?.events_category?.data);
    // const pastEvents = useSelector((state) => state.events?.past_events);
    const pastEvents = props?.match?.params?.sectionid === "past-events";
    const CATEGORY_ID = useSelector((state) => state.events?.cat_id);
    const EVENTS_LIST = useSelector((state) => state.events?.events_list?.data?.listing);
    const NEXT_PAGE = EVENTS_LIST?.next_page_id;
    const CURRENT_PAGE = EVENTS_LIST?.current_page;
    const [loadMoreBtn, setLoadMoreBtn] = useState(false);
    const [secName, setSecName] = useState("All Events");
    const [loader, setLoader] = useState(false);
    const EVENTS_PACKAGES_LIST = useSelector((state) => state?.events?.events_packages_list);
    const userDetails = JSON.parse(localStorage.getItem("userData"));
    const isCorporateAccount = userDetails?.user_details?.corporate_account;
    const companyDomain = userDetails?.organisation?.email_domain;
    const isWipro = (companyDomain === "wipro.com");
    const [isBot, setIsBot] = useState(false);

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const page = params.get('page');

    useEffect(()=>{
        let bots = ['googlebot', 'bingbot', 'yandex', 'baiduspider', 'facebookexternalhit', 'twitterbot', 'rogerbot', 'linkedinbot', 'embedly', 'quora link preview', 'showyoubot', 'outbrain', 'pinterest/0.', 'pinterestbot', 'slackbot', 'vkShare', 'W3C_Validator', 'whatsapp', 'redditbot', 'applebot', 'flipboard', 'tumblr', 'bitlybot', 'skypeuripreview', 'nuzzel', 'discordbot', 'google page speed', 'qwantify', 'bitrix link preview', 'xing-contenttabreceiver', 'chrome-lighthouse', 'telegrambot', 'prerender']; 
        let checkbot = bots.filter((bot)=>{
            return navigator.userAgent.toLowerCase().indexOf(bot)>-1
        });
        setIsBot(checkbot.length>0)
        console.log(checkbot.length>0);
    }, []);

    useEffect(()=>{
        console.log('sectionid changed')
            let selcSec = EVENTS_CATEGORY_LIST?.filter(sl=>sl.section_slug===props?.match?.params?.sectionid);
            if(selcSec && selcSec.length){
                handleCategories(selcSec[0].id, selcSec[0].name)
            } else if(!props?.match?.params?.sectionid){
                handleSeeAll("all-events")
            } else if(props?.match?.params?.sectionid=='past-events'){
                handlePastEvents("past_events")
            };
    }, [props?.match?.params?.sectionid]);

    useEffect(() => {
        // get events categories
        dispatch(EventsAction.eventsCategories());
        if(CATEGORY_ID == null){
            dispatch(EventsAction.setCategoryId("all-events"));
        }
    }, []);
    useEffect(() => {
        if(history?.location?.fromSimilarOffer?.bool){
            dispatch(EventsAction.setCategoryId("all-events"));
            dispatch(EventsAction.getEvents(CATEGORY_ID, 1))
        }
    }, [])

    useEffect(() => {
        if(CURRENT_PAGE == undefined && EVENTS_CATEGORY_LIST?.length){
            dispatch(EventsAction.getEvents(CATEGORY_ID, page || 1))
        }
    }, [CATEGORY_ID])

    useEffect(()=>{
        if(EVENTS_CATEGORY_LIST?.length && props?.match?.params?.sectionid){
            let secId = EVENTS_CATEGORY_LIST.filter(sl=>sl.section_slug===props?.match?.params?.sectionid)[0]?.id;
            dispatch(EventsAction.setCategoryId(secId));   
        } else if(EVENTS_CATEGORY_LIST) {
            console.log('cat list came')
            dispatch(EventsAction.setCategoryId("all-events"));
            dispatch(EventsAction.getEvents(CATEGORY_ID, page || 1));
        }

    }, [EVENTS_CATEGORY_LIST]);

    const handleCategories = (id, name) => {
        console.log(id, name)
        dispatch(EventsAction.setCategoryId(id));
        dispatch(EventsAction.getEvents(CATEGORY_ID, 1))
        dispatch(EventsAction.setPastEvents(false))
        setSecName(name)
    }

    const handlePastEvents = (value) => {
        dispatch(EventsAction.setPastEvents(true))
        dispatch(EventsAction.setCategoryId(value));
        dispatch(EventsAction.getEvents(CATEGORY_ID, 1))
    }

    // see all events
    const handleSeeAll = (message) => {
        dispatch(EventsAction.setCategoryId(message));
        dispatch(EventsAction.getEvents(CATEGORY_ID, 1))
        dispatch(EventsAction.setPastEvents(false))
        setSecName("All Events")
    }
    const requiredUrl = () => {
        return EVENTS_CATEGORY_LIST?.filter(sl=>sl.id===CATEGORY_ID)[0]?.section_slug
    }
    const requiredTitle = () => {
        return EVENTS_CATEGORY_LIST?.filter(sl=>sl.id===CATEGORY_ID)[0]?.name
    }
    const requiredMetaDesc = () => {
        return EVENTS_CATEGORY_LIST?.filter(sl=>sl.id===CATEGORY_ID)[0]?.description
    }
    // redirect to event details page

    const handleEventDetails = (id) => {
        //commented to implement SEO Links
        // history.push(`/events/event-details/${id}`);
        // window.scrollTo(0, 0);
    };

    const loadMorePackages = () => {
        setLoadMoreBtn(false)
        setLoader(true)
        dispatch(EventsAction.getEvents(CATEGORY_ID, NEXT_PAGE));
        
    }

    useEffect(() => {
        if (NEXT_PAGE !== null) {
            setLoadMoreBtn(true)
        }else{
            setLoadMoreBtn(false)
            setLoader(false)
        }
        }, [NEXT_PAGE])
    return (
        <div>
            <Helmet>
                <title>{`${requiredTitle() ? requiredTitle() : "Events"}`} - Hammock Holidays</title>
                <meta name="description" content={`${requiredMetaDesc() ? requiredMetaDesc() : "Be there do it"}`} />
            </Helmet>
            <div className="filter-holder corporate-category-filter">
                <div className={EVENTS_CATEGORY_LIST && EVENTS_CATEGORY_LIST.length <= 3 ? 'd-flex justify-content-center mobi-category-overflow' : 'filter-wrapper mobi-category-overflow'} >
                <Link to={`/events`} style={{ textDecoration:"none"}}>

                    <div className={CATEGORY_ID === "all-events" ? 'see-all-events active-see-all ' : 'see-all-events'} onClick={() => handleSeeAll("all-events")}>
                        {
                            CATEGORY_ID === "all-events" ? <p>All <br /> Events</p> : <p>See all <br />Events</p>
                        }
                    </div>
                    </Link>    

                    {
                        EVENTS_CATEGORY_LIST && EVENTS_CATEGORY_LIST.length > 0 &&
                        EVENTS_CATEGORY_LIST.map((item) => (
                            <Link to={`/events/${item.section_slug}`}>
                            <div className={CATEGORY_ID === item?.id ? 'filter-card active-category' : 'filter-card'} onClick={() => handleCategories(item?.id, item?.name)}>
                                <div className="img-gradient">
                                    <div className="cat-img">
                                        <img src={item.image} alt="" />
                                        <div class="pseudo"></div>
                                    </div>
                                    <div className="card-info">
                                        <p>{item.name}</p>
                                    </div>
                                </div>
                            </div>
                            </Link>
                        ))
                    }
                    {
                        <Link to={`/events/past-events`}>
                        <div className={ props?.match?.params?.sectionid === "past-events" ? 'filter-card active-category' : 'filter-card'} onClick={() => handlePastEvents("past_events")}>
                        <div className="img-gradient">
                            <div className="cat-img">
                                <img src={pastEventsImg} alt="" />
                                <div class="pseudo"></div>
                            </div>
                            <div className="card-info">
                                <p>Past Events</p>
                            </div>
                        </div>
                    </div>
                    </Link>
                    }
                    <div className="px-3"></div>
                </div>
            </div>

            <div className="container">
                <div className="line"></div>
                <div className="">
                    {
                        EVENTS_PACKAGES_LIST?.length > 0 ?
                            <div className="corporatePackages-holder">
                                {EVENTS_PACKAGES_LIST && EVENTS_PACKAGES_LIST.length > 0 &&
                                    EVENTS_PACKAGES_LIST.map((packageInfo, index) => {
                                        return (
                                            <Link to={`/events/event-details/${packageInfo.event_slug}`}>
                                            <div style={{ cursor: "pointer" }}
                                                className="package" key={packageInfo?.id}
                                                onClick={() => handleEventDetails(packageInfo?.event_slug)}  >
                                                <div className="row overflow-hidden w-100-vw">
                                                    <div className="col-12 col-lg-5 pr-lg-0">
                                                        {
                                                            packageInfo?.image?.length === 1 ?
                                                                <div className="package-image">
                                                                    <img
                                                                        src={packageInfo?.image}
                                                                        className="w-100"
                                                                        alt=""
                                                                    />
                                                                </div> :
                                                                <OwlCarousel
                                                                    className="owl-theme owl-corporate"
                                                                    {...owl_packages} >
                                                                    {packageInfo.image.map((images) => {
                                                                        if(images){
                                                                            return (
                                                                                <div className="item">
                                                                                    <div className="package-image">
                                                                                        <img src={images} className="w-100" alt="" />
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })}
                                                                </OwlCarousel>
                                                        }
                                                    </div>
                                                    <div className="col-12 col-lg-7 pl-lg-0">
                                                        <div className="package-details">
                                                            {packageInfo?.price == 0 ?
                                                            <div className="free-tag">
                                                                <span>FREE SESSION</span>
                                                            </div>: null}
                                                            <h4 className="">{packageInfo?.pack_title}
                                                                {
                                                                    packageInfo?.workshop === true ?
                                                                        <span style={{ textTransform: 'uppercase' }}>ONLINE WORKSHOP</span> : ''
                                                                }
                                                            </h4>

                                                            <div className="info">
                                                                <p>{packageInfo.stream}</p>
                                                                <div className="line"></div>
                                                                <p>{pastEvents ? <span style={{fontWeight: 500}}>Held on: </span>: ""}{moment(packageInfo?.schedule_data?.date, 'DD.MM.YYYY').format('ddd Do MMM, YYYY')}, {moment(packageInfo?.schedule_data?.from,  'hh:mm').format('LT')} - {moment(packageInfo?.schedule_data?.to,  'hh:mm').format('LT')} </p>
                                                                <p>{packageInfo.brief_description}</p>
                                                            </div>
                                                            {packageInfo?.price == 0 ? <div className="points"><p className={pastEvents ? "opacity_2" : ""}>Book for free</p></div> :
                                                            <div className="points">
                                                                {
                                                                    (isCorporateAccount && isWipro) ? <p className={pastEvents ? "opacity_2" : ""}>Starting {packageInfo.price} pts</p> : <p className={pastEvents ? "opacity_2" : ""}>Starting Rs. {packageInfo.price}</p>

                                                                }
                                                            </div>}
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </Link>
                                        );
                                    })}
                                    {loadMoreBtn || isBot? 
                                        <div className="text-center">
                                            
                                            {isBot?<div className="d-flex justify-content-between">
                                                {CURRENT_PAGE>1&&<a href={`/events/${requiredUrl()?requiredUrl():''}?page=${CURRENT_PAGE-1}`}>
                                                    <button type="submit" className="load-more-btn" ><span>Prev page</span></button></a>}
                                                {NEXT_PAGE&&<a href={`/events/${requiredUrl()?requiredUrl():''}?page=${NEXT_PAGE}`}>
                                                    <button type="submit" className="load-more-btn" ><span>Next page</span></button></a>}
                                            </div>:<button type="submit" class="load-more-btn" onClick={() => loadMorePackages()}><span>Load more...</span></button>}
                                        </div> : loader ? <LazyLoadingLoader /> 
                                    : null}
                            </div> : <p className="text-center">Currently no events available for <span className='gradient-text'>{secName}</span></p>
                    }
                </div>
            </div>
        </div>
    )
}

export default Index;
