
import { BOOKING_INFORMATION_DATA, BOOKING_STATUS, CATEGORY_ID, CHANGE_DATA_FOR_PROCEED_TO_BOOK, CHECKOUT_DETAILS, CORPORATES_CATEGORY, CORPORATES_LOGIN, CORPORATES_LOGIN_USER_ID, CORPORATES_PACKAGES, CORPORATES_PACKAGES_LIST, CORPORATE_PACKAGE_DETAILS, DATA_CHANGED_BOOL_VAL, EDITED_CORP_HOLIDAY_NAME, EDITED_CORP_HOLIDAY_NAME_BOOL, EDITED_CORP_HOLIDAY_NUMBER, EDITED_CORP_HOLIDAY_NUMBER_BOOL, EDITED_GIFTVOUCHER_NAME, EDITED_GIFTVOUCHER_NAME_BOOL, EDITED_GIFTVOUCHER_NUMBER, EDITED_GIFTVOUCHER_NUMBER_BOOL, GUEST_DATA, INITIAL_CATEGORY_ID, PACKAGE_END_DATE, PACKAGE_START_DATE, PAGE_SIZE, SIMILAR_GIFTS, RESET_CORP_LIST, GET_SEARCH_VALUE, FILTER_VALUES, PACKAGE_FLEXIBLE_DATES, CORPORATE_TESTIMONIALS, NEAR_BY_PACKAGES, MORE_PACKAGES, CORPORATE_REVIEW_REFERENCE_ID, CORPORATE_REVIEW_POST, CORPORATE_REVIEW_LIST, CORPORATE_REVIEWLIST_TOTAL_PAGE, CORPORATE_REVIEWLIST_CURRENT_PAGE, CORPORATE_ACTIVE_PACKAGE_INDEX, INTERNAL_PACK_DATA, INTERNAL_CHECKOUT_DETAILS } from '../actions/types';

const date = JSON.parse(localStorage.getItem("startAndEndDate"));
const initialState = {
    corpLogin: [],
    corporates_login_user_id: {},
    corporates_category: [],
    corporates_packages: [],
    cat_id: null,
    corporates_package_details: localStorage.getItem("packageDetails") ? JSON.parse(localStorage.getItem("packageDetails")) : [],
    similar_gifts: [],
    pacStartDate: localStorage.getItem("startAndEndDate") ? date?.startDate : null,
    pacEndDate: localStorage.getItem("startAndEndDate") ? date?.endDate : null,
    pacFlexibleDates: localStorage.getItem("startAndEndDate") ? date?.flexibleDates : null,
    guestData: null,
    booking_information_data: localStorage.getItem("bookData") ? JSON.parse(localStorage.getItem("bookData")) : [],
    change_data_to_proceed_to_book: localStorage.getItem("dataChanged") ? JSON.parse(localStorage.getItem("dataChanged")) : {},
    data_changed_bool_val: localStorage.getItem("dataChangedBool") ? JSON.parse(localStorage.getItem("dataChangedBool")) : null,
    checkout_details: [],
    booking_details: [],
    editedGiftVoucherName: null,
    editedGiftVoucherNameBool: false,
    editedGiftVoucherNumber: null,
    editedGiftVoucherNumberBool: false,
    corpEditedHolidayName: null,
    corpEditedHolidayNameBool: false,
    corpEditedHolidayNumber: null,
    corpEditedHolidayNumberBool: false,
    page_size: 1,
    corporates_packages_list: [],
    corporates_next_pacakge_id: 1,
    searchValue: "",
    filterValues: "",
    corporates_testimonial: {},
    near_by_packages: [],
    more_packages: [],
    referenceId: null,
    reviewStatus: null,
    reviewList: null,
    reviewListTotalPage: null,
    reviewListCurrentPage: null,
    activePackageIndex:0,
    internalPackData:null,
    internalcheckout_details:null,

}

const corporatesReducers = (state = initialState, action) => {
    switch (action.type) {
        case CORPORATES_LOGIN:
            return {
                ...state,
                corpLogin: action.payload
            }
        case CORPORATES_LOGIN_USER_ID:
            return {
                ...state,
                corporates_login_user_id: action.payload
            }
        case CORPORATES_CATEGORY:
            return {
                ...state,
                corporates_category: action.payload
            }
        case CORPORATES_PACKAGES:
            return {
                ...state,
                corporates_packages: action.payload,
            }
        case RESET_CORP_LIST:
            return {
                ...state,
                corporates_packages_list: action.payload,
            }
        case CORPORATES_PACKAGES_LIST:
            return {
                ...state,
                corporates_packages_list: state.corporates_packages_list.concat(action.payload),
            }
        case CATEGORY_ID:
            return {
                ...state,
                cat_id: action.payload
            }
        case INITIAL_CATEGORY_ID:
            return {
                ...state,
                cat_id: action.payload,
            }
        case CORPORATE_PACKAGE_DETAILS:
            return {
                ...state,
                corporates_package_details: action.payload,
            }
        case SIMILAR_GIFTS:
            return {
                ...state,
                similar_gifts: action.payload,
            }
        case PACKAGE_START_DATE:
            return {
                ...state,
                pacStartDate: action.payload,
            }
        case PACKAGE_END_DATE:
            return {
                ...state,
                pacEndDate: action.payload,
            }
        case PACKAGE_FLEXIBLE_DATES:
            return {
                ...state,
                pacFlexibleDates: action.payload,
            }
        case GUEST_DATA:
            return {
                ...state,
                guestData: action.payload,
            }
        case BOOKING_INFORMATION_DATA:
            return {
                ...state,
                booking_information_data: action.payload,
            }
        case CHECKOUT_DETAILS:
            return {
                ...state,
                checkout_details: action.payload,
            }
        case INTERNAL_CHECKOUT_DETAILS:
                return {
                    ...state,
                    internalcheckout_details: action.payload,
                }    
        case BOOKING_STATUS:
            return {
                ...state,
                booking_details: action.payload,
            }
        case CHANGE_DATA_FOR_PROCEED_TO_BOOK:
            return {
                ...state,
                change_data_to_proceed_to_book: action.payload,
            }
        case DATA_CHANGED_BOOL_VAL:
            return {
                ...state,
                data_changed_bool_val: action.payload,
            }

        case EDITED_GIFTVOUCHER_NAME:
            return {
                ...state,
                editedGiftVoucherName: action.payload
            }
        case EDITED_GIFTVOUCHER_NAME_BOOL:
            return {
                ...state,
                editedGiftVoucherNameBool: action.payload
            }
        case EDITED_GIFTVOUCHER_NUMBER:
            return {
                ...state,
                editedGiftVoucherNumber: action.payload
            }
        case EDITED_GIFTVOUCHER_NUMBER_BOOL:
            return {
                ...state,
                editedGiftVoucherNumberBool: action.payload
            }
        case EDITED_CORP_HOLIDAY_NAME:
            return {
                ...state,
                corpEditedHolidayName: action.payload
            }
        case EDITED_CORP_HOLIDAY_NAME_BOOL:
            return {
                ...state,
                corpEditedHolidayNameBool: action.payload
            }
        case EDITED_CORP_HOLIDAY_NUMBER:
            return {
                ...state,
                corpEditedHolidayNumber: action.payload
            }
        case EDITED_CORP_HOLIDAY_NUMBER_BOOL:
            return {
                ...state,
                corpEditedHolidayNumberBool: action.payload
            }
        case PAGE_SIZE:
            return {
                ...state,
                page_size: action.payload
            }
        case GET_SEARCH_VALUE:
            return {
                ...state,
                searchValue: action.payload
            }
        case FILTER_VALUES:
            return {
                ...state,
                filterValues: action.payload
            }
        case CORPORATE_TESTIMONIALS:
            return {
                ...state,
                corporates_testimonial: action.payload
            }
        case NEAR_BY_PACKAGES:
            return {
                ...state,
                near_by_packages: action.payload
            }
        case MORE_PACKAGES:
            return {
                ...state,
                more_packages: action.payload
            }
        case CORPORATE_REVIEW_REFERENCE_ID:
            return {
                ...state,
                referenceId: action.payload
            }
        case CORPORATE_REVIEW_POST:
            return {
                ...state,
                reviewStatus: action.payload
            }
        case CORPORATE_REVIEW_LIST:
            return {
                ...state,
                reviewList: action.payload
            }
        case CORPORATE_REVIEWLIST_TOTAL_PAGE:
            return {
                ...state,
                reviewListTotalPage: action.payload
            }
        case CORPORATE_REVIEWLIST_CURRENT_PAGE:
            return {
                ...state,
                reviewListCurrentPage: action.payload
            }
            case CORPORATE_ACTIVE_PACKAGE_INDEX:
                return {
                    ...state,
                    activePackageIndex: action.payload
                }   
        case INTERNAL_PACK_DATA:
            return{
                ...state,
                internalPackData:action.payload
            }         
        default:
            return state
    }
}

export default corporatesReducers;