import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import * as visaRequestAction from "../../redux/actions/visaCallbackAction";
import "../../assets/css/partials/corporates/corporates.scss";
import { testEmail } from "../../utils/common";
import CommonModal from '../common/commonModal';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import requestReceived from '../../assets/images/corporates/employer-form.svg';
import closeIcon from '../../assets/images/common/close-modal.svg';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import IntlContactNumber from '../common/intlContactNumber';

import {
    INFORMATION_SUBMITTED,
    CONNECT_TEAM_MESSAGE,
} from "../../utils/constants";
import { Checkbox } from "@material-ui/core";

function CallbackForm(props) {
    const { enqueueSnackbar } = useSnackbar;
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [showForm, setShowForm] = useState(false)
    const [isVisible, setIsVisible] = useState(true);
    const [height, setHeight] = useState(0)

    const [state, setState] = useState({
        contact_name: "",
        contact_email: "",
        contact_number: "",
        first_time_traveller: false,
        country: "",
        special_request: "",
    });

    const [error, setError] = useState({
        contact_name: false,
        contact_email: false,
        contact_number: false,
        first_time_traveller: false,
        counrty: false,
        special_request: false,
        valid_email: false,
        valid_mobile: false,

    })

    const handleChange = (key, value) => {

        setState(() => ({
            ...state,
            [key]: value,
        }));
        setError({
            ...error,
            [key]: false,
            valid_email: key == "contact_email" ? false : error.valid_email
        })

    };

    const handleCheckbox = () => {
        const checkBox = document.querySelector('#checkbox');
        if (checkBox.checked == true) {
            checkBox.checked = false;
        }
    }

    const handleEnquiry = (e) => {
        e.preventDefault();
        if (!state.contact_name || state.contact_name === "") {
            focusInput('#contact_name');
            setError({ contact_name: true })
        } else if (!state.contact_email || state.contact_email === "") {
            focusInput('#contact_email');
            setError({ contact_email: true })

        } else if (!testEmail(state.contact_email)) {
            focusInput('#contact_email');
            setError({ valid_email: true })

        }
        else if((state.contact_number.startsWith('+91') && state.contact_number.length!==13)  || !isPossiblePhoneNumber(state.contact_number)){
            focusInput('.PhoneInputInput');
            setError({ contact_number: true })
        }
        // else if (state.contact_number.match(/^[0-9]+$/) == null) {
        //     setError({ contact_number: true })
        // }
        // else if (state.contact_number.length !== 10) {
        //     setError({ valid_mobile: true })
        // }
        else if (!state.country || state.country === "") {
            focusInput('#country');
            setError({ country: true })

        }

        else if (!state.special_request || state.special_request === "") {
            focusInput('#special_request');
            setError({ special_request: true })

        } else {

            dispatch(
                visaRequestAction.visaCallbackRequest(state, handleSuccess, handleErrors)
            );
            
        }
    };

    const refreshState = () => {
        setState({
            contact_name: "",
            contact_email: "",
            contact_number: "",
            first_time_traveller: false,
            country: "",
            special_request: "",
        });
        handleCheckbox();
        setError({
            contact_name: false,
            contact_email: false,
            contact_number: false,
            first_time_traveller: false,
            counrty: false,
            special_request: false,
            valid_email: false,
        });
    }

    // form submitted successfully
    const handleSuccess = () => {
        setShowModal(true)
        setTimeout(() => {
            // refreshState();
            
            closeFormHandler()
            setShowModal(false)
        }, 3000)

    };



    const handleErrors = (message) => {
        enqueueSnackbar(message, {
            variant: "error",
        });
    };

    const handleForm = () => {
        setShowForm(true)
    }
    const closeFormHandler = () => {
        setShowForm(false)
        props.setShowForm(false)
        refreshState()
    }

    useEffect(() => {
        window.addEventListener("scroll", listenToScroll);
        return () =>
            window.removeEventListener("scroll", listenToScroll);
    }, [])
    useEffect(() => {
        refreshState()
        
    }, [showForm, props.showContactForm])
    const listenToScroll = () => {
        let heightToHideFrom = document.body.scrollHeight - 850;
        const winScroll = document.body.scrollTop ||
            document.documentElement.scrollTop;
        setHeight(winScroll);

        if (winScroll > heightToHideFrom) {
            setIsVisible(false);
        } else {
            setIsVisible(true);
        }
    };

    const focusInput = (inputDom)=>{
          var elementPosition = document.querySelector(`${inputDom}`).getBoundingClientRect().top;
                      var offsetPosition = elementPosition + window.pageYOffset - 145;
                    //   window.scrollTo({top: offsetPosition, behavior: "smooth" });
                      setTimeout(()=>{
                          document.querySelector(inputDom).focus();
                      });
    };
    useEffect(()=>{
        if(showForm){
          document.querySelector('html').style.overflow = "hidden"
    
        } else {
          document.querySelector('html').style.overflow = "scroll"
        }
    
      }, [showForm])

    return (
        <>
            <div className={(showForm || props.showContactForm) ? "visa-enquire-mobile" : "visa-enquire-desk"}>
                <div className="login-holder" ref={props.callbackFormRef}>
                    {(showForm || props.showContactForm) ? <span onClick={closeFormHandler}><img src={closeIcon} className="close-icon" /></span> : null}

                    <h5>We can assist you</h5>
                    <form onSubmit={handleEnquiry}>
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <div class="line"></div>
                                <div className="input-holder">
                                    <p>About you</p>
                                    <div class="custom-input card--bg mb20">
                                        <input
                                            value={state.contact_name}
                                            type="text"
                                            class="form-control"
                                            id="contact_name"
                                            placeholder="Name"
                                            onChange={(e) => handleChange("contact_name", e.target.value)}
                                            onKeyPress={(event) => {
                                                if (!/^[A-Za-z ]+$/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}

                                        />
                                        <div class="form-line"></div>
                                        <label>Your name</label>
                                        <br />
                                        {error.contact_name === true ? <span className="errorMsg">Please enter name </span> : null}
                                    </div>
                                    <div class="custom-input card--bg mb20">
                                        <input
                                            value={state.contact_email}
                                            type="text"
                                            class="form-control"
                                            id="contact_email"
                                            placeholder="Email ID"
                                            onChange={(e) => handleChange("contact_email", e.target.value)}
                                        />
                                        <div class="form-line"></div>
                                        <label>Your email</label>
                                        <br />
                                        {error.contact_email === true ? <span className="errorMsg">Please enter email </span> : null}
                                        {error.valid_email === true ? <span className="errorMsg">Please enter valid email </span> : null}
                                    </div>
                                    {/* <div class="custom-input card--bg mb20">
                                        <input
                                            value={state.contact_number}
                                            type="text"
                                            class="form-control"
                                            placeholder="Phone number"
                                            max='10'
                                            onChange={(e) => handleChange("contact_number", e.target.value)}
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                        <div class="form-line"></div>
                                        <label>Your Contact number</label>
                                        <br />
                                        {error.contact_number === true ? <span className="errorMsg">Please enter contact number </span> : null}
                                        {error.valid_mobile === true ? <span className="errorMsg">Please enter 10 digit contact number</span> : null}
                                    </div> */}

                                    <div class="custom-input card--bg">
                                    <IntlContactNumber disabled={false} hasFormLine={true} hideSaveBtn={true} mobile={`${state.contact_number}`} numberChange={(val)=>{
                                                    setState(() => ({
                                                        ...state,
                                                        contact_number: val,
                                                    }))
                                                    setError({
                                                        ...error,
                                                        contact_number: false
                                                    })
                                                    } } 
                                                    hasError={error.contact_number} /> 
                                    </div> 
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 mt-lg-5">
                                <div className="input-holder d-flex justify-content-between">
                                    <p className="mb-0">Are you a first time traveller ?</p>
                                    <p style={{ float: "right" }}><label class="switch">
                                        <input

                                            type="checkbox"
                                            id="checkbox"
                                            onChange={(e) => handleChange("first_time_traveller", e.target.checked)}
                                        />
                                        <span class="slider round"></span>
                                    </label></p>


                                </div>
                                <div class="line"></div>
                                <div className="input-holder">
                                    <p>Visa Query</p>
                                    <div class="custom-input card--bg mb20">
                                        <input
                                            value={state.country}
                                            type="text"
                                            class="form-control"
                                            id="country"
                                            placeholder="Country name"
                                            onChange={(e) => handleChange("country", e.target.value)}
                                            onKeyPress={(event) => {
                                                if (!/^[A-Za-z ]+$/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                        <div class="form-line"></div>
                                        <label>Country you are travelling to</label>
                                        <br />
                                        {error.country === true ? <span className="errorMsg">Please enter country name </span> : null}
                                    </div>
                                    <div class="custom-input card--bg mb20">
                                        <input
                                            value={state.special_request}
                                            type="text"
                                            id="special_request"
                                            class="form-control"
                                            placeholder="Enter message here"
                                            onChange={(e) => handleChange("special_request", e.target.value)}
                                        />
                                        <div class="form-line"></div>
                                        <label>How can we help you</label>
                                        <br />
                                        {error.special_request === true ? <span className="errorMsg">Please enter message </span> : null}
                                    </div>
                                </div>
                                <div className="text-right">
                                    <button type="sumbit" className="btn otp-btn" style={{ width: 'auto' }}>
                                        Receive a callback
                                    </button>
                                </div>
                            </div>
                        </div>


                    </form>
                </div>

                {
                    // showModal ? <CommonModal showMyModal={showModal}
                    //   modalSubHeadingText={INFORMATION_SUBMITTED}
                    //   modalHeadingText={CONNECT_TEAM_MESSAGE} /> : null
                }

                <div className="login-success-popup">
                    <Dialog
                        open={showModal}
                    >
                        <DialogTitle>
                            <div className="success-holder" style={{ margin: '50px' }}>
                                <div>
                                    <img src={requestReceived} alt="successful" />
                                </div>
                                <div className="success-message">
                                    <p>Request recieved</p>
                                    <p>We will go through your request, <br />
                                        and connect with you shortly</p><hr></hr>
                                </div>

                            </div>
                        </DialogTitle>
                    </Dialog>
                </div>
            </div>
            {
                isVisible && <div className="mobile-buttons">
                    <div className="stcky-leftButton">
                        <div className="white-button" onClick={props.handleContact}>
                            <a >Contact</a>
                        </div>
                    </div>
                    <div className="stcky-rightButton">
                        <div className="custom-button" onClick={handleForm}>
                            <a >Enquire</a>
                        </div>
                    </div>
                </div>
            }

        </>
    );
}

export default CallbackForm;
