import React, { useEffect } from 'react'
import OwlCarousel from "react-owl-carousel";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { categoryOptions } from '../../resources/carouselOptions';
const CategoryHolder = React.memo(({ setCategory }) => {
    const categoryList= useSelector((state) => state?.experiences?.experience_categories?.data);
    return (
        <div className="slider-holder mt-0 mb-4">
            <div className="carousel-wrapper  category_carousel carousel-mobi-wrapper mt-lg-3" >

                <OwlCarousel className="owl-theme category-filter" {...categoryOptions} navSpeed={800}  >
                  
                        <Link to={`/experiences`} style={{ textDecoration: "none" }}>
                            <div className='item' 
                           >
                                <div className={`see-all-events`} onClick={() => setCategory("fromTab")}>

                                    <p className='all-events-p'>All <br /> Experiences</p> : <p className='see-all-events-p'>See all <br />Experiences</p>

                                </div>
                            </div>
                        </Link>
                    
                    {categoryList &&
                        categoryList.length > 0 &&
                        categoryList.map((item, idx) => (

                            <div
                                className={
                                    "category-card"
                                }
                                key={idx}
                                onClick={

                                    () => setCategory({ id: item.id, name: item?.name, slug: item?.section_slug,fromTab:true })

                                }

                                id={item.section_slug != "holidays" ? item.section_slug : item.name}
                            >

                                <div className="img-gradient">
                                    <div className="cat-img">
                                        <img src={item.image} alt="" />
                                        <div class="pseudo"></div>
                                    </div>
                                    <div className="card-info">
                                        <p>{item.name}</p>
                                    </div>
                                </div>
                            </div>
                            // {/* </Link> */}
                        ))}


                </OwlCarousel>
            </div>

        </div>
    )
})

export default CategoryHolder
