
import React, { useState, useEffect, useRef } from 'react';
import Header from "../../common/header";
import Footer from '../../../components/common/footer';
import { testEmail } from "../../../utils/common";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import backIcon from '../../../assets/images/common/back-icon.svg';
import '../../../assets/css/partials/common/checkout.scss';
import '../../../assets/css/partials/holidays/sendGift/buying.scss';
import Edit from '../../../assets/images/common/Edit.svg';
import * as HolidaysAction from "../../../redux/actions/holidaysActions";
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import { makeStyles } from '@material-ui/core/styles';
import IntlContactNumber from "../../common/intlContactNumber";
import { isPossiblePhoneNumber } from 'react-phone-number-input';



function Buying() {

    const useStyles = makeStyles({
        root: {
            width: '100%',
        },
        input: {
            width: 42,
        },
    });

    const myRef = useRef(null)
    const executeScroll = () => myRef.current.scrollIntoView()

    const history = useHistory();
    const [value, setValue] = useState(100);
    const classes = useStyles();
    const dispatch = useDispatch();
    const userDetails = JSON.parse(localStorage.getItem("userData"));
    const isCorporateAccount = userDetails?.user_details?.corporate_account;

    const buyVoucherDetailsData = useSelector(state => state.holidays.buyVoucherDetails);

    const { price, title, image, message, request, name, email, contactNumber } = buyVoucherDetailsData;

    const [scroll, setScroll] = useState(false);
    const [state, setstate] = useState({
        recipients_name: '',
        recipients_email: '',
        recipients_contact: '',
        price: price,
        title: title,
        image: image,
        message: message,
        request: request,
        name: name,
        email: email,
        contactNumber: contactNumber,
    })

    const [error, setError] = useState({
        recipients_name: false,
        recipients_email: false,
        recipients_contact: false,
        contactNumber: false,
        valid_email: false,

    })

    const points = parseInt(localStorage.getItem("points"))
    const [balance, setBalance] = useState(price);
    const [pointsLeft, setPointsLeft] = useState(points);
    const [isRewardPointsBtnBool, setIsRewardPointsBtnBool] = useState(false);
    const [usedRewardPoints, setUsedRewardPoints] = useState(0);

    const [disabled, setDisabled] = useState(true);
    const [numDisabled, setnumDisabled] = useState(true);
    const [emailDisabled, setEmailDisabled] = useState(true);

    const [initialAmount, setInitialAmount] = useState(buyVoucherDetailsData?.price);
    const [gstAmount, setGstAmount] = useState(price);
    const [includingGST, setIncludingGST] = useState(price)
    const [includingChangedGST, setIncludingChangedGST] = useState(price)
    const [afterCheckedGST, setAfterCheckedGST] = useState(price)

    useEffect(() => {
        const initialPrice = buyVoucherDetailsData?.price;
        const calculateGST = (initialPrice * 5 / 100) + initialPrice; // Calculating 5% GST 
        setInitialAmount(calculateGST - initialPrice);
        setIncludingGST(calculateGST);
        setPointsLeft(points);

        // hide/show confirm button on scroll
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 950);
        });

    }, [buyVoucherDetailsData?.price, price])

    const balanceUpdateHandler = () => {
        const checkBox = document.querySelector('#rewardPointsCheck')

        if (checkBox.checked === true) {
            setIsRewardPointsBtnBool(true);
            if (price === points) {
                setBalance(0);
                setPointsLeft(0);
                setUsedRewardPoints(price);
                setValue(0);
                setGstAmount(0);
                setAfterCheckedGST(0);
            }
            else if (price > points) {
                setBalance(price - points);
                setPointsLeft(0);
                setUsedRewardPoints(points);
                setValue(0);
                const getBalance = price - points;
                const calcGST = (getBalance * 5 / 100) + getBalance
                setGstAmount(calcGST);
                setAfterCheckedGST(calcGST - getBalance)
            }
            else if (price < points) {
                setBalance(0);
                setPointsLeft(points - price);
                setUsedRewardPoints(price);
                const getPointsLeft = points - price
                const calculatePercentage = getPointsLeft * 100 / points;
                setValue(calculatePercentage);
                setGstAmount(0);
                setAfterCheckedGST(0);
            }
        }
        else if (checkBox.checked === false) {
            setIsRewardPointsBtnBool(false);
            setUsedRewardPoints(0);
            setBalance(price);
            const getBalance = price;
            setGstAmount((getBalance * 5 / 100) + getBalance);
            setAfterCheckedGST(0)
            if (points > 0) {
                setValue(100);
            }
        }
    }


    const handleEditNameClick = () => {
        setDisabled(!disabled);
    }
    const handleEditEmailClick = () => {
        setEmailDisabled(!emailDisabled);
    }
    const handleEditNumberClick = () => {
        setnumDisabled(!numDisabled);
    }


    const onChangeHandler = (key, e) => {
        setstate({
            ...state,
            [key]: e.target.value
        })
    }
    const numberChange = (val)=>{
        setstate({
            ...state,
            contactNumber: val
        });
    }

    const handleSliderChange = (event, newValue) => {
        setValue(newValue);
    };

    const submitHandler = (e) => {
        e.preventDefault();
        if (!state.recipients_name || state.recipients_name === "") {
            setError({ recipients_name: true })
        } else if (!state.recipients_email || state.recipients_email === "") {

            setError({ recipients_email: true })

        } else if (!testEmail(state.recipients_email)) {

            setError({ valid_email: true })

        } else if (!state.recipients_contact || state.recipients_contact === "" || state.recipients_contact.length < 10 ||
            state.recipients_contact.length > 10) {

            setError({ recipients_contact: true })

        } else if (!state.contactNumber || state.contactNumber === "" || state.contactNumber.length < 10 ||
            state.contactNumber.length > 10) {

            setError({ contactNumber: true })

        }
        else if (state.recipients_contact.match(/^[0-9]+$/) == null) {

            setError({ recipients_contact: true })

        } else {
            let data = {
                ...state,
                total_points: usedRewardPoints,
                amount: !isRewardPointsBtnBool ? includingGST : gstAmount,

            }
            if (data?.title !== "" || data?.title !== null) {
                if (data?.amount > 0) {
                    dispatch(HolidaysAction.handleCheckoutVoucher(data, isCorporateAccount));
                    history.push("/voucherCheckout", data);
                    window.scrollTo(0, 0)
                }
                else {
                    dispatch(HolidaysAction.handleCheckoutVoucher(data, isCorporateAccount));
                    history.push(`/gift-voucher/payment-successfull`);
                }
            }
        }
    }

    return (
        <div className="buying-holder">
            <div className="innerPage-header">
                <Header />
            </div>
            <div className="container innerPage-container">
                <a href onClick={() => history.goBack()}>
                    <div className="back-button">
                        <img src={backIcon} alt="" />
                    </div>
                </a>
                <div className="purchase-details">
                    <h1>Purchasing Gift<br /> Voucher</h1>
                    <div className="line"></div>
                    <div className="main-wrapper">
                        <div className="left-wrapper">
                            <div className="wrapper ">


                                <div className="buyer-details" >
                                    <div className="recipient-form">
                                        <h6>Please share Recipient’s details</h6>
                                        <form>
                                            <div class="input-holder">
                                                <div class="form-group desk" >
                                                    <input type="text" className="form-control"
                                                        value={state.recipients_name}
                                                        onChange={(value) => onChangeHandler('recipients_name', value)}
                                                        placeholder="Name"
                                                    />
                                                    <label for="">Recipient’s Name</label>
                                                    <br />
                                                    {error.recipients_name === true ? <span className="errorMsg">Please enter recipient’s name </span> : null}
                                                </div>
                                                <div class="form-group mobile">
                                                    <input type="text" className="form-control"
                                                        value={state.recipients_name}
                                                        onChange={(value) => onChangeHandler('recipients_name', value)}
                                                        placeholder="Name"
                                                    />
                                                    <label for="">Recipient’s Name</label>
                                                    <br />
                                                    {error.recipients_name === true ? <span className="errorMsg">Please enter recipient’s name </span> : null}
                                                </div>

                                                <div class="edit" >
                                                    <img src={Edit} alt="" />

                                                </div>

                                            </div>
                                        </form>
                                        <form>
                                            <div class="input-holder">
                                                <div class="form-group desk">
                                                    <input type="text" className="form-control"
                                                        // disabled={emailDisabled}
                                                        value={state.recipients_email}
                                                        onChange={(value) => onChangeHandler('recipients_email', value)}
                                                        placeholder="Email"
                                                    />
                                                    <label for="">Recipient’s Email id</label>
                                                    <br />
                                                    {error.recipients_email === true ? <span className="errorMsg">Please enter recipient’s email id </span> : null}
                                                    {error.valid_email === true ? <span className="errorMsg">Please enter valid email </span> : null}
                                                </div>
                                                <div class="form-group mobile">
                                                    <input type="text" className="form-control"

                                                        value={state.recipients_email}
                                                        onChange={(value) => onChangeHandler('recipients_email', value)}
                                                        placeholder="Email"
                                                    />
                                                    <label for="">Recipient’s Email id</label>
                                                    <br />
                                                    {error.recipients_email === true ? <span className="errorMsg">Please enter recipient’s email id </span> : null}
                                                    {error.valid_email === true ? <span className="errorMsg">Please enter valid email </span> : null}
                                                </div>
                                                <div class="edit" onClick={handleEditEmailClick}>
                                                    <img src={Edit} alt="" />
                                                </div>
                                            </div>
                                        </form>
                                        <form>
                                            <div class="input-holder">
                                                <div class="form-group desk">
                                                    <input type="text" className="form-control"
                                                        // disabled={numDisabled}
                                                        onChange={(value) => onChangeHandler('recipients_contact', value)}
                                                        value={state.recipients_contact}
                                                        placeholder="Contact number"
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                    <label for="">Recipient’s Contact number</label>
                                                    <br />
                                                    {error.recipients_contact === true ? <span className="errorMsg">Please enter recipient’s 10 digit contact number </span> : null}
                                                </div>
                                                <div class="form-group mobile">
                                                    <input type="text" className="form-control"

                                                        onChange={(value) => onChangeHandler('recipients_contact', value)}
                                                        value={state.recipients_contact}
                                                        placeholder="Contact number"
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                    <label for="">Recipient’s Contact number</label>
                                                    <br />
                                                    {error.recipients_contact === true ? <span className="errorMsg">Please enter recipient’s 10 digit contact number </span> : null}
                                                </div>
                                                <div class="edit" onClick={handleEditNumberClick}>
                                                    <img src={Edit} alt="" />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="giftVoucher-holder voucher-selected">
                                    <div className="gift-voucher " >
                                        <div className="img-wrapper">
                                            <img src={state.image} alt="" className="voucher-image" />
                                        </div>
                                        <div className="voucher-content">
                                            <h2>{state.title}</h2>
                                            <div className="line"></div>
                                            {/* <p>{ !state.message ? null : state.message.split('_')}</p> */}
                                            <p>{state.message?.split('_')[1]}</p>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="right-wrapper">
                        <div className={scroll ? "d-none" : "d-block"}>
                                <div className="mobile-buttons text-center">
                                    <div className="stcky-rightButton">
                                        <div className="custom-button" onClick={executeScroll}>
                                            <a >Confirm</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="price-holder mb-4" style={{ marginTop: '30px' }} ref={myRef}>
                                <h4>Gift amount</h4>
                                <div className="price-line"></div>
                                {/* <br />
                                <div className="total-cost">
                                    <div className="total">
                                        <p>Total</p>
                                    </div>
                                    <div className="amount">
                                        <p>Rs. {price}</p>
                                    </div>
                                </div>
                                <br /> */}
                                {
                                    // If user is corporate user show points left and user can use points to purchase
                                    // else show amount to be paid
                                    isCorporateAccount ?
                                        (<div>
                                            <div className="total-cost">
                                                <div className="total">
                                                    <p>Total cost</p>
                                                </div>
                                                <div className="amount">
                                                    <p>{price}</p>
                                                </div>
                                            </div>
                                            {/* <p className="note">Note :  Additional charges for extra adults/children.</p> */}
                                            <div className="rewards-holder">
                                                <div className="my-rewards">
                                                    <div >
                                                        <p className="rewards">My Reward Points</p>
                                                    </div>
                                                    <div>
                                                        <p className="pts">{`-${isRewardPointsBtnBool ? usedRewardPoints : 0}pts`}</p>
                                                    </div>
                                                </div>
                                                <div className={classes.root}>
                                                    <Grid container spacing={2} alignItems="center">
                                                        <Grid item>
                                                        </Grid>
                                                        <Grid item xs>
                                                            <Slider
                                                                value={(typeof value === 'number' && isRewardPointsBtnBool) ? value : points > 0 ? 100 : 0}
                                                                onChange={handleSliderChange}
                                                                aria-labelledby="input-slider" disabled
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <p className="points-left">{isRewardPointsBtnBool ? pointsLeft : points} pts left</p>
                                                    <div className="use-rewards">
                                                        <div>
                                                            <p className="reward-points">Use reward points</p>
                                                        </div>
                                                        <div>
                                                            <label className="switch">
                                                                <input type="checkbox" onChange={balanceUpdateHandler}
                                                                    id="rewardPointsCheck"
                                                                />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="balance-holder d-flex justify-content-between">
                                                <div>
                                                    <p>Balance to be paid</p>
                                                    {
                                                        (isRewardPointsBtnBool && balance === 0) ? '' : <p>GST 5%</p>
                                                    }
                                                </div>
                                                <div>
                                                    <p className="text-right">Rs {!isRewardPointsBtnBool ? buyVoucherDetailsData?.price.toFixed(2) : balance.toFixed(2)}</p>
                                                    {
                                                        (isRewardPointsBtnBool && balance === 0) ? '' : <p className="text-right"><span>+</span> Rs {isRewardPointsBtnBool ? afterCheckedGST.toFixed(2) : initialAmount.toFixed(2)}</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>) :
                                        (<div>
                                            <div className="total-cost">
                                                <div className="total">
                                                    <p style={{ marginBottom: '10px' }}>Amount</p>
                                                    <p style={{ fontWeight: '500' }}>GST 5%</p>
                                                </div>
                                                <div className="amount">
                                                    <p className="mb-2 text-right" >Rs {buyVoucherDetailsData?.price?.toFixed(2)}</p>
                                                    <p className="text-right"><span>+</span> Rs {initialAmount.toFixed(2)}</p>
                                                </div>
                                            </div>
                                            <div className="line ml-0"></div>
                                        </div>)
                                }

                                <button type="button" className="btn proceed-to-book w-100" onClick={submitHandler} >
                                    {
                                        (isRewardPointsBtnBool && balance === 0) ? 'Pay with points' : 'Checkout'
                                    }
                                </button>
                            </div>
                            
                            <div className="buyer-details profile-details" style={{ flex: '1' }}>
                                <h6>Our team will connect with you on below details</h6>
                                <form>
                                    <div class="input-holder">
                                        <div class="form-group">
                                            <input type="text" className="form-control"
                                                value={state.name}
                                                // disabled={disabled}
                                                onChange={(value) => onChangeHandler('name', value)}
                                                placeholder="Name"
                                            />
                                            <label for="">Your Name</label>
                                        </div>
                                        <div class="edit" onClick={handleEditNameClick}>
                                            <img src={Edit} alt="" />
                                        </div>
                                    </div>
                                </form>
                                <form>
                                    <div class="input-holder">
                                        <div class="form-group">
                                            <input type="text" className="form-control"
                                                // disabled={emailDisabled}
                                                value={state.email}
                                                onChange={(value) => onChangeHandler('email', value)}
                                                placeholder="Email"
                                            />
                                            <label for="">Your Email id</label>
                                        </div>
                                        <div class="edit" onClick={handleEditEmailClick}>
                                            <img src={Edit} alt="" />
                                        </div>
                                    </div>
                                </form>
                                {/* <form>
                                    <div class="input-holder">
                                        <div class="form-group">
                                            <input type="text" className="form-control"
                                                // disabled={numDisabled}
                                                onChange={(value) => onChangeHandler('contactNumber', value)}
                                                value={state.contactNumber}
                                                placeholder="Contact number"
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                            />
                                            <label for="">Your Contact number</label>
                                            {error.contactNumber === true ? <span className="errorMsg">Please enter 10 digit contact number </span> : null}
                                        </div>
                                        <div class="edit" onClick={handleEditNumberClick}>
                                            <img src={Edit} alt="" />
                                        </div>
                                    </div>
                                </form> */}
                                <IntlContactNumber mobile={state.contactNumber||''} disabled={false} numberChange={numberChange} hasError={error.contactNumber}  />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="mobile-buttons">
                <div className="stcky-leftButton">
                    <div className="white-button">
                        <a>Rs. {price}</a>
                    </div>
                </div>
                <div className="stcky-rightButton">
                    <div className="custom-button" onClick={submitHandler}>
                        <a >Prepare a Gift</a>
                    </div>
                </div>
            </div> */}

            <Footer />
        </div >
    )
}

export default Buying;