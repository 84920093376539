import React, { useEffect, useRef, useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import { useSelector } from "react-redux";

const PackageTabsHolder = ({
  category,
  setCategory,
  Tabdata,
  setIsStuck,
  isStuck,
  isStay,
  isItinerary,
  categoryChanged,
}) => {
  const [show, setShow] = useState(false);
  const SCROLL_REF = useSelector((state) => state.holidays.set_Scroll);
  const [ismobile, setIsmobile] = useState(window.innerWidth <= 767)
  const navRef = useRef(null);

  function chaneCategory(data) {
    setCategory(data, "tab");

    setShow(false);
  }
  const handleResize = () => {
    setIsmobile(window.innerWidth <= 767)
  }
  var nav = document
    .querySelector(
      ".navbar-expand-lg"

    )
    ?.getBoundingClientRect().bottom;
  const stuckPoint = ismobile ? 0 : 75
  const handleScroll = () => {

    if (navRef.current) {

      const isStuckStatus = navRef.current.getBoundingClientRect().top <= stuckPoint;

      if (ismobile) {
        if (isStuckStatus) {
          document.querySelector(".moblie-tab-holder").classList.add("fixed");
          if (isStay) {
            document.querySelector(".stay")?.classList.remove("stay-package-description");
            document.querySelector(".stay")?.classList.add("stay-sticky-description");
          }
          document.querySelector(".package-nav").style.paddingBottom = "60px"

        }
        else {
          document
            .querySelector(".moblie-tab-holder")
            .classList.remove("fixed");
          if (isStay) {
            document.querySelector(".stay")?.classList.add("stay-package-description");
            document.querySelector(".stay")?.classList.remove("stay-sticky-description");
          }
          document.querySelector(".package-nav").style.paddingBottom = "0px"

        }
      } else {
        if (isStuckStatus) {
          document.querySelector(".nav-holders").classList.add("sticky-nav");
          setIsStuck(true);
          document.querySelector(".package-nav").style.paddingBottom = "60px"
        } else {
          document
            .querySelector(".nav-holders")
            .classList.remove("sticky-nav");
          setIsStuck(false);
          document.querySelector(".package-nav").style.paddingBottom = "0px"
        }
      }
    }
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', () => handleResize);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);



  const categories = Tabdata?.filter((e) => e != category);
  return (
    <>
      {!SCROLL_REF &&
        <div
          id="nav"
          ref={navRef}
          className="package-nav"
        >
          {
            //DESKTOP VIEW
          }
          <div
            className='nav-holders'
            style={{ paddingRight: isStay && isStuck ? "80px" : "" }}
          >
            
            <div className={isStuck ? ` ${isStay ? `col-lg-8 container-fluid` : "container-fluid"}` : ""} style={{ padding: "0px" }}>
              <nav className={isItinerary ? "container p-0" : " "}>
                <ul className={`d-flex  ${isStay && isStuck ? "stay-tab " : isItinerary ? Tabdata.length < 5 ? "" : "justify-content-between" : ""}`} style={{ gap: isStay ? "40px" : isItinerary && Tabdata.length < 5 ? "70px" : "" }}>
                  {Tabdata &&
                    Tabdata.map((e, i) => (
                      <li>
                        <a
                          id={i}
                          className={
                            category == e
                              ? "active"
                              : category == "" && e == "Overview"
                                ? "active"
                                : ""
                          }
                          onClick={() => setCategory(e, "tab")}
                        >
                          {e}
                        </a>
                      </li>
                    ))}
                </ul>
              </nav>
            </div>

          </div>
          {
            //MOBILE VIEW
          }
          <div >
            <div className="moblie-tab-holder">
              <div className="mob-nav">
                <p onClick={() => setShow(!show)} className="mob-tabs">
                  {category ? category : "Overview"}

                  <span>
                    <FaChevronDown />
                  </span>
                </p>
              </div>

              <ul className={`${show ? "d-block" : "d-none"} ${isStay && !isStuck ? "stay-ul" : ""}`} style={{ width: "100%" }}>
                {categories &&
                  categories.map((e, i) => (
                    <li
                      className="mob-tabs"
                      onClick={() => chaneCategory(e)}
                      key={i}
                    >
                      {e}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default PackageTabsHolder;
