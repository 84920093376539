import React, { useState } from 'react';
import { Link, withRouter, Route, useHistory, useLocation } from 'react-router-dom';
import '../../assets/css/partials/auth/auth.scss';
import LeftIcon from '../../assets/icons/authIcons/Left.png';
import HammockLogo from '../../assets/icons/authIcons/hammockLogo.png';
import LoginGroup from '../../assets/icons/authIcons/login-group.png';
import BgGradient from '../../assets/images/authImages/BG-gradient.png';
import Tab from './authTab';
import Login from './login';
import SignUp from './signUp';

const AuthHomeComponent = (props) => {
    const [ showModal, setShowModal ] = useState(false);
    const [ modalSubHeadingText, setModalSubHeadingText ] = useState("");
    const [ modalHeadingText, setModalHeadingText ] = useState("");
    const location=useLocation()
    const history=useHistory()
    const showModalStatus = (isModalOpen) => {
        setShowModal(isModalOpen);
        setModalSubHeadingText("Successfully logged in")
        setModalHeadingText("Welcom back to Hammock")
    };

    
    const handleSignUpIndex = () =>{
        setShowModal(true)
        setModalSubHeadingText("Successfully Sign Up!")
        setModalHeadingText("Welcom back to Hammock")
    }
    const goBack=()=>{
if(location?.state?.fromHeader){
history.goBack()
}else{
    history.push('/')
}
    }
    return (
        <>
        <div className="auth-wrapper login row">
            <div className="login-banner-content col-sm">
                    <div className="login-text">
                        <div className="login-logo">
                            <img src={HammockLogo} alt="hammocklogo"/>
                            <img src={LoginGroup} alt="group"/>
                        </div>
                        <p className="login-text-heading">Hammock Leisure Holidays</p>
                        <p className="login-text-subheading">Make magical Experience happen</p>
                        
                    </div>
                    <div className="vectors vector1">
                        <img src={BgGradient} className="img-fluid" alt="bg-gradient" />
                    </div>
            </div>
            <div className="login-auth-content col-sm">
                <div className="login-auth-content-block">
                    <div onClick={goBack} className="auth-left-icon cursor-pointer">
                         <img src={LeftIcon} alt="leftIcon"/>
                   </div>
                    <div className="auth-main-container">
                        <Tab/>
                        <Route exact path="/login" render={() => <Login showModal={showModalStatus}/>}/>
                        <Route exact path="/signup" render={() => <SignUp showModal={handleSignUpIndex}/>}/>
                    </div>
                </div>
            </div>
            
        </div>
            {/*For mobile view
            <div className="login-auth-content popup col-sm">
                <div className="login-auth-content-block">
                    <div className="auth-left-icon">
                        <Link to="/">
                            <img src={LeftIcon} alt="leftIcon"/>
                        </Link>
                    </div>
                    <div className="auth-main-container">
                        <Tab/>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-login" role="tabpanel" aria-labelledby="pills-login-tab">
                             <Login showModal={showModalStatus}/>
                            </div>
                            <div className="tab-pane fade" id="pills-signup" role="tabpanel"  aria-labelledby="pills-signup-tab">
                                <SignUp showModal={handleSignUpIndex}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            </>
    );
};

export default withRouter(AuthHomeComponent);

