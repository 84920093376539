import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSelector, useDispatch } from "react-redux";
import closeIcon from '../../assets/images/common/close-modal.svg';
import { testEmail } from "../../utils/common";
import * as HolidaysAction from '../../redux/actions/holidaysActions';
import requestReceived from '../../assets/images/corporates/employer-form.svg';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import IntlContactNumber from '../common/intlContactNumber';

function UserRequestForm({ userData,setSuccess, openModal, showMyModal, customize, userRequestFormRef }) {
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => {
        openModal(false)
    }
    const data = JSON.parse(localStorage.getItem("userData"));
    const contactName = localStorage.getItem("full_name");
    let contactNum = localStorage.getItem("mobile");

    if(isNaN(contactNum)) {
        let userDataStored = JSON.parse(localStorage.getItem("userData"));
        contactNum = userDataStored ? userDataStored?.user_details?.mobile : "";
    }
    const [state, setState] = useState({
        contact_name: contactName ? contactName : "",
        contact_email: data?.user_details?.email ? data?.user_details?.email : "",
        contact_number: contactNum ? contactNum : "" ,
    });

    const [error, setError] = useState({
        contact_name: false,
        contact_email: false,
        contact_number: false,
        valid_email: false,
    })

    const handleChange = (key, value) => {
        setState(() => ({
            ...state,
            [key]: value.target.value,
        }));
        setError({
            ...error,
            [key]: false,
            valid_email: key == "contact_email" ? false : error.valid_email
        })
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!state.contact_name || state.contact_name === "") {
            setError({ contact_name: true })
        }
        else if (!state.contact_email || state.contact_email === "") {
            setError({ contact_email: true })
        }
        else if (!testEmail(state.contact_email)) {
            setError({ valid_email: true })
        }
        else if((state.contact_number.startsWith('+91') && state.contact_number.length!==13)  || !isPossiblePhoneNumber(state.contact_number)){
            setError({ contact_number: true })
        }
        else {
            const body = { ...userData, ...state }
            if(customize){
                dispatch(
                    HolidaysAction.submitCustomizeRequest(body, handleSuccess)
                );
            }
            else {
                dispatch(
                    HolidaysAction.submitInternationalRequest(body, handleSuccess)
                );
            }

        }
    };

     // form submitted successfully
  const handleSuccess = () => {
      setShowModal(true)
    setTimeout(() => {
      refreshState();
      setShowModal(false);
      openModal(false);
    }, 2000)
  };

  const refreshState = () => {
    setSuccess();
    setState({
        contact_name: "",
        contact_email: "",
        contact_number: "",
    });
    setError({
        contact_name: false,
        contact_email: false,
        contact_number: false,
        valid_email: false,
    });
    window.location.reload();
   
  }

    return (
        <div className={` ${showMyModal ? 'show' : 'hide'}`} id="monthsModal" ref={userRequestFormRef}>
            <div className="user--modal">
                <div className="position-relative">
                    <h5>We will coordinate with you on below details</h5>
                    <span className="closeIcon" onClick={handleClose}><img src={closeIcon} className="close-icon" /></span>
                    <div className="line"></div>
                    <div>
                        <form>
                            <div class="custom-input card--bg mb20 user-card-bg">
                                <input type="text" class="form-control" placeholder="Your name" onChange={(value) => handleChange("contact_name", value)} value={state.contact_name} />
                                <div class="form-line"></div>
                                <label>Your name</label><br />
                                {error.contact_name === true ? <span className="errorMsg text-center">Please enter name </span> : null}
                            </div>

                            <div class="custom-input card--bg mb20 user-card-bg">
                                <input type="text" class="form-control" placeholder="Your Email id" onChange={(value) => handleChange("contact_email", value)} value={state.contact_email} />
                                <div class="form-line"></div>
                                <label>Your email id</label><br />
                                {error.contact_email === true ? <span className="errorMsg text-center">Please enter a email Id </span> : null}
                                {error.valid_email === true ? <span className="errorMsg text-center">Please enter a valid email Id</span> : null}
                            </div>
                            {/* <div class="custom-input card--bg mb20 user-card-bg">
                                <input type="text" class="form-control" placeholder="Your Contact number" onChange={(value) => handleChange("contact_number", value)} value={state.contact_number} onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }} />
                                <div class="form-line"></div>
                                <label>Your Contact number</label><br />
                                {error.contact_number === true ? <span className="errorMsg text-center">Please enter a valid 10 digits mobile number</span> : null}
                            </div> */}
                            <div className="custom-input mb20 user-card-bg">
                            <IntlContactNumber disabled={false} hasFormLine={true} titleValue={'Your mobile number'} labelClass={'w-100'}  hideSaveBtn={true} mobile={`${state.contact_number}`} numberChange={(val)=>{
                                            setState(() => ({
                                                ...state,
                                                contact_number: val,
                                            })) 
                                            setError({
                                                ...error,
                                                contact_number: false
                                            })
                                        
                                            }}
                                            hasError={error.contact_number} /> 
                            </div>                                            
                            <div class="line"></div>
                            <div class="text-center">
                                <button type="button" class="btn otp-btn w-auto" onClick={handleSubmit}>Ok, Done</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>

            {
                // success popup
                <div className="login-success-popup">
                    <Dialog
                        open={showModal}
                    >
                        <DialogTitle>
                            <div className="success-holder" style={{ margin: '50px' }}>
                                <div>
                                    <img src={requestReceived} alt="successful" />
                                </div>
                                <div className="success-message">
                                    <p>Request recieved</p>
                                    <p>We will go through your request, <br />
                                        and connect with you shortly</p><hr></hr>
                                </div>

                            </div>
                        </DialogTitle>
                    </Dialog>
                </div>
            }

        </div>
    )
}

export default UserRequestForm
