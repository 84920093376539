import React, { useEffect,useRef, useState } from "react";
import "../../assets/css/partials/sliders.scss";
import OwlCarousel from "react-owl-carousel";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import {options} from '../../resources/carouselOptions';
import { useSnackbar } from "notistack";
import * as homeAction from "../../redux/actions/homeAction";
import * as HolidaysAction from '../../redux/actions/holidaysActions';

function HolidaySlider() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loader, setLoader] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const SCROLL_REF = useSelector((state) => state.holidays.set_Scroll);

  const HOLIDAY_LIST = useSelector(
    (state) => state.homepage.holidayList.data
  );

  const handleRoute = (route) => {
    dispatch(HolidaysAction.setSearchValue(""))
    dispatch(HolidaysAction.setFilterValues(""))
    dispatch(HolidaysAction.setRouteValue("Bharat Dekho"));
    localStorage.setItem("routeFromHome", true)
    localStorage.removeItem("holidayCategory")
    history.push({ pathname: `/${route}`, seeAll: {bool: true}});
    // history.push(`/${route}`);
    window.scrollTo(0,0);
  };

 

  useEffect(() => {
    dispatch(homeAction.holidayPackages(handleLoader));
    if (SCROLL_REF) {
      executeScroll();
    }
  }, [SCROLL_REF]);

  useEffect(()=>{
    // setTimeout(()=>{
    //   let prevUrl=localStorage.getItem('prevUrl');
    //   if(prevUrl?.length){
    //     if(prevUrl?.split('/').slice(0, -1).join('/')==='/holidays/holiday-details'){
    //       myRef.current.scrollIntoView();
    //     }
    //   }
    // });
    
  }, []);

  const myRef = useRef(null)
  const executeScroll = () => {
    myRef.current.scrollIntoView();
    dispatch(HolidaysAction.setScroll(false));
  }
  console.log("object")

  const handleLoader = () => {
    setLoader(false);
  };

  const goToPackageDetails = (id) => {
    history.push(`/holidays/holiday-details/${id}`);
    window.scrollTo(0, 0);
  };
  return (
    <div className="slider-holder" ref={myRef}>
      <div className="container">
        <h4> <span> Holidays </span> like never before
        </h4>
      </div>
      {!loader ? (
        <div className="carousel-wrapper carousel-mobi-wrapper">
          <OwlCarousel className="owl-theme" {...options}>
            {HOLIDAY_LIST &&
              HOLIDAY_LIST.length > 0 &&
              HOLIDAY_LIST.map((item,idx) => (
                <Link to={{pathname:`/holidays/holiday-details/${item.holiday_slug}`,state: { id: item?.id}}} key={idx}>
                <div className="item cursor-pointer" onClick={() => {
                  localStorage.removeItem("filteredList")
                  dispatch(HolidaysAction.setScroll(true));
                  localStorage.setItem("hol-actidx",item?.id)
                }  }>
                  <div className="card-wrapper" >
                    <img src={item.image} alt={item.pack_title} />
                    <div className="info">
                      <p>{item.pack_title}</p>
                      {
                        //  <p>{item.points} pts</p>
                      }
                    </div>
                  </div>
                </div>
                </Link>
              ))}
            {
              HOLIDAY_LIST?.length > 3 ?
                <div className="item">
                  <div
                    className="card-wrapper see-all"
                    onClick={() => {
                      handleRoute("holidays")
                      dispatch(HolidaysAction.setScroll(true));
                      }} >
                    <p>  See all <br />
                      Holidays </p>
                  </div>
                </div> : null
            }

          </OwlCarousel>
        </div>
      ) : null}
    </div>
  )
}

export default HolidaySlider
