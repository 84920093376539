import React from "react";
import "../../assets/css/partials/corporates/corporates.scss";
import { CLIENTS_LIST1, CLIENTS_LIST2, CLIENTS_LIST3 } from "../../resources/clients";
function clients() {
  return (
    <div className="our-clients">
      <h6>Some of our Clientele</h6>
      <div className="logo-wrapper">
          <div className="d-flex justify-content-xl-center">
            {CLIENTS_LIST1.length > 0 &&
              CLIENTS_LIST1.map((item) => (
                <div className="card-wrapper">
                  <div className="img-holder">
                    <img src={item.image} alt="" />
                  </div>
                  <div className="info">
                    <p className="mb-0">{item.name}</p>
                  </div>
                </div>
              ))}
          </div>
        
          <div className="d-flex justify-content-xl-center">
          {CLIENTS_LIST2.length > 0 &&
            CLIENTS_LIST2.map((item) => (
              <div className="card-wrapper">
                <div className="img-holder">
                  <img src={item.image} alt="" />
                </div>
                <div className="info">
                  <p className="mb-0">{item.name}</p>
                </div>
              </div>
            ))}
        </div>
          <div className="d-flex justify-content-xl-center">
          {CLIENTS_LIST3.length > 0 &&
            CLIENTS_LIST3.map((item) => (
              <div className="card-wrapper" >
                <div className="img-holder">
                  <img src={item.image} alt="" />
                </div>
                <div className="info">
                  <p className="mb-0">{item.name}</p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default clients;
