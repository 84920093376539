import React, { useEffect } from 'react'
import CareersMain from '../../../assets/images/home/Converted/careersmain.webp'
import '../../../assets/css/partials/common/careers&about-us.scss'
import { useHistory } from "react-router-dom";
import Logo from "../../../assets/images/common/logo.svg";
import CloseIcon from "../../../assets/images/common/close-icon.svg";
import Footer from '../footer'
import Header from '../header';
import { Helmet } from 'react-helmet';
const Careers = () => {
  const jobs = ["Travel and Tourism Manager", "Travel and Tourism Executive", "Junior Executive Tours and Travel"]
  const history = useHistory()
  const goToDescription = (job) => {
    history.push({ pathname: "/job-description", state: job })
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
       <Helmet>
        <title>Careers</title>
      </Helmet>
      <div className=" d-md-none">
        <Header />
      </div>
      <div className='career-logo d-flex justify-content-between'>
        <img src={Logo} alt="logo" onClick={()=>history.push("/")} />
        <img alt="close-icon" className='close-icon' onClick={() => history.goBack()} src={CloseIcon} />
      </div>
      <div className='careers-banner-holder'>
        <div className="main-banner">
          <img src={CareersMain} alt="CareersMain" />
        </div>
        <div className='text-holder'>
          <p className='main-title'>Careers</p>
          <p className='sub-title'>Join our Journey</p>
          <p className='content'>If you're someone who;<br />
            loves traveling, loves experiencing a new culture, loves exploring hidden places<br />
            loves talking to people, loves to try new cuisines, loves a good adventure<br />
            and would want to get paid while doing this?<br />
            Then you're the right person for this opportunity... </p>
        </div>
      </div>
      <div className='container'>
        <div className='current-openings-wrapper d-flex'>
          <p className='gradient-text'>Current Openings</p>
          <div className='jobs-wrapper d-flex flex-column '>
            {jobs.map((job, i) => (
              <div onClick={() => goToDescription(job)} key={i} className="jobs-list d-flex  justify-content-between">
                <p className='jop-title gradient-text'>{job}</p>
                <button  className='view-btn'>View details</button></div>
            ))
            }
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Careers