import React, { useEffect, useState } from 'react'
import UserProfile from '../profile'
import { Tabs, Tab } from "react-bootstrap";
import Slider from '@material-ui/core/Slider';
import Footer from '../footer';
import Grid from '@material-ui/core/Grid';
import * as corporateAction from '../../../redux/actions/corporatesAuthActions';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from "../../../assets/images/common/close-modal.svg";
import { makeStyles } from '@material-ui/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { PAYMENT_URL, PAYMENT_URL_TEST } from "../../../config/api";
import LoginPopup from "../../auth/login";
import SignupPopup from "../../auth/signUp";

import styled from 'styled-components';
import Dialog from "@material-ui/core/Dialog";
const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    input: {
        width: 42,
    },
});
const StyledDialog = styled(Dialog)`
  & > .MuiDialog-container > .MuiPaper-root {
    width: 430px;
    background-color: #f5f5f5;
    position: relative;
  }
  & > .MuiDialog-container > .MuiPaper-root > .close-login {
    position: absolute;
    top: 12px;
    right: 23px;
    cursor: pointer;
  }
`;
const InstantCheckout = (internalPackPage) => {
    const classes = useStyles();
    const location = useLocation()
    const history = useHistory()
    const data = internalPackPage.location.state;
    const packID = internalPackPage.match.params.id;
    const packData = useSelector(state => state.corporates.internalPackData);
    const profile_details = useSelector(state => state?.userProfile?.profile_details);
    const points =  profile_details?.data?.points;
    const [showLoginModal, setShowLoginModal] = useState(false);
    const dispatch = useDispatch()
    const userInfo = JSON.parse(localStorage.getItem("userData"));
    const companyDomain = userInfo?.organisation?.email_domain;
    const isWipro = (companyDomain === "wipro.com");
    const [isRewardPointsBtnBool, setIsRewardPointsBtnBool] = useState(false);
    let isJustLoggedIn = localStorage.getItem("isJustLoggedIn");
    const checkoutDetails = data;
    const [pointsLeft, setPointsLeft] = useState(points);
    const [usedRewardPoints, setUsedRewardPoints] = useState(0);
    const [value, setValue] = useState(100);
    const [total_price, settotal_price] = useState(packData?.amount)
    const [balance, setBalance] = useState(packData?.amount);
    const [gstAmount, setGstAmount] = useState(packData?.amount);
    const [afterCheckedGST, setAfterCheckedGST] = useState(packData?.amount)
    const [initialAmount, setInitialAmount] = useState(packData?.amount);
    const [includingGST, setIncludingGST] = useState(packData?.amount)
    const [radiovalue, setRadioValue] = useState('DC');
    const [checkoutPage, setcheckoutPage] = useState(location?.pathname?.startsWith('/checkout'))
    const [clickType, setClickType] = useState("login");
    const [handleError,setHandleError]=useState(false)
    useEffect(() => {
        if (location?.pathname) {
            setcheckoutPage(location?.pathname?.startsWith('/checkout'))
        }
    }, [location?.pathname])
    const handleSliderChange = (event, newValue) => {
        value(newValue);
    };
    
    const handleClose = () => {
        setShowLoginModal(false);
      };
      useEffect(()=>{
        settotal_price(packData?.is_advance_paid?packData?.balance_amount:packData?.is_advance?packData?.advance_amount:packData?.balance_amount);
        setBalance(packData?.is_advance_paid?packData?.balance_amount:packData?.is_advance?packData?.advance_amount:packData?.balance_amount)
      },[packData])
    const balanceUpdateHandler = () => {
        const checkBox = document.querySelector('#rewardPointsCheck')
        if (checkBox.checked === true) {
            setIsRewardPointsBtnBool(true);
            if (total_price === points) {
                setBalance(0);
                setPointsLeft(0);
                setUsedRewardPoints(total_price);
                setValue(0);
                setGstAmount(0);
                setAfterCheckedGST(0);
            }
            else if (total_price > points) {
                setBalance(total_price - points);
                setPointsLeft(0);
                setUsedRewardPoints(points);
                setValue(0);
                const getBalance = total_price - points;
                const calcGST = (getBalance * 5 / 100) + getBalance
                setGstAmount(calcGST);
                setAfterCheckedGST(calcGST - getBalance)

            }
            else if (total_price < points) {
                setBalance(0);
                setPointsLeft(points - total_price);
                setUsedRewardPoints(total_price);
                const getPointsLeft = points - total_price
                const calculatePercentage = getPointsLeft * 100 / points;
                setValue(calculatePercentage);
                setGstAmount(0);
                setAfterCheckedGST(0);
            }
        }
        else if (checkBox.checked === false) {
            setIsRewardPointsBtnBool(false);
            setUsedRewardPoints(0);
            setBalance(total_price);
            const getBalance = total_price;
            setGstAmount((getBalance * 5 / 100) + getBalance);
            setAfterCheckedGST(0)
            if (points > 0) {
                setValue(100);
            }
        }
    }
    useEffect(() => {
        const initialPrice = total_price
        const calculateGST = (initialPrice * 5 / 100) + initialPrice; // Calculating 5% GST 
        setInitialAmount(calculateGST - initialPrice);
        setIncludingGST(calculateGST)
        // hide/show confirm button on scroll
        // let mobile=isEnablePayment?900:350
        // window.addEventListener("scroll", () => {
        //     setScroll(window.scrollY > mobile);
        // });
    }, [total_price, radiovalue])
    const paymentModeChange = (e) => {
        setRadioValue(e.target.value);
    }
    const clearData=()=>{
localStorage.removeItem("BookingData")
    }
    const paidWithPoints = () => {
        history.push(`/payment-successful`);
    }
    const handleCheckout = () => {
        const getToken = localStorage.getItem("token");
       
        if (getToken === "" || getToken == null) {
            setShowLoginModal(true)
        }else{
            const data = {
                total_points: usedRewardPoints,
                amount: !isRewardPointsBtnBool ? includingGST : gstAmount,
                contact_name: packData?.contact_name,
                contact_email: packData?.contact_email,
                contact_number: packData?.contact_number,
                pay_mode: radiovalue,
                pack_slug: packID
            }
            if(data?.contact_name){
                if(data.amount>0){
            dispatch(corporateAction.internalPackcheckOut(data, paidWithPoints, payAmount));
            localStorage.setItem("BookingData",JSON.stringify(data))}else{
                dispatch(corporateAction.internalPackcheckOut(data, paidWithPoints,payAmount));
            }
            }
        
        }
    }
    const onBackButtonEvent = (e, bool) => {
     e.preventDefault()
     alert("hi")
            history.push("/holidays")
      
            window.scrollTo(0,0)
    }
    
    
      useEffect(() => {
        if(isJustLoggedIn==="true"&&includingGST){
            handleCheckout()
          setTimeout(()=>localStorage.setItem("isJustLoggedIn","false"),100)  
        }
    }
      , [isJustLoggedIn,includingGST]);
        
  
    useEffect(() => {
        window.scrollTo(0, 0);

        let bookingData=localStorage.getItem("BookingData")
       if(!checkoutPage&&!bookingData){
        history.push("/")
       }
      
    }, [checkoutPage]);
    const payAmount = (data) => {
        history.push(`/payment`, {...data,pack_title:packData?.pack_title});
    }
    useEffect(() => {
        if (packID && checkoutPage) {
            dispatch(corporateAction.getInternapPackDetails(packID,setHandleError))
            localStorage.removeItem("BookingData")
        }
    }, [packID, checkoutPage])

    return (
        <>
            <UserProfile  fromCheckout={true} />
            {packData||checkoutDetails?(
            <div className='container instant-checkout-wrapper'>
                <div className=" profile-tabs">
                    <Tabs className="justify-content-center " defaultActiveKey={'profile'} id="uncontrolled-tab-example">
                        <Tab eventKey="profile" title="Package Booking">
                        </Tab>
                    </Tabs>
                </div>
                <div className='row'>
                    <div className='col-12 col-md-6 col-lg-6'>
                        <div className='package-title'>
                            <p>{checkoutPage ? packData?.pack_title:checkoutDetails?.pack_title }</p>
                            <div className='line'></div>
                        </div>
                        <div className='user-details d-flex flex-column'>
                            <div className='line'></div>
                            <div className='sub-details d-flex flex-column'>
                                <span>{checkoutPage ? packData?.contact_name : checkoutDetails?.contact_name}</span>
                                <small>Name</small>
                            </div>
                            <div className='sub-details d-flex flex-column'>
                                <span>{checkoutPage ? packData?.contact_email : checkoutDetails?.contact_email}</span>
                                <small>Email</small>
                            </div>
                            <div className='sub-details d-flex flex-column'>
                                <span>{checkoutPage ? packData?.contact_number : checkoutDetails?.contact_number}</span>
                                <small>Mobile</small>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-6 col-lg-5'>
                        <div className='purchase-details'>
                            {checkoutPage ?
                                <div className="price-holder" >
                                    <h4>Price</h4>
                                    <div className="price-line"></div>
                                    <div className="total-cost">
                                        <div className="total">
                                            <p>Package amount</p>
                                        </div>
                                        <div className="amount">
                                            <p>Rs {(total_price)?.toLocaleString('en-IN')}</p>
                                        </div>
                                    </div>

                                    {points!==undefined?
                                  (  (points==0 && !isWipro) ? null :
                                        <div className="rewards-holder">
                                            <div className="my-rewards">
                                                <div >
                                                    <p className="rewards">My Reward Points</p>
                                                </div>
                                                <div>
                                                    <p className="pts">{`-${isRewardPointsBtnBool ? usedRewardPoints : 0}pts`}</p>
                                                </div>
                                            </div>
                                            <div className={classes.root}>
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item>
                                                    </Grid>
                                                    <Grid item xs>
                                                        <Slider
                                                            value={(typeof value === 'number' && isRewardPointsBtnBool) ? value : points > 0 ? 100 : 0}
                                                            onChange={handleSliderChange}
                                                            aria-labelledby="input-slider" disabled
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <p className="points-left">{isRewardPointsBtnBool ? pointsLeft : points} pts left</p>
                                                <div className="use-rewards">
                                                    <div>
                                                        <p className="reward-points">Use reward points</p>
                                                    </div>
                                                    <div>
                                                        <label className="switch">
                                                            <input type="checkbox" disabled={points==0} onChange={balanceUpdateHandler}
                                                                id="rewardPointsCheck"
                                                            />
                                                            <span className="slider round"></span>
                                                        </label>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                  ):null
                                }
                                    <div className="balance-holder d-flex justify-content-between">
                                        <div>
                                            <p>Balance to be paid </p>
                                            {
                                                (isRewardPointsBtnBool && balance === 0) ? '' : <p>GST 5%</p>
                                            }

                                        </div>
                                        <div className="text-right">
                                            <p>Rs {parseFloat(!isRewardPointsBtnBool ? (total_price)?.toFixed(2) : balance?.toFixed(2))?.toLocaleString('en-IN')}</p>
                                            {
                                                (isRewardPointsBtnBool && balance === 0) ? '' : <p> <span>+</span> Rs {parseFloat(!isRewardPointsBtnBool ? (initialAmount)?.toFixed(2) : afterCheckedGST?.toFixed(2))?.toLocaleString('en-IN')}</p>
                                            }

                                        </div>
                                    </div>
                                    {
                                        (isRewardPointsBtnBool && balance === 0) ? null :
                                            <div className="balance-holder payment-mode-checkbox" >
                                                <p>Select payment mode</p>
                                                <div className="radio" >
                                                    <label for="DC" className={radiovalue === 'DC' ? 'border-blue' : ''}>
                                                        <div className="check-btns">
                                                            <input type="radio" id="DC" name="payment_mode" value='DC'
                                                                onChange={paymentModeChange}
                                                                defaultChecked
                                                            />
                                                            <span class="checkmark"></span>
                                                            <div className="checkBtn-info">
                                                                <h5>Use Debit Card / UPI to pay</h5>
                                                                <p>Select this payment option</p>
                                                            </div>
                                                        </div>
                                                    </label>
                                                    <label for="CC" className={radiovalue === 'CC' ? 'border-blue' : ''}>
                                                        <div className="check-btns">
                                                            <input type="radio" id="CC" name="payment_mode" value='CC'
                                                                onChange={paymentModeChange}

                                                            />
                                                            <span class="checkmark"></span>
                                                            <div className="checkBtn-info">
                                                                <h5>Use Credit Card / Net Banking to pay</h5>
                                                                <p>Select this payment option</p>
                                                            </div>
                                                        </div>
                                                    </label>
                                                </div>

                                            </div>
                                    }

                                    <button type="button" className="btn proceed-to-book w-100" href onClick={handleCheckout}>
                                        {
                                            (isRewardPointsBtnBool && balance === 0) ? 'Pay with points' : 'Checkout'
                                        } </button>
                                </div>
                                : <div className="price-holder">
                                    <h4 className="checkout-title">{
                                        radiovalue == "DC" ? 'You are using Debit Card / UPI' : 'You are using Credit Card / Net Banking'
                                    } </h4>
                                    <div className="price-line w-100"></div>
                                    <form action={PAYMENT_URL_TEST} method='post'>
                                        <input type="hidden" name="firstname" value={checkoutDetails?.firstname} />
                                        <input type="hidden" name="lastname" value="" />
                                        <input type="hidden" name="surl" value={checkoutDetails?.surl} />
                                        <input type="hidden" name="phone" value={checkoutDetails?.phone} />
                                        <input type="hidden" name="key" value={checkoutDetails?.key} />
                                        <input type="hidden" name="hash" value={checkoutDetails?.hash} />
                                        <input type="hidden" name="curl" value={checkoutDetails?.curl} />
                                        <input type="hidden" name="furl" value={checkoutDetails?.furl} />
                                        <input type="hidden" name="txnid" value={checkoutDetails?.txnid} />
                                        <input type="hidden" name="productinfo" value={checkoutDetails?.productinfo} />
                                        <input type="hidden" name="amount" value={checkoutDetails?.amount} />
                                        <input type="hidden" name="email" value={checkoutDetails?.email} />
                                        <button type="submit" className="btn proceed-to-book w-100 btn-fixed-mobi" href onClick={clearData}>Pay Rs {(checkoutDetails?.amount)?.toLocaleString('en-IN')}</button>
                                    </form>

                                </div>
                            }
                        </div>
                    </div>
                </div>
                <StyledDialog
          open={showLoginModal}
          PaperProps={{
            style: {
              width: "430px",
              backgroundColor: "#fff",
            },
          }}
        >
          <div className="close-login" onClick={handleClose}>
            <img src={CloseIcon} />
          </div>
          <div className="events-auth-tabs">
            <Tabs
              defaultActiveKey={clickType == "login" ? "login" : "signup"}
              onSelect={(type) => setClickType(type)}
              id="uncontrolled-tab-example"
            >
              <Tab eventKey="login" title="Login">
                <div className="events-login-holder">
                  <LoginPopup
                    closeModalEvent={showLoginModal}
                    toggleModalEvent={setShowLoginModal}
                    clickType={clickType}
                    checkoutParams={packID}
                  />
                </div>
              </Tab>
              <Tab eventKey="signup" title="Signup">
                <div className="events-login-holder">
                  <SignupPopup
                    closeModalEvent={showLoginModal}
                    toggleModalEvent={setShowLoginModal}
                    clickType={clickType}
                    checkoutParams={packID}
                  />
                </div>
              </Tab>
            </Tabs>
          </div>
        </StyledDialog>
            </div>):<div className='d-flex w-100  justify-content-center align-items-center' style={{height:"300px"}}>{handleError?"Invalid Link":""}</div>
}
            <Footer />
        </>
    )
}

export default InstantCheckout