import React, { useEffect } from 'react'
import OwlCarousel from "react-owl-carousel";
import { useDispatch, useSelector } from "react-redux";
import { corporate_options } from '../../resources/carouselOptions';
import { useHistory } from "react-router-dom";
import * as CorporateAction from "../../redux/actions/corporatesAuthActions";


function YouMayAlsoLike({ giftInfo, giftVoucher }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const SIMILAR_GIFTS = useSelector((state) => state.corporates.similar_gifts.data);
    const id = giftInfo
    const userInfo = JSON.parse(localStorage.getItem("userData"));
    const companyDomain = userInfo?.organisation?.email_domain;
    const isWipro = (companyDomain === "wipro.com");

    useEffect(() => {
        dispatch(CorporateAction.getSimilarPackagesOrGifts(id));
        setTimeout(() => {
            refreshSimilarPackages();
        },500)
    }, []);

    const refreshSimilarPackages = () => {
        dispatch(CorporateAction.getSimilarPackagesOrGifts(id));
    }
    const handleGiftDetails = (giftId, items) => {
        // localStorage.setItem('giftPackageInfo', JSON.stringify(items))
        history.push(`/corporates/gift-voucher/${giftId}?isSimilar=true`, items);
        window.scrollTo(0, 0);
      };
    return (
        <div className={giftVoucher &&  SIMILAR_GIFTS && SIMILAR_GIFTS.length > 0 ? "similar-gifts-holder" : ""}>
        {
            SIMILAR_GIFTS && SIMILAR_GIFTS.length > 0 ? <div className="container">
            <div className="nearby-experiences similar-offers">
                <h5>You may also like</h5>
                <div className="divider"></div>
                <OwlCarousel className="owl-theme" {...corporate_options}>
                    {
                        SIMILAR_GIFTS && SIMILAR_GIFTS.length > 0 && SIMILAR_GIFTS.map((items) => (
                            <div className="item">
                                <div className="card-wrapper d-lg-flex" style={{cursor: 'pointer'}} onClick={() => handleGiftDetails(items.pack_slug, items)}>
                                    <img src={items.image} className="w-100" alt="" />
                                    <div className="info">
                                       <p>{items.pack_title}</p>
                                       <div className="desc">
                                            <p>{items.brief_description}</p>
                                       </div>
                                        {isWipro? <p className="points">{items.min_points} - {items.max_points} pts</p> : 
                                        <p className="points">Rs {items.min_points} - {items.max_points}</p>}
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </OwlCarousel>
            </div>
        </div> : null
        }
            
        </div>
    )
}

export default YouMayAlsoLike
