import { API } from "../../config/api";
import {
    axiosPost, axiosGet
} from "../../services/apiServices"
import {
    PLANNING_START_DATE,
    PLANNING_END_DATE,
    STORIES_LIST,
    HONEYMOON_DESTINATION_LIST,
    HONEYMOON_STORIES_LIST,
    MY_FAMILY_CATEGORY_ID,
    ANNIVERSARY_STORIES_LIST

} from './types'
export const ERROR_ALERT = "ERROR_ALERT";

export const giftRequestEnquiry = (body, handleSuccess, handleErrors) => {
    console.log("giftRequestEnquiry", body)
    console.log(body, 'request api fire')
    return async () => {
        axiosPost(API.GIFTREQUEST_ENQUIRY, body)
            .then((res) => {
                if (res.data.status_code === 200) {
                    handleSuccess();
                }
                if (res.data.status_code === 400) {
                    handleErrors(res.data.data.message)
                }
            })
            .catch((err) => {
                handleErrors(err.response.data?.data?.message);
            });
    };
};
export const honeyMoonRequestEnquiry = (state, handleSuccess, handleErrors) => {
    const body = { ...state, request_type: "honeymoon" }
    return async () => {
        axiosPost(API.HONEYMOON_ENQUIRY, body)
            .then((res) => {
                if (res.data.status_code === 200) {
                    handleSuccess();
                }
                if (res.data.status_code === 400) {
                    handleErrors(res.data.data.message)
                }
            })
            .catch((err) => {
                handleErrors(err.response.data?.data?.message);
            });
    };
};
export const anniversaryRequestEnquiry = (state, handleSuccess, handleErrors) => {
    const body = { ...state, request_type: "anniversary" }
    return async () => {
        axiosPost(API.ANNIVERSARY_ENQUIRY, body)
            .then((res) => {
                if (res.data.status_code === 200) {
                    handleSuccess();
                }
                if (res.data.status_code === 400) {
                    handleErrors(res.data.data.message)
                }
            })
            .catch((err) => {
                handleErrors(err.response.data?.data?.message);
            });
    };
};
export const getStoriesList = () => {
    return async (dispatch) => {
        axiosGet(API.STORIES_LIST_API)
            .then((res) => {
                if (res.data.status_code === 200) {
                    dispatch({
                        type: STORIES_LIST,
                        payload: res.data.data
                    })
                }
            })
            .catch((err) => {
                dispatch({
                    type: ERROR_ALERT,
                    payload:
                        (err && err.response && err.response.data) ||
                        "Something went wrong.",
                });
            });
    };
};
export const getHoneymoonDestinationsList = (type) => {
    const body = {
        "filters": [{ "field": "active", "value": "true" }, { "field": "request_type", "value": type }],
        "sort": { "field": "priority", "order": "A" },
        "page": 1,
        "list_per_page": 15

    }
    return async (dispatch) => {
        axiosPost(API.GET_HONEYMOON_DESTINATION_LIST, body)
            .then((res) => {
              
                if (res.data.status_code === 200) {
                    dispatch({
                        type: HONEYMOON_DESTINATION_LIST,
                        payload: res.data.data.results
                    })
                }

            })
            .catch((err) => {
                dispatch({
                    type: ERROR_ALERT,
                    payload:
                        (err && err.response && err.response.data) ||
                        "Something went wrong.",
                });
            });
    };
};
export const myFamilyFirstCategoryId = (id) => {
    return async (dispatch) => {
        if (id) {
            dispatch({
                type: MY_FAMILY_CATEGORY_ID,
                payload: id
            })
        }
    }
}
export const getHoneymoonStoriesList = (type) => {
    const body = {
        "filters": [{ "field": "request_type", "value": type }, { "field": "active", "value": "true" }],
        "page": 1,
        "list_per_page": 15
    }
    return async (dispatch) => {
        axiosPost(API.GET_HONEYMOON_STORIES, body)
            .then((res) => {
             
                if (res.data.status_code === 200) {
                    if (type == "honeymoon") {
                        dispatch({
                            type: HONEYMOON_STORIES_LIST,
                            payload: res.data.data.results
                        })
                    } else {
                        dispatch({
                            type: ANNIVERSARY_STORIES_LIST,
                            payload: res.data.data.results
                        })
                    }
                }

            })
            .catch((err) => {
                dispatch({
                    type: ERROR_ALERT,
                    payload:
                        (err && err.response && err.response.data) ||
                        "Something went wrong.",
                });
            });
    };
};


// export const handlePlanningStartAndEndDate = (startDate, endDate) => {
//     return async (dispatch) => {
//       dispatch({
//         type: PLANNING_START_DATE,
//         payload: startDate
//       })
//       dispatch({
//         type: PLANNING_END_DATE,
//         payload: endDate
//       })
//     }
//   }