import React, { useEffect, useRef, useState } from "react";
import Header from '../common/header';
import { useDispatch, useSelector } from "react-redux";
import backIcon from "../../assets/images/common/back-icon.svg";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import Dialog from '@material-ui/core/Dialog';
import styled from "styled-components";
import "../../assets/css/partials/events/events.scss";
import Loader from '../common/loader';
import EventSlides from "../events/eventSlides";
import * as EventsAction from '../../redux/actions/eventsAction';
import $ from 'jquery';
import Footer from '../common/footer';
import PrepareFormModal from './PrepareGiftFormModal';
import GuestsModal from "../common/guestsModal";
import '../../assets/css/partials/corporates/corpGuestModal.scss';
import closeIcon from '../../assets/images/common/close-modal.svg';
import CalenderModal from "../corporates/calenderModal";
import moment from "moment";
import ContactDetailsCard from '../common/contactDetailsCard';
import WhatsappLogo from '../../assets/images/corporates/whatsapp-logo.svg'
import SimilarOffers from '../events/similarOffers';
import Stories from "./stories";
import SimilarStories from "./similarStories";
import useClickOutside from '../../hooks/useClickOutside';
import * as giftRequestAction from "../../redux/actions/giftRequestAction";
import { ScrollToTop } from "../common";
import Breadcrum from "../common/breadcrum";
import HolidayHeader from "../common/holidayHeader";

function Stories_details(storyDetailsPage) {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation()
    const [loader, setLoader] = useState(false);
    const loginUserData = useSelector((state) => state.auth.login_user_otp);
    const STORY_ID = storyDetailsPage.match.params.id
    const [myfamily, setMyfamily] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modal, setModal] = useState(false);
    const [adultGuestCount, setAdultGuestCount] = useState(0);
    const [childrensGuestCount, setChildrensGuestCount] = useState(0);
    const [count, setCount] = useState(0);
    const [showForm, setShowForm] = useState(false)
    const [showContactModal, setShowContactModal] = useState(false)
    const [showCalenderModal, setShowCalenderModal] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [scroll, setScroll] = useState(false);
    const [id, setId] = useState(location?.state?.categoryId)

    const STORIES_LIST = useSelector((state) => state.giftRequest.stories_list);
    const honeymoonStoriesList = useSelector((state) => state.giftRequest.honeymoon_stories_list);
    const anniversaryStoriesList = useSelector((state) => state.giftRequest.anniversary_stories_list);
    useEffect(() => {
        if (location.state) {
            setId(location.state?.categoryId)
        }
    }, [location.state])
    useEffect(() => {
        dispatch(giftRequestAction.getStoriesList())
        dispatch(giftRequestAction.getHoneymoonStoriesList(id == 1 ? "honeymoon" : "anniversary"))
    }, [])

    const STORY_DATA = id == 3 ? STORIES_LIST.filter(item => {
        if (item?.id == STORY_ID) {
            return item
        }
    }) : id == 1 ? honeymoonStoriesList.filter(item => {
        if (item?.id == STORY_ID) {
            return item
        }
    }) : anniversaryStoriesList.filter(item => {
        if (item?.id == STORY_ID) {
            return item
        }
    })
    console.log(STORY_DATA, "kl")
    const handleContactModalClose = () => {
        setShowContactModal(false)
    }

    const handleNumberOfGuests = () => {
        setShowModal(true);
        // document.getElementById("guestModal").classList.remove("hide");
        // document.getElementById("guestModal").classList.add("show");
    };
    const [state, setState] = useState({
        location: "",
        occasion: "",
        guest: "",
        // tempGuest:"",
        message: "",
        schedule: ""
    });

    const [error, setError] = useState({
        location: false,
        occasion: false,
        guest: false,
        schedule: false,
        infantValidate: false
    })

    const handleChange = (key, value) => {
        setState(() => ({
            ...state,
            [key]: value.target.value,
        }));
        setError({
            ...error,
            [key]: false
        })
    };
    const contactModalRef = useRef();
    useClickOutside(contactModalRef, () => {
        setShowContactModal(false)
    });

    const popupRef = useRef();
    useClickOutside(popupRef, () => {
        if (showForm && !modal) {
            closeFormHandler()
        }
    });

    const guestDatahandler = (adultData, childrenData, countData) => {
        let guestRes = [];
        setShowModal(false);
        setAdultGuestCount(adultData);
        setChildrensGuestCount(childrenData);
        setCount(countData);
        if (adultData > 0) {
            guestRes.push(`${adultData} adult${adultData > 1 ? 's' : ''}`);
        }
        if (childrenData > 0) {
            guestRes.push(`${childrenData} kid${childrenData > 1 ? 's' : ''}`);
        }
        if (countData > 0) {
            guestRes.push(`${countData} infant${countData > 1 ? 's' : ''}`);
        }
        setState(() => ({
            ...state,
            'guest': guestRes.join(',')
        }))
        if (adultData || childrenData || countData) {
            setError({
                ...error,
                guest: false,
                infantValidate: adultData == 0 ? error.infantValidate : false
            })
        }
    };
    const selectDateHandler = () => {
        setShowCalenderModal(true);
        setMyfamily(true)
    };

    const handleDatesData = (sDate, eDate, bool) => {
        setShowCalenderModal(false);
        if (bool) {
            setStartDate(null);
            setEndDate(null);
            setState(() => ({
                location: "",
                occasion: "",
                message: "",
                guest: "",
                schedule: ""
            }))
        } else {
            setStartDate(sDate);
            setEndDate(eDate);
            setState(() => ({
                ...state,
                'schedule': {
                    "to": `${eDate}`,
                    "from": `${sDate}`
                }
            }))
        }
        if (sDate || eDate) {
            setError({
                ...error,
                schedule: false
            })
        }
    };

    const handleEnquiry = (e) => {
        e.preventDefault();
        if (!state.location || state.location === "") {
            focusInput('#location');
            setError({ location: true })
        } else if (!state.occasion || state.occasion === "") {
            focusInput('#occasion');
            setError({ occasion: true })
        } else if (!state.schedule || state.schedule === "" || startDate === false) {
            focusInput('#schedule');
            setError({ schedule: true })
        } else if (!state.guest || state.guest === "" || state.guest === `${0} adults, ${0} kids, ${0} infants`) {
            focusInput('#guest');
            setError({ guest: true })
        } else if ((count >= 1 || childrensGuestCount >= 1) && adultGuestCount === 0) {
            focusInput('#guest');
            setError({ infantValidate: true })
        }
        else {
            setModal(true);
        }
    };

    const inputFieldsClear = () => {
        setState({
            ...state,
            location: "",
            occasion: "",
            guest: "",
            message: "",
            schedule: ""
        })


    }
    const handleForm = () => {
        setState({
            ...state,
            location: "",
            occasion: "",
            message: "",
        })
        setCount(0)
        setChildrensGuestCount(0)
        setAdultGuestCount(0)
        setStartDate(null)
        setEndDate(null)
        handleDatesData(null, null, true)
        setShowForm(true)
    }
    const handleContactModal = () => {
        setShowContactModal(true)
    }

    const closeFormHandler = () => {
        setState({
            ...state,
            location: "",
            occasion: "",
            message: "",
        })
        setCount(0)
        setChildrensGuestCount(0)
        setAdultGuestCount(0)
        setStartDate(null)
        setEndDate(null)
        handleDatesData(null, null, true)
        setShowForm(false)
    }

    const getGuestCount = (adult, kids, infant) => {
        let tmpGuestsArray = [];
        if (adult > 0) {
            tmpGuestsArray.push(`${adult} adult${adult > 1 ? 's' : ''}`);
        }
        if (kids > 0) {
            tmpGuestsArray.push(`${kids} kid${kids > 1 ? 's' : ''}`);
        }
        if (infant > 0) {
            tmpGuestsArray.push(`${infant} infant${infant > 1 ? 's' : ''}`);
        }
        return tmpGuestsArray.join(', ');
    };

    const focusInput = (inputDom) => {
        var elementPosition = document.querySelector(`${inputDom}`).getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - 145;
        window.scrollTo({ top: offsetPosition, behavior: "smooth" });
        setTimeout(() => {
            document.querySelector(inputDom).focus();
        });
    };

    const goBackFun = () => {
        let urlHistory = JSON.parse(localStorage.getItem('urlHistory'));
        if (!localStorage.getItem('prevUrl')) {
            history.push('/my-family-first');

        } else if ((urlHistory[0].pathname === urlHistory[urlHistory.length - 1].pathname) && urlHistory.length > 2) {
            history.push('/my-family-first');
        } else {
            history.goBack();
        }
    };
    const handleLoader = () => {
        setLoader(false);
    };

    useEffect(() => {
        window.addEventListener("scroll", listenToScroll);
        return () =>
            window.removeEventListener("scroll", listenToScroll);
    }, [])

    const listenToScroll = () => {
        let footer = document
        .querySelector(
            ".footer-holder"

        )
        ?.getBoundingClientRect().top+600;
    window.addEventListener("scroll", () => {
        setScroll(window.scrollY > footer);


    })
    };


    return (
        <div className="stories-details-page">
            <div className={Object.keys(loginUserData).length !== 0 &&
                loginUserData.constructor === Object ? 'innerPage-header hide-header-mobi' : 'innerPage-header hide-header-mobi pre-login-header'}>
                <div className=" d-lg-none ">
                    <Header />
                </div>
                <div className="d-none d-lg-block">
                    <HolidayHeader />
                </div>
            </div>
            {
                !loader ?
                    (<div className=" innerPage-container innerPage-container-mobi">


                        <div className="package-holder" key={STORY_DATA[0]?.id}>
                            <div className="package-details">
                                {(
                                    <div className="images--holder">
                                        <EventSlides eventInfo={STORY_DATA[0]} fromStories={true} />
                                    </div>
                                )}
                                <div
                                    onClick={goBackFun}
                                    className="back-button-mobi back-button d-md-none"
                                >
                                    <img src={backIcon} alt="backIcon" />
                                </div> 
                                {/* <div className="line d-none d-md-block"></div> */}
                                <div className="container">


                                    <div className="row">
                                        <div className="col-12 col-lg-8">

                                            <div className="itinerary-package-title pb-4">
                                                <div
                                                    onClick={goBackFun}
                                                    className="back-button d-none d-md-inline-flex"
                                                >
                                                    <img src={backIcon} alt="backIcon" />
                                                </div>
                                                <p className="d-none d-md-block">
                                                    <span>
                                                        {" "}
                                                        {STORY_DATA[0]?.title}
                                                    </span>
                                                </p>
                                            </div>
                                            {
                                                // mobile view
                                            }
                                            <div className="package-title d-block d-md-none">
                                                <h1>{STORY_DATA[0]?.title}</h1>


                                            </div>


                                            <div className="package-description">
                                                <p dangerouslySetInnerHTML={{
                                                    __html: STORY_DATA[0]?.description
                                                }}>
                                                </p>


                                            </div>

                                        </div>

                                        <div className="col-12 col-lg-4">
                                            <div className="myfamily-holder" style={{ position: 'relative' }}>
                                                <div className="">
                                                    <div className="row justify-content-center">
                                                        <div className=" col-12 ">
                                                            <div className={showForm ? "prepareGift-mobile" : "prepareGift-desk mb20"} >
                                                                <div className={showForm ? "prepareGift-holder create-gift-holder" : "prepareGift-holder"} style={{ margin: "0px auto" }} ref={popupRef}>
                                                                    <span onClick={closeFormHandler}><img alt='close-icon' src={closeIcon} className={showForm ? "d-block close-icon" : "close-icon"} /></span>
                                                                    <h5 style={{ fontSize: "28px" }}>Create a {id == 1 ? "Honeymoon Package" : id == 2 ? "Anniversary Package" : "Family Gift"}</h5>
                                                                    <h3>Fill details below to proceed</h3>
                                                                    <form>
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <div className="input-holder">
                                                                                    <div className="custom-input card--bg ">
                                                                                        <input
                                                                                            value={state.location}
                                                                                            type="text"
                                                                                            id="location"
                                                                                            className="form-control"
                                                                                            autocomplete="off"
                                                                                            placeholder="Bangalore, Goa, Pudu"
                                                                                            onChange={(value) => handleChange("location", value)}
                                                                                            onKeyPress={(event) => {
                                                                                                if (!/^[A-Za-z ]+$/.test(event.key)) {
                                                                                                    event.preventDefault();
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                        <div className="form-line"></div>
                                                                                        <label>Locations preferred</label>
                                                                                        {error.location === true ? <span className="errorMsg text-center">Preferred locations required</span> : null}
                                                                                    </div>
                                                                                </div>

                                                                                <div className="input-holder">
                                                                                    <p></p>
                                                                                    <div className="custom-input card--bg mb20">
                                                                                        <input
                                                                                            value={state.occasion}
                                                                                            type="text"
                                                                                            id="occasion"
                                                                                            autocomplete="off"
                                                                                            className="form-control"
                                                                                            placeholder={`Anniversary, ${id == 3 ? "Birthday" : "Honeymoon"}`}
                                                                                            onChange={(value) => handleChange("occasion", value)}
                                                                                        />
                                                                                        <div className="form-line"></div>
                                                                                        <label>Occasions</label>
                                                                                        {error.occasion === true ? <span className="errorMsg text-center">Occasion required</span> : null}
                                                                                    </div>
                                                                                    <div className="custom-input card--bg mb20 position-relative">
                                                                                        <input

                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            readOnly
                                                                                            id="schedule"
                                                                                            placeholder="Pick Your Dates"
                                                                                            onChange={(value) => handleChange("schedule", value)}
                                                                                            onClick={selectDateHandler}
                                                                                            value={
                                                                                                startDate &&
                                                                                                    endDate !== null ?
                                                                                                    `${moment(startDate).format('ddd-DD MMM')} to ${moment(endDate).format('ddd-DD MMM')}` : ""
                                                                                            }
                                                                                        />
                                                                                        <div className="form-line"></div>
                                                                                        <label>When are you planning</label>
                                                                                        {error.schedule === true ? <span className="errorMsg text-center">Please select dates required</span> : null}
                                                                                        {showCalenderModal ? (
                                                                                            <CalenderModal
                                                                                                showMyModal={showCalenderModal}
                                                                                                handleDatesData={handleDatesData}
                                                                                                myfamily={myfamily}
                                                                                                prevStartDate={startDate}
                                                                                                prevEndDate={endDate}
                                                                                            />
                                                                                        ) : null}
                                                                                    </div>
                                                                                    <div className="custom-input card--bg mb20 position-relative">
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            id="guest"
                                                                                            readOnly
                                                                                            placeholder="Guest here"
                                                                                            onChange={(value) => handleChange("guest", value)}
                                                                                            onClick={handleNumberOfGuests}
                                                                                            value={
                                                                                                adultGuestCount !== 0 ||
                                                                                                    childrensGuestCount !== 0 ||
                                                                                                    count !== 0
                                                                                                    ? getGuestCount(adultGuestCount, childrensGuestCount, count)
                                                                                                    : ""
                                                                                            }
                                                                                        />
                                                                                        <div className="form-line"></div>
                                                                                        <label>No. of guests</label>
                                                                                        {error.guest === true ? <span className="errorMsg text-center">No of guests required</span> : null}
                                                                                        {error.infantValidate === true ? <span className="errorMsg text-center">For Infants/children at least 1 adult is required</span> : null}
                                                                                        {showModal ? <GuestsModal
                                                                                            showMyModal={showModal}
                                                                                            guestDatahandler={guestDatahandler}
                                                                                            adultCount={adultGuestCount}
                                                                                            childrensCount={childrensGuestCount}
                                                                                            infantCount={count}
                                                                                        /> : null}
                                                                                    </div>

                                                                                </div>

                                                                                <div className="input-holder mt-lg-4">
                                                                                    <div className="custom-input card--bg2 mb20" style={{ border: '0px solid #E0E0E0' }}>
                                                                                        <h2>Any specific request</h2>
                                                                                        <div className="inner-card">
                                                                                            <input
                                                                                                value={state.message}
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                placeholder="Enter here"
                                                                                                onChange={(value) => handleChange("message", value)}
                                                                                            />
                                                                                            <div className="form-line"></div>
                                                                                            <label>Example:</label>
                                                                                            <label>Add Cadbury celeberations chocolate box</label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {/* <div className="line"></div> */}
                                                                                <div className="text-center">
                                                                                    <button type="button" className="btn otp-btn wedding-btn prepare-btn" onClick={handleEnquiry}>
                                                                                        {id == 3 ? "Request for a Gift" : "Request for a Package"}
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                            {
                                                                modal && <PrepareFormModal
                                                                    closeFormHandler={closeFormHandler}
                                                                    handleClose={() => setModal(false)}
                                                                    id={id}
                                                                    showModal={modal}
                                                                    giftData={state}
                                                                    inputFieldsClear={inputFieldsClear} />
                                                            }
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>


                                            {/* <div className={showContactModal ? "contact-modal" : "d-none d-md-block sticky-contact "} >
                                        <div className="login-holder contact-holder" ref={contactModalRef}>

                                            <h5>Contact Us</h5>
                                            <span onClick={handleContactModalClose}> {showContactModal? <img src={closeIcon} className="close-icon" /> : null}</span>
                                            <div className="line"></div>
                                            <div className="contact-card mb-3 d-flex align-items-center justify-content-between">
                                                <div>
                                                    <p>
                                                        <a href="tel:+919902064657">+91 9902064657</a>
                                                    </p>
                                                    <p>Our number</p>
                                                </div>
                                                <div>
                                                    <a href="https://wa.me/+919902064657" rel="noreferrer" target="_blank">
                                                        <img src={WhatsappLogo} alt="" />
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="contact-card">
                                                <p>
                                                    <a
                                                        href="mailto:ramananda@hammockholidays.com"
                                                        rel="noreferrer"
                                                        target="_blank"
                                                    >
                                                        ramananda@hammockholidays.com{" "}
                                                    </a>
                                                </p>
                                                <p>Our email</p>
                                            </div>
                                        </div>
                                    </div> */}
                                            {
                                                showContactModal ? <div className="contact-modal">
                                                    <ContactDetailsCard openModal={setShowContactModal} contactModalRef={contactModalRef} />
                                                </div> : null
                                            }
                                            <div className="d-none d-md-block sticky-contact" >
                                                <ContactDetailsCard stickyBtnText={id == 3 ? 'Request for a Gift' : 'Request for a Package'} />
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>


                        </div>

                    </div>) : <Loader />}
            <div className={scroll ? "d-none" : "d-block"}>
                <div className="mobile-buttons">
                    <div className="stcky-leftButton">
                        <div className="white-button" onClick={handleContactModal}>
                            <a >Contact</a>
                        </div>
                    </div>
                    <div className="stcky-rightButton">
                        <div className="custom-button" onClick={handleForm}>
                            <a >{id == 3 ? "Create a Gift" : "Book Now"}</a>
                        </div>
                    </div>
                </div>
            </div>
            <ScrollToTop />
            <SimilarStories STORY_ID={STORY_ID} STORIES_LIST={id == 3 ? STORIES_LIST : id == 1 ? honeymoonStoriesList : anniversaryStoriesList} closeFormHandler={closeFormHandler} />
            <Footer />
        </div>
    )
}

export default Stories_details
