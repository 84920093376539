import React, { useState, useEffect } from 'react';
import vector1 from "../../assets/images/common/Vector1.svg";
import vector2 from "../../assets/images/common/Vector2.svg";
import closeIcon from "../../assets/images/common/close-icon.svg";
import VixImg from "../../assets/images/home/vix/Converted/vix.webp";
import Logo from "../../assets/images/common/logo.svg";
import banner from "../../assets/images/home/Converted/virtual-experience.webp";
import Virtual1 from "../../assets/images/home/vix/Converted/Virtual1.webp";
import VirtualMobi1 from "../../assets/images/home/vix/Converted/Virtual1-mobi.webp";
import Virtual2 from "../../assets/images/home/vix/Converted/Virtual2.webp";
import VirtualMobi2 from "../../assets/images/home/vix/Converted/Virtual2-mobi.webp";
import Virtual3 from "../../assets/images/home/vix/Converted/Virtual3.webp";
import VirtualMobi3 from "../../assets/images/home/vix/Converted/Virtual3-mobi.webp";
import Virtual4 from "../../assets/images/home/vix/Converted/Virtual4.webp";
import VirtualMobi4 from "../../assets/images/home/vix/Converted/Virtual4-mobi.webp";
import Virtual5 from "../../assets/images/home/vix/Converted/Virtual5.webp";
import VirtualMobi5 from "../../assets/images/home/vix/Converted/Virtual5-mobi.webp"

import associate1 from "../../assets/images/home/vix/associate1.svg";
import associate2 from "../../assets/images/home/vix/associate2.svg";
import logo from "../../assets/images/home/vix/Logo.svg";

import client1 from "../../assets/images/home/vix/client1.svg";
import client2 from "../../assets/images/home/vix/client2.svg"
import client3 from "../../assets/images/home/vix/client3.svg";
import client4 from "../../assets/images/home/vix/client4.svg"

import VixContactDetails from './vixContactDetails';
import Footer from "../common/footer";
import { useDispatch } from "react-redux";
import * as HomeAction from '../../redux/actions/homeAction';
import { Helmet } from 'react-helmet';

import {
    BrowserRouter as Router,
    Link, useHistory
} from "react-router-dom";

function VirtualExperience() {
    const history = useHistory();
    const dispatch = useDispatch()
    const [showContactModal, setShowContactModal] = useState(false)
    const [isVisible, setIsVisible] = useState(true);
    const [height, setHeight] = useState(0)

    const handleContactModal = () => {
        setShowContactModal(true)
    }

    useEffect(() => {
        window.scrollTo(0,0);
        window.addEventListener("scroll", listenToScroll);
        return () =>
            window.removeEventListener("scroll", listenToScroll);
    }, [])

    const listenToScroll = () => {
        let heightToHideFrom = document.body.scrollHeight - 850;
        const winScroll = document.body.scrollTop ||
            document.documentElement.scrollTop;
        setHeight(winScroll);

        if (winScroll > heightToHideFrom) {
            setIsVisible(false);
        } else {
            setIsVisible(true);
        }
    };

    const goBack = () => {
        history.goBack()
        dispatch(HomeAction.setScrollVix(true));
    }

    return (
        <div>
            <Helmet>
                <title>Virtual Interactive Experiences - Hammock Holidays</title>
                <meta name="description" content="Experiential learning for students" />
            </Helmet>
            <div className="banner-holder userProfile-holder">
                <div className="header-holder">
                    <Link to="/">
                        <div className={(window.location.pathname === '/user/profile' || window.location.pathname === '/user/bookings') ? 'd-none d-md-block' : 'logo virtual-mobile-logo'}>
                            <img src={Logo} alt="Logo" />
                        </div>
                    </Link>
                    <div className="cta-buttons d-block mobi-profile-goBack">
                        <a style={{ cursor: "pointer" }}
                            className="mr-4"
                            onClick={goBack}   >
                            <img src={closeIcon} alt="closeIcon" />
                        </a>
                    </div>
                </div>
                {/* Banner images */}
                <div className="banner">
                    <img src={banner} className="img-fluid" alt="banner" />
                </div>

                {/* Banner text */}
                <div>
                    <div className="corporate-logo">
                        <img src={VixImg} alt="VixImg" style={{ borderRadius: '0px' }} />
                    </div>
                    <div className="text-holder"><h1><span className="vix-char">v</span>irtual <span className="vix-char">i</span>nteractive <span className="vix-char">x</span>periences</h1>
                        <p>Experiential learning for students</p></div>
                </div>

                <div className="vector-holder vector1">
                    <img src={vector1} className="img-fluid" alt="vector1" />
                </div>
                <div className="vector-holder vector2">
                    <img src={vector2} className="img-fluid" alt="vector2" />
                </div>
            </div>

            <div className="container">

                <div className="cerp-corp-wrapper vix-wrapper">
                    <div className="cerp-corp">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-6 vertical-center order-12 order-md-1 vix-desc1">
                                <div className="info">
                                    <p><span className="vix-content">Facilitating Learning</span> in young minds</p>
                                    <p className="vix-desc ">We use interdisciplinary and applied learning approach, through virtual interactive programs that are fun and engaging.</p>
                                    <div className="learn-more-holder d-none d-md-block" onClick={handleContactModal}>
                                        <button className="btn btn-learn-more w-100">Contact us</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-5 corp-mobi-p0 order-1 order-md-12">
                                <div className="d-none d-md-block">
                                    <img src={Virtual1} className="w-100" alt="Virtual1" />
                                </div>
                                <div className="d-block d-md-none">
                                    <img src={VirtualMobi1} className="w-100" alt="VirtualMobi1" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="vix-associations-holder">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-3">
                            <div className="associates">
                                <h5>Activities presented in association with</h5>
                                <div className="line"></div>
                                <div className="single-logo d-md-none">
                                    <div className="mb-lg-4 mr-2 mr-lg-0 ">
                                        <img src={logo} className="w-100" alt="logo" />
                                    </div>
                                </div>
                                <div className="logos">
                                    <div className="mb-lg-4 mr-2 mr-lg-0 d-none d-md-block">
                                        <img src={logo} className="w-100" alt="logo" />
                                    </div>
                                    <div className="mb-lg-4 mr-2 mr-lg-0">
                                        <img src={associate1} className="w-100" alt="associate1" />
                                    </div>
                                    <div>
                                        <img src={associate2} className="w-100" alt="associate2" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-8 ml-lg-3 d-flex d-lg-block mt-5 mt-lg-0 overflow-auto">
                            <div className="grades-holder">
                                <div className="grades">
                                    <p>6-12 grades</p>
                                </div>
                                <div className="activities">
                                    <div className="activity-list">
                                        <p><span>Haka </span></p>
                                        <span className="outcome-span">will help </span> <br />
                                        <p className="outcome-text">Shed inhibitions</p>
                                    </div>
                                    <div className="activity-list">
                                        <p><span>Virtual Acapella
                                        </span>
                                        </p>
                                        <span className="outcome-span">will help </span> <br />
                                        <p className="outcome-text">Foster collaboration</p>
                                    </div>
                                    <div className="activity-list">
                                        <p><span>Picture in Pixel
                                        </span></p>
                                        <span className="outcome-span">will help </span> <br />
                                        <p className="outcome-text">Create memories</p>

                                    </div>
                                    <div >
                                        <p><span>Brain Freeze
                                        </span></p>
                                        <span className="outcome-span">will help </span> <br />
                                            <p className="outcome-text">Boost’s morale & bonding</p>

                                    </div>
                                </div>
                            </div>
                            <div className="d-lg-flex">
                                <div className="grades-holder grades-holder-custom mr-lg-3">
                                    <div className="grades">
                                        <p>8-12 grades</p>
                                    </div>
                                    <div className="activities">
                                        <div className="activity-list">
                                        <p><span>Home Olympics
                                            </span></p>
                                            <span className="outcome-span">will help </span> <br />
                                            <p className="outcome-text">Motivate creativity</p>
                                            
                                                
                                        </div>
                                        <div>
                                        <p><span>Survival Games
                                            </span></p>
                                            <span className="outcome-span">will help </span> <br />
                                            <p className="outcome-text">Negotiation & teamwork</p>
                                        </div>

                                    </div>
                                </div>
                                <div className="grades-holder ml-lg-3">
                                    <div className="grades">
                                        <p>10-12 grades</p>
                                    </div>
                                    <div className="activities">
                                        <div>
                                            <p><span>Virtual Acapella
                                            </span> </p>
                                            <span className="outcome-span">will help </span>
                                            <br />
                                            <p className="outcome-text">Foster collaboration</p>
                                                
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="line"></div>

                <div className="cerp-corp-wrapper vix-wrapper">
                    <div className="cerp-corp">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-6 vertical-center order-12 vix-desc2">
                                <div className="info">
                                    <p>Customized Virtual <span className="vix-content">Experiential Learning,</span> delivered by  <span className="vix-content">Experienced Trainers</span></p>
                                    <p className="vix-desc ">Programs are curated for student of various curricullums. Which are structured based on topics and are highly customizable</p>
                                    <div className="learn-more-holder d-none d-md-block" onClick={handleContactModal} >
                                        <button className="btn btn-learn-more w-100">Contact us</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-5 corp-mobi-p0 order-1">
                                <div className="d-none d-md-block">
                                    <img src={Virtual2} className="w-100" alt="Virtual2" />
                                </div>
                                <div className="d-block d-md-none">
                                    <img src={VirtualMobi2} className="w-100" alt="VirtualMobi2" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="cerp-corp-wrapper vix-wrapper">
                    <div className="cerp-corp">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-6 vertical-center order-12 order-md-1 vix-desc1">
                                <div className="info">
                                    <p>Created with the Objective to <span className="vix-content">Build Camaraderie</span></p>
                                    <p className="vix-desc">Activities are delivered in phases:</p>
                                    <p className="vix-desc">1. Starting with engagement</p>
                                    <p className="vix-desc">2. Progress to study groups</p>
                                    <p className="vix-desc">3. Ends with meaningful, personalized virtual interactions</p>

                                    <div className="learn-more-holder d-none d-md-block" onClick={handleContactModal}>
                                        <button className="btn btn-learn-more w-100">Contact us</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-5 corp-mobi-p0 order-1 order-md-12">
                                <div className="d-none d-md-block">
                                    <img src={Virtual3} className="w-100" alt="Virtual3" />
                                </div>
                                <div className="d-block d-md-none">
                                    <img src={VirtualMobi3} className="w-100" alt="VirtualMobi3" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="our-clients vix-clients">
                    <div className="logo-wrapper">
                        <div className="d-flex justify-content-xl-center">

                            <div className="card-wrapper">
                                <div className="count">
                                    <p>Over <br /> 2.3<span>k+</span></p>
                                </div>
                                <div className="info d-flex">
                                    <p className="mb-0"><span>VIX programs </span>
                                        conducted both Virtual & In-person </p>
                                </div>
                            </div>

                            <div className="card-wrapper">
                                <div className="img-holder">
                                    <img src={client1} alt="Indian School of Business" />
                                </div>
                                <div className="info d-flex">
                                    <p className="mb-0"> <span>Orientation program</span> for Indian School of Business</p>
                                </div>
                            </div>

                            <div className="card-wrapper">
                                <div className="img-holder">
                                    <img src={client2} alt="Nigerian University of Technology and Management" />
                                </div>
                                <div className="info d-flex">
                                    <p className="mb-0"><span>Orientation program</span> for Nigerian University of Technology and Management</p>
                                </div>
                            </div>

                            <div className="card-wrapper">
                                <div className="img-holder">
                                    <img src={client3} alt="Amazon India" />
                                </div>
                                <div className="info d-flex">
                                    <p className="mb-0">Organized the student <span>Intern program</span> at Amazon India</p>
                                </div>
                            </div>

                            <div className="card-wrapper">
                                <div className="img-holder">
                                    <img src={client4} alt="ThoughtWorks" />
                                </div>
                                <div className="info d-flex">
                                    <p className="mb-0">Supported the <span>STEP program</span> of ThoughtWorks</p>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

                <div className="cerp-corp-wrapper vix-wrapper">
                    <div className="cerp-corp">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-6 vertical-center order-12 vix-desc2">
                                <div className="info">
                                    <p>Every Experience is an Exercise in <span className="vix-content">Enhanced Learning</span></p>
                                    <p className="vix-desc ">Our range of experiential programs enable students to tap into the fundamental nature of understading, inspire innovation and pursue unlimited possibilities</p>
                                    <div className="learn-more-holder d-none d-md-block" onClick={handleContactModal}>
                                        <button className="btn btn-learn-more w-100">Contact us</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-5 corp-mobi-p0 order-1">
                                <div className="d-none d-md-block">
                                    <img src={Virtual4} className="w-100" alt="Virtual4" />
                                </div>
                                <div className="d-block d-md-none">
                                    <img src={VirtualMobi4} className="w-100" alt="Virtual4" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="cerp-corp-wrapper vix-wrapper">
                    <div className="cerp-corp">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-6 vertical-center order-12 order-md-1 vix-desc1">
                                <div className="info">
                                    <p>Endless <span className="vix-content">Opportunities</span> to Investigate <span className="vix-content">Real-World Problems</span></p>
                                    <p className="vix-desc">With emphasis on an applied approach, the teachings are designed to equip students in becoming critical thinkers and problem solvers.</p>

                                    <div className="learn-more-holder d-none d-md-block" onClick={handleContactModal}>
                                        <button className="btn btn-learn-more w-100">Contact us</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-5 corp-mobi-p0 order-1 order-md-12">
                                <div className="d-none d-md-block">
                                    <img src={Virtual5} className="w-100" alt="Virtual5" />
                                </div>
                                <div className="d-block d-md-none">
                                    <img src={VirtualMobi5} className="w-100" alt="VirtualMobi5" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="line"></div>
                <VixContactDetails />
                {
                    showContactModal ? <div className="contact-modal">
                        <div className="container">
                            <VixContactDetails openModal={setShowContactModal} />
                        </div>
                    </div> : null
                }
            </div>

            {
                isVisible && <div className="mobile-buttons text-center">
                    <div className="stcky-rightButton w-auto">
                        <div className="custom-button" onClick={handleContactModal}>
                            <a>Connect to know more</a>
                        </div>
                    </div>
                </div>
            }


            <Footer />
        </div>
    )
}

export default VirtualExperience
