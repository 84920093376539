import React, { useState, useEffect, useLayoutEffect } from 'react';
import * as ExperienceAction from '../../redux/actions/experiencesAction';
import * as HolidaysAction from '../../redux/actions/holidaysActions';
import { useHistory, Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import moment from 'moment';
import LinkIcon from '../../assets/images/common/feather_external-link.svg';
import SanitizedStayTag from '../common/sanitizedStayTag';
import LazyLoadingLoader from '../common/lazyLodaingLoader';
import { Helmet } from 'react-helmet';
import { scheduleDuration, specificScheduleDuration } from '../utilities/utillFunc';
import SearchLocationAndPriceRange from "./../holidays/searchLocationAndPriceRange";
import CategoryHolder from './experirnceCategoryHolder';





const owl_packages = {
    items: 1,
    margin: 5,
    nav: false,
    loop: true,
    dots: true,
    autoplay: true,
    smartSpeed: 500,
    animateIn: 'fadeIn',
    animateOut: 'fadeOut',
};

function Index(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const EXPERIENCE_SECTIONS = useSelector((state) => state?.experiences?.experience_categories?.data);
    const CATEGORY_ID = useSelector((state) => state?.experiences?.cat_id);
    const EXPERIENCE_PACKAGES = useSelector((state) => state?.experiences?.experience_packages?.data?.listing?.results);
    const EXPERIENCE_DATA = useSelector((state) => state?.experiences?.experience_packages?.data);
    const EXPERIENCE_PACKAGES_LIST = useSelector((state) => state?.experiences?.experience_packages_list);
    const SEARCH_VALUE = useSelector((state) => state.holidays?.searchValue);
    const minVal = useSelector((state) => state.holidays?.filterValues?.minVal);
    const maxVal = useSelector((state) => state.holidays?.filterValues?.maxVal);
    const userDetails = JSON.parse(localStorage.getItem("userData"));
    const ROUTE_VALUE = useSelector((state) => state?.experiences?.route_message);
    const isCorporateAccount = userDetails?.user_details?.corporate_account;
    const companyDomain = userDetails?.organisation?.email_domain;
    const isWipro = (companyDomain === "wipro.com")
    const NEXT_PAGE = useSelector((state) => state?.experiences?.experience_packages?.data?.listing?.next_page_id);
    const CURRENT_PAGE = useSelector((state) => state?.experiences?.experience_packages?.data?.listing?.current_page);
    const [loadMoreBtn, setLoadMoreBtn] = useState(false);
    const [loader, setLoader] = useState(false);
    const [changeCategory, setChangeCategory] = useState(CATEGORY_ID);
    const routeFromHome = JSON.parse(localStorage.getItem("routeFromHome"))
    const [isFirstRender, setIsFirstRender] = useState(history.location?.data?.bool)
    const [isBot, setIsBot] = useState(false);
    const location = useLocation()
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const page = params.get('page');
    const [category, setCategory] = useState('all-experiences')
    const activesectionId = location?.pathname.split("/")[location?.pathname.split("/").length - 1]
    const scrollactiveExpCategory = localStorage.getItem("scrollactiveExpCategory")
    const sectionSlug = location?.pathname.split("/").length > 2 ? activesectionId : null
    const fromHolidays=localStorage.getItem('prevUrl')?.indexOf('holidays') > -1&&!localStorage.getItem('prevUrl').includes('details')
    
   
    useEffect(() => {
        let bots = ['googlebot', 'bingbot', 'yandex', 'baiduspider', 'facebookexternalhit', 'twitterbot', 'rogerbot', 'linkedinbot', 'embedly', 'quora link preview', 'showyoubot', 'outbrain', 'pinterest/0.', 'pinterestbot', 'slackbot', 'vkShare', 'W3C_Validator', 'whatsapp', 'redditbot', 'applebot', 'flipboard', 'tumblr', 'bitlybot', 'skypeuripreview', 'nuzzel', 'discordbot', 'google page speed', 'qwantify', 'bitrix link preview', 'xing-contenttabreceiver', 'chrome-lighthouse', 'telegrambot', 'prerender'];
        let checkbot = bots.filter((bot) => {
            return navigator.userAgent.toLowerCase().indexOf(bot) > -1
        });
        setIsBot(checkbot.length > 0)
        if (localStorage.getItem('prevUrl')?.indexOf('holidays') > -1&&!localStorage.getItem('prevUrl').includes('details')) {
            handleSeeAll("all-experiences",true)
            dispatch(ExperienceAction.setRouteValue(""));
            dispatch(HolidaysAction.setFilterValues(""));
            dispatch(HolidaysAction.setSearchValue(""));
            setIsFirstRender(false)
        }


    }, []);

    const handleCategories = (name, id, isFromClick) => {
        dispatch(ExperienceAction.setCategoryId(id));
        setChangeCategory(name);
        if (routeFromHome || isFromClick) {
            dispatch(ExperienceAction.getExperiencePackages(id, 1));
        }
        dispatch(ExperienceAction.setRouteValue(""));
        dispatch(HolidaysAction.setFilterValues(""))
        dispatch(HolidaysAction.setSearchValue(""))
        setIsFirstRender(false)
    }

    useEffect(() => {
        let seltSec = EXPERIENCE_SECTIONS?.filter(sl => sl.section_slug === props?.match?.params?.sectionid);
        if (seltSec && seltSec.length && CATEGORY_ID != parseInt(seltSec[0]?.id)) {
            handleCategories(seltSec[0]?.name, seltSec[0]?.id, true)
        } else if (!props?.match?.params?.sectionid && CATEGORY_ID != "all-experiences") {
            // handleSeeAll("all-experiences")
        }
    }, [props?.match?.params?.sectionid]);
    useLayoutEffect(() => {
        if (sectionSlug && EXPERIENCE_SECTIONS) {
            if (sectionSlug == "holidays") {
                document.querySelector(`#Holidays`)?.classList.add("active-category-card");
            } else {
                document.querySelector(`#Holidays`)?.classList.remove("active-category-card");
            }
            EXPERIENCE_SECTIONS?.map((item) => {
                if (item?.section_slug == sectionSlug) {
                    document.querySelector(`#${item.section_slug}`)?.classList.add("active-category-card");
                 
                } else {
                    document.querySelector(`#${item.section_slug}`)?.classList.remove("active-category-card");
                    document.querySelector(`.see-all-events`)?.classList.remove("active-see-all")
                    document.querySelector(`.all-events-p`)?.classList.add('d-none')

                }
            })

        }
    }, [sectionSlug, EXPERIENCE_SECTIONS])
    useLayoutEffect(() => {
        if (EXPERIENCE_SECTIONS) {
            if (!category.fromTab&&sectionSlug===null) {
                handleSeeAll("all-experiences",category==="fromTab")
                document.querySelector(`.see-all-events`)?.classList.add("active-see-all")
                document.querySelector(`.see-all-events-p`)?.classList.add('d-none')
                document.querySelector(`.all-events-p`)?.classList.remove('d-none')
                document.querySelector(`.active-category-card`)?.classList.remove("active-category-card")
                
            }

             if (category&&category.fromTab===true && category?.id !== CATEGORY_ID) {
                document.querySelector(`.see-all-events`)?.classList.remove("active-see-all")
                handleCategories(category.name, category.id, true)
                document.querySelector(`.see-all-events-p`)?.classList.remove('d-none')
                document.querySelector(`.all-events-p`)?.classList.add('d-none')
                history.push(`/experiences/${category.slug}`)
            }

        }

    }, [category, sectionSlug, EXPERIENCE_SECTIONS])
    useLayoutEffect(() => {
        if (EXPERIENCE_SECTIONS || EXPERIENCE_SECTIONS && scrollactiveExpCategory) {
            if (EXPERIENCE_SECTIONS) {
                EXPERIENCE_SECTIONS?.map((item, idx) => {
                    if (sectionSlug == item?.section_slug) {
                        let owl = window.$('.owl-carousel')
                        owl.owlCarousel();
                        owl.trigger('to.owl.carousel', idx);
                        setTimeout(() => localStorage.removeItem("scrollactiveExpCategory"), 5000)
                    }
                })

            }
        }
    }, [EXPERIENCE_SECTIONS, scrollactiveExpCategory])

    useEffect(() => {
        if (ROUTE_VALUE === "Experiential Stays") {
            EXPERIENCE_SECTIONS && EXPERIENCE_SECTIONS.forEach((item) => {
                if (ROUTE_VALUE === item?.name) {
                    handleCategories(item?.name, parseInt(item?.id))
                    dispatch(ExperienceAction.getExperiencePackages(item?.id, 1));
                }
            }
            )
        }
        else {
            // dispatch(ExperienceAction.getExperienceSections());
            // dispatch(ExperienceAction.setCategoryId("all-experiences"));
        }
        return (() => {
            localStorage.setItem("routeFromHome", false)
        })
       
    }, [EXPERIENCE_SECTIONS]);

    const scrollToActiveSec = () => {
        let scrollContent = document.body.querySelector(`#filter-wrapper`);
        let activeLink = document.body.querySelector(`.active-category`);
        if (scrollContent && activeLink) {

            scrollContent.scrollLeft = activeLink.offsetLeft - 140
        }
    }

    useEffect(() => {
        dispatch(ExperienceAction.getExperienceSections());
        if (CATEGORY_ID == null && !props?.match?.params?.sectionid) {
            dispatch(ExperienceAction.setCategoryId("all-experiences"));
            // dispatch(ExperienceAction.getExperiencePackages(CATEGORY_ID,1));
        }
    }, []);
    useEffect(() => {
        if (history?.location?.seeAll?.bool) {
            dispatch(ExperienceAction.setCategoryId("all-experiences"));
            dispatch(ExperienceAction.getExperiencePackages("all-experiences", page || 1));
        }
    }, [])


    useEffect(() => {
        // if(CURRENT_PAGE == undefined && EXPERIENCE_SECTIONS?.length){
        //     dispatch(ExperienceAction.getExperiencePackages(CATEGORY_ID,page || 1));
        // }
        if ((CURRENT_PAGE == undefined) && CATEGORY_ID !== null && CATEGORY_ID !== undefined && !isFirstRender&&!fromHolidays) {
            dispatch(ExperienceAction.getExperiencePackages(CATEGORY_ID, page || 1));
        }
        if (!routeFromHome && isFirstRender && SEARCH_VALUE == "" && CATEGORY_ID !== null && CATEGORY_ID !== undefined&&!fromHolidays) {
            dispatch(ExperienceAction.getExperiencePackages(CATEGORY_ID, page || 1));
        }
    }, [CATEGORY_ID])

    const handleExperienceDetails = (id) => {
        dispatch(ExperienceAction.setRouteValue(""));
        localStorage.removeItem("filteredList")
        //commented to add seo links
        // history.push(`/experiences/experience-details/${id}`);
        // window.scrollTo(0, 0);
    }
    const handleExperienceFilteredDetails = () => {
        localStorage.setItem("filteredList", true)
    }

    // see all experiences
    const handleSeeAll = (message, fromTab) => {
        dispatch(ExperienceAction.setCategoryId(message));
        if (fromTab) {
            dispatch(ExperienceAction.getExperiencePackages("all-experiences", 1));
        }
        setChangeCategory(message);
        dispatch(HolidaysAction.setFilterValues(""))
        dispatch(HolidaysAction.setSearchValue(""))
    }

    const openMap = (e, lat, lng) => {
        window.open(`http://www.google.com/maps/place/${lat},${lng}`)
        e.stopPropagation()
    }

    const sanitisedTag = (str) => {
        return str;
    }
    const requiredUrl = () => {
        return EXPERIENCE_SECTIONS?.filter(sl => sl.id === CATEGORY_ID)[0]?.section_slug
    }
    const requiredTitle = () => {
        return EXPERIENCE_SECTIONS?.filter(sl => sl.id === CATEGORY_ID)[0]?.name
    }
    const requiredMetaDesc = () => {
        return EXPERIENCE_SECTIONS?.filter(sl => sl.id === CATEGORY_ID)[0]?.description
    }
    const getSpecificDatesMonth = (packageInfo) => {
        const months = [];
        packageInfo?.schedule_data?.multipleDates?.length > 0 &&
            packageInfo?.schedule_data?.multipleDates.map(date => {
                months.push(moment(date).format("MMM"));
            })
        const uniqueMonths = [... new Set(months)]
        const specificDatesMonths = uniqueMonths.map((item, index) => {
            return <span> {`${item}${uniqueMonths.length == index + 1 ? '' : ","} `}</span>
        })
        return specificDatesMonths

    }
    const calculateDays = (packageInfo) => {
        const arr = [];
        packageInfo?.schedule_data?.selectedDates?.length > 0
            && packageInfo?.schedule_data?.selectedDates?.map(date => {
                const startDate = moment(`${date.to}`, 'DD-MM-YYYY');
                const endDate = moment(`${date.from}`, 'DD-MM-YYYY');
                const result = endDate.diff(startDate, 'days');
                arr.push(Math.round(Math.abs(result) + 1));
            });

        const min = Math.min(...arr);
        const max = Math.max(...arr);

        if (min !== max) {
            return <span>Min {min} {min == 1 ? 'day' : 'days'}, Max {max} {max == 1 ? 'day' : 'days'}</span>
        } else {
            return <span> Max {max} {min == 1 ? 'day' : 'days'}</span>
        }

    }
    const loadMorePackages = () => {
        setLoadMoreBtn(false)
        setLoader(true)
        // dispatch(ExperienceAction.getExperiencePackages(CATEGORY_ID, NEXT_PAGE));
        if ((SEARCH_VALUE == undefined || SEARCH_VALUE == "") && (maxVal == undefined || maxVal == "")) {
            dispatch(ExperienceAction.getExperiencePackages(CATEGORY_ID, NEXT_PAGE));
            // console.log("list without price and location filter")
        } else if ((SEARCH_VALUE == undefined || SEARCH_VALUE == "") && (maxVal !== undefined || maxVal !== "")) {
            dispatch(ExperienceAction.searchByLocation(CATEGORY_ID, SEARCH_VALUE, minVal, maxVal, NEXT_PAGE));
            // console.log("filter with points only")
        } else if ((SEARCH_VALUE !== undefined || SEARCH_VALUE !== "") && (maxVal == undefined || maxVal == "")) {
            dispatch(ExperienceAction.searchByLocation(CATEGORY_ID, SEARCH_VALUE, minVal, maxVal, NEXT_PAGE));
            // console.log("filter with location only")
        } else {
            dispatch(ExperienceAction.searchByLocation(CATEGORY_ID, SEARCH_VALUE, minVal, maxVal, NEXT_PAGE));
            // console.log("filter with location and points")
        }

    }

    useEffect(() => {
        if (NEXT_PAGE !== null) {
            setLoadMoreBtn(true)
        } else {
            setLoadMoreBtn(false)
            setLoader(false)
        }
    }, [NEXT_PAGE])
    useEffect(() => {
        if (EXPERIENCE_SECTIONS?.length && props?.match?.params?.sectionid) {
            let secId = EXPERIENCE_SECTIONS.filter(sl => sl.section_slug === props?.match?.params?.sectionid)[0]?.id;
            dispatch(ExperienceAction.setCategoryId(secId));
        } else if (EXPERIENCE_SECTIONS) {
            dispatch(ExperienceAction.setCategoryId("all-experiences"));
            // dispatch(ExperienceAction.getExperiencePackages(CATEGORY_ID, page || 1))
        }
        setTimeout(() => scrollToActiveSec())
    }, [EXPERIENCE_SECTIONS]);

 

    return (
        <div>
            <Helmet>
                <title>{`${requiredTitle() ? requiredTitle() : "Experiences"}`} - Hammock Holidays</title>
                <meta name="description" content={`${requiredMetaDesc() ? requiredMetaDesc() : "Explore Things to do"}`} />
            </Helmet>
            {EXPERIENCE_SECTIONS&&category&&
                <div className={`d-none ${EXPERIENCE_SECTIONS?.length>4?'d-lg-block':''}`}>
                    <CategoryHolder setCategory={setCategory} categoryList={EXPERIENCE_SECTIONS} fromExp={true} />
                </div>
            }
            <div className={`filter-holder corporate-category-filter holiday-holder ${EXPERIENCE_SECTIONS?.length>4?'d-lg-none':''}`}>
                <div id='filter-wrapper' className={EXPERIENCE_SECTIONS && EXPERIENCE_SECTIONS.length <= 3 ? 'd-flex justify-content-center mobi-category-overflow' : 'filter-wrapper mobi-category-overflow'}>
                    <Link to={`/experiences`} style={{ textDecoration: "none" }}>
                        <div className={CATEGORY_ID === "all-experiences" ? 'see-all-events active-see-all' : 'see-all-events'} onClick={() => handleSeeAll("all-experiences", true)}>
                            {
                                CATEGORY_ID === "all-experiences" ? <p>All <br /> Experiences</p> : <p>See all <br />Experiences</p>
                            }
                        </div>
                    </Link>

                    {

                        EXPERIENCE_SECTIONS && EXPERIENCE_SECTIONS.length > 0 &&
                        EXPERIENCE_SECTIONS.map((item, index) => (
                            <Link to={`/experiences/${item.section_slug}`} key={index} >
                                <div className={CATEGORY_ID == item?.id ? "filter-card active-category" : " filter-card"}
                                // onClick={() => handleCategories(item?.name, item?.id, true)}  
                                >
                                    <div className="img-gradient">
                                        <div className="cat-img">
                                            <img src={item?.image} alt={item?.name} />
                                            <div class="pseudo"></div>
                                        </div>
                                        <div className="card-info">
                                            <p >{item?.name}</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        ))

                    }

                </div>
            </div>
            <div className="container">
                <SearchLocationAndPriceRange
                    changeCategory={changeCategory}
                    categoryID={CATEGORY_ID}
                />
                {/* <div class="line"></div> */}
                <div className="">
                    {
                        EXPERIENCE_PACKAGES_LIST?.length > 0 ?
                            <div className="corporatePackages-holder">
                                {EXPERIENCE_PACKAGES_LIST && EXPERIENCE_PACKAGES_LIST.length > 0 &&
                                    EXPERIENCE_PACKAGES_LIST.map((packageInfo, index) => {
                                        return (
                                            <Link to=
                                                {{ pathname: `/experiences/experience-details/${packageInfo?.experience_slug}`, state: { id: packageInfo.id } }}
                                            >
                                                <div style={{ cursor: "pointer" }}
                                                    className="package" key={packageInfo?.id}
                                                    onClick={() => handleExperienceDetails(packageInfo?.experience_slug)}  >
                                                    <div className="row overflow-hidden w-100-vw">
                                                        <div className="col-12 col-lg-5 pr-lg-0">
                                                            {
                                                                packageInfo?.image?.length === 1 ?
                                                                    <div className="package-image">
                                                                        <img
                                                                            src={packageInfo?.image}
                                                                            className="w-100"
                                                                            alt={EXPERIENCE_DATA?.filter_data ? packageInfo?.pack_title : packageInfo?.title}
                                                                        />
                                                                    </div> :
                                                                    <OwlCarousel
                                                                        className="owl-theme owl-corporate"
                                                                        {...owl_packages} >
                                                                        {packageInfo.image.map((images,idx) => {
                                                                            if (images) {
                                                                                return (
                                                                                    <div className="item">
                                                                                        <div className="package-image">
                                                                                            <img src={images} className="w-100" alt={`${EXPERIENCE_DATA?.filter_data ? packageInfo?.pack_title : packageInfo?.title} ${idx+1}`} />
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        })}
                                                                    </OwlCarousel>
                                                            }
                                                        </div>
                                                        <div className="col-12 col-lg-7 pl-lg-0">
                                                            <div className="package-details">
                                                                <div className="d-flex" style={{ flexWrap: 'wrap' }}>
                                                                    {packageInfo &&
                                                                        packageInfo.covid_safe === true ?
                                                                        <><SanitizedStayTag experienceStay={sanitisedTag} /></> : null
                                                                    }
                                                                    <div className="bestseller-tag">
                                                                        {
                                                                            packageInfo?.best_seller ? <span>BEST SELLER</span> : ''
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <h4 className="">{EXPERIENCE_DATA?.filter_data ? packageInfo?.pack_title : packageInfo?.title}

                                                                </h4>
                                                                <div className="info">
                                                                    <p className="d-flex">
                                                                        {packageInfo?.location?.length > 1 ? `${(packageInfo?.location_city || packageInfo?.location?.[0]?.city)}, ${packageInfo?.location?.[1]?.address}` :
                                                                            `${(packageInfo?.location_city || packageInfo?.location?.[0]?.city)}${(packageInfo?.location?.[0]?.state ? "," : "")} ${(packageInfo?.location?.[0]?.state)}`
                                                                        }
                                                                        <span className="open-maps d-flex align-items-center" onClick={(e) => openMap(e, packageInfo?.latitude, packageInfo?.longitude)}>See in map <img src={LinkIcon} /> </span> </p>
                                                                    <div className="line"></div>
                                                                    {
                                                                        packageInfo?.schedule_type === "package" ?
                                                                            <p>
                                                                                {packageInfo?.schedule_data?.days} {packageInfo?.schedule_data?.days == 1 ? 'Day' : 'Days'}  {packageInfo?.schedule_data?.nights} {
                                                                                    packageInfo?.schedule_data?.nights == 1 ? 'Night' : 'Nights'
                                                                                }
                                                                            </p> : ""
                                                                    }
                                                                    {
                                                                        packageInfo?.schedule_type === "recurring" ?
                                                                            <p>Every
                                                                                {packageInfo?.schedule_data?.newSelectedDyas?.length > 0 ?
                                                                                    packageInfo?.schedule_data?.newSelectedDyas.map((index) => {
                                                                                        switch (index) {
                                                                                            case 0:
                                                                                                return <span> Sun</span>
                                                                                            case 1:
                                                                                                return <span>, Mon</span>
                                                                                            case 2:
                                                                                                return <span>, Tue</span>
                                                                                            case 3:
                                                                                                return <span>, Wed</span>
                                                                                            case 4:
                                                                                                return <span>, Thu</span>
                                                                                            case 5:
                                                                                                return <span>, Fri</span>
                                                                                            case 6:
                                                                                                return <span>, Sat</span>
                                                                                        }
                                                                                    }) : ""
                                                                                }
                                                                                {packageInfo?.schedule_data?.recurringHours || packageInfo?.schedule_data?.recurringMinutes ? ` -` : ``}
                                                                                {packageInfo?.schedule_data?.recurringHours !== "0" && packageInfo?.schedule_data?.recurringHours !== undefined ?
                                                                                    packageInfo?.schedule_data?.recurringHours > 1 ? ` ${packageInfo?.schedule_data?.recurringHours} hrs ${packageInfo?.schedule_data?.recurringMinutes ? '' : '/day'}` : ` ${packageInfo?.schedule_data?.recurringHours} hr ${packageInfo?.schedule_data?.recurringMinutes ? '' : '/day'}` : ""}
                                                                                {packageInfo?.schedule_data?.recurringMinutes !== (0 || undefined) ?
                                                                                    packageInfo?.schedule_data?.recurringMinutes > 1 ? `  ${packageInfo?.schedule_data?.recurringMinutes} mins/day` : `  ${packageInfo?.schedule_data?.recurringMinutes} min/day` : ""}
                                                                            </p> : ""
                                                                    }
                                                                    {
                                                                        packageInfo?.schedule_type === "specific_dates" ?
                                                                            <p>In the month of
                                                                                {getSpecificDatesMonth(packageInfo)} {specificScheduleDuration("", "", packageInfo)}
                                                                            </p> : ""
                                                                    }
                                                                    {
                                                                        packageInfo?.schedule_type === "date_range" ?
                                                                            <p>
                                                                                {calculateDays(packageInfo)}
                                                                            </p> : ""
                                                                    }
                                                                    {
                                                                        packageInfo?.flexi_schedule ?
                                                                            <p>
                                                                                Flexible Days
                                                                            </p> : ""
                                                                    }
                                                                    <p style={{ color: "#797979" }}>{packageInfo.brief_description}</p>
                                                                </div>
                                                                <div className="points">
                                                                    {
                                                                        packageInfo?.sec_name === "International" ? null : (isCorporateAccount && isWipro) ? <p>Starting {(packageInfo.revised_price ? packageInfo.revised_price : packageInfo.price)?.toLocaleString('en-IN')} pts</p> : <p>Starting Rs. {(packageInfo.revised_price ? packageInfo.revised_price : packageInfo.price)?.toLocaleString('en-IN')}</p>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        );
                                    })}
                                {loadMoreBtn || isBot ?
                                    <div className="text-center">
                                        {isBot ? <div className="d-flex justify-content-between">
                                            {CURRENT_PAGE > 1 && <a href={`/experiences/${requiredUrl() ? requiredUrl() : ''}?page=${CURRENT_PAGE - 1}`}>
                                                <button type="submit" className="load-more-btn" ><span>Prev page</span></button></a>}
                                            {NEXT_PAGE && <a href={`/experiences/${requiredUrl() ? requiredUrl() : ''}?page=${NEXT_PAGE}`}>
                                                <button type="submit" className="load-more-btn" ><span>Next page</span></button></a>}
                                        </div> : <button type="submit" className="load-more-btn" onClick={() => loadMorePackages()}><span>Load more...</span></button>}
                                    </div> : loader ? <LazyLoadingLoader />
                                        : null}
                            </div> : <p className="text-center">Currently no packages available for selected category</p>
                    }
                </div>
            </div>

        </div>
    )
}

export default Index
