
import '../../assets/css/partials/common/lazyLoadingLoader.scss'


 const LazyLoadingLoader = () => {
  //other logic
  
    return (
        <div className="lazy-load-spinner">
            <div class="spinner">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
        </div>
    );
  
}

export default LazyLoadingLoader

