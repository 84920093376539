import React from "react";
import { useHistory } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/common/logo.svg";
import Instagram from "../../assets/icons/instagram.webp";
import Twitter from "../../assets/icons/twitter.png";
import Facebook from "../../assets/icons/facebook.webp";
import Phone from "../../assets/icons/phone.webp";
import Mail from "../../assets/icons/mail.webp";
import Location from "../../assets/icons/location.webp";

import "../../assets/css/partials/header-footer.scss";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

function Footer() {
  const history = useHistory();
 const EXP_IN_YEARS= localStorage.getItem("EXP_IN_YEARS")
  const goToTermsPage = () => {
    history.push(`/terms-and-conditions`);
    window.scrollTo(0, 0);
  };
  const isCorporateAccount = useSelector((state) => state.auth.login_user_otp.corporate_account);
  const goToRefundPage = () => {
    history.push(`/refund-and-cancellations`);
    window.scrollTo(0, 0);
  };
  const goToPrivacyPage = () => {
    history.push(`/privacy-policy`);
    window.scrollTo(0, 0);
  };
  const goToCareesPage = () => {
    history.push(`/careers`);
    window.scrollTo(0, 0);
  };
  const goToSections = (sec) => {
    history.push(sec);
    window.scrollTo(0, 0);
  };
  return (
    <div className="footer-holder">
      <Container>
        <Row className="row-level-1">
          <Col xs={12} lg={6} className={`${isCorporateAccount?"mb-1":"mb-5"} mb-lg-0`}>
            <Row>
              <Col>
                <img src={Logo} alt="logo"/>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  Hammock group companies are headquartered in Bengaluru,
                  Karnataka, India. Over the past {EXP_IN_YEARS} years we have diversified
                  into Travel & Hospitality, Destination.
                </p>
              </Col>
            </Row>
            <div className="d-flex footer-sm">
              <a href="https://www.facebook.com/HammockHolidays/" target="_blank" aria-label="facebook">
                {" "}
                <img className="footer-logo-facebook" src={Facebook} alt="facebook-icon" />
              </a>
              <a href="https://www.instagram.com/hammockholidays/" target="_blank" aria-label="instagram">
                {" "}
                <img className="footer-logo" src={Instagram} alt="instagram-icon" />
              </a>
            </div>
          </Col>
          <Col>
            <Row>
              <Col xs={0} lg={1}></Col>
              <Col lg={4} xs={12} className="mb-5 mb-lg-0">
                {!isCorporateAccount&& <>
                <h4 className="footer-sec-head">Services</h4>
                <p
                  onClick={() => goToSections("/holidays/bharat-dekho")}
                  className="footer-sec-p"
                >
                  Holidays
                </p>
                <p
                  onClick={() => goToSections("/corporates")}
                  className="footer-sec-p"
                >
                  Corporates
                </p>
                <p
                  onClick={() => goToSections("/experiences")}
                  className="footer-sec-p"
                >
                  Experiences
                </p>
                <p
                  onClick={() => goToSections("/my-family-first")}
                  className="footer-sec-p"
                >
                  My Family First
                </p>
                <p
                  onClick={() => goToSections("/weddings")}
                  className="footer-sec-p"
                >
                  Weddings
                </p>
                </>
}
              </Col>
              <Col lg={7} xs={12} className="mb-5 mb-lg-0">
                <div className="d-flex careers-footer">
              <h4 className="footer-sec-head " onClick={() => goToSections("/careers")}>Careers</h4>
              <h4 className="footer-sec-head " onClick={() => goToSections("/about-us")}>About Us</h4>
              </div>
                <h4 className="footer-sec-head mb-3">Contact</h4>
                <a href="tel:+91 9902064657">
                  <p className="footer-sec-p">
                    <img src={Phone} alt="" className="footer-logo-mob mr-1" />{" "}
                    +91 9902064657
                  </p>
                </a>
                <a href="mailto:ramananda@hammockholidays.com">
                  <p className="footer-sec-p d-flex flex-wrap align-items-center">
                    <img src={Mail} alt="" className="footer-logo-contact mr-2" />
                    <span>ramananda</span><span>@hammockholidays.com</span> 
                  </p>
                </a>
                <p className="footer-sec-p d-flex">
                <a href="https://goo.gl/maps/3nJU3rGHzTEPn9o79" target="_blank" aria-label="location"> <img src={Location} alt="location-icon" className="footer-logo-mob mr-2" />{" "}</a>
                  <span>3291, 1st Floor, 12th Main ,<br className="d-block d-md-none"/> HAL II <br className="d-none d-md-block"/> Stage, Indiranagar,
                  Bengaluru, Karnataka 560008</span>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />
        <p className="footer-last">
          © {new Date().getFullYear()} Hammock, Inc.&nbsp; All rights reserved. &nbsp;
          <span className="footer-links" href onClick={() => goToTermsPage()}>
            {" "}
            Terms & Conditions.{" "}
          </span>
          <span className="footer-links" href onClick={() => goToPrivacyPage()}>
            {" "}
            Privacy policy.{" "}
          </span>
          <span className="footer-links" href onClick={() => goToRefundPage()}>
            {" "}
            Refund & Cancellations.
          </span>
        </p>
      </Container>
    </div>
  );
}

export default Footer;
