import React from "react";
import backIcon from "../../../assets/images/common/back-icon.svg";


import Hikes from "../../../assets/images/itinerary/Hikes.svg";
import GaugeChart from "react-gauge-chart";
import styled from "styled-components";

import { useGauge } from "use-gauge";
import { getActivityType } from "./itinerayfunctions";
import { Rating } from "@material-ui/lab";
export default function ItineraryFeatures({
  data,
  calculateDays,
  isInternational,
  chaneCategory,
  fromCorporate,
  isWipro,
  dates
}) {
  const {
    activity_level,
    activity_type,
    location_country,
    group_size,
    ages,
    average_review_count,
    total_review_counts,
    price,
    revised_price,
    location_city,
    points
  } = data && data;
  let fLCapital = (s) => s.replace(/./, (c) => c.toUpperCase());
  const  state  = data?.location&&data.location[0]?.state
  const  latitude  = data?.location&&data.location[0]?.latitude
  const  longitude  = data?.location&&data.location[0]?.longitude
  const isSecondLocation = data?.location && data.location[1]?.address
  const secondLocation = isSecondLocation?.includes(',') ? isSecondLocation.split(',')[0] : isSecondLocation
  let colors = [
    "#2BB14A",
    activity_level > 1 ? "#F3AF1A" : "#E0E0E0",
    activity_level > 2 ? "#E9D702" : "#E0E0E0",
    activity_level > 3 ? "#E03D3D" : "#E0E0E0",
  ];
  let activityLevelTxt =
    activity_level == 1
      ? "Leisurely"
      : activity_level == 2
      ? "Moderate"
      : activity_level == 3
      ? "Challenging"
      : activity_level == 4
      ? "Strenuous"
      : null;
  let percent =
    activity_level == 1
      ? 0.1
      : activity_level == 2
      ? 0.35
      : activity_level == 3
      ? 0.65
      : activity_level == 4
      ? 0.85
      : null;
  const GaugeContainer = styled.div``;
  const gauge = useGauge({
    domain: [0, 100],
    startAngle: 90,
    endAngle: 270,
    numTicks: 4,
    diameter: 100,
  });

  const needle = gauge.getNeedleProps({
    value: 30,
    baseRadius: 8,
    tipRadius: 1,
  });
  const GaugeText = styled.div`
    position: absolute;

    border-top-right-radius: 56px;
    border-top-left-radius: 56px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  `;
  const summary = {
    "Location:": " Nepal ",
    "Activity:": "Walks/Hikes",
    "Group Sizes:": "4-16",
    "Ages:": "16+",
  };
  return (
    <div>
      <div className="itinerary-features-holder row">
        <div className="itinerary-loc-details col-12 col-lg-3  col-md-12">
          <div className="location d-flex" style={{ gap: "25px" }}>
            <p className="loc-key">{secondLocation ? 'Locations' : 'Location'}:</p>
            <p className="loc-val">
              {!isInternational&&!secondLocation ? (
                <a
                  href={`https://maps.google.com/maps?q=${latitude},${longitude}&hl=es;z=14&amp;output=embed`}
                  target="_blank"
                >
                 <p>{location_city&& `${fLCapital(location_city)}`}{state!==null&&state!=""?`, ${state}`:""}</p> 
                
                </a>
              ) : (
                isInternational?fLCapital(location_country) :secondLocation? location_city && `${fLCapital(location_city)}, ${fLCapital(secondLocation)}`:null 
              )}
            </p>
          </div>
          <div className="itinerary-details">
            <div>
              <p>Activity:</p>
              <p>Group Size:</p>
              <p className="ages">Age:</p>
            </div>
            <div className="sum-val">
              <p>{getActivityType(activity_type, "txt")}</p>
              <p>{group_size?.min&&group_size?.max? `${group_size?.min} - ${group_size?.max}`:"-"}</p>
              <p className="ages">{ages}</p>
            </div>
          </div>
        </div>
        <div className="d-flex act-rating col-12  col-md-12 col-lg-6 ">
          <div className="sum-act-level d-flex flex-column align-items-center  ">
            <div className="d-flex flex-wrap justify-content-center lev-tit">
              {" "}
              <p>Activity level:</p>
              <span>{activityLevelTxt}</span>
            </div>
            <div className="level-meter">
              <GaugeContainer className="GaugeContainer">
                <GaugeChart
                  id="uv-gauge-chart"
                  nrOfLevels={2}
                  arcPadding={0.02}
                  cornerRadius={4}
                  needleColor="#E0E0E0"
                  needleWidth={"30px"}
                  arcWidth={0.25}
                  needleBaseColor="transparent"
                  formatTextValue={() => ""}
                  arcsLength={[0.25, 0.25, 0.25, 0.25]}
                  colors={colors}
                  percent={percent}
                />
                <GaugeText className="text">{activity_level}</GaugeText>
              </GaugeContainer>
            </div>
          </div>
          <div className="sum-ratings d-flex flex-column  align-items-center">
            <div className="d-flex flex-column justify-content-between align-items-center">
             
                <Rating
                  name="size-medium"
                  value={average_review_count}
                  readOnly
                />
              
              <p>
                {average_review_count} / 5 from {total_review_counts} reviews
              </p>
            </div>

            <div className="d-flex flex-column justify-content-between align-items-center">
              <img alt={activity_type} src={getActivityType(activity_type, "img")} style={{width:activity_type==="leisure"?"65px":"115px"}}/>
            </div>
          </div>
        </div>
        <div className="sum-price d-flex  col-12 col-lg-3 col-md-12">
          <div className="prices d-flex flex-column align-items-center">
            {revised_price!=0&&!fromCorporate&&
            <p className="normal" style={{ color: "#959595" }}>
              {!isWipro?"Rs.":""}{(price?price:points).toLocaleString('en-IN')} {isWipro?"pts":""}
            </p>
}
<div className="d-flex date-price align-items-center">  <p className="discount">
              From{" "}
              <span style={{ fontWeight: "700", fontSize: "28px" }}>
                {!isWipro?"Rs.":""}{(fromCorporate?points:revised_price!=0?revised_price:price).toLocaleString('en-IN')} {isWipro?"pts":""}
              </span>
            </p> {calculateDays && <p className="daterange"> {calculateDays}</p>}</div>
           
          </div>
        
          <button className="btn view-prices-btn" onClick={()=>chaneCategory(dates,"tab")}>View {dates}</button>
        </div>
      </div>
    </div>
  );
}
