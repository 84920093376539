import React from "react";
import "../../../assets/css/partials/corporates/corporates.scss";
import { DOMESTIC_COVERAGE1, DOMESTIC_COVERAGE2 } from "../../../resources/holidaysData";
function DomesticCoverage() {
  return (
    <div className="our-clients">
      <h6>Some of our Domestic coverage </h6>
      <div className="logo-wrapper">
          <div className="d-flex justify-content-xl-center">
            {DOMESTIC_COVERAGE1.length > 0 &&
              DOMESTIC_COVERAGE1.map((item) => (
                <div className="card-wrapper card-wrapper-coverage">
                  <div className="img-holder">
                    <img src={item.image} alt="" />
                  </div>
                  <div className="info">
                    <p className="mb-0">{item.name}</p>
                  </div>
                </div>
              ))}
          </div>
        
          <div className="d-flex justify-content-xl-center">
          {DOMESTIC_COVERAGE2.length > 0 &&
            DOMESTIC_COVERAGE2.map((item) => (
              <div className="card-wrapper">
                <div className="img-holder">
                  <img src={item.image} alt="" />
                </div>
                <div className="info">
                  <p className="mb-0">{item.name}</p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default DomesticCoverage;
