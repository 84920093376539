
import { USER_PROFILE_DETAILS, USER_BOOKING_DETAILS, NON_CORP_USER_PROFILE_DETAILS, UPDATED_MOBILE_NUMBER, UPDATE_USER_DEATILS_MESSAGE, INVALID_OTP_MESSAGE_PROFILE, MOBILE_NUMBER_ALREADY_EXIST, SHOW_OTP_MODAL_FALSE, SET_SCROLL } from '../actions/types'
const initialState = {
    profile_details: [],
    booking_details: [],
    updatedMobileNum:null,
    updateUserDetailsMessage:"",
    invalidOtpErrMsg:"",
    numberAlreadyExist: false,
    showOtpModalFalse:null,
    set_Scroll: false
}

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_PROFILE_DETAILS:
            return {
                ...state,
                profile_details: action.payload
            }
        case USER_BOOKING_DETAILS:  
            return {
                ...state,
                booking_details: action.payload
            }
        case NON_CORP_USER_PROFILE_DETAILS:
            return {
                ...state,
                profile_details: action.payload
            }    
        case UPDATED_MOBILE_NUMBER:
            return {
                ...state,
                updatedMobileNum: action.payload
            }    
        case UPDATE_USER_DEATILS_MESSAGE:
            return {
                ...state,
                updateUserDetailsMessage: action.payload
            }    
        case INVALID_OTP_MESSAGE_PROFILE:
            return {
                ...state,
                invalidOtpErrMsg: action.payload
            }    
        case SHOW_OTP_MODAL_FALSE:
            return {
                ...state,
                showOtpModalFalse: action.payload
            }    
        case MOBILE_NUMBER_ALREADY_EXIST:
            return {
                ...state,
                numberAlreadyExist: action.payload
            } 
        case SET_SCROLL:
            return {
                ...state,
                set_Scroll: action.payload
            }    
        default:
            return state
    }
}

export default profileReducer