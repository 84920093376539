
import React, { useState, useEffect, useRef } from 'react';
import Header from '../header';
import Footer from '../footer';
import { makeStyles } from '@material-ui/core/styles';

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import backIcon from '../../../assets/images/common/back-icon.svg';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import Slider from '@material-ui/core/Slider';
import GuestsModal from "../../common/guestsModal";
import CalenderModal from "../../corporates/calenderModal";
import { testEmail } from "../../../utils/common";
import { useSnackbar } from 'notistack';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Input from '@material-ui/core/Input';
import productImg from '../../../assets/images/dummy/voucher.png'
import '../../../assets/css/partials/common/checkout.scss';
import Edit from '../../../assets/images/common/Edit.svg';
import * as corporateAction from '../../../redux/actions/corporatesAuthActions';
import { NUMBER_OF_GUESTS, CHILDREN_INFANT_ERROR, PEOPLE, SELECTED_DATE, SPECIFIC_REQUEST } from '../../../utils/constants';
import IntlContactNumber from "../intlContactNumber";
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import UserDetails from '../userDetails'
import useClickOutside from '../../../hooks/useClickOutside';
import mark from '../../../assets/images/common/mark.png';
import exclamationMark from '../../../assets/icons/authIcons/confirmation-icon.png';
import * as HolidaysAction from "../../../redux/actions/holidaysActions";
import { ScrollToTop } from '..';
import { scheduleDuration, specificScheduleDuration } from '../../utilities/utillFunc';
import HolidayRangePicker from "../../holidays/holidayRangePicker";
import HolidayHeader from '../holidayHeader';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    input: {
        width: 42,
    },
});

function Checkout() {
    const dispatch = useDispatch()
    const history = useHistory();

    const classes = useStyles();
    const [value, setValue] = useState(100);
    const [disabled, setDisabled] = useState(true);
    const [numDisabled, setnumDisabled] = useState(true);
    const [guestDisabled, setguestDisabled] = useState(true);
    const [isRewardPointsBtnBool, setIsRewardPointsBtnBool] = useState(false);
    const [showDateRangeModal, setShowDateRangeModal] = useState(false);
    const activePackageIndex = useSelector(
        (state) => state?.corporates?.activePackageIndex
      );
    const bookingInformationData = useSelector(state => state.corporates.booking_information_data);
    const changeDataProceedToBook = useSelector(state => state.corporates.change_data_to_proceed_to_book);
    const PACKAGE_DETAILS = useSelector(state => state.corporates.corporates_package_details.data);
    const initialPackageData = PACKAGE_DETAILS && PACKAGE_DETAILS?.corporate?.packages[activePackageIndex];
    const SECTION_NAME = PACKAGE_DETAILS && PACKAGE_DETAILS?.corporate?.section?.name;
    
    const dataChangedBoolVal = useSelector(state => state.corporates.data_changed_bool_val);
    const packScheduleData = dataChangedBoolVal ? changeDataProceedToBook?.schedule_data : initialPackageData?.schedule_data;
    const isPackage = dataChangedBoolVal ? changeDataProceedToBook?.schedule_type == "package" : initialPackageData?.schedule_type == "package"
    const isRecurring = dataChangedBoolVal ? changeDataProceedToBook?.schedule_type == "recurring" : initialPackageData?.schedule_type == "recurring"
    const isDateRange = dataChangedBoolVal ? changeDataProceedToBook?.schedule_type == "date_range" : initialPackageData?.schedule_type == "date_range"
    const isSpecific = dataChangedBoolVal ? changeDataProceedToBook?.schedule_type == "specific_dates" : initialPackageData?.schedule_type == "specific_dates"
    const packageData = dataChangedBoolVal ? changeDataProceedToBook?.schedule_data : initialPackageData?.schedule_data;
    const isEnablePayment = dataChangedBoolVal ? changeDataProceedToBook?.enable_payment : initialPackageData?.enable_payment;
    const isFlexible= dataChangedBoolVal ? changeDataProceedToBook?.flexi_schedule : initialPackageData?.flexi_schedule;
    const days = packageData?.days;
    const nights = packageData?.nights
    const { adults, children, infants, schedule_data,schedule_type, contact_email, contact_name, contact_number, special_request, total_price } = bookingInformationData;

    const userDetails = JSON.parse(localStorage.getItem("userData"));
    let dateRangeMinDays = null; 
    let dateRangeMaxDays = null; 
    const profile_details = useSelector(state => state?.userProfile?.profile_details);
    const points = parseInt(localStorage.getItem("points")) ? parseInt(localStorage.getItem("points")) : profile_details?.data?.points;

    // let mobile = localStorage.getItem("mobile");
    let mobile = contact_number;
    if (isNaN(mobile)) {
        let userDataStored = JSON.parse(localStorage.getItem("userData"));
        mobile = userDataStored.user_details.mobile;
    }
    const contactName = localStorage.getItem("full_name")
    const [balance, setBalance] = useState(total_price);
    const [pointsLeft, setPointsLeft] = useState(points);
    const [usedRewardPoints, setUsedRewardPoints] = useState(0);

    const [emailDisabled, setEmailDisabled] = useState(true);
    const [requestDisabled, setRequestDisabled] = useState(true);
    const [isEditName, setIsEditName] = useState(false);
    const [editName, setEditName] = useState('');
    const [isEditEmail, setIsEditEmail] = useState(false);
    const [isEditSelectDate, setIsEditSelectDate] = useState(true);

    const [selectStartDate, setSelectStartDate] = useState('');
    const [selectEndDate, setSelectEndDate] = useState('');
    const [selectFlexibleDate, setSelectFlexibleDate] = useState([]);
    const [resAPIStartDate, setResAPIStartDate] = useState(null);
    const [resAPIEndDate, setResAPIEndDate] = useState(null);
    const [resAPISelectedDates, setResAPISelectedDates] = useState(null);
    const [adult, setAdult] = useState('');
    const [adultError, setAdultError] = useState(false);
    const [editNameError, setEditNameError] = useState(false);
    const [editEmailError, setEditEmailError] = useState(false);
    const [editEmailValidError, setEditEmailValidError] = useState(false);
    const [editNumberError, setEditNumberError] = useState(false);
    const [gstAmount, setGstAmount] = useState(total_price);
    const [updatedChildren, setUpdatedChildren] = useState('');
    const [updatedChildrenError, setUpdatedChildrenError] = useState(false);
    const [updatedInfants, setUpdatedInfants] = useState('');
    const [updatedInfantsError, setUpdatedInfantsError] = useState(false);
    
    const [updateDateBool, setUpdateDateBool] = useState(false);
    const [updateGuestBool, setUpdateGuestBool] = useState(false);
    const [showGuestModal, setShowGuestModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showCalenderModal, setShowCalenderModal] = useState(false);
    const [editEmail, setEditEmail] = useState('');
    const [isEditNumber, setIsEditNumber] = useState(false);
    const [editNumber, setEditNumber] = useState('');
    const [isEditSpecificRequest, setIsEditSpecificRequest] = useState(false);
    const [specificRequest, setSpecificRequest] = useState('');
    const [isNameUpdate, setisNameUpdate] = useState(false);
    const [isEmailUpdate, setisEmailUpdate] = useState(false);

    const [initialAmount, setInitialAmount] = useState(initialPackageData?.points);
    const [changedAmount, setChangedAmount] = useState(changeDataProceedToBook?.points);
    const [includingGST, setIncludingGST] = useState(total_price)
    const [includingChangedGST, setIncludingChangedGST] = useState(total_price)
    const [afterCheckedGST, setAfterCheckedGST] = useState(total_price)

    const [scroll, setScroll] = useState(false);
    const [radiovalue, setRadioValue] = useState('DC');
    const [showModal, setShowModal] = useState(false);
    const userInfo = JSON.parse(localStorage.getItem("userData"));
    const companyDomain = userInfo?.organisation?.email_domain;
    const isWipro = (companyDomain === "wipro.com");
    const {enqueueSnackbar} = useSnackbar();
    const myRef = useRef(null)
    const executeScroll = () => myRef.current.scrollIntoView()
    const nameInputRef = useRef();
    const emailInputRef = useRef();
    const popupRef = useRef();
    useClickOutside(nameInputRef, () => nameUpdateSuccess());
    useClickOutside(emailInputRef, () => emailUpdateSuccess());
    const nameUpdateSuccess = () => {
        if(isNameUpdate && editName == ""){
            enqueueSnackbar("Please enter your name", {
                variant: "error",
            });
        }else if(isNameUpdate){
            enqueueSnackbar("Your name updated successfully", {
                variant: "success",
            });
            setEditNameError(false)
        }
        setisNameUpdate(false)
    }

    const emailUpdateSuccess = () => {
        if(isEmailUpdate && editEmail == ""){
            enqueueSnackbar("Please enter your email", {
                variant: "error",
            });
        }else if(isEmailUpdate && !testEmail(editEmail)){
            enqueueSnackbar("Please enter valid email", {
                variant: "error",
            });
            
        }else if(isEmailUpdate){
            enqueueSnackbar("Your email updated successfully", {
                variant: "success",
            });
            setEditEmailError(false)
            setEditEmailValidError(false)
            
        }
        setisEmailUpdate(false)
    }
    useClickOutside(popupRef, () => {
        setShowModal(false)
        if(showModal){
            history.push('/corporates')
            window.scroll(0,0)
        }
    });

    useEffect(() => {
        const initialPrice = initialPackageData?.points
        const calculateGST = (initialPrice * 5 / 100) + initialPrice; // Calculating 5% GST 
        setInitialAmount(calculateGST - initialPrice);
        setIncludingGST(calculateGST)

        const ChangedPrice = changeDataProceedToBook?.points;
        const calculateChangedGST = (ChangedPrice * 5 / 100) + ChangedPrice; // Calculating 5% GST 
        setChangedAmount(calculateChangedGST - ChangedPrice);
        setIncludingChangedGST(calculateChangedGST);

        // hide/show confirm button on scroll
        let mobile=isEnablePayment?900:350
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > mobile);
        });
    }, [initialPackageData?.points, changeDataProceedToBook?.points, radiovalue])


    useEffect(() => {
        window.scrollTo(0,0);
    }, [])
    const onBackButtonEvent = (e, bool) => {
        e.preventDefault()
        setShowConfirmModal(true)
        if(bool){
            history.goBack()
        }
        setTimeout(()=>{
            window.scrollTo(0,0)})
    }
    const checkoutRef=useRef()
    useEffect(() => {
        checkoutRef?.current?.scrollIntoView()
        setTimeout(()=>{
        window.scrollTo(0,0)})
      }, [checkoutRef?.current])

    useEffect(() => {
        // window.history.pushState(null, null, window.location.pathname);
        if(window.history.state?.name!=='backstate'){
            window.history.pushState({name:'backstate'}, null, window.location.pathname);
        }
        window.addEventListener('popstate', onBackButtonEvent);
        return () => {
          window.removeEventListener('popstate', onBackButtonEvent);  
        };
      }, []);

    const balanceUpdateHandler = () => {
        const checkBox = document.querySelector('#rewardPointsCheck')

        if (checkBox.checked === true) {
            setIsRewardPointsBtnBool(true);
            if (total_price === points) {
                setBalance(0);
                setPointsLeft(0);
                setUsedRewardPoints(total_price);
                setValue(0);
                setGstAmount(0);
                setAfterCheckedGST(0);
            }
            else if (total_price > points) {
                setBalance(total_price - points);
                setPointsLeft(0);
                setUsedRewardPoints(points);
                setValue(0);
                const getBalance = total_price - points;
                const calcGST = (getBalance * 5 / 100) + getBalance
                setGstAmount(calcGST);
                setAfterCheckedGST(calcGST - getBalance)

            }
            else if (total_price < points) {
                setBalance(0);
                setPointsLeft(points - total_price);
                setUsedRewardPoints(total_price);
                const getPointsLeft = points - total_price
                const calculatePercentage = getPointsLeft * 100 / points;
                setValue(calculatePercentage);
                setGstAmount(0);
                setAfterCheckedGST(0);
            }
        }
        else if (checkBox.checked === false) {
            setIsRewardPointsBtnBool(false);
            setUsedRewardPoints(0);
            setBalance(total_price);
            const getBalance = total_price;
            setGstAmount((getBalance * 5 / 100) + getBalance);
            setAfterCheckedGST(0)
            if (points > 0) {
                setValue(100);
            }
        }
    }
    const handleCheckout = (e) => {
        e.preventDefault();
        const { adults, pack_id, children, infants, contact_email, contact_name, contact_number, schedule_data, special_request } = bookingInformationData;
        if (adult === 0 && updatedChildren === 0 && updatedInfants === 0) {
            setAdultError(true);
            setUpdatedChildrenError(true);
            setUpdatedInfantsError(true);
        }
        else if (adult === 0) {
            setAdultError(true);
            setUpdatedChildrenError(false);
            setUpdatedInfantsError(false);
        }
        else if (isEditName && (editName === "" || editName === null)) {
            setEditNameError(true)
            setEditEmailError(false)
            setEditEmailValidError(false)
            focusInput('#name');
        }
        else if (isEditEmail && (editEmail === "" || editEmail === null)) {
            setEditEmailError(true)
            setEditEmailValidError(false)
            setEditNameError(false)
            focusInput('#email');
        }
        else if (isEditEmail && !testEmail(editEmail)) {
            setEditEmailValidError(true)
            setEditEmailError(false)
            setEditNameError(false)
            focusInput('#email');
        }
        else if((editNumber.startsWith('+91') && editNumber.length!==13)  || !isPossiblePhoneNumber(editNumber)){
            setEditNumberError(true)
            setEditEmailError(false)
            setEditEmailValidError(false)
            setEditNameError(false)
            focusInput('.PhoneInputInput');
        }
        else {
            let data = {
                pack_id: dataChangedBoolVal ? changeDataProceedToBook?.id : pack_id,
                adults: !updateGuestBool ? adults : adult,
                children: !updateGuestBool ? children : updatedChildren,
                infants: !updateGuestBool ? infants : updatedInfants,
                resAPIStartDate: !updateDateBool ? schedule_data?.from : resAPIStartDate, 
                resAPIEndDate: !updateDateBool ? schedule_data?.to : resAPIEndDate, 
                resAPISelectedDates: !updateDateBool ? schedule_data : resAPISelectedDates, 

                special_request: isEditSpecificRequest ? specificRequest : special_request,
                total_points: usedRewardPoints,

                amount: !dataChangedBoolVal && !isRewardPointsBtnBool ? includingGST : dataChangedBoolVal && !isRewardPointsBtnBool ? includingChangedGST : gstAmount,
                price: total_price,
                contact_name: isEditName ? editName : contactName,
                contact_email: isEditEmail ? editEmail : contact_email,
                contact_number: isEditNumber ? editNumber : mobile,
                pay_mode: radiovalue
            }
            const updatedData = {
                ...bookingInformationData,
                resAPIStartDate: !updateDateBool ? schedule_data?.from : resAPIStartDate, 
                resAPIEndDate: !updateDateBool ? schedule_data?.to : resAPIEndDate, 
                resAPISelectedDates: !updateDateBool ? schedule_data : resAPISelectedDates, 
                adults: !updateGuestBool ? adults : adult,
                children: !updateGuestBool ? children : updatedChildren,
                infants: !updateGuestBool ? infants : updatedInfants,
                contact_name: isEditName ? editName : contact_name,
                contact_email: isEditEmail ? editEmail : contact_email,
                contact_number: isEditNumber ? editNumber : mobile,
                special_request: isEditSpecificRequest ? specificRequest : special_request,
            }
            dispatch(corporateAction.updateBookingData(updatedData));
            if(isEnablePayment){
                // if amount is greated then zero redirecting to payment's page
                // and if paying with points after redeeming points redirecting to success page
                if (data?.pack_id !== "" || data?.pack_id !== null) {
                    if (data?.amount > 0) {
                        dispatch(corporateAction.checkOut(data, paidWithPoints, payAmount))
                        // history.push(`/checkout-details/holiday`, data);
                    }
                    else {
                        dispatch(corporateAction.checkOut(data, paidWithPoints, payAmount));
                    }
                }
            }else{
                dispatch(corporateAction.availabilityRequest(data, handleSuccess));
            }
            
        }
    }

    const handleSuccess = () => {
        setShowModal(true)
        // setTimeout(() => {
        //     setShowModal(false)
        //     window.scroll(0,0)
        //     history.push('/corporates')
            
        // }, 5000)
    };
    const paidWithPoints = () => {
        history.push(`/payment-successful`);
        dispatch(corporateAction.corpHolidayEditedName(null, false));
        dispatch(corporateAction.corpHolidayEditedNumber(null, false));
        dispatch(corporateAction.giftVoucherEditName(null, false));
        dispatch(corporateAction.giftVoucherEditNumber(null, false));
    }

    const payAmount = (data) => {
        history.push(`/corporate/checkout-details/holiday`, data);
    }

    const handleSliderChange = (event, newValue) => {
        value(newValue);
    };

    const handleEditNameClick = () => {
        setDisabled(!disabled);
    }

    const handleEditEmailClick = () => {
        setEmailDisabled(!emailDisabled);
    }
    const handleEditNumberClick = () => {
        setnumDisabled(!numDisabled);
    }
    const handleEditGuestClick = () => {
        setguestDisabled(!guestDisabled);
    }
    const changeName = (e) => {
        setIsEditName(true)
        setisNameUpdate(true)
        setEditName(e.target.value)
        dispatch(corporateAction.corpHolidayEditedName(e.target.value, true))
        setEditNameError(false)
    };


    const paymentModeChange = (e) => {
        setRadioValue(e.target.value);
    }

    const emailChange = (e) => {
        setIsEditEmail(true)
        setisEmailUpdate(true)
        setEditEmail(e.target.value)
        setEditEmailError(false)
        setEditEmailValidError(false)
    };

    const numberChange = (val) => {
        setIsEditNumber(true)
        if(val){
            setEditNumber(val)
            dispatch(corporateAction.corpHolidayEditedNumber(val, true))
            setEditNumberError(false)
        } else {
            setEditNumber('');
            dispatch(corporateAction.corpHolidayEditedNumber('', false));
        }
        
    };

    const changeSpecialRequest = (e) => {
        setIsEditSpecificRequest(true)
        setSpecificRequest(e.target.value)
    };

    const handleNumberOfGuests = () => {
        setShowGuestModal(true);
        if (showGuestModal) {
            document.getElementById("guestModal").classList.remove("hide");
            document.getElementById("guestModal").classList.add("show");
        }
    };

    const guestDatahandler = (adultData, childrenData, countData) => {
        setUpdateGuestBool(true)
        setShowGuestModal(false);
        setAdult(adultData);
        setUpdatedChildren(childrenData);
        setUpdatedInfants(countData);
    };

    const handleEditSelectDate = () => {
        setIsEditSelectDate(!isEditSelectDate);
    };

    const dateSelectHandler = () => {
        setShowCalenderModal(true);
    }

    const handleDatesData = (startDate, endDate, selectedDates) => {
        setShowCalenderModal(false);
        if (startDate === false) {
            setUpdateDateBool(false);
        } else {
            setUpdateDateBool(true);
            setSelectStartDate(startDate);
        }
        if (endDate === false) {
            setUpdateDateBool(false);
        } else {
            setUpdateDateBool(true);
            setSelectEndDate(endDate);
        }
        if(selectedDates?.length === 0){
            setUpdateDateBool(false)
        } else{
            setUpdateDateBool(true);
            setSelectFlexibleDate(selectedDates);
        }
    }

    const focusInput = (inputDom)=>{
        var elementPosition = document.querySelector(`${inputDom}`).getBoundingClientRect().top;
                    var offsetPosition = elementPosition + window.pageYOffset - 145;
                    window.scrollTo({top: offsetPosition, behavior: "smooth" });
                    setTimeout(()=>{
                        document.querySelector(inputDom).focus();
                    });
    };

    const handleResAPIStartAndEndDate = (resStartDate, resEndDate, selectedDates) => {
        setResAPIStartDate(resStartDate)
        setResAPIEndDate(resEndDate)
        setResAPISelectedDates(selectedDates)
    }

    const packImage = dataChangedBoolVal ? changeDataProceedToBook?.images : initialPackageData?.images;

    const getGuestCount = (adult, kids, infant)=>{
        // `${adultGuestCount} adult(s), ${childrensGuestCount} kid(s), ${count} infant(s)` //old code
        let tmpGuestsArray = [];
        if(adult>0){
            tmpGuestsArray.push(`${adult} adult${adult>1?'s':''}`);
        }
        if(kids>0){
            tmpGuestsArray.push(`${kids} kid${kids>1?'s':''}`);
        }
        if(infant>0){
            tmpGuestsArray.push(`${infant} infant${infant>1?'s':''}`);
        }
        return tmpGuestsArray.join(', ');
    };

    const getSpecificDatesMonth = () => {
        const months = []; 
        packScheduleData?.multipleDates.map(date => {                   
            months.push(moment(date).format("MMM"));
        }) 
        const uniqueMonths = [... new Set(months)]
        const specificDatesMonths =  uniqueMonths.map((item, index) => {
            return <span> {`${item}${uniqueMonths.length == index + 1 ? '' : ","} `}</span>
        })
        return specificDatesMonths
    
    }
    // calculate days between selected dates
    const calculateDays = () => {
        const arr = [];
        if(!dataChangedBoolVal){
            if(initialPackageData && initialPackageData?.schedule_type == "date_range" ){
                initialPackageData && initialPackageData?.schedule_data?.selectedDates?.map(date => {
                    const startDate = moment(`${date.to}`, 'DD-MM-YYYY');
                    const endDate = moment(`${date.from}`, 'DD-MM-YYYY');
                    const result = endDate.diff(startDate, 'days');
                    arr.push(Math.round(Math.abs(result) + 1 ));
                });
            }
        }
       else {
        if( changeDataProceedToBook && changeDataProceedToBook?.schedule_type == "date_range" ){
            changeDataProceedToBook && changeDataProceedToBook?.schedule_data?.selectedDates?.map(date => {
                const startDate = moment(`${date.to}`, 'DD-MM-YYYY');
                const endDate = moment(`${date.from}`, 'DD-MM-YYYY');
                const result = endDate.diff(startDate, 'days');
                arr.push(Math.round(Math.abs(result) + 1));   
            });
        } 
       }
        
        const min = Math.min(...arr);
        const max = Math.max(...arr);
        const calculatedDateRangeDays = {
            "minimum": min, 
            "maximum": max
        } 
        dateRangeMinDays = min;
        dateRangeMaxDays = max;
        if(min !== max){
            return <span>Min {min} {min == 1 ? 'day' : 'days'}, Max {max} {max == 1 ? 'day' : 'days'}</span>
        }else{
            return<span> Max {max} {min == 1 ? 'day' : 'days'}</span>
        }
        
    }
    const goBack=()=>{
        localStorage.setItem("cor-actidx",initialPackageData?.id);
        history.goBack()
        dispatch(HolidaysAction.setScroll(true));
    }
    const updatedDate = () => {
        if(schedule_type === "date_range"){
            return `${moment(selectStartDate, 'DD-MM-YYYY').format('Do MMM, ddd YYYY')} - ${moment(selectEndDate, 'DD-MM-YYYY').format('Do MMM, ddd YYYY')}`
        }else{
            return `${moment(selectStartDate, 'ddd-DD MMM YYYY').format('Do MMM, ddd YYYY')} - ${moment(selectEndDate, 'ddd-DD MMM YYYY').format('Do MMM, ddd YYYY')}`
        }
        
    }

    const dateRangeSelector = () => {
        setShowDateRangeModal(true);
        if (showDateRangeModal) {
            document.getElementById("holidayModal").classList.remove("hide");
            document.getElementById("holidayModal").classList.add("show");
        }
    }


    return (
        <div>
            <div className="innerPage-header hide-header-mobi">
            <div className="d-lg-none">
                    <Header />
                </div>
                <div className="d-none d-lg-block">
                    <HolidayHeader />
                </div>
            </div>
            <div ref={checkoutRef} className="container innerPage-container innerPage-container-mobi">
                <a href onClick={(e) => onBackButtonEvent(e,true)}>
                    <div className="back-button back-button-mobi">
                        <img src={backIcon} alt="backIcon" />
                    </div>
                </a>
                <div className="login-success-popup">
                <Dialog
                    open={showConfirmModal}
                >
                    <DialogTitle>
                        <div className="success-holder" >
                            <div>
                                <img src={exclamationMark} alt="successful" />
                            </div>
                            <div className="success-message">
                                <p style={{textAlign: "center", fontWeight:"bold", fontSize:"18px"}} >Are you sure you want to go back?</p>
                                <p style={{textAlign: "center", fontWeight:"normal", fontSize:"14px", opacity: "0.6"}} className="mb-5"> You may lose the updated information! </p>
                                <div className="confirmation-btn">
                                    <button className="cursor-pointer " onClick={() => goBack()}>Yes</button>
                                    <button className="cursor-pointer " onClick={() => setShowConfirmModal(false)}>No</button>
                                </div>
                            </div>

                        </div>
                    </DialogTitle>
                </Dialog>
                </div>
                <div className="purchase-details">
                    <h1>Your Details</h1>
                    <div className="line"></div>
                    <div className="row mb-5 pb-5">
                        <div className="col-12 col-lg-7">
                            <div className="d-flex d-md-block mobi-overflow">
                                <div className="product-holder product-holder-mobi">
                                    <div className="prod-img">
                                    {packImage && packImage.map(image => {
                                        if(image?.thumbnail == true){
                                            return <img src={image?.image} alt={initialPackageData?.pack_title} width="200" height="228" />
                                        }
                                    })}
                                    </div>
                                    <div className="prod-info">
                                        <h5>{!dataChangedBoolVal ? initialPackageData?.pack_title : changeDataProceedToBook?.pack_title}</h5>
                                        <div className="locations-list">
                                        { SECTION_NAME === "International" ? 
                                            <p>{!dataChangedBoolVal ? initialPackageData?.location_country : changeDataProceedToBook?.location_country}</p>:
                                            <p>{!dataChangedBoolVal ? initialPackageData?.location[0]?.city : changeDataProceedToBook?.location[0]?.city}, {!dataChangedBoolVal ? initialPackageData?.location[0]?.state : changeDataProceedToBook?.location[0]?.state}</p>}
                                        </div>
                                        <div className="line"></div>

                                        <div className="details">
                                            {isPackage ? 
                                                <p>{!dataChangedBoolVal ? initialPackageData?.schedule_data?.days : changeDataProceedToBook?.schedule_data?.days} {(initialPackageData?.schedule_data?.days == 1 || changeDataProceedToBook?.schedule_data?.days == 1) ? 'Day' : 'Days'}  {!dataChangedBoolVal ? initialPackageData?.schedule_data?.nights : changeDataProceedToBook?.schedule_data?.nights} {
                                                    (initialPackageData?.schedule_data?.nights == 1 || changeDataProceedToBook?.schedule_data?.nights == 1) ? 'Night' : 'Nights'
                                                } </p>:
                                                ""
                                            }
                                            {/* isSpecific */}
                                        { isSpecific ? 
                                            <div className="details">
                                                <p>In the month of {getSpecificDatesMonth()} {specificScheduleDuration(dataChangedBoolVal, changeDataProceedToBook, initialPackageData)}</p>
                                            </div> 
                                        : ""
                                        }
                                        {/* recurring dates */}
                                        { isRecurring ?
                                                <div className="details">
                                                    <p>Every
                                                        {!dataChangedBoolVal ? 
                                                        initialPackageData.schedule_data?.newSelectedDyas?.length > 0 &&  initialPackageData?.schedule_data?.newSelectedDyas.map((index) => {
                                                            switch(index){
                                                                case 0:
                                                                    return <span> Sun</span>
                                                                case 1:
                                                                    return <span>, Mon</span>
                                                                case 2:
                                                                    return <span>, Tue</span>
                                                                case 3:
                                                                    return <span>, Wed</span>
                                                                case 4:
                                                                    return <span>, Thu</span>
                                                                case 5:
                                                                    return <span>, Fri</span>
                                                                case 6:
                                                                    return <span>, Sat</span>
                                                            }
                                                        }) : 
                                                        changeDataProceedToBook?.schedule_data?.newSelectedDyas?.length > 0 &&  changeDataProceedToBook?.schedule_data?.newSelectedDyas.map((index) => {
                                                            switch(index){
                                                                case 0:
                                                                    return <span> Sun</span>
                                                                case 1:
                                                                    return <span>, Mon</span>
                                                                case 2:
                                                                    return <span>, Tue</span>
                                                                case 3:
                                                                    return <span>, Wed</span>
                                                                case 4:
                                                                    return <span>, Thu</span>
                                                                case 5:
                                                                    return <span>, Fri</span>
                                                                case 6:
                                                                    return <span>, Sat</span>
                                                            }
                                                        })
                                                        }
                                                        {scheduleDuration(dataChangedBoolVal, changeDataProceedToBook, initialPackageData)}
                                                    </p>
                                                </div> 
                                            : null
                                        }
                                        {/* date range */}
                                        { isDateRange ? 
                                            <div className="details">
                                                <p>{calculateDays()}</p>
                                            </div>
                                            : ""
                                        }
                                        { isFlexible ? 
                                            <div className="details">
                                                <p>Flexible Days</p>
                                            </div>
                                            : ""
                                        }
                                        </div>
                                    </div>

                                </div>

                                <div className="buyer-details mt-4 edit-package-dates">
                                <form>
                                        <div class="input-holder">
                                            <div class="form-group  mr-3 ">
                                                {
                                                    (isRecurring || isSpecific) ? 
                                                    <input type="text" className="form-control" size="40"
                                                    readOnly
                                                    value={!updateDateBool ? `${moment(schedule_data?.from, 'DD.MM.YYYY').format('Do MMM, ddd YYYY')}` :
                                                        `${selectStartDate}`}
                                                    // disabled={isEditSelectDate}
                                                    onClick={schedule_type === "date_range" ? dateRangeSelector : dateSelectHandler}
                                                /> :
                                                !isFlexible ?
                                                <input type="text" 
                                                readOnly
                                                className="form-control" size="40"
                                                    value={!updateDateBool ? `${moment(schedule_data?.from, 'DD.MM.YYYY').format('Do MMM, ddd YYYY')} - ${moment(schedule_data?.to, 'DD.MM.YYYY').format('Do MMM, ddd YYYY')}` : updatedDate()}
                                                    // disabled={isEditSelectDate}
                                                    onClick={schedule_type === "date_range" ? dateRangeSelector : dateSelectHandler}
                                                />: 
                                                <div className="checkout-flexiDates">
                                                    <input type="text" 
                                                    readOnly
                                                    className="form-control" size={25}
                                                        
                                                        value={!updateDateBool ? schedule_data?.length > 0 ? schedule_data?.slice(0,2)?.map(date=> ` ` + moment(date, 'DD-MM-YYYY').format('ddd-DD MMM')) : "" :
                                                        schedule_data?.length > 0 ?
                                                            selectFlexibleDate?.slice(0,2)?.map(date=> ` ` + moment(date, 'DD-MM-YYYY').format('ddd-DD MMM' ) ) : null
                                                        }
                                                    
                                                        onClick={schedule_type === "date_range" ? dateRangeSelector : dateSelectHandler}
                                                    />
                                                    {!updateDateBool ? schedule_data?.length > 2 ?
                                                        <span onClick={dateSelectHandler}>
                                                            +{schedule_data?.length - 2} more
                                                            </span> : 
                                                        null : selectFlexibleDate?.length > 2 ?
                                                        <span onClick={dateSelectHandler}>
                                                            +{selectFlexibleDate?.length - 2} more
                                                            </span> : 
                                                    null }
                                                </div>
                                                }
                                                
                                                <label for="">{SELECTED_DATE}</label>
                                                
                                            </div>

                                            {/* {
                                                isEditSelectDate ? <div class="edit" onClick={handleEditSelectDate}>
                                                    <img src={Edit} alt="" />
                                                </div> : <p className="save--btn" onClick={handleEditSelectDate}>Save</p>
                                            } */}
                                            {
                                                showCalenderModal && <CalenderModal
                                                    showMyModal={showCalenderModal}
                                                    handleDatesData={handleDatesData}
                                                    handleResAPIStartAndEndDate={handleResAPIStartAndEndDate}
                                                    isExperience={true}
                                                    isPackage={isPackage}
                                                    isSpecific={isSpecific}
                                                    isRecurring={isRecurring}
                                                    packageDays={days}
                                                    packageNights={nights}
                                                    value={!updateDateBool? schedule_data : selectFlexibleDate}
                                                    isFlexible={isFlexible}
                                                    prevStartDate={!updateDateBool? moment(schedule_data?.from, "DD-MM-YYYY").format('ddd-DD MMM YYYY'): selectStartDate}
                                                    prevEndDate={!updateDateBool ? moment(schedule_data?.to, "DD-MM-YYYY").format('ddd-DD MMM YYYY') : selectEndDate}
                                                    getSpecificDatesMonth={getSpecificDatesMonth}
                                                    recurringDaysList={dataChangedBoolVal
                                                        ? changeDataProceedToBook?.schedule_data?.newSelectedDyas
                                                        : initialPackageData !== undefined &&
                                                        initialPackageData?.schedule_data?.newSelectedDyas}
                                                    specificDaysList={dataChangedBoolVal
                                                            ? changeDataProceedToBook?.schedule_data?.multipleDates
                                                            : initialPackageData !== undefined &&
                                                            initialPackageData?.schedule_data?.multipleDates}       
                                                />
                                            }
                                            {
                                                showDateRangeModal && <HolidayRangePicker
                                                showMyModal={showDateRangeModal}
                                                handleDatesData={handleDatesData}
                                                handleResAPIStartAndEndDate={handleResAPIStartAndEndDate}
                                                prevStartDate={!updateDateBool? moment(schedule_data?.from, "DD-MM-YYYY").format('ddd-DD MMM YYYY'): selectStartDate}
                                                prevEndDate={!updateDateBool ? moment(schedule_data?.to, "DD-MM-YYYY").format('ddd-DD MMM YYYY') : selectEndDate}
                                                dateRangeMinDays={dateRangeMinDays}
                                                dateRangeMaxDays={dateRangeMaxDays}
                                                dateList={dataChangedBoolVal
                                                    ? changeDataProceedToBook?.schedule_data.selectedDates
                                                    : initialPackageData !== undefined &&
                                                    initialPackageData?.schedule_data.selectedDates}
                                                />
                                            }
                                        </div>
                                    </form>
                                    <form>
                                        <div class="input-holder">
                                            <div class="form-group  mr-3">
                                                <input type="text"
                                                readOnly
                                                 className="form-control" size={26}
                                                    // disabled={guestDisabled}
                                                    onClick={handleNumberOfGuests}
                                                    value={!updateGuestBool ?
                                                        adults !== 0 ||
                                                            children !== 0 ||
                                                            infants !== 0
                                                            ? getGuestCount(adults, children, infants) 
                                                            : ""
                                                        : getGuestCount(adult, updatedChildren, updatedInfants)
                                                    }
                                                    // onChange={emailChange}
                                                />
                                                <label for="">{PEOPLE}</label>
                                                {adultError && updatedChildrenError && updatedInfantsError ? <p style={{ color: 'red', fontSize: '12px' }}>{NUMBER_OF_GUESTS}</p> : adultError ? <p style={{ color: 'red', fontSize: '12px' }}>{CHILDREN_INFANT_ERROR}</p> : null}
                                            </div>

                                            {/* {
                                                guestDisabled ? <div class="edit" onClick={handleEditGuestClick}>
                                                    <img src={Edit} alt="" />
                                                </div> : <p className="save--btn" onClick={handleEditGuestClick}>Save</p>
                                            } */}
                                            {
                                                showGuestModal && <GuestsModal
                                                    showMyModal={showGuestModal}
                                                    guestDatahandler={guestDatahandler}
                                                    adultCount={!updateGuestBool ? adults : adult}
                                                    childrensCount={!updateGuestBool ? children : updatedChildren}
                                                    infantCount={!updateGuestBool ? infants : updatedInfants}
                                                />
                                            }

                                        </div>
                                    </form>

                                </div>
                            </div>
                            {/* <div >
                                <form className="edit-specific-request">
                                    <div className="form-group specific-request">
                                        <label for="">{SPECIFIC_REQUEST}</label>
                                        <div className="custom-request">
                                            <input type="text" className="w-100"
                                                value={!isEditSpecificRequest ? `${special_request}` : specificRequest}
                                                onChange={changeSpecialRequest}
                                                placeholder="Enter here"
                                            />

                                            <div class="form-line"></div>
                                        </div>
                                    </div>
                                </form>
                            </div> */}
                        </div>
                        <div className="col-12 col-lg-5 d-flex d-lg-block flex-column-reverse">
                            <div className={scroll ? "d-none" : "d-block"}>
                                <div className="mobile-buttons text-center">
                                    <div className="stcky-rightButton">
                                        <div className="custom-button" onClick={executeScroll}>
                                            <a >{isEnablePayment ? `Confirm` : `Submit`}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {isEnablePayment ? 
                            <div className="price-holder" ref={myRef}>
                                <h4>Price</h4>
                                <div className="price-line"></div>
                                <div className="total-cost">
                                    <div className="total">
                                        <p>Package amount</p>
                                    </div>
                                    <div className="amount">
                                        <p>Rs {(!dataChangedBoolVal ? initialPackageData?.points : changeDataProceedToBook?.points)?.toLocaleString('en-IN')}</p>
                                    </div>
                                </div>
                                <p className="note">Note :  Additional charges for extra adults/children.</p>
                                {points!==undefined?
                                  (  (points==0 && !isWipro) ? null :
                                        <div className="rewards-holder">
                                            <div className="my-rewards">
                                                <div >
                                                    <p className="rewards">My Reward Points</p>
                                                </div>
                                                <div>
                                                    <p className="pts">{`-${isRewardPointsBtnBool ? usedRewardPoints : 0}pts`}</p>
                                                </div>
                                            </div>
                                            <div className={classes.root}>
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item>
                                                    </Grid>
                                                    <Grid item xs>
                                                        <Slider
                                                            value={(typeof value === 'number' && isRewardPointsBtnBool) ? value : points > 0 ? 100 : 0}
                                                            onChange={handleSliderChange}
                                                            aria-labelledby="input-slider" disabled
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <p className="points-left">{isRewardPointsBtnBool ? pointsLeft : points} pts left</p>
                                                <div className="use-rewards">
                                                    <div>
                                                        <p className="reward-points">Use reward points</p>
                                                    </div>
                                                    <div>
                                                        <label className="switch">
                                                            <input type="checkbox" disabled={points==0} onChange={balanceUpdateHandler}
                                                                id="rewardPointsCheck"
                                                            />
                                                            <span className="slider round"></span>
                                                        </label>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                  ):null
                                }
                                <div className="balance-holder d-flex justify-content-between">
                                    <div>
                                        <p>Balance to be paid </p>
                                        {
                                            (isRewardPointsBtnBool && balance === 0) ? '' : <p>GST 5%</p>
                                        }

                                    </div>
                                    <div className="text-right">
                                        <p>Rs {parseFloat(!dataChangedBoolVal && !isRewardPointsBtnBool ? (initialPackageData?.points).toFixed(2) : dataChangedBoolVal && !isRewardPointsBtnBool ? (changeDataProceedToBook?.points).toFixed(2) : balance.toFixed(2)).toLocaleString('en-IN')}</p>
                                        {
                                            (isRewardPointsBtnBool && balance === 0) ? '' : <p> <span>+</span> Rs {parseFloat(!dataChangedBoolVal && !isRewardPointsBtnBool ? initialAmount.toFixed(2) : dataChangedBoolVal && !isRewardPointsBtnBool ? changedAmount.toFixed(2) : afterCheckedGST.toFixed(2)).toLocaleString('en-IN')}</p>
                                        }

                                    </div>
                                </div>
                                {
                                    (isRewardPointsBtnBool && balance === 0) ? null :
                                        <div className="balance-holder payment-mode-checkbox" >
                                            <p>Select payment mode</p>
                                            <div className="radio" >
                                                <label for="DC" className={radiovalue === 'DC' ? 'border-blue' : ''}>
                                                    <div className="check-btns">
                                                        <input type="radio" id="DC" name="payment_mode" value='DC'
                                                            onChange={paymentModeChange}
                                                            defaultChecked
                                                        />
                                                        <span class="checkmark"></span>
                                                        <div className="checkBtn-info">
                                                            <h5>Use Debit Card / UPI to pay</h5>
                                                            <p>Select this payment option</p>
                                                        </div>
                                                    </div>
                                                </label>
                                                <label for="CC" className={radiovalue === 'CC' ? 'border-blue' : ''}>
                                                    <div className="check-btns">
                                                        <input type="radio" id="CC" name="payment_mode" value='CC'
                                                            onChange={paymentModeChange}

                                                        />
                                                        <span class="checkmark"></span>
                                                        <div className="checkBtn-info">
                                                            <h5>Use Credit Card / Net Banking to pay</h5>
                                                            <p>Select this payment option</p>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>

                                        </div>
                                }

                                <button type="button" className="btn proceed-to-book w-100" href onClick={handleCheckout}>
                                    {
                                        (isRewardPointsBtnBool && balance === 0) ? 'Pay with points' : 'Checkout'
                                    } </button>
                            </div>: 
                                <div className="price-holder" ref={myRef}>
                                    {/* <h4>Price</h4>
                                    <div className="price-line"></div>
                                    <div className="total-cost">
                                                    <div className="total">
                                                        <p>Total cost</p>
                                                    </div>
                                                    <div className="amount">
                                                        <p>{!dataChangedBoolVal ? initialPackageData?.points : changeDataProceedToBook?.points}</p>
                                                    </div>
                                                </div>
                                                <p className="note">Note :  Additional charges for extra adults/children.</p> */}
                                                {/* <div className="mt-4 mb-4 line"></div> */}
                                    <button type="button" className="btn proceed-to-book w-100" href onClick={handleCheckout}>
                                        Submit
                                    </button>
                                </div>
                            }
                            <div className="login-success-popup" >
                                <Dialog
                                    open={showModal}
                                >
                                    <DialogTitle>
                                        <div className="success-holder text-center" ref={popupRef}>
                                            <div>
                                                <img src={mark} alt="successful" />
                                            </div>
                                            <div className="success-message">
                                                <h2 style={{textAlign: "center"}} className="mb-4">Request Received</h2>
                                                <p style={{fontWeight: "normal", fontSize: "18px"}}>Thank you for reaching out to us. Your enquiry has been received successfully. Our destination expert will reach out to you soon! Please make sure you have updated your contact information.</p>
                                                <button className="cursor-pointer btn-goto-home mt-0" onClick={() => history.push('/corporates')}>Continue exploring</button>
                                                    
                                            </div>

                                        </div>
                                    </DialogTitle>
                                </Dialog>
                            </div>
                            <UserDetails 
                            changeName={changeName}
                            emailChange={emailChange}
                            numberChange={numberChange} 
                            contactName={contact_name}
                            editName={editName} 
                            isEditName={isEditName}
                            contact_email={contact_email}
                            editEmail={editEmail}
                            isEditEmail={isEditEmail}
                            isEditNumber={isEditNumber}
                            editNumber={editNumber}
                            mobile={mobile}
                            editNumberError={editNumberError}
                            editEmailError={editEmailError}
                            editNameError={editNameError}
                            editEmailValidError={editEmailValidError}
                            nameInputRef={nameInputRef}
                            emailInputRef={emailInputRef}
                            nameUpdateSuccess={nameUpdateSuccess}
                            emailUpdateSuccess={emailUpdateSuccess}
                            setEditNumberError={setEditNumberError}
                            />
                            {/* <div className="buyer-details mt-4">
                                <h6>Our team will connect with you on below details</h6>
                                <form>
                                    <div class="input-holder">
                                        <div class="form-group  mr-3">
                                            <input type="text" className="form-control" 
                                                size={!isEditName ? contact_name?.length : editName?.length}
                                                value={!isEditName ? `${contactName}` : editName}
                                                disabled={disabled}
                                                onKeyPress={(event) => {
                                                    if (!/[a-zA-Z\s]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                onChange={changeName} />
                                            <label for="">Your Name</label>
                                            {
                                                editNameError ? <p style={{ color: 'red', fontSize: '12px' }}>Name is required</p> : ''
                                            }
                                        </div>

                                        {
                                            disabled ? <div class="edit" onClick={handleEditNameClick}>
                                                <img src={Edit} alt="" />
                                            </div> : <p className="save--btn" onClick={handleEditNameClick}>Save</p>
                                        }
                                    </div>
                                </form>
                                <form>
                                    <div class="input-holder">
                                        <div class="form-group  mr-3">
                                            <input type="text" className="form-control" size={contact_email?.length + 2}
                                                disabled={emailDisabled}
                                                value={!isEditEmail ? `${contact_email}` : editEmail}
                                                onChange={emailChange}
                                            />
                                            <label for="">Your Email id</label>
                                            {
                                                editEmailError ? <p style={{ color: 'red', fontSize: '12px' }}>Email Id is required</p> : ''
                                            }
                                            {
                                                editEmailValidError ? <p style={{ color: 'red', fontSize: '12px' }}>Please enter a valid email Id</p> : ''
                                            }

                                        </div>

                                        {
                                            emailDisabled ? <div class="edit" onClick={handleEditEmailClick}>
                                                <img src={Edit} alt="" />
                                            </div> : <p className="save--btn" onClick={handleEditEmailClick}>Save</p>
                                        }
                                    </div>
                                </form>
                                <IntlContactNumber disabled={true} mobile={isEditNumber?editNumber:mobile} numberChange={numberChange} hasError={editNumberError} />
                            </div> */}

                        </div>

                    </div>
                </div>

            </div>


            <ScrollToTop/>
            <Footer />
        </div>
    )
}

export default Checkout

