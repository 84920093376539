import {
    VOUCHER_CHECKOUT_DETAILS,
    VOUCHER_PAYMENT_DETAILS,
    BOOKING_STATUS,
    HOLIDAYS_CATEGORY,
    INITIAL_HOLIDAY_CATEGORY_ID,
    CATEGORY_ID,
    VISAS_AND_MORE,
    HOLIDAYS_LIST,
    SEARCH_HOLIDAYS_BY_LOCATION,
    HOLIDAY_PACKAGE_DETAILS,
    HOLIDAY_BOOKING_INFORMATION_DATA,
    CHANGE_DATA_FOR_PROCEED_TO_BOOK,
    DATA_CHANGED_BOOL_VAL,
    CHECKOUT_DETAILS,
    EDITED_CORP_HOLIDAY_NAME,
    EDITED_CORP_HOLIDAY_NAME_BOOL,
    EDITED_CORP_HOLIDAY_NUMBER,
    EDITED_CORP_HOLIDAY_NUMBER_BOOL,
    SIMILAR_HOLIDAYS,
    SELECTED_MONTHS,
    CUSTOMIZE_HOLIDAY,
    SET_SUCCESS,
    NEAR_BY_EXPERIENCES,
    MORE_HOLIDAYS,
    ROUTE_MESSAGE,
    SET_SCROLL,
    HOLIDAYS_PACKAGES_LIST,
    RESET_HOLIDAY_LIST,
    FILTER_VALUES,
    PAGE_SIZE,
    SET_SEARCH_VALUE,
    HOLIDAY_FILTER_VALUES,
    HOLIDAY_TESTIMONIALS,
    HOLIDAY_REVIEW_REFERENCE_ID,
    HOLIDAY_REVIEW_POST,
    HOLIDAY_REVIEW_LIST,
    HOLIDAY_REVIEWLIST_TOTAL_PAGE,
    HOLIDAY_REVIEWLIST_CURRENT_PAGE,
    HOLIDAY_ACTIVE_PACKAGE_INDEX
} from '../actions/holidaysActions'

const initialState = {
    cat_id: null,
    buyVoucherDetails: localStorage.getItem("voucherData") ? JSON.parse(localStorage.getItem("voucherData")) : [],
    voucherCheckoutDetails: localStorage.getItem("checkoutData") ? JSON.parse(localStorage.getItem("checkoutData")) : [],
    voucherPaymentDetails: {},
    booking_details: [],
    holiday_categories: [],
    visas_more: null,
    customize_holiday: null,
    holiday_packages: [],
    holiday_details: localStorage.getItem("packInfo") ? JSON.parse(localStorage.getItem("packInfo")) : null,
    booking_information_data: localStorage.getItem("bookData") ? JSON.parse(localStorage.getItem("bookData")) : [],
    change_data_to_proceed_to_book: localStorage.getItem("dataChanged") ? JSON.parse(localStorage.getItem("dataChanged")) : {},
    similar_events: [],
    data_changed_bool_val: localStorage.getItem("dataChangedBool") ? JSON.parse(localStorage.getItem("dataChangedBool")) : null,
    pacStartDate: null,
    pacEndDate: null,
    checkout_details: null,
    corpEditedHolidayName: null,
    corpEditedHolidayNameBool: false,
    corpEditedHolidayNumber: null,
    corpEditedHolidayNumberBool: false,
    selected_months: [],
    set_success: false,
    nearby_experiences: [],
    more_holidays: [],
    route_message: localStorage.getItem("holiday-section") === "" ? "" : localStorage.getItem("holiday-section"),
    set_Scroll: false,
    holiday_packages_list: [],
    filterValues: "",
    page_size: 1,
    searchValue: "",
    holiday_testimonial: {},
    referenceId:null,
    reviewStatus:null,
    reviewList:null,
    reviewListTotalPage:null,
    reviewListCurrentPage:null,
    activePackageIndex: 0
}

const holidaysReducers = (state = initialState, action) => {
    switch (action.type) {
        case 'BUY_VOUCHER':
            return {
                ...state,
                buyVoucherDetails: action.payload
            }
        case BOOKING_STATUS:
            return {
                ...state,
                booking_details: action.payload
            }
        case HOLIDAYS_CATEGORY:
            return {
                ...state,
                holiday_categories: action.payload
            }
        case CATEGORY_ID:
            return {
                ...state,
                cat_id: action.payload,
            }
        case INITIAL_HOLIDAY_CATEGORY_ID:
            return {
                ...state,
                cat_id: action.payload
            }
        case VISAS_AND_MORE:
            return {
                ...state,
                visas_more: action.payload,
            }
        case CUSTOMIZE_HOLIDAY:
            return {
                ...state,
                customize_holiday: action.payload,
            }
        case HOLIDAYS_LIST:
            return {
                ...state,
                holiday_packages: action.payload,
            }
        case RESET_HOLIDAY_LIST:
            return {
                ...state,
                holiday_packages_list: action.payload,
            }
        case HOLIDAYS_PACKAGES_LIST:
            return {
                ...state,
                holiday_packages_list: state.holiday_packages_list.concat(action.payload),
            }
        case HOLIDAY_PACKAGE_DETAILS:
            return {
                ...state,
                holiday_details: action.payload,
            }
        case SEARCH_HOLIDAYS_BY_LOCATION:
            return {
                ...state,
                holiday_packages: action.payload,
            }
        case VOUCHER_CHECKOUT_DETAILS:
            return {
                ...state,
                voucherCheckoutDetails: action.payload
            }
        case VOUCHER_PAYMENT_DETAILS:
            return {
                ...state,
                voucherPaymentDetails: action.payload
            }
        case CHECKOUT_DETAILS:
            return {
                ...state,
                checkout_details: action.payload,
            }
        case HOLIDAY_BOOKING_INFORMATION_DATA:
            return {
                ...state,
                booking_information_data: action.payload,
            }
        case CHANGE_DATA_FOR_PROCEED_TO_BOOK:
            return {
                ...state,
                change_data_to_proceed_to_book: action.payload,
            }
        case DATA_CHANGED_BOOL_VAL:
            return {
                ...state,
                data_changed_bool_val: action.payload,
            }
        case EDITED_CORP_HOLIDAY_NAME:
            return {
                ...state,
                corpEditedHolidayName: action.payload
            }
        case EDITED_CORP_HOLIDAY_NAME_BOOL:
            return {
                ...state,
                corpEditedHolidayNameBool: action.payload
            }
        case EDITED_CORP_HOLIDAY_NUMBER:
            return {
                ...state,
                corpEditedHolidayNumber: action.payload
            }
        case EDITED_CORP_HOLIDAY_NUMBER_BOOL:
            return {
                ...state,
                corpEditedHolidayNumberBool: action.payload
            }
        case SIMILAR_HOLIDAYS:
            return {
                ...state,
                similar_holidays: action.payload
            }
        case SELECTED_MONTHS:
            return {
                ...state,
                selected_months: action.payload
            }

        case SET_SUCCESS:
            return {
                ...state,
                set_success: action.payload
            }
        case NEAR_BY_EXPERIENCES:
            return {
                ...state,
                nearby_experiences: action.payload
            }
        case MORE_HOLIDAYS:
            return {
                ...state,
                more_holidays: action.payload
            }
        case ROUTE_MESSAGE:
            return {
                ...state,
                route_message: action.payload
            }
        case SET_SCROLL:
            return {
                ...state,
                set_Scroll: action.payload
            } 
        case HOLIDAY_FILTER_VALUES:
            return {
                ...state,
                filterValues: action.payload
            } 
        case PAGE_SIZE:
            return {
                ...state,
                page_size: action.payload
            }  
        case SET_SEARCH_VALUE:
            return {
                ...state,
                searchValue: action.payload
            }    
        case HOLIDAY_TESTIMONIALS:
            return {
                ...state,
                holiday_testimonial: action.payload
            } 
        case HOLIDAY_REVIEW_REFERENCE_ID:
            return {
                    ...state,
                    referenceId:action.payload
             }  
             case HOLIDAY_REVIEW_POST:
                return{
                    ...state,
                    reviewStatus:action.payload
                }
                case HOLIDAY_REVIEW_LIST:
                return{
                    ...state,
                    reviewList:action.payload
                }
                case HOLIDAY_REVIEWLIST_TOTAL_PAGE:
                    return{
                        ...state,
                        reviewListTotalPage:action.payload
                    }
               case HOLIDAY_REVIEWLIST_CURRENT_PAGE:
                return{
                    ...state,
                    reviewListCurrentPage:action.payload
                }
                case HOLIDAY_ACTIVE_PACKAGE_INDEX:
                    return {
                        ...state,
                        activePackageIndex: action.payload
                    } 
        default:
            return state
    }
}

export default holidaysReducers