
import React, { useState, useEffect } from 'react';
import Header from '../../../components/common/header';
import Footer from '../../../components/common/footer';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import backIcon from '../../../assets/images/common/back-icon.svg';
import '../../../assets/css/partials/common/checkout.scss';
import '../../../assets/css/partials/holidays/sendGift/buying.scss';
import * as HolidaysAction from "../../../redux/actions/holidaysActions";
import {PAYMENT_URL} from "../../../config/api";

function Buying() {

    const history = useHistory();
    const dispatch = useDispatch()

    const voucherCheckoutDetailsData = useSelector(state => state.holidays.voucherCheckoutDetails);

    const { amount, pack_title, pack_image, pack_description, specific_request, contact_name, contact_email, contact_mobile, recipient_name, recipient_email, recipient_mobile } = voucherCheckoutDetailsData;

    const voucherPaymentDetails = useSelector(state => state.holidays.voucherPaymentDetails);

    useEffect(() => {
        dispatch(HolidaysAction.handleCheckoutVoucher())

    }, []);

    /* clearing voucherData key's from 
  localStorage while redirecting to bank payments page to avoid any potential flaws */
    const clearVoucherData = () => {
        localStorage.removeItem('voucherData');
        localStorage.removeItem('checkoutData');
    }

    return (
        <div className="buying-holder">
            <div className="innerPage-header">
                <Header />
            </div>
            <div className="container innerPage-container">
                <a href onClick={() => history.goBack()}>
                    <div className="back-button">
                        <img src={backIcon} alt="" />
                    </div>
                </a>
                <div className="purchase-details">
                    <h1>Checkout</h1>
                    <div className="line"></div>
                    <div className="main-wrapper mb-5 pb-5">
                        <div className="left-wrapper">
                            <div className="wrapper">

                                <div className="buyer-details">
                                    <div className="recipient-form">
                                        <h6> Recipient’s details</h6>
                                        <form>
                                            <div class="input-holder">
                                                <div class="form-group">
                                                    <input type="text" className="form-control"
                                                        value={recipient_name}

                                                        placeholder="Name"
                                                    />
                                                    <label for="">Recipient’s Name</label>
                                                </div>

                                            </div>
                                        </form>
                                        <form>
                                            <div class="input-holder">
                                                <div class="form-group">
                                                    <input type="text" className="form-control"

                                                        value={recipient_email}

                                                        placeholder="Email"
                                                    />
                                                    <label for="">Recipient’s Email id</label>
                                                </div>

                                            </div>
                                        </form>
                                        <form>
                                            <div class="input-holder">
                                                <div class="form-group">
                                                    <input type="text" className="form-control"

                                                        value={recipient_mobile}
                                                        placeholder="Contact number"
                                                    />
                                                    <label for="">Recipient’s Contact number</label>
                                                </div>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="giftVoucher-holder voucher-selected">
                                    <div className="gift-voucher">
                                        <div className="img-wrapper">
                                            <img src={pack_image} alt="" className="voucher-image" />
                                        </div>
                                        <div className="voucher-content">
                                            <h2>{pack_title}</h2>
                                            <div className="line"></div>
                                            <p>{!pack_description ? null : pack_description}</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="right-wrapper">
                            <div className="price-holder" style={{ marginTop: '30px' }}>

                                <p>Please go to previous screen to change anything</p>
                                <div className="price-line"></div>
                                <div className="checkout-desk-button">
                                    <form action={PAYMENT_URL} method="post">
                                        <input type="hidden" name="firstname" value={voucherPaymentDetails?.firstname} />
                                        <input type="hidden" name="surl" value={voucherPaymentDetails?.surl} />
                                        <input type="hidden" name="key" value={voucherPaymentDetails?.key} />
                                        <input type="hidden" name="hash" value={voucherPaymentDetails?.hash} />
                                        <input type="hidden" name="curl" value={voucherPaymentDetails?.curl} />
                                        <input type="hidden" name="furl" value={voucherPaymentDetails?.furl} />
                                        <input type="hidden" name="txnid" value={voucherPaymentDetails?.txnid} />
                                        <input type="hidden" name="productinfo" value={voucherPaymentDetails?.productinfo} />
                                        <input type="hidden" name="amount" value={voucherPaymentDetails?.amount} />
                                        <input type="hidden" name="email" value={voucherPaymentDetails?.email} />
                                        <button type="submit" className="btn proceed-to-book w-100"
                                            onClick={clearVoucherData} >
                                            Pay Rs {voucherPaymentDetails?.amount?.toFixed(2)}
                                        </button>
                                    </form>
                                </div>

                            </div>
                            <br />
                            <div className="checkout-form">
                                <div className="buyer-details profile-details" style={{ flex: '1' }}>
                                    {/* <h6>Our team will connect with you on below details</h6> */}
                                    <form>
                                        <div class="input-holder">
                                            <div class="form-group">
                                                <input type="text" className="form-control"
                                                    value={contact_name}


                                                    placeholder="Name"
                                                />
                                                <label for="">Your Name</label>
                                            </div>

                                        </div>
                                    </form>
                                    <form>
                                        <div class="input-holder">
                                            <div class="form-group" style={{ width: '100%' }}>
                                                <input type="text" className="form-control"
                                                    value={contact_email}
                                                    placeholder="Email"

                                                />
                                                <label for="">Your Email id</label>
                                            </div>

                                        </div>
                                    </form>
                                    <form>
                                        <div class="input-holder">
                                            <div class="form-group">
                                                <input type="text" className="form-control"

                                                    value={contact_mobile}
                                                    placeholder="Contact number"
                                                />
                                                <label for="">Your Contact number</label>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="" >

                <div className="checkout-mobile-button">
                    <div className="button">
                        <form action={PAYMENT_URL} method="post">
                            <input type="hidden" name="firstname" value={voucherPaymentDetails?.firstname} />
                            <input type="hidden" name="surl" value={voucherPaymentDetails?.surl} />
                            <input type="hidden" name="key" value={voucherPaymentDetails?.key} />
                            <input type="hidden" name="hash" value={voucherPaymentDetails?.hash} />
                            <input type="hidden" name="curl" value={voucherPaymentDetails?.curl} />
                            <input type="hidden" name="furl" value={voucherPaymentDetails?.furl} />
                            <input type="hidden" name="txnid" value={voucherPaymentDetails?.txnid} />
                            <input type="hidden" name="productinfo" value={voucherPaymentDetails?.productinfo} />
                            <input type="hidden" name="amount" value={voucherPaymentDetails?.amount} />
                            <input type="hidden" name="email" value={voucherPaymentDetails?.email} />
                            <button type="submit" className="btn proceed-to-book w-100"
                                onClick={clearVoucherData}
                            >
                                Pay {voucherPaymentDetails?.amount?.toFixed(2)}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Buying