import React from "react";
import InfoCircle from "../../assets/icons/info_circle.svg";
import { IconButton } from "@material-ui/core";
import "../../assets/css/partials/common/priceNotes.scss";

const PriceNote = ({ note }) => {
  return (
    <div>
      <div class="notes mob-prevent" style={{ width: "100%" }}>
        <div class="row-note">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            <IconButton className="InfoCircle">
              <img src={InfoCircle} alt="InfoCircle" aria-hidden="true" />
            </IconButton>
          </div>
          <div style={{ marginLeft: "15px" }}>
            {<h4>Note</h4>}
            <p>{note}</p>
          </div>
        </div>
      </div>
      {/* Tag Mobile view*/}
      <div class="notes desk-prevent" style={{ width: "100%", height:"auto" }}>
        <div class="row-note" style={{flexDirection:"column"}}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "-17px",
              justifyContent:"flex-start"
            }}
          >
            <IconButton className="InfoCircle" >
              <img src={InfoCircle} alt="InfoCircle" aria-hidden="true" />
            </IconButton>
            <h4 style={{ marginLeft: "29px" }}>Note</h4>
          </div>
          <div style={{ marginLeft: "10px" }}>
            <p style={{ width: "auto" }}>{note}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceNote;
