import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../../assets/css/partials/weddings/weddings.scss"
import { Link } from "react-router-dom";
import { responsive } from '../../resources/carouselOptions';


const Destinations = ({ WEDDING_DESTINATIONS_LIST, tittle }) => {


    return (
        <>{WEDDING_DESTINATIONS_LIST && WEDDING_DESTINATIONS_LIST?.length > 0 &&
            <div className="slider-holder destination-holder mt-lg-5 mb-lg-5">
                <div className="container">
                    <div className="wedding-holder wedding-holder-mob">
                        <div className="wedding-info">
                            <h1>Our <span>{tittle} Destinations</span></h1>
                        </div>
                    </div>
                </div>
                <div className={`carousel-wrapper carousel-mobi-wrapper ${WEDDING_DESTINATIONS_LIST && WEDDING_DESTINATIONS_LIST?.length < 3 ? "carousel-center" : ""} mt-lg-3`}>
                    {WEDDING_DESTINATIONS_LIST && WEDDING_DESTINATIONS_LIST?.length > 0 &&
                        <OwlCarousel className="owl-theme" {...responsive}>
                            {
                                WEDDING_DESTINATIONS_LIST?.map((item) => (
                                    <div className="item ">
                                        <div className="cat-img">
                                            <div className="overlay"></div>
                                            <img src={item.image} alt={item.location} />
                                            <p>{item.location}</p>
                                        </div>
                                    </div>

                                ))
                            }
                        </OwlCarousel>}
                </div>
            </div>
        }
        </>
    )
}

export default Destinations