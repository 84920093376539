import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, { useEffect, useLayoutEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import "../../assets/css/partials/common/filterCards.scss";
import "../../assets/css/partials/corporates/corporatePackages.scss";
import * as CorporateAction from "../../redux/actions/corporatesAuthActions";
import LazyLoadingLoader from "../common/lazyLodaingLoader";
import SanitizedStayTag from "../common/sanitizedStayTag";
import SearchLocationAndPriceRange from "../common/searchLocationAndPriceRange";
import moment from "moment";
import { scheduleDuration, specificScheduleDuration } from '../utilities/utillFunc';
import { Helmet } from "react-helmet";


const owl_packages = {
  items: 1,
  margin: 5,
  nav: false,
  loop: true,
  dots: true,
  autoplay: true,
  smartSpeed: 500,
  animateIn: "fadeIn",
  animateOut: "fadeOut",
};

function CorporatePackages({ categoryData }) {
  const history = useHistory();

  const handlePackageDetails = (id) => {
    // history.push(`/corporates/package-details/${id}`);
    localStorage.removeItem("filteredList");
    // window.scrollTo(0, 0);
  };

  const handleGiftDetails = (packageInfo) => {
    // history.push(`/corporates/gift-voucher/${packageInfo.corp_id}`, packageInfo);
    // sessionStorage.setItem('packageInfo', JSON.stringify(packageInfo))
    localStorage.removeItem("filteredList");
  };

  const dispatch = useDispatch();
  const CORP_LIST = useSelector(
    (state) => state.corporates?.corporates_category.data
  );
  const PAGE_SIZE = useSelector((state) => state.corporates?.page_size);
  const CORPORATE_PACKAGES = useSelector(
    (state) => state.corporates?.corporates_packages.data
  );
  const SEARCH_VALUE = useSelector((state) => state.corporates?.searchValue);
  const FILTER_VALUES = useSelector((state) => state.corporates?.filterValues);
  const minVal = useSelector((state) => state.corporates?.filterValues?.minVal);
  const maxVal = useSelector((state) => state.corporates?.filterValues?.maxVal);
  const CORPORATE_ID = useSelector((state) => state.corporates?.cat_id);
  const CATEGORY_ID =
    localStorage.getItem("corporateCategory") !== "undefined"
      ? JSON.parse(localStorage.getItem("corporateCategory"))
      : CORPORATE_ID;
  const CORPORATE_PACKAGES_LIST = useSelector(
    (state) => state.corporates?.corporates_packages_list
  );
  const scrollCategory = localStorage.getItem("scrollactiveCorpCategory")
  const activeCorpCategory = localStorage.getItem("activeCorpCategory")
  const CORPORATE_PACKAGES_LIST_LENGTH = CORPORATE_PACKAGES_LIST?.length;
  const TOTAL_RESULTS = CORPORATE_PACKAGES?.listing?.total_results;
  const PAGE_SIZE_COUNT = CORPORATE_PACKAGES?.listing?.page_size;
  const CURRENT_PAGE = CORPORATE_PACKAGES?.listing?.current_page;
  const NEXT_PAGE = CORPORATE_PACKAGES?.listing?.next_page_id;
  const userInfo = JSON.parse(localStorage.getItem("userData"));
  const companyDomain = userInfo?.organisation?.email_domain;
  const isWipro = companyDomain === "wipro.com";
  const [changeCategory, setChangeCategory] = useState(CATEGORY_ID);
  let [page, setPage] = useState(1);
  const [search, setSearch] = useState(false);
  const [searchBool, setSearchBool] = useState(false);
  const [nextPage, setNextPage] = useState(NEXT_PAGE);
  const [loader, setLoader] = useState(false);
  const [loadMoreBtn, setLoadMoreBtn] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(
    history.location?.data?.bool
  );

  const [isBackFromDetailsPage, setIsBackFromDetailsPage] = useState(
    localStorage.getItem("goBackToCorp")
  );

  useEffect(() => {
    dispatch(CorporateAction.corporateCategories());
    dispatch(
      CorporateAction.setCategoryId(
        CATEGORY_ID ? CATEGORY_ID : CORP_LIST && CORP_LIST[0].id
      )
    );
  }, []);
  useEffect(() => {
    dispatch(
      CorporateAction.setCategoryId(
        CATEGORY_ID ? CATEGORY_ID : CORP_LIST && CORP_LIST[0].id
      )
    );
    if (CORP_LIST) {
      scrollToActiveSec()
      if(!activeCorpCategory){
        localStorage.setItem("activeCorpCategory",CORP_LIST[0].section_slug)
      }
    }
  }, [CORP_LIST]);

  useEffect(() => {
    if (
      CURRENT_PAGE == undefined &&
      CATEGORY_ID !== null &&
      CATEGORY_ID !== undefined
    ) {
      dispatch(
        CorporateAction.getCorporatePackages(CATEGORY_ID, 1, handleLoader)
      );
    }
    if (
      isFirstRender &&
      SEARCH_VALUE == "" &&
      CATEGORY_ID !== null &&
      CATEGORY_ID !== undefined
    ) {
      dispatch(
        CorporateAction.getCorporatePackages(CATEGORY_ID, 1, handleLoader)
      );
    }
  }, [CATEGORY_ID, searchBool]);

  useEffect(() => {
    if (history?.location?.fromViewMore?.bool) {
      dispatch(
        CorporateAction.getCorporatePackages(CATEGORY_ID, 1, handleLoader)
      );

    }

  }, []);
  const scrollToActiveSec = () => {
    let scrollContent = document.body.querySelector(`#filter-wrappers`);
    let activeLink = document.body.querySelector(`.active-category`);
    if (scrollContent && activeLink) {
      scrollContent.scrollLeft = activeLink.offsetLeft - 140;
    }
  };
  useEffect(() => {
    if (history?.location?.seeAll?.bool) {
      dispatch(CorporateAction.setCategoryId(CORP_LIST && CORP_LIST[0].id));
      dispatch(
        CorporateAction.getCorporatePackages(
          CORP_LIST && CORP_LIST[0].id,
          1,
          handleLoader
        )
      );
    }
  }, []);
  
  useLayoutEffect(() => {
    if (activeCorpCategory || CORP_LIST) {
    let active = activeCorpCategory 
      if (active == "holidays") {
        document.querySelector(`#Holidays`)?.classList.add("active-category-card");
      } else {
        document.querySelector(`#Holidays`)?.classList.remove("active-category-card");
      }
      CORP_LIST?.map((item) => {
        if (item?.section_slug == active) {
          document.querySelector(`#${item.section_slug}`)?.classList.add("active-category-card");
        } else {
          document.querySelector(`#${item.section_slug}`)?.classList.remove("active-category-card");
        }
      })

    }
  }, [activeCorpCategory, CORP_LIST, CORPORATE_PACKAGES_LIST])
  useEffect(() => {
    if (categoryData && categoryData?.id !== CORPORATE_ID) {

      handleCategories(categoryData.id, categoryData.name, categoryData.slug)
    }
  }, [categoryData])
  useEffect(() => {
    if (CORP_LIST || scrollCategory) {
      CORP_LIST?.map((item, idx) => {
        if (CATEGORY_ID == item?.id) {
          let owl = window.$('.owl-carousel')
          owl.owlCarousel();
          owl.trigger('to.owl.carousel', idx);
          setTimeout(() => localStorage.removeItem("scrollactiveCorpCategory"), 100)
        }
      })

    }
  }, [CORP_LIST, scrollCategory])
  const refreshPackages = () => { };

  const handleCategories = (id, name, slug) => {
    dispatch(CorporateAction.setCategoryId(id));
    localStorage.setItem("corporateCategory", id);
    dispatch(CorporateAction.setPageSize(1));
    setChangeCategory(name);
    localStorage.setItem("activeCorpCategory", slug)
    setIsBackFromDetailsPage(false);
    dispatch(CorporateAction.getCorporatePackages(id, 1, handleLoader));
    dispatch(CorporateAction.getFilterValues(""));
    dispatch(CorporateAction.getSearchValue(""));
    setIsFirstRender(false);
  };

  const handleFilteredPackage = (id) => {
    // history.push(`/corporates/package-details/${id}`);
    // window.scrollTo(0, 0);
    localStorage.setItem("filteredList", true);
  };

  const handleScroll = () => { };

  const fetchMorePackages = () => {
    setLoadMoreBtn(false);
    setLoader(true);
    if (
      (SEARCH_VALUE == undefined || SEARCH_VALUE == "") &&
      (maxVal == undefined || maxVal == "")
    ) {
      dispatch(
        CorporateAction.getCorporatePackages(
          CATEGORY_ID,
          NEXT_PAGE,
          handleLoader
        )
      );
      // console.log("list without price and location filter")
    } else if (
      (SEARCH_VALUE == undefined || SEARCH_VALUE == "") &&
      (maxVal !== undefined || maxVal !== "")
    ) {
      dispatch(
        CorporateAction.filterByPointsListing(
          CATEGORY_ID,
          SEARCH_VALUE,
          minVal,
          maxVal,
          NEXT_PAGE,
          handleLoader
        )
      );
      // console.log("filter with points only")
    } else if (
      (SEARCH_VALUE !== undefined || SEARCH_VALUE !== "") &&
      (maxVal == undefined || maxVal == "")
    ) {
      dispatch(
        CorporateAction.searchByLocation(
          CATEGORY_ID,
          SEARCH_VALUE,
          minVal,
          maxVal,
          NEXT_PAGE,
          handleLoader
        )
      );
      // console.log("filter with location only")
    } else {
      dispatch(
        CorporateAction.searchByLocation(
          CATEGORY_ID,
          SEARCH_VALUE,
          minVal,
          maxVal,
          NEXT_PAGE,
          handleLoader
        )
      );
      // console.log("filter with location and points")
    }
  };

  const handleLoader = () => { };

  const handleSearch = () => {
    dispatch(CorporateAction.setPageSize(page));
    setSearchBool(!searchBool);
  };

  useEffect(() => {
    if (NEXT_PAGE !== null) {
      setLoadMoreBtn(true);
    } else {
      setLoadMoreBtn(false);
      setLoader(false);
    }
  }, [NEXT_PAGE]);



  const getSpecificDatesMonth = (packageInfo) => {
    const months = [];
    packageInfo?.schedule_data?.multipleDates?.length > 0 &&
      packageInfo?.schedule_data?.multipleDates.map(date => {
        months.push(moment(date).format("MMM"));
      })
    const uniqueMonths = [... new Set(months)]
    const specificDatesMonths = uniqueMonths.map((item, index) => {
      return <span> {`${item}${uniqueMonths.length == index + 1 ? '' : ","} `}</span>
    })
    return specificDatesMonths

  }
  const calculateDays = (packageInfo) => {
    const arr = [];
    packageInfo?.schedule_data?.selectedDates?.length > 0
      && packageInfo?.schedule_data?.selectedDates?.map(date => {
        const startDate = moment(`${date.to}`, 'DD-MM-YYYY');
        const endDate = moment(`${date.from}`, 'DD-MM-YYYY');
        const result = endDate.diff(startDate, 'days');
        arr.push(Math.round(Math.abs(result) + 1));
      });

    const min = Math.min(...arr);
    const max = Math.max(...arr);

    if (min !== max) {
      return <span>Min {min} {min == 1 ? 'day' : 'days'}, Max {max} {max == 1 ? 'day' : 'days'}</span>
    } else {
      return <span> Max {max} {min == 1 ? 'day' : 'days'}</span>
    }

  }

  return (
    <div>
      <Helmet>
        <title>Corporate Deals - Hammock Holidays</title>
        <meta
          name="description"
          content="Employee Recognition Programs Inspiring Rewards – Inspiring Performance"
        />
      </Helmet>
      <div className={`filter-holder corporate-category-filter ${CORP_LIST?.length>5?'d-lg-none':''}`}>
        <div
          id="filter-wrappers"
          className={
            CORP_LIST && CORP_LIST.length <= 4
              ? "text-center filter-wrapper-mobi"
              : "filter-wrapper"
          }
        >
          {CORP_LIST &&
            CORP_LIST.length > 0 &&
            CORP_LIST.map((item,idx) => (
              // <Link to={`/corporates/${item.section_slug}`}>
              <div
                className={
                  CATEGORY_ID === item.id
                    ? "filter-card active-category"
                    : "filter-card"
                }
                key={idx}
                onClick={
                  CATEGORY_ID !== item.id
                    ? () => handleCategories(item.id, item?.name)
                    : null
                }
                id={item.name}
              >
                <div className="img-gradient">
                  <div className="cat-img">
                    <img src={item.image} alt={item.name} />
                    <div class="pseudo"></div>
                  </div>
                  <div className="card-info">
                    <p>{item.name}</p>
                  </div>
                </div>
              </div>
              // {/* </Link> */}
            ))}
          <div className="px-3"></div>
        </div>
      </div>
      <div className="container">
        {CORPORATE_PACKAGES && CORPORATE_PACKAGES?.gift_voucher === true ? (
          ""
        ) : (
          <SearchLocationAndPriceRange
            changeCategory={changeCategory}
            handleSearch={handleSearch}
            page={PAGE_SIZE}
            categoryID={CATEGORY_ID}
            handleLoader={handleLoader}
            fromCorporate={true}
            refreshPackages={refreshPackages}
            secName={CORPORATE_PACKAGES_LIST && CORPORATE_PACKAGES_LIST[0]?.sec_name}
          />
        )}
        {CORPORATE_PACKAGES_LIST?.length > 0 ? (
          <div className="corporatePackages-holder ">
            {CORPORATE_PACKAGES_LIST &&
              CORPORATE_PACKAGES_LIST.length > 0 &&
              CORPORATE_PACKAGES_LIST.map((packageInfo, index) => {
                return (
                  // corporates/gift-voucher
                  // <Link to={`/corporates/package-details/${packageInfo.corp_id}`}>
                  <Link
                    to={
                      CORPORATE_PACKAGES &&
                        CORPORATE_PACKAGES?.gift_voucher === true
                        ? {
                          pathname: `/corporates/gift-voucher/${packageInfo.corp_slug}`,
                          state: packageInfo,
                        }
                        : {
                          pathname: `/corporates/package-details/${packageInfo.corp_slug}`,
                          state: { id: packageInfo.id }
                        }
                    }
                  >
                    <div
                      style={{ cursor: "pointer" }}
                      key={packageInfo?.id}
                      className="package"
                      packageDetailsPage={packageInfo?.corp_id}
                      onClick={() =>
                        CORPORATE_PACKAGES &&
                          CORPORATE_PACKAGES?.gift_voucher === true
                          ? handleGiftDetails(packageInfo)
                          : CORPORATE_PACKAGES?.filter_data
                            ? handleFilteredPackage(packageInfo?.id)
                            : handlePackageDetails(packageInfo?.corp_id)
                      }
                    >
                      <div className="row overflow-hidden w-100-vw">
                        <div className="col-12 col-lg-5 pr-lg-0">
                          {packageInfo?.image.length === 1 ? (
                            <div className="package-image">
                              <img
                                src={packageInfo?.image}
                                className="w-100"
                                alt={CORPORATE_PACKAGES &&
                                  CORPORATE_PACKAGES.gift_voucher === true
                                  ? packageInfo?.title
                                  : CORPORATE_PACKAGES?.filter_data ? packageInfo?.pack_title : packageInfo?.title}
                              />
                            </div>
                          ) : (
                            <OwlCarousel
                              className="owl-theme owl-corporate"
                              {...owl_packages}
                            >
                              {packageInfo?.image.map((images,idx) => {
                                if (images) {
                                  return (
                                    <div className="item">
                                      <div className="package-image">
                                        <img
                                          src={images}
                                          className={
                                            CORPORATE_PACKAGES &&
                                              CORPORATE_PACKAGES?.gift_voucher ===
                                              true
                                              ? "object-fill w-100"
                                              : "w-100"
                                          }
                                          alt={`${CORPORATE_PACKAGES &&
                                            CORPORATE_PACKAGES.gift_voucher === true
                                            ? packageInfo?.title
                                            : CORPORATE_PACKAGES?.filter_data ? packageInfo?.pack_title : packageInfo?.title} ${idx+1}` }
                                        />
                                      </div>
                                    </div>
                                  );
                                }
                              })}
                            </OwlCarousel>
                          )}
                        </div>
                        <div className="col-12 col-lg-7 pl-lg-0">
                          <div className="package-details">
                            <div
                              className="d-flex"
                              style={{ flexWrap: "wrap" }}
                            >
                              {packageInfo &&
                                packageInfo.covid_safe === true ? (
                                <>
                                  <SanitizedStayTag />
                                </>
                              ) : null}

                              <div className="bestseller-tag">
                                {packageInfo?.best_seller ? (
                                  <span>BEST SELLER</span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>

                            <h4>
                              {CORPORATE_PACKAGES &&
                                CORPORATE_PACKAGES.gift_voucher === true
                                ? packageInfo?.title
                                : CORPORATE_PACKAGES?.filter_data ? packageInfo?.pack_title : packageInfo?.title}
                              {packageInfo?.opening_soon !== null ?
                                <span className="workshop-type text-center mb-0" style={{ textTransform: 'uppercase' }}>REOPENING ON {moment(packageInfo?.opening_soon).format('MMM, YYYY')}</span> : ''

                              }
                              {CORPORATE_PACKAGES &&
                                CORPORATE_PACKAGES.gift_voucher === true ? (
                                <span>GIFT VOUCHER </span>
                              ) : (
                                ""
                              )}
                            </h4>

                            <div className="info">
                              {CORPORATE_PACKAGES &&
                                CORPORATE_PACKAGES.gift_voucher === true ? (
                                <p></p>
                              ) : (
                                <>{packageInfo?.sec_name === "International" ?
                                <p>{packageInfo?.location_country ? packageInfo?.location_country : ''}</p> :
                                <p>{packageInfo?.location?.length > 1 ? `${(packageInfo?.location_city || packageInfo?.location?.[0]?.city)}, ${packageInfo?.location?.[1]?.address}` :
                                `${(packageInfo?.location_city || packageInfo?.location?.[0]?.city)}${(packageInfo?.location?.[0]?.state?",":"")} ${(packageInfo?.location?.[0]?.state)}`
                            }</p>
                              }</>
                              )}
                              <div className="line"></div>
                              {CORPORATE_PACKAGES &&
                                CORPORATE_PACKAGES.gift_voucher === true ? (
                                <p className="mb-2">
                                  Valid till :{" "}
                                  {moment(packageInfo?.valid_till).format(
                                    "Do MMMM, YYYY"
                                  )}
                                </p>
                              ) : !packageInfo?.flexi_schedule ? (
                                <>
                                  {packageInfo?.schedule_type === "package" ?
                                    <p>
                                      {packageInfo?.schedule_data?.days}{" "}
                                      {packageInfo?.schedule_data?.days == 1
                                        ? "Day"
                                        : "Days"}{" "}
                                      {packageInfo?.schedule_data?.nights}{" "}
                                      {packageInfo?.schedule_data?.nights == 1
                                        ? "Night"
                                        : "Nights"}
                                    </p> : ""
                                  }

                                  {
                                    packageInfo?.schedule_type === "recurring" ?
                                      <p>Every
                                        {packageInfo?.schedule_data?.newSelectedDyas?.length > 0 ?
                                          packageInfo?.schedule_data?.newSelectedDyas.map((index) => {
                                            switch (index) {
                                              case 0:
                                                return <span> Sun</span>
                                              case 1:
                                                return <span>, Mon</span>
                                              case 2:
                                                return <span>, Tue</span>
                                              case 3:
                                                return <span>, Wed</span>
                                              case 4:
                                                return <span>, Thu</span>
                                              case 5:
                                                return <span>, Fri</span>
                                              case 6:
                                                return <span>, Sat</span>
                                            }
                                          }) : ""
                                        }
                                        {packageInfo?.schedule_data?.recurringHours || packageInfo?.schedule_data?.recurringMinutes ? ` -` : ``}
                                        {packageInfo?.schedule_data?.recurringHours !== "0" && packageInfo?.schedule_data?.recurringHours !== undefined ?
                                          packageInfo?.schedule_data?.recurringHours > 1 ? ` ${packageInfo?.schedule_data?.recurringHours} hrs ${packageInfo?.schedule_data?.recurringMinutes ? '' : '/day'}` : ` ${packageInfo?.schedule_data?.recurringHours} hr ${packageInfo?.schedule_data?.recurringMinutes ? '' : '/day'}` : ""}
                                        {packageInfo?.schedule_data?.recurringMinutes !== (0 || undefined) ?
                                          packageInfo?.schedule_data?.recurringMinutes > 1 ? `  ${packageInfo?.schedule_data?.recurringMinutes} mins/day` : `  ${packageInfo?.schedule_data?.recurringMinutes} min/day` : ""}
                                      </p> : ""
                                  }
                                  {
                                    packageInfo?.schedule_type === "specific_dates" ?
                                      <p>In the month of
                                        {getSpecificDatesMonth(packageInfo)} {specificScheduleDuration("", "", packageInfo)}
                                      </p> : ""
                                  }
                                  {
                                    packageInfo?.schedule_type === "date_range" ?
                                      <p>
                                        {calculateDays(packageInfo)}
                                      </p> : ""
                                  }
                                </>
                              ) : (
                                <p>Flexible Days</p>
                              )}
                              <p>{packageInfo.brief_description}</p>
                            </div>
                            {CORPORATE_PACKAGES.gift_voucher === true ? (
                              <div className="gift-points d-flex">
                                {isWipro ? (
                                  <div class="total-points">
                                    {packageInfo.min_points} -{" "}
                                    {packageInfo.max_points} pts
                                  </div>
                                ) : (
                                  <div class="total-points">
                                    Rs {packageInfo.min_points} -{" "}
                                    {packageInfo.max_points}{" "}
                                  </div>
                                )}
                                <div className="voucher">Buy Voucher</div>
                              </div>
                            ) : (
                              <div className="points">
                                {isWipro ? (
                                  <p>{(packageInfo.points).toLocaleString('en-IN')} pts</p>
                                ) : (
                                  <p>Rs {(packageInfo.points).toLocaleString('en-IN')}</p>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              })}
            {loadMoreBtn ? (
              <div className="text-center">
                <button
                  type="submit"
                  class="load-more-btn"
                  onClick={() => fetchMorePackages()}
                >
                  <span>Load more...</span>
                </button>
              </div>
            ) : loader ? (
              <LazyLoadingLoader />
            ) : null}
          </div>
        ) : (
          <p className="text-center">
            Currently no packages available for selected category
          </p>
        )}
      </div>
    </div>
  );
}

export default CorporatePackages;
