import React from 'react'

const Experts = ({mobile3,wedding3,handleForm,section}) => {
  return (
  
        <div className='container wedding-exports'>
            <div className="wedding-holder wedding-holder-mob  mt-lg-5 mb-lg-5 ">
                <div className="about d-flex flex-wrap">
                    <div className="mobiImages d-flex d-md-none">
                        <div>
                            <img src={mobile3} alt={section} />
                        </div>
                    </div>
                    <div className="d-md-flex d-none d-md-block">
                        <div className="img1" style={{marginBottom:"10px"}}>
                            <img src={wedding3} alt={section}/>
                        </div>
                    </div>
                    <div className="second-container container">
                        <div className="wedding-info my-family-holder">
                            <h1>Our team of experts is ready to make it an unforgettable experience for you and your partner.</h1>
                        </div>
                        <div>
                            <div  onClick={handleForm} className="learn-more-holder d-none d-md-block">
                                <button className="btn btn-learn-more w-100">Book Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       
</div>
  )
}

export default Experts