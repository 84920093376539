export const BASE_URL = "https://hammockbed.appinessworld.com"; // test server
// export const BASE_URL = "https://hammockstagbed.appinessworld.com"; // staging server

export const PAYMENT_URL_TEST = "https://test.payu.in/_payment"; // test server payment gateway
export const PAYMENT_URL = "https://secure.payu.in/_payment"; // live server payment gateway

// live payment keys
export const CC_PAYMENT_KEY = process.env.REACT_APP_PAYMENT_CC_KEY;
export const DC_PAYMENT_KEY = process.env.REACT_APP_PAYMENT_DC_KEY;

// test payment key
export const TEST_PAYMENT_KEY = process.env.REACT_APP_PAYMENT_TEST_KEY;

//subscribe key
export const SUBSCRIBE_KEY = process.env.REACT_APP_SUBSCRIBE_KEY;


export const API = {
  RESEND_OTP: `${BASE_URL}/user/account/resend-otp/`,
  CORPORATE_ENQUIRY: `${BASE_URL}/user/corporate/enquiry/`,
  CORPORATE_CATEGORY_LIST: `${BASE_URL}/user/corporate/listing/section/`,
  CORPORATE_PACKAGES_LIST: `${BASE_URL}/user/corporate/listing/list/`,
  CORPORATE_PACKAGE_DETAILS_API: `${BASE_URL}/user/corporate/listing/detail/`,
  CORPORATE_FILTERED_PACKAGE_DETAILS_API:`${BASE_URL}/user/corporate/listing/pack-detail/`,
  CORPORATE_TESTIMONIALS: `${BASE_URL}/user/corporate/testimonial/list/`,
  CORPORATE_REVIEW_POST_CHECK:`${BASE_URL}/user/corporate/review-post-check/`,
  CORPORATE_REVIEW_POST:`${BASE_URL}/user/corporate/add-review/`,
  CORPORATE_REVIEW_LIST:`${BASE_URL}/user/corporate/review/list/`,
  CORPORATE_LOGIN: `${BASE_URL}/user/account/corp-login-verify/`,
  CORPORATE_FILTER: `${BASE_URL}/user/corporate/listing/filter/`,
  GET_SIMILAR_OFFERS: `${BASE_URL}/user/corporate/listing/similar-offers/`,
  SIMILAR_PACKAGE_GIFTS_API: `${BASE_URL}/user/corporate/listing/similar-offers-gift/`,
  SEARCH_BY_LOCATION: `${BASE_URL}/user/corporate/listing/search/`,
  GET_INTERNAL_PACK: `${BASE_URL}/user/internalpack/get-pack-details/`,
  // SEARCH_BY_LOCATION: `${BASE_URL}/user/corporate/listing/search/`,
  CORPORATE_BOOKING_CHECKOUT: `${BASE_URL}/user/corporate/booking/corp-booking/`,
  INTRNAL_PACKAGE_GATEWAY:`${BASE_URL}/user/payment/internal-pack-gateway/`,
  INTERNAL_PACKAGE_BOOKING:`${BASE_URL}/user/internalpack/package-booking/`,
  CORPORATE_CHECK_AVAILABILITY: `${BASE_URL}/user/corporate/check-availability/corp/`,
  CORPORATE_BOOKING_PAYMENT_GATEWAY: `${BASE_URL}/user/payment/payment-gateway/`,
  EXPERIENCE_FEATURED_LIST: `${BASE_URL}/user/experience/home/list/`,
  // Locations list
  LOCATIONS_LIST: `${BASE_URL}/user/corporate/listing/location/`,
  COUNTRY_LIST: `${BASE_URL}/user/corporate/country-list/`,
  HOLIDAYS_LOCATIONS_LIST: `${BASE_URL}/user/holidays/location-list/`,
  HOLIDAYS_COUNTRY_LIST: `${BASE_URL}/user/holidays/country-list/`,
  EXPERIENCE_LOCATIONS_LIST: `${BASE_URL}/user/experience/location-list/`,
  HOLIDAY_FEATURED_LIST: `${BASE_URL}/user/holidays/home/list/ `,
  SUBSCRIBE_US: `${BASE_URL}/user/account/subscribe-us/ `,
  // My Family First
  GIFTREQUEST_ENQUIRY: `${BASE_URL}/user/gifts/my-family-first/`,
  ANNIVERSARY_ENQUIRY: `${BASE_URL}/user/anniversary/request-for-anniversary/`,
  HONEYMOON_ENQUIRY:`${BASE_URL}/user/honeymoon/request-for-honeymoon/`,
  STORIES_LIST_API: `${BASE_URL}/user/gifts/my-family-first/story-list/`,
  GET_HONEYMOON_DESTINATION_LIST:`${BASE_URL}/user/anniversary/listAnniversaryDestinations/`,
  GET_HONEYMOON_STORIES:`${BASE_URL}/user/anniversary/listStorys/`,
  //My Weddings
  WEDDING_STORIES_LIST_API:`${BASE_URL}/user/weddings/listStorys/`,
  WEDDING_DESTINATIONS_LIST_API:`${BASE_URL}/user/weddings/listWeddingDestinations/`,
  BOOK_WEDDINGS_API:`${BASE_URL}/user/weddings/request-for-wedding/`,

  // Visas & more API
  VISA_CALLBACK: `${BASE_URL}/user/holidays/visa-request/`,

  // Send a Gift Voucher
  VOUCHER_PIC_UPLOAD: `${BASE_URL}/user/holidays/upload-image/`,

   // Holidays

  HOLIDAYS_CATEGORY_LIST: `${BASE_URL}/user/holidays/section-list/`,
  HOLIDAYS_PACKAGES_LIST: `${BASE_URL}/user/holidays/listing-list/`,
  SEARCH_HOLIDAYS_BY_LOCATION: `${BASE_URL}/user/holidays/listing-search/`,
  HOLIDAY_PACKAGE_DETAILS_API: `${BASE_URL}/user/holidays/listing-detail/`,
  HOLIDAY_FILTERED_PACKAGE_DETAILS_API: `${BASE_URL}/user/holidays/listing/pack-detail/`,
  CORP_BOOKING_WITH_POINTS: `${BASE_URL}/user/holidays/booking/corp-points/`,
  HOLIDAY_CORPORATE_PAYMENT: `${BASE_URL}/user/payment/holiday-corp-gateway/`,
  HOLIDAY_NON_CORPORATE_PAYMENT: `${BASE_URL}/user/payment/holiday-user-gateway/`,
  SIMILAR_HOLIDAYS_API: `${BASE_URL}/user/holidays/similar-offer/`,
  INTERNATIONAL_REQUEST: `${BASE_URL}/user/holidays/international-request/`,
  CUSTOMIZE_REQUEST: `${BASE_URL}/user/holidays/custom-request/`,
  HOLIDAYS_NEARBY_EXPERIENCES: `${BASE_URL}/user/holidays/near-by-experience/`,
  HOLIDAYS_NEARBY_PACKAGES: `${BASE_URL}/user/holidays/near-by-holiday/`,
  HOLIDAYS_AVAILABILITY_REQUEST_CORP: `${BASE_URL}/user/holidays/check-availability/corp/`,
  HOLIDAYS_AVAILABILITY_REQUEST_NON_CORP: `${BASE_URL}/user/holidays/check-availability/user/`,
  HOLIDAYS_TESTIMONIALS: `${BASE_URL}/user/holidays/testimonial/list/`,
  HOLIDAYS_REVIEW_POST_CHECK:`${BASE_URL}/user/holidays/review-post-check/`,
  HOLIDAYS_REVIEW_POST:`${BASE_URL}/user/holidays/add-review/`,
  HOLIDAYS_REVIEW_LIST:`${BASE_URL}/user/holidays/review/list/`,
  //send a gift payment gateway
  GIFT_VOUCHER_PAYMENT: `${BASE_URL}/user/payment/gift-payment/`,
  GIFT_VOUCHER_CORP_PAYMENT: `${BASE_URL}/user/payment/send-gift-corp-payment/`,
  GIFT_VOUCHER_NON_CORP_PAYMENT: `${BASE_URL}/user/payment/send-gift-user-payment/`,
  GIFT_VOUCHER_CORP_ONLY_POINTS: `${BASE_URL}/user/holidays/send-gift-corp-booking/`,

  CONTACT_US: `${BASE_URL}/user/account/contact-us/`,

  // User profile API's

  GET_PROFILE_DETAILS: `${BASE_URL}/user/corporate/get-profile/`,
  ADD_ADDRESS: `${BASE_URL}/user/corporate/create-address/`,
  UPDATE_ADDRESS: `${BASE_URL}/user/corporate/update-del-address/`,
  DELETE_ADDRESS: `${BASE_URL}/user/corporate/update-del-address/`,
  UPDATE_PROFILE: `${BASE_URL}/user/corporate/update-profile/`,
  CORPORATE_NEARBY_EXPERIENCES: `${BASE_URL}/user/corporate/near-by-experience/`,
  CORPORATE_NEARBY: `${BASE_URL}/user/corporate/near-by-corporate/`,
  PROFILE_PIC_UPLOAD: `${BASE_URL}/user/corporate/profile/image-upload/`,
  CORPORATE_FEATURED_LIST: `${BASE_URL}/user/corporate/listing/home-page/`,
  GET_BOOKINGS_API: `${BASE_URL}/user/corporate/profile/bookings/`,
  UPLOAD_RESUME:`${BASE_URL}/career/asset-upload/`,
  CAREERS_REQUEST:`${BASE_URL}/career/career-request/`,
  // non corporate use profile
  GET_NON_CORP_PROFILE_DETAILS: `${BASE_URL}/user/account/user-profile/`,
  GET_NON_CORP_BOOKINGS_API: `${BASE_URL}/user/account/profile-booking/`,
  UPDATE_NON_CORP_PROFILE: `${BASE_URL}/user/account/profile-update/`,
  CORP_USER_OTP_VERIFY: `${BASE_URL}/user/account/profile-mobile-verify/`,
  UPDATE_MOBILE: `${BASE_URL}/user/account/profile-mobile-update/`,

  // Events API's
  EVENTS_CATEGORY_LIST: `${BASE_URL}/user/events/section/list/`,
  EVENTS_PACKAGES_LIST: `${BASE_URL}/user/events/list/`,
  EVENTS_PACKAGE_DETAILS_API: `${BASE_URL}/user/events/detail/`,
  EVENT_BOOKING_CHECKOUT: `${BASE_URL}/user/events/booking/corp-booking/`,
  EVENT_FREE_BOOKING: `${BASE_URL}/user/events/booking/free-booking/`,
  EVENT_BOOKING_PAYMENT_GATEWAY: `${BASE_URL}/user/payment/event-corp-gateway/`,
  EVENT_USER_BOOKING_PAYMENT_GATEWAY: `${BASE_URL}/user/payment/event-user-gateway/`,
  SIMILAR_EVENTS_API: `${BASE_URL}/user/events/similar-events/`,
  EVENTS_TESTIMONIALS: `${BASE_URL}/user/events/testimonial/list/`,

  // Experience API's

  EXPERIENCE_CATEGORY_LIST: `${BASE_URL}/user/experience/section-list/`,
  EXPERIENCES_PACKAGES_LIST: `${BASE_URL}/user/experience/listing-list/`,
  EXPERIENCE_PACKAGE_DETAILS_API: `${BASE_URL}/user/experience/listing-detail/`,
  EXPERIENCE_FILTERED_PACKAGE_DETAILS_API: `${BASE_URL}/user/experience/listing/pack-detail/`,
  EXPERIENCE_NEAR_BY_PACKAGE_DETAILS_API: `${BASE_URL}/user/experience/listing/pack-detail/`,
  EXPERIENCE_BOOKING_WITH_POINTS: `${BASE_URL}/user/experience/booking/corp-points/`,
  EXPERIENCE_CORPORATE_PAYMENT: `${BASE_URL}/user/payment/experience-corp-gateway/`,
  EXPERIENCE_NON_CORPORATE_PAYMENT: `${BASE_URL}/user/payment/experience-user-gateway/`,
  SIMILAR_PACKAGE_EXPERIENCES: `${BASE_URL}/user/experience/similar-offer/`,
  NEARBY_PACKAGE_EXPERIENCES: `${BASE_URL}/user/experience/near-by/`,
  MORE_PACKAGE_EXPERIENCES: `${BASE_URL}/user/experience/near-by/`,
  EXPERIENCE_AVAILABLILITY_REQUEST_CORP: `${BASE_URL}/user/experience/check-availability/corp/`,
  EXPERIENCE_AVAILABLILITY_REQUEST_NON_CORP: `${BASE_URL}/user/experience/check-availability/user/`,
  EXPERIENCE_TESTIMONIALS: `${BASE_URL}/user/experience/testimonial/list/`,
  SEARCH_EXPERIENCES_BY_LOCATION: `${BASE_URL}/user/experience/listing-search/`,
  EXPERIENCE_REVIEW_POST_CHECK:`${BASE_URL}/user/experience/review-post-check/`,
  EXPERIENCE_REVIEW_POST:`${BASE_URL}/user/experience/add-review/`,
  EXPERIENCE_REVIEW_LIST:`${BASE_URL}/user/experience/review/list/`,

  // Global search
  GLOBAL_SEARCH_API: `${BASE_URL}/user/account/global-search/`,

};

