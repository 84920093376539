import { data } from 'jquery';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { BsArrowRight } from "react-icons/bs";
const ItineraryPackageDates = ( {packageData,setShowForm,handleShowForm,calculateDaysProps}) => {
  const [filtersortedDate, setFiltersortedDate] = useState([])



  useEffect(() => {
    if(packageData){
      let resDate = packageData.filter(d => {
          return moment(d.from, 'DD-MM-YYYY').isAfter(); ;
      });
      resDate.sort((a, b) => moment(a.from, "DD-MM-YYYY").valueOf() - moment(b.from, "DD-MM-YYYY").valueOf());
      setFiltersortedDate(resDate)
    }
  }, [packageData]);

  return (
  
    <div>
    
      {filtersortedDate&&filtersortedDate.map((date, i) => (
        
                  <div className="Dates-Wrapper d-flex">
                   
                    <div className="Dates-Duration d-flex">
                      <div className="d-flex flex-column Dates-Duration-dates">
                        <p className="day">{moment(date?.from,"DD-MM-YYYY").format("dddd")}</p>
                        <p className="date">{moment(date?.from,"DD-MM-YYYY").format('DD MMM YYYY')}</p>
                      </div>
                      <div>
                        <BsArrowRight />
                      </div>
                      <div className="d-flex flex-column Dates-Wrapper-dates">
                        <p className="day">{moment(date?.to,"DD-MM-YYYY").format("dddd")}</p>
                        <p className="date">{moment(date?.to,"DD-MM-YYYY").format('DD MMM YYYY')}</p>
                      </div>
                    </div>
                    <div>
                      <button onClick={()=>handleShowForm(date?.from,date?.to)} className=" btn Dates-Wrapper-btn">
                        Contact Tour Planner
                      </button>
                    </div>
                  </div>
                ))}
                {filtersortedDate.length==0&&
                 <div className="Dates-Wrapper d-flex">
                   <div className="Date-range">
                    {calculateDaysProps()}
                    </div>
                    <div>
                      <button onClick={()=>handleShowForm()} className="btn Dates-Wrapper-btn">
                        Contact Tour Planner
                      </button>
                    </div>
                  </div>
                }
    </div>
      
 
  )
}

export default ItineraryPackageDates