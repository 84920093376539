import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../../assets/css/partials/common/filterCards.scss";
import "../../assets/css/partials/corporates/corporates.scss";
import Login from "./corpLogin";
import OwlCarousel from "react-owl-carousel";
import Employer from "./employerForm";
import EmployerModal from "./employerModal";
import Category from "../common/category";
import CategoryDetails from "./categoryDetails";
import CategoryImages from "./categoryImages";
import { useHistory } from "react-router";
import {
  useLocation
} from "react-router-dom";

import Clients from "./clients";
import About1 from '../../assets/images/corporates/marketing-image1.png';
import About2 from '../../assets/images/corporates/marketing-image2.png';

import AboutMobi1 from '../../assets/images/corporates/marketing-mobi1.png';
import AboutMobi2 from '../../assets/images/corporates/marketing-mobi2.png';
import * as ProfileAction from "../../redux/actions/profileAction";
import useClickOutside, { useIsInViewport } from '../../hooks/useClickOutside';
import {slider_options, testimonial_options} from "../../resources/carouselOptions"

import testimoni from '../../assets/images/corporates/testimonials/testimoni1.png';
import { Helmet } from "react-helmet";


import {
  CORPORATE_FILTERS,
  CORPORATE_SERVICES,
  CORPORATE_PACKAGES,
  CORPORATE_REWARDS_PROGG,
  TESTIMONIALS
} from "../../resources/corporateData";


import WhatsappLogo from '../../assets/images/corporates/whatsapp-logo.svg'
import { ScrollToTop } from "../common";
import ContactFloatIngButton from "./contactFloatIngButton";

function Home() {

  const dispatch = useDispatch();
  const [showEmployerForm, setShowEmployerForm] = useState(false);
  const [showEmployerFormMobile, setShowEmployerFormMobile] = useState(false);
  const SCROLL_REF = useSelector((state) => state.userProfile.set_Scroll);
  const [isVisible, setIsVisible] = useState(true);
  const [isInputFocus, setIsInputFocus] = useState(false);
  const [height, setHeight] = useState(0)
  const [id, setId] = useState(1)
  const history = useHistory()
  const location=useLocation()
  
  const ChangeCategory = (newId) => {
    setId(newId);
  }
  useEffect(()=>{
    if(location.state){
ChangeCategory(location.state?.id)
    }
  },[location.state])

  useEffect(() => {
    if (SCROLL_REF) {
      executeScroll();
    }
  }, [SCROLL_REF])

  const myRef = useRef(null)
  const executeScroll = () => {
    myRef.current.scrollIntoView();
    dispatch(ProfileAction.setScroll(false));
  }
  const formRef = useRef(null)
  const isFormInViewport = useIsInViewport(formRef);
  const executeScrollToForm = () => {
    formRef.current.scrollIntoView();
  }

  const employerFormRef = useRef();
  useClickOutside(employerFormRef, () => {
    setShowEmployerForm(false)
    setShowEmployerFormMobile(false)
      
  });

  const employerFormMobileRef = useRef();
  useClickOutside(employerFormMobileRef, () => {
    setShowEmployerFormMobile(false)
      
  });

  const handleContactModal = () => {
    setShowEmployerForm(true)
  }

  const handleContactModalMobi = () => {
    setShowEmployerFormMobile(true)
  }
  
  useEffect(() => {
    if(!isInputFocus){
      window.addEventListener("scroll", listenToScroll);
    }   
    return () => 
       window.removeEventListener("scroll", listenToScroll); 
  }, [isInputFocus])

  const listenToScroll = () => {
    let heightToHideFrom = document.body.scrollHeight - 850 ;
    const winScroll = document.body.scrollTop || 
        document.documentElement.scrollTop;
    setHeight(winScroll);

    if (winScroll > heightToHideFrom) {  
         setIsVisible(false);
    } else {
        setIsVisible(true);
    }  
  };  

  const onInputFocus = () => {
    setIsVisible(false);
    setIsInputFocus(true)
  }

  return (
    <div>

      <Helmet>
          <title>Corporates - Hammock Holidays</title>
          <meta name="description" content="Employee Recognition Programmes Inspiring Rewards – Inspiring Performance" />
      </Helmet>
      <div className="corporate-services">
        <div className="container">
          <div className="employee-reward">
              <h5>Better your <span className="gradient-text">Employee Engagement</span> with our <span>Reward & Recognition</span> Programmes</h5>
          </div>
        </div>
          <Category CATEGORY={CORPORATE_REWARDS_PROGG} ChangeCategory={ChangeCategory} id={id} />
        <div className="container">
          <CategoryDetails id={id}/>
           {
            // Talk to us section
            <div className="talk-to-us d-none d-md-block" ref={formRef}>
              <h5>Want to know more?</h5>
              <div className="row">
                <div className="col-12 col-lg-7">
                  <Employer onInputFocus={onInputFocus} />
                </div>
                <div className="col-12 col-lg-5">
                  <div className="login-holder">
                    <h5>For any queries</h5>
                    <div className="line"></div>
                    <div className="contact-card mb-3 d-flex align-items-center justify-content-between">
                      <div>
                        <p>
                          <a href="tel:+919886039555">+91 9886039555</a>
                        </p>
                        <p>Our number</p>
                      </div>
                      <div>
                        <a href="https://wa.me/+919886039555" rel="noreferrer" target="_blank">
                          <img src={WhatsappLogo} alt="WhatsappLogo" />
                        </a>
                      </div>
                    </div>
                    <div className="contact-card">
                      <p>
                        <a href="mailto:dilip@hammockholidays.com" rel="noreferrer"
                          target="_blank" >
                          dilip@hammockholidays.com
                        </a>
                      </p>
                      <p>Our email</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          <CategoryImages id={id} section={'corporate'}/>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="promotion-card">
                  <h5>Great things happen when your team is happy together</h5>
                  <p>Celebrate your team’s wins and promote a positive work environment by enabling everyone in your organisation <br/>to be recognised. Here’s your opportunity to be the employer of choice.</p>
                  <div className="text-center cta d-none d-md-block">
                    <button type="sumbit" className="btn" onClick={executeScrollToForm}>
                      Contact us
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            // Employee engagement

            // <div className="employee-engagement">
            //   <h5> Hammock <span className="gradient"> Employee Engagement Programme</span></h5>
            //   <p>Improve your business productivity with us</p>
            //   <div className="filter-holder">
            //     <div className="filter-wrapper">
            //       {CORPORATE_FILTERS.length > 0 &&
            //         CORPORATE_FILTERS.map((item) => (
            //           <div className="filter-card corporate-card">
            //             <img src={item.image} alt="" />
            //             <div className="card-info">
            //               <p>{item.name}</p>
            //             </div>
            //           </div>
            //         ))}
            //       <div className="px-3"></div>
            //     </div>
            //   </div>
            // </div>

          }
          <div className="d-flex acheivements-holder justify-content-lg-center">
            <div className="acheivement-card">
              <div className="count">
                <p>243<span>k</span><span>+</span></p>
              </div>
              <div className="info">
                <p>Long services <br />
                  awarded</p>
              </div>
            </div>
            {/* <div className="acheivement-card">
              <div className="count">
                <p>100<span>k</span><span>+</span></p>
              </div>
              <div className="info">
                <p>Gift cards <br />
                  delivered</p>
              </div>
            </div> */}
            <div className="acheivement-card">
              <div className="count">
                <p>1.8<span>k</span><span>+</span></p>
              </div>
              <div className="info">
                <p>Corporate <br />
                  Get-togethers</p>
              </div>
            </div>
            <div className="acheivement-card">
              <div className="count">
                <p>2<span>k</span><span>+</span></p>
              </div>
              <div className="info">
                <p>Outbound Training & <br /> Leadership Programmes</p>
              </div>
            </div>
            <div className="acheivement-card">
              <div className="count">
                <p>21<span>yrs</span><span>+</span></p>
              </div>
              <div className="info">
                <p>Experience in corporate <br /> rewards space</p>
              </div>
            </div>
          </div>

          {/* <div className="cerp-corp-wrapper">
            {
              CORPORATE_SERVICES.length > 0 && CORPORATE_SERVICES.map((service) => (
                <div className="cerp-corp">
                  <div className="row justify-content-center">
                    <div className="col-12 col-md-4 corp-mobi-p0">
                      <div className="d-none d-md-block">
                        <img src={service?.image} className="w-100" alt="" />
                      </div>
                      <div className="d-block d-md-none">
                        <img src={service?.mobiImage} className="w-100" alt="" />
                      </div>
                    </div>
                    <div className="col-12 col-md-7 vertical-center">
                      <div className="info">
                        <p>{service?.title}</p>
                        <p>{service?.description}</p>
                        <div className="learn-more-holder d-none d-md-block" onClick={handleContactModal}>
                          <button className="btn btn-learn-more w-100">Contact us to learn more </button>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              ))
            }
          </div> */}

          {
            // testimonials
            <div className="testimonial-holder">
              <h5>What our clients are saying</h5>
              <div className="main">
                <div className="testimonial-wrapper">
                  <OwlCarousel className="owl-theme" {...testimonial_options} >
                    {
                      TESTIMONIALS && TESTIMONIALS.length > 0 && TESTIMONIALS?.map((item) => (
                        <div className="testimoni">
                          <div className="d-flex">
                            <div>
                              <img src={item?.image} alt={item?.name} />
                            </div>
                            <div className="name-designation">
                              <p>{item?.name}</p>
                              <p>{item?.designation}</p>
                            </div>
                          </div>
                          <p className="description">{item?.testimoni}</p>
                        </div>
                      ))
                    }
                  </OwlCarousel>
                </div>
              </div>
              {/* <div className="testimonial-wrapper">
                {
                  TESTIMONIALS && TESTIMONIALS.length > 0 && TESTIMONIALS?.map((item) => (
                    <div className="testimoni">
                      <div className="d-flex">
                        <div>
                          <img src={item?.image} alt="" />
                        </div>
                        <div className="name-designation">
                          <p>{item?.name}</p>
                          <p>{item?.designation}</p>
                        </div>
                      </div>
                      <p className="description">{item?.testimoni}</p>
                    </div>
                  ))
                }
              </div> */}
            </div>
          }


           <div className="corpPage-login" ref={myRef}>
            <Login onInputFocus={onInputFocus}/>
          </div>

          {
            showEmployerForm ?
              <EmployerModal openModal={setShowEmployerForm} employerFormRef={employerFormRef} /> : null
          }

          {
            // mobile view
            showEmployerFormMobile ? <div className="employer-modal">
              <Employer openModal={setShowEmployerFormMobile} employerFormMobileRef={employerFormMobileRef}/>
            </div> : null
          }

          
          

          <Clients />

          {
            // mobi view 
          }
          <div className="mt-4 d-block d-md-none">
            <div className="login-holder">
              <h5>For any queries</h5>
              <div className="line"></div>
              <div className="contact-card mb-3 d-flex align-items-center justify-content-between">
                <div>
                  <p>
                    <a href="tel:+919886039555">+91 9886039555</a>
                  </p>
                  <p>Our number</p>
                </div>
                <div>
                  <a href="https://wa.me/+919886039555" rel="noreferrer" target="_blank">
                    <img src={WhatsappLogo} alt="" />
                  </a>
                </div>
              </div>
              <div className="contact-card">
                <p>
                  <a href="mailto:dilip@hammockholidays.com" rel="noreferrer"
                    target="_blank" >
                    dilip@hammockholidays.com
                  </a>
                </p>
                <p>Our email</p>
              </div>
            </div>
          </div>
        </div>
      </div>
<ContactFloatIngButton isFormInViewport={isFormInViewport}  executeScrollToForm={executeScrollToForm}/>
      {
        isVisible &&  <div className="mobile-buttons">
        <div className="stcky-leftButton" style={{width: "50%"}}>
          <div className="white-button" onClick={executeScroll}>
            <p className="mb-0">Employee?</p>
            <a>Login/Sign Up</a>
          </div>
        </div>
        <div className="stcky-rightButton" style={{width: "50%"}}>
          <div className="custom-button" onClick={handleContactModalMobi}>
          <p className="mb-0">Want to learn more?</p>
            <a>Contact Us</a>
          </div>
        </div>
      </div>
      }
     
     {/* <ScrollToTop showBelow={250}/> */}
    </div>
  );
}

export default Home;