import React from 'react'
import { useSelector } from "react-redux";
import Header from "./header";
import Footer from "./footer";
import backIcon from "../../assets/images/common/back-icon.svg";
import { useHistory } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { ScrollToTop } from '.';

function PrivacyPolicy() {
    const history = useHistory();
    const loginUserData = useSelector((state) => state.auth.login_user_otp);
    return (
        <div>
          <Helmet>
                <title>Privacy Policy - Hammock Holidays</title>
                <meta name="description" content="" />
            </Helmet>
            <div className={Object.keys(loginUserData).length !== 0 &&
                loginUserData.constructor === Object ? 'innerPage-header' : 'innerPage-header pre-login-header'} >
                <Header />
            </div>
            <div className="container innerPage-container">
                <a onClick={() => history.goBack()}>
                    <div className="back-button">
                        <img src={backIcon} alt="backIcon" />
                    </div>
                </a>
                <div className="package-holder">
                    <div className="package-title">
                        <h1>
                        Privacy Policy
                  </h1>
                    </div>
                </div>
                <div className="terms-conditions-content">
                    <div className="content-wrapper mb-5">
                        <h4 className="mb-3">Privacy Policy</h4>
                        <p>By accessing this Site, certain information about you, such as Internet protocol (IP) addresses, navigation through the Site, the systems and programs used and the time spent, along with other similar information, may be stored on our servers. The same may be used by us for web site traffic analysis or for such other purposes as we may deem appropriate.
                          </p>

                        <p>Any personally identifiable information you submit will only be disclosed to suppliers who are linked to your bookings (with an intention to protect misrepresentation/fraudulent use of services & offers purchased/gifted to by a User), and are required to fulfill your request for certain products or services. We will not disclose, sell, share or in any way reveal your information to any other third party.
                          </p>

                        <p>
                            There are a number of products and services offered by third parties included on our site (e.g., loyalty/performance awards, gift coupons, service incentives). These products and services constitute Hammock Holidays offers. If you choose to use these separate products or services, disclose information to the providers, and/or grant them permission to collect information about you, then their use of your information is governed by their privacy policy.
                          </p>

                        <p>
                            Notwithstanding the foregoing, Hammock Holidays reserves the right (and you authorize Hammock Holidays) to share or disclose your Registration Information and Account information when Hammock determines, in its sole discretion, that the disclosure of such information is necessary to identify, contact, or bring legal action against you if you are or may be violating this Privacy and Security Policy or Hammock Holidays Terms & Conditions/ Terms of Use to prevent potentially prohibited or illegal activities or if required by any applicable law, rule regulation, subpoena or other legal process.
                          </p>

                        <p>
                            We use a combination of firewall barriers, encryption techniques and authentication procedures, among others, to maintain the security of your online session and to protect Hammock Holidays accounts and systems from unauthorized access.
                          </p>

                        <p>
                            Our servers are in a secure facility and access requires multiple levels of authentication. Our databases are protected from general employee access both physically and logically. All Registration Information and Account Information is stored in an encrypted database, and all backup drives and tapes are encrypted with different keys.
                          </p>

                        <p>
                            If you have a security related concern, please contact us at <a rel="noreferrer" target="_blank" href="mailto:customercare@hammockholidays.com">customercare@hammockholidays.com</a>. We will work closely with you to ensure a rapid and personal response to your concerns.
                          </p>

                        <p>
                            If you have any questions, comments, concerns or feedback regarding this Privacy and Security Policy, send an email to <a rel="noreferrer" target="_blank"  href="mailto:customercare@hammockholidays.com">customercare@hammockholidays.com</a>.

                          </p>

                    </div>

                    <div className="content-wrapper mb-5">
                        <h5 className="mb-3">Customer service / Help desk contact number:</h5>
                        <p>Hammock Leisure Holidays Pvt Ltd </p>
                        <p className="mb-2">
                            3291, 1st Floor, 12th Main, HAL II Stage, Indiranagar, Bangalore 560 008.</p>
                        <p className="mb-2">
                           Tel :<a href="tel:91-80- 4202 3333" > 91-80- 4202 3333</a></p>
                         <p className="mb-2">
                        Email : <a href="mailto:customercare@hammockholidays.com" rel="noreferrer" target="_blank">customercare@hammockholidays.com</a>
                         </p>  
                         <p>
                        Web : <a href="https://www.hammockholidays.com/" rel="noreferrer" target="_blank"> www.hammockholidays.com </a>
                         </p> 
                    </div>

                </div>
            </div>
            <ScrollToTop showBelow={250}/>
            <Footer />
        </div>
    )
}

export default PrivacyPolicy
