import { createStore, applyMiddleware, compose } from 'redux';
// import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import RootReducer from '../redux/reducers/index';

const initialState = {}

const middleware = [thunk];
const store = createStore(RootReducer, compose(applyMiddleware(thunk)));

// const store = createStore(
//     RootReducer,
//     initialState,
//     composeWithDevTools(applyMiddleware(...middleware))
// );
export default store;