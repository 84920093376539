import React, { useEffect } from "react";
import "../../assets/css/partials/corporates/corporates.scss";
import closeIcon from '../../assets/images/common/close-modal.svg';
import { useSelector } from "react-redux";

function VideoPopup({popupRef}) {

  const EVENT_VIDEO = useSelector(state => state?.common?.video);
  
  useEffect(() => {
    if(EVENT_VIDEO && EVENT_VIDEO?.media_type == "youtube" ){
      setTimeout(() => {
        window.document.querySelector(`.iframe`).contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
      }, 1000)
      
    }else  if(EVENT_VIDEO?.media_type == "video"){
      window.document.querySelector(`.local-video`).play()
    }
  }, [EVENT_VIDEO])

  const renderVideo = () => {
    if(EVENT_VIDEO.media_type == "youtube"){
        return  <iframe className="iframe"
        src={`https://www.youtube.com/embed/${new URL(EVENT_VIDEO.video).searchParams.get("v")}?modestbranding=1&enablejsapi=1&version=3&rel=0&playerapiid=ytplayer&autoplay=1`}
        title="YouTube video player" 
        frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowfullscreen="allowfullscreen"

        ></iframe>
    }else if(EVENT_VIDEO.media_type == "other" && new URL(EVENT_VIDEO.video).hostname==='vimeo.com'){
        return <iframe  className="iframe"
        src={`https://player.vimeo.com/video${new URL(EVENT_VIDEO.video).pathname}?api=1&autoplay=1`} 
        frameborder="0" 
        allow="autoplay; fullscreen; picture-in-picture" 
        allowfullscreen>
        </iframe>
    }else if(EVENT_VIDEO.media_type == "video"){
      return  <video  className="local-video" controls >
            <source src={`${EVENT_VIDEO.video}`} type="video/mp4" />
        </video>
    }
  }
  

  return (
    <div className="video-overlay" >
      <span className="popup-close"><img src={closeIcon} /></span>
      <div className="video-holder" ref={popupRef}>
          {renderVideo()}
        </div>
      
    </div>
  );
}

export default VideoPopup;
