import React from 'react';

import SuccessfulLogo from '../../assets/icons/authIcons/successfulLogo.png'

const CommonModal = ({ showMyModal, modalSubHeadingText, modalHeadingText }) => {
    return (
        <div className={`modal-wrapper ${showMyModal ? 'show' : 'hide'}`}>
            <div className="overlay-modal"></div>
            <div className="card-box card-box-index">
                <img src={SuccessfulLogo} alt="successful"/>
                <p>{modalSubHeadingText}</p>
                <h6>{modalHeadingText}</h6><hr/>
            </div>
        </div>
        
    );
};

export default CommonModal;