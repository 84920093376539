import React, {useRef} from "react";
import "../../assets/css/partials/common/sanitizedStays.scss";
import { Icon, InlineIcon } from "@iconify/react";
import shieldPlus from "@iconify-icons/bi/shield-plus";
import Sanitized from '../../assets/images/common/Vector.png'
import { ModalBody } from "react-bootstrap";
import useClickOutside from '../../hooks/useClickOutside';

export default function SanitizedStayTag({ experienceStay, pauseVideos }) {
    const userRequestFormRef = useRef();
    const userRequestMobFormRef = useRef();
    const displaypop = () => {
        document.getElementById("myModel").style.display = "block";
        document.querySelector("html").style.overflow = "hidden";
        // document.body.style.height = "100vh";
        // document.body.style.overflowY = "hidden";
        pauseVideos();
    }
    useClickOutside(userRequestFormRef, () => {
        closer()
    });
    useClickOutside(userRequestMobFormRef, () => {
        mobCloser()
    });
    const displaymobpop = () => {
        document.getElementById("myPopModel").style.display = "block";
        // document.body.style.height = "100vh";
        // document.body.style.overflowY = "hidden";
        pauseVideos();
    }
    const closer = () => {
        document.getElementById("myModel").style.display = "none";
        document.querySelector("html").style.overflow = "scroll";
        // document.body.style.overflowY = "scroll";
    }
    const mobCloser = () => {
        document.getElementById("myPopModel").style.display = "none";
        // document.body.style.overflowY = "scroll";
    }
    return (
        <div>
            <div class="rowww mob-prevent" onClick={displaypop} style={{ width: "100%" }}>
                <div class="rowww-jr">
                    <div class="columnsss firstss">
                        {/* <Icon icon={shieldPlus} color="green" width="90%" /> */}
                        <img src={Sanitized} alt="Sanitized-logo" />
                    </div>
                    <div class="columnsss secondss">
                        {experienceStay ?
                            <h4>Sanitized Experiences</h4> :
                            <h4>Sanitized Stays</h4>}
                        <p style={{ fontSize: '90%' }}>
                        This stay/activity has confirmed that COVID19 safety measures are in place.  However, please adhere to the necessary precautions.
                        </p>
                    </div>
                    <div class="columnsss thirdss">
                        <h2> {'>'} </h2>
                    </div>
                </div>
            </div>
            {/* Tag Mobile view*/}
            <div class="rowww desk-prevent" onClick={displaymobpop} style={{ width: "100%" }}>
                <div class="rowww-jr">
                    <div class="columnsss firstss">
                        {/* <Icon icon={shieldPlus} color="green" width="90%" /> */}
                        <img src={Sanitized} alt="Sanitized-logo" />
                    </div>
                    <div class="columnsss secondss">
                        {experienceStay ?
                            <h5 style={{ marginLeft: '15px' }}>Sanitized Experiences</h5> :
                            <h5 style={{ marginLeft: '15px' }}>Sanitized Stays</h5>}
                    </div>
                    <div class="columnsss thirdss">
                        <h2 style={{ marginTop: '-5px' }}> {'>'} </h2>
                    </div>
                </div>
                <div className="rowww-down-jr">
                    <p style={{ fontSize: '86%' }}>
                    This stay/ activity has confirmed that COVID19 safety measures are in place.  However, please adhere to the necessary precautions.
                    </p>
                </div>
            </div>
            {/* Pop up */}
            <div id="myModel" className="model mob-prevent">
                <div className="model-content" ref={userRequestFormRef}> 
                    <div className="up">
                        <h2>Ensuring COVID safety</h2>
                        <span style={{ marginTop: '-60px', marginRight: '10px' }} onClick={closer} class="closer">&times;</span>
                        <p style={{ marginBottom: '25px' }}>
                        We request all guests to abide by the mandatory COVID19 protocols and follow the guidelines and SOP’s issued by the central government, state governments & statutory bodies from time to time. Given the current dynamic situation some monuments or sightseeing places may be closed temporarily or may be out-of-bounds, without any prior notice
                        </p>
                        <hr style={{ width: '100%', textAlign: 'left', color: 'grey', marginRight: '30px' }}></hr>
                    </div>
                    <div className="down">
                        <div className="child fir">
                            <h4>Safety features</h4>
                            <p>• Staff follow all safety protocols as directed by local
                                authorities. </p>
                            <p>• Checking of body temperature of the guest on arrival.</p>
                            <p>• Disinfecting all baggage at time of check-in.</p>
                            <p>• Hand sanitizer availability in key areas.</p>
                            <p>• Sanitization of all common touch points.</p>


                            <h4>Physical distancing</h4>
                            <p>• Cashless payment facility available.</p>
                            <p>• Physical distancing rules followed.</p>
                        </div>
                        <div className="child sec">
                            <h4>Cleanliness & disinfecting</h4>
                            <p>
                                • Frequent sanitization of the kitchen, restaurant, and other
                                common areas.
                            </p>
                            <p>
                                • All plates, cutlery, glasses, and other tableware are sanitized.
                            </p>
                            <p>• Guest accommodation is disinfected between stays.</p>
                            <p>• Guest accommodation sealed after cleaning.</p>
                            <p>
                                • Guests have the option to cancel any cleaning services for their
                                accommodation during their stay.
                            </p>
                            <p>
                                • Linens, towels, and laundry washed in accordance with local
                                authority guidelines.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* Pop up mobile view */}
            <div id="myPopModel" className="model">
                <div className="model-content" ref={userRequestMobFormRef}>
                    <div className="down">
                        <h3 style={{ marginBottom: '-4px' }}>Ensuring COVID</h3>
                        <h3 style={{ marginBottom: '15px' }}>safety</h3>
                        <span style={{ marginTop: '-95px', marginRight: '10px' }} onClick={mobCloser} class="closer">&times;</span>
                        <p style={{ marginBottom: '25px' }}>
                            
                        </p>
                        <hr style={{ width: '100%', textAlign: 'left', color: 'grey', marginRight: '30px' }}></hr>
                    </div>

                    We request all guests to abide by the mandatory COVID19 protocols and follow the guidelines and SOP’s issued by the central government, state governments & statutory bodies from time to time.
Given the current dynamic situation, some monuments or sightseeing places may be closed temporarily or maybe out-of-bounds, without any prior notice
                    <h5>Safety features</h5>
                    <p>• Staff follow all safety protocols as directed by local
                        authorities. </p>
                    <p>• Checking of body temperature of the guest on arrival.</p>
                    <p>• Disinfecting all baggage at time of check-in.</p>
                    <p>• Hand sanitizer availability in key areas.</p>
                    <p>• Sanitization of all common touch points.</p>


                    <h5>Physical distancing</h5>
                    <p>• Cashless payment facility available.</p>
                    <p>• Physical distancing rules followed.</p>


                    <h5>Cleanliness & disinfecting</h5>
                    <p>
                        • Frequent sanitization of the kitchen, restaurant, and other
                        common areas.
                    </p>
                    <p>
                        • All plates, cutlery, glasses, and other tableware are sanitized.
                    </p>
                    <p>• Guest accommodation is disinfected between stays.</p>
                    <p>• Guest accommodation sealed after cleaning.</p>
                    <p>
                        • Guests have the option to cancel any cleaning services for their
                        accommodation during their stay.
                    </p>
                    <p>
                        • Linens, towels, and laundry washed in accordance with local
                        authority guidelines.
                    </p>


                </div>
            </div>
        </div>
    );
}
