import React, { useEffect, useState } from 'react'
import Header from '../header'
import banner from "../../../assets/images/home/experience-banner.png";
import { useSelector, useDispatch } from 'react-redux'
import * as CorporateAction from "../../../redux/actions/corporatesAuthActions";
import { SECTIONS } from '../../../resources/eventsData'
import * as HomeAction from '../../../redux/actions/homeAction'
import { useHistory } from 'react-router'
import { scheduleDuration, specificScheduleDuration } from '../../utilities/utillFunc';
import moment from 'moment';
import GlobalSearch from '../globalSearch';
import SanitizedStayTag from '../../common/sanitizedStayTag';
import { ScrollToTop } from "../../common";
import Footer from '../footer';
import backIcon from "../../../assets/images/common/back-icon.svg";
function SearchResultsPage() {
    const dispatch = useDispatch()
    const history = useHistory()
    const SEARCH_VAL = useSelector((state) => state.homepage.global_search_val);
    const SUGGESTIONS_LIST = useSelector((state) => state.homepage.global_search_results);
    const [suggestions, setSuggestions] = useState(SUGGESTIONS_LIST)
    const sec_name = localStorage.getItem('sec_name')
    const [secName, setSecName] = useState(sec_name)
    const userDetails = JSON.parse(localStorage.getItem("userData"));
    const isCorporateAccount = userDetails?.user_details.corporate_account;
    const CORP_LIST = useSelector((state) => state.corporates?.corporates_category.data);
    const companyDomain = userDetails?.organisation?.email_domain;
    const isWipro = (companyDomain === "wipro.com")
    const [value, setValue] = useState("")

    const handleCategory = (module) => {
        setSecName(module)
        localStorage.setItem('sec_name', module)
        let filteredSuggestion = SUGGESTIONS_LIST.filter((item) => {
            if (module == "Experiences") {
                return item.module == "experience"
            } else if (module == "Holidays") {
                return item.module == "holidays"
            } else if (module == "Events") {
                return item.module == "events"
            }
        })

        setSuggestions(filteredSuggestion)
    }
    const goBackFun = () => {
        history.goBack()
    }
    const handleCorpCategory = (sec_name) => {
        setSecName(sec_name)
        localStorage.setItem('sec_name', sec_name)
        let filteredSuggestion = SUGGESTIONS_LIST.filter((item) => {
            return sec_name == item.corporate__section__name
        })
        setSuggestions(filteredSuggestion)
    }

    const handleSeeAll = (module) => {
        localStorage.setItem('sec_name', module)
        setSecName(module)
        setSuggestions(SUGGESTIONS_LIST)
    }

    useEffect(() => {
        dispatch(HomeAction.getGlobalSearchResults(SEARCH_VAL, isCorporateAccount || false));
        if (isCorporateAccount) {
            dispatch(CorporateAction.corporateCategories());
        }
    }, [SEARCH_VAL])
    const isInternational = (item) => {
        const section = item?.holiday_section_name || item?.corp_section_name || item?.experience_section_name
        return section === "International"
    }
    useEffect(() => {
        if (secName !== "see all" && !isCorporateAccount) {
            handleCategory(secName)
        } else if (secName !== "see all" && isCorporateAccount) {
            handleCorpCategory(secName)
        } else {
            handleSeeAll("see all")
        }
    }, [SUGGESTIONS_LIST])



    const handlePackageDetails = (suggestion) => {
        if (suggestion.module == "experience") {
            localStorage.setItem("filteredList", true)
            history.push({ pathname: `/experiences/experience-details/${suggestion.experience_slug}`, state: { id: suggestion?.id } })
            // openLink(`/experiences/experience-details/${suggestion.pack_slug}`);
        } else if (suggestion.module == "corporate") {
            localStorage.setItem("filteredList", true)
            history.push({ pathname: `/corporates/package-details/${suggestion.corporate_slug}`, state: { id: suggestion?.id } })
            // openLink(`/corporates/package-details/${suggestion.pack_slug}`);
        } else if (suggestion.module == "events") {
            history.push(`/events/event-details/${suggestion.event_slug}`)
            // openLink(`/events/event-details/${suggestion.pack_slug}`);
        } else if (suggestion.module == "holidays") {
            localStorage.setItem("filteredList", true)
            history.push({ pathname: `/holidays/holiday-details/${suggestion.holiday_slug}`, state: { id: suggestion?.id } })
            // openLink(`/holidays/holiday-details/${suggestion.pack_slug}`);
        }
    }

    const getSpecificDatesMonth = (packageInfo) => {
        const months = [];
        packageInfo?.schedule_data?.multipleDates?.length > 0 &&
            packageInfo?.schedule_data?.multipleDates.map(date => {
                months.push(moment(date).format("MMM"));
            })
        const uniqueMonths = [... new Set(months)]
        const specificDatesMonths = uniqueMonths.map((item, index) => {
            return <span> {`${item}${uniqueMonths.length == index + 1 ? '' : ","} `}</span>
        })
        return specificDatesMonths

    }

    const calculateDays = (packageInfo) => {
        const arr = [];
        packageInfo?.schedule_data?.selectedDates?.length > 0
            && packageInfo?.schedule_data?.selectedDates?.map(date => {
                const startDate = moment(`${date.to}`, 'DD-MM-YYYY');
                const endDate = moment(`${date.from}`, 'DD-MM-YYYY');
                const result = endDate.diff(startDate, 'days');
                arr.push(Math.round(Math.abs(result) + 1));
            });

        const min = Math.min(...arr);
        const max = Math.max(...arr);

        if (min !== max) {
            return <span>Min {min} {min == 1 ? 'day' : 'days'}, Max {max} {max == 1 ? 'day' : 'days'}</span>
        } else {
            return <span> Max {max} {min == 1 ? 'day' : 'days'}</span>
        }

    }
    const handleSearchValue = (value) => {
        setValue(value)
    }
    const handleSubmit = () => {
        dispatch(HomeAction.setGlobalSearchVal(value))
        setSecName("see all")
        localStorage.setItem('sec_name', "see all")
        setSuggestions(SUGGESTIONS_LIST)
    }

    const sanitisedTag = (str) => {
        return str;
    }

    return (
        <>
            <div className="banner-holder">
                <Header />
                <div className="banner">
                    <img src={banner} className="img-fluid" alt="banner" />
                </div>
                <div
                    onClick={goBackFun}
                    className={`back-button back-button-mobi d-md-none`}
                >
                    <img src={backIcon} alt="backIcon" />
                </div>
                <div className="text-holder animate-fadein" style={{ top: '50%' }}>
                    <h1>
                        Search results for <span>"{SEARCH_VAL}"</span>
                    </h1>
                </div>
            </div>
            <div className="filter-holder corporate-category-filter holiday-holder mt-5">
                <div className={CORP_LIST && CORP_LIST?.length <= 3 ? 'd-flex justify-content-center mobi-category-overflow' : 'filter-wrapper mobi-category-overflow'}>
                    <div className={secName == "see all" ? 'see-all-events active-see-all' : 'see-all-events'} onClick={() => handleSeeAll("see all")}>
                        {
                            secName === "see all" ? <p>All <br /> Results</p> : <p>See all <br />Results</p>
                        }
                    </div>
                    {isCorporateAccount ?
                        <>
                            {
                                CORP_LIST && CORP_LIST?.length > 0 &&
                                CORP_LIST.map((item, index) => (
                                    <div key={index} className={secName == item?.name ? "filter-card active-category" : " filter-card"} onClick={() => handleCorpCategory(item.name)}>
                                        <div className="img-gradient">
                                            <div className="cat-img">
                                                <img src={item?.image} alt={item?.name} />
                                                <div class="pseudo"></div>
                                            </div>
                                            <div className="card-info">
                                                <p >{item?.name}</p>
                                            </div>
                                        </div>
                                    </div>

                                ))
                            }
                        </> :
                        <>
                            {
                                SECTIONS && SECTIONS?.length > 0 &&
                                SECTIONS.map((item, index) => (
                                    <div key={index} className={secName == item?.name ? "filter-card active-category" : " filter-card"} onClick={() => handleCategory(item.name)}>
                                        <div className="img-gradient">
                                            <div className="cat-img">
                                                <img src={item?.image} alt={item?.name} />
                                                <div class="pseudo"></div>
                                            </div>
                                            <div className="card-info">
                                                <p >{item?.name}</p>
                                            </div>
                                        </div>
                                    </div>

                                ))
                            }
                        </>}
                </div>
            </div>

            <div className="container">
                <div className="searchPage-wrapper">
                    <div className="input">
                        <GlobalSearch handleSearchValue={handleSearchValue} fromSearchPage={true} />
                    </div>
                    <div>
                        <button type="submit" className="ml-2" onClick={value ? handleSubmit : null}>Search</button>
                    </div>
                </div>
                {suggestions?.length > 0 ?
                    <div className="searchResuts">

                        <div className="corporatePackages-holder">
                            {suggestions && suggestions?.length > 0 &&
                                suggestions.map((packageInfo, index) => {
                                    return (
                                        <div style={{ cursor: "pointer" }} className="package" onClick={() => handlePackageDetails(packageInfo)}>
                                            <div className="row overflow-hidden w-100-vw">
                                                {/* <div className="col-12 col-lg-5 pr-lg-0">

                            </div> */}
                                                <div className="col-12 col-lg-12 pl-lg-0">
                                                    <div className="package-details">
                                                        {packageInfo?.price == 0 && packageInfo.module == "events" ?
                                                            <div className="free-tag">
                                                                <span>FREE SESSION</span>
                                                            </div> : null}
                                                        <div className="d-flex" style={{ flexWrap: 'wrap' }}>
                                                            {packageInfo &&
                                                                packageInfo.covid_safe === true ?
                                                                <><SanitizedStayTag experienceStay={sanitisedTag} /></> : null
                                                            }
                                                            <div className="bestseller-tag">
                                                                {packageInfo?.best_seller ? <span>BEST SELLER</span> : ''}
                                                            </div>
                                                        </div>
                                                        <h4>{packageInfo.pack_title}
                                                            {packageInfo?.workshop === true ?
                                                                <span style={{ textTransform: 'uppercase' }}>ONLINE WORKSHOP</span> : ''}
                                                        </h4>
                                                        <div className="info">
                                                            {packageInfo.module !== "events" && !isInternational(packageInfo) ?
                                                                <p className="d-flex">
                                                                    {packageInfo?.location?.length > 1 ? `${(packageInfo?.location_city || packageInfo?.location?.[0]?.city)}, ${packageInfo?.location?.[1]?.address}` :
                                                                        `${(packageInfo?.location_city || packageInfo?.location?.[0]?.city)}${(packageInfo?.location?.[0]?.state ? "," : "")} ${(packageInfo?.location?.[0].state)}`
                                                                    }
                                                                </p> :
                                                                <p className="d-flex">
                                                                    <p className="d-flex">{packageInfo.location_country}</p>
                                                                </p>}
                                                            <div className="line"></div>
                                                            {
                                                                packageInfo?.schedule_type === "package" ?
                                                                    <p>
                                                                        {packageInfo?.schedule_data?.days} {packageInfo?.schedule_data?.days == 1 ? 'Day' : 'Days'}  {packageInfo?.schedule_data?.nights} {
                                                                            packageInfo?.schedule_data?.nights == 1 ? 'Night' : 'Nights'
                                                                        }
                                                                    </p> : ""
                                                            }
                                                            {
                                                                packageInfo?.schedule_type === "recurring" ?
                                                                    <p>Every
                                                                        {packageInfo?.schedule_data?.newSelectedDyas?.length > 0 ?
                                                                            packageInfo?.schedule_data?.newSelectedDyas.map((index) => {
                                                                                switch (index) {
                                                                                    case 0:
                                                                                        return <span> Sun</span>
                                                                                    case 1:
                                                                                        return <span>, Mon</span>
                                                                                    case 2:
                                                                                        return <span>, Tue</span>
                                                                                    case 3:
                                                                                        return <span>, Wed</span>
                                                                                    case 4:
                                                                                        return <span>, Thu</span>
                                                                                    case 5:
                                                                                        return <span>, Fri</span>
                                                                                    case 6:
                                                                                        return <span>, Sat</span>
                                                                                }
                                                                            }) : ""
                                                                        }
                                                                        {packageInfo?.schedule_data?.recurringHours || packageInfo?.schedule_data?.recurringMinutes ? ` -` : ``}
                                                                        {packageInfo?.schedule_data?.recurringHours !== "0" && packageInfo?.schedule_data?.recurringHours !== undefined ?
                                                                            packageInfo?.schedule_data?.recurringHours > 1 ? ` ${packageInfo?.schedule_data?.recurringHours} hrs ${packageInfo?.schedule_data?.recurringMinutes ? '' : '/day'}` : ` ${packageInfo?.schedule_data?.recurringHours} hr ${packageInfo?.schedule_data?.recurringMinutes ? '' : '/day'}` : ""}
                                                                        {packageInfo?.schedule_data?.recurringMinutes !== (0 || undefined) ?
                                                                            packageInfo?.schedule_data?.recurringMinutes > 1 ? `  ${packageInfo?.schedule_data?.recurringMinutes} mins/day` : `  ${packageInfo?.schedule_data?.recurringMinutes} min/day` : ""}
                                                                    </p> : ""
                                                            }
                                                            {
                                                                packageInfo?.schedule_type === "specific_dates" && packageInfo.module !== "events" ?
                                                                    <p>In the month of
                                                                        {getSpecificDatesMonth(packageInfo)} {specificScheduleDuration("", "", packageInfo)}
                                                                    </p> : ""
                                                            }
                                                            {
                                                                packageInfo?.schedule_type === "date_range" ?
                                                                    <p>
                                                                        {calculateDays(packageInfo)}
                                                                    </p> : ""
                                                            }
                                                            {
                                                                packageInfo?.flexi_schedule ?
                                                                    <p>
                                                                        Flexible Days
                                                                    </p> : ""
                                                            }
                                                            {packageInfo.module == "events" ?
                                                                <p style={{ color: "#000" }}>{moment(packageInfo?.schedule_data?.date, 'DD.MM.YYYY').format('ddd Do MMM, YYYY')}, {moment(packageInfo?.schedule_data?.from, 'hh:mm').format('LT')} - {moment(packageInfo?.schedule_data?.to, 'hh:mm').format('LT')} </p>
                                                                : null}
                                                        </div>
                                                        <p style={{ color: "#797979" }}>{packageInfo.brief_description}</p>
                                                        <div className="points">
                                                            {(isCorporateAccount && isWipro) ? <p>Starting {packageInfo.points} pts</p> : <p>Starting Rs. {isCorporateAccount ? packageInfo.points
                                                                : packageInfo.revised_price ? packageInfo.revised_price : packageInfo.price}</p>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)
                                })
                            }
                        </div>
                    </div> :
                    <p className="text-center">Currently no packages available for “{SEARCH_VAL}” in <span className="gradient-text">{secName}</span></p>
                }
            </div>
            <Footer />
            <ScrollToTop showBelow={200} />
        </>
    )
}

export default SearchResultsPage
