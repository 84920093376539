import React from "react";
import "../../assets/css/partials/common/sanitizedStays.scss";
import { Icon, InlineIcon } from "@iconify/react";
import shieldPlus from "@iconify-icons/bi/shield-plus";
import Safety from '../../assets/images/common/safety.svg';

export default function SanitizedStayTag({experienceStay}) {
  return (
    <div>{experienceStay?
      <div className="toggle">
        <span>
           <img src={Safety} className="mr-1" />
          <span className="text">SANITIZED EXPERIENCE</span>
        </span>
      </div>:(
        <div className="toggle">
        <span>
           <img src={Safety} className="mr-1" />
          <span className="text">SANITIZED STAY</span>
        </span>
      </div>
      )}
    </div>
  );
}
