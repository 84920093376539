import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import * as CorporateAction from "../../redux/actions/corporatesAuthActions";
import CommonButton from '../common/authCommonButton';
import 'animate.css';


function HolidayRangePicker({ showMyModal, handleDatesData, dateList, dateRangeMinDays, dateRangeMaxDays, prevStartDate, prevEndDate, handleResAPIStartAndEndDate }) {
    const options = { weekday: 'short', day: 'numeric', month: 'long' };
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(prevStartDate ? moment(prevStartDate, 'DD-MMM-YYYY').format('DD-MM-YYYY') : null);
    const [endDate, setEndDate] = useState(prevEndDate ? moment(prevEndDate, 'DD-MMM-YYYY').format('DD-MM-YYYY') : null);
    const [indexValue, setIndexValue] = useState(localStorage.getItem('dateRangeIndex'));
    const [error, setError] = useState(false)
    const [filtersortedDate, setFiltersortedDate] = useState([])

    const wrapperRef = useRef(null);
    useModalOutsideClose(wrapperRef);

    useEffect(() => {
        let resDate = dateList.filter(d => {
            return moment(d.from, 'DD-MM-YYYY').isAfter(); ;
        });
        resDate.sort((a, b) => moment(a.from, "DD-MM-YYYY").valueOf() - moment(b.from, "DD-MM-YYYY").valueOf());
        setFiltersortedDate(resDate)
    }, [dateList]);
   
    function useModalOutsideClose(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setError(false)
                    document.getElementById("holidayModal").classList.add("hide");
                    document.getElementById("holidayModal").classList.remove("show");
                 
                    setEndDate(null)
                    setStartDate(null)
                    setIndexValue(null)
                    localStorage.setItem('dateRangeIndex', null)
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    };

    const selectDate = (value, from, to) => {
        setIndexValue(value);
        setStartDate(from);
        setEndDate(to);
        setError(false)
        localStorage.setItem('dateRangeIndex', value)
        // console.log(to, from);
    }
 
    let resAPIStartDate = startDate !== null && startDate
    let resAPIEndDate = endDate !== null && endDate


    let resStartDate = startDate !== null && startDate.toLocaleString('en-US', options);
    let resEndDate = endDate !== null && endDate.toLocaleString('en-US', options);

    const handleHolidayData = () => {
        handleDatesData(resStartDate, resEndDate);
        handleResAPIStartAndEndDate(resAPIStartDate, resAPIEndDate, null)
        // dispatch(CorporateAction.handleStartAndEndDate(resAPIStartDate, resAPIEndDate));
        if (resEndDate == false || resEndDate == false) {
            setError(true)
        } else {
            document.getElementById("holidayModal").classList.add("hide");
            document.getElementById("holidayModal").classList.remove("show");
        }

    };
    const renderDays = () => {
        if (dateRangeMinDays !== dateRangeMaxDays) {
            return <span>Min {dateRangeMinDays} {dateRangeMinDays == 1 ? 'day' : 'days'}, Max {dateRangeMaxDays} {dateRangeMaxDays == 1 ? 'day' : 'days'}</span>
        } else {
            return <span> Max {dateRangeMaxDays} {dateRangeMinDays == 1 ? 'day' : 'days'}</span>
        }
    };

    return (
        <div>
            <div className={`modal-wrapper ${showMyModal ? 'show' : 'hide'}`} id="holidayModal">
            <div className='calendar-overlay'></div>
                <div className="card-box calender-modal animated_only_mobile animate__animated animate__fadeInUp animate_faster" ref={wrapperRef}>
                    <div className="row">
                        <div className="col-md-8 order-12 order-md-1 holiday-range-scroll">
                            {error ? <p style={{ color: 'red' }}>Please select start date and end date</p> : <p className="schedule-date">Choose a date range from below</p>}
                            {/* <p className="schedule-date">{(dateRangeMinDays || dateRangeMaxDays) ? renderDays() : null}</p> */}
                            {
                                filtersortedDate && filtersortedDate.length > 0 && filtersortedDate.map((item, index) => (
                                    <div className={`holiday-range ${indexValue == index ? 'holiday-range-active' : ''}`} onClick={() => selectDate(index, item?.from, item?.to)}>
                                        <div className="d-flex justify-content-between w-100 align-items-center">
                                            <div>
                                                <p> <span>{moment(item?.from, 'DD-MM-YYYY').format('Do MMM')}</span> </p>
                                                <p className="grey-text"> <span>{moment(item?.from, 'DD-MM-YYYY').format('YYYY, ddd')}</span> </p>
                                            </div>
                                            <div> - </div>
                                            <div>
                                                <p><span>{moment(item?.to, 'DD-MM-YYYY').format('Do MMM')}</span> </p>
                                                <p className="grey-text"> <span>{moment(item?.to, 'DD-MM-YYYY').format('YYYY, ddd')}</span> </p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="col-md-3 order-1 d-flex d-md-block justify-content-around justify-content-md-left">
                            <div className="row check-in trip-date">
                                <p className="text-left">Start date</p>
                                <h5 className="text-left">{resStartDate && moment(resStartDate, 'DD-MM-YYYY').format('Do MMM, ddd YYYY')}</h5>
                            </div>
                            <div className="row check-out trip-date">
                                <p className="text-left">End date</p>
                                <h5 className="text-left">{resEndDate && moment(resEndDate, 'DD-MM-YYYY').format('Do MMM, ddd YYYY')}</h5>
                            </div>
                        </div>
                    </div>
                    <div className="row calender-footer-btn">
                        <button className="btn-common" type="button" onClick={handleHolidayData}>Done</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HolidayRangePicker
