import { Rating } from '@material-ui/lab'
import moment from 'moment'
import React from 'react'

const ReviewCard = ({name,rating,date,commant,img}) => {
  return (
    <div className="review-holder">
    <div className="rew-profile-rating  d-flex justify-content-between">
      <div className="profile d-flex ">
        {img!==""?
        <img src={img} alt={name} />
        : <div className="nameChar">
        <p>{name !== undefined && name?.charAt(0).toUpperCase()}</p>
        </div>
}
        <p className="name">{name}</p>
        <p className="date">{moment(date).format("MMMM, YYYY")}</p>
      </div>

      <div className="rating  d-flex align-items-center">
      <Rating
                  name="size-medium"
                  value={rating}
                  readOnly
                  max={rating}
                />
        <p className="date">{moment(date).format("MMMM, YYYY")}</p>
      </div>
    </div>
    <p className='review-commant'>
     {commant}
    </p>
  </div>
  )
}

export default ReviewCard