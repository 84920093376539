import {
    PLANNING_START_DATE,
    PLANNING_END_DATE,
    STORIES_LIST,
    HONEYMOON_DESTINATION_LIST,
    HONEYMOON_STORIES_LIST,
    MY_FAMILY_CATEGORY_ID,
    ANNIVERSARY_STORIES_LIST,
    

} from '../actions/types';

const initialState = {
    planningStartDate: null,
    planningEndDate: null,
    stories_list: [],
    honeymoon_stories_list: [],
    anniversary_stories_list: [],
    honeymoon_destinations_list: [],
    categoryId:1
   
}

const giftRequestReducers = (state = initialState, action) => {
    switch(action.type){
        case PLANNING_START_DATE:
        return {
            ...state,
            planningStartDate: action.payload,
        }
        case PLANNING_END_DATE:
        return {
            ...state,
            planningEndDate: action.payload,
        }
        case STORIES_LIST:
        return {
            ...state,
            stories_list: action.payload,
        }
        case HONEYMOON_DESTINATION_LIST:
            return {
                ...state,
            honeymoon_destinations_list: action.payload,
            }
        case HONEYMOON_STORIES_LIST:
        return {
            ...state,
            honeymoon_stories_list: action.payload,
        }
        case ANNIVERSARY_STORIES_LIST:
        return {
            ...state,
            anniversary_stories_list: action.payload,
        }
        case MY_FAMILY_CATEGORY_ID:
            return{
                ...state,categoryId:action.payload,
            }
           
        default:
            return state
    }
}

export default giftRequestReducers;