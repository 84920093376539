import React, {useEffect} from 'react'

import { useHistory } from "react-router-dom";
import "../../assets/css/partials/myFamilyFirst/myFamilyFirst.scss";
import OwlCarousel from "react-owl-carousel";
import { stories_options } from '../../resources/carouselOptions';
import { GIFT_SLIDER} from "../../resources/myFamilyFirstData";
import * as giftRequestAction from "../../redux/actions/giftRequestAction";

function Stories({STORIES_LIST,categoryId}) {
    const owl_packages = {
        items: 1,
        margin: 5, 
        nav: false,
        loop: true,
        dots: true,
        autoplay: true,
        smartSpeed: 500,
        animateIn: 'fadeIn',
        animateOut: 'fadeOut',
    };
    const history = useHistory();

   
    const handleStoriesDetails = (id) => {
        if(categoryId==3){
        history.push({pathname:`/my-family-first/stories-details/${id}`,state:{categoryId}})
        window.scroll(0,0)
        }else if(categoryId==2){
            history.push({pathname:`/my-family-first/anniversary/stories-details/${id}`,state:{categoryId}})
            window.scroll(0,0)

        }
        else{
            history.push({pathname:`/my-family-first/honeymoon/stories-details/${id}`,state:{categoryId}})
            window.scroll(0,0)
        }
    }


    return (
        <div className="stories-holder">
            <div className="wrapper">        
            {STORIES_LIST && STORIES_LIST.length > 0 ? STORIES_LIST.map(item => (
                <div className="item" style={{cursor: 'pointer'}} onClick={() => handleStoriesDetails(item?.id)}>
                    <div className="card-wrapper" >
                        <div className="card-img">
                        {/* { */}
                            {/* <OwlCarousel className="owl-theme owl-corporate" {...owl_packages} > */}
                                {item.media.map((images) => {
                                    if(images){
                                        if(images?.thumbnail == true){
                                            return (
                                                <div className="item">
                                                    <div className="package-image">
                                                        <img src={images.image} className="w-100" alt={item.title} />
                                                    </div>
                                                </div>
                                            )
                                        }
                                        
                                    }
                                })}
                            {/* </OwlCarousel> */}
                        {/* } */}
                        </div>
                        <div className="title" >
                            <p>{item.title}</p>
                        </div>
                    </div>
                </div>))
            : null}
            
            </div>
        </div>
    )
}

export default Stories
