import { useEffect, useState } from "react";
import ArrowUpwardIcon from "../../../assets/icons/up_Arrow.svg";
import { makeStyles, createTheme} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import "../../../assets/css/_common.scss";

const theme = createTheme({
  breakpoints: {
    values: {
      xs:600
    },
  },
}); 

const useStyles = makeStyles((theme) => ({
  toTop: {
    zIndex: 2,
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    bottom: "2vh",
    background: "linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%)",
    borderRadius: "11%",
    "&:hover, &.Mui-focusVisible": {
      transition: "0.3s",
      color: "#397BA6",
    },
    [theme.breakpoints.down('xs')]: {
    display:"none"
    },

  },
}));
export const ScrollToTop = () => {
  const classes = useStyles();
  const [visible, setVisible] = useState(false)
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 250){
      setVisible(true)
    } 
    else if (scrolled <= 250){
      setVisible(false)
    }
  };
  
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
    });
  };
  
  window.addEventListener('scroll', toggleVisible);

  return (
    <div>
        <IconButton
          onClick={scrollToTop}
          className={classes.toTop}
          aria-label="to top"
          component="span"
          style={{
            height: "60px",
            width: "60px",
            marginBottom: "38px",
            marginRight: "30px",
            // marginLeft: "1333px",
            display: visible ? '' : 'none'
          }}
        >
          <div>
            <img
              src={ArrowUpwardIcon}
              alt="scroll-up"
              className="h-6 w-6"
              aria-hidden="true"
            />
          </div>
        </IconButton>
    </div>
  );
};
