import { combineReducers } from "redux";
import authReducer from "./authReducer";
import commonReducer from "./commonReducer";

import corporatesReducers from "../reducers/corporatesReducers";
import profileReducer from "../reducers/profileReducer";
import homepageReducers from "../reducers/homeReducers";
import holidaysReducers from "./holidayReducers";
import experienceReducer from "./experienceReducer";

import eventReducers from "../reducers/eventsReducer";
import giftRequestReducers from "./giftRequestReducer";
import weddingsReducer from "./weddingsReducer";
const rootReducers = combineReducers({
  auth: authReducer,
  common: commonReducer,
  corporates: corporatesReducers,
  events: eventReducers,
  userProfile: profileReducer,
  homepage: homepageReducers,
  holidays: holidaysReducers,
  experiences: experienceReducer,
  giftRequest: giftRequestReducers,
  weddings:weddingsReducer,
});

export default rootReducers;
