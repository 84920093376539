import React from 'react';
import Label from '../common/label';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';


const InputComponent = ({ type, name, placeholder, value, onChange,onKeyPress, titleValue,disabled, msg, isTrue, maxLength, pattern, titleColor, inputClass, labelClass, hideInputContainer, hasUnderline, hasSeprator, hasFormLine, mobileUpdateSuccess, autoComplete}) => {
const mobileNumberChange=(e)=>{
    const re = /[0-9-+]/;
    if (e === '' || re.test(e) ||e ===undefined) {
  onChange(e)
    }
}
    return (
        <div className={`input-container ${type==='phonenumber'?'intl-phone-input':''}`}>
            {type==='phonenumber'?
            <PhoneInput
                placeholder={placeholder}
                value={value}
                withCountryCallingCode = {true}
                limitMaxLength={true}
                international={true}
                disabled={disabled}
                defaultCountry="IN"
                // className={`${hideInputContainer?'form-control':''} ${inputClass}` }
                onChange={(e)=>mobileNumberChange(e)}
                onKeyPress={(e) => {if(e.key === "Enter") {
                    e.preventDefault()
                    mobileUpdateSuccess()
                    e.target.blur();
                }}}
                />
            :<input 
                type={type}
                placeholder={placeholder} 
                name={name}
                value={value}
                onChange={onChange}
                onKeyPress={onKeyPress}
                maxLength={maxLength}
                pattern={pattern}
                disabled={disabled}
                className={inputClass}
                autoComplete={autoComplete ? "off" : ""}
            />}
            {hasUnderline&&<div class="line my8"></div>}
            {hasSeprator&&<div class="modal-seprator"></div>}
            {hasFormLine&&<div class="form-line"></div>}
            <Label title={titleValue}  className={labelClass} />
            {isTrue ? (
                <div>
                    <Label title={`${msg}`}  className={isTrue ? "error": ""}/>
                </div>
            ): null}
        </div>
    )
}

export default InputComponent
