import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from '../../../assets/images/common/feather_edit.svg';
import AddIcon from '../../../assets/images/common/feather_plus.svg';
import AddressModal from '../../common/profile/addressModal';
import EditAddress from '../../common/profile/editAddressModal';
import EditPhoneNumber from '../../common/profile/editPhoneNumber';
import OtpModal from '../../common/profile/otpModal';
import * as CorporateAction from '../../../redux/actions/corporatesAuthActions';
import * as ExperienceAction from '../../../redux/actions/experiencesAction';
import * as HolidaysAction from '../../../redux/actions/holidaysActions';
import {modalBoolVal, updateMN, updateNonCorpUserDetails, getProfileDetails, getNonCorporateProfileDetails, updatedMobileNumber } from '../../../redux/actions/profileAction';
import { Helmet } from 'react-helmet';
import InputComponent from '../inputComponent';
import { useSnackbar } from "notistack";
import { formatPhoneNumberIntl, isPossiblePhoneNumber } from 'react-phone-number-input';
import useClickOutside from '../../../hooks/useClickOutside';
import { ScrollToTop } from '..';

function MyProfile() {
    const USER_DETAILS = useSelector((state) => state.userProfile?.profile_details?.data);
    const history = useHistory();
    const [show, setShow] = useState(false);
    const [showOtpComponent, setShowOtpComponent] = useState(false);
    const [showAddress, setAddressShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleAddressClose = () => setAddressShow(false);
    const handleAddressShow = () => setAddressShow(true);
    const [enableMobileEdit, setEnableMobileEdit] = useState(false);
    const [isMobileOtpEnabled, setIsMobileOtpEnabled] = useState(false);
    const [mobileNumber, setMobileNumber] = useState(null);
    const [isMobileNoUpdated, setIsMobileNoUpdated] = useState(false);
    const mobileInputRef = useRef();
    const { enqueueSnackbar } = useSnackbar();
    const pacStartDate = useSelector( (state) => state.corporates.pacStartDate);
    const pacEndDate = useSelector( (state) => state.corporates.pacEndDate);
    const pacFlexibleDates = useSelector( (state) => state.corporates.pacFlexibleDates);

    // useClickOutside(mobileInputRef, () => {
    //     setEnableMobileEdit(false)
    //     if(enableMobileEdit && isMobileNoUpdated){
    //         saveMobileNumber()
    //     }
    //     console.log(mobileNumber, 'clicked outside');
    // });
    useClickOutside(mobileInputRef, () => mobileUpdateSuccess());
    const mobileUpdateSuccess = () => {
        setEnableMobileEdit(false)
        if(enableMobileEdit && isMobileNoUpdated){
            saveMobileNumber()
        }
    }

    const userDetails = JSON.parse(localStorage.getItem("userData"));
    const isCorporateAccount = userDetails?.user_details?.corporate_account;
    useEffect(() => {
        localStorage.setItem("points", USER_DETAILS?.points)
    }, [USER_DETAILS?.points]);
    useEffect(()=>{
        document.body.style.overflowY = "scroll";
    }, []);
    const dispatch = useDispatch()
    const goToCorporates = () => {
        dispatch(CorporateAction.setCategoryId(null));
        dispatch(CorporateAction.getSearchValue(""))
        dispatch(CorporateAction.getFilterValues(""))
        
        history.push({ pathname: "/corporates", data: {bool: true}} );
        window.scrollTo(0, 0);
     
    };
    const handleOtpComponent = (bool) => {
        setShowOtpComponent(bool);
        dispatch(modalBoolVal(showOtpComponent))
    }

    const closeOtpComponent = (showOtpComponent) => {
        setShowOtpComponent(false)
    }

    const editMobile = ()=>{
        console.log('edit Mobile');
        setTimeout(()=>{document.querySelector('.PhoneInputInput').focus()})
        if(isMobileOtpEnabled){
            handleShow();
        } else {
            setMobileNumber(USER_DETAILS?.user?.mobile);
            setEnableMobileEdit(true)
        }
    };

    const handleSuccess = () => {
        setEnableMobileEdit(false);
        enqueueSnackbar("Phone number updated successfully", {
            variant: "success",
        });
        let updatedDatabookData = JSON.parse(localStorage.getItem("bookData"))
        if(updatedDatabookData){
        const updatedData = {
            ...updatedDatabookData,
            resAPIStartDate: pacStartDate, 
            resAPIEndDate: pacEndDate, 
            resAPISelectedDates: pacFlexibleDates, 
            contact_number: mobileNumber,

        }
        localStorage.setItem("bookData", JSON.stringify(updatedData))
        dispatch(ExperienceAction.updateBookingData(updatedData));
        dispatch(HolidaysAction.updateBookingData(updatedData));
        dispatch(CorporateAction.updateBookingData(updatedData));
        }
        // dispatch(updatedMobileNumber(mobileNumber));
        localStorage.setItem("mobile",mobileNumber);
        refreshProfileDetails();
    };

    const handleErrors = (message) => {
        enqueueSnackbar(message, {
            variant: "error",
        });
    };

    const refreshProfileDetails = () => {
        if(isCorporateAccount){
            dispatch(getProfileDetails());
        }
        else {
            dispatch(getNonCorporateProfileDetails())
        }
    };

    const saveMobileNumber = ()=>{
        if(mobileNumber !== null){
            if((mobileNumber?.startsWith('+91') && mobileNumber.length!==13)  || !isPossiblePhoneNumber(mobileNumber)){
                enqueueSnackbar('Phone Number is not valid', {
                    variant: "error",
                });
                return false;
            }
            if(isCorporateAccount){
                dispatch(updateMN({mobile:mobileNumber}, handleSuccess, handleErrors));
            } else {
                dispatch(updateMN({mobile:mobileNumber}, handleSuccess, handleErrors));
                // dispatch(updateNonCorpUserDetails({mobile:mobileNumber.replace('+', '')}, handleSuccess, handleErrors));
            }
        
        }
        setIsMobileNoUpdated(false)
        
        
    };

    const handleMobileChange = (val)=>{
        console.log(val);
        if(mobileNumber !== val){
            setIsMobileNoUpdated(true)
        }
        setMobileNumber(val);
    };

     
    return (
        <div className="container">
            <Helmet>
                <title>Profile - Hammock Holidays</title>
                <meta name="description" content="User profile - Hammock Holidays" />
            </Helmet>
            <div className="profile--details">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <div className="contact-info mb-3">
                            <h5>My contact info</h5>
                            <div className="line"></div>
                            <div className="details-card"  ref={mobileInputRef}>
                                <div className="details" >
                                {!enableMobileEdit?<div>
                                {
                                    USER_DETAILS?.user?.mobile === "" ? null : <p onClick={()=>editMobile()}>{formatPhoneNumberIntl(!USER_DETAILS?.user?.mobile.startsWith('+')?`+${USER_DETAILS?.user?.mobile}`:USER_DETAILS?.user?.mobile)}</p>
                                }
                                {
                                    USER_DETAILS?.user?.mobile === "" ? <p className="mb-0" style={{fontSize: '16px',
                                        fontWeight: '400'}} onClick={()=>editMobile()}>Add mobile number</p> :  <p>Mobile number</p>
                                }</div>:
                                <div className="intl-number">
                                    
                                    <InputComponent
                                        type="phonenumber"
                                        name="number"
                                        
                                        placeholder="Enter mobile number"
                                        // placeholder={addNew?'Add your mobile number':'Edit your mobile number'}
                                        value={mobileNumber}
                                        onChange={handleMobileChange}
                                        titleValue={USER_DETAILS?.user?.mobile === ""?'Add your mobile number':'Edit your mobile number'}
                                        mobileUpdateSuccess={mobileUpdateSuccess}
                                        // msg={'Enter a valid  mobile number'}
                                        // isTrue={error.mobile}
                                        
                                    />
                                    
                                </div>}
                                    
                                </div>
                                {true?<div >
                                {
                                    USER_DETAILS?.user?.mobile === "" ? <img src={AddIcon} alt="AddIcon" onClick={()=>editMobile()}/> : 
                                    null
                                }
                                {/* {
                                    USER_DETAILS?.user?.mobile === "" ? <img src={AddIcon} alt="" onClick={()=>editMobile()}/> : 
                                    <img src={EditIcon} alt="" 
                                    />
                                } */}
                                    
                                </div>:
                                <div>
                                    <button className={'save--btn'} disabled={`${USER_DETAILS?.user?.mobile}`===`${mobileNumber}`} onClick={()=>saveMobileNumber()}>Save</button>
                                </div>
                                }
                                {
                                    show && <EditPhoneNumber contactNumber={USER_DETAILS?.user?.mobile} handleClose={handleClose} handleShow={handleShow} showModal={show} 
                                    handleOtpComponent={handleOtpComponent}
                                    modalFalse={() => closeOtpComponent(showOtpComponent)}
                                    addNew={USER_DETAILS?.user?.mobile === "" }
                                    /> 
                                }
                            </div>
                            <div className="details-card">
                                <div className="details">
                                    <p>{USER_DETAILS?.user?.email}</p>
                                    <p>Email</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                    {
                        isCorporateAccount ?  <div className="my-rewards text-center">
                        <h5>My Reward Points</h5>
                        <div className="balance">
                            <p>{USER_DETAILS?.points} pts</p>
                            <p>Balance</p>
                        </div>
                        <div className="line"></div>
                        <button type="button" className="btn proceed-to-book w-100" onClick={goToCorporates}>Explore deals</button>
                    </div> : null
                    }
                       
                        <div className="contact-info" style={{marginTop:"5px"}}>
                            <h5>My address</h5>
                            <div className="line"></div>
                            {
                                USER_DETAILS && USER_DETAILS.user.address.length > 0 && USER_DETAILS.user.address.map((item) => (
                                    <div className="address-card">
                                        <div className="details">
                                            <p>{item?.street}</p>
                                            <p>{item?.city}, {item?.state} {item?.pincode}</p>
                                        </div>
                                    <EditAddress key={item.id} addressDetails={item} />
                                        </div>
                                ))
                            }
                            {
                                USER_DETAILS && USER_DETAILS.user.address.length < 2 ? <div className="add-address">
                                    <div >
                                        <p className="new-address">Add new addres</p>
                                    </div>
                                     <AddressModal/>
                                    
                                </div> : ''
                            }

                        </div>
                    </div>
                </div>
            { showOtpComponent && <OtpModal btnTitle="Helo" showModal={showOtpComponent} handleClose={() => setShowOtpComponent(false)}/>}
            </div>

        </div>
    )
    
}



export default MyProfile
