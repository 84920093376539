import React, {useEffect, useRef} from "react";
import "../../assets/css/partials/travelInfo.scss";
import { useDispatch, useSelector } from "react-redux";
import OwlCarousel from "react-owl-carousel";
import { Link, useHistory, useLocation } from "react-router-dom";
import * as HolidaysAction from '../../redux/actions/holidaysActions';
import * as homeAction from '../../redux/actions/homeAction';
import { traveller_options } from "../../resources/carouselOptions";
import {
    HELP_TRAVELLERS,
    EXPERIENCE_SCHOOL,
    TRAVEL_N_HOSPITALITY
} from "../../resources/travellerInfo";
import moment from "moment";

function TravelInfo() {
    const history = useHistory();
    const dispatch = useDispatch();
    const SCROLL_REF = useSelector((state) => state.homepage.set_Scroll_vix);
    const EXP_IN_YEARS = moment().diff('1996-07-09', 'years')

    useEffect(() => {
        if (SCROLL_REF) {
            executeScroll();
          }
    }, [SCROLL_REF])

    const myRef = useRef(null)
    const executeScroll = () => {
      myRef.current.scrollIntoView();
      dispatch(homeAction.setScrollVix(false));
    }

    // experience for schools redirections 
    const goToDetailsPage = (fieldtrip) => {
        if (fieldtrip) {
            window.open('https://www.fieldtrips.in/', "_blank")
        }
        else {
            history.push(`/virtual-interactive-experiences`);
            window.scrollTo(0, 0);
        }
    }

    const goToVisasAndMore = (route, message) => {
        dispatch(HolidaysAction.setRouteValue(message));
        if(message === "Visas & More"){
          localStorage.setItem("visas_more", true);
          localStorage.removeItem("international");
          localStorage.removeItem("customize");
        }
        localStorage.setItem("routeFromHome", true)
        history.push(`/${route}`);
        window.scrollTo(0, 0);
      };
 

    return (
        <div className="travelInfo-holder">

            <div className="traveller-holder">
                <div className="container">
                    <h4>
                        We help our <span>Travellers</span>
                    </h4>
                </div>
                
                <div className="visa-travelInfo">
                    <div className="">
                        <div className="info-holder">
                            <div className="card-wrapper" >
                                {HELP_TRAVELLERS.length > 0 &&
                                    HELP_TRAVELLERS.map((item,idx) => (
                                        <Link to={`/holidays/visas-more`} style={{textDecoration:"none", color:"black"}} key={idx}>
                                        <div className="info-card cursor-pointer" onClick={() => goToVisasAndMore("holidays", "Visas & More")} >
                                            <div className="img-holder">
                                                <img src={item.image} alt={item.title} />
                                            </div>
                                            <div className="card-content" >
                                                <h2>{item.title}</h2>
                                                <p>{item.description}</p>
                                            </div>

                                        </div>
                                        </Link>
                                    ))}
                                    <div className="px-dummy"></div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>

            <div className="traveller-holder" ref={myRef}>
                <div className="container">
                    <h4>
                        Experience for <span>Schools</span>
                    </h4>
                </div>
                <div className="visa-travelInfo">
                    <div className="">
                        <div className="info-holder">
                            <div className="card-wrapper">
                                {EXPERIENCE_SCHOOL.length > 0 &&
                                    EXPERIENCE_SCHOOL.map((item,idx) => (
                                        <div className="info-card cursor-pointer" key={idx} onClick={() => goToDetailsPage(item?.fieldtrip)}>

                                            <div className="img-holder">
                                                <img src={item.image} alt={item.title} />
                                            </div>
                                            <div className="card-content">
                                                <h2>{item.title}</h2>
                                                <p>{item.description}</p>
                                            </div>

                                        </div>
                                    ))}
                                    <div className="px-dummy"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="traveller-holder">
                <div className="container">
                    <h4>
                        <span>{EXP_IN_YEARS} Years</span> in Travel & Hospitality
                    </h4>
                </div>
                <div className="visa-travelInfo">
                    <div className="">
                        <div className="info-holder">
                            <div className="card-wrapper">
                                {TRAVEL_N_HOSPITALITY.length > 0 &&
                                    TRAVEL_N_HOSPITALITY.map((item,idx) => (
                                        <div className="info-card" key={idx}>

                                            <div className="img-holder">
                                                <img src={item.image} alt={item.title} />
                                            </div>
                                            <div className="card-content">
                                                <h2>{item.title}</h2>
                                                <p>{item.description}</p>
                                            </div>

                                        </div>
                                    ))}
                                <div className="px-dummy"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TravelInfo;
