import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import './dateRangePicker.scss';

const Heading = ({ date, changeMonth, resetDate }) => (
  <nav className="calendar--nav">
    <a onClick={() => changeMonth(date.month() - 1)}>&#8249;</a>
    <h1 onClick={() => resetDate()}>{date.format('MMMM')} <small>{date.format('YYYY')}</small></h1>
    <a onClick={() => changeMonth(date.month() + 1)}>&#8250;</a>
  </nav>
);
var isRecurring;
var isSpecific;
var recurringDaysList;
var specificDaysList;
var isFlexible;
var packageDays;
var packageNights;
// var maxDate;
const Day = ({ currentDate, date, startDate, endDate, onClick, selectedDates }) => {
  
  let className = [];
  // here 0 = sunday, 1 = monday etc...
  let allDays = [0, 1, 2, 3, 4, 5, 6]
  
  if (isRecurring) {
    allDays = allDays.filter(val => !recurringDaysList.includes(val));
    allDays.forEach((item) => {
      if (date.weekday() === item) {
        className.push('disabled');
      }
    })
  }
  
  if (isSpecific) {
    className.push('disabled');
    specificDaysList.forEach((item) => {
      if (date.dayOfYear() === moment(item).dayOfYear() && moment(item).isAfter()) {
        className.push('enable-specific');
      }
    })
  }

  if(isFlexible){
    // console.log('is Flexible')
    if (selectedDates.indexOf(moment(date).format('DD-MM-YYYY'))>-1) {
      className.push('flexi-date');
    } else {

    }
    // className.push('start-specific');
  }

  if (moment().isSame(date, 'day')) {
    className.push('active');
  }


  if (date.isSame(startDate, 'day')) {
    if (isRecurring || isSpecific) {
      className.push('start-specific');
    }
    else {
      className.push('start');
    }
  }

  if (date.isBetween(startDate, endDate, 'day')) {
    className.push('between');
  }

  if (date.isSame(endDate, 'day')) {
    className.push('end');
  }

  if (!date.isSame(currentDate, 'month')) {
    className.push('muted');
  }
  if (date.isBefore(moment(), 'day')) {
    className.push('disabled');
  }
  // if (isFlexible&&maxDate&&date.isAfter(moment(maxDate, 'YYYY-MM-DD'), 'day')) {
    // className.push('disabled');
  // }

  return (
    <span onClick={() => onClick(date)} currentDate={date} id="dateList" className={className.join(' ')}>{date.date()}</span>
  )
};

const Days = ({ date, startDate, endDate, onClick, selectedDates }) => {
  
  const thisDate = moment(date);
  const daysInMonth = moment(date).daysInMonth();
  const firstDayDate = moment(date).startOf('month');
  const previousMonth = moment(date).subtract(1, 'month');
  const previousMonthDays = previousMonth.daysInMonth();
  const nextsMonth = moment(date).add(1, 'month');
  let days = [];
  let labels = [];

// console.log({isRecurring, isSpecific})
  for (let i = 0; i < 7; i++) {
    labels.push(<span className="label">{moment().day(i).format('ddd')}</span>);
  }

  for (let i = firstDayDate.day(); i >= 1; i--) {
    previousMonth.date(previousMonthDays - i + 1);
    if (isRecurring || isSpecific) {
      days.push(
        <Day key={moment(previousMonth).format('DD MM YYYY')} onClick={(date) => onClick(date)} currentDate={date} date={moment(previousMonth)} startDate={startDate} endDate="" />
      );
    }
    else {
      days.push(
        <Day key={moment(previousMonth).format('DD MM YYYY')} onClick={(date) => onClick(date)} currentDate={date} date={moment(previousMonth)} startDate={startDate} endDate={endDate} selectedDates={selectedDates}  />
      );
    }

  }


  for (let i = 1; i <= daysInMonth; i++) {
    thisDate.date(i);
    if (isRecurring || isSpecific) {
      days.push(
        <Day key={moment(thisDate).format('DD MM YYYY')} onClick={(date) => onClick(date)} currentDate={date} date={moment(thisDate)} startDate={startDate} endDate=""  />
      );
    }
    else {
      days.push(
        <Day key={moment(thisDate).format('DD MM YYYY')} onClick={(date) => onClick(date)} currentDate={date} date={moment(thisDate)} startDate={startDate} endDate={endDate} selectedDates={selectedDates}  />
      );
    }
  }

  const daysCount = days.length;
  for (let i = 1; i <= (42 - daysCount); i++) {
    nextsMonth.date(i);
    if (isRecurring || isSpecific) {
      days.push(
        <Day key={moment(nextsMonth).format('DD MM YYYY')} onClick={(date) => onClick(date)} currentDate={date} date={moment(nextsMonth)} startDate={startDate} endDate="" />
      );
    }
    else {
      days.push(
        <Day key={moment(nextsMonth).format('DD MM YYYY')} onClick={(date) => onClick(date)} currentDate={date} date={moment(nextsMonth)} startDate={startDate} endDate={endDate} selectedDates={selectedDates}  />
      );
    }
  }

  return (
    <nav className="calendar--days">
      {labels.concat()}
      {days.concat()}
    </nav>
  );
};

class DateRangePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: moment(),
      startDate: moment(props.prevStartDate),
      endDate: moment(props.prevEndDate),
      selectedDates: props.selectedDates
    };
  }
  resetDate() {
    this.setState({
      date: moment()
    });
  }

  changeMonth(month) {
    const { date } = this.state;

    date.month(month);

    this.setState(
      date
    );
  }

  componentDidUpdate(prevState) {
    if(isFlexible){
      this.props.handleDates(this.state.selectedDates)
    } else if (prevState.startDate !== this.state.startDate || prevState.endDate !== this.state.endDate) {
      this.props.handleDates(this.state.startDate, this.state.endDate)
    }
  }

  changeDate(date) {
    // console.log(date)
    console.log(date, "date")
    if(isFlexible){
      let tmpSelectedDates = [...this.state.selectedDates];
      
        
      if(tmpSelectedDates?.indexOf(moment(date).format('DD-MM-YYYY'))===-1){
        tmpSelectedDates.push(moment(date).format('DD-MM-YYYY'));
      } else {
        
        tmpSelectedDates.splice(tmpSelectedDates?.indexOf(moment(date).format('DD-MM-YYYY')), 1);
      }
      this.setState({
          selectedDates:tmpSelectedDates
      });
      
      return false;
    }
    let { startDate, endDate } = this.state;

    if(isSpecific || isRecurring){
      this.setState({
        startDate:moment(date),
        endDate:null
      })
      return false;
    }

    

    if(!startDate || !moment(startDate).isValid() || date.isBefore(startDate, 'day') ||  (moment(startDate).isValid() && moment(endDate).isValid() )) {
      startDate = moment(date);
      endDate = null;
    }  else if (date.isSame(startDate, 'day') && date.isSame(endDate, 'day')) {
      startDate = null;
      endDate = null;
    }  else if (date.isAfter(startDate, 'day')) {
      endDate = moment(date);
    }
    
    // if (startDate === null || date.isBefore(startDate, 'day') || !startDate.isSame(endDate, 'day')) {
    //   if(this.props.packageNights &&  !moment(startDate).isValid()){
    //     endDate = moment(date).add(this.props.packageNights, 'days');
    //   } else {
    //     endDate = moment(date);
    //   }
    //   startDate = moment(date);
      
    // } else if (date.isSame(startDate, 'day') && date.isSame(endDate, 'day')) {
    //   startDate = null;
    //   endDate = null;
    // } else if (date.isAfter(startDate, 'day')) {
    //   endDate = moment(date);
    // }
    this.setState({
      startDate,
      endDate
    });
  }

  render() {
    const { date, startDate, endDate, selectedDates } = this.state;
    isRecurring = this.props.isRecurring;

    const isPackage = this.props.isPackage;
    isSpecific = this.props.isSpecific;
    recurringDaysList = this.props.recurringDaysList;
    specificDaysList = this.props.specificDaysList;
    isFlexible = this.props.isFlexible;
    // maxDate=this.props.maxDate
    return (
      <div className="calendar">
        <Heading date={date} changeMonth={(month) => this.changeMonth(month)} resetDate={() => this.resetDate()} />
        
        <Days onClick={(date) => this.changeDate(date)} date={date} startDate={startDate} endDate={endDate} selectedDates={selectedDates} />
      </div>
    );
  }
}

export default DateRangePicker;