import React, { useEffect, useRef, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import Header from "../common/header";
import HolidayHeader from "../common/holidayHeader";
import Footer from "../common/footer";
import { useDispatch, useSelector } from "react-redux";
import backIcon from "../../assets/images/common/back-icon.svg";
import moment from "moment";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import * as EventsAction from "../../redux/actions/eventsAction";
import Dialog from "@material-ui/core/Dialog";
import styled from "styled-components";
import LoginPopup from "../auth/login";
import SignupPopup from "../auth/signUp";
import DialogTitle from "@material-ui/core/DialogTitle";
import "../../assets/css/partials/events/events.scss";
import { handleProceedToBook } from "../../redux/actions/corporatesAuthActions";
import closeIcon from "../../assets/images/common/close-modal.svg";
import SelectDateAndTime from "../../components/events/selectDateAndTime";
import { Fragment } from "react";
import Loader from "../common/loader";
import * as HolidaysAction from "../../redux/actions/holidaysActions";
import * as CorporateAction from "../../redux/actions/corporatesAuthActions";
import * as ExperienceAction from "../../redux/actions/experiencesAction";
import CalenderModal from "../corporates/calenderModal";
import HolidayRangePicker from "../holidays/holidayRangePicker";
import GuestsModal from "../common/guestsModal";
import { Tabs, Tab } from "react-bootstrap";
import CloseIcon from "../../assets/images/common/close-modal.svg";
import ContactDetailsCard from "../common/contactDetailsCard";
import SanitizedStayTagPopup from "../common/sanitizedStayPopup";
import { Helmet } from "react-helmet";
import PackageDetailsCarousel from "../common/packageDetailsCarousel";
import $ from "jquery";
import Testimonials from "../common/testimonial";
import TestimonialPopup from "../common/testimonialPopup";
import useClickOutside from "../../hooks/useClickOutside";
// import BookThisForm from './bookThisForm';
import SimilarOffers from "./similarOffers";
import NearByExperiences from "./nearByExperiences";
import ViewMoreHolidays from "./viewMoreHolidays";
import { ScrollToTop } from "../common";
import "../../assets/css/partials/common/priceNotes.scss";
import "../../assets/css/partials/itinerary-and-staybased.scss";
import PriceNote from "../common/priceNote";
import Breadcrum from "../common/breadcrum";
import ItineraryFeatures from "../common/packageDetails/itineraryFeaturesCard";
import PackageTabsHolder from "../common/packageDetails/packageTabsHolder";
import ItineraryPackageDates from "../common/packageDetails/itineraryPackageDates";
import CustomerReviews from "../common/packageDetails/customerRevews";
import ItineraryEnquiryForm from "../common/packageDetails/itineraryEnquiryForm";
import StayFeaturesCard from "../common/packageDetails/stayFeaturesCard";
import Amenities from "../common/packageDetails/amenities";
const owl_images = {
  items: 1,
  margin: 0,
  nav: false,
  loop: true,
  dots: true,
  autoplay: true,
  smartSpeed: 500,
  animateIn: "fadeIn",
  animateOut: "fadeOut",
};

const StyledDialog = styled(Dialog)`
  & > .MuiDialog-container > .MuiPaper-root {
    width: 430px;
    background-color: #f5f5f5;
    position: relative;
  }
  & > .MuiDialog-container > .MuiPaper-root > .close-login {
    position: absolute;
    top: 12px;
    right: 23px;
    cursor: pointer;
  }
`;

function HolidayDetails(holidayDetailsPage) {
  const pointsEl = useRef(null);
  const location = useLocation();

  const search = window.location.search;
  // console.log(window.location.hash)
  const params = new URLSearchParams(search);
  const isPack = params.get("isPack");
  const isSimilarOffer = JSON.parse(isPack);

  const holidayID = holidayDetailsPage.match.params.id;
  const loginUserData = useSelector((state) => state.auth.login_user_otp);
  const dispatch = useDispatch();
  const history = useHistory();
  const [loader, setLoader] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [request, setRequest] = useState("");
  const [clickType, setClickType] = useState("login");

  const [adultGuestCount, setAdultGuestCount] = useState(0);
  const [childrensGuestCount, setChildrensGuestCount] = useState(0);
  const [count, setCount] = useState(0);
  const [showTestimonialPopup, setShowTestimonialPopup] = useState(false);
  const [popupData, setPopupData] = useState("");
  const [testimonialSH, setTestimonialSH] = useState({});

  const [dataChangedBool, setDataChangedBool] = useState(false);
  const [dataChanged, setDataChanged] = useState({});
  const [showCalenderModal, setShowCalenderModal] = useState(false);
  const [showDateRangeModal, setShowDateRangeModal] = useState(false);
  const [flexiDates, setFlexiDates] = useState([]);
  const [showContactModal, setShowContactModal] = useState(false);
  const activePackageIndex = useSelector(
    (state) => state?.holidays?.activePackageIndex
  );
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [resAPIStartDate, setResAPIStartDate] = useState(null);
  const [resAPIEndDate, setResAPIEndDate] = useState(null);
  const [resAPISelectedDates, setResAPISelectedDates] = useState(null);
  const HOLIDAY_DETAILS = useSelector(
    (state) => state?.holidays?.holiday_details?.data
  );
  const SCROLL_REF = useSelector((state) => state.holidays.set_Scroll);
  const [category, setCategory] = useState("Overview");
  const [categoryId, setCategoryId] = useState("overview");
  const [showPoupupDateRangeModal, setShowPoupupDateRangeModal] = useState(true);


  const SECTION_NAME =
    HOLIDAY_DETAILS && HOLIDAY_DETAILS?.holiday?.section.name;
  const SECTION = HOLIDAY_DETAILS && HOLIDAY_DETAILS?.holiday?.section;
  const SECTION_ID = HOLIDAY_DETAILS && HOLIDAY_DETAILS?.holiday?.section.id;
  const HOLIDAY_TESTIMONIALS = useSelector(
    (state) => state?.holidays?.holiday_testimonial?.data
  );
  const referenceId = useSelector((state) => state?.holidays?.referenceId);

  const initialPackageData =
    HOLIDAY_DETAILS && HOLIDAY_DETAILS?.holiday?.packages[activePackageIndex];
  const [packageIdGet, setPackageId] = useState(
    HOLIDAY_DETAILS?.holiday?.packages[0].id
  );
  const packageRef = useRef()
  const executeScrollTop = () => {

    packageRef?.current.scrollIntoView()
    if(localStorage.getItem("prevUrl") !== "/"){
    dispatch(HolidaysAction.setScroll(false))
    }
    window.scrollTo(0, 0)

  };
  useEffect(() => {
    if (SCROLL_REF) {
      let idx = localStorage.getItem("hol-actidx");
      if (idx) {
        packageChangeHandler(idx)
      }
    }
    if (SCROLL_REF && !loader) {
      executeScrollTop();
    }
  }, [SCROLL_REF, loader]);
  useEffect(() => {
    packageRef?.current?.scrollIntoView()
  }, [packageRef?.current])
  const [calendarDuration, setCalendarDuration] = useState();
  const isFiltered = JSON.parse(localStorage.getItem("filteredList"));
  const HOLIDAY_INFO = HOLIDAY_DETAILS?.holiday?.packages[activePackageIndex];
  const packageType = HOLIDAY_INFO && HOLIDAY_INFO.package_type;
  const PRICE = useSelector((state) => state?.holidays?.holiday_details?.data);

  const userDetails = JSON.parse(localStorage.getItem("userData"));
  const isCorporateAccount = userDetails?.user_details?.corporate_account;
  const HOLIDAYS_DATA = useSelector(
    (state) => state?.holidays?.holiday_packages?.data
  );

  const [totalPrice, setotalPrice] = useState(HOLIDAY_INFO?.price);
  const [scroll, setScroll] = useState(false);
  const [isStuck, setIsStuck] = useState(false);
  const myRef = useRef(null);
  const discriptionRef = useRef();
  const stayRef = useRef();
  const isStay = packageType == "staybased"
  const isItinerary = packageType == "itinerary"
  // const [showVideoPopup, setShowVideoPopup] = useState(false);
  // const popupRef = useRef();
  // useClickOutside(popupRef, (e) => {
  //   setTimeout(() => setShowVideoPopup(false), 100);
  // });
  const contactModalRef = useRef();
  useClickOutside(contactModalRef, (e) => {
    setTimeout(() => setShowContactModal(false), 100);
  });
  // const handleVideoPopup = () => {
  //   setShowVideoPopup(true);
  // };
  const executeScroll = () => {
    if (isItinerary) {
      if (categoryId == "dates") {
        scrolltoView()
      }
      setCategory(Dates);
      setCategoryId("dates");
      setcategoryChanged(true);


    } else {
      myRef.current.scrollIntoView();
      setTimeout(() => document
        .querySelector(".moblie-tab-holder")
        ?.classList.remove("fixed"), 200)
      pauseVideos();
    }
  };
  let dateRangeMinDays = null;
  let dateRangeMaxDays = null;

  const companyDomain = userDetails?.organisation?.email_domain;
  const isWipro = companyDomain === "wipro.com";
  // const dateRangeList = dataChangedBool ? dataChanged?.schedule_data.selectedDates : initialPackageData?.schedule_data.selectedDates
  const isPackage = dataChangedBool
    ? dataChanged?.schedule_type == "package"
    : initialPackageData?.schedule_type == "package";
  const packageData = dataChangedBool
    ? dataChanged?.schedule_data
    : initialPackageData?.schedule_data;
  const days = packageData?.days;
  const nights = packageData?.nights;
  const isDateRange = dataChangedBool
    ? dataChanged?.schedule_type == "date_range"
    : initialPackageData?.schedule_type == "date_range";
  let flexiDatesLength = flexiDates?.length;
  const isFlexible = dataChangedBool
    ? dataChanged?.flexi_schedule
    : initialPackageData?.flexi_schedule;
  const isEnablePayment = dataChangedBool
    ? dataChanged?.enable_payment
    : initialPackageData?.enable_payment;
  const [state, setState] = useState({
    dates: "",
    guest: "",
    infantValidate: "",
    message: "",
  });
  const [showForm, setShowForm] = useState(false);
  const [categoryChanged, setcategoryChanged] = useState(false);
  const [error, setError] = useState({
    dates: false,
    guest: false,
    infantValidate: false,
  });

  const handleChange = (key, value) => {
    setState(() => ({
      ...state,
      [key]: value.target.value,
    }));
  };
  const [activePackId, setActivePackId] = useState(initialPackageData?.id)
  const reviewStatus = useSelector((state) => state?.holidays?.reviewStatus);
  const reviewList = useSelector((state) => state?.holidays?.reviewList);
  const holidaysReviewListTotalPage = useSelector((state) => state?.holidays?.reviewListTotalPage);
  const holidaysReviewListCurrentPage = useSelector((state) => state?.holidays?.reviewListCurrentPage);

  useEffect(() => {
    document.getElementById("scroller").scrollIntoView();
    setIsStuck(false)
  }, []);

  useEffect(() => {
    if (isSimilarOffer) {
      const activePkgId = localStorage.getItem("activePackageId")
      HOLIDAY_DETAILS?.holiday?.packages?.map((pack, idx) => {
        if (activePkgId == pack?.id) {
          dispatch(HolidaysAction.changePackageIndex(idx))
          setActivePackId(pack?.id)
        }
      })
    }
  }, [HOLIDAY_DETAILS])
  useEffect(() => {
    if (isSimilarOffer || isFiltered) {
      if (isSimilarOffer) {
        setDataChangedBool(false);
        setShowDateRangeModal(false)


      }
    } if (holidayID) {
      dispatch(HolidaysAction.getHolidayDetails(holidayID, handleLoader));
    }
    // hide/show confirm button on scroll
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 650);
    });
    localStorage.removeItem("dateRangeIndex");
  }, [holidayID]);
  useEffect(() => {
    setcategoryChanged(false)
    setCategory("Overview");
    setCategoryId("overview");

    if (initialPackageData?.id) {
      dispatch(HolidaysAction.reviewListing(initialPackageData?.id, 1));
      setActivePackId(initialPackageData?.id)
      setShowDateRangeModal(false)
      dispatch(
        HolidaysAction.getTestimonials(
          dataChangedBool ? dataChanged?.id : initialPackageData?.id
        )
      );
    }
  }, [initialPackageData?.id]);
  useEffect(() => {
    if (initialPackageData?.id && Object.keys(loginUserData).length !== 0 &&
      loginUserData.constructor === Object) {
      dispatch(HolidaysAction.ReviewPostCheck(initialPackageData?.id));
    }
  },
    [
      initialPackageData?.id, loginUserData
    ])
  const handleReviewListPagination = (e, value) => {
    e.preventDefault();
    dispatch(HolidaysAction.reviewListing(initialPackageData?.id, value));

  }
  useEffect(() => {
    if (categoryChanged) {
      scrolltoView();
    }
  }, [categoryId, holidaysReviewListCurrentPage]);
  useEffect(() => {
    document.getElementById("scroller").scrollIntoView();

    // setMaxDate(initialPackageData?.valid_till);

    let pid = localStorage.getItem("exp-pid");
    if (pid) {
      setTimeout(() => {
        pointsChangeHandler(parseInt(pid));
      });
    }
    let isJustLoggedIn = localStorage.getItem("isJustLoggedIn");
    if (isJustLoggedIn === "true") {
      handleSubmit();
    }
    dispatch(HolidaysAction.changePackageIndex(0))
    return () => {
      localStorage.removeItem("exp-pid");
    };
  }, []);

  useEffect(() => {
    setotalPrice(HOLIDAY_INFO?.revised_price ? HOLIDAY_INFO?.revised_price : HOLIDAY_INFO?.price);
  }, [HOLIDAY_INFO?.price]);

  const handleLoader = () => {
    setLoader(false);
  };
  const startLoader = () => {
    setLoader(true)
  }

  const calcTestimonialScrollHeight = () => {
    // setTestimonialSH
    if (HOLIDAY_TESTIMONIALS && HOLIDAY_TESTIMONIALS.length) {
      let tempTestimonialSH = {};
      HOLIDAY_TESTIMONIALS?.forEach((val, index) => {
        tempTestimonialSH[`testimonial-block-${index}`] =
          document.getElementById(`testimonial-block-${index}`)?.scrollHeight;
      });
      setTestimonialSH(tempTestimonialSH);
    }
  };
  useEffect(() => {
    if (location?.state?.id && HOLIDAY_DETAILS?.holiday?.packages?.length > 0&&!SCROLL_REF) {
      HOLIDAY_DETAILS?.holiday?.packages?.map((pack, idx) => {
        if (location.state.id === pack?.id) {
          dispatch(HolidaysAction.changePackageIndex(idx))
          setActivePackId(pack?.id)
        }
      })

    }
  }, [location?.state?.id, HOLIDAY_DETAILS?.holiday?.packages])
  const handleTestimonialPopup = (item) => {
    setShowTestimonialPopup(true);
    setPopupData(item);
    pauseVideos();
  };

  useEffect(() => {
    setTimeout(() => {
      calcTestimonialScrollHeight();
    });
  }, [HOLIDAY_TESTIMONIALS]);


  const sortedPackages = HOLIDAY_DETAILS?.holiday?.packages?.map((pack, idx) => {
    return {
      id: pack?.id,
      price: pack.revised_price ? pack.revised_price : pack.price
    }
  }).sort((a, b) => a.price - b.price)
  const scrollToFormView = () => {
    var elementPosition = document
      .querySelector(".booking-holder")
      .getBoundingClientRect().top;
    var add = isItinerary ? 150 : 100
    var offsetPosition = elementPosition + window.pageYOffset - add;
    window.scrollTo({ top: offsetPosition, behavior: "smooth" });

  };

  const handleSubmit = () => {
    const formData = JSON.parse(localStorage.getItem("formData"));
    let data = JSON.parse(localStorage.getItem("userData"));
    let mobile = localStorage.getItem("mobile");
    let full_name = localStorage.getItem("full_name");
    const bookData = {
      pack_id: formData?.pack_id,
      adultGuestCount: formData.adultGuestCount,
      childrensGuestCount: formData?.childrensGuestCount,
      count: formData?.count,
      request: formData?.request,
      totalPrice: formData?.totalPrice,
      points: isCorporateAccount ? data?.points : "",
      amount: totalPrice - data.points,
      name: full_name,
      email: data?.user_details?.email,
      contactNumber: mobile,
      resAPIStartDate: formData?.resAPIStartDate,
      resAPIEndDate: formData?.resAPIEndDate,
      resAPISelectedDates: formData?.resAPISelectedDates,
      schedule_type: formData?.schedule_type,
    };
    dispatch(HolidaysAction.handleProceedToBook(bookData));
    dispatch(
      HolidaysAction.changeDataforProceedToBook(
        formData?.dataChanged,
        formData?.dataChangedBool
      )
    );
    dispatch(
      CorporateAction.handleStartAndEndDate(
        formData?.resAPIStartDate,
        formData?.resAPIEndDate,
        formData?.resAPISelectedDates
      )
    );
    history.push("/holidays/checkout");
  };
  const closeHandler = () => {
    resetFormFields();
    setShowForm(false);
    setShowPoupupDateRangeModal(true)
  };

  const submitHandler = (e) => {
    const getToken = localStorage.getItem("token");

    e.preventDefault();
    if (!state.dates || state.dates === "" || startDate === false) {
      setError({ dates: true });
      scrollToFormView();
    } else if (
      !state.guest ||
      state.guest === "" ||
      state.guest === `${0} adults, ${0} kids, ${0} infants`
    ) {
      setError({ guest: true });
      scrollToFormView();
    } else if (
      (count >= 1 || childrensGuestCount >= 1) &&
      adultGuestCount === 0
    ) {
      setError({ infantValidate: true });
      scrollToFormView();
    } else {
      if (getToken === "" || getToken == null) {
        // alert("please login");
        const formData = {
          pack_id: dataChangedBool ? dataChanged?.id : initialPackageData?.id,
          adultGuestCount,
          childrensGuestCount,
          count,
          request,
          totalPrice,
          startDate,
          endDate,
          resAPIStartDate,
          resAPIEndDate,
          resAPISelectedDates,
          schedule_type: dataChangedBool
            ? dataChanged?.schedule_type
            : initialPackageData?.schedule_type,
          dataChanged: dataChanged,
          dataChangedBool: dataChangedBool,
        };
        localStorage.setItem("formData", JSON.stringify(formData));
        setShowLoginModal(true);
      } else {
        let data = JSON.parse(localStorage.getItem("userData"));
        let mobile = localStorage.getItem("mobile");
        let full_name = localStorage.getItem("full_name");
        const bookData = {
          pack_id: dataChangedBool ? dataChanged?.id : initialPackageData?.id,
          adultGuestCount,
          childrensGuestCount,
          count,
          request,
          totalPrice,
          points: isCorporateAccount ? data?.points : "",
          amount: totalPrice - data.points,
          name: full_name,
          email: data?.user_details?.email,
          contactNumber: mobile,
          startDate,
          endDate,
          resAPIStartDate,
          resAPIEndDate,
          resAPISelectedDates,
          schedule_type: dataChangedBool
            ? dataChanged?.schedule_type
            : initialPackageData?.schedule_type,
        };
        dispatch(HolidaysAction.handleProceedToBook(bookData));
        dispatch(
          HolidaysAction.changeDataforProceedToBook(
            dataChanged,
            dataChangedBool
          )
        );
        dispatch(
          CorporateAction.handleStartAndEndDate(
            resAPIStartDate,
            resAPIEndDate,
            resAPISelectedDates
          )
        );
        history.push("/holidays/checkout");
      }
    }
  };

  const handleClose = () => {
    setShowLoginModal(false);
  };
  const pauseVideos = () => {
    let player = $("iframe");

    if (player) {
      player.map((index) => {
        $("iframe")[index].contentWindow.postMessage(
          '{"event":"command","func":"' + "pauseVideo" + '","args":""}',
          "*"
        );
        $("iframe")[index].contentWindow.postMessage(
          '{"method":"pause","value":"' + "pauseVideo" + '","args":""}',
          "*"
        );
      });
    }
    $(".owl-item")
      .find("video")
      .each(function () {
        this.pause();
      });
  };

  const handleDatesData = (sDate, eDate, selectedDates) => {
    setShowCalenderModal(false);
    setStartDate(sDate);
    setEndDate(eDate);
    setState(() => ({
      ...state,
      dates: `${sDate} - ${eDate}`,
    }));
    if (selectedDates && selectedDates?.length > 0) {
      setFlexiDates(selectedDates);
      setStartDate(selectedDates[0]);
      setState(() => ({
        ...state,
        dates: selectedDates,
      }));
    }
  };

  const handleResAPIStartAndEndDate = (
    resStartDate,
    resEndDate,
    selectedDates
  ) => {
    setResAPIStartDate(resStartDate);
    setResAPIEndDate(resEndDate);
    setResAPISelectedDates(selectedDates);
  };

  const handleNumberOfGuests = () => {
    setShowModal(true);
    pauseVideos();
    if (showModal) {
      document.getElementById("guestModal").classList.remove("hide");
      document.getElementById("guestModal").classList.add("show");
    }
  };

  const guestDatahandler = (adultData, childrenData, countData) => {
    setShowModal(false);
    setAdultGuestCount(adultData);
    setChildrensGuestCount(childrenData);
    setCount(countData);
    setState(() => ({
      ...state,
      guest: `${adultData} adults, ${childrenData} kids, ${countData} infants`,
    }));
  };

  const selectDateHandler = () => {
    let packageDuration = dataChangedBool
      ? dataChanged?.schedule_data?.days?.match(/\d+/)[0]
      : initialPackageData !== undefined &&
      initialPackageData?.schedule_data?.days?.match(/\d+/)[0];
    setCalendarDuration(packageDuration);
    setShowCalenderModal(true);
    pauseVideos();
  };

  const selectDateRangeHandler = () => {
    setShowDateRangeModal(true);
    pauseVideos();
    if (showDateRangeModal) {
      document.getElementById("holidayModal")?.classList.remove("hide");
      document.getElementById("holidayModal")?.classList.add("show");
    }
  };
  const packageChangeHandler = (id) => {
    resetFormFields()
    HOLIDAY_DETAILS?.holiday?.packages?.map((pack, idx) => {
      if (id == pack?.id) {
        dispatch(HolidaysAction.changePackageIndex(idx))
        setActivePackId(id)
      }
    })

    // window.scrollTo(0, 0)
  }
  const pointsChangeHandler = (pkgId) => {
    localStorage.setItem("exp-pid", pkgId);
    resetFormFields();
    dispatch(HolidaysAction.getHolidayDetails(holidayID, handleLoader));
    let packageDuration = dataChangedBool
      ? dataChanged?.schedule_data?.days?.match(/\d+/)[0]
      : initialPackageData !== undefined &&
      initialPackageData?.schedule_data?.days?.match(/\d+/)[0];
    setCalendarDuration(packageDuration);
    setDataChangedBool(true);
    setShowDateRangeModal(false);
    HOLIDAY_DETAILS?.holiday?.packages?.filter((data) => {
      if (data?.id == pkgId) {
        setDataChanged(data);
        setPackageId(data.id);
        setotalPrice(data.price);
      }
    });
  };

  const handleContactModal = () => {
    setShowContactModal(true);
    pauseVideos();
  };
  const handleShowForm = async (sDate, eDate) => {
    resetFormFields();

    if (sDate || eDate !== undefined) {
      handleResAPIStartAndEndDate(sDate, eDate, null);
      setStartDate(sDate);
      setEndDate(eDate);
      setShowPoupupDateRangeModal(false)
      setState(() => ({
        ...state,
        dates: `${sDate} - ${eDate}`,
        guest: "",
      }));
    } else {
      setState(() => ({
        ...state,
        dates: "",
        guest: "",
      }));
    }

    setShowCalenderModal(false);
    setShowForm(true);
  };

  const resetFormFields = () => {
    setAdultGuestCount(0);
    setChildrensGuestCount(0);
    setCount(0);
    setStartDate(null);
    setEndDate(null);
    setFlexiDates([]);
    setError({
      dates: false,
      guest: false,
      infantValidate: false,
    });
    setState(() => ({
      ...state,

      dates: "",
    }));

    setRequest("");
  };

  const goBack = () => {
    // history.goBack()
    // dispatch(HolidaysAction.setScroll(true));
  };

  const calculateDays = () => {
    const arr = [];
    if (!dataChangedBool) {
      if (
        initialPackageData &&
        initialPackageData?.schedule_type == "date_range"
      ) {
        initialPackageData &&
          initialPackageData?.schedule_data?.selectedDates?.map((date) => {
            const startDate = moment(`${date.to}`, "DD-MM-YYYY");
            const endDate = moment(`${date.from}`, "DD-MM-YYYY");
            const result = endDate.diff(startDate, "days");
            arr.push(Math.round(Math.abs(result) + 1));
          });
      }
    } else {
      if (dataChanged && dataChanged?.schedule_type == "date_range") {
        dataChanged &&
          dataChanged?.schedule_data?.selectedDates?.map((date) => {
            const startDate = moment(`${date.to}`, "DD-MM-YYYY");
            const endDate = moment(`${date.from}`, "DD-MM-YYYY");
            const result = endDate.diff(startDate, "days");
            arr.push(Math.round(Math.abs(result) + 1));
          });
      }
    }

    const min = Math.min(...arr);
    const max = Math.max(...arr);
    dateRangeMinDays = min;
    dateRangeMaxDays = max;
    if (min !== max) {
      return (
        <span>
          Min {min} {min == 1 ? "day" : "days"}, Max {max}{" "}
          {max == 1 ? "day" : "days"}
        </span>
      );
    } else {
      return (
        <span>
          {" "}
          Max {max} {min == 1 ? "day" : "days"}
        </span>
      );
    }
  };

  const getGuestCount = () => {
    // `${adultGuestCount} adult(s), ${childrensGuestCount} kid(s), ${count} infant(s)` //old code
    let tmpGuestsArray = [];
    if (adultGuestCount > 0) {
      tmpGuestsArray.push(
        `${adultGuestCount} adult${adultGuestCount > 1 ? "s" : ""}`
      );
    }
    if (childrensGuestCount > 0) {
      tmpGuestsArray.push(
        `${childrensGuestCount} kid${childrensGuestCount > 1 ? "s" : ""}`
      );
    }
    if (count > 0) {
      tmpGuestsArray.push(`${count} infant${count > 1 ? "s" : ""}`);
    }
    return tmpGuestsArray.join(", ");
  };

  const goBackFun = () => {
    let urlHistory = JSON.parse(localStorage.getItem("urlHistory"));
    if (!localStorage.getItem("prevUrl")) {
      localStorage.setItem("holidayCategory", SECTION_ID);
      history.push("/holidays");
    } else if (
      urlHistory[0].pathname === urlHistory[urlHistory.length - 1].pathname &&
      urlHistory.length > 2
    ) {
      history.push("/holidays");
    } else if (localStorage.getItem("prevUrl") === "/") {
      history.push("/");
    } else if (urlHistory[0].pathname?.includes("/checkout") && localStorage.getItem("prevUrl")?.includes("/checkout")) {
      history.push("/holidays");
    }
    else {
      history.goBack();
    }
  };
  useEffect(() => {
    if (resAPIStartDate || resAPIEndDate || resAPISelectedDates?.length) {
      setError({ dates: false });
    }
  }, [resAPIEndDate, resAPIEndDate, resAPISelectedDates]);
  useEffect(() => {
    if (adultGuestCount !== 0 || childrensGuestCount !== 0 || count !== 0) {
      setError({ guest: false });
    }
  }, [adultGuestCount, childrensGuestCount, count]);

  useEffect(() => {
    if (showContactModal || showForm) {
      document.querySelector("html").style.overflow = "hidden";
    } else {
      document.querySelector("html").style.overflow = "scroll";
    }
  }, [showContactModal, showForm]);

  const calculateDaysProps = () => {
    if (isPackage) {
      return (
        <span>
          {days && days !== "0"
            ? days && days === "1"
              ? `${days} day `
              : `${days} days `
            : ""}
          {nights && nights !== "0"
            ? nights && nights === "1"
              ? `${nights} night`
              : `${nights} nights`
            : ""}
        </span>
      );
    } else if (isDateRange) {
      return calculateDays();
    } else if (isFlexible) {
      return "Flexible Days";
    }
  };
  const itineraryDescriptions = {
    overview: initialPackageData && initialPackageData.description,
    itinerary: initialPackageData && initialPackageData.itinerary,
    accommodation: initialPackageData && initialPackageData.accomodations,
    essential_info: initialPackageData && initialPackageData.essential_info,
    faqs: initialPackageData && initialPackageData.faq,
  };
  const Dates = initialPackageData?.schedule_type === "package" ? "Schedule" : "Dates"
  const itineraryTabData = [
    { name: "Overview", id: "overview" },
    { name: "Itinerary", id: "itinerary" },
    { name: "Accommodation", id: "accommodation" },
    { name: "Essential Info", id: "essential_info" },
    { name: "Reviews", id: "reviews" },
    { name: Dates, id: "dates" },
    { name: "FAQs", id: "faqs" },
  ];
  const stayBasedTabData = [
    { name: "Overview", id: "overview" },
    { name: "Reviews", id: "reviews" },
    { name: "Amenities", id: "amenities" },
  ];

  const itineraryFilterTabData = itineraryTabData.filter(item => {
    if (item.id === "reviews" || item.id === "dates") {

      return item

    }
    else {

      if (itineraryDescriptions[item.id] !== null) {
        if (itineraryDescriptions[item.id] !== undefined) {
          if (itineraryDescriptions[item.id] !== "") {
            if (!itineraryDescriptions[item.id]?.startsWith("<span")) {
              return item

            }
          }
        }
      }
    }
  })



  const Tabdata =
    isStay ? stayBasedTabData : itineraryFilterTabData;

  const sanitisedcolumn = initialPackageData?.note
    ? "col-12 col-lg-6 "
    : "col-12 col-lg-12 ";
  const notescolumn =
    initialPackageData?.covid_safe === true
      ? "col-12 col-lg-6 "
      : "col-12 col-lg-12 ";
  const scrolltoView = (id) => {
    if (isStay) {
      stayRef?.current?.scrollIntoView({
        behavior: "smooth",
      });
    } else {
      discriptionRef?.current?.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const prices = () => {
    if (initialPackageData?.revised_price != 0) {
      return (
        <div className="d-flex   pricess">
          {" "}
          <p className="normal">Rs {(initialPackageData.price).toLocaleString('en-IN')}</p>
          <p className="discount"><span>From</span> Rs {(initialPackageData.revised_price).toLocaleString('en-IN')}</p>
        </div>
      );
    } else {
      return (
        <div className="d-flex  pricess">
          <p className="discount"><span>From</span> Rs {(initialPackageData.price).toLocaleString('en-IN')}</p>
        </div>
      );
    }
  };

  function chaneCategory(name, from) {
    if (categoryId == "dates") {
      scrolltoView()
    }
    if (from == "tab") {
      Tabdata.map((e) => {
        if (e.name == name) {
          setCategory(e.name);
          setCategoryId(e.id);
          setcategoryChanged(true);
        }
      });
    } else {
      Tabdata.map((e) => {
        if (e.id == name) {
          setCategory(e.name);
          setCategoryId(e.id);
        }
      });
    }
  }

  return (
    <Fragment>
      <Helmet>
        <title>
          {`${dataChangedBool
            ? dataChanged !== undefined && dataChanged?.pack_title
            : initialPackageData !== undefined &&
            initialPackageData?.pack_title
            }`}
        </title>
        <meta
          name="description"
          content={`${dataChangedBool
            ? dataChanged?.brief_description
            : initialPackageData?.brief_description
            }`}
        />

        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={
            dataChangedBool
              ? dataChanged?.pack_title
              : initialPackageData?.pack_title
          }
        />
        <meta
          property="og:description"
          content={
            dataChangedBool
              ? dataChanged?.brief_description
              : initialPackageData?.brief_description
          }
        />
        <meta
          property="og:image"
          content={
            dataChangedBool
              ? dataChanged?.images?.filter((im) => im.thumbnail)[0]?.image
              : initialPackageData?.images?.filter((im) => im.thumbnail)[0]
                ?.image
          }
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content={window.location.host} />
        <meta property="twitter:url" content={window.location.href} />
        <meta
          name="twitter:title"
          content={
            dataChangedBool
              ? dataChanged?.pack_title
              : initialPackageData?.pack_title
          }
        />
        <meta
          name="twitter:description"
          content={
            dataChangedBool
              ? dataChanged?.brief_description
              : initialPackageData?.brief_description
          }
        />
        <meta
          name="twitter:image"
          content={
            dataChangedBool
              ? dataChanged?.images?.filter((im) => im.thumbnail)[0]?.image
              : initialPackageData?.images?.filter((im) => im.thumbnail)[0]
                ?.image
          }
        ></meta>
      </Helmet>
      <div id="scroller">
        <div
          className={
            Object.keys(loginUserData).length !== 0 &&
              loginUserData.constructor === Object
              ? "innerPage-header hide-header-mobi"
              : "innerPage-header hide-header-mobi pre-login-header"
          }
        >
          <div className=" d-lg-none d-block">
            <Header />
          </div>
          <div className="d-none d-lg-block">
            <HolidayHeader />
          </div>

        </div>

        {!loader ? (
          <div ref={packageRef} className=" innerPage-container itinerary-container innerPage-container-mobi">
            <div className="package-holder" key={HOLIDAY_INFO?.id}>
              <div className="package-details">
                {
                  <div className="images--holder ">
                    {initialPackageData &&
                      initialPackageData?.images?.length > 0 ? (
                      <PackageDetailsCarousel
                        dataChangedBool={dataChangedBool}
                        initialPackageData={initialPackageData}
                        dataChanged={dataChanged}
                      // showVideoPopup={showVideoPopup}
                      // handleVideoPopup={handleVideoPopup}
                      // popupRef={popupRef}
                      />
                    ) : null}
                  </div>
                }
                <div
                  onClick={goBackFun}
                  className="back-button-mobi back-button d-md-none"
                >
                  <img src={backIcon} alt="backIcon" />
                </div> 
                
                <div className={isStay ? "container-fluid row   stay-container" : "container"}>
                  <div className={isStay ? "col-lg-11 col-12 stay-header" : " row"}>


                    <div className={`itinerary-header col-12 p-0 ${!isStay && "itinerary-mob"}`}>
                      <div className={`itinerary-package-title ${packageType===null?"pb-4": !initialPackageData?.covid_safe && !initialPackageData?.note||HOLIDAY_DETAILS?.holiday?.packages?.length > 1 ? "pb-5" : "pb-2"}`}>
                        <div
                          onClick={goBackFun}
                          className="back-button d-none d-md-inline-flex"
                        >
                          <img src={backIcon} alt="backIcon" />
                        </div>
                        <p className={packageType === null && "d-none d-md-block"}>
                          <span>
                            {" "}
                            {initialPackageData !== undefined &&
                              initialPackageData?.pack_title}
                          </span>
                        </p>
                      </div>
                      {HOLIDAY_DETAILS?.holiday?.packages?.length > 1 && packageType !== null &&
                        <div className={`${isStay ? "col-lg-11" : "col-lg-12"} d-flex packages-prices p-0 ${!initialPackageData.covid_safe && !initialPackageData.note ? "mb-4" : ""}`}>
                          {

                            sortedPackages && sortedPackages.map((pack, idx) => (


                              <button onClick={() => packageChangeHandler(pack.id)} key={idx} className={`pkg-buttons ${pack?.id == activePackId ? "active-pkg" : "non-active-pkg"}`}><span>Rs.{(pack.price).toLocaleString('en-IN')}</span></button>


                            ))

                          }
                        </div>
                      }
                      {packageType !== null && (
                        <div
                          className={` row d-flex  ${initialPackageData?.note &&
                            !initialPackageData?.covid_safe === true
                            ? "note"
                            : `safe-note ${!initialPackageData?.note && "sanitized-row"}`
                            }  align-items-center`}
                        >
                          <div className={sanitisedcolumn}>
                            {initialPackageData &&
                              initialPackageData?.covid_safe === true ? (
                              <SanitizedStayTagPopup pauseVideos={pauseVideos} />
                            ) : null}
                          </div>
                          <div className={notescolumn}>
                            {initialPackageData?.note && (
                              <PriceNote note={initialPackageData?.note} />
                            )}
                          </div>
                        </div>
                      )}
                    </div>

                  </div>

                  {isItinerary && (
                    <ItineraryFeatures
                      data={initialPackageData}
                      calculateDays={calculateDaysProps()}

                      isInternational={SECTION_NAME === "International"}
                      chaneCategory={chaneCategory}
                      dates={Dates}
                    />
                  )}
                  <div
                    ref={discriptionRef}
                    id={categoryId}
                    className={
                      packageType !== null
                        ? ` row ${isItinerary
                          ? "itinerary-package-Tabs-description"
                          : "packageTabs-description col-lg-11 col-12 row p-0"
                        }`
                        : "row"
                    }
                  >
                    {isItinerary && (
                      <div className="col-12 col-lg-12">
                        <PackageTabsHolder
                          isStay={isStay}
                          isItinerary={isItinerary}
                          isStuck={isStuck}
                          setIsStuck={setIsStuck}
                          categoryChanged={categoryChanged}
                          category={category}
                          setCategory={chaneCategory}
                          Tabdata={Tabdata.map((e) => e.name)}
                        />
                      </div>
                    )}
                    <div

                      className={
                        packageType == null || isItinerary && category === "Overview" ||
                          category === "Accommodation" ||
                          category === "Essential Info" ||
                          category === "Itinerary"
                          ? "col-12 col-lg-8 "
                          : isStay
                            ? "col-12 col-lg-9 stay-tabs-discription"
                            : "col-12 col-lg-12 "
                      }
                    >
                      {packageType === null && (
                        <div>
                          <div className="package-title d-none d-md-block">

                            {SECTION_NAME === "International" ? (
                              <p
                                style={{
                                  textAlign: "left",
                                  color: "#797979",
                                }}
                              >
                                {initialPackageData !== undefined &&
                                  initialPackageData?.location_country}
                              </p>
                            ) : (
                              <p
                                style={{
                                  textAlign: "left",
                                  color: "#797979",
                                }}
                              >
                                  {initialPackageData&& initialPackageData?.location?.length > 1 ? `${(initialPackageData?.location_city || initialPackageData?.location?.[0].city)}, ${initialPackageData?.location?.[1]?.address}` :
                                    `${(initialPackageData?.location_city || initialPackageData?.location[0]?.city)}${(initialPackageData?.location[0]?.state)?",":""} ${(initialPackageData?.location[0]?.state)}`
                                  }
                              </p>
                            )}

                            {isPackage ? (
                              <span>
                                {days && days !== "0"
                                  ? days && days === "1"
                                    ? `${days} day `
                                    : `${days} days `
                                  : ""}
                                {nights && nights !== "0"
                                  ? nights && nights === "1"
                                    ? `${nights} night`
                                    : `${nights} nights`
                                  : ""}
                              </span>
                            ) : null}
                            {isDateRange ? <p>{calculateDays()}</p> : ""}
                            {isFlexible ? <p>Flexible Days</p> : ""}
                          </div>
                          <div
                            className="line d-none d-md-block"
                            style={{ marginTop: "-30px" }}
                          ></div>

                          {
                            // mobile view
                          }
                          <div className="package-title d-block d-md-none">
                            <h1>
                              {dataChangedBool
                                ? dataChanged?.pack_title
                                : initialPackageData !== undefined &&
                                initialPackageData?.pack_title}
                            </h1>

                            <span
                              className="d-flex "
                              style={{ flexWrap: "wrap" }}
                            >
                              {SECTION_NAME === "International" ? (
                                <p
                                  style={{
                                    textAlign: "center",
                                    color: "#797979",
                                  }}
                                >
                                  {dataChangedBool
                                    ? dataChanged?.location_country
                                    : initialPackageData !== undefined &&
                                    initialPackageData.location_country}
                                </p>
                              ) : (
                                <p
                                  style={{
                                    textAlign: "center",
                                    color: "#797979",
                                  }}
                                >
                                  {initialPackageData&& initialPackageData?.location?.length > 1 ? `${(initialPackageData?.location_city || initialPackageData?.location[0]?.city)}, ${initialPackageData?.location?.[1]?.address}` :
                                    `${(initialPackageData?.location_city || initialPackageData?.location?.[0]?.city)}${(initialPackageData?.location[0]?.state)?",":""} ${(initialPackageData?.location?.[0]?.state)}`
                                  }
                                </p>
                              )}
                            </span>
                            {isPackage ? (
                              <span>
                                {days && days !== "0"
                                  ? days && days === "1"
                                    ? `${days} day `
                                    : `${days} days `
                                  : ""}
                                {nights && nights !== "0"
                                  ? nights && nights === "1"
                                    ? `${nights} night`
                                    : `${nights} nights`
                                  : ""}
                              </span>
                            ) : null}
                            {isDateRange ? <p>{calculateDays()}</p> : ""}
                            {isFlexible ? <p>Flexible Days</p> : ""}
                          </div>
                          <div>
                            {HOLIDAY_DETAILS && (
                              <p className="mb-price">
                                {isCorporateAccount && isWipro ? (
                                  <label ref={pointsEl}>
                                    {
                                      (initialPackageData
                                        ?.price).toLocaleString('en-IN')
                                    }{" "}
                                    pts{" "}
                                  </label>
                                ) : (
                                  <label ref={pointsEl}>
                                    Rs{" "}
                                    {
                                      (initialPackageData
                                        ?.price).toLocaleString('en-IN')
                                    }{" "}
                                  </label>
                                )}
                              </p>
                            )} 
                          </div>
                          {HOLIDAY_DETAILS?.holiday?.packages?.length > 1 &&
                            <div className={`${isStay ? "col-lg-11" : "col-lg-12"} d-flex packages-prices p-0 ${!initialPackageData.covid_safe && !initialPackageData.note ? "mb-4" : ""}`}>
                              {

                                sortedPackages && sortedPackages.map((pack, idx) => (


                                  <button onClick={() => packageChangeHandler(pack.id)} key={idx} className={`pkg-buttons ${pack?.id == activePackId ? "active-pkg" : "non-active-pkg"}`}><span>Rs.{(pack.price).toLocaleString('en-IN')}</span></button>


                                ))

                              }
                            </div>
                          }
                          {initialPackageData?.note ? (
                            <PriceNote
                              note={
                                dataChangedBool
                                  ? dataChanged?.note
                                  : initialPackageData.note
                              }
                            />
                          ) : null}
                          {initialPackageData &&
                            initialPackageData?.covid_safe === true ? (
                            <SanitizedStayTagPopup pauseVideos={pauseVideos} />
                          ) : null}
                        </div>
                      )}
                      {isStay && (
                        <StayFeaturesCard
                          initialPackageData={
                            initialPackageData && initialPackageData
                          }
                          isInternational={SECTION_NAME === "International"}
                          calculateDays={calculateDaysProps()}
                        />
                      )}
                      <div
                        className={
                          isStay
                            ? "stay-package-tabs-description"
                            : ""
                        }
                      >
                        {isStay && (
                          <PackageTabsHolder
                            category={category}
                            setCategory={chaneCategory}
                            Tabdata={Tabdata.map((e) => e.name)}
                            isStay={isStay}
                            isItinerary={isItinerary}
                            isStuck={isStuck}
                            setIsStuck={setIsStuck}
                          />
                        )}

                        <div
                          ref={stayRef}
                          className={
                            isItinerary
                              ? "itinerary-package-description stay-desc" : isStay && isStuck ? "stay-sticky-description stay-desc"
                                : isStay
                                  ? "stay-package-description stay stay-desc"
                                  : "package-description"
                          }
                        > 
                          {packageType === null && (
                            <p className="mb-3">
                              Validity till :{" "}
                              {initialPackageData !== undefined &&
                                moment(initialPackageData?.valid_till).format(
                                  "Do MMMM, YYYY"
                                )}
                            </p>
                          )}
                          {category !== "Amenities" && category !== "Reviews" && categoryId !== "dates" &&
                            <p
                              dangerouslySetInnerHTML={{
                                __html: itineraryDescriptions[categoryId],
                              }}
                            ></p>
                          }

                          {category === "Reviews" && (
                            <CustomerReviews
                              packageTitle={
                                initialPackageData &&
                                initialPackageData?.pack_title
                              }
                              chaneCategory={chaneCategory}
                              currentPage={holidaysReviewListCurrentPage}
                              totalPage={holidaysReviewListTotalPage}
                              reviewList={reviewList}
                              reviewStatus={reviewStatus}
                              handlePagination={handleReviewListPagination}
                              Id={initialPackageData && initialPackageData?.id}
                              packageType={packageType}
                              referenceId={referenceId}
                            />
                          )}
                          {category === "Amenities" && (
                            <Amenities
                              initialPackageData={
                                initialPackageData && initialPackageData
                              }
                            />
                          )}
                          {categoryId === "dates" && (
                            <ItineraryPackageDates
                              packageData={
                                HOLIDAY_INFO &&
                                HOLIDAY_INFO?.schedule_data?.selectedDates
                              }
                              handleShowForm={handleShowForm}
                              setShowForm={setShowForm}
                              calculateDaysProps={calculateDaysProps}
                            />
                          )}
                        </div>
                      </div>
                    </div>

                    <div
                      className={
                        packageType == null ||
                          isItinerary && category === "Overview" ||
                          category === "Accommodation" ||
                          category === "Essential Info" ||
                          category === "Itinerary" ||
                          showForm
                          ? "col-12 col-lg-4"
                          : isStay
                            ? "col-12 col-lg-3 stay-forms"
                            : "d-none"
                      }
                      ref={myRef}
                    >
                      <div >
                        {packageType === null && (
                          <div>
                            {isCorporateAccount && isWipro ? (
                              <p className="price" ref={pointsEl}>
                                {(initialPackageData
                                  ?.price).toLocaleString('en-IN')}{" "}
                                pts{" "}
                              </p>
                            ) : (
                              <p className="price" ref={pointsEl}>
                                Rs{" "}
                                {(initialPackageData
                                  ?.price).toLocaleString('en-IN')}{" "}
                              </p>
                            )}
                          </div>
                        )}
                        <div className={showForm ? "tour-planner-model" : ""}>
                          <div
                            className={
                              showForm
                                ? "login-holder booking-holder contact-tour-planner"
                                : "login-holder booking-holder "
                            }
                          >
                            <span className="d-flex" onClick={closeHandler}>
                              <img
                                src={closeIcon}
                                className={
                                  showForm ? "d-block close-icon" : "d-none"
                                }
                                alt='close-icon'
                              />
                            </span>
                            {isStay && (
                              <div className="stay-price  d-flex flex-column  align-items-center">
                                {prices()}
                                <p>{calculateDaysProps()}</p>
                              </div>
                            )}
                            <form>
                              {initialPackageData !== undefined &&
                                initialPackageData?.schedule_type ==
                                "date_range" ? (
                                <div className="form-group position-relative">
                                  <input
                                    className="form-control"
                                    type="text"
                                    readOnly
                                    placeholder="From - To"
                                    onClick={selectDateRangeHandler}
                                    value={
                                      startDate && endDate !== null
                                        ? `${moment(
                                          startDate,
                                          "DD-MM-YYYY"
                                        ).format("ddd-DD MMM")} to ${moment(
                                          endDate,
                                          "DD-MM-YYYY"
                                        ).format("ddd-DD MMM")}`
                                        : ""
                                    }
                                    onChange={(value) =>
                                      handleChange("dates", value)
                                    }
                                  />
                                  <label for="">Preferred Dates</label>
                                  {error.dates === true ? (
                                    <div className="errorMsg text-center">
                                      Please select dates
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {showDateRangeModal && showPoupupDateRangeModal && (
                                    <HolidayRangePicker
                                      showMyModal={showDateRangeModal}
                                      handleDatesData={handleDatesData}
                                      handleResAPIStartAndEndDate={
                                        handleResAPIStartAndEndDate
                                      }
                                      dateRangeMinDays={dateRangeMinDays}
                                      dateRangeMaxDays={dateRangeMaxDays}
                                      dateList={
                                        initialPackageData !== undefined &&
                                        initialPackageData?.schedule_data
                                          ?.selectedDates
                                      }
                                      isHoliday={true}
                                    />
                                  )}
                                </div>
                              ) : (
                                <div className="form-group position-relative">
                                  {!isFlexible ? (
                                    <input
                                      className="form-control"
                                      type="text"
                                      readOnly
                                      placeholder="Check in - Check out"
                                      onClick={selectDateHandler}
                                      value={
                                        startDate && endDate !== null
                                          ? `${moment(startDate).format(
                                            "ddd-DD MMM"
                                          )} to ${moment(endDate).format(
                                            "ddd-DD MMM"
                                          )}`
                                          : ""
                                      }
                                      onChange={(value) =>
                                        handleChange("dates", value)
                                      }
                                    />
                                  ) : (
                                    <div className="flexiDates">
                                      <input
                                        className={
                                          flexiDatesLength > 2
                                            ? "form-control text-left pl-0"
                                            : "form-control"
                                        }
                                        type="text"
                                        readOnly
                                        placeholder={
                                          isFlexible ? "Select dates" : ""
                                        }
                                        onClick={selectDateHandler}
                                        value={
                                          flexiDates && flexiDates.length > 0
                                            ? flexiDates &&
                                              flexiDates.length == 1
                                              ? `${moment(
                                                flexiDates[0],
                                                "DD-MM-YYYY"
                                              ).format("ddd-DD MMM")} `
                                              : `${moment(
                                                flexiDates[0],
                                                "DD-MM-YYYY"
                                              ).format(
                                                "ddd-DD MMM"
                                              )}, ${moment(
                                                flexiDates[1],
                                                "DD-MM-YYYY"
                                              ).format("ddd-DD MMM")} `
                                            : ""
                                        }
                                        onChange={(value) =>
                                          handleChange("dates", value)
                                        }
                                      />
                                      {flexiDatesLength > 2 ? (
                                        <span onClick={selectDateHandler}>
                                          +{flexiDatesLength - 2} more
                                        </span>
                                      ) : null}
                                    </div>
                                  )}
                                  <label for="">Preferred dates</label>
                                  {error.dates === true ? (
                                    <div className="errorMsg text-center">
                                      Please select dates
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {showCalenderModal ? (
                                    <CalenderModal
                                      duration={calendarDuration}
                                      showMyModal={showCalenderModal}
                                      handleDatesData={handleDatesData}
                                      handleResAPIStartAndEndDate={
                                        handleResAPIStartAndEndDate
                                      }
                                      packageDays={days}
                                      packageNights={nights}
                                      isPackage={isPackage}
                                      value={flexiDates}
                                      prevStartDate={startDate}
                                      prevEndDate={endDate}
                                      isFlexible={isFlexible}
                                    />
                                  ) : null}
                                </div>
                              )}
                              <div className="form-group position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  readOnly
                                  placeholder="Number of guests"
                                  onChange={(value) =>
                                    handleChange("guest", value)
                                  }
                                  onClick={handleNumberOfGuests}
                                  value={
                                    adultGuestCount !== 0 ||
                                      childrensGuestCount !== 0 ||
                                      count !== 0
                                      ? getGuestCount()
                                      : ""
                                  }
                                />
                                <label for="">Guests</label>
                                {error.guest === true ? (
                                  <div className="errorMsg text-center">
                                    Please enter number of guests
                                  </div>
                                ) : null}
                                {error.infantValidate === true ? (
                                  <div className="errorMsg text-center">
                                    For Infants/children at least 1 adult is
                                    required
                                  </div>
                                ) : null}
                                {showModal && (
                                  <GuestsModal
                                    showMyModal={showModal}
                                    guestDatahandler={guestDatahandler}
                                    adultCount={adultGuestCount}
                                    childrensCount={childrensGuestCount}
                                    infantCount={count}
                                  />
                                )}
                              </div>
                              {/* <div className="form-group specific-request">
                            <label for="">Any specific request</label>
                            <div className="custom-request">
                              <input
                                type="text"
                                name=""
                                id=""
                                placeholder="Enter here"
                                value={request}
                                onChange={(e) => setRequest(e.target.value)}
                              />
                              <div class="form-line"></div>
                              <div className="example">
                                <p>Example:</p>
                                <p>
                                  I am out of points, Can I extend my stay by
                                  paying
                                </p>
                              </div>
                            </div>
                          </div> */}
                              <div className="line-custom"></div>
                              {/* {isEnablePayment && (
                            <div className="total-amount">
                              {isCorporateAccount && isWipro ? (
                                <p>{totalPrice} pts</p>
                              ) : (
                                <p>Rs {totalPrice}</p>
                              )}
                            </div>
                          )} */}
                              <div>
                                <button
                                  type="button"
                                  className="btn proceed-to-book  w-100"
                                  onClick={submitHandler}
                                >
                                  {isEnablePayment
                                    ? `Proceed to book`
                                    : `Enquire now`}
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                        <ItineraryEnquiryForm
                          showForm={showForm}
                          packageType={packageType}
                          isInternational={SECTION_NAME === "International"}
                        />
                      </div>


                      <div
                        className={`d-none ${packageType === null ? "d-md-block" : ""
                          }  sticky-contact`}
                      >
                        <ContactDetailsCard isEnablePayment={isEnablePayment} isInternational={SECTION_NAME === "International"}/>
                      </div>
                    </div>
                    {showContactModal ? (
                      <div className="contact-modal">
                        <ContactDetailsCard
                          openModal={setShowContactModal}
                          contactModalRef={contactModalRef}
                          isInternational={SECTION_NAME === "International"}
                        />
                      </div>
                    ) : null}
                  </div>
                  {HOLIDAY_TESTIMONIALS && HOLIDAY_TESTIMONIALS?.length > 0 ? (
                    <div className={isStay ? "col-lg-11 col-12" : ""}>
                      <Testimonials
                        testimonialsData={HOLIDAY_TESTIMONIALS}
                        testimonialSH={testimonialSH}
                        handleTestimonialPopup={handleTestimonialPopup}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Loader />
        )}

        {/* {
                            <SimilarOffers
                            similarEvents={
                                dataChangedBool ? dataChanged?.id : initialPackageData?.id
                            }
                            similarPackageEmptyData={similarPackageEmptyData}
                        />
                        } */}

        {
          <NearByExperiences
            resetFormFields={resetFormFields}
            startLoader={startLoader}
            holidayID={holidayID}
            similarExperiences={
              dataChangedBool ? dataChanged?.id : initialPackageData?.id
            }
          />
        }
        {
          <ViewMoreHolidays
            similarExperiences={
              dataChangedBool ? dataChanged?.id : initialPackageData?.id
            }
            holidayID={holidayID}
            startLoader={startLoader}
            secName={SECTION_NAME}
            resetFormFields={resetFormFields}
          />
        }
        <Footer />

        {showDate ? (
          <div>
            <SelectDateAndTime
              showMyModal={showDate}
              scheduleDates={HOLIDAY_INFO?.sch_dates}
            />
          </div>
        ) : (
          ""
        )}
        <div className={scroll ? "d-none" : "d-block"}>
          <div className="mobile-buttons">
            <div className="stcky-leftButton">
              <div className="white-button" onClick={handleContactModal}>
                <a>Contact</a>
              </div>
            </div>
            <div className="stcky-rightButton">
              <div className="custom-button" onClick={executeScroll}>
                <a>
                  {isItinerary ? Dates : isEnablePayment
                    ? `Book this`
                    : `Enquire Now`}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        {
          // open login and signup modal
        }
        {showTestimonialPopup ? (
          <TestimonialPopup
            openModal={setShowTestimonialPopup}
            item={popupData}
          />
        ) : null}
        <StyledDialog
          open={showLoginModal}
          PaperProps={{
            style: {
              width: "430px",
              backgroundColor: "#fff",
            },
          }}
        >
          <div className="close-login" onClick={handleClose}>
            <img src={CloseIcon} alt={closeIcon} />
          </div>
          <div className="events-auth-tabs">
            <Tabs
              defaultActiveKey={clickType == "login" ? "login" : "signup"}
              onSelect={(type) => setClickType(type)}
              id="uncontrolled-tab-example"
            >
              <Tab eventKey="login" title="Login">
                <div className="events-login-holder">
                  <LoginPopup
                    closeModalEvent={showLoginModal}
                    toggleModalEvent={setShowLoginModal}
                    clickType={clickType}
                  />
                </div>
              </Tab>
              <Tab eventKey="signup" title="Signup">
                <div className="events-login-holder">
                  <SignupPopup
                    closeModalEvent={showLoginModal}
                    toggleModalEvent={setShowLoginModal}
                    clickType={clickType}
                  />
                </div>
              </Tab>
            </Tabs>
          </div>
        </StyledDialog>
      </div>
      <ScrollToTop showBelow={250} />
    </Fragment>
  );
}

export default HolidayDetails;
