import React from 'react';
import VirtualContact from "../../assets/images/home/vix/Converted/contact-card.webp";
import VirtualMobiContact from "../../assets/images/home/vix/Converted/contact-card-mobi.webp";
import WhatsappLogo from '../../assets/images/corporates/whatsapp-logo.svg'
import closeIcon from '../../assets/images/common/close-modal.svg';

function VixContactDetails({ openModal }) {
    const handleClose = () => {
        openModal(false);
      };
    return (
        <div>
            <div className="cerp-corp-wrapper vix-wrapper vix-contact-wrapper">
            {
                openModal && <span className="closeIcon" onClick={handleClose}><img src={closeIcon} className="close-icon" /></span>
            }
                <div className="cerp-corp">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-5 pr-lg-0">
                            <div className="d-none d-md-block" style={{height: '100%'}}>
                                <img src={VirtualContact} className="vix-contact-img w-100" alt="" />
                            </div>
                            <div className="d-block d-md-none" >
                                <img src={VirtualMobiContact} className="vix-contact-img w-100" alt="" />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 pl-lg-0">
                            <div className="info vix-contact-info">
                                <p style={{marginTop: '0px'}}><span className="vix-content">For a Customized  <br /> Demo,</span> Contact us</p>
                                <div className="line"></div>
                                <div className="contact-card mb-3 d-flex align-items-center justify-content-between">
                                    <div>
                                        <p>
                                            <a href="tel:+919886039555">+91 9886039555</a>
                                        </p>
                                        <p>Our number</p>
                                    </div>
                                    <div>
                                        <a href="https://wa.me/+919886039555" rel="noreferrer" target="_blank">
                                            <img src={WhatsappLogo} alt="" />
                                        </a>
                                    </div>
                                </div>

                                <div className="contact-card mb-3">
                                    <p>
                                        <a href="mailto:dilip@fieldtrips.in"
                                            rel="noreferrer"
                                            target="_blank" >
                                            dilip@fieldtrips.in{" "}
                                        </a>
                                    </p>
                                    <p>Our email</p>
                                </div>
                                <div className="contact-card">
                                    <p>
                                        <a href="mailto:chandran@fieldtrips.in"
                                            rel="noreferrer"
                                            target="_blank" >
                                            chandran@fieldtrips.in{" "}
                                        </a>
                                    </p>
                                    <p>Another email</p>
                                </div>
                            </div>


                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default VixContactDetails
