import React, { useEffect, useRef, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import Header from "../common/header";
import Footer from "../common/footer";
import { useDispatch, useSelector } from "react-redux";
import backIcon from "../../assets/images/common/back-icon.svg";
import moment from "moment";
import { useHistory, withRouter } from "react-router-dom";
import * as EventsAction from "../../redux/actions/eventsAction";
import Dialog from "@material-ui/core/Dialog";
import styled from "styled-components";
import LoginPopup from "../auth/login";
import DialogTitle from "@material-ui/core/DialogTitle";
import "../../assets/css/partials/events/events.scss";
import { handleProceedToBook } from "../../redux/actions/corporatesAuthActions";
import SelectDateAndTime from "../../components/events/selectDateAndTime";
import { Fragment } from "react";
import Loader from "../common/loader";
import BookThisForm from "./bookThisForm";
import SimilarOffers from "./similarOffers";
import { Helmet } from "react-helmet";
import TestimonialPopup from "../common/testimonialPopup";
import EventSlides from "./eventSlides";
import $ from "jquery";
import parse from "html-react-parser";
import { ScrollToTop } from "../common";
import "../../assets/css/partials/common/priceNotes.scss";
import PriceNote from "../common/priceNote";
import Breadcrum from "../common/breadcrum";
// import {

//     TESTIMONIALS
//   } from "../../resources/eventsData";

function EventDetails(eventDetailsPage) {
  const pointsEl = useRef(null);
  const owlRef = useRef();
  const myRef = useRef();
  const eventID = eventDetailsPage.match.params.id;
  const loginUserData = useSelector((state) => state.auth.login_user_otp);
  const dispatch = useDispatch();
  const history = useHistory();
  const [loader, setLoader] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [showTestimonialPopup, setShowTestimonialPopup] = useState(false);
  const [popupData, setPopupData] = useState("");
  const [testimonialSH, setTestimonialSH] = useState({});
  const SIMILAR_EVENTS = useSelector((state) => state?.events?.similar_events?.data);

  const EVENT_DETAILS = useSelector(
    (state) => state?.events?.event_details?.data?.event
  );
  const EVENTS_TESTIMONIALS = useSelector(
    (state) => state?.events?.events_testimonial?.data
  );
  const EVENT_INFO = EVENT_DETAILS?.packages[0];
  const SECTION = EVENT_DETAILS?.section;
  const eventDate = EVENT_INFO?.valid_till;
  const PRICE = useSelector((state) => state?.events?.event_details?.data);
  // const [dataChangedBool, setDataChangedBool] = useState(false);
  const SELECTED_DATE = useSelector((state) => state?.events?.selected_date);
  const SELECTED_TIME = useSelector((state) => state?.events?.selected_time);

  const userDetails = JSON.parse(localStorage.getItem("userData"));
  const isCorporateAccount = userDetails?.user_details?.corporate_account;

  const companyDomain = userDetails?.organisation?.email_domain;
  const isWipro = companyDomain === "wipro.com";

  const [totalPrice, setotalPrice] = useState(EVENT_INFO?.price);
  // const [totalPrice, setotalPrice] = useState(PACKAGE_DETAILS?.points_list[0]);
  const [state, setState] = useState({
    dates: "",
  });

  const [error, setError] = useState({
    dates: false,
  });
  const scroll = () => {
    myRef.current.scrollIntoView();
  };
  const handleChange = (key, value) => {
    setState(() => ({
      ...state,
      [key]: value.target.value,
    }));
  };

  useEffect(() => {
    // if(typeof owlCarousel === 'function') {
    //     console.log('owl function loaded')
    //     $(".owl-carousel").owlCarousel({
    //       navigation : true,
    //       autoplay:true
    //     });
    //   }
    console.log(
      EVENT_DETAILS?.packages[0]?.images.filter((im) => im.thumbnail)[0]?.image
    );
    console.log(
      EVENT_DETAILS?.packages[0]?.images?.filter((im) => im.thumbnail)[0]?.image
    );
    let owl = window.$(".owl-carousel");
    owl.owlCarousel();
    owl.trigger("play.owl.autoplay");
    console.log("autoplay");
  }, []);

  useEffect(() => {
    dispatch(EventsAction.getEventDetails(eventID, handleLoader));

    window.scrollTo(0, 0);
  }, [eventID]);

  useEffect(() => {
    dispatch(EventsAction.getTestimonials(EVENT_INFO?.id));
  }, [EVENT_INFO]);
  useEffect(() => {
    setotalPrice(EVENT_INFO?.price);
  }, [EVENT_INFO?.price]);

  useEffect(() => {
    setTimeout(() => {
      calcTestimonialScrollHeight();
    });
  }, [EVENTS_TESTIMONIALS]);

  const handleLoader = () => {
    setLoader(false);
  };

  const StyledDialog = styled(Dialog)`
    & > .MuiDialog-container > .MuiPaper-root {
      width: 430px;
      background-color: #f5f5f5;
      position: relative;
    }
    & > .MuiDialog-container > .MuiPaper-root > .close-login {
      position: absolute;
      top: 12px;
      right: 23px;
      cursor: pointer;
    }
  `;

  const calcTestimonialScrollHeight = () => {
    // setTestimonialSH
    if (EVENTS_TESTIMONIALS && EVENTS_TESTIMONIALS.length) {
      let tempTestimonialSH = {};
      EVENTS_TESTIMONIALS?.forEach((val, index) => {
        tempTestimonialSH[`testimonial-block-${index}`] =
          document.getElementById(`testimonial-block-${index}`)?.scrollHeight;
      });
      setTestimonialSH(tempTestimonialSH);
    }
  };
  const handleSubmit = (id) => {
    const getToken = localStorage.getItem("token");
    if (getToken === "" || getToken == null) {
      // alert("please login");
      setShowModal(true);
    } else {
      let data = JSON.parse(localStorage.getItem("userData"));
      const points = parseInt(localStorage.getItem("points"));

      if (isCorporateAccount) {
        const bookData = {
          pack_id: EVENT_INFO?.id,
          total_price: totalPrice,
          schedule: {
            date: EVENT_INFO?.schedule_data?.date,
            from: EVENT_INFO?.schedule_data?.from,
            to: EVENT_INFO?.schedule_data?.to,
          },
          points: points,
          amount: totalPrice,
          name: data?.user_details?.full_name,
          email: data?.user_details?.email,
          contactNumber: data?.user_details?.mobile,
        };
        dispatch(
          EventsAction.handleProceedToBook(bookData, isCorporateAccount)
        );
      } else {
        const bookData = {
          pack_id: EVENT_INFO?.id,
          total_price: totalPrice,
          schedule: {
            date: EVENT_INFO?.schedule_data?.date,
            from: EVENT_INFO?.schedule_data?.from,
            to: EVENT_INFO?.schedule_data?.to,
          },
          amount: totalPrice,
          name: data?.user_details?.full_name,
          email: data?.user_details?.email,
          contactNumber: data?.user_details?.mobile,
        };
        dispatch(
          EventsAction.handleProceedToBook(bookData, isCorporateAccount)
        );
      }
      // dispatch(CorporateAction.changeDataforProceedToBook(dataChanged, dataChangedBool));
      history.push(`/events/checkout`);
      window.scrollTo(0, 0);
    }
  };
  const pauseVideos = () => {
    let player = $("iframe");

    if (player) {
      player.map((index) => {
        $("iframe")[index].contentWindow.postMessage(
          '{"event":"command","func":"' + "pauseVideo" + '","args":""}',
          "*"
        );
        $("iframe")[index].contentWindow.postMessage(
          '{"method":"pause","value":"' + "pauseVideo" + '","args":""}',
          "*"
        );
      });
    }
    $(".owl-item")
      .find("video")
      .each(function () {
        this.pause();
      });
  };
  const handleTestimonialPopup = (item) => {
    setShowTestimonialPopup(true);
    setPopupData(item);
    pauseVideos();
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const selectDateHandler = () => {
    showDate ? setShowDate(false) : setShowDate(true);
  };
  // const checkHeight = (testimonialID) => {

  //         const scrollHeight = document.querySelector(`#${testimonialID}`)?.scrollHeight;

  //     if(scrollHeight > 88){
  //         console.log(scrollHeight, testimonialID, true)
  //         return true;
  //     }else{
  //         console.log(scrollHeight, testimonialID, false)
  //         return false
  //     }

  // }
  const goBackFun = () => {
    let urlHistory = JSON.parse(localStorage.getItem("urlHistory"));
    if (!localStorage.getItem("prevUrl")) {
      history.push("/events");
    } else if (
      urlHistory[0].pathname === urlHistory[urlHistory.length - 1].pathname &&
      urlHistory.length > 2
    ) {
      history.push("/events");
    } else {
      history.goBack();
    }
  };
  const isPastDate = () => {
    const todayDate = moment().format("YYYY-MM-DD");
    const isBefore = moment(EVENT_INFO?.valid_till).isBefore(todayDate);
    return isBefore;
  };

  return (
    <Fragment>
      <Helmet>
        <title>{`${EVENT_DETAILS?.title}`} - Hammock Holidays</title>
        <meta
          name="description"
          content={`${EVENT_DETAILS?.packages[0].brief_description}`}
        />

        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={EVENT_DETAILS?.title} />
        <meta
          property="og:description"
          content={EVENT_DETAILS?.packages[0].brief_description}
        />
        <meta
          property="og:image"
          content={
            EVENT_DETAILS?.packages[0]?.images?.filter((im) => im.thumbnail)[0]
              ?.image || `${window.location.origin}/Logo.png`
          }
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content={window.location.host} />
        <meta property="twitter:url" content={window.location.href} />
        <meta name="twitter:title" content={EVENT_DETAILS?.title} />
        <meta
          name="twitter:description"
          content={EVENT_DETAILS?.packages[0].brief_description}
        />
        <meta
          name="twitter:image"
          content={
            EVENT_DETAILS?.packages[0]?.images?.filter((im) => im.thumbnail)[0]
              ?.image || `${window.location.origin}/Logo.png`
          }
        ></meta>
      </Helmet>
      <div className="event-details-page">
        <div
          className={
            Object.keys(loginUserData).length !== 0 &&
            loginUserData.constructor === Object
              ? "innerPage-header hide-header-mobi"
              : "innerPage-header hide-header-mobi pre-login-header"
          }
        >
          <Header />
        </div>
        {!loader ? (
          <div className=" innerPage-container innerPage-container-mobi">
            

            <div className="package-holder" key={EVENT_INFO?.id}>
              <div className="package-details">
              {
                <div className="images--holder">
                  <EventSlides eventInfo={EVENT_INFO} />
                </div>
              }
              
              {/* <div className="line d-none d-md-block"></div> */}
                <div className="container">
                  <div className="breadcrums">
                    <a href onClick={() => goBackFun()}>
                      <div className="back-button back-button-mobi">
                        <img src={backIcon} alt="" />
                      </div>
                    </a>
                    <Breadcrum module={"Events"} goBackFun={goBackFun} section={SECTION}/>
                  </div>
                <div className="row">
                  <div className="col-12 col-lg-8">
                  <div className="package-title d-none d-md-block">
                <h1>{EVENT_DETAILS?.title}</h1>
                {EVENT_INFO?.workshop === true ? (
                  <p className="workshop-type mb-0">
                    ONLINE WORKSHOP
                  </p>
                ) : (
                  ""
                )}
              </div>
                    
                    {
                      // mobile view
                    }
                    <div className="package-title d-block d-md-none">
                      <h1>{EVENT_DETAILS?.title}</h1>
                      {EVENT_INFO?.workshop === true ? (
                        <p className="workshop-type text-center">
                          ONLINE WORKSHOP
                        </p>
                      ) : (
                        ""
                      )}
                      <p className="event-date">
                        {isPastDate() ? (
                          <span style={{ fontWeight: 500 }}>Held on: </span>
                        ) : (
                          ""
                        )}
                        {`${moment(
                          EVENT_INFO?.schedule_data?.date,
                          "DD-MM-YYYY"
                        ).format("ddd Do MMM,")} ${moment(
                          EVENT_INFO?.schedule_data?.from,
                          "hh:mm"
                        ).format("LT")} to ${moment(
                          EVENT_INFO?.schedule_data?.to,
                          "hh:mm"
                        ).format("LT")}`}
                      </p>
                    </div>

                    <div className="select-points mb-3">
                      {PRICE &&
                        PRICE?.price_list?.length > 0 &&
                        PRICE?.price_list.map((points, index) =>
                          points !== 0 ? (
                            isCorporateAccount ? (
                              <div className="event-price">
                                {isWipro ? (
                                  <p>{points} pts</p>
                                ) : (
                                  <p>Rs {points}</p>
                                )}
                              </div>
                            ) : (
                              <div className="event-price">
                                <p>Rs {points}</p>
                              </div>
                            )
                          ) : (
                            <div
                              className="event-price"
                              style={{ width: "150px", padding: "12px 20px" }}
                            >
                              <p>Free session</p>
                            </div>
                          )
                        )}
                    </div>
                    {EVENT_INFO?.note ? (
                      <PriceNote
                      note={EVENT_INFO?.note}
                      />
                    ) : null}
                    {isPastDate() && (SIMILAR_EVENTS?.length > 0)  ? (
                      <div
                        className="gradient-text d-md-none d-block"
                        onClick={scroll}
                      >
                        <p>See Upcoming Events</p>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="package-description">
                      {/* <p>Bookings valid up to {moment(eventDate, 'YYYY-MM-DD').format('Do MMM YYYY')} till 12:00 Midnight </p> */}
                      <p
                        dangerouslySetInnerHTML={{
                          __html: EVENT_INFO?.description,
                        }}
                      ></p>
                    </div>
                  </div>

                  <div className="col-12 col-lg-4">
                    <BookThisForm
                      pauseVideos={pauseVideos}
                      isEnablePayment={true}
                      scroll={scroll}
                      similarEvents={SIMILAR_EVENTS}
                    />
                  </div>
                </div>
              
              {EVENTS_TESTIMONIALS && EVENTS_TESTIMONIALS?.length > 0 ? (
                <div className="package-testimonial">
                  <div className="heading">
                    <h2>Testimonials</h2>
                  </div>
                  <div className="line"></div>
                  <div className="cards-wrapper">
                    {EVENTS_TESTIMONIALS &&
                      EVENTS_TESTIMONIALS?.length > 0 &&
                      EVENTS_TESTIMONIALS?.map((item, index) => {
                        return (
                          <div className="testimonial-card">
                            <div className="header d-none d-md-flex">
                              <p className="profile">
                                <img src={item.guest_image} />
                                <p>{item.guest_name}</p>
                              </p>
                              <p>
                                {moment(item?.modified, "YYYY-MM-DD").format(
                                  "MMMM, YYYY"
                                )}
                              </p>
                            </div>
                            <div className="header-mobile d-block d-md-none">
                              <p className="profile">
                                <img src={item.guest_image} />
                                <p>
                                  {item.guest_name}
                                  <br></br>
                                  <span>
                                    {moment(
                                      item?.modified,
                                      "YYYY-MM-DD"
                                    ).format("MMMM, YYYY")}
                                  </span>
                                </p>
                              </p>
                            </div>
                            <div className="content">
                              {/* <p>{item.message.replace( /(<([^>]+)>)/ig, '').substring(0, 150)}{item.message.replace( /(<([^>]+)>)/ig, '').length > 150 ? <a onClick={() => handleTestimonialPopup(item)}>... read more</a> : null}</p> */}
                              <div
                                className="testimonial-block"
                                id={`testimonial-block-${index}`}
                              >
                                {/* {testimonialSH[`testimonial-block-${index}`]} */}
                                {parse(item.message)}

                                {testimonialSH[`testimonial-block-${index}`] >
                                  88 && (
                                  <div className="testimonial-readmore">
                                    <span className="readmore-span">
                                      <a
                                        onClick={() =>
                                          handleTestimonialPopup(item)
                                        }
                                      >
                                        ... read more{" "}
                                      </a>{" "}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              ) : null}
              </div>
              </div>
            </div>
          </div>
        ) : (
          <Loader />
        )}
        {showTestimonialPopup ? (
          <TestimonialPopup
            openModal={setShowTestimonialPopup}
            item={popupData}
          />
        ) : null}
        {EVENT_INFO && EVENT_INFO?.id ? (
          <SimilarOffers
            similarEvents={EVENT_INFO && EVENT_INFO?.id}
            isPastDate={isPastDate()}
            myRef={myRef}
          />
        ) : null}
        <Footer />
        {
          //   <div className="events-login-holder">
          //     <StyledDialog
          //         open={showModal}>
          //         <div className="close-login" onClick={handleClose}>
          //             <img src={CloseIcon} />
          //         </div>
          //         <LoginPopup />
          //     </StyledDialog>
          // </div>
        }
        {showDate ? (
          <div>
            <SelectDateAndTime
              showMyModal={showDate}
              scheduleDates={EVENT_INFO?.sch_dates}
            />
          </div>
        ) : (
          ""
        )}
      </div>
      <ScrollToTop showBelow={200} />
    </Fragment>
  );
}

export default EventDetails;
