
import React, { useState, useEffect, useRef } from 'react';
import Header from '../common/header';
import Footer from '../common/footer';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import backIcon from '../../assets/images/common/back-icon.svg';
import productImg from '../../assets/images/dummy/voucher.png'
import '../../assets/css/partials/common/checkout.scss';
import { PAYMENT_URL, PAYMENT_URL_TEST } from "../../config/api";
import * as HolidaysAction from '../../redux/actions/holidaysActions';
import DatePopup from '../common/datePopup';
import HolidayHeader from '../common/holidayHeader';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    input: {
        width: 42,
    },
});

function HolidayCheckoutDetails(props) {
    const data = props.location.state;
    const dispatch = useDispatch()
    const history = useHistory();
    const [showModal, setShowModal] = useState(false)
    const wrapperRef = useRef(null);
    useModalOutsideClose(wrapperRef);
    const receivedDetails = useSelector(state => state.holidays.checkout_details);
    const checkoutDetails = data;
    const activePackageIndex = useSelector(
        (state) => state?.holidays?.activePackageIndex
      );
    const holidayPackageDetails = useSelector(state => state.holidays?.holiday_details);
    const PACKAGE_DETAILS = useSelector(state => state.holidays?.holiday_details?.data);
    const SECTION_NAME = PACKAGE_DETAILS && PACKAGE_DETAILS?.holiday?.section.name;
    const initialPackageData = PACKAGE_DETAILS && PACKAGE_DETAILS?.holiday?.packages[activePackageIndex];
    const changeDataProceedToBook = useSelector(state => state.holidays.change_data_to_proceed_to_book);
    const dataChangedBoolVal = useSelector(state => state.holidays.data_changed_bool_val);
    const packImage = dataChangedBoolVal ? changeDataProceedToBook?.images : initialPackageData?.images;
    let mobile = localStorage.getItem("mobile");
    const isDateRange = dataChangedBoolVal ? changeDataProceedToBook?.schedule_type == "date_range" : initialPackageData?.schedule_type == "date_range"
    const isPackage = dataChangedBoolVal ? changeDataProceedToBook?.schedule_type == "package" : initialPackageData?.schedule_type == "package"
    const packScheduleData = dataChangedBoolVal ? changeDataProceedToBook?.schedule_data : initialPackageData?.schedule_data;
    const isFlexible= dataChangedBoolVal ? changeDataProceedToBook?.flexi_schedule : initialPackageData?.flexi_schedule;
    const days = packScheduleData?.days;
    const nights = packScheduleData?.nights;
    
    if (isNaN(mobile)) {
        let userDataStored = JSON.parse(localStorage.getItem("userData"));
        mobile = userDataStored.user_details.mobile;
    }
    const contactName = localStorage.getItem("full_name");
    // const {points, pack_title,location } =  displayGiftVoucherData;


    const corpHolidayEditdName = useSelector(state => state.holidays.corpEditedHolidayName);
    const corpHolidayEditdNameBool = useSelector(state => state.holidays.corpEditedHolidayNameBool);
    const corpHolidayEditdNumber = useSelector(state => state.holidays.corpEditedHolidayNumber);
    const corpHolidayEditdNumberBool = useSelector(state => state.holidays.corpEditedHolidayNumberBool);

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(HolidaysAction.checkOut());
        if(PACKAGE_DETAILS === undefined){
            history.push(`/holidays`);
        }
    }, []);

    function useModalOutsideClose(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setShowModal(false)
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    };
    /* clearing packageDetails, bookData and eventDetails key's from 
    localStorage while redirecting to bank payments page to avoid any potential flaws */
    const clearPackageAndBookingData = () => {
        localStorage.removeItem('packageDetails');
        localStorage.removeItem('bookData');
        localStorage.removeItem('packInfo');
        dispatch(HolidaysAction.corpHolidayEditedName(null, false));
        dispatch(HolidaysAction.corpHolidayEditedNumber(null, false));
    }
    const calculateDays = () => {
        const arr = [];
        if(!dataChangedBoolVal){
            if(initialPackageData && initialPackageData?.schedule_type == "date_range" ){
                initialPackageData && initialPackageData?.schedule_data?.selectedDates?.map(date => {
                    const startDate = moment(`${date.to}`, 'DD-MM-YYYY');
                    const endDate = moment(`${date.from}`, 'DD-MM-YYYY');
                    const result = endDate.diff(startDate, 'days');
                    arr.push(Math.round(Math.abs(result) + 1 ));
                });
            }
        }
       else {
        if( changeDataProceedToBook && changeDataProceedToBook?.schedule_type == "date_range" ){
            changeDataProceedToBook && changeDataProceedToBook?.schedule_data?.selectedDates?.map(date => {
                const startDate = moment(`${date.to}`, 'DD-MM-YYYY');
                const endDate = moment(`${date.from}`, 'DD-MM-YYYY');
                const result = endDate.diff(startDate, 'days');
                arr.push(Math.round(Math.abs(result) + 1));   
            });
        } 
       }
        
        const min = Math.min(...arr);
        const max = Math.max(...arr);
        const calculatedDateRangeDays = {
            "minimum": min, 
            "maximum": max
        } 
        if(min !== max){
            return <span>Min {min} {min == 1 ? 'day' : 'days'}, Max {max} {max == 1 ? 'day' : 'days'}</span>
        }else{
            return<span> Max {max} {min == 1 ? 'day' : 'days'}</span>
        }
        
    }

    const getGuestCount = (adult, kids, infant)=>{
        // `${adultGuestCount} adult(s), ${childrensGuestCount} kid(s), ${count} infant(s)` //old code
        let tmpGuestsArray = [];
        if(adult>0){
            tmpGuestsArray.push(`${adult} adult${adult>1?'s':''}`);
        }
        if(kids>0){
            tmpGuestsArray.push(`${kids} kid${kids>1?'s':''}`);
        }
        if(infant>0){
            tmpGuestsArray.push(`${infant} infant${infant>1?'s':''}`);
        }
        return tmpGuestsArray.join(', ');
    };
   console.log("data?.contact_number", data?.contact_number)

    return (
        <div>
            <div className="innerPage-header hide-header-mobi">
            <div className="d-lg-none">
                    <Header />
                </div>
                <div className="d-none d-lg-block">
                    <HolidayHeader />
                </div>
            </div>
            <div className="container innerPage-container innerPage-container-mobi">
                <a href onClick={() => history.goBack()}>
                    <div className="back-button back-button-mobi">
                        <img src={backIcon} alt="backIcon" />
                    </div>
                </a>
                <div className="purchase-details">
                    <h1>Checkout</h1>
                    <div className="line"></div>
                    <div className="row mb-5 pb-5">
                        <div className="col-12 col-lg-7">
                            <div className="d-flex d-md-block mobi-overflow">
                                <div className="product-holder product-holder-mobi">
                                    <div className="prod-img">
                                    {packImage && packImage?.map(image => {
                                        if(image?.thumbnail == true){
                                            return <img src={image?.image} alt={initialPackageData?.pack_title} width="200" height="228" />
                                        }
                                    })}
                                    </div>
                                    <div className="prod-info">
                                        <h5>{!dataChangedBoolVal ? initialPackageData?.pack_title : changeDataProceedToBook?.pack_title}</h5>
                                        <div className="locations-list">
                                        { SECTION_NAME === "International" ? 
                                            <p>{!dataChangedBoolVal ? initialPackageData?.location_country : changeDataProceedToBook?.location_country}</p>:
                                            <p>{!dataChangedBoolVal ? initialPackageData?.location[0]?.city : changeDataProceedToBook?.location[0]?.city}, {!dataChangedBoolVal ? initialPackageData?.location[0]?.state : changeDataProceedToBook?.location[0]?.state}</p>}
                                        </div>
                                        <div className="line"></div>
                                        { isPackage ? 
                                            <span>
                                             {(days && days !== "0") ? (days && days === "1") ? `${days} day ` : `${days} days ` : ""} 
                                             {(nights && nights !== "0") ? (nights && nights === "1") ? `${nights} night` : `${nights} nights` : ""}
                                            </span> : null}
                                        { isDateRange ? 
                                            <div className="details">
                                                <p>{calculateDays()}</p>
                                            </div>
                                            : null
                                        }
                                        { isFlexible ? 
                                            <div className="details">
                                                <p>Flexible Days</p>
                                            </div>
                                            : null
                                        }
                                    </div>
                                </div>

                                <div className="buyer-details packageInfo edit-package-dates">
                                    <div className="input-holder d-block">
                                        <div className="details">
                                            {!isFlexible ? 
                                            <p>{moment(data?.schedule?.from, 'DD.MM.YYYY').format('Do MMM, ddd YYYY')} - {moment(data?.schedule?.to, 'DD.MM.YYYY').format('Do MMM, ddd YYYY')}</p> :
                                            <p>{data?.schedule.slice(0,2).map((date, index) => moment(date, 'DD-MM-YYYY').format('ddd-DD MMM') + `${data?.schedule?.length > 1 && data?.schedule?.length - 1 !== index ? `, `: '' }`)} {data?.schedule?.length > 2 ?
                                                <span style={{ fontSize: "12px", pointer: "thumb"}} onClick={() => setShowModal(true)}>
                                                    +{data?.schedule?.length - 2} more
                                                    </span> : 
                                                null }
                                            </p>
                                            }
                                            <p>Selected Date</p> 
                                            
                                        </div>
                                        {
                                         isFlexible? <DatePopup showModal={showModal} data={data} wrapperRef={wrapperRef}/> : ""
                                        }
                                        

                                    </div>

                                    <div className="input-holder mb-2 d-block">
                                        <div className="details">
                                            
                                            <p>{getGuestCount(data.adults, data.children, data.infants)}</p>
                                            <p>Number of People</p>
                                        </div>

                                    </div>

                                </div>


                            </div>
                        </div>
                        <div className="col-12 col-lg-5 d-flex d-lg-block flex-column-reverse">
                            <div className="price-holder">
                                <h4 className="checkout-title">{
                                    checkoutDetails?.pay_mode == "DC" ? 'You are using Debit Card / UPI' : 'You are using Credit Card / Net Banking'
                                } </h4>
                                <div className="price-line w-100"></div>
                                <form action={PAYMENT_URL_TEST} method='post'>
                                    <input type="hidden" name="firstname" value={checkoutDetails?.firstname} />
                                    <input type="hidden" name="lastname" value="" />
                                    <input type="hidden" name="surl" value={checkoutDetails?.surl} />
                                    <input type="hidden" name="phone" value={checkoutDetails?.phone} />
                                    <input type="hidden" name="key" value={checkoutDetails?.key} />
                                    <input type="hidden" name="hash" value={checkoutDetails?.hash} />
                                    <input type="hidden" name="curl" value={checkoutDetails?.curl} />
                                    <input type="hidden" name="furl" value={checkoutDetails?.furl} />
                                    <input type="hidden" name="txnid" value={checkoutDetails?.txnid} />
                                    <input type="hidden" name="productinfo" value={checkoutDetails?.productinfo} />
                                    <input type="hidden" name="amount" value={checkoutDetails?.amount} />
                                    <input type="hidden" name="email" value={checkoutDetails?.email} />
                                    <button type="submit" onClick={clearPackageAndBookingData} className="btn proceed-to-book w-100 btn-fixed-mobi" href >Pay Rs {(checkoutDetails?.amount).toLocaleString('en-IN')}</button>
                                </form>

                            </div>
                                <div className="user-details">
                                    <div className="input-holder mb-2 d-block">
                                        <div className="details">
                                            <p>{data?.contact_name}</p>
                                            <p>Your Name</p>
                                        </div>
                                    </div>
                                    <div className="input-holder mb-2 d-block">
                                        <div className="details">
                                            <p>{data?.contact_number}</p>
                                            <p>Your Contact number</p>
                                        </div>
                                    </div>
                                    <div className="input-holder mb-2 d-block">
                                        <div className="details">
                                            <p>{data?.contact_email}</p>
                                            <p>Your Email id</p>
                                        </div>
                                    </div>
                                </div> 
                            
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default withRouter(HolidayCheckoutDetails);

