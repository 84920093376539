import React from 'react'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
import Header from './header'
import Logo from "../../assets/images/common/logo.svg";
import CloseIcon from "../../assets/images/common/close-icon.svg";
import AboutMain from "../../assets/images/home/about/aboutmain.webp";
import AboutMobile from "../../assets/images/home/about/aboutmobile.webp";
import About2 from "../../assets/images/home/about/about2.webp";
import Slider1 from "../../assets/images/home/about/aboutslider1.webp";
import Slider2 from "../../assets/images/home/about/aboutslider2.webp";
import Slider3 from "../../assets/images/home/about/aboutslider3.webp";
import Slider4 from "../../assets/images/home/about/aboutslider4.webp";
import Slider5 from "../../assets/images/home/about/aboutslider5.webp";
import Bc from "../../assets/images/home/about/bc.webp";
import Dk from "../../assets/images/home/about/dk.webp";
import Rama from "../../assets/images/home/about/rama.webp";
import OurTeamBg from "../../assets/images/home/about/ourteamBG.webp";
import PromoCard from "../../assets/images/home/about/PromoCard.webp";
import Footer from './footer';
import RecognitionsAndPartners from "../homepage/recognitionsAndPartners";
const AboutUs = () => {
  const history = useHistory()
  const sliderImages = [Slider1, Slider2, Slider3, Slider4, Slider5]
  const EXP_IN_YEARS = localStorage.getItem("EXP_IN_YEARS")
  return (
    <div>
      <Helmet>
        <title>About Us</title>
      </Helmet>
      <div className=" d-md-none">
        <Header />
      </div>
      <div className='career-logo d-flex justify-content-between'>
        <img src={Logo} onClick={() => history.push("/")} alt="logo" />
        <img className='close-icon' alt='close' onClick={() => history.goBack()} src={CloseIcon} />
      </div>
      <div className=' about-us-holder'>
        <div className='about-banner-holder'>
          <div className="about-main-banner">
            <img src={AboutMain} className="d-none d-md-block" alt="main-banner" />
            <img src={AboutMobile} alt="main-banner" className='d-md-none' />

          </div>
          <div className='about-banner-text d-flex flex-column'>
            <p className='about'>About us</p>
            <p className='about-title'>Making the holidays to <br /> best moments</p>
          </div>
        </div>



        <div className='about-us-description-wrapper container'>
          <p className='description-title about-us-txt'>Who we are?</p>
          <p className='description-content'>We are holiday planners and consultants who tailor holidays to suit individual preference, budget and time. With an experience that totals over {EXP_IN_YEARS} years of specialized service in our field, we aim at providing a large spectrum of options for the holiday makers to make an appropriate choice and to provide all the services required for them to experience a memorable holiday from commencement to completion. Equipped to serve all customers alike, quality service and tour packages across various price categories are our forte. With an amazing team and adequate infrastructure, we, at Hammock Holidays, try to make your holiday a very convenient and hassle free one! You can relax and lie back if you plan to hand over your travel planning and execution to Hammock Holidays, we promise to make your vacation a fun filled one.
          </p>
        </div>
        <div className='about-us-banner-wrapper'>
          <p className='about-us-banner-txt'>We create memories of your journey</p>
          <div className='about-us-banner'>
            <img src={About2} alt="About2" />

          </div>
        </div>



        <div className='about-us-numbers d-flex'>
          <div className='years d-flex flex-column align-items-center'>
            <p className='about-us-txt'>{EXP_IN_YEARS}+</p>
            <p className='text about-us-txt'>years of Experience</p>
          </div>
          <div className='packages d-flex flex-column align-items-center'>
            <p className='about-us-txt'>500+</p>
            <p className='text  about-us-txt'>Packages</p>
          </div>
          <div className='destinations d-flex flex-column align-items-center'>
            <p className='about-us-txt'>100+</p>
            <p className='text  about-us-txt'>Destinations</p>
          </div>
        </div>
        <div className='about-us-our-team-wrapper'>
          <div className='about-us-our-team-wrapper-bg' >
            <img src={OurTeamBg} alt='OurTeamBg' />
          </div>
          <div className='about-us-our-team'>
            <div className='container'>
              <h3 className='our-team-heading'>Our leadership team</h3>
              <div className='d-flex our-team-details'>
                <p className='team-member-description'>Mr. B. Chandran (BC) is the Managing Director & Founder of Hammock Holidays. He worked in Patel Group of Companies & Chandrika Soaps before starting up this company 2.6 decades ago with the motive of providing the best travel & holiday experience for holiday lovers.</p>
                <div className='our-team-img-wrapper'>
                  <img src={Bc} alt='Mr. B. Chandran (BC)' />
                  <p className='member-name mt-4'>Mr. B. Chandran (BC)</p>
                  <p className='mb-4'>Managing Director & Founder</p>
                </div>
              </div>
              <div className='d-flex our-team-details flex-row-reverse'>
                <p className='team-member-description'>Mr. Dilip Kumar (DK) is one of the Directors of Hammock Holidays and has been with us for over 2 decades now. He has been in industry for as long as 43 years and is well versed in the field with major contacts and partnerships with many travel partners across the country.</p>
                <div className='our-team-img-wrapper'>
                  <img src={Dk} alt='Mr. Dilip Kumar (DK)' />
                  <p className='member-name mt-4'>Mr. Dilip Kumar (DK)</p>
                  <p className='mb-4'>Director</p>
                </div>
              </div>
              <div className='d-flex our-team-details'>
                <p className='team-member-description'>Mr. Ramananda Shetty (RS) - With his Masters in Tourism degree from Mangalore University, Rama joined Hammock Holidays as the Junior executive in 2005 before he advanced to the current designation as a Director.
                </p>
                <div className='our-team-img-wrapper'>
                  <img src={Rama} alt='Mr. Ramananda Shetty (RS)' />
                  <p className='member-name mt-4'>Mr. Ramananda Shetty (RS)</p>
                  <p className='mb-4'>Director</p>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div className='about-us-our-technical-team-wrapper container'>
          <h3 className='about-us-txt'>Our technical team</h3>
          <div className='about-us-our-technical-team d-flex flex-column'>
          <img src={PromoCard} alt='PromoCard' />
            <p >Our Tech Partner
Appiness Interactive, a multiple award-winning Bangalore-based UX and technology consulting firm is our tech and marketing partner. For more than a decade, we are associated with Appiness for all our requirements in design, technology, and digital domains.</p>
    
          </div>

        </div>
        <div className='about-us-destinations'>
          <p className='destinations-heading about-us-txt'>Plan your destination with us</p>
          <div className='about-us-slider d-flex'>
            {
              sliderImages?.map((img,idx) => <img key={idx} src={img} alt={`slider ${idx+1}`}/>)
            }
          </div>
        </div>

        <RecognitionsAndPartners fromAbout={true} />

      </div>
      <Footer />
    </div>
  )
}

export default AboutUs