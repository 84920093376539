import React, { useState, useSelector } from 'react';
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import "../../assets/css/partials/myFamilyFirst/myFamilyFirst.scss";
import * as giftRequestAction from "../../redux/actions/giftRequestAction";
import closeIcon from '../../assets/images/common/close-modal.svg';
import { testEmail } from "../../utils/common";
import CommonModal from '../common/commonModal';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import IntlContactNumber from '../common/intlContactNumber';

import {
    REQUEST_SUBMITTED,
    CONNECT_TEAM_MESSAGE,
} from "../../utils/constants";

function PrepareGiftFormModal({id, handleClose, showModal, giftData, inputFieldsClear, closeFormHandler }) {
    console.log("giftData", giftData)
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [show, setShow] = useState(false);

    const data = JSON.parse(localStorage.getItem("userData"));
    const contactName = localStorage.getItem("full_name");
    let contactNum = localStorage.getItem("mobile");
    const [disable, setDisable] = useState(false);
    if(isNaN(contactNum)) {
        let userDataStored = JSON.parse(localStorage.getItem("userData"));
        contactNum = userDataStored ? userDataStored?.user_details?.mobile : "";
    }

    const [state, setState] = useState({
        locations_preferred: giftData.location,
        occasion: giftData.occasion,
        guests: giftData.guest,
        specific_request: giftData.message,
        schedule: giftData.schedule,
        contact_name: contactName ? contactName : "",
        contact_email: data?.user_details?.email ? data?.user_details?.email : "",
        contact_number: contactNum ,
    });
    const [error, seterror] = useState({
        contact_name: false,
        contact_email: false,
        contact_number: false,
    })
    const handleChange = (key, value) => {
        setState(() => ({
            ...state,
            [key]: value.target.value,
        }));
        seterror({
            ...error,
            [key]: false
        })

    };

    const submitHandler = (e) => {
        e.preventDefault();
        if (!state.contact_name || state.contact_name === "") {
            seterror({ contact_name: true })
        } else if (!testEmail(state.contact_email)) {
            seterror({ contact_email: true })
        } else if((state.contact_number.startsWith('+91') && state.contact_number.length!==13)  || !isPossiblePhoneNumber(state.contact_number)){
            seterror({ contact_number: true })
            console.log(state)
        }
        else {
            if(id==1){
            dispatch(giftRequestAction.honeyMoonRequestEnquiry(state, handleSuccess, handleErrors));
            setDisable(true)
            }else if(id==2){
                dispatch(giftRequestAction.anniversaryRequestEnquiry(state, handleSuccess, handleErrors));
                setDisable(true)
                }
                else if(id==3){
                    dispatch(giftRequestAction.giftRequestEnquiry(state, handleSuccess, handleErrors));
                    setDisable(true)
                    }
        }

    }
    console.log("giftRequestEnquiry state", state.guests)

    const handleSuccess = () => {
        closeFormHandler()
        setState({
            locations_preferred: "",
            occasion: "",
            guests: "",
            specific_request: "",
            contact_name: "",
            contact_email: "",
            contact_number: "",
        });

        setShow(true);
        setTimeout(() => {
            setShow(false);
            inputFieldsClear();
            handleClose();
            window.location.reload();
            setDisable(false)
        }, 3000);
    };

    const handleErrors = (message) => {
        enqueueSnackbar(message, {
            variant: "error",
        });
    };

    return (
        <>
            <div className="">

                <Modal className="prepareGiftFormModal" show={showModal} onHide={handleClose}>
                    <span onClick={handleClose} className="cursor-pointer"><img src={closeIcon} className="close-icon" /></span>
                    <Modal.Header>
                        <Modal.Title>Please share your contact details</Modal.Title>

                        <div className="line"></div>

                    </Modal.Header>
                    <Modal.Body>
                        <p>This is for our team to contact you</p>
                        <CommonModal show={showModal} onHide={handleClose} />
                        <form>
                            <div class="form-group custom-input">
                                <input type="text" className="form-control" value={state.contact_name} name="contact_name" 
                                onChange={(value) => handleChange("contact_name", value)} placeholder="Enter Name here"
                                autocomplete="off" 
                                onKeyPress={(event) => {
                                    if (!/^[A-Za-z ]+$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                />
                                <div className="modal-seprator"></div>
                                <label>Your name</label>
                                {error.contact_name === true ? <p className="errorMsg mob-error text-center">Your name is required</p> : null}
                            </div>
                            <div class="form-group custom-input">
                                <input type="text" className="form-control" value={state.contact_email} name="contact_email" onChange={(value) => handleChange("contact_email", value)} autocomplete="off" placeholder="Enter Email here" />
                                <div className="modal-seprator"></div>
                                <label>Your email id</label>
                                {error.contact_email === true ? <p className="errorMsg mob-error text-center">Enter valid email</p> : null}
                            </div>
                            {/* <div class="form-group custom-input">
                                <input type="number" className="form-control" value={state.contact_number} name="contact_number" onChange={(value) => handleChange("contact_number", value)} placeholder="Enter Phone number" />
                                <div className="modal-seprator"></div>
                                <label>Your Contact number</label>
                                {error.contact_number === true ? <p className="errorMsg mob-error text-center">Phone number required</p> : null}
                            </div> */}
                            <div class="form-group custom-input">
                            <IntlContactNumber disabled={false} hasSeprator={true} hideSaveBtn={true} mobile={state.contact_number||''} numberChange={(val)=>{
                                console.log(val?.length > 10, "valllll")
                                            setState(() => ({
                                                ...state,
                                                contact_number: val,
                                            }))
                                            seterror({
                                                ...error,
                                                contact_number: false
                                            }) 
                                            }} 
                                            hasError={error.contact_number} /> 
                            </div>                                            
                            <div className="line"></div>
                            <div className="text-center mt-3">
                                <Button className="btn btn-done" onClick={submitHandler} disabled={disable}>
                                    Submit </Button>
                            </div>
                            {
                                show ? <CommonModal showMyModal={showModal}
                                    modalSubHeadingText={REQUEST_SUBMITTED}
                                    modalHeadingText={CONNECT_TEAM_MESSAGE} /> : null
                            }
                        </form>
                    </Modal.Body>

                </Modal>

            </div>
        </>
    )
}

export default PrepareGiftFormModal;
