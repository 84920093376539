import React, { useState, useRef, useEffect } from 'react';
import OwlCarousel from "react-owl-carousel";
import Header from '../common/header';
import Footer from '../common/footer';
import moment from 'moment';
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as HolidaysAction from '../../redux/actions/holidaysActions';
import Loader from '../common/loader';
import CloseIcon from '../../assets/images/common/close-modal.svg';
import ContactDetailsCard from '../common/contactDetailsCard';
import backIcon from "../../assets/images/common/back-icon.svg";
import GuestsModal from "../common/guestsModal";
import MonthPicker from "./monthPicker";
import UserRequestForm from "./userRequestForm"
import { Helmet } from 'react-helmet';
import useClickOutside from '../../hooks/useClickOutside';
import SanitizedStayTagPopup from "../common/sanitizedStayPopup";


const owl_images = {
    items: 1,
    margin: 0,
    nav: false,
    loop: true,
    dots: true,
    autoplay: true,
    smartSpeed: 500,
    animateIn: 'fadeIn',
    animateOut: 'fadeOut',
};

function International(holidayDetailsPage) {
    const history = useHistory();
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(true);
    const [scroll, setScroll] = useState(false);

    const holidayID = holidayDetailsPage.match.params.id;
    const loginUserData = useSelector((state) => state.auth.login_user_otp);
    const [adultGuestCount, setAdultGuestCount] = useState(0);
    const [childrensGuestCount, setChildrensGuestCount] = useState(0);
    const [count, setCount] = useState(0);
    const [allMonths, setAllMonths] = useState([]);
    const [monthsData, setMonthsData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [request, setRequest] = useState('')
    const [showMonthPickerModal, setShowMonthPickerModal] = useState(false);
    const [showUserForm, setShowUserForm] = useState(false);
    const [bookedDetails, setBookedDetails] = useState({});
    const [showContactModal, setShowContactModal] = useState(false)

    const [setSucces, showSetSuccess] = useState(false)
    const HOLIDAY_DETAILS = useSelector(state => state?.holidays?.holiday_details?.data);
    const internationalPackageData = HOLIDAY_DETAILS && HOLIDAY_DETAILS?.holiday?.packages[0];
    const myRef = useRef(null)
    const executeScroll = () => myRef.current.scrollIntoView()
    const [state, setState] = useState({
        months: "",
        guest: "",
        infantValidate: "",
        special_request: "",
    });

    const userRequestFormRef = useRef();
    useClickOutside(userRequestFormRef, () => {
        setShowUserForm(false)
        
    });

    const handleNumberOfGuests = () => {
        setShowModal(true);
        if (showModal) {
            document.getElementById("guestModal").classList.remove("hide");
            document.getElementById("guestModal").classList.add("show");
        }
    };

    const [error, setError] = useState({
        months: false,
        guest: false,
        infantValidate: false
    })

    const handleChange = (key, value) => {
        setState(() => ({
            ...state,
            [key]: value.target.value,
        }));
    };

    const guestDatahandler = (adultData, childrenData, countData) => {
        setShowModal(false);
        setAdultGuestCount(adultData);
        setChildrensGuestCount(childrenData);
        setCount(countData);
        setState(() => ({
            ...state,
            'guest': `${adultData} adults, ${childrenData} kids, ${countData} infants`
        }))
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(HolidaysAction.getHolidayDetails(holidayID, handleLoader));
        dispatch(HolidaysAction.setSelectedMonths([]))
        // hide/show confirm button on scroll
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 670);
        });

    }, [holidayID]);

    const handleLoader = () => {
        setLoader(false);
    };

    const refreshState = () => {
        setAllMonths([]);
        setAdultGuestCount("");
        setChildrensGuestCount("");
        setCount("");
        setRequest("");
        setState(() => ({
            ...state,
            'months': "",
            'guest': "",
            'infantValidate': "",
            'special_request': "",
        }))
    }

    const selectMonthPickerHandler = () => {
        setShowMonthPickerModal(true);
        if (showMonthPickerModal) {
            document.getElementById("monthsModal").classList.remove("hide");
            document.getElementById("monthsModal").classList.add("show");
        }

    }

    const handleMonthsData = (selectedMonths) => {
        const formatedList = [];
        selectedMonths.forEach((item) => {
            if (typeof item === "string") {
                formatedList.push(moment(item).format(" MMM"))
            }
        })
        const monthsAsString = formatedList.toString();
        setMonthsData(selectedMonths);
        setAllMonths(monthsAsString);
        setState(() => ({
            ...state,
            'months': selectedMonths
        }))
    }

    const handleContactModal = () => {
        setShowContactModal(true)
    }

    const submitHandler = (e) => {
        e.preventDefault();
        if (!state.months || state.months === "" || state.months === null) {
            setError({ months: true });
        }
        else if (!state.guest || state.guest === "" || state.guest === `${0} adults, ${0} kids, ${0} infants`) {
            setError({ guest: true })
        }
        else if ((count >= 1 || childrensGuestCount >= 1) && adultGuestCount === 0) {
            setError({ infantValidate: true })
        }

        else {
            setError({ guest: false })
            let data = JSON.parse(localStorage.getItem("userData"));
            const bookData = {
                holiday_pack: internationalPackageData?.id,
                adults: adultGuestCount,
                children: childrensGuestCount,
                infants: count,
                schedule: monthsData,
                special_request: request,
            };
            setBookedDetails(bookData)
            setShowUserForm(true);
        }
    };

    const getGuestCount = (adult, kids, infant) => {

        let tmpGuestsArray = [];
        if (adult > 0) {
            tmpGuestsArray.push(`${adult} adult${adult > 1 ? 's' : ''}`);
        }
        if (kids > 0) {
            tmpGuestsArray.push(`${kids} kid${kids > 1 ? 's' : ''}`);
        }
        if (infant > 0) {
            tmpGuestsArray.push(`${infant} infant${infant > 1 ? 's' : ''}`);
        }
        return tmpGuestsArray.join(', ');
    };

    const pauseVideos = () => {

    }

    const goBackFun = ()=>{
        if(!localStorage.getItem('prevUrl')){
            history.push('/holidays');
           
        } else {
            history.goBack();
        } 
    };

    return (
        <div>
            <Helmet>
                <title>{`${internationalPackageData?.pack_title}`}</title>
                <meta name="description" content="Employee Recognition Programs Inspiring Rewards – Inspiring Performance" />
            </Helmet>
            <div className={Object.keys(loginUserData).length !== 0 &&
                loginUserData.constructor === Object ? 'innerPage-header hide-header-mobi' : 'innerPage-header hide-header-mobi pre-login-header'}>
                <Header />
            </div>
            {
                !loader ?
                    (<div className="container innerPage-container innerPage-container-mobi">
                        <a href onClick={() => goBackFun()}>
                            <div className="back-button back-button-mobi">
                                <img src={backIcon} alt="" />
                            </div>
                        </a>

                        <div className="package-holder" key={internationalPackageData?.id}>
                            <div className="package-title d-none d-md-block">
                                <h1>{internationalPackageData?.pack_title}</h1>
                                {
                                    internationalPackageData?.opening_soon !== null ? <p className="workshop-type text-center mb-0" style={{ textTransform: 'uppercase' }}>REOPENING ON {moment(internationalPackageData?.opening_soon).format('MMM, YYYY')}</p> : ''
                                }
                                <p className="international-location">{internationalPackageData?.location[0]?.city} . {internationalPackageData?.location[0]?.state}</p>
                            </div>
                            <div className="line d-none d-md-block" style={{ marginTop: '-30px' }}></div>
                            <div className="package-details">
                                <div className="row">

                                    <div className="col-12">
                                        {(
                                            <div className="images--holder">
                                                {internationalPackageData && internationalPackageData?.images?.length > 1 ?
                                                    <OwlCarousel className="owl-theme" {...owl_images}>
                                                        {
                                                            internationalPackageData?.images?.map((item) => (
                                                                <div className="item">
                                                                    <div className="package-image">
                                                                        <img
                                                                            src={item?.image}
                                                                            className="img-fluid"
                                                                            alt="images"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ))

                                                        }
                                                    </OwlCarousel> : 
                                                    <div className="item">
                                                        <div className="package-image">
                                                            <img
                                                                src={internationalPackageData?.images[0]?.image}
                                                                className="img-fluid w-100"
                                                                alt="images"

                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        )}
                                        {
                                            // mobile view
                                        }
                                        <div className="package-title d-block d-md-none">
                                            <h1>{internationalPackageData?.pack_title}</h1>
                                            {
                                                internationalPackageData?.opening_soon !== null ? <p className="workshop-type text-center mb-0" style={{ textTransform: 'uppercase' }}>REOPENING ON {moment(internationalPackageData?.opening_soon).format('MMM, YYYY')}</p> : ''
                                            }
                                            <p className="international-location">{internationalPackageData?.location[0]?.city} . {internationalPackageData?.location[0]?.state}</p>
                                        </div>

                                    </div>

                                </div>
                                <div class="row">
                                    <div className="col-12 col-lg-7">
                                        {internationalPackageData?.covid_safe === true && 
                                          <SanitizedStayTagPopup pauseVideos={pauseVideos} />
                                        }
                                        <div className="package-description">
                                            <p dangerouslySetInnerHTML={{
                                                __html: internationalPackageData?.description
                                            }}>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-12 col-lg-5">
                                        <div className="login-holder booking-holder" ref={myRef}>
                                            <h5 style={{ lineHeight: '42px' }}>Travel to {internationalPackageData?.location[0]?.city}</h5>
                                            <p className="fill-details">
                                                Fill details below to proceed
                                            </p>
                                            <form >
                                                {
                                                    (
                                                        <div className="form-group position-relative">
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                onChange={(value) => handleChange("months", value)}
                                                                placeholder="Mar, Apr, Jun"
                                                                onClick={selectMonthPickerHandler}
                                                                value={`${allMonths}`}
                                                            />
                                                            <label for="">When are you planning</label>
                                                            {error.months === true ? <div className="errorMsg text-center">Please select months</div> : ""}
                                                            {
                                                                showMonthPickerModal &&
                                                                <MonthPicker
                                                                    showMyModal={showMonthPickerModal}
                                                                    handleMonthsData={handleMonthsData}
                                                                />
                                                            }
                                                        </div>
                                                    )
                                                }
                                                <div className="form-group position-relative">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Number of guests"
                                                        onChange={(value) => handleChange("guest", value)}
                                                        onClick={handleNumberOfGuests}
                                                        value={
                                                            state?.guest === "" ? ""
                                                                : getGuestCount(adultGuestCount, childrensGuestCount, count)
                                                        }
                                                    />
                                                    <label for="">Guests</label>
                                                    {error.guest === true ? <div className="errorMsg text-center">Please enter number of guests</div> : null}
                                                    {error.infantValidate === true ? <div className="errorMsg text-center">For Infants/children at least 1 adult is required
                                                    </div> : null}
                                                    {
                                                        showModal && <GuestsModal
                                                            showMyModal={showModal}
                                                            guestDatahandler={guestDatahandler}
                                                            adultCount={adultGuestCount}
                                                            childrensCount={childrensGuestCount}
                                                            infantCount={count}
                                                        />
                                                    }
                                                </div>
                                                <div className="form-group specific-request">
                                                    <label for="">Any specific request</label>
                                                    <div className="custom-request">
                                                        <input type="text"
                                                            name=""
                                                            id=""
                                                            placeholder="Enter here"
                                                            value={request}
                                                            onChange={(e) => setRequest(e.target.value)}
                                                        />
                                                        <div class="form-line"></div>
                                                        <div className="example">
                                                            <p>Example:</p>
                                                            <p>I am out of points, Can i extend my stay by paying</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn proceed-to-book w-100"
                                                        onClick={submitHandler}
                                                    >
                                                        Submit request
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                        {
                                            showContactModal ? <div className="contact-modal">
                                                <ContactDetailsCard openModal={setShowContactModal} />
                                            </div> : null
                                        }
                                        <div className="d-none d-md-block sticky-contact">
                                            <ContactDetailsCard stickyBtnText={'Submit request'} isInternational={true} />
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className={scroll ? "d-none" : "d-block"}>
                            <div className="mobile-buttons text-center">
                                <div className="stcky-leftButton">
                                    <div className="white-button" onClick={handleContactModal}>
                                        <a >Contact</a>
                                    </div>
                                </div>
                                <div className="stcky-rightButton">
                                    <div className="custom-button" onClick={executeScroll}>
                                        <a >Travel here</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            showUserForm &&
                            <div className="internation-user-form">
                                <UserRequestForm userData={bookedDetails} setSuccess={refreshState} openModal={setShowUserForm} showMyModal={showUserForm} userRequestFormRef={userRequestFormRef}/>
                            </div>
                        }
                    </div>) : <Loader />}
            <Footer />
        </div>
    )
}

export default International
