import { WEDDING_STORIES_LIST, WEDDING_DESTINATIONS } from '../actions/types';

const initialState = {

    wedding_stories_list: [],
    wedding_destinations_list: []
}

const weddingsReducer = (state = initialState, action) =>{
    switch(action.type){
        case WEDDING_STORIES_LIST:
            return {
            ...state,
            wedding_stories_list: action.payload,
            }
        case WEDDING_DESTINATIONS:
            return{
                ...state,
                wedding_destinations_list : action.payload
            }
        default:
            return state
    }
}
export default weddingsReducer