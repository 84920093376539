import React, {useEffect} from 'react'
import OwlCarousel from "react-owl-carousel";
import { useDispatch, useSelector } from "react-redux";
import { corporate_options } from '../../resources/carouselOptions';
import { useHistory } from "react-router-dom";
import * as ExperienceAction from '../../redux/actions/experiencesAction';
import * as HolidaysAction from '../../redux/actions/holidaysActions';



function ViewMoreExperiences({similarPackages, similarPackageEmptyData,startLoader,experienceID}) {
    const dispatch = useDispatch();
    const history = useHistory();
    const SIMILAR_EXPERIENCES = useSelector((state) => state.experiences.more_experiences.data);
    const id = similarPackages;
    const userInfo = JSON.parse(localStorage.getItem("userData"));
    const isCorporateAccount = userInfo?.user_details?.corporate_account;
    const companyDomain = userInfo?.organisation?.email_domain;
    const isWipro = (companyDomain === "wipro.com");
    useEffect(() => {
        if(id){
        dispatch(ExperienceAction.getMoreExperiences(id));
        }
    }, [id]);

    const refreshSimilarPackages = () => {
        dispatch(ExperienceAction.getMoreExperiences(id));
    }
    const handleExperienceDetails = (id,pkgId) => {
        history.push({pathname:`/experiences/experience-details/${id}`,state:{id:pkgId}});
        if(id != experienceID){
            startLoader()
          }
        similarPackageEmptyData()
        window.scrollTo(0, 0);
      };
    const handleSeeAll = () => {
        history.push({pathname: `/experiences`, seeAll: {bool: true}});
        dispatch(HolidaysAction.setFilterValues(""))
        dispatch(HolidaysAction.setSearchValue(""))
        window.scroll(0,0)
    }
      
    return (<>
        <div className="d-none d-lg-block">
        {
            SIMILAR_EXPERIENCES && SIMILAR_EXPERIENCES.length > 0 ?  <div className="container">
            <div className="nearby-experiences similar-offers stays-nearby">
                <h5><span>Expereience</span> something new this year</h5>
                <div className="divider"></div>
                <OwlCarousel className="owl-theme" {...corporate_options}>
                {
                    SIMILAR_EXPERIENCES && SIMILAR_EXPERIENCES.length > 0 && SIMILAR_EXPERIENCES.map((items) => (
                        <div className="item">
                            <div className="card-wrapper d-lg-flex flex-column" style={{cursor: 'pointer'}} onClick={() => handleExperienceDetails(items.experience_slug,items.id)}>
                                <img src={items.image} className="w-100" alt={items.pack_title} />
                                <div className="info">
                                   <p>{items.pack_title}</p>
                                   {/* <div className="desc">
                                        <p>{items.brief_description}</p>
                                   </div> */}
                                   {
                                    (isCorporateAccount && isWipro) ? <p className="points">{(items.revised_price?items.revised_price:items.price).toLocaleString('en-IN')} pts</p> : 
                                     <p className="points"> Rs {(items.revised_price?items.revised_price:items.price).toLocaleString('en-IN')}</p>
                                   }

                                </div>
                            </div>
                        </div>
                    ))
                }
                {SIMILAR_EXPERIENCES && SIMILAR_EXPERIENCES.length > 5 ? 
                    <div className="item">
                        <div className="card-wrapper see-all" style={{cursor: 'pointer'}} onClick={() => handleSeeAll()}>
                        <p>  See all <br />
                        Experiences </p>
                        </div>
                    </div>
                : null}
                </OwlCarousel>
            </div>
        </div> : null
        }
        </div>
        <div className="stories d-lg-none ">
        { SIMILAR_EXPERIENCES && SIMILAR_EXPERIENCES.length > 0 ? 
            <div className="container ">
                <div className="nearby-experiences  mb-10">
                <h5><span>Expereience</span> something new this year</h5>
                    <div className="divider"></div>
                </div>
            </div>: null}
            <div className="view-more-holder">
                <div className="wrapper">        
                {
                    SIMILAR_EXPERIENCES && SIMILAR_EXPERIENCES.length > 0 && SIMILAR_EXPERIENCES.map((items) => (
                        <div className="item" >
                            <div className="card-wrapper" onClick={() => handleExperienceDetails(items.experience_slug,items.id)} style={{cursor: 'pointer'}}>
                                <div className="card-img">
                                    <img src={items.image} className="w-100" alt={items.pack_title} />
                                </div>
                                <div className="info">
                                    <p className="title">{items.pack_title}</p>
                                
                                { items.price !== 0 ?
                                        (isCorporateAccount && isWipro) ? <p className="points">{(items.revised_price?items.revised_price:items.price).toLocaleString('en-IN')} pts</p> :   <p className="points">Rs {(items.revised_price?items.revised_price:items.price).toLocaleString('en-IN')}</p> : <p className="points">Book for free</p>
                                    }</div>
                            </div>
                        </div>
                        ))
                }
                {
                        SIMILAR_EXPERIENCES && SIMILAR_EXPERIENCES.length > 5 ?
                        <div className="item">
                            <div className="card-wrapper see-all" style={{cursor: 'pointer'}} onClick={() => handleSeeAll()}>
                            <p>  See all  </p>
                            </div>
                        </div>: null
                    }
                </div>
            </div>
        </div>
        </>
    )
}

export default ViewMoreExperiences
