import React, { useState, useEffect, Fragment } from 'react';
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import AddIcon from '../../../assets/images/common/feather_plus.svg';
import EditIcon from '../../../assets/images/common/feather_edit.svg';
import CloseIcon from '../../../assets/images/common/close-modal.svg';
import * as ProfileAction from '../../../redux/actions/profileAction';
import OtpComponent from '../../auth/otpComponent';
import  InputComponent from '../inputComponent';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

function EditPhoneNumber({ contactNumber, handleClose, handleShow, showModal, handleOtpComponent, modalFalse, addNew}) {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [ showOtpModal, setShowOtpModal ] = useState(null)
    const userInfo = JSON.parse(localStorage.getItem("userData"));
    const isCorporateAccount = userInfo?.user_details?.corporate_account;
    const [mobile, setMobile] = useState(contactNumber);
    const numExist = useSelector(state => state.userProfile.numberAlreadyExist);
    const [error, setError] = useState({
        mobile: false,
    });


    const handleChange = (val) => {
        if(val){
            console.log(val)
            setMobile(val);
            dispatch(ProfileAction.updatedMobileNumber(val));
        }
        
    };

    const updateMobileNumber = (e) => {
        e.preventDefault();
        if (!mobile || mobile === "") {
            setError({ mobile: true })
        }
        else if(isCorporateAccount) {
            dispatch(ProfileAction.updateUserPhoneNumber(mobile, handleSuccess, handleErrors));
            // localStorage.setItem("mobile",mobile);
        }
        else {
            dispatch(ProfileAction.updateNonCorpUserPhoneNumber(mobile, handleSuccess, handleErrors));
            // localStorage.setItem("mobile",mobile);
        }
    };

    const handleSuccess = () => {
        setMobile("");
        // enqueueSnackbar("Phone number updated successfully", {
        //     variant: "success",
        // });
        handleOtpComponent(true);
        handleClose();
        refreshProfileDetails();
    };

    const handleErrors = (message) => {
        enqueueSnackbar(message, {
            variant: "error",
        });
        handleOtpComponent(false);
    };

    const refreshProfileDetails = () => {
        if(isCorporateAccount){
            dispatch(ProfileAction.getProfileDetails());
        }
        else {
            dispatch(ProfileAction.getNonCorporateProfileDetails())
        }
    };

    return (
        <Fragment>
        <div>

            <Modal className="custom-modal" show={showModal} onHide={handleClose}>
                <Modal.Header>
                {
                    addNew ? <Modal.Title>Adding Contact</Modal.Title> : <Modal.Title>Updating Contact</Modal.Title>
                }
                    <div className="cursor-pointer" onClick={handleClose}>
                        <img src={CloseIcon} alt="" />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        
                        <InputComponent
                                type="phonenumber"
                                name="number"
                                // placeholder="Your phone number"
                                // placeholder={addNew?'Add your mobile number':'Edit your mobile number'}
                                value={mobile}
                                onChange={handleChange}
                                titleValue={addNew?'Add your mobile number':'Edit your mobile number'}
                                msg={'Enter a valid  mobile number'}
                                isTrue={error.mobile}
                                
                            />
                        <div className="text-right mt-3" style={{display:"flex", justifyContent:"center"}}>
                            <Button className="btn btn-done" onClick={updateMobileNumber} disabled={contactNumber === mobile}>
                                Recieve OTP
                                 </Button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
        </Fragment>
    )
}

export default EditPhoneNumber
