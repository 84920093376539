export const scheduleDuration = (dataChangedBool, dataChanged, initialPackageData) => {
    if(dataChangedBool === true && dataChanged !== null && dataChanged.schedule_type === "recurring"){
        return <span>{ ` - ${dataChanged.schedule_data.recurringHours > 1 ? `${dataChanged.schedule_data.recurringHours} hrs ${dataChanged.schedule_data.recurringMinutes === undefined ?"": `${dataChanged.schedule_data.recurringMinutes > 1 ? `${dataChanged.schedule_data.recurringMinutes} mins` : `${dataChanged.schedule_data.recurringMinutes} min`}`}/day`:`${dataChanged.schedule_data.recurringHours} hr ${dataChanged.schedule_data.recurringMinutes !== undefined ? `${dataChanged.schedule_data.recurringMinutes}min`:""}/day` }`}</span>
    }else{
        return <span>{ `- ${initialPackageData.schedule_data.recurringHours > 1 ? `${initialPackageData.schedule_data.recurringHours}hrs ${initialPackageData?.schedule_data?.recurringMinutes ===undefined ? "" :`${initialPackageData?.schedule_data?.recurringMinutes > 1 ? `${initialPackageData?.schedule_data?.recurringMinutes}mins` : `${initialPackageData?.schedule_data?.recurringMinutes}min`} ` } /day` : `${initialPackageData.schedule_data.recurringHours}hr ${initialPackageData.schedule_data.recurringMinutes !==undefined? `${initialPackageData.schedule_data.recurringMinutes}min`:""} /day`}`}</span>
    }
};

export const specificScheduleDuration = (dataChangedBool, dataChanged, initialPackageData) => {
   if(dataChangedBool === true && dataChanged !== null && dataChanged.schedule_type === "specific_dates"){
       return <span>{ `- ${dataChanged.schedule_data.hours === undefined || dataChanged.schedule_data.hours === "" ? "" : `${dataChanged.schedule_data.hours > 1 ? `${dataChanged.schedule_data.hours} hrs` : `${dataChanged.schedule_data.hours}hr`}`}  ${dataChanged.schedule_data.minutes === undefined || dataChanged.schedule_data.minutes === "" || dataChanged.schedule_data.minutes === '0'? "" : `${dataChanged.schedule_data.minutes > 1 ? `${dataChanged.schedule_data.minutes}mins`: `${dataChanged.schedule_data.minutes}min`}`} /day`}</span>
   }else{
       return <span>{ `- ${initialPackageData.schedule_data.hours === undefined ? "" : `${initialPackageData.schedule_data.hours > 1 ? `${initialPackageData.schedule_data.hours} hrs`: `${initialPackageData.schedule_data.hours} hr`}`}  ${initialPackageData.schedule_data.minutes === undefined  || initialPackageData.schedule_data.minutes === "" || initialPackageData.schedule_data.minutes === '0'? "" :`${initialPackageData.schedule_data.minutes > 1 ? `${initialPackageData.schedule_data.minutes} mins` : `${initialPackageData.schedule_data.minutes} min`}` } /day`}</span>
   }
}