 const travelAndTourismManager={
    id:"1",
    name:"Travel and Tourism Manager",
    responsibilities:["Source new sales opportunities through inbound lead follow-up and outbound cold calls and emails","Grasp over Domestic as well as International Tour Packages","Understanding the client’s requirements and providing a solution for the same","To explain the tour packages a client is looking for","Must have good convincing skills","Maintain and expand your database of prospects within your assigned territory"],
    skills:['Promoting and marketing the business','Selling travel products and tour packages','Sourcing products and destinations to meet consumer demands for bespoke travel and sustainable tourism','Dealing with customer inquiries and aiming to meet their expectations','Overseeing the smooth, efficient running of the business'],
    qualification:'3-5 years of experience & graduated or above in any field with excellent command of the English language.'
}
 const travelAndTourismExecutive={
    id:"2",
    name:"Travel and Tourism Executive",
    responsibilities:['Source new sales opportunities through inbound lead follow-up and outbound cold calls and emails','Route qualified prospects to the appropriate sales executives for further development and closure','Understanding the client’s requirements and providing a solution for the same','To explain the tour packages a client is looking for','Must have good convincing skills','Maintain and expand your database of prospects within your assigned territory'],
    skills:['Promoting and marketing the business','Selling travel products and tour packages','Sourcing products and destinations to meet consumer demands for bespoke travel andsustainable tourism','Dealing with customer inquiries and aiming to meet their expectations','Helping in the smooth, efficient running of the business'],
    qualification:'1-3 years of experience & graduated or above in any field with excellent command of the English language.'
}
 const juniorExecutiveToursAndTravel={
    id:"3",
    name:"Junior Executive Tours and Travel",
    responsibilities:['Work closely with the team to understand Hammock Holidays’ offers and packages','Aid the Sales & Marketing team in completing the deals','Updating new packages and deals on the website','Supplying travelers with pertinent information and useful travel/holiday material'],
    skills:['Promoting and marketing the business','Dealing with customer inquiries and aiming to meet their expectations','Helping in the smooth, efficient running of the business'],
    qualification:'0-3 years of experience & graduated or above in any field with excellent command of the English language.'
}
export const jobData=[travelAndTourismExecutive,travelAndTourismManager,juniorExecutiveToursAndTravel]
