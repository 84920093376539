import React, { useState, useRef, useEffect } from "react";
import CustomizeCards from './customizeCards';
import closeIcon from '../../../assets/images/common/close-modal.svg';
import GuestsModal from "../../common/guestsModal";
import ContactDetailsCard from '../../common/contactDetailsCard';
import MonthPicker from "../monthPicker";
import moment from 'moment';
import { useSelector, useDispatch } from "react-redux";
import * as HolidaysAction from '../../../redux/actions/holidaysActions';
import UserRequestForm from "../userRequestForm";
import DomesticCoverage from "./domesticCoverage";
import InternationalCoverage from "./internationalCoverage";
import useClickOutside from '../../../hooks/useClickOutside';
import { Helmet } from 'react-helmet';

function CustomizePackages() {
    const [showModal, setShowModal] = useState(false);
    const [modal, setModal] = useState(false);
    const dispatch = useDispatch();
    const [adultGuestCount, setAdultGuestCount] = useState(0);
    const [childrensGuestCount, setChildrensGuestCount] = useState(0);
    const [count, setCount] = useState(0);
    const [showForm, setShowForm] = useState(false);
    const [scroll, setScroll] = useState(false);
    const [request, setRequest] = useState('');
    const [bookedDetails, setBookedDetails] = useState({}); 
    const [showUserForm, setShowUserForm] = useState(false);
    const [showMonthPickerModal, setShowMonthPickerModal] = useState(false);
    const [allMonths, setAllMonths] = useState([]);
    const [monthsData, setMonthsData] = useState([]);
    const [showContactModal, setShowContactModal] = useState(false)
    const myRef = useRef(null)
    const executeScroll = () => myRef.current.scrollIntoView()

    const handleContactModalClose = () => {
        setShowContactModal(false)
    }
    const userRequestFormRef = useRef();
    useClickOutside(userRequestFormRef, () => {
        setShowUserForm(false)
        
    });
    const contactModalRef = useRef();
    useClickOutside(contactModalRef, (e) => {
       setTimeout( () => setShowContactModal(false),100)
        
    });

    

    const handleNumberOfGuests = () => {
        setShowModal(true);
        if (showModal) {
            document.getElementById("guestModal").classList.remove("hide");
            document.getElementById("guestModal").classList.add("show");
        }
    };

    const selectMonthPickerHandler = () => {
        setShowMonthPickerModal(true);
        if (showMonthPickerModal) {
            document.getElementById("monthsModal").classList.remove("hide");
            document.getElementById("monthsModal").classList.add("show");
        }

    }

    const [state, setState] = useState({
        locations_preferred: "",
        months: "",
        guest: "",
        infantValidate: "",
        special_request: "",
    });

    const [error, setError] = useState({
        locations_preferred: false,
        months: false,
        guest: false,
        infantValidate: false
    })

    useEffect(() => {
        dispatch(HolidaysAction.setSelectedMonths([]))
        // hide/show confirm button on scroll
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 950);
        });
    }, []);

    const handleChange = (key, value) => {
        setState(() => ({
            ...state,
            [key]: value.target.value,
        }));
        setError({
            ...error,
            [key]: false
        })
    };

    const guestDatahandler = (adultData, childrenData, countData) => {
        setShowModal(false);
        setAdultGuestCount(adultData);
        setChildrensGuestCount(childrenData);
        setCount(countData);
        setState(() => ({
            ...state,
            'guest': `${adultData} adults, ${childrenData} kids, ${countData} infants`
        }))
        if(adultData || childrenData || countData){
            setError({
                ...error,
                'guest': false,
                'infantValidate': adultData == 0 ? true : false
            })
        }
    };



    const handleMonthsData = (selectedMonths) => {
        const formatedList = [];
        selectedMonths.forEach((item) => {
            if (typeof item === "string") {
                formatedList.push(moment(item).format(" MMM"))
            }
        })
        const monthsAsString = formatedList.toString();
        setMonthsData(selectedMonths);
        setAllMonths(monthsAsString);
        setState(() => ({
            ...state,
            'months': selectedMonths
        }))
        if(selectedMonths.length){
            setError({
                ...error,
                'months': false
            })
        }
    }

    const submitHandler = (e) => {
        e.preventDefault();
        if (!state.locations_preferred || state.locations_preferred === "") {
            focusInput('#locations_preferred');
            setError({ locations_preferred: true });
        }
        else if (!state.months || state.months === "") {
            focusInput('#months');
            setError({ months: true });
        }
        else if (!state.guest || state.guest === "" || state.guest === `${0} adults, ${0} kids, ${0} infants`) {
            focusInput('#guest');
            setError({ guest: true })
        }
        else if ((count >= 1 || childrensGuestCount >= 1) && adultGuestCount === 0) {
            focusInput('#guest');
            setError({ infantValidate: true })
        }

        else {
            setError({ guest: false })
            const bookData = {
                adults: adultGuestCount,
                children: childrensGuestCount,
                infants: count,
                locations_preferred: state?.locations_preferred,
                schedule: monthsData,
                special_request: request,
            };
            setBookedDetails(bookData)
            setShowUserForm(true);
        }
    };

    const refreshState = () => {
        setAllMonths([]);
        setAdultGuestCount("");
        setChildrensGuestCount("");
        setCount("");
        setRequest("");
        setState(() => ({
            ...state,
            'locations_preferred': "",
            'months': "",
            'guest' : "",
            'infantValidate': "",
            'special_request': "",
        }))
        dispatch(HolidaysAction.setSelectedMonths([]))
    }

    const handleContactModal = () => {
        setShowContactModal(true)
    }

    const getGuestCount = (adult, kids, infant)=>{
        
        let tmpGuestsArray = [];
        if(adult>0){
            tmpGuestsArray.push(`${adult} adult${adult>1?'s':''}`);
        }
        if(kids>0){
            tmpGuestsArray.push(`${kids} kid${kids>1?'s':''}`);
        }
        if(infant>0){
            tmpGuestsArray.push(`${infant} infant${infant>1?'s':''}`);
        }
        return tmpGuestsArray.join(', ');
    };

    const focusInput = (inputDom)=>{
        var elementPosition = document.querySelector(`${inputDom}`).getBoundingClientRect().top;
                    var offsetPosition = elementPosition + window.pageYOffset - 145;
                    window.scrollTo({top: offsetPosition, behavior: "smooth" });
                    setTimeout(()=>{
                        document.querySelector(inputDom).focus();
                    });
    };

    useEffect(()=>{
        if(showContactModal){
          document.querySelector('html').style.overflow = "hidden"
    
        } else {
          document.querySelector('html').style.overflow = "scroll"
        }
    
      }, [showContactModal])
    
    return (
        <div>
            <Helmet>
                <title>Holidays - Hammock Holidays</title>
                <meta name="description" content="Plan Exclusive Holidays - Find what’s best for you" />
            </Helmet>
            <div className="container">
                <div className="line"></div>
                <div className="row">
                    <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12">
                        <CustomizeCards />
                    </div>
                    <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 pl-lg-0">
                        <div className={showForm ? "prepareGift-mobile" : "prepareGift-desk"} >
                            <div className="login-holder booking-holder"
                            ref={myRef}
                            >
                                <h5 >Customize for me</h5>
                                <p className="fill-details">
                                    Fill details below to proceed
                                </p>
                                <form >
                                    <div className="form-group">
                                        <input
                                            value={state?.locations_preferred}
                                            type="text"
                                            id="locations_preferred"
                                            className="form-control"
                                            placeholder="China, Spain, Pudu"
                                            onChange={(value) => handleChange("locations_preferred", value)}
                                            onKeyPress={(event) => {
                                                if (!/^[A-Za-z ]+$/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                        <div className="form-line"></div>
                                        <label>Preferred locations</label>
                                        {error.locations_preferred === true ? <span className="errorMsg text-center">Preferred location is required</span> : null}
                                    </div>
                                    <div className="form-group position-relative">
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="months"
                                            readOnly
                                            onChange={(value) => handleChange("months", value)}
                                            placeholder="Mar, Apr, Jun"
                                            onClick={selectMonthPickerHandler}
                                            value={`${allMonths}`}
                                        />
                                        <label for="">When are you planning</label>
                                        {error.months === true ? <div className="errorMsg text-center">Please select months</div> : ""}
                                        {
                                            showMonthPickerModal &&
                                            <MonthPicker
                                                showMyModal={showMonthPickerModal}
                                                handleMonthsData={handleMonthsData}
                                            />
                                        }
                                    </div>
                                    <div className="form-group position-relative">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="guest"
                                            readOnly
                                            placeholder="Number of guests"
                                            onChange={(value) => handleChange("guest", value)}
                                            onClick={handleNumberOfGuests}
                                            value={
                                                state?.guest === "" ? ""
                                                    : getGuestCount(adultGuestCount, childrensGuestCount, count) 
                                            }
                                        />
                                        <label for="">Guests</label>
                                        {error.guest === true ? <div className="errorMsg text-center">Please enter number of people</div> : null}
                                        {error.infantValidate === true ? <div className="errorMsg text-center">For Infants/children at least 1 adult is required
                                        </div> : null}
                                        {
                                            showModal && <GuestsModal
                                                showMyModal={showModal}
                                                guestDatahandler={guestDatahandler}
                                                adultCount={adultGuestCount}
                                                childrensCount={childrensGuestCount}
                                                infantCount={count}
                                            />
                                        }
                                    </div>
                                    <div className="form-group specific-request">
                                        <label for="">Any specific request</label>
                                        <div className="custom-request">
                                            <input type="text"
                                                name=""
                                                id=""
                                                placeholder="Enter here"
                                                value={request}
                                                onChange={(e) => setRequest(e.target.value)}
                                            />
                                            <div class="form-line"></div>
                                            <div className="example">
                                                <p>Example:</p>
                                                <p>I am out of points, Can I extend my stay by paying</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <button
                                            type="button"
                                            className="btn proceed-to-book w-100"
                                             onClick={submitHandler}
                                        >
                                            Submit request
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {
                            showUserForm &&
                            <div className="internation-user-form">
                                <UserRequestForm userData={bookedDetails} setSuccess={refreshState}  openModal={setShowUserForm} showMyModal={showUserForm} customize={true} userRequestFormRef={userRequestFormRef}/> 
                            </div>
                        }
                        {
                            showContactModal ? <div className="contact-modal">
                                <ContactDetailsCard openModal={setShowContactModal} contactModalRef={contactModalRef}/>
                            </div> : null
                        }
                        <div className="d-none d-md-block">
                            <ContactDetailsCard />
                        </div>
                    </div>
                </div>
            
            </div>
            {  
                // contains logo's of domestic and international coverage partners
             <div>
                  <DomesticCoverage />
                  <InternationalCoverage />
             </div>
            }
            <div className={scroll ? "d-none" : "d-block"}>
            <div className="mobile-buttons text-center">
                <div className="stcky-leftButton">
                    <div className="white-button" style={{padding: '20px 10px' }} onClick={handleContactModal}>
                        <a >Contact</a>
                    </div>
                </div>
                <div className="stcky-rightButton">
                    <div className="custom-button" style={{padding: '20px 10px' }} onClick={executeScroll}>
                        <a >Customize for me</a>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default CustomizePackages
