import React, { useEffect, useState } from "react";
import SubscribeImg from "../../assets/images/common/subscribe.webp";
import { Modal, Button } from "react-bootstrap";
import closeIcon from "../../assets/images/common/close-modal.svg";
import SuccessfulLogo from "../../assets/icons/authIcons/successfulLogo.png";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useDispatch } from "react-redux";

import * as homeAction from "../../redux/actions/homeAction";
import { testEmail } from "../../utils/common";
import { useSnackbar } from "notistack";
const SubscripeModel = ({ showModal, setShowModal }) => {
  const dispatch = useDispatch();
  const [successModal, setSuccessModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState({
    contact_name: "",
    contact_email: "",
    
  });
  const [error, seterror] = useState({
    contact_name: false,
    contact_email: false,
    
  });
  const handleChange = (key, value) => {
    setState(() => ({
      ...state,
      [key]: value.target.value,
    }));
    seterror({
      ...error,
      [key]: false,
    });
  };
  useEffect(() => {
    if (showModal) {
      document.querySelector("html").style.overflow = "hidden";
    } else {
      document.querySelector("html").style.overflow = "scroll";
    }
  }, [showModal]);

  const handleClose = () => {
    setState({
      contact_name: "",
      contact_email: "",
    
    });
    seterror({
      contact_name: false,
      contact_email: false,
     
    });
    setShowModal(false);
    setSuccessModal(false);
  };
  const submitHandler = (e) => {
    e.preventDefault();
    if (!state.contact_name || state.contact_name === "") {
      seterror({
        contact_name: true,
        contact_email: true,
        
      });
    } else if (!testEmail(state.contact_email)) {
      seterror({ contact_email: true });
    }  else {
      const data = {
        name: state.contact_name,
        email: state.contact_email,
    
      };
      dispatch(homeAction.newsLetter(data, handleSuccess, handleErrors));
    }
  };
  const handleSuccess = (message) => {
    setSuccessModal(true);
    setTimeout(() => handleClose(), 500);
  };
  const handleErrors = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
    });
  };
  return (
    <div className="">
      <Modal
        className="subscribe-modal"
        size="md"
        show={showModal}
        centered
        onHide={handleClose}
      >
        <img src={SubscribeImg} alt="Subscribe-banner" />
        <span onClick={handleClose} className="close-wrap">
          <img src={closeIcon} className="cross-icon" />
        </span>

        <Modal.Body>
          <div className="container text-center">
            <div className="subscribe-popup my-4 mb-5">
              <h1>Subscribe to our Newsletter</h1>
              <p className="mb-4">
                Be the first to know when we add new properties.
              </p>
              {/* <CommonModal show={showModal} onHide={handleClose} /> */}
              <form onSubmit={submitHandler}>
                <div class="form-group custom-input">
                  <input
                    type="text"
                    className="form-control"
                    value={state.contact_name}
                    name="contact_name"
                    onChange={(value) => handleChange("contact_name", value)}
                    placeholder="Enter your name"
                    onKeyPress={(event) => {
                      if (!/^[A-Za-z ]+$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  {error.contact_name === true ? (
                    <p className="errorMsg mob-error">Your name is required</p>
                  ) : null}
                </div>
                <div class="form-group custom-input">
                  <input
                    type="text"
                    className="form-control"
                    value={state.contact_email}
                    name="contact_email"
                    onChange={(value) => handleChange("contact_email", value)}
                    placeholder="Enter your email id"
                  />
                  {error.contact_email === true ? (
                    <p className="errorMsg mob-error">Enter valid email</p>
                  ) : null}
                </div>
                {/* <div class="form-group custom-input">
                  <input
                    type="tel"
                    className="form-control"
                    value={state.contact_number}
                    name="contact_email"
                    onChange={(value) => handleChange("contact_number", value)}
                    placeholder="Enter phone number"
                    onKeyPress={(event) => {
                      if (!/[0-9-+]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  {error.contact_number === true ? (
                    <p className="errorMsg mob-error">Enter valid phone no</p>
                  ) : null}
                </div> */}
                <div className="mt-5">
                  <Button className="btn" type="submit">
                    Subscribe{" "}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div className="login-success-popup">
        <Dialog
          open={successModal}
          maxWidth="xs"
          fullWidth={true}
          onClose={handleClose}
        >
          <DialogTitle>
            <div className="success-holder" style={{}}>
              <div>
                <img src={SuccessfulLogo} alt="successful" />
              </div>
              <div className="success-message">
                <p>Subscribed Successfully</p>
              </div>
            </div>
          </DialogTitle>
        </Dialog>
      </div>
    </div>
  );
};

export default SubscripeModel;
