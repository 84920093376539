import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment';
import { useSelector, useDispatch } from "react-redux";
import checkedIcon from '../../assets/images/holidays/checked.svg';
import * as HolidaysAction from '../../redux/actions/holidaysActions';

function MonthPicker({ showMyModal, handleMonthsData }) {
    const wrapperRef = useRef(null);
    const dispatch = useDispatch();
    const selectedMonths = useSelector((state) => state?.holidays?.selected_months);
    useModalOutsideClose(wrapperRef);
    var monthsList = [];

    // get list of 6 months including current month
    const start = moment().subtract(1, 'month');
    for (let i = 0; i < 6; i++) {
        var months = {}
        months.monthName = start.add(1, 'month').format('MMMM YYYY');
        monthsList.push(months);
    }
    //
    
    useEffect(() => {
    }, [selectedMonths])

    const handleCheckboxes = () => {
        var array = []
        var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')

        for (var i = 0; i < checkboxes.length; i++) {
            array.push(checkboxes[i].value)
        }
        dispatch(HolidaysAction.setSelectedMonths(array))
    }

    const handleSelectedMonths = () => {
        handleMonthsData(selectedMonths)
        handleShowHide();
    }

    function useModalOutsideClose(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    handleShowHide()
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    };

    const handleShowHide = () => {
        document.getElementById("monthsModal").classList.add("hide");
        document.getElementById("monthsModal").classList.remove("show");
    }

    return (
        <div className={`modal-wrapper ${showMyModal ? 'show' : 'hide'}`} id="monthsModal">
            <div className='calendar-overlay'></div>
            <div className="card-box calender-modal select-month" ref={wrapperRef}>
                <div className="row">
                    <div className="col-md-7 order-12 order-md-1 holiday-range-scroll">
                        {
                            monthsList && monthsList.length > 0 && monthsList.map((item) => (
                                <div id="month-checkboxes">
                                    <input type="checkbox" onClick={handleCheckboxes} name="monthsFilter" id={item?.monthName} value={item?.monthName} />
                                    <label className="month-label" for={item?.monthName}  >{item?.monthName}
                                        <span className="checked-icon"> <img src={checkedIcon} /> </span>
                                    </label>
                                </div>
                            ))
                        }

                    </div>
                    <div className="col-md-4 order-1 d-flex d-md-block justify-content-around justify-content-md-left">
                      <div className="selected-months-holder">
                            <h6>Looking for</h6>
                            <div className="d-flex" style={{flexWrap: 'wrap'}}>
                                {
                                    selectedMonths && selectedMonths.length > 0 && selectedMonths.map((months) => (
                                        <div className="months-selected">
                                            <p>{moment(months).format("MMM")}
                                                <span className="mr-1">,</span>
                                            </p>
                                        </div>
                                    ))
                                }
                            </div>
                      </div>
                    </div>
                </div>
                <div className="row calender-footer-btn">
                <button className="btn-common" type="button" onClick={handleSelectedMonths}>Done</button>
            </div>
            </div>
        </div>
    )
}

export default MonthPicker
