import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import CommonButton from "../common/authCommonButton";
import InputComponent from "../common/inputComponent";
import SocialLogin from "../common/socialLogin";
import {
  createSignUpUser,
  createUserOtpSignUp,
} from "../../redux/actions/authAction";
import OtpComponent from "./otpComponent";
import { withRouter } from "react-router-dom";
import Modal from "../common/commonModal";
import {
  SUCCESSFUL_SIGNUP,
  SUCCESSFUL_SIGNUP_WELCOME_MSG,
} from "../../utils/constants";
import { useSnackbar } from "notistack";
import { useHistory, useLocation } from "react-router-dom";
import { testEmail } from "../../utils/common";
import { Helmet } from "react-helmet";
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import exclamationMark from '../../assets/icons/authIcons/confirmation-icon.png';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as ProfileAction from "../../redux/actions/profileAction";


function SignUp({closeModalEvent, toggleModalEvent, clickType,checkoutParams}){
  const fromEventModal = closeModalEvent
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation()

  const [mobile, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [show, setShow] = useState(false);
  const [isTrue, setIsTrue] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [msg, setMsg] = useState("");
  const [isNumTrue, setIsNumTrue] = useState(false);
  const [numMsg, setNumMsg] = useState("");
  const [isRecieveOtp, setIsRecievedOtp] = useState(true);
  const [enableMobileNumber, setEnableMobileNumber] =useState(false);
  const [autoComplete, setAutoComplete] = useState(false);
  const [infoPopup, setInfoPopup] = useState(false);

  useEffect(()=>{
    if(clickType){
      setIsRecievedOtp(true);
      setEmail("");
      setShow(false);
      setIsTrue(false);
    }
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ){
      setAutoComplete(true)
      } 
  }, [clickType]);

  const dispatch = useDispatch();
  const signUpUserOtp = useSelector((state) => state.auth.signup_user_otp);
  const isCorp = useSelector((state) => state.auth.isCorp);

  let eml = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

  const enableOtp = () => {
    setShow(true);
    setIsRecievedOtp(false);
    // setForLogin("forLogin");
  };

  const handleErrors = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
    });
    setIsTrue(false);
  };

 

  const emailValidate = (e) => {
    setEmail(e.target.value);
    setIsTrue(false);
  };

  const numberValidate = (val) => {
    setPhoneNumber(val);
    setIsNumTrue(false);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (email === "") {
      enqueueSnackbar("Please enter a email Id", {
        variant: "error",
      });
    } 
    else if (!testEmail(email)) {
      enqueueSnackbar("Please enter a valid email Id", {
        variant: "error",
      });
    }
    else if (mobile === "" && enableMobileNumber) {
      enqueueSnackbar("Please enter phone number", {
        variant: "error",
      });
    } else if (!isPossiblePhoneNumber(mobile) && enableMobileNumber) {
      console.log(mobile);
      enqueueSnackbar("Please enter a valid phone number", {
        variant: "error",
      });
      // setNumMsg("Except only number");
      // setIsNumTrue(true);
    } else {
      let reqPayload;
      if(enableMobileNumber){
        reqPayload = {mobile:mobile, email};
      } else {
        reqPayload = {email};
      }
      dispatch(createSignUpUser(reqPayload, enableOtp, handleErrors, fromEventModal, setInfoPopup));
    }
    // setForSignUp("forSignUp");
  };

  const handleOtpSubmit = (modalVal, otp) => {
    // setIsRecievedOtp(false);
    dispatch(createUserOtpSignUp(otp, refreshState, handleErrors));
    // setShowModal(true);
  };

  const refreshState = () => {
    enqueueSnackbar("Signup successfully", {
      variant: "success",
    });
    if(fromEventModal){
      window.location.reload();
      localStorage.setItem("isJustLoggedIn", 'true');
    }
    else {
      if(isCorp){
        history.push('/corporates');
      }else{
        history.push('/');
      }
      window.location.reload();
    }
    window.scrollTo(0, 0);
  };

  const handleGoBack = () => {
    setShow(false);
    setEmail("");
    setPhoneNumber("")
  };

  useEffect(() => {
    if (
      Object.keys(signUpUserOtp).length !== 0 &&
      signUpUserOtp.constructor === Object
    ) {
      if(isCorp){
        history.push('/corporates');
      }else{
        history.push('/');
      }
      localStorage.setItem("token", signUpUserOtp.data.token);
    }
  }, [signUpUserOtp]);
  const goToCorpLogin = () => {
    history.push('/corporates')
    dispatch(ProfileAction.setScroll(true));
    if(checkoutParams){
      localStorage.setItem("checkoutParams",checkoutParams)
    }
    
   }
  return (
    <>
    <Helmet>
          <title>Sign Up - Hammock Holidays</title>
          <meta name="description" content="Employee Recognition Programs Inspiring Rewards – Inspiring Performance" />
      </Helmet>
    <div className="card-box event-card-box">
    
      {!show ? (
        <form onSubmit={submitHandler}>
          <InputComponent
            type="text"
            name="email"
            placeholder="Your Email id"
            value={email}
            onChange={emailValidate}
            titleValue="Email"
            msg={msg}
            isTrue={isTrue}
            autoComplete={autoComplete}
          />
          {enableMobileNumber&&<InputComponent
            type="phonenumber"
            name="number"
            placeholder="Your phone number"
            value={mobile}
            onChange={numberValidate}
            titleValue="Phone number"
            msg={numMsg}
            isTrue={isNumTrue}
            maxLength="10"
            size="10"
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />}
          <CommonButton>Sign me up</CommonButton>
        </form>
      ) : (
        <OtpComponent 
          onOtpSubmitHandler={handleOtpSubmit} 
          handleGoBack={handleGoBack}
          btnTitle="Sign Up" 
          isSignup={true}
        />
      )}
      <hr />
      <div className="login-success-popup">
        <Dialog
            open={infoPopup}
            onClose={() => setInfoPopup(false)}
        >
            <DialogTitle>
                <div className="success-holder" >
                    <div>
                        <img src={exclamationMark} alt="successful" />
                    </div>
                    <div className="success-message">
                        <p style={{textAlign: "center", fontWeight: 500 }} > Are you trying to sign up as a corporate employee?</p>
                        <div className="confirmation-btn mt-5">
                            <button className="cursor-pointer " style={{fontWeight: 700}} onClick={() => setInfoPopup(false)}>Try different mail id</button>
                            <button className="cursor-pointer corporate" onClick={() => goToCorpLogin()}>Sign up as a corporate</button>
                        </div>
                    </div>

                </div>
            </DialogTitle>
        </Dialog>
        </div>
      <Modal
        showMyModal={showModal}
        modalSubHeadingText={SUCCESSFUL_SIGNUP}
        modalHeadingText={SUCCESSFUL_SIGNUP_WELCOME_MSG}
      />
      <div className="login-footer">
        <SocialLogin fromPopover={fromEventModal} title="Or Signup Using" />
      </div>
    </div>
    </>
  );
};

export default withRouter(SignUp);
