import React, {useEffect, useState, useRef} from 'react';
import OwlCarousel from "react-owl-carousel";
import $ from 'jquery';
import VideoPopup from "../common/videoPopup";
import useClickOutside from '../../hooks/useClickOutside';
import * as commonAction from '../../redux/actions/commonAction';
import { useDispatch, useSelector } from "react-redux";
import leftArrow from "../../assets/images/common/left.svg";
import rightArrow from "../../assets/images/common/right.png";

const EventSlides = React.memo(({eventInfo, fromStories})=>{
    let owl_images = {
        items: 1,
        margin: 0,
        nav: false,
        loop: false,
        rewind:true,
        dots: true,
        autoplay: false, 
        smartSpeed: 500,
        animateIn: 'fadeIn',
        animateOut: 'fadeOut',
        video: true,
        responsive: {
            0:{
                items: 1,
                nav: false,
                margin: 0,
                dots: true,
                rewind:true,
                autoplay: true
            },
            767:{
                items: 1,
                nav: true,
                margin: 10,
                dots: false,
                rewind: false,
                stagePadding: 80,
                navText: [`<img alt='leftArrow' src=${leftArrow} />`, `<img alt='rightArrow' src=${rightArrow} />`],
            },
            992: {
              items: 2,
              nav: true,
              dots: false,
              rewind: false,
              margin: 12,
              stagePadding: 40,
              navText: [`<img alt='leftArrow' src=${leftArrow} />`, `<img alt='rightArrow' src=${rightArrow} />`],
    
            }
        },
        onClick: function(){
            let owl = window.$('.owl-carousel')
            owl.owlCarousel();
            owl.trigger('stop.owl.autoplay');
        },
        onTranslate: function(index){
            $('.owl-item').find('video').each(function() {
                this.pause();
            });
            let player = $('iframe');
            $('.video_mask').width('100%');
            $('.video_mask').height('100%');
            let owl = window.$('.owl-carousel')
            owl.owlCarousel();
            // owl.trigger('play.owl.autoplay');
            
            if(player){
                player.map((index) => {
                    $('iframe')[index].contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
                    $('iframe')[index].contentWindow.postMessage('{"method":"pause","value":"' + 'pauseVideo' + '","args":""}', '*');
                })
            }
            // let player = $('iframe')[2]
            
            // if(player){
            //     player.contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
            // }
        }
    };

    const [showVideoPopup, setShowVideoPopup] = useState(false);
    const [startPosition, setStartPosition] = useState(0);
    const dispatch = useDispatch();
    const popupRef = useRef();
    useClickOutside(popupRef, (e) => {
        setTimeout(() => setShowVideoPopup(false), 100) 
        
    });
    const handleClose = () => {
        setShowVideoPopup(false)
    }
    useEffect(()=>{
        // let owl = window.$('.owl-carousel')
        // owl.owlCarousel();
        // owl.trigger('play.owl.autoplay');
        
    }, [eventInfo]);
    const videoClicked = (vm, type, item)=>{
        let owl = window.$('.owl-carousel')
        owl.owlCarousel();
        owl.trigger('stop.owl.autoplay');
        console.log(window.$('.owl-carousel'));
        window.$('.video_mask').width(0);
        window.$('.video_mask').height(0);
        console.log(window.document.querySelector(`.${vm}`).contentWindow);
        if(type == "youtube" || type == "other"){
            window.document.querySelector(`.${vm}`).contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
        window.document.querySelector(`.${vm}`).contentWindow.postMessage('{"method":"play","value":"' + 'playVideo' + '","args":""}', '*');
        }else if(type == "video"){
            window.document.querySelector(`.${vm}`).play()
        }else{
            return null;
        }
        
    };
    const mobileVideoClicked = (item, index) => {
        dispatch(commonAction.setVideo(item));
        localStorage.setItem("video", JSON.stringify(item));
        setShowVideoPopup(true)
        setStartPosition(index)
        let owl = window.$('.owl-carousel')
        owl.owlCarousel();
        owl.trigger('stop.owl.autoplay');
        // window.$('.mobile_video_mask').width(0);
        // window.$('.mobile_video_mask').height(0);
        
        
    }


    return <div>
         { !fromStories ? 
        <OwlCarousel startPosition={startPosition}  className="owl-theme" {...owl_images}>
        {
            eventInfo?.images?.map((image, index) => {
                if(image.media_type == "image"){
                    return <div className="item">
                                <div className="package-image">
                                    <img
                                        src={image?.image}
                                        className="img-fluid"
                                        alt={`${eventInfo?.title} ${index+1}`}
                                    />
                                </div>
                            </div>
                }else if(image.media_type == "youtube"){
                    return (
                    <div className="package-video">
                    <div className="video_mask" onClick={()=> videoClicked(`manage-video-${index}`, image.media_type, image)}></div>
                    <div className="mobile_video_mask" onClick={()=> mobileVideoClicked(image, index)}></div>
                    <iframe className={`youtube-video manage-video-${index}`}
                    src={`https://www.youtube.com/embed/${new URL(image.video).searchParams.get("v")}?modestbranding=1&enablejsapi=1&version=3&rel=0&playerapiid=ytplayer`}
                    title="YouTube video player" 
                    frameborder="0" 
                    
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowfullscreen="allowfullscreen"
                    
                    >
                    
                    </iframe>
                    </div>)
                }else if(image.media_type == "other" && new URL(image.video).hostname==='vimeo.com'){
                    return (
                        <div className="package-video" >
                            <div className="video_mask" onClick={()=>videoClicked(`manage-video-${index}`, image.media_type, image)}></div>
                            <div className="mobile_video_mask" onClick={()=> mobileVideoClicked(image, index)}></div>
                            <iframe  className={`vimeo-video manage-video-${index}`}
                            src={`https://player.vimeo.com/video${new URL(image.video).pathname}?api=1`} 
                    
                    frameborder="0" 
                    allow="autoplay; fullscreen; picture-in-picture" 
                    allowfullscreen>
            </iframe>
            </div>)
                }
                else if(image.media_type == "video"){
                    return (
                        <div className=" package-video">
                            <div className="video_mask" onClick={()=>videoClicked(`manage-video-${index}`, image.media_type, image)}></div>
                            <div className="mobile_video_mask" onClick={()=> mobileVideoClicked(image, index)}></div>
                                <video className={`video manage-video-${index}`} controls>
                                <source src={`${image.video}`} type="video/mp4" />
                                </video>
                            
                        </div>
                    )
                }else if(!image.media_type
                    ){
                        return <div className="item">
                                <div className="package-image">
                                    <img
                                        src={image?.image}
                                        className="img-fluid"
                                        alt={`${eventInfo?.title} ${index+1}`}
                                    />
                                </div>
                            </div>
                }
                }) 
              
        }
    </OwlCarousel>: 
    <OwlCarousel startPosition={startPosition}  className="owl-theme" {...owl_images}>
    {
        eventInfo?.media?.map((image, index) => {
            if(image.media_type == "image"){
                return <div className="item">
                            <div className="package-image">
                                <img
                                    src={image?.image}
                                    className="img-fluid"
                                    alt={`${eventInfo?.title} ${index+1}`}
                                />
                            </div>
                        </div>
            }else if(image.media_type == "youtube"){
                return (
                <div className="package-video">
                <div className="video_mask" onClick={()=> videoClicked(`manage-video-${index}`, image.media_type, image)}></div>
                <div className="mobile_video_mask" onClick={()=> mobileVideoClicked(image, index)}></div>
                <iframe className={`youtube-video manage-video-${index}`}
                src={`https://www.youtube.com/embed/${new URL(image.video).searchParams.get("v")}?modestbranding=1&enablejsapi=1&version=3&rel=0&playerapiid=ytplayer`}
                title="YouTube video player" 
                frameborder="0" 
                
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen="allowfullscreen"
                
                >
                
                </iframe>
                </div>)
            }else if(image.media_type == "other" && new URL(image.video).hostname==='vimeo.com'){
                return (
                    <div className="package-video" >
                        <div className="video_mask" onClick={()=>videoClicked(`manage-video-${index}`, image.media_type, image)}></div>
                        <div className="mobile_video_mask" onClick={()=> mobileVideoClicked(image, index)}></div>
                        <iframe  className={`vimeo-video manage-video-${index}`}
                        src={`https://player.vimeo.com/video${new URL(image.video).pathname}?api=1`} 
                
                frameborder="0" 
                allow="autoplay; fullscreen; picture-in-picture" 
                allowfullscreen>
        </iframe>
        </div>)
            }
            else if(image.media_type == "video"){
                return (
                    <div className=" package-video">
                        <div className="video_mask" onClick={()=>videoClicked(`manage-video-${index}`, image.media_type, image)}></div>
                        <div className="mobile_video_mask" onClick={()=> mobileVideoClicked(image, index)}></div>
                            <video className={`video manage-video-${index}`} controls>
                            <source src={`${image.video}`} type="video/mp4" />
                            </video>
                        
                    </div>
                )
            }else if(!image.media_type
                ){
                    return <div className="item">
                            <div className="package-image">
                                <img
                                    src={image?.image}
                                    className="img-fluid"
                                    alt={`${eventInfo?.title} ${index+1}`}
                                />
                            </div>
                        </div>
            }
            }) 
          
    }
    </OwlCarousel>}
    {
        showVideoPopup ?
        <VideoPopup popupRef={popupRef} handleClose={handleClose}/> : null
    }
    </div>
})

export default EventSlides
