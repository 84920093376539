import { API, BASE_URL, CC_PAYMENT_KEY, DC_PAYMENT_KEY, TEST_PAYMENT_KEY } from "../../config/api";
import {
  axiosPostWithToken,
  axiosMediaUpload,
  axiosGetWithToken,
  axiosPost,
  axiosGet
} from "../../services/apiServices";
import { ERROR_ALERT } from "./commonAction";
import {ALL_LOCATIONS_LIST} from "../actions/types";

export const EXPERIENCE_CATEGORY = "EXPERIENCE_CATEGORY"
export const INITIAL_EXPERIENCE_CATEGORY_ID = "INITIAL_EXPERIENCE_CATEGORY_ID"
export const CATEGORY_ID = "CATEGORY_ID"
export const EXPERIENCE_CATEGORY_ID = "EXPERIENCE_CATEGORY_ID"
export const EXPERIENCES_LIST="EXPERIENCES_LIST"
export const EXPERIENCE_PACKAGE_DETAILS="EXPERIENCE_PACKAGE_DETAILS"
export const EXPERIENCE_BOOKING_INFORMATION_DATA="EXPERIENCE_BOOKING_INFORMATION_DATA"
export const CHANGE_DATA_FOR_PROCEED_TO_BOOK="CHANGE_DATA_FOR_PROCEED_TO_BOOK"
export const DATA_CHANGED_BOOL_VAL="DATA_CHANGED_BOOL_VAL"
export const BOOKING_STATUS = "BOOKING_STATUS"
export const CHECKOUT_DETAILS="CHECKOUT_DETAILS"
export const EDITED_CORP_EXPERIENCE_NAME="EDITED_CORP_EXPERIENCE_NAME"
export const EDITED_CORP_EXPERIENCE_NAME_BOOL="EDITED_CORP_EXPERIENCE_NAME_BOOL"
export const EDITED_CORP_EXPERIENCE_NUMBER="EDITED_CORP_EXPERIENCE_NUMBER"
export const EDITED_CORP_EXPERIENCE_NUMBER_BOOL="EDITED_CORP_EXPERIENCE_NUMBER_BOOL"
export const SIMILAR_EXPERIENCES="SIMILAR_EXPERIENCES"
export const NEAR_BY_EXPERIENCES="NEAR_BY_EXPERIENCES"
export const MORE_EXPERIENCES="MORE_EXPERIENCES"
export const EXPERIENCES_PACKAGES_LIST="EXPERIENCES_PACKAGES_LIST"
export const RESET_EXPERIENCES_LIST="RESET_EXPERIENCES_LIST"
export const EXP_ROUTE_MESSAGE="EXP_ROUTE_MESSAGE"
export const EXPERIENCE_TESTIMONIALS="EXPERIENCE_TESTIMONIALS"
export const EXPERIENCE_REVIEW_REFERENCE_ID="EXPERIENCE_REVIEW_REFERENCE_ID"
export const EXPERIENCE_REVIEW_POST="EXPERIENCE_REVIEW_POST"
export const EXPERIENCE_REVIEW_LIST="EXPERIENCE_REVIEW_LIST"
export const EXPERIENCE_REVIEWLIST_TOTAL_PAGE="EXPERIENCE_REVIEWLIST_TOTAL_PAGE"
export const EXPERIENCE_REVIEWLIST_CURRENT_PAGE="EXPERIENCE_REVIEWLIST_CURRENT_PAGE"
export const EXPERIENCE_ACTIVE_PACKAGE_INDEX="EXPERIENCE_ACTIVE_PACKAGE_INDEX"
// get experience section's or categories list API
export const getExperienceSections = (boolean, id) => {
    return async (dispatch, getState) => {
      const token = getState().auth.token;
      axiosGetWithToken(`${API.EXPERIENCE_CATEGORY_LIST}`, token).then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: EXPERIENCE_CATEGORY,
            payload: res.data,
          });
         
          // if(boolean){
          //   dispatch({
          //     type: INITIAL_EXPERIENCE_CATEGORY_ID,
          //     payload: id,
          //   });
          // }
          // else {
          //   dispatch({
          //     type: INITIAL_EXPERIENCE_CATEGORY_ID,
          //     payload: "all-experiences",
          //   });
          // }
        }
      })
        .catch((err) => {
          dispatch({
            type: ERROR_ALERT,
            payload:
              (err && err.response && err.response.data) ||
              "Something went wrong.",
          });
        });
    }
  }

  // setting category id in state
export const setCategoryId = (id) => {
    return async (dispatch) => {
      dispatch({
        type: EXPERIENCE_CATEGORY_ID,
        payload: id,
      });
     
    }
  }

  export const setRouteValue = (message) => {
    return async (dispatch) => {
      dispatch({
        type: EXP_ROUTE_MESSAGE,
        payload: message,
      });
    }
  }

  // GET list of all locations
export const getExpLocationsList = (categoryID) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;;
    axiosGet(`${API.EXPERIENCE_LOCATIONS_LIST }?sec_id=${categoryID}`, token).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: ALL_LOCATIONS_LIST,
          payload: res.data,
        });
      }
    })
      .catch((err) => {
       console.log("err",err)
      });
  }
};

// search holiday packages by location
export const searchByLocation = (categoryID, searchInput, minVal, maxVal, page) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;;
    const catId = getState().corporates.cat_id;
    const body = {
      "search_term": searchInput,
      "sec_id": categoryID === "all-experiences" ? null : categoryID,
      "min_val": minVal,
      "max_val": maxVal,
      "next_page_id": page,
      "page_size": 8
    };
    axiosPostWithToken(`${API.SEARCH_EXPERIENCES_BY_LOCATION}`, body, token).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: EXPERIENCES_LIST,
          payload: res.data,
        });
        if(page == 1){
          dispatch({
            type: RESET_EXPERIENCES_LIST,
            payload: res.data.data.listing.results,
          });
        }else{
          dispatch({
            type: EXPERIENCES_PACKAGES_LIST,
            payload: res.data.data.listing.results,
          });
        }
      }
    })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  }
}

  // POST API list of experience packages 
export const getExperiencePackages = (id, page) => {
    return async (dispatch, getState) => {
      const catId = getState().experiences?.cat_id;
      const body = {
        "sec_id": id === "all-experiences" ? "" : id,
        "next_page_id": page,
        "page_size":8
      }
      axiosPost(`${API.EXPERIENCES_PACKAGES_LIST}`, body).then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: EXPERIENCES_LIST,
            payload: res.data,
          });
          if(page == 1){
            dispatch({
              type: RESET_EXPERIENCES_LIST,
              payload: res.data.data.listing.results,
            })
          }else{
            
            dispatch({
              type: EXPERIENCES_PACKAGES_LIST,
              payload: res.data.data.listing.results,
            });
          }
        }
      })
        .catch((err) => {
          dispatch({
            type: ERROR_ALERT,
            payload:
              (err && err.response && err.response.data) ||
              "Something went wrong.",
          });
        })
  
    }
  }

  // GET API to get the details of individual experience
export const getExperienceDetails = (id, loader) => {
  return async (dispatch, getState) => {
    axiosGet(`${API.EXPERIENCE_PACKAGE_DETAILS_API + id}/`).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: EXPERIENCE_PACKAGE_DETAILS,
          payload: res.data,
        });
        localStorage.setItem("packInfo", JSON.stringify(res?.data))
        loader();
      }
    })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
        loader()
      });
  }
}
export const getFilteredExperienceDetails = (id, loader) => {
  return async (dispatch, getState) => {
    axiosGet(`${API.EXPERIENCE_FILTERED_PACKAGE_DETAILS_API + id}/`).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: EXPERIENCE_PACKAGE_DETAILS,
          payload: res.data,
        });
        localStorage.setItem("packInfo", JSON.stringify(res?.data))
        loader();
      }
    })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
        loader()
      });
  }
}
// GET API to get the testimonial of individual events
export const getTestimonials = (id) => {
  return async (dispatch, getState) => {
    axiosGet(`${API.EXPERIENCE_TESTIMONIALS + id}/`).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: EXPERIENCE_TESTIMONIALS,
          payload: res.data,
        });
      }
    })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  }
}

export const getNearByExperienceDetails = (id, loader) => {
  return async (dispatch, getState) => {
    axiosGet(`${API.EXPERIENCE_NEAR_BY_PACKAGE_DETAILS_API + id}/`).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: EXPERIENCE_PACKAGE_DETAILS,
          payload: res.data,
        });
        localStorage.setItem("packInfo", JSON.stringify(res?.data))
        loader();
      }
    })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
        loader()
      });
  }
}

// on click of proceed to book dispatching data to EXPERIENCE_BOOKING_INFORMATION_DATA
export const handleProceedToBook = (data) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const fromDate = getState().corporates.pacStartDate;
    const toDate = getState().corporates.pacEndDate;
    
    const body = {
      "pack_id": data.pack_id,
      "schedule_data": data?.resAPISelectedDates?.length > 0 ? data?.resAPISelectedDates : {
          "from": data?.resAPIStartDate,
          "to": data?.resAPIEndDate
      },
      "adults": data.adultGuestCount,
      "children": data.childrensGuestCount,
      "infants": data.count,
      "special_request": data.request,
      "total_price": data.totalPrice,
      "amount": (Number(data.points).toFixed(2)),
      "points": data.points,
      "contact_name": data.name,
      "contact_email": data.email,
      "contact_number": data.contactNumber,
      "schedule_type": data?.schedule_type

    };
    localStorage.setItem("bookData", JSON.stringify(body));
    dispatch({
      type: EXPERIENCE_BOOKING_INFORMATION_DATA,
      payload: body
    })
  }
}
export const updateBookingData = (data) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const fromDate = getState().corporates.pacStartDate;
    const toDate = getState().corporates.pacEndDate;
    const flexibleDates = getState().corporates.pacFlexibleDates;
    const booking_information_data = getState().experiences.booking_information_data
    const body = {
      ...booking_information_data,
      "schedule_data":  data?.resAPISelectedDates?.length > 0 ? data?.resAPISelectedDates :{
        "from": data?.resAPIStartDate,
        "to": data?.resAPIEndDate
      },
      "adults": data?.adults,
      "children": data?.children,
      "infants": data?.infants,
      "contact_name": data?.contact_name,
      "contact_email": data?.contact_email,
      "contact_number": data?.contact_number,
      "special_request": data?.special_request,
    };
    localStorage.setItem("bookData", JSON.stringify(body));
    dispatch({
      type: EXPERIENCE_BOOKING_INFORMATION_DATA,
      payload: body
    })
  }
}

export const changeDataforProceedToBook = (dataChanged, dataChangedBool) => {
  return async (dispatch, getState) => {
    dispatch({
      type: CHANGE_DATA_FOR_PROCEED_TO_BOOK,
      payload: dataChanged
    })
    dispatch({
      type: DATA_CHANGED_BOOL_VAL,
      payload: dataChangedBool
    })
    localStorage.setItem("dataChangedBool", JSON.stringify(dataChangedBool));
    localStorage.setItem("dataChanged", JSON.stringify(dataChanged));
  }
}
// Review Post Check
export const ReviewPostCheck = (id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      pack_id: id,
    };
    axiosPostWithToken(API.EXPERIENCE_REVIEW_POST_CHECK, body, token)
      .then((res) => {
        dispatch({
          type: EXPERIENCE_REVIEW_REFERENCE_ID,
          payload: res?.data?.data.map(e=>e.ref_id).filter(f=>f!=null),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const reviewListing = (id,page) => {
  return async (dispatch, getState) => {
    const body = {
      next_page_id: page,
      page_size: 5,
    };
    axiosPost(`${API.EXPERIENCE_REVIEW_LIST + id}/`, body)
      .then((res) => {
        
        dispatch({
          type: EXPERIENCE_REVIEW_LIST,
          payload: res?.data?.data.review_data,
        });
        dispatch({
          type:EXPERIENCE_REVIEWLIST_TOTAL_PAGE,
          payload:res?.data?.data.review_data.total_pages
        })
        dispatch({
          type:EXPERIENCE_REVIEWLIST_CURRENT_PAGE,
          payload:res?.data?.data.review_data.current_page
        })
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const reviewStatusClearUp = () => {
  return async (dispatch) => {
    dispatch({
      type: EXPERIENCE_REVIEW_POST,
      payload: null,
    });
  };
};
export const changePackageIndex = (index) => {
  return async (dispatch) => {
    dispatch({
      type: EXPERIENCE_ACTIVE_PACKAGE_INDEX,
      payload: index,
    });
  };
};

export const postReview = (pkgid, cmt, str, refid) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const body = {
      pack_id: pkgid,
      review_star: str,
      review_comment: cmt,
      ref_id: refid,
    };
    axiosPostWithToken(API.EXPERIENCE_REVIEW_POST, body, token)
      .then((res) => {
        
        dispatch({
          type: EXPERIENCE_REVIEW_POST,
          payload: res?.data?.data.message,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
// checkout function
// CONDITION 1: If points are greater then amount pay with points and amount will be equal to zero.
// CONDITION 2: Else pay with amount redirect's to bank payment screen (can also use points and can pay balance amount)
export const checkOut = (data, corporate, paidWithPoints, payAmount, isSpecific) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const fromDate = getState().corporates.pacStartDate;
    const toDate = getState().corporates.pacEndDate;
    const flexibleDates = getState().corporates.pacFlexibleDates;
    const isCorporateAccount = corporate
    
    const body = {
      "pack_id": data?.pack_id,
      "schedule": data?.resAPISelectedDates?.length > 0 ? data?.resAPISelectedDates :{
        "from": data?.resAPIStartDate,
        "to": (data?.schedule_type === "specific_dates" || data?.schedule_type === "recurring") ? "" : data?.resAPIEndDate
      },
      "adults": data?.adults,
      "children": data?.children,
      "infants": data?.infants,
      "special_request": data?.special_request,
      "total_points": data?.total_points,
      "amount": data?.amount,
      "contact_name": data?.contact_name,
      "contact_email": data?.contact_email,
      "contact_number": data?.contact_number,
      "pay_mode" : data?.amount > 0 ? data?.pay_mode : "",
    };

    if (data?.amount === 0) {
      axiosPostWithToken(`${API.EXPERIENCE_BOOKING_WITH_POINTS}`, body, token).then((res) => {
        if (res.data.status_code === 200) {
          if (res.data.data.serializer.booking_status === "booked") {
            dispatch({
              type: BOOKING_STATUS,
              payload: res.data,
            });
            paidWithPoints();
          }
        }
      })
        .catch((err) => {
          dispatch({
            type: ERROR_ALERT,
            payload:
              (err && err.response && err.response.data) ||
              "Something went wrong.",
          });
        })
    }
    else if(isCorporateAccount === true){
      axiosPostWithToken(`${API.EXPERIENCE_CORPORATE_PAYMENT}`, body, token).then((res) => {
        if (res.data.status_code === 200) {
          const resBody = res.data.data;
          const paymentBody = {
            surl: `${BASE_URL}/user/payment/experience-corp-success/`,
            curl: `${BASE_URL}/user/payment/cancelled/`,
            furl: `${BASE_URL}/user/payment/experience-corp-failure/`,
            // key: res?.data?.data?.pay_mode == "CC" ? `${CC_PAYMENT_KEY}` : `${DC_PAYMENT_KEY}`, // live server payment's key
            key: `${TEST_PAYMENT_KEY}`, // test server payment's key
            phone: data?.contact_number,
          }
          const payment_details = { ...resBody, ...paymentBody }
          const checkout_details = {...body, ...payment_details}
          dispatch({
            type: CHECKOUT_DETAILS,
            payload: payment_details,
          });
          payAmount(checkout_details);
        }
      })
        .catch((err) => {
          dispatch({
            type: ERROR_ALERT,
            payload:
              (err && err.response && err.response.data) ||
              "Something went wrong.",
          });
        });
    }
    else if(isCorporateAccount === false){
      axiosPostWithToken(`${API.EXPERIENCE_NON_CORPORATE_PAYMENT}`, body, token).then((res) => {
        if (res.data.status_code === 200) {
          const resBody = res.data.data;
          const paymentBody = {
            surl: `${BASE_URL}/user/payment/experience-user-success/`,
            curl: `${BASE_URL}/user/payment/cancelled/`,
            furl: `${BASE_URL}/user/payment/experience-user-failure/`,
            // key: res?.data?.data?.pay_mode == "CC" ? `${CC_PAYMENT_KEY}` : `${DC_PAYMENT_KEY}`, // live server payment's key
            key: `${TEST_PAYMENT_KEY}`, // test server payment's key
            phone: data?.contact_number,
          }
          const payment_details = { ...resBody, ...paymentBody }; 
          const checkout_details = {...body, ...payment_details}
          dispatch({
            type: CHECKOUT_DETAILS,
            payload: payment_details,
          });
          payAmount(checkout_details);
        }
        
      })
        .catch((err) => {
          dispatch({
            type: ERROR_ALERT,
            payload:
              (err && err.response && err.response.data) ||
              "Something went wrong.",
          });
        });
    }
    return
  }
};

export const availabilityRequest = (data, corporate, handleSuccess, payAmount, isSpecific) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const fromDate = getState().corporates.pacStartDate;
    const toDate = getState().corporates.pacEndDate;
    const flexibleDates = getState().corporates.pacFlexibleDates;
    const isCorporateAccount = corporate
    console.log("dta")
    const body = {
      "pack_id": data?.pack_id,
      "schedule": data?.resAPISelectedDates?.length > 0 ? data?.resAPISelectedDates :{
        "from": data?.resAPIStartDate,
        "to": (data?.schedule_type === "specific_dates" || data?.schedule_type === "recurring") ? "" : data?.resAPIEndDate
      },
      "adults": data?.adults,
      "children": data?.children,
      "infants": data?.infants,
      "special_request": data?.special_request,
      "contact_name": data?.contact_name,
      "contact_email": data?.contact_email,
      "contact_number": data?.contact_number,
      "amount": data?.amount
    };
    if(isCorporateAccount){
    axiosPostWithToken(`${API.EXPERIENCE_AVAILABLILITY_REQUEST_CORP}`, body, token).then((res) => {
      if (res.data.status_code === 200) {
        if (res.data.data.serializer.booking_status === "requested") {
          handleSuccess();
        }
      }
    })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      })
    }else{
      axiosPostWithToken(`${API.EXPERIENCE_AVAILABLILITY_REQUEST_NON_CORP}`, body, token).then((res) => {
        if (res.data.status_code === 200) {
          if (res.data.data.serializer.booking_status === "requested") {
            handleSuccess();
          }
        }
      })
        .catch((err) => {
          dispatch({
            type: ERROR_ALERT,
            payload:
              (err && err.response && err.response.data) ||
              "Something went wrong.",
          });
        })
    }

  }
}

export const corpExperienceEditedName = (name, bool) => {
  return async (dispatch, getState) => {
    dispatch({
      type: EDITED_CORP_EXPERIENCE_NAME,
      payload: name
    });
    dispatch({
      type: EDITED_CORP_EXPERIENCE_NAME_BOOL,
      payload: bool
    });
  }
};

export const corpExperienceEditedNumber = (number, bool) => {
  return async(dispatch) => {
    dispatch({
      type: EDITED_CORP_EXPERIENCE_NUMBER,
      payload: number
    });
    dispatch({
      type: EDITED_CORP_EXPERIENCE_NUMBER_BOOL,
      payload: bool
    });
  }
};

// POST API to get similar experiences
export const getSimilarExperiences = (id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const packid = {
      pack_id: id
    };
    axiosPostWithToken(`${API.SIMILAR_PACKAGE_EXPERIENCES}`, packid, token).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: SIMILAR_EXPERIENCES,
          payload: res.data,
        });
      }
    })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  }
}

// Get API to get nearby experiences
export const getNearByExperiences = (id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const packid = {
      pack_id: id
    };
    axiosGetWithToken(`${API.NEARBY_PACKAGE_EXPERIENCES + id}/`, token).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: NEAR_BY_EXPERIENCES,
          payload: res.data,
        });
      }
    })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  }
};
// Get API to get more experiences
export const getMoreExperiences = (id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const packid = {
      pack_id: id
    };
    axiosGetWithToken(`${API.MORE_PACKAGE_EXPERIENCES + id}/?more=true`, token).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: MORE_EXPERIENCES,
          payload: res.data,
        });
      }
    })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  }
};

