import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import GoogleIcon from "../../assets/icons/authIcons/Google.svg";
import { auth } from "../../firebase/firebase";
import firebase from "firebase";
import "firebase/firestore";
import "firebase/auth";
import "firebase/database";
import {
  loginWithGoogle,
} from "../../redux/actions/authAction";
import { useDispatch, useSelector } from "react-redux";
import CommonButton from "./authCommonButton";
import { useSnackbar } from "notistack";

const SocialLogin = (props) => {
  const {fromPopover}=props;
  const { enqueueSnackbar } = useSnackbar();
  const [userProviderData, setUserProviderData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const goggleLogin = useSelector((state) => state.auth.login_with_google);
  const goggleError = useSelector((state) => state.auth.google_error_message);

  const provider = new firebase.auth.GoogleAuthProvider();
  provider.setCustomParameters({ prompt: "select_account" });

  const facebookProvider = new firebase.auth.FacebookAuthProvider();

  const signInWithGoogle = () => {
    auth.signInWithPopup(provider).then((response) => {
      dispatch(loginWithGoogle(response, handleErrors));
      if(!fromPopover){
        props.history.push('/')
        window.scrollTo(0,0);
      }
      window.scrollTo(0,0);
    });
  };

  const handleErrors = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
    });
  };

  useEffect(() => {
    if(goggleError.status_code === 400){
        setShowModal(true)
    }else if(goggleLogin.status_code === 200){
      if(!fromPopover){
        props.history.push('/')
        window.scrollTo(0,0);
      }
      localStorage.setItem("isJustLoggedIn", 'true');
      localStorage.setItem('goggleLogin', JSON.stringify(goggleLogin.data))
      window.scrollTo(0,0);
    }
  }, [goggleError, goggleLogin]);
  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <>
      <h5>{props.title}</h5>
      <div className="login-footer-icon">
        <div className="social-icon-cont">
          <img src={GoogleIcon} alt="google" onClick={signInWithGoogle} />
        </div>
      </div>
      {showModal && (
        <div className="modal-wrapper ">
          <div className="overlay-modal"></div>
          <div className="card-box card-box-index">
            <p>{`${goggleError.data.message} login with valid email`}</p>
            <CommonButton children="Ok" onClick={closeModal} />
          </div>
        </div>
      )}
    </>
  );
};

export default withRouter(SocialLogin);
