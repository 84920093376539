import moment from "moment";

export const  calculateDaysFromDateRange = (packageInfo) => {
    const arr = [];
    packageInfo?.schedule_data?.selectedDates?.length > 0
    && packageInfo?.schedule_data?.selectedDates?.map(date => {
        const startDate = moment(`${date.to}`, 'DD-MM-YYYY');
        const endDate = moment(`${date.from}`, 'DD-MM-YYYY');
        const result = endDate.diff(startDate, 'days');
        arr.push(Math.round(Math.abs(result) + 1 ));
    });
    
    const min = Math.min(...arr);
    const max = Math.max(...arr);
   
    if(min !== max){
        return <span>Min {min} {min == 1 ? 'day' : 'days'}, Max {max} {max == 1 ? 'day' : 'days'}</span>
    }else{
        return<span> Max {max} {min == 1 ? 'day' : 'days'}</span>
    }
    
}