import React, {useEffect} from 'react'
import OwlCarousel from "react-owl-carousel";
import { useDispatch, useSelector } from "react-redux";
import { corporate_options } from '../../resources/carouselOptions';
import { useHistory } from "react-router-dom";
import * as ExperienceAction from '../../redux/actions/experiencesAction';
import * as HolidaysAction from '../../redux/actions/holidaysActions';


function StaysNearByExperiences({similarPackages, similarPackageEmptyData,startLoader,experienceID}) {
    const dispatch = useDispatch();
    const history = useHistory();
    const SIMILAR_EXPERIENCES = useSelector((state) => state.experiences.near_by_experiences.data);
    const id = similarPackages;
    const userInfo = JSON.parse(localStorage.getItem("userData"));
    const isCorporateAccount = userInfo?.user_details?.corporate_account;
    const companyDomain = userInfo?.organisation?.email_domain;
    const isWipro = (companyDomain === "wipro.com");
    useEffect(() => {
        if(id){
        dispatch(ExperienceAction.getNearByExperiences(id));
        }
    }, [id]);

    const refreshSimilarPackages = () => {
        dispatch(ExperienceAction.getNearByExperiences(id));
    }
    const handleExperienceDetails = (id,pkgId) => {
        history.push({pathname:`/experiences/experience-details/${id}`,state:{id:pkgId}});
      if(id != experienceID){
        startLoader()
      }
        similarPackageEmptyData()
        window.scrollTo(0, 0);
      };
      
    return (
        <div>
        {
            SIMILAR_EXPERIENCES && SIMILAR_EXPERIENCES.length > 0 ?  <div className="container">
            <div className="nearby-experiences similar-offers stays-nearby">
                <h5><span>Recommendations</span> for you</h5>
                <div className="divider"></div>
                <OwlCarousel className="owl-theme" {...corporate_options}>
                {
                    SIMILAR_EXPERIENCES && SIMILAR_EXPERIENCES.length > 0 && SIMILAR_EXPERIENCES.map((items) => (
                        <div className="item">
                            <div className="card-wrapper d-lg-flex flex-column" style={{cursor: 'pointer'}} onClick={() => handleExperienceDetails(items.experience_slug,items.id)}>
                                <img src={items.image} className="w-100" alt={items.pack_title} />
                                <div className="info">
                                   <p>{items.pack_title}</p>
                                   {/* <div className="desc">
                                        <p>{items.brief_description}</p>
                                   </div> */}
                                   {
                                    (isCorporateAccount && isWipro) ? <p className="points">{(items.revised_price?items.revised_price:items.price).toLocaleString('en-IN')} pts</p> : 
                                     <p className="points"> Rs {(items.revised_price?items.revised_price:items.price).toLocaleString('en-IN')}</p>
                                   }

                                </div>
                            </div>
                        </div>
                    ))
                }
                </OwlCarousel>
            </div>
        </div> : null
        }
        </div>
    )
}

export default StaysNearByExperiences
