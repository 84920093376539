import { API } from "../../config/api";
import {
    axiosGet, axiosPostWithToken, axiosPost
} from "../../services/apiServices"
import { WEDDING_STORIES_LIST, WEDDING_DESTINATIONS } from "./types";
export const ERROR_ALERT = "ERROR_ALERT";

export const getStoriesList = () => {
    
    return async (dispatch) => {
        const body = {
            "page": 1, 
            "list_per_page": 15
        }
        axiosPost(API.WEDDING_STORIES_LIST_API,body)
            .then((res) => {
                if (res.data.status_code === 200) {
                    dispatch({
                        type: WEDDING_STORIES_LIST,
                        payload: res.data.data
                    })
                }
            })
            .catch((err) => {
                dispatch({
                    type: ERROR_ALERT,
                    payload:
                      (err && err.response && err.response.data) ||
                      "Something went wrong.",
                  });
            });
    };
};

export const getDestinationsList = () =>{
    return async (dispatch) => {
        const body = {
    
            "sort":{"field":"priority", "order":"A"},
             "page": 1, 
             "list_per_page": 15
             
        }
        axiosPost(API.WEDDING_DESTINATIONS_LIST_API,body)
        .then((res)=>{
            if(res.data.status_code === 200){
                dispatch({
                    type: WEDDING_DESTINATIONS,
                    payload: res.data.data.results
                })
            }
        })
        .catch((err) =>{
            dispatch({
                type: ERROR_ALERT,
                payload:
                  (err && err.response && err.response.data) ||
                  "Something went wrong.",
              });
        })
    }
}

export const bookWeddings = (body, handleSuccess, handleErrors) =>{
    return async () => {
        axiosPost(API.BOOK_WEDDINGS_API, body)
        .then((res) => {
            if (res.data.status_code === 200) {
                handleSuccess();
            }
            if (res.data.status_code === 400) {
                handleErrors(res.data.data.message)
            }
        })
        .catch((err) => {
            handleErrors(err.response.data?.data?.message);
        });
    }
}