
import packageDetails1 from '../assets/images/events/package1.png';
import packageDetails2 from '../assets/images/events/package2.png';
import packageDetails3 from '../assets/images/events/package3.png';
import packageDetails4 from '../assets/images/events/package4.png';


import event1 from '../assets/images/events/virtual-event.png';
import event2 from '../assets/images/events/perso-events.png';
import testimonial1 from '../assets/images/events/1.png'
import testimonial2 from '../assets/images/events/2.png'

import image1 from '../assets/images/home/s1.png'
import image2 from '../assets/images/home/s2.png'
import image3 from '../assets/images/home/s3.png'


export const EVENTS_LIST = [
    {
        name: 'Virtual Event',
        image: `${event1}`,
    },
    // {
    //     name: 'Person Event',
    //     image: `${event2}`,
    // }
]



export const EVENTS_PACKAGE_DETAILS = [
    {
        title: 'Wild Art Workshop for Kids, Teens & Young Adults by Shaaz Jung',
        highlight: 'online workshop',
        image: [`${packageDetails1}`],
        stream: 'Zoom event live',
        schedule_date: '22nd May . 23rd May 2021',
        brief_description: 'Paddington Resorts & Spa – Resort in Coorg, Madikeri A luxury offering near the banks of Mother Cauvery, abodes those subtleties of nature which the heart longs for. Nestled in the midst of a dense jungle, this is a place where spirits remain untainted and wilted life springs up again...',
        price: '1,100',
    },
    {
        title: `Usha Uthup's Original Take On The Night Club Era | Paytm Insider presents Jim Beam Originals`,
        highlight: 'online event',
        image: [`${packageDetails2}`],
        stream: 'Zoom event live',
        schedule_date: '22nd May . 23rd May 2021',
        brief_description: 'For over 50 years now Uthup has spread a message of love and unity, peace and harmony, tolerance and integrity, and happiness - through music. From discotheques to concerts across India and the World, she has addressed the youth about the value...',
        price: '1,100',
    },
    {
        title: 'Exploring Tagore',
        highlight: 'online workshop',
        image: [`${packageDetails3}`],
        stream: 'Zoom event ',
        schedule_date: 'Every Mondays .  2 hrs',
        brief_description: 'Join us as we explore and collectively reflect upon the proses, poems and stories by Rabindranath Tagore. These collective reflections are an attempt to shed light on our day to day life, issues, challenges, through the words of Saints, Masters and Mystics....',
        price: '1,100',
    },
    {
        title: 'Comedy Club from Dehradun Open Mic ONLINE EVENT',
        highlight: 'online event',
        image: [`${packageDetails4}`, `${packageDetails4}`],
        stream: 'Zoom event ',
        schedule_date: 'Every Mondays .  2 hrs',
        brief_description: 'Comedy Club Dehradun is now on Zoom! We are kicking off our open mics with some of our funniest comics from Dehradun and beyond. And for the piece de resistance, we have a very special guest comic for this mic: The winner of SRCP season 2, Fatima Ayesha!',
        price: '1,100',
    },


]

export const TESTIMONIALS = [
    {
        name: "Vijay",
        image: `${testimonial1}`,
        date: "October 2021",
        content: "Very convenient, super service and centrally located. Well kitted out, comfy bed, huge bathroom. Just a small note on improving ventilation. Very convenient, super service and centrally located. Well kitted out, comfy bed, huge bathroom. Just a small note on improving ventilation"
    },
    {
        name: "Goutam Sethi",
        image: `${testimonial2}`,
        date: "October 2021",
        content: "Very convenient, super service and centrally located. Well kitted out, comfy bed, huge bathroom. Just a small note on improving ventilation and natural light, which the host"
    },
    {
        name: "Vivek Patil",
        image: `${testimonial2}`,
        date: "October 2021",
        content: "Very convenient, super service and centrally located. Well kitted out, comfy bed, huge bathroom. Just a small note on improving ventilation and natural light, which the host"
    },
    {
        name: "Aashtha Singh",
        image: `${testimonial1}`,
        date: "October 2021",
        content: "Very convenient, super service and centrally located. Well kitted out, comfy bed, huge bathroom. Just a small note on improving ventilation and natural light, which the host"
    },
    {
        name: "Saloni Vyash",
        image: `${testimonial1}`,
        date: "October 2021",
        content: "Very convenient, super service and centrally located. Well kitted out, comfy bed, huge bathroom. Just a small note on improving ventilation and natural light, which the host"
    },
    {
        name: "Raghu",
        image: `${testimonial2}`,
        date: "October 2021",
        content: "Very convenient, super service and centrally located. Well kitted out, comfy bed, huge bathroom. Just a small note on improving ventilation and natural light, which the host"
    },
]

export const SECTIONS = [
    {
        id: "1",
        name: "Experiences",
        image: `${image1}`
    },
    {
        id: "2",
        name: "Holidays",
        image: `${image2}`
    },
] 