import { CORPORATE_HOMEPAGE_LIST, EXPERIENCE_HOMEPAGE_LIST, HOLIDAY_HOMEPAGE_LIST, SET_SCROLL_CORPORATES, SET_SCROLL_VIX, SEARCH_SUGGESTIONS, SET_GLOBAL_SEARCH_VAL, GLOBAL_SEARCH_SUGGESTIONS } from '../actions/types'
const initialState = {
    corporateList: [],
    experienceList: [],
    holidayList: [],
    set_Scroll: false,
    set_Scroll_vix: false,
    search_suggestions_list: [],
    global_search_val: localStorage.getItem("globalSearchVal") ? localStorage.getItem("globalSearchVal") : null,
    global_search_results: localStorage.getItem("searchResults") ? JSON.parse(localStorage.getItem("searchResults")) : null
}
const homepageReducers = (state = initialState, action) => {
    switch (action.type) {
        case CORPORATE_HOMEPAGE_LIST:
            return {
                ...state,
                corporateList: action.payload
            }
        case EXPERIENCE_HOMEPAGE_LIST:
            return {
                ...state,
                experienceList: action.payload
            }
        case HOLIDAY_HOMEPAGE_LIST:
            return {
                ...state,
                holidayList: action.payload
            }
        case SET_SCROLL_CORPORATES:
            return {
                ...state,
                set_Scroll: action.payload
            }
        case SET_SCROLL_VIX:
            return {
                ...state,
                set_Scroll_vix: action.payload
            }
        case SEARCH_SUGGESTIONS:
            return {
                ...state,
                search_suggestions_list: action.payload
            }
        case GLOBAL_SEARCH_SUGGESTIONS:
            return {
                ...state,
                global_search_results: action.payload
            }
        case SET_GLOBAL_SEARCH_VAL:
            return {
                ...state,
                global_search_val: action.payload
            }
        default:
            return state
    }

}


export default homepageReducers