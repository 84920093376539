import React, { useState, useEffect } from 'react'
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import CloseIcon from '../../../assets/images/common/close-modal.svg';
import * as ProfileAction from '../../../redux/actions/profileAction';

const OtpModal = ({showModal, handleClose, handleOtpComponent}) => {
  const [otp, setOtp] = useState("");
  const [isOtpError, setIsOtpError] = useState(null)
  const [initialMinute, setInitialMinute] = useState(1);
  const [initialSeconds, setInitialSeconds] = useState(0);
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);

  const userInfo = JSON.parse(localStorage.getItem("userData"));
  const isCorporateAccount = userInfo?.user_details?.corporate_account;
  const updatedMobNum = useSelector(state => state.userProfile.updatedMobileNum);
  const updateUserDetailMessage = useSelector(state => state.userProfile.updateUserDetailsMessage);
  const numExist = useSelector(state => state.userProfile.numberAlreadyExist);
  const { enqueueSnackbar } = useSnackbar();

   const dispatch = useDispatch();
  
    const confirmHandler = () => {
      //  handleOtpComponent(false)
        setIsOtpError(false)
        if (otp === "") {
            setIsOtpError(true)
        } 
        else if(isCorporateAccount === true){
            dispatch(ProfileAction.corpUserOtpVerify(otp,  handleSuccess, handleErrors))
             handleClose();
            localStorage.setItem("mobile",updatedMobNum);
        }else{
          dispatch(ProfileAction.nonCorpUserOtpVerify(otp,  handleSuccess, handleErrors))
          handleClose();
          localStorage.setItem("mobile",updatedMobNum);
        }
    }
    
    const handleSuccess = () => {
      enqueueSnackbar("Phone number updated successfully", {
        variant: "success",
      });
      refreshProfileDetails();
    };

    const handleErrors = (message) => {
      enqueueSnackbar(message, {
          variant: "error",
      });
    };

    const handleChange = (e) => {
        setOtp(e.target.value)
    };

    const otpHandler = (e) => {
      e.preventDefault();
    };
    
      const resendOtpTimer = () => {
        setMinutes(1);
        setSeconds(0);
        let myInterval = setTimeout(() => {
          if (seconds > 0) {
            setSeconds(seconds - 1);
          }
          if (seconds === 0) {
            if (minutes === 0) {
              clearInterval(myInterval);
            } else {
              setMinutes(minutes - 1);
              setSeconds(59);
            }
          }
        }, 1000);
        return () => {
          clearInterval(myInterval);
        };
      };
    
      useEffect(() => {
        let myInterval = setInterval(() => {
          if (seconds > 0) {
            setSeconds(seconds - 1);
          }
          if (seconds === 0) {
            if (minutes === 0) {
              clearInterval(myInterval);
            } else {
              setMinutes(minutes - 1);
              setSeconds(59);
            }
          }
        }, 1000);
        return () => {
          clearInterval(myInterval);
        };
      });

      const refreshProfileDetails = () => {
        if(isCorporateAccount){
          dispatch(ProfileAction.getProfileDetails());
        }
        else {
          dispatch(ProfileAction.getNonCorporateProfileDetails())
        }
    };
    
    const otpResendHandler = () => {
       resendOtpTimer();
       if(isCorporateAccount) {
        dispatch(ProfileAction.updateUserPhoneNumber(updatedMobNum));
       }
       else {
        dispatch(ProfileAction.updateNonCorpUserPhoneNumber(updatedMobNum));
      }
    }

    return (
    <div>
        <Modal className="custom-modal" show={showModal} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>Verifying Contact</Modal.Title>
                <div className="cursor-pointer" onClick={handleClose}>
                    <img src={CloseIcon} alt="" />
                </div>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={otpHandler}>
                    {minutes === 0 && seconds === 0 ? (
                        ""
                        ) : (
                        <span className="otp-timer" 
                          style=
                            {{textAlign:"left",
                            marginLeft:"10px"}}
                           >
                            {" "}
                            <span >
                            {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                            </span>{" "}
                            Seconds Left
                        </span>
                    )}
                    <span style={{margin:"8px 0px", display:"block"}}>{ updateUserDetailMessage?.data !== undefined ? updateUserDetailMessage?.data?.message : ''}</span>
                    <div class="form-group custom-input">
                        <input type="text" className="form-control" 
                        placeholder="Enter recieved OTP here"
                        value={otp}
                        name="otp" onChange={handleChange} onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }} />
                        {minutes <  1 && seconds >= 0 
                            ?  <a onClick={otpResendHandler}  className="resend-otp cursor-pointer" style={{opacity:`${minutes === 0 && seconds === 0?"":".2"}`, cursor:`${minutes === 0 && seconds === 0?"pointer": "none"}`}}>Resend OTP</a>
                            : <a className="resend-otp" style={{ opacity: 0.6, cursor:"auto"}}>Resend OTP</a>
                        }
                        {isOtpError && <p style={{color:"red"}}>Please enter recieved otp</p>}
                    </div>
                    <div className="text-right mt-3" style={{display:"flex", justifyContent:"center"}}>
                        <Button className="btn btn-done" onClick={confirmHandler}>
                          Confirm this number </Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    </div>
    )
}


export default OtpModal;
