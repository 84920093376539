import client1 from '../assets/images/corporates/clients/wipro.svg';
import client2 from '../assets/images/corporates/clients/cgi.svg';
import client3 from '../assets/images/corporates/clients/texas.svg';
import client4 from '../assets/images/corporates/clients/csg.svg';
import appiness from '../assets/images/corporates/clients/appiness.svg';

import client5 from '../assets/images/corporates/clients/star.svg';
import client6 from '../assets/images/corporates/clients/volvo.svg';
import client7 from '../assets/images/corporates/clients/ibm.svg';
import client8 from '../assets/images/corporates/clients/hp.svg';
import client9 from '../assets/images/corporates/clients/tech-mahindra.svg';
import ignitarium from '../assets/images/corporates/clients/ignitarium.svg';
import qualitest from '../assets/images/corporates/clients/qualitest.svg';


export const CLIENTS_LIST1 = [
    {
        name: "Wipro",
        image: `${client1}`
    },
    {
        name: "CGI",
        image: `${client2}`
    },
    {
        name: "Texas Instruments",
        image: `${client3}`
    },
    {
        name: "CSG",
        image: `${client4}`
    },
]

export const CLIENTS_LIST2 = [
    {
        name: "Tech mahindra",
        image: `${client9}`
    },
    {
        name: "Ignitarium",
        image: `${ignitarium}`
    },
    {
        name: "Qualitest",
        image: `${qualitest}`
    },
    {
        name: "Appiness",
        image: `${appiness}`
    }
]
export const CLIENTS_LIST3 = [

    {
        name: "Volvo",
        image: `${client6}`
    },
    {
        name: "IBM",
        image: `${client7}`
    },
    {
        name: "HP",
        image: `${client8}`
    },
    {
        name: "Star Health",
        image: `${client5}`
    }

]