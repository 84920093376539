import React, {useState, useEffect, useRef} from 'react';
import { Link, withRouter, useHistory, useLocation } from "react-router-dom";
import userIcon from '../../assets/icons/feather_user.svg'
import homeIcon from '../../assets/icons/feather_home.svg'
import closeIcon from '../../assets/icons/feather_close.svg'
import hamLogo from '../../assets/images/common/ham-logo.svg'
import hamNavIcon from '../../assets/icons/feather_ham.svg'
import { slide as Menu } from 'react-burger-menu'
import * as ProfileAction from "../../redux/actions/profileAction";
import * as CorporateAction from "../../redux/actions/corporatesAuthActions";
import { useDispatch, useSelector } from "react-redux";

const profileImageLocal = localStorage.getItem("profileImage");





const HamNav = ({handleSubmit, userDetails, full_name, loginUserData, googleLogin, handleLogout, goToProfilePage})=>{
        const [isOpen, setIsOpen] = useState(false);
        const [secName, setSecName] = useState("");
        const myRef = useRef(null)
        const [profileImage, setProfileImage] = useState(profileImageLocal);
        const dispatch = useDispatch();
        const history = useHistory();
        const isCorporateAccount = useSelector((state) => state.auth.login_user_otp.corporate_account);
        const CORP_LIST = useSelector((state) => state.corporates?.corporates_category.data);
        const section_slug = localStorage.getItem("section_slug")
    const showSettings = (event) => {
        event.preventDefault();
        setIsOpen(!isOpen);
      }
      const USER_DETAILS = useSelector(
        (state) => state.userProfile?.profile_details?.data
      );
    useEffect(()=>{
      setProfileImage(USER_DETAILS?.user?.image);
    }, [USER_DETAILS]);
    const BurgerIcon = ()=>{
        return (
            <div >  
               {/* <i className="fa fa-bars"></i> */}
               <img src={hamNavIcon} alt="feather-ham-nav-icon"/>
            </div> 
        )
    };

    const isLoggedIn = ()=>{
        return ((Object.keys(loginUserData).length !== 0 &&
        loginUserData.constructor === Object) || (Object.keys(googleLogin).length !== 0 &&
        googleLogin.constructor === Object));
    };

    const closeNav =() => {
        console.log(isOpen);
        setIsOpen(false)
        myRef.current.scrollIntoView();
    };

    const handleOnOpen = ()=>{
        setIsOpen(true)
    }

    const handleAuth = (authType)=>{
      console.log(authType);
      closeNav()
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        handleSubmit(authType);
      } else {
        console.log(authType);
        history.push(`/${authType}`);
      }
      
    };
    const handleCorpLogin = () => {
        dispatch(ProfileAction.setScroll(true));
        history.push('/corporates')
        closeNav()
    }

    useEffect(()=>{
      if(isOpen){
        document.querySelector('html').style.overflow = "hidden"

      } else {
        document.querySelector('html').style.overflow = "scroll"
      }

      return(() => {
        document.querySelector('html').style.overflow = "scroll"
      })

    }, [isOpen])

    const handleCategory = (id, name,slug) => {
      dispatch(CorporateAction.setCategoryId(id));
      dispatch(CorporateAction.getCorporatePackages(id, 1));
      setSecName(name)
      localStorage.setItem("activeCorpCategory",slug)
      localStorage.setItem("scrollactiveCorpCategory",true)
      closeNav()
    }

    useEffect(() => {
      if(isCorporateAccount){
        setTimeout(() => {
          let activeLink = document.body.querySelector(`#${secName ? secName : "Holidays"}`);
          let scrollContent = document.body.querySelector(`#filter-wrapper`);
          if (scrollContent && activeLink) {
            scrollContent.scrollLeft = activeLink.offsetLeft -170 ;
          }else if(scrollContent) {
            scrollContent.scrollLeft = 0;
        }
        },200)
      }
      
    }, [secName])
    
      
    return (
        
        <Menu pageWrapId={ "page-wrap" }  className={ "hamm-menu" } outerContainerId={ "header-holder" } disableAutoFocus
        onStateChange={(state) => setIsOpen(state.isOpen)}
         right  isOpen={ isOpen } width={/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? '100%': '410px' } customBurgerIcon={ <BurgerIcon /> }  >
          {/* <main id="page-wrap"> */}

              <div className="position-relative" ref={myRef}>
                {/* <p>hammock</p> */}
                <div className="grad-text position-absolute  ham-icon cursor-pointer" onClick={()=>{history.push(`${isCorporateAccount ? '/corporates' : '/'}`);closeNav()}} style={{left: "35px"}}>
                  {/* <i class="fa fa-home" aria-hidden="true"></i> */}
                  <img style={{width:18}} src={homeIcon} alt='home-icon' />
                </div>
                <div className=" position-absolute  ham-icon cursor-pointer" onClick={()=>{closeNav()}} style={{right: "35px"}}>
                  {/* <i class="fa fa-times" aria-hidden="true"></i> */}
                  <img style={{width:14}} src={closeIcon} alt='close-icon' />
                </div>
                <div className="grad-text text-center" 
                style={{padding:33}}
                onClick={()=>{history.push(`${isCorporateAccount ? '/corporates' : '/'}`);closeNav()}}>
                  {/* hammock */}
                   <img src={hamLogo} alt='hammock-logo' />
                  </div>
                {/* <a href="javascript:void(0)" className="closebtn" onClick={()=>setIsOpen(false)} style={{ color: "black" }}>&times;</a> */}
                <div className="profile-ham-menu">
                
                
                  <button
                    className="btn "
                    style={{padding:5, cursor: "default"}}
                    type="button" >
                    <div class="gradient-border">
                      {
                        isLoggedIn()?(profileImage === "" || profileImage == null ? <div className="name-char" > <p>{full_name !== undefined && full_name?.charAt(0).toUpperCase()}</p>  </div> : <img src={profileImage} alt="profileImage" />): <div className="name-char"> <img src={userIcon}  style={{height:40, width:40}} alt="userIcon"/></div>
                      }
                    </div>
                  </button>
                  
                  
                    <div className="ml-3">
                        {isLoggedIn()?<div style={{fontWeight:'bold'}} className="ml-">
                            <div style={{color:'black', fontSize:22}}>{full_name}</div>
                            <div className="grad-text cursor-pointer" style={{fontSize:16}} onClick={()=>{goToProfilePage("profile");closeNav()}}>My Profile</div>
                        </div>:<div style={{color:'black', fontWeight:'bold'}} className="ml-3"> Guest Profile</div>}
                        
                    </div>
                </div>
                {!isLoggedIn()&&<div class=" d-flex justify-content-evenly overlays-content">

                  <div>
                    <button  onClick={() => handleAuth("login")} className="btn mobiBtns yo1">Login</button><br />
                  </div>
                  <div>
                    <button className="btn mobiBtns yo2" onClick={() => handleAuth("signup")}>Sign Up</button>
                  </div>
               
                </div>}
                {!isLoggedIn()&&<div className="grad-text text-center mt-2 mb-4" style={{cursor: "pointer"}} onClick={() => handleCorpLogin()}>Login as a corporate employee</div>}
                {isLoggedIn()&&<div className="ham-mybookings" onClick={()=>goToProfilePage("bookings")}>
                    <div className="grad-text cursor-pointer" style={{padding:'10px 20px'}} onClick={()=>closeNav()} >
                        My Booking
                    </div>
                    </div>}
                <hr />
                <div className="ham-nav">
                  {CORP_LIST && isCorporateAccount && CORP_LIST.length > 0 ? 
                  <ul >
                  {CORP_LIST && CORP_LIST.map(item => {
                    return(
                      <li onClick={()=>handleCategory(item.id, item.name,item.section_slug)}><Link to="/corporates"  >{item.name}</Link></li>
                    )
                  })}
                  </ul>  :
                  <ul>
                    <li onClick={()=>closeNav()}><Link to={{ pathname: `/holidays${section_slug?`/${section_slug}`:''}`, data: {bool: true}}} >Holidays</Link></li>
                    <li onClick={()=>closeNav()}><Link to="/corporates" >Corporates</Link></li>
                    <li onClick={()=>closeNav()}><Link to="/experiences" >Experiences</Link></li>
                    <li onClick={()=>closeNav()}><Link to="/my-family-first" >My Family First</Link></li>
                    <li onClick={()=>closeNav()}><Link to="/weddings"  >Weddings</Link></li>
                </ul>
                }
                  
                    
                </div>
                <hr />
                <div className="ham-nav thin-list">
                  <ul >
                    <li><Link to="/careers" >Careers</Link></li>
                    <li><Link to="/about-us" >About Us</Link></li>
                    <li><Link to="/terms-and-conditions" >Terms & Conditions</Link></li>
                    <li><Link to="/privacy-policy" >Privacy Policy</Link></li>
                    <li><Link to="/refund-and-cancellations" >Refund & cancellations</Link></li>
                  </ul>
                </div>
                <hr />
                <div className="text-center">
                  <button onClick={()=>{history.push('/contact-us');closeNav()}} className="btn ham-contact-btn">Contact hammock</button>
                </div>
                {isLoggedIn()&&<div className="text-center">
                  <button className="btn ham-logout-btn" onClick={()=>{handleLogout();closeNav()}}>Log out</button>
                </div>}
                {/* ham-logout-btn */}
              </div>
        {/* </main> */}
        </Menu>
      );
}

export default HamNav

// const hn = ()=>{
//     const [isOpen, setIsOpen] = useState(false);
//     return (
//         <div >
//         <div className="ham-icon"><a href="javascript:void(0);"  onClick={()=>setIsOpen(!isOpen)} >
//               <i className="fa fa-bars"></i>
//         </a></div>
//         {(isOpen || true)&&<div  className="ham-menu">
//               <div  style={isOpen ? {} : { display: 'none' }}  className="overlays  animate__animated animate__slideInRight animate__faster animate__backOutDown">
//                 <p>hammock</p>
//                 <a href="javascript:void(0)" className="closebtn" onClick={()=>setIsOpen(false)} style={{ color: "black" }}>&times;</a>
//                 <div className="profile-ham-menu">
//                     Guest profile
//                 </div>
//                 <div class=" d-flex justify-content-evenly overlays-content">

//                   <div>
//                     {/* <button className="btn mobiBtns yo1"><a onClick={() => handleSubmit("login")}>Login</a></button><br /> */}
//                   </div>
//                   <div>
//                     {/* <button className="btn mobiBtns yo2"><a onClick={() => handleSubmit("signup")}>Sign Up</a></button> */}
//                   </div>
//                   {/* <a onClick={goToContactUs} className="yo3">Contact Us</a> */}

               
//                   {/* <Link to="/events" className="yo3"><a>Events</a></Link> */}
//                 </div>
//                 <hr />
//                 <div className="ham-nav">
//                   <ul >
//                     <li><Link to="/events" ><a>Events</a></Link></li>
//                     <li><Link to="/events" ><a>Experiences</a></Link></li>
//                     <li><Link to="/events" ><a>Holidays</a></Link></li>
//                     <li><Link to="/events" ><a>Corporate</a></Link></li>
//                     <li><Link to="/events" ><a>My Family First</a></Link></li>
//                   </ul>
//                 </div>
//                 <hr />
//                 <div className="ham-nav thin-list">
//                   <ul >
//                     <li><Link to="/events" ><a>Terms & Conditions</a></Link></li>
//                     <li><Link to="/events" ><a>Privacy Policy</a></Link></li>
//                     <li><Link to="/events" ><a>Refund & cancellations</a></Link></li>
//                   </ul>
//                 </div>
//                 <hr />
//                 <div>
//                   <button>Contact hammock</button>
//                 </div>
//               </div>
//             </div>}
//         </div>
//     );
// };

// // export  hn;