import React, { useState, useEffect } from "react";
import "../../assets/css/partials/home.scss";
import { TabHolder, Header, Footer, ScrollToTop } from "../../components/common";
import banner from "../../assets/images/home/Converted/banner.webp";
import CorporatesBanner from "../../assets/images/home/Converted/corporate-banner.webp";
import CorporatesMobileBanner from "../../assets/images/home/Converted/corp_mobile_banner.webp";
import ExperienceBanner from "../../assets/images/home/Converted/experience-banner.webp";
import HolidaysBanner from "../../assets/images/home/Converted/holidays-banner.webp";
import MyFamilyBanner from "../../assets/images/home/Converted/gifts-banner.webp";
import EventsBanner from "../../assets/images/home/events_banner.png";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import vector1 from "../../assets/images/common/Vector1.svg";
import vector2 from "../../assets/images/common/Vector2.svg";
import AuthIndex from "../../components/auth/index";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  NavLink,
} from "react-router-dom";
import "../../assets/css/partials/tabs-holder.scss";
import Home from "../../components/homepage/home";
import Corporates from "../../components/corporates";
import MyFamilyFirst from "../../components/myFamilyFirst";
import Weddings from "../../components/weddings"
import Events from '../../components/events';
import Holidays from '../../components/holidays';
import Experiences from '../../components/experiences';
import GlobalSearch from "../../components/common/globalSearch";
import MyProfile from "../../components/common/profile/myProfile";
import MyBookings from "../../components/common/profile/myBookings";
import UserProfile from "../../components/common/profile"; 
import * as HomeAction from '../../redux/actions/homeAction'
import { useHistory } from 'react-router';
import moment from "moment";

export default function HomeLayout(props) {
  // todo
  const loginUserData = useSelector((state) => state.auth.login_user_otp);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const fullName = localStorage.getItem("full_name");
  const [value, setValue] = useState("")
  const [mounted,setMounted]=useState(false)
  const history = useHistory()
  const getToken = localStorage.getItem("token");
  const dispatch = useDispatch()
  let loacationPath = props.location.pathname;
  const EXP_IN_YEARS = moment().diff('1996-07-09', 'years')
useEffect(()=>{
  if(EXP_IN_YEARS){
    localStorage.setItem("EXP_IN_YEARS",EXP_IN_YEARS)
  }
},[EXP_IN_YEARS])
useEffect(()=>{
  setMounted(true)
},[])
  const handleSearchValue = (value) => {
      setValue(value)
  }
  const SUGGESTIONS_LIST = useSelector((state) => state.homepage.search_suggestions_list);
  const handleSubmit = () => {
      dispatch(HomeAction.setGlobalSearchVal(value))
      localStorage.setItem('sec_name', 'see all')
      if(value){
        history.push('/search')
      }
  }
  
  return (
    <div>
      <div className="banner-holder">
        <Header />
        <Helmet>
        <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="50" />
          <meta property="og:image:height" content="50" />
          <meta property="og:image:alt" content={`${EXP_IN_YEARS}yrs of creating Magical Experiences - Holidays & Gifts . Virtual Events . Corporate deals . and a lot more`} />
          <meta property="og:site_name" content="Hammock Holidays" />
          <meta property="og:title" content="Hammock Holidays - Holidays & Gifts, Virtual Events, Experiences, Holidays, Corporate deals, My Family First" />
          <meta property="og:description" content={`${EXP_IN_YEARS}yrs of creating Magical Experiences - Holidays & Gifts . Virtual Events . Corporate deals . and a lot more`} />
          <meta property="og:image" itemprop="image" content="%PUBLIC_URL%/Logo.png" />
          <meta property="og:type" content="website" />
        </Helmet>
        {/* Banner images */}
          <div className={` ${loacationPath==="/profile" || loacationPath==="/bookings"?'':'d-none'}`}>
              <UserProfile fromNav={true}/>
          </div>
          
          <div className={`banner ${loacationPath==="/"?'':'d-none'}`}>
          {mounted&&
            <img  src={banner} className="img-fluid" alt="main-banner" width='100%' />
          }
          </div>
          <div className={`banner ${loacationPath==="/corporates" ?'':'d-none'}`}>
            <img src={CorporatesBanner} className="img-fluid d-none d-md-block" alt="CorporatesBanner" />
            <img src={CorporatesMobileBanner} className="img-fluid d-block d-md-none" alt="CorporatesBanner" />
          </div>
          <div className={`banner ${loacationPath.indexOf("/experiences")>-1?'':'d-none'}`}>
            <img src={ExperienceBanner} className="img-fluid" alt="ExperienceBanner" />
          </div>
          <div className={`banner ${loacationPath.indexOf("/holidays")>-1?'':'d-none'}`}>
            <img src={HolidaysBanner} className="img-fluid" alt="HolidaysBanner" />
          </div>
          <div className={`banner ${loacationPath==="/my-family-first"?'':'d-none'}`}>
            <img src={MyFamilyBanner} className="img-fluid" alt="MyFamilyBanner" />
          </div>
          <div className={`banner ${loacationPath==="/weddings"?'':'d-none'}`}>
            <img src={MyFamilyBanner} className="img-fluid" alt="WeddingsBanner" />
          </div>

        {/* Banner text */}
        {loacationPath === "/" ? (
          <div className="text-holder animate-fadein" style={{top:'54%'}}>
            {" "}
            <h1>
              {EXP_IN_YEARS} yrs of creating <span>Magical Experiences</span>
            </h1>{" "}
            <p>Holidays & Gifts . Virtual Events . Corporate Deals and a lot more</p>{" "}
            <div className="home-search d-md-flex d-none">
              <div style={{flexGrow: "2"}}><GlobalSearch handleSearchValue={handleSearchValue} fromHome={true}/></div>
              <div className="search-button"><button disabled={SUGGESTIONS_LIST.length===0} onClick={handleSubmit}>Search</button></div>
            </div>
          </div>
        ) : (
          ""
        )}
        {loacationPath === "/corporates" || loacationPath==="/bookings" ? (

          <div>
            { userData ? <div className="corporate-logo">
              <img src={userData && userData.organisation?.logo} alt="" />
            </div> : ''

            }
            <div className="text-holder animate-fadein">
              {loginUserData &&
                loginUserData.corporate_account &&
                loginUserData.user_details ? (
                <h1>
                  Hi {fullName}, Deals just
                  <span> for you</span>
                </h1>
              ) : (
                <h1>
                 Employee <span>Recognition Programmes</span>
                </h1>
              )}
              {
                loginUserData &&
                loginUserData.corporate_account &&
                loginUserData.user_details ?  <p>Here you can use your corporate reward points</p> : <p>Inspiring Rewards – Inspiring Performance</p>
              }
              
            </div>
          </div>

        ) : ""
        }
        {loacationPath.indexOf("/experiences")>-1? (
          <div className="text-holder animate-fadein">
            <h1>
              Explore <span>Things to do</span>
            </h1>
            <p>Find something new today</p>
          </div>
        ) : (
          ""
        )}
        {loacationPath.indexOf("/holidays")>-1? (
          <div className="text-holder animate-fadein">
            {" "}
            <h1>
              Plan <span>Exclusive Holidays</span>
            </h1>{" "}
            <p>Find what’s best for you</p>
          </div>
        ) : (
          ""
        )}
        {loacationPath === "/my-family-first" ? (
          <div className="text-holder animate-fadein">
            {" "}
            <h1>
              Gift memorable <span>Experiences</span>
            </h1>{" "}
            <p>Make a gift with us for your loved ones</p>
          </div>
        ) : (
          ""
        )}
        {loacationPath === "/weddings" ? (
          <div className="text-holder animate-fadein">
            {" "}
            <h1>
              Gift memorable <span>Wedding Experiences</span> 
            </h1>{" "}
            <p>Make a gift with us for your loved ones</p>
          </div>
        ) : (
          ""
        )}

        <div class="vector-holder vector1">
          <img src={vector1} className="img-fluid" alt="vector1" width="100%" />
        </div>
        <div class="vector-holder vector2">
          <img src={vector2} className="img-fluid" alt="vector2" width="100%"/>
        </div>
      </div>
      <TabHolder />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        {/* <Route exact path="/events">
          <Events />
        </Route> */}
        <Route exact path="/events/:sectionid" component={Events} />
        <Route exact path="/experiences">
          <Experiences />
        </Route>
        <Route exact path="/experiences/:sectionid" component={Experiences} />
        <Route exact path="/holidays">
          <Holidays />
        </Route>
        <Route exact path="/holidays/:sectionid" component={Holidays} />
        <Route exact path="/corporates">
          <Corporates />
        </Route>
        <Route exact path="/corporates/:sectionid" component={Corporates} />
        <Route exact path="/my-family-first">
          <MyFamilyFirst />
        </Route>
        <Route exact path="/weddings">
          <Weddings />
        </Route>
        <Route path="/profile">
          <MyProfile />
        </Route>
        <Route path="/bookings">
          <MyBookings />
        </Route>
      </Switch>
      <ScrollToTop  />

      <Footer />
    </div>
  );
}
